import axios from 'axios';
import { apiErrorHandler } from '../services/api';
import * as Api from './index';

// list resource needs for a school
export const listResourceNeedBySchool = schoolId => {
    const url = Api.getv2ApiBase(`/resource-need/${schoolId}`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'List Resource Need error'
            })
        );
};

// list resource need template bindings
export const listResourceNeedTemplateBindings = schoolId => {
    const url = Api.getv2ApiBase(`/resource-need/template-bindings?schoolId=${schoolId}`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'List Resource Need Template Binding error'
            })
        );
};

// mapped resource needs and tags by key
export const getResourceNeedsMappedByBindingKey = schoolId => {
    const url = Api.getv2ApiBase(`/resource-need/template-bindings-tags/${schoolId}`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'List Resource Need Template Binding With Tags error'
            })
        );
};
