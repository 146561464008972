import axios from 'axios';
import * as Api from './index';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { strictEqual } from 'assert';

export const updateLabelMappingsForFlow = (chatbotFlowId, labelIds) => {
    try {
        if (typeof chatbotFlowId !== 'string') {
            throw new Error(`Parameter chatbotFlowId must be a string.`);
        }

        if (!Array.isArray(labelIds)) {
            throw new Error(`Parameter labelIds must be an array.`);
        }

        const url = Api.getv2ApiBase(`/chatbot-flow-label-mappings/${chatbotFlowId}`);
        const options = Api.getOptions();

        return axios.post(url, { labelIds }, options).then(response => {
            return response.data;
        });
    } catch (error) {
        console.error('Problem saving labels: ', error.message);
    }
};

export const createChatbotFlowLabelMappings = async ({ mappings, errorHandlerOptions = {} }) => {
    const url = Api.getv2ApiBase(`/chatbot-flow-label-mappings`);
    const options = Api.getOptions();

    try {
        strictEqual(
            Array.isArray(mappings) && mappings.every(mapping => typeof mapping === 'object'),
            true,
            'mappings must be an array of objects'
        );

        const response = await axios.post(url, { mappings }, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem saving the Label(s). Please try again.',
            ...errorHandlerOptions
        });
    }
};
