'use strict';

// This file creates a cache for various uses. The cache is cleared on app refresh.
// You probably don't need to use this unless you just need to cache some static data,
// or need to share some data across the app and don't need/want a full store setup.

import { LRUCache } from 'lru-cache';

const appCache = new LRUCache({ max: 500 });

const setCachedValue = ({ key, value }) => {
    appCache.set(key, value);
};

const getCachedValue = ({ key }) => {
    return appCache.get(key);
};

const cacheKeyGetters = {
    exampleStaticKey: () => 'myStaticKey',
    exampleDynamicKey: id => `myDynamicKey-${id}`,
    interventionDetailContextKey: interventionTemplateId =>
        `interventionDetailsContext-${interventionTemplateId}`
};

export const setters = {
    setInterventionDetailsContext: (interventionTemplateId, value) =>
        setCachedValue({
            key: cacheKeyGetters.interventionDetailContextKey(interventionTemplateId),
            value
        })
};

export const getters = {
    getInterventionDetailsContext: interventionTemplateId =>
        getCachedValue({
            key: cacheKeyGetters.interventionDetailContextKey(interventionTemplateId)
        })
};
