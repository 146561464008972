import { objectToCamelCase, objectToSnakeCase } from '../utils';
import api from './api';
import options from './options';
import ChatbotTemplate from '../chatbotTemplates';

export class ChatbotSchool {
    constructor(chatbotSchool) {
        // eslint-disable-next-line no-param-reassign
        chatbotSchool = chatbotSchool || {};
        const {
            id = null,
            name = '',
            initialMessagesSent = null,
            initialMessagesDelivered = null,
            studentsResponded = null
        } = chatbotSchool;
        Object.assign(this, {
            id,
            name,
            initialMessagesSent,
            initialMessagesDelivered,
            studentsResponded
        });
    }

    static create(opts) {
        return new ChatbotSchool(opts);
    }

    static fromAPI(json) {
        return new ChatbotSchool(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }
}

export default class ChatbotFlow {
    static api = api.create(ChatbotFlow);
    static options = options;
    constructor(chatbotFlow) {
        // eslint-disable-next-line no-param-reassign
        chatbotFlow = chatbotFlow || {};
        const {
            id = null,
            template = null,
            templateRef = {},
            date = '',
            status = '',
            studentList = [],
            studentFilter = null,
            schools = [],
            schoolsRef = [],
            historical = false
        } = chatbotFlow;
        Object.assign(this, {
            id,
            template,
            templateRef: ChatbotTemplate.fromAPI(templateRef),
            date,
            status,
            studentList,
            studentFilter,
            schools,
            schoolsRef: schoolsRef.map(school => {
                return ChatbotSchool.fromAPI(school);
            }),
            historical
        });
    }

    static create(opts) {
        return new ChatbotFlow(opts);
    }

    static fromAPI(json) {
        return new ChatbotFlow(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }
}
