<template>
    <div>
        <div class="table-row">
            <div class="table-row-cell-medium">
                {{ studentUpload.schoolRef.name }}
            </div>
            <div class="table-row-cell-large">
                <p v-if="isImported" class="text-bold">{{ studentUpload.title }}</p>
                <p v-else class="text-bold text-primary">{{ studentUpload.title }}</p>
                <p class="uploaded-date">
                    Uploaded {{ formatDate(studentUpload.datetimeCreated) }}
                </p>
            </div>
            <div class="table-row-cell-large">
                {{
                    studentUpload.uploadTypeRef.indexOf('Withdrew') > 0
                        ? 'Remove Students - Did not persist'
                        : studentUpload.uploadTypeRef
                }}
            </div>
            <div class="table-row-cell-large">
                <v-select
                    class="status-dropdown"
                    :options="uploadStatusOptions"
                    v-model="uploadStatus"
                    @update:modelValue="onUploadStatusChanged"
                    :clearable="false"
                />
            </div>
            <div class="table-row-cell-small">
                <a
                    v-if="studentUpload.notes"
                    class="notes-link"
                    @click.stop="toggleNotes(studentUpload.id)"
                >
                    <template v-if="!expandedRows.includes(studentUpload.id)">See notes</template>
                    <template v-else>Hide notes</template>
                </a>
            </div>
            <div class="table-row-cell-small">
                <Button styleType="off" @click.stop="downloadFile(studentUpload)">Download</Button>
            </div>
            <div class="table-row-cell-small" v-if="false">
                <Button
                    styleType="off"
                    v-if="!isImported"
                    :disabled="isImported"
                    @click.stop="startProcessing(studentUpload)"
                    >Start</Button
                >
            </div>
        </div>
        <TransitionExpand>
            <div class="transition-container" v-if="expandedRows.includes(studentUpload.id)">
                <div class="upload-notes">{{ studentUpload.notes }}</div>
            </div>
        </TransitionExpand>
    </div>
</template>

<script>
import StudentUpload from '@/services/studentUploads';
import Button from '@/components-deprecated/Button';
import TransitionExpand from '@/components-deprecated/TransitionExpand';
import { vueMomentFilterReplacement } from '@/lib/vue-migration-helpers';

export default {
    name: 'ManageUploadsRow',
    components: {
        Button,
        TransitionExpand
    },
    data() {
        return {
            expandedRows: [],
            uploadStatus: null
        };
    },
    async created() {
        this.uploadStatus = this.uploadStatusOptions.find(
            status => status.value === this.studentUpload.status
        );
    },
    props: {
        studentUpload: {
            type: Object,
            default: null
        },
        uploadStatusOptions: {
            type: Array,
            default: null
        },
        uploadTypeOptions: {
            type: Array,
            default: null
        }
    },
    computed: {
        isImported() {
            return this.studentUpload.status === StudentUpload.options.IMPORTED;
        },
        isInProgress() {
            return this.studentUpload.status === StudentUpload.options.IN_PROGRESS;
        }
    },
    watch: {
        studentUpload() {
            this.uploadStatus = this.uploadStatusOptions.find(
                status => status.value === this.studentUpload.status
            );
        }
    },
    methods: {
        formatDate(date) {
            return vueMomentFilterReplacement(date, 'YYYY-MM-DD');
        },
        async refreshPage() {
            await this.$emit('refresh');
        },
        async onUploadStatusChanged(status) {
            if (this.studentUpload.status === status.value) {
                return;
            }

            // Don't allow status to be changed to New
            if (status.value === StudentUpload.options.NEW) {
                this.uploadStatus = this.uploadStatusOptions.find(
                    status => status.value === this.studentUpload.status
                );
                return;
            }

            await StudentUpload.api.update({
                id: this.studentUpload.id,
                status: status.value
            });

            await this.refreshPage();
        },
        toggleNotes(studentUploadId) {
            if (this.expandedRows.includes(studentUploadId)) {
                this.expandedRows = this.expandedRows.filter(id => id !== studentUploadId);
            } else {
                this.expandedRows.push(studentUploadId);
            }
        },
        async downloadFile(studentUpload) {
            window.open(studentUpload.file);
        },
        startProcessing(studentUpload) {
            this.$modal.show('modal-manage-student-upload', {
                studentUpload: studentUpload,
                uploadTypeOptions: this.uploadTypeOptions,
                refreshPage: this.refreshPage,
                showConfirmationModal: this.showConfirmationModal
            });
        },
        showConfirmationModal(results) {
            this.$emit('on-confirmation', results);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.table-row {
    display: flex;
    padding: 15px 20px;

    &:hover {
        background-color: $white-blue;
    }
}

.table-row-cell {
    margin: auto;
}

.table-row-cell-small {
    @extend .table-row-cell;
    flex: 0.4;
}

.table-row-cell-medium {
    @extend .table-row-cell;
    flex: 0.7;
}

.table-row-cell-large {
    @extend .table-row-cell;
    flex: 1;
}

.uploaded-date {
    color: $main-gray;
}

.status-dropdown {
    width: 75%;
    background-color: inherit;
}

.transition-container {
    display: flex;
}

.notes-link {
    cursor: pointer;
}

.upload-notes {
    border: 1px solid $very-light-gray;
    margin: 0 16rem;
    padding: 1rem;
    word-break: break-all;
}
</style>
