<template>
    <div>
        <LoadingSpinner v-if="isLoading" />
        <div v-else class="block">
            <div class="opted-in-container">
                <div v-if="!student.optedOutOfSms" class="opted-in-badge">Opted-In</div>
            </div>
            <div class="username-wrapper">
                <h1 class="block__padding">{{ student.fullName }}</h1>
            </div>

            <div :class="{ 'padded-row': !isEditMode }">
                <div class="student-info">
                    <div class="row">
                        <div class="block__title h3">Student Information</div>
                        <button
                            v-if="isEditMode"
                            class="edit-link"
                            @click.prevent="onCancelButtonClicked"
                        >
                            Cancel
                        </button>
                        <button
                            v-if="isEditMode"
                            class="edit-link"
                            form="edit-student-info"
                            type="submit"
                        >
                            Save
                        </button>
                        <button
                            v-if="!isEditMode"
                            type="button"
                            class="edit-link"
                            @click.prevent="onEditButtonClicked"
                        >
                            Edit
                        </button>
                    </div>
                    <div v-if="!isEditMode">
                        <div class="h3" style="color: black">
                            ID:&nbsp;
                            <span class="text-gray">{{ studentId }}</span>
                        </div>
                        <div class="h3" style="color: black">
                            Phone:&nbsp;
                            <span class="text-gray">{{ studentPhoneNumber }}</span>
                        </div>
                        <div class="h3" style="color: black">
                            Email:&nbsp;
                            <span class="text-gray">{{ student.email }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="student.assignedAdmins && !isEditMode" class="assigned-admins-container">
                    <div class="block__title h3">Assigned Administrators</div>
                    <div class="assigned-admins">
                        <div v-for="admin in student.assignedAdmins" :key="admin.id" class="admin">
                            {{ admin.display_name ? admin.display_name : admin.email }}
                        </div>
                        <div v-if="!student.assignedAdmins.length">N/A</div>
                    </div>
                </div>
            </div>

            <form
                v-if="isEditMode"
                class="padded-bottom-row"
                id="edit-student-info"
                @submit.prevent="onEditButtonClicked"
                aria-label="Edit student information"
            >
                <ValidatedInput
                    ref="input"
                    type="text"
                    placeholder="First name"
                    label="First Name"
                    :regexp="/[A-z0-9 \/\-]{0,30}/"
                    class="edit-student-input"
                    v-model="student.firstName"
                />
                <ValidatedInput
                    ref="input"
                    class="edit-student-input"
                    type="text"
                    placeholder="Last name"
                    label="Last Name"
                    :regexp="/[A-z0-9 \/\-]{0,30}/"
                    v-model="student.lastName"
                />
                <ValidatedInput
                    ref="input"
                    class="edit-student-input"
                    type="text"
                    placeholder="ID number"
                    label="Student ID"
                    v-model="student.studentId"
                />
                <ValidatedInput
                    ref="input"
                    class="edit-student-input"
                    type="text"
                    placeholder="Phone number"
                    label="Phone number"
                    :regexp="/[0-9 \/\-]{0,15}/"
                    v-model="student.phoneNumber"
                />
                <BaseCheckbox
                    label="Opted Out"
                    :modelValue="student.optedOutOfSms"
                    @update:modelValue="onOptOutChange"
                />
            </form>

            <div class="padded-bottom-row">
                <StudentBlockInfoTags :tags="student.tagsRef" @add="addTag" />
            </div>

            <div class="block__risk-header">
                <div class="block__title h3">Risk Profile</div>
                <button
                    :class="alerts.length > 0 ? 'block__alerts' : 'block__alerts no-cursor'"
                    @click="showAlertsModal"
                >
                    {{ alerts.length }} Alerts Triggered 🚨
                </button>
            </div>
            <div class="block__risk-level">
                <template v-if="student.overallRiskPercentile">
                    <PieChart
                        class="block__risk-level-chart"
                        :color="chartCircleColor"
                        :value="student.overallRiskPercentile"
                        :text="riskLevelText"
                        ariaLabelTitle="Pie chart representing this student's risk percentile:"
                    />
                    <div>
                        <IndicatorRiskLevel :riskLevel="student.overallRiskLevel" />
                        {{ riskLevelDescription }}
                    </div>
                </template>
                <div v-else>
                    This student does not have a risk score. They either have not answered enough
                    questions or they are not part of the retention framework. Ask your EdSights
                    consultant for details.
                </div>
            </div>

            <template v-if="student.overallRiskPercentile">
                <div class="section-header">What is driving risk for {{ student.firstName }}?</div>
                <StudentBlockInfoRiskDriver
                    class="risk-driver"
                    v-for="riskCategory in relevantRiskCategories"
                    :key="riskCategory"
                    :riskCategory="riskCategory"
                    :riskLevel="getRiskLevel(riskCategory)"
                    :riskPercentile="getRiskPercentile(riskCategory)"
                    :riskSubcategories="riskSubcategories[riskCategory]"
                />
            </template>
            <ModalAddTag />
            <ModalAlerts :alerts="alerts" @update-status="updateStatus" />
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue';

import Alert from '@/services/alerts';
import Student from '@/services/students';
import RiskBucketScore from '@/services/riskBucketScores';

import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import StudentBlockInfoTags from '@/components-deprecated/StudentBlockInfoTags';
import StudentBlockInfoRiskDriver from '@/components-deprecated/StudentBlockInfoRiskDriver';
import ModalAddTag from '@/components-deprecated/admin/ModalAddTag';
import ModalAlerts from '@/components-deprecated/admin/ModalAlerts';
import PieChart from '@/components-deprecated/charts/PieChart';
import IndicatorRiskLevel from '@/components-deprecated/IndicatorRiskLevel';
import {
    COLOR_PRIMARY,
    COLOR_CHART_RISK_LOW_DARKER,
    COLOR_CHART_RISK_MEDIUM_DARKER,
    COLOR_CHART_RISK_HIGH_DARKER
} from '@/consts/colors';
import ValidatedInput from '@/components-deprecated/inputs/ValidatedInput';
import { BaseCheckbox } from '@edsights/ui-core';

export default {
    name: 'StudentBlockInfo',
    COLOR_PRIMARY,
    components: {
        StudentBlockInfoTags,
        StudentBlockInfoRiskDriver,
        ModalAddTag,
        ModalAlerts,
        PieChart,
        IndicatorRiskLevel,
        ValidatedInput,
        BaseCheckbox,
        LoadingSpinner
    },

    props: {
        student: {
            type: Student,
            default: Student.create()
        }
    },
    data() {
        return {
            alerts: [],
            studentRiskPoints: {
                academicRiskPoints: 0,
                engagementRiskPoints: 0,
                financialRiskPoints: 0,
                wellnessRiskPoints: 0
            },
            totalStudentRiskPoints: 0,
            relevantRiskCategories: [],
            riskSubcategories: {}, //key is risk category, value is list of subcategories
            isEditMode: false,
            isLoading: false
        };
    },
    async created() {
        this.isLoading = true;
        await this.fetchAlerts();
        this.compileStudentRiskPoints();
        this.getRelevantRiskCategories();
        await this.getAllRiskSubcategories();
        this.isLoading = false;
    },
    methods: {
        async fetchAlerts() {
            let { results } = await Alert.api.list({ student: this.student.id });
            this.alerts = results;
        },
        // Compiles and reformats student risk points into the data property studentRiskPoints.
        // Sets negative risk points to 0 so that we're not working with negative numbers.
        // Finally, we add up all the points to totalStudentRiskPoints, which will be used as the
        // denominator to calculate risk point percent for each risk driver.
        compileStudentRiskPoints() {
            for (let riskType in this.studentRiskPoints) {
                this.student[`${riskType}`] < 0
                    ? (this.studentRiskPoints[riskType] = 0)
                    : (this.studentRiskPoints[riskType] = this.student[`${riskType}`]);
                this.totalStudentRiskPoints += this.studentRiskPoints[riskType];
            }

            // added logic b/c you can't divide by 0. So if totalStudentRiskPoints = 0, set it to 1
            // so we're not passing NaN to ChartProgress.
            this.totalStudentRiskPoints =
                this.totalStudentRiskPoints === 0 ? 1 : this.totalStudentRiskPoints;
        },
        getRelevantRiskCategories() {
            let riskLevelMapping = {
                LOW_RISK: 0,
                MEDIUM_RISK: 1,
                HIGH_RISK: 2
            };
            let allRiskCategories = ['academic', 'engagement', 'financial', 'wellness'];

            this.relevantRiskCategories = [];
            allRiskCategories.forEach(riskCategory => {
                let riskLevel = riskLevelMapping[this.getRiskLevel(riskCategory)];
                if (riskLevel >= 0) {
                    this.relevantRiskCategories.push(riskCategory);
                }
            });
        },
        async getAllRiskSubcategories() {
            this.riskSubcategories = {};
            let promises = this.relevantRiskCategories.map(async riskCategory => {
                let riskSubcategories = await this.getRiskSubcategories(riskCategory);
                this.riskSubcategories[riskCategory] = riskSubcategories;
            });

            await Promise.all(promises);
        },
        onClickEnvelope() {
            this.$emit('envelope');
        },
        addTag() {
            this.$modal.show('modal-add-tag-to-student', { student: this.student });
        },
        onEditButtonClicked() {
            if (this.isEditMode) {
                this.isEditMode = false;
                this.$emit('onSaveButtonClicked', this.student);
            } else {
                this.isEditMode = true;
            }
        },
        onCancelButtonClicked() {
            this.isEditMode = false;
        },
        onOptOutChange(value) {
            this.student.optedOutOfSms = value;
        },
        getRiskLevel(riskCategory) {
            return this.student[`${riskCategory}RiskLevel`];
        },
        getRiskPercentile(riskCategory) {
            return this.student[`${riskCategory}RiskPercentile`];
        },
        async getRiskSubcategories(riskCategory) {
            let riskLevel = this.getRiskLevel(riskCategory);
            let riskBucketScores = await RiskBucketScore.api.list({
                student: this.student.id,
                risk_level_gte: riskLevel,
                risk_bucket__category: riskCategory.toUpperCase()
            });

            return riskBucketScores.results;
        },
        async updateStatus(alertId, status, message) {
            await Alert.api.updateStatus(alertId, status);
            await this.fetchAlerts();
            this.$Alert.alert({
                type: 'success',
                message: `<h2>${message}</h2>`,
                timeout: 3000
            });
        },
        showAlertsModal() {
            if (this.alerts.length === 0) {
                return;
            }
            this.$modal.show('modal-alerts', {
                alerts: this.alerts,
                updateStatus: this.updateStatus
            });
            nextTick(() => {
                const alertsModal = document.querySelector('.vm--container');
                if (alertsModal) {
                    alertsModal.focus();
                }
            });
        }
    },
    computed: {
        chartCircleColor() {
            switch (this.student.overallRiskLevel) {
                case 'LOW_RISK':
                    return COLOR_CHART_RISK_LOW_DARKER;
                case 'MEDIUM_RISK':
                    return COLOR_CHART_RISK_MEDIUM_DARKER;
                case 'HIGH_RISK':
                    return COLOR_CHART_RISK_HIGH_DARKER;
            }

            return COLOR_CHART_RISK_LOW_DARKER;
        },
        riskLevelDescription() {
            if (this.student.overallRiskLevel === Student.NOT_APPLICABLE) {
                return 'There is not enough data yet for this student.  Check back later to see their risk percentile.';
            } else {
                return `The ${this.student.overallRiskPercentile}th percentile means \
      this student has a higher risk profile \
      than ${this.student.overallRiskPercentile}% of their peers.`;
            }
        },
        riskLevelText() {
            if (
                this.student.overallRiskLevel === Student.NOT_APPLICABLE ||
                this.student.overallRiskPercentile === null
            ) {
                return 'N/A';
            } else {
                return `${this.student.overallRiskPercentile}th`;
            }
        },
        studentId() {
            return this.student.studentId ? this.student.studentId : 'N/A';
        },
        studentPhoneNumber() {
            return this.student.phoneNumber ? this.student.phoneNumber : 'N/A';
        },
        studentAcademicRiskPointsPercent() {
            return (this.studentRiskPoints.academicRiskPoints / this.totalStudentRiskPoints) * 100;
        },
        studentEngagementRiskPointsPercent() {
            return (
                (this.studentRiskPoints.engagementRiskPoints / this.totalStudentRiskPoints) * 100
            );
        },
        studentFinancialRiskPointsPercent() {
            return (this.studentRiskPoints.financialRiskPoints / this.totalStudentRiskPoints) * 100;
        },
        studentWellnessRiskPointsPercent() {
            return (this.studentRiskPoints.wellnessRiskPoints / this.totalStudentRiskPoints) * 100;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.row {
    display: flex;
    flex-direction: row;
}

.padded-row {
    @extend .row;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.padded-bottom-row {
    margin-bottom: 3rem;
}

.edit-link {
    all: unset;
    cursor: pointer;
    margin-left: 1rem;
    opacity: 0.75;
    font-weight: bold;
    border: 1px solid $edsights-blue;
    border-radius: 4px;
    padding: 4px 8px;
    line-height: 1;
    display: flex;
    align-items: center;
    &:focus {
        outline: 2px solid $edsights-blue;
        outline-offset: 2px;
    }
}

.block {
    &__risk-header {
        display: flex;
        justify-content: space-between;
    }
    &__title {
        text-transform: uppercase;
    }
    &__alerts {
        cursor: pointer;
        background: transparent;
        border: 0;
        &:focus {
            outline: 2px solid $edsights-blue;
            outline-offset: 2px;
        }
        &.no-cursor {
            cursor: default;
        }
    }
    &__padding {
        margin: 15px 15px 15px 0;
    }
    &__envelope {
        width: 35px;
        cursor: pointer;
        margin-top: 10px;
    }
    &__risk-level {
        display: flex;
        margin: 15px 0;
    }
    &__risk-level-chart {
        margin-right: 25px;
    }
}

.username-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.timeline {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    width: 60%;

    &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__line {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 2px;
        background-color: #d8d8d8;
        height: 30px;
    }

    &__point {
        height: 8px;
        width: 8px;
        border-radius: 6px;
        background-color: $edsights-blue;
        z-index: 10;
        margin-bottom: 0.1rem;
    }
}

.edit-student-input {
    padding: 7px 15px;
    margin-bottom: 10px;

    &:first-child {
        margin-top: 20px;
    }

    &:last-child {
        margin-bottom: 25px;
    }
}

.edit-button {
    float: right;
}

:deep(.checkbox__checkmark) {
    margin-right: 0;
}

.opted-in-container {
    display: flex;
    justify-content: flex-end;
}

.opted-in-badge {
    color: $primary-dark;
    background-color: rgba($primary-brand-color, 0.1);
    font-size: 0.9rem;
    border-radius: 0.5rem;
    padding: 0rem 0.5rem;
    width: 69px;
    font-weight: bold;
}

.section-header {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 2rem;
}

.col {
    display: flex;
    flex-direction: column;
}

.risk-driver {
    margin: 1.5rem 0rem;
}

.assigned-admins-container {
    width: 50%;
    max-width: 50%;
    max-height: 80px;

    .assigned-admins {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        overflow-y: auto;

        .admin {
            width: 100%;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
