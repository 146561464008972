import axios from 'axios';
import { apiErrorHandler } from '../services/api';
import * as Api from './index';

export const listStatuses = async () => {
    const url = await Api.getIntegrationBase('/integration-status');
    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'List Integration Status error'
            })
        );
};
