<template>
    <div class="dashboard-container">
        <div class="school-container">
            <h1>Toggle Auto Integrations</h1>
            <div class="school-table-header">
                <div class="school-header-large">School</div>
                <div class="school-header-large">Username</div>
                <div class="school-header-large">Id</div>
                <div class="school-header-small">Enabled</div>
            </div>
            <div v-if="showLoadingSpinner">
                <LoadingSpinner />
            </div>
            <div class="school-list-container" v-else>
                <div v-for="school in schools" :key="school.schoolId" class="school">
                    <div class="school-details">
                        <div class="school-detail-cell-large">{{ school.name }}</div>
                        <div class="school-detail-cell-large">{{ school.username }}</div>
                        <div class="school-detail-cell-large">{{ school.id }}</div>
                        <div class="school-detail-cell-small">
                            <BaseCheckbox
                                :modelValue="school.enabled"
                                @update:modelValue="handleCheckboxClick(school)"
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    v-if="isModalVisible"
                    title="Confirm Changes"
                    size="medium"
                    primaryButtonTitle="Confirm"
                    @secondary-button-clicked="isModalVisible = false"
                    @primary-button-clicked="confirmToggle"
                >
                    <div>
                        {{ message }}
                    </div>
                </Modal>
            </div>
        </div>
    </div>
</template>

<script>
import * as SchoolApi from '@/api/school';
import { startCase, toLower } from 'lodash';
import Modal from '@/components-deprecated/global/Modal';
import { BaseCheckbox } from '@edsights/ui-core';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';

export default {
    name: 'ToggleAutoIntegrations',
    components: {
        Modal,
        BaseCheckbox,
        LoadingSpinner
    },
    data() {
        return {
            showLoadingSpinner: true,
            selectedSchool: '',
            schools: [],
            enableOrDisable: '',
            isModalVisible: false
        };
    },
    created() {
        this.loadSftpSchoolList();
    },
    methods: {
        async loadSftpSchoolList() {
            this.schools = await SchoolApi.listSchoolsWithStorageBuckets();
            this.showLoadingSpinner = false;
        },
        snakeToTitle(snakeCaseString) {
            return startCase(toLower(snakeCaseString));
        },
        handleCheckboxClick(school) {
            this.selectedSchool = school;
            this.message = `Are you sure you want to toggle SFTP Auto Integrations for ${school.name}?`;
            this.isModalVisible = true;
        },
        async confirmToggle() {
            const response = await SchoolApi.toggleFeatureFlagForSchool({
                payload: {
                    schoolId: this.selectedSchool.id,
                    featureFlagName: this.selectedSchool.featureFlagName,
                    enabled: !this.selectedSchool.enabled
                }
            });
            if (response) {
                this.$Alert.alert({
                    type: 'success',
                    message: `${this.snakeToTitle(this.selectedSchool.featureFlagName)} ${
                        response.enabled ? 'enabled' : 'disabled'
                    }.`,
                    timeout: 3000
                });
                this.selectedSchool.enabled = response.enabled;
                this.isModalVisible = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.school-list-container {
    width: 100%;
}
.school {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.school-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65%;
}
.school-table-header {
    display: flex;
    padding: 10px 20px;
    background-color: #f4f7f9;
    justify-content: space-evenly;
    font-weight: bold;
    width: 100%;
    margin-top: 1rem;
}
.school:hover {
    background-color: #f4f7f9;
}
.school-header-small {
    flex: 0.1;
}
.school-header-large {
    flex: 0.7;
}
.school-details {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 20px;
    width: 100%;
}
.school-detail-cell-small {
    flex: 0.15;
    display: flex;
    align-items: center;
    justify-content: center;
}
.school-detail-cell-large {
    flex: 0.7;
}
</style>
