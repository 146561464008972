<template>
    <modal
        name="modal-add-tag-or-category"
        adaptive
        height="auto"
        width="90%"
        :max-width="1200"
        :click-to-close="true"
        :stack="false"
        @before-open="beforeOpen"
        @before-close="beforeClose"
        tabindex="-1"
    >
        <div class="modal">
            <header class="modal__header">
                <h2 class="modal__header-title">Add Custom Tag or Group</h2>
                <p class="modal__header-description">
                    Add a new tag to organize and manage your students. Determine which type of tag
                    you would like to create, and then create the new tag name. A tag group is a
                    grouping of tags (i.e. "Dorm" is the group and "Jones Hall" is the tag).
                </p>
            </header>
            <div class="modal__content">
                <div class="modal__tag-type">
                    <div style="margin: 1rem 0">Type</div>

                    <div class="modal__tag-type-item" v-if="tagCategories.length > 0">
                        <RadioButton
                            v-model="newTagType"
                            inputId="tag"
                            name="type"
                            label="New Tag"
                            inputVal="tag"
                        />
                    </div>
                    <div class="modal__tag-type-item">
                        <RadioButton
                            v-model="newTagType"
                            inputId="category"
                            name="type"
                            label="New Tag Group"
                            inputVal="category"
                        />
                    </div>
                </div>
                <Input
                    v-if="newTagType"
                    v-model.trim="newTagName"
                    :placeholder="newTagType == 'tag' ? 'Tag Name' : 'Tag Group Name'"
                    class="modal__input"
                />
                <div v-if="error" class="error-msg">{{ error }}</div>

                <div v-if="newTagName && newTagType == 'tag'" class="modal__assign">
                    <div v-if="newTagType == 'tag'" class="modal__assign-header">
                        Assign tags to existing tag group
                    </div>

                    <div class="modal__assign-scroll">
                        <div v-for="(item, i) in existingTagOrCategoryList" :key="i" class="item">
                            <RadioButton
                                :inputId="item.id"
                                :inputVal="item.id"
                                v-model="selectedCategory"
                                name="selectedCategory"
                                :label="item.name"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="newTagName && newTagType == 'category' && tags.length > 0"
                    class="modal__assign"
                >
                    <div v-if="newTagType == 'category'" class="modal__assign-header">
                        Assign existing custom tags
                    </div>

                    <div class="modal__assign-scroll">
                        <div v-for="(item, i) in existingTagOrCategoryList" :key="i" class="item">
                            <BaseCheckbox
                                class="checkbox"
                                :label="item.name"
                                :modelValue="tagIsSelected(item)"
                                @update:modelValue="toggleTagSelection(item)"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal__footer">
                <Button class="cancel-btn" @click="$modal.hide('modal-add-tag-or-category')"
                    >Cancel</Button
                >
                <Button :disabled="loading" class="save-btn" @click="onClickSave">Save</Button>
            </div>
        </div>
    </modal>
</template>

<script>
import TagCategories from '@/services/tagCategories';
import Tag from '@/services/tags';
import { RadioButton } from '@/components-deprecated/inputs/';
import Input from '@/components-deprecated/Input';
import Button from '@/components-deprecated/Button';
import { BaseCheckbox } from '@edsights/ui-core';

export default {
    name: 'ModalAddTagOrCategory',
    components: {
        Input,
        Button,
        BaseCheckbox,
        RadioButton
    },
    props: {
        school: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            newTagType: null, // Can either be 'category' or 'tag'
            newTagName: null,
            selectedCategory: '',
            error: '',
            loading: false,
            selectedTagsToAdd: [],
            selectedTag: '',
            checboxSelected: '',
            tagCategories: [],
            tags: []
        };
    },
    computed: {
        getCustomTagCategories() {
            return this.tagCategories;
        },
        getCustomTags() {
            return this.getCustomTagCategories.reduce((acc, curr) => {
                acc = [...acc, ...curr.tagsRef];
                return acc;
            }, []);
        },

        existingTagOrCategoryList() {
            // If creating a new Tag (this.newTagType == 'tag'), display list of Tag Categories to assign new Tag to.
            // If creating a new Category (this.newTagType == 'category'), display list of Tags to assign new Category to.
            return this.newTagType == 'tag' ? this.tagCategories : this.getCustomTags;
        }
    },
    watch: {},

    methods: {
        async beforeOpen(event) {
            this.tagCategories = event.params.tagCategories;
            this.tags = this.getCustomTags;
        },
        beforeClose() {
            // Reset all necessary data as modal is closing.
            this.newTagType = null;
            this.newTagName = null;
            this.selectedCategory = '';
            this.error = '';
            this.selectedTagsToAdd = [];
        },
        toggleNewTagType(type = null) {
            this.newTagType == type ? (this.newTagType = null) : (this.newTagType = type);
        },
        async onClickSave() {
            if (!this.newTagName) {
                this.error = '*Required';
                return;
            }
            if (this.newTagType == 'tag' && !this.selectedCategory) {
                this.error = 'Please select one group';
                return;
            }

            this.newTagType == 'category' ? this.createNewTagCategory() : this.createNewTag();
        },
        async createNewTagCategory() {
            this.loading = true;
            try {
                const res = await TagCategories.api.createTagCategory({
                    name: this.newTagName,
                    school: this.school
                });
                let id = '';
                if (res.status == 201 && this.selectedTagsToAdd.length > 0) {
                    id = res.data.id;
                    let selected = this.selectedTagsToAdd.map(t => {
                        return {
                            ...t,
                            category: id,
                            school: this.school
                        };
                    });

                    await Tag.api.updateTags(selected);
                }
                // Three actions after a new Category is successfully created:
                // 1. Notify user with success alert.
                this.$Alert.alert({
                    type: 'success',
                    message: '<h2>Tag Group successfully created!</h2>',
                    timeout: 3000
                });
                // 2. Emit event to ManageTags to refresh the page so that new category appears.
                this.$emit('refreshPage');
                // 3. Hide this modal.
                this.$modal.hide('modal-add-tag-or-category');
            } finally {
                this.loading = false;
            }
        },
        async createNewTag() {
            this.loading = true;
            try {
                // Both 'name' and 'category' are required when creating new Tag.
                const newTag = {
                    name: this.newTagName,
                    category: this.selectedCategory,
                    school: this.school
                };
                await Tag.api.createTag(newTag);

                // Three actions after a new Tag is successfully created:
                // 1. Notify user with success alert.
                this.$Alert.alert({
                    type: 'success',
                    message: '<h2>Tag successfully created!</h2>',
                    timeout: 3000
                });
                // 2. Emit event to ManageTags to refresh the page so that new tag appears.
                this.$emit('refreshPage');
                // 3. Hide this modal.
                this.$modal.hide('modal-add-tag-or-category');
            } finally {
                this.loading = false;
            }
        },
        tagIsSelected(tag) {
            return this.selectedTagsToAdd.find(t => t.id === tag.id) ? true : false;
        },
        toggleTagSelection(tag) {
            if (this.tagIsSelected(tag)) {
                this.selectedTagsToAdd = this.selectedTagsToAdd.filter(
                    t => t.id !== tag.id
                );
            } else {
                this.selectedTagsToAdd.push(tag);
            }
        }
    }
};
</script>

<style lang="scss">
@import '../../styles/variables';
@import '../../styles/mixins/buttons';

.modal {
    padding: 1rem 3rem;

    &__header {
        margin-bottom: 1rem;
    }

    &__header-title {
        margin: 2rem 0;
    }

    &__tag-type {
        font-size: 1.2rem;
    }
    &__tag-type-item {
        font-size: 1.1rem;
        margin: 0.5rem 0;
        input {
            margin-right: 1rem;
        }
    }

    &__input {
        max-width: 35rem;
        margin: 1rem 0;
    }

    &__assign {
        padding: 15px 30px;
        background: $off-white;
        border: 2px solid #e5eeed;
        border-radius: 10px;
        margin: 2rem 0;
        max-width: 50rem;
    }
    &__assign-header {
        font-size: 1.1rem;
    }
    &__assign-scroll {
        height: 15rem;
        overflow-y: scroll;
    }

    .item {
        margin: 1rem 0;

        input {
            margin-right: 1rem;
        }
    }

    .error-msg {
        color: $amaranth;
        margin-left: 0.5rem;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        margin: 2rem 0;
    }

    .cancel-btn {
        @include outline-button();
        margin-right: 1rem;
        width: 10rem;
    }

    .save-btn {
        width: 10rem;
    }
}
</style>
