<template>
    <modal
        classes="v--modal"
        name="modal-merge-tags"
        height="auto"
        width="600"
        :clickToClose="true"
        :stack="false"
        @before-open="beforeOpen"
        tabindex="-1"
        aria-labeledby="modal-title"
    >
        <div class="modal">
            <header class="modal-header">
                <h2 id="modal-title" class="modal-header-title">Merge {{ tags.length }} tags</h2>
            </header>
            <div class="modal-input-container">
                <div class="name-input-label">Tags</div>
                <div class="tags-container">
                    <StudentItemTags :tags="tags" />
                </div>
                <div class="name-input-label">New Merged Name</div>
                <ValidatedInput
                    ref="input"
                    v-model="newName"
                    class="name-input"
                    type="text"
                    placeholder="Enter a new name"
                    :regexp="/[A-z0-9 \/\-]{0,30}/"
                />
            </div>
            <div class="button-container">
                <Button styleType="off" type="submit" @click="close()">
                    Cancel
                </Button>
                <loading-spinner-button
                    class="modal-button base-button"
                    text="Save"
                    :loading="loading"
                    type="submit"
                    @click="onRename()"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import Button from '@/components-deprecated/Button';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import StudentItemTags from '@/components-deprecated/tables/StudentItemTags';
import ValidatedInput from '@/components-deprecated/inputs/ValidatedInput';
import Tags from '@/services/tags';

export default {
    name: 'ModalMergeTags',
    components: {
        Button,
        LoadingSpinnerButton,
        StudentItemTags,
        ValidatedInput
    },
    data() {
        return {
            loading: false,
            tags: [],
            newName: ''
        };
    },
    methods: {
        beforeOpen(event) {
            this.newName = '';
            this.tags = event.params.tags;
            this.updateHandler = event.params.updateHandler ? event.params.updateHandler : () => {};
        },
        close() {
            this.$modal.hide('modal-merge-tags');
        },
        onRename() {
            this.loading = true;
            Tags.api
                .mergeTags(this.tags, this.newName)
                .then(() => {
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Tags successfully merged!</h2>',
                        timeout: 3000
                    });
                    this.updateHandler();
                    this.$modal.hide('modal-merge-tags');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.modal {
    padding: 0;
    &-header {
        padding: 2.5rem;
    }
    &-content {
        padding: 24px;
        display: block;
        text-align: right;

        &-text {
            text-align: left;
            color: #000;
        }
    }
    &-button {
        &:last-child {
            margin-left: 1rem;
        }
    }
}

.tags-container {
    padding: 2rem;
    padding-top: 0;
}

.name-input-label {
    margin-left: 15px;
    margin-bottom: 5px;
    color: $accessible-gray;
}

.name-input {
    border-radius: 10px;
    border: 1px solid #1f8bfb;
    padding: 10px;
    margin: 10px;
    margin-top: 0px;
    width: 80%;
    &::placeholder {
        color: $accessible-gray !important;
    }
    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.modal-input-container {
    padding: 2rem;
}

.button-container {
    margin: 2rem;
    display: flex;
    justify-content: flex-end;
}
</style>
