<template>
    <Modal
        class="add-assigned-tag-modal"
        :title="title"
        size="medium"
        primaryButtonTitle="Save"
        :showPrimaryButtonLoadingSpinner="saving"
        @secondary-button-clicked="close"
        @primary-button-clicked="save"
    >
        <LoadingSpinner v-if="loading" />
        <div v-else>
            Select tags to link to limited admin. Students in those tags will be automatically
            assigned to them.
            <br />
            <br />
            Tags:

            <Chips :chips="selectedTagChips" showCloseIcon @chip-clicked="deselectTag">
                <template v-slot:after-chips>
                    <div class="add-create">
                        <Dropdown
                            class="add-label"
                            :options="availableTagOptions"
                            @option-clicked="selectTag"
                            enableSearch
                        >
                            <template v-slot:custom-menu-trigger>
                                <div class="add-item">+</div>
                            </template>
                        </Dropdown>
                    </div>
                </template>
            </Chips>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';
import { listTagsAvailableForAdminAssignment } from '@/api/admin-assigned-tags';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Chips from '@/components-deprecated/global/Chips';
import Dropdown from '@/components-deprecated/global/Dropdown';

export default {
    name: 'AddAssignedTagModal',
    components: {
        LoadingSpinner,
        Modal,
        Chips,
        Dropdown
    },
    props: {
        adminDisplayName: {
            type: String,
            required: true
        },
        adminId: {
            type: String,
            required: true
        },
        saving: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: true,
            availableTags: [],
            selectedTagIds: []
        };
    },
    created() {
        this.loadAvailableTags(this.adminId);
    },
    methods: {
        close() {
            this.$emit('close-add-new-tag-modal');
        },
        save() {
            this.$emit('save-add-new-tag-modal', this.selectedTagIds);
        },
        async loadAvailableTags(adminId) {
            this.availableTags = await listTagsAvailableForAdminAssignment({ adminId });
            this.loading = false;
        },
        selectTag(tagId) {
            this.selectedTagIds.push(tagId);
        },
        deselectTag(tag) {
            this.selectedTagIds = this.selectedTagIds.filter(id => id !== tag.value);
        }
    },
    computed: {
        title() {
            if (typeof this.adminDisplayName === 'string') {
                return `Link tags to ${this.adminDisplayName}`;
            }
            return 'Link tags';
        },
        availableTagOptions() {
            if (Array.isArray(this.availableTags)) {
                // make a copy since .sort() will mutate the original array
                const availableTags = [...this.availableTags];
                return availableTags
                    .sort(
                        (a, b) =>
                            // sort alphabetically with isPublic tags showing first
                            Number(b.isPublic) - Number(a.isPublic) || a.name.localeCompare(b.name)
                    )
                    .reduce((acc, tag) => {
                        if (!this.selectedTagIds.includes(tag.id)) {
                            acc.push({ label: tag.name, value: tag.id });
                        }
                        return acc;
                    }, []);
            }

            return [];
        },
        selectedTagChips() {
            if (Array.isArray(this.availableTags)) {
                return this.availableTags.reduce((acc, tag) => {
                    if (this.selectedTagIds.includes(tag.id)) {
                        acc.push({ label: tag.name, value: tag.id });
                    }
                    return acc;
                }, []);
            }

            return [];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.add-assigned-tag-modal {
    .add-item {
        font-size: 30px;

        background-color: white;
        border: none;
        color: $edsights-blue;
        margin-right: 10px;
    }
}
</style>
