export const TAG_RELATIONSHIP = {
    or: {
        value: 'OR',
        label: 'OR'
    },
    and: {
        value: 'AND',
        label: 'AND'
    }
};
