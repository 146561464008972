import { objectToSnakeCase, objectToCamelCase } from '../utils';
import api from './api';

export default class TagCategoryMappings {
    static api = api.create(TagCategoryMappings);
    static readOnlyFields = ['id'];
    constructor({ id = null, school = null, rawName = null, tagCategory = null } = {}) {
        Object.assign(this, {
            id,
            school,
            rawName,
            tagCategory
        });
    }
    static create(data = {}) {
        return new TagCategoryMappings(data);
    }
    static fromAPI(json = {}) {
        const jsonCamel = objectToCamelCase(json);
        return new TagCategoryMappings(jsonCamel);
    }
    static toAPI(d, fields = [], excludeFields = []) {
        let data = {};
        if (fields.length > 0) {
            fields.forEach(f => {
                data[f] = d[f];
            });
        } else {
            data = d;
        }
        excludeFields = [...TagCategoryMappings.readOnlyFields, ...excludeFields];
        excludeFields.forEach(f => {
            delete data[f];
        });
        return objectToSnakeCase(data);
    }
}
