<template>
    <div class="container">
        <ButtonBack></ButtonBack>

        <h1 class="h2">Manage Alerts</h1>
        <p style="padding-top: 1rem">
            Input your alert contacts and customize the message that students receive when an alert
            is triggered. If you leave an alert contact blank then no alert will be triggered.
        </p>

        <div class="alert-container" v-for="alertType in alertTypes" :key="alertType.id">
            <h2>{{ triggerCategories[alertType.triggerCategory] }} Alert</h2>

            <div class="input-group">
                <label>
                    <span>Alert Admin Emails</span>
                    <p>
                        Alert Admins are your institution's faculty/staff that will be emailed when
                        an Alert is triggered.
                    </p>
                    <input
                        class="input"
                        type="text"
                        placeholder="Separate emails with a comma"
                        v-model="alertType.emailAddresses"
                        v-on:change="updateAlertType(alertType)"
                    />
                </label>
            </div>

            <div class="input-group">
                <label>
                    <span>Customize your Alert Message</span>
                    <p>
                        The Alert Message is the text message that students will receive if they
                        trigger this Alert.
                    </p>
                    <textarea
                        class="input"
                        rows="4"
                        cols="50"
                        v-model="alertType.content"
                        v-on:change="updateAlertType(alertType)"
                    ></textarea>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import AlertType from '@/services/alertTypes';
import triggerCategories from '@/services/alerts/options';
import ButtonBack from '@/components-deprecated/ButtonBack';
import { mapState } from 'vuex';
import { isDbSchoolFeatureFlagEnabled } from '@/lib/feature-flag';

export default {
    name: 'ManageAlerts',
    components: {
        ButtonBack
    },
    data() {
        return {
            alertTypes: [],
            triggerCategories: triggerCategories,
            nodeAlertType: false
        };
    },
    async created() {
        this.nodeAlertType = await this.getNodeAlertType();
        this.alertTypes = await this.getAlertTypes();
    },
    watch: {},
    computed: {
        ...mapState(['user', 'staffSchoolViewEnabled'])
    },
    methods: {
        async getNodeAlertType() {
            const schoolId = this.$store.state.user.schoolRef.id;
            const featureFlagName = 'NODE_ALERT_TYPE';
            return await isDbSchoolFeatureFlagEnabled(schoolId, featureFlagName);
        },
        getPrettyTriggerName(trigger) {
            let prettyTrigger = '';

            switch (trigger) {
                case 'COVID_TRIGGER':
                    prettyTrigger = 'The Covid Alert';
                    break;
                case 'DROPOUT_TRIGGER':
                    prettyTrigger = 'The Dropout Alert';
                    break;
                case 'MENTAL_HEALTH_TRIGGER':
                    prettyTrigger = 'The Mental Health Alert';
                    break;
                case 'VIOLENCE_TRIGGER':
                    prettyTrigger = 'The Violence Alert';
                    break;
                case 'TITLE_IX_TRIGGER':
                    prettyTrigger = 'The Title IX Alert';
                    break;
                default:
                    prettyTrigger = 'An';
            }

            return prettyTrigger;
        },
        async updateAlertType(alertType) {
            if (
                alertType &&
                alertType.emailAddresses &&
                typeof alertType.emailAddresses === 'string' &&
                alertType.emailAddresses.indexOf(';') > -1
            ) {
                this.$Alert.alert({
                    type: 'error',
                    message: `${this.getPrettyTriggerName(
                        alertType.triggerCategory
                    )} email list contains at least one semicolon. All email address lists must separate emails with commas.`,
                    timeout: 10000
                });
            } else {
                if (this.nodeAlertType) {
                    await AlertType.api.updateV2(alertType, [
                        'emailAddresses',
                        'content',
                        'triggerCategory'
                    ]);
                } else {
                    await AlertType.api.update(alertType, ['emailAddresses', 'content']);
                }
                this.alertTypes = await this.getAlertTypes();
            }
        },
        async getAlertTypes() {
            try {
                const params = {};
                if (this.staffSchoolViewEnabled) {
                    params.schools = this.user.school;
                }
                let response = null;
                if (this.nodeAlertType) {
                    response = await AlertType.api.listV2(this.user.school);
                } else {
                    response = await AlertType.api.list(params);
                }

                return response.results;
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: `There was a problem loading this page. Please try again later.`,
                    timeout: 5000
                });
                console.error(error);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.alert-container {
    margin: 2rem;
}

.input-group {
    margin-top: 1.75rem;
    max-width: 75%;

    > p:nth-child(1) {
        font-weight: bold;
        font-size: small;
    }

    > .input {
        border-radius: 0;
        padding: 0.75rem 1rem;
    }
}
</style>
