import store from '../store';
import Alerts from '@/lib/alerts';

// uses v2 node api as a proxy for all v1 api calls if feature flag is set
const apiHostUrl =
    process.env.VUE_APP_FEATURE_FLAG_USE_V1_NODE_PROXY_ENDPOINT === 'true'
        ? process.env.VUE_APP_API_V2
        : process.env.VUE_APP_API_V1;

export const API_BASE = apiHostUrl + '/api/v1/';

/**
 * @function     A generic handler for API Errors.
 * @description  Shows an alert-alert notification for response error codes.
 * @param        error object.
 */
export function apiErrorHandler({
    apiName = '',
    enable400Alert = true,
    enable500Alert = true,
    rethrowErrors = true
}) {
    return error => {
        const { response } = error;

        // Console log for dev debug
        // eslint-disable-next-line no-console
        console.log(`${apiName} Error:`, error);

        // Show error to user
        if (response.status >= 400 && response.status < 500) {
            // Handle 4xx errors (probably bad user input)
            const { data } = response;
            let message = '<h3>Error...</h3>';

            // Handle common error structures
            if (data.detail) {
                message += `<p>${data.detail}</p>`;
            } else if (data.non_field_errors) {
                message += `<p>${data.non_field_errors}</p>`;
            } else if (Array.isArray(data)) {
                data.forEach(error => {
                    message += `<p>${error}</p>`;
                });
            } else {
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        // auth errors come in the form of `{ password: ["list", "of", "errors"] }`
                        if (Array.isArray(data[key])) {
                            let messages = data[key].map(e => `<p>${e}</p>`).join('');
                            message += `${messages}`;
                        } else {
                            message += `<p>${key}: ${data[key]}</p>`;
                        }
                    }
                }
            }

            // eslint-disable-next-line no-console
            console.log('Error data:', message);

            if (enable400Alert) {
                Alerts.alert({
                    type: 'error',
                    message,
                    timeout: 6000
                });
            }
            // Optionally re-raise for further optional error handling
            if (rethrowErrors) {
                throw error;
            }
        }
        if (enable500Alert) {
            // Generic handling for other errors (ex: 500 errors)
            Alerts.alert({
                type: 'error',
                message:
                    '<h3>Error...</h3>' + '<p>Something went wrong! Please try again later.</p>',
                timeout: 6000
            });
        }
        // Optionally re-raise for further optional error handling
        if (rethrowErrors) {
            throw error;
        }
    };
}

export default {
    API_BASE,
    apiErrorHandler
};
