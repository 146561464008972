import axios from 'axios';
import { apiErrorHandler } from '@/services/api';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';

export const resetAdminPassword = async ({ id, password, confirmPassword }) => {
    if (password !== confirmPassword) {
        throw new Error('Passwords must match');
    }

    const url = Api.getv2ApiBase(`/admin/${id}`);
    const defaultOptions = Api.getOptions();

    return axios
        .patch(url, { password }, { ...defaultOptions })
        .then(response => {
            return response.data;
        })
        .catch(apiErrorHandler({ apiName: 'Reset Admin Password' }));
};

export const updateAdminDetails = async ({ id, email, firstName, lastName }) => {
    const url = Api.getv2ApiBase(`/admin/${id}`);
    const defaultOptions = Api.getOptions();

    return axios
        .patch(url, { email, firstName, lastName }, { ...defaultOptions })
        .then(response => {
            return response.data;
        })
        .catch(apiErrorHandler({ apiName: 'Update Admin Details' }));
};

export const updateAdminType = async ({ id, type, canAccessKb, errorHandlerOptions = {} }) => {
    try {
        const url = Api.getv2ApiBase(`/admin/${id}/admin-type`);
        const defaultOptions = Api.getOptions();

        const response = await axios.patch(url, { type, canAccessKb }, { ...defaultOptions });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};
