<template>
    <div class="container">
        <TabsContainer initialTabId="Create School" class="nested-tabs-container">
            <Tab id="Create School">
                <CreateSchoolDashboard />
            </Tab>
            <Tab id="Deactivate School">
                <DeactivateSchool />
            </Tab>
        </TabsContainer>
    </div>
</template>

<script>
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import CreateSchoolDashboard from '@/views/staff/StaffTools/components/DashboardComponents/CreateSchoolDashboard';
import DeactivateSchool from '@/views/staff/StaffTools/components/DashboardComponents/DeactivateSchool';

export default {
    name: 'DashboardTools',
    components: {
        TabsContainer,
        Tab,
        CreateSchoolDashboard,
        DeactivateSchool
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.container {
    :deep() {
        .nested-tabs-container .tabs .tab {
            font-size: 1rem !important;

            &:hover {
                border-bottom: 3px solid #43d1ff !important;
            }

            &.is-active {
                color: #43d1ff !important;
                border-bottom: 3px solid #43d1ff !important;
            }
        }
    }
}
</style>
