<template>
    <div class="tabs-container">
        <div class="tabs">
            <div
                class="tab"
                v-for="tab in tabs"
                :key="tab.id"
                :class="{ 'is-active': tab.isActive }"
                @click="selectTab(tab.id)"
            >
                {{ tab.renderedLabel }}
                <div class="close" v-if="showCloseIcons" @click="$emit('close-tab', tab.id)">
                    &#x2715;
                </div>
            </div>
            <div v-if="showAddTab" class="tab add-item" @click="$emit('add-tab')">
                + <label v-if="addTabLabel" class="add-tab-label">{{ addTabLabel }}</label>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
    name: 'TabsContainer',
    props: {
        // initialTabId can be set to define an initial tab, after which the tabs are managed internally.
        initialTabId: {
            type: String,
            required: false
        },
        showAddTab: {
            type: Boolean,
            default: false
        },
        showCloseIcons: {
            type: Boolean,
            default: false
        },
        addTabLabel: {
            type: String,
            required: false
        },
        disableTabSwitching: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            tabs: [],
            tabsCount: 0
        };
    },
    provide() {
        return {
            registerTab: this.registerTab,
            unregisterTab: this.unregisterTab
        };
    },
    mounted() {
        if (this.initialTabId) {
            this.selectTab(this.initialTabId);
        } else if (this.tabs.length > 0) {
            this.selectTab(this.tabs[0].id);
        }
    },
    methods: {
        registerTab(tab) {
            this.tabs.push(tab);
            this.tabsCount = this.tabs.length;
            if (this.tabsCount === 1 && !this.initialTabId) {
                this.selectTab(tab.id);
            }
        },
        unregisterTab(tabId) {
            this.tabs = this.tabs.filter(tab => tab.id !== tabId);
            this.tabsCount = this.tabs.length;
        },
        selectTab(tabId) {
            if (!this.disableTabSwitching && typeof tabId === 'string') {
                this.tabs.forEach(tab => {
                    tab.isActive = tab.id === tabId;

                    // emit in case the parent is interested in knowing the active tab
                    if (tab.isActive) {
                        this.$emit('tab-selected', tabId);
                    }
                });
            }
        },
        selectLastTab() {
            if (Array.isArray(this.tabs) && this.tabs.length > 0) {
                const lastTabId = this.tabs[this.tabs.length - 1].id;
                this.selectTab(lastTabId);
            }
        }
    },
    watch: {
        tabs(newValue) {
            const newTabsCount = newValue.length;
            const oldTabsCount = this.tabsCount;
            if (newTabsCount > oldTabsCount) {
                this.tabsCount = newTabsCount;
                nextTick(() => {
                    this.selectLastTab();
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.tabs-container {
    width: 100%;
    .tabs {
        padding-bottom: 1rem;

        display: flex;
        flex-wrap: wrap;
        .tab {
            display: flex;
            padding: 10px;
            font-size: 1rem;
            border-bottom: 3px solid $white-blue;
            cursor: pointer;

            &:hover {
                border-bottom: 3px solid #38a0fa;
            }

            &.is-active {
                border-bottom: 3px solid #38a0fa;
                color: #38a0fa;
            }

            &.add-item {
                cursor: pointer;
                color: #38a0fa;
                font-size: 2rem;
                background-color: white;
                border: none;

                label {
                    cursor: pointer;
                    padding-left: 0.5rem;
                    color: $base-font-color;
                    font-size: 1rem;
                }

                &:hover {
                    .add-tab-label {
                        color: #38a0fa;
                    }
                }
            }

            .close {
                margin-left: 1.1rem;
                font-size: 1.3rem;
                font-weight: normal;
                color: $black-alpha-0-5;

                &:hover {
                    color: #38a0fa;
                }
            }
        }
    }
}
</style>
