<template>
    <div class="delete-label-modal-container">
        <Modal
            title="Delete Label"
            primaryButtonTitle="Delete"
            secondaryButtonTitle="Cancel"
            :showPrimaryButtonLoadingSpinner="saving"
            @secondary-button-clicked="onCancel"
            @primary-button-clicked="onSave"
        >
            <div class="modal-content">
                Are you sure you want to delete the label?
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';

export default {
    name: 'DeleteLabelModal',
    components: { Modal },
    props: {
        labelText: {
            type: String,
            required: true
        },
        saving: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        onSave() {
            this.$emit('onSave');
        },
        onCancel() {
            this.$emit('onCancel');
        }
    }
};
</script>

<style lang="scss" scoped>
.delete-label-modal-container {
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>
