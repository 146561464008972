<template>
    <div class="tags">
        <div class="title-wrapper">
            <div class="tags__title">Tags</div>
            <button class="tags__add-tag text-gray" @click="onClickManageTags">
                Manage tags
            </button>
        </div>
        <div class="tags__items">
            <TagItem
                class="tags__tag"
                v-for="tag in tags"
                :key="tag.id"
                :text="tag.name"
                :toolTip="`${tag.categoryRef.name}: ${tag.name}`"
            />
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue';

import TagItem from '@/components-deprecated/TagItem';

export default {
    name: 'StudentBlockInfoTags',
    components: {
        TagItem
    },
    props: {
        tags: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        onClickManageTags() {
            this.$emit('add');

            nextTick(() => {
                const manageTagsModal = document.querySelector('.vm--container');
                if (manageTagsModal) {
                    manageTagsModal.focus();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.tags__add-tag {
    background: transparent;
    border: 0;

    &:focus {
        outline: 2px solid $edsights-blue;
        outline-offset: 2px;
    }
}

.tags {
    &__title {
        text-transform: uppercase;
    }

    &__add-tag {
        cursor: pointer;
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
    }

    &__tag {
        width: 48%;
        margin-bottom: 10px;
        margin-right: 2rem;
    }
}
</style>
