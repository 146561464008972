import User from '../users/models';
import api from './api';
import options from './options';

import Tag from '../tags';
import Note from '../notes';
import Insight from '../insights';

import { objectToCamelCase, objectToSnakeCase } from '../utils';
import { cleanNullOrNumber } from '../numbers';

export default class Student extends User {
    static api = api.create(Student);
    static options = options;
    static excludeFields = [];

    constructor({
        id = null,
        displayName = null,
        email = '',
        dateJoined = '',
        phoneNumber = '',
        firstName = '',
        lastName = '',
        isAdmin = false,
        school = null,
        schoolRef = null,
        optedOutOfSms = null,
        academicRiskLevel = null,
        wellnessRiskLevel = null,
        engagementRiskLevel = null,
        financialRiskLevel = null,
        overallRiskLevel = null,
        tagsRef = [],
        notes = [],
        lastUpdateAuthor = null,
        insights = [],
        overallRiskPercentile,
        financialRiskPercentile,
        academicRiskPercentile,
        engagementRiskPercentile,
        wellnessRiskPercentile,
        overallRiskPoints,
        academicRiskPoints,
        engagementRiskPoints,
        financialRiskPoints,
        wellnessRiskPoints,
        assignedAdmins = [],
        studentId = null,
        phase = null
    }) {
        super();
        Object.assign(this, {
            id,
            displayName,
            email,
            dateJoined: new Date(dateJoined),
            phoneNumber,
            firstName,
            lastName,
            isAdmin,
            school,
            schoolRef,
            optedOutOfSms,
            academicRiskLevel,
            wellnessRiskLevel,
            engagementRiskLevel,
            financialRiskLevel,
            overallRiskLevel,
            tagsRef: tagsRef.map(Tag.fromAPI),
            notes: notes.map(Note.fromAPI),
            lastUpdateAuthor: lastUpdateAuthor != null ? User.fromAPI(lastUpdateAuthor) : null,
            insights: insights.map(Insight.fromAPI),
            financialInsights: insights.filter(insight => insight.type === 'FINANCIAL'),
            academicInsights: insights.filter(insight => insight.type === 'ACADEMIC'),
            engagementInsights: insights.filter(insight => insight.type === 'ENGAGEMENT'),
            beliefInsights: insights.filter(insight => insight.type === 'BELIEFS'),
            overallRiskPercentile: cleanNullOrNumber(overallRiskPercentile),
            financialRiskPercentile: cleanNullOrNumber(financialRiskPercentile),
            academicRiskPercentile: cleanNullOrNumber(academicRiskPercentile),
            engagementRiskPercentile: cleanNullOrNumber(engagementRiskPercentile),
            wellnessRiskPercentile: cleanNullOrNumber(wellnessRiskPercentile),
            overallRiskPoints: cleanNullOrNumber(overallRiskPoints),
            academicRiskPoints: cleanNullOrNumber(academicRiskPoints),
            engagementRiskPoints: cleanNullOrNumber(engagementRiskPoints),
            financialRiskPoints: cleanNullOrNumber(financialRiskPoints),
            wellnessRiskPoints: cleanNullOrNumber(wellnessRiskPoints),
            assignedAdmins,
            studentId,
            // replace the underscores with a space keep them uppercase otherwise
            // they are sent to the backend incorreclty when trying to update
            phase: phase ? phase.replace(/_/g, ' ') : null
        });
    }

    get financialRiskPointsPercents() {
        return (this.financialRiskPoints * 100) / this.overallRiskPoints;
    }

    get academicRiskPointsPercents() {
        return (this.academicRiskPoints * 100) / this.overallRiskPoints;
    }

    get engagementRiskPointsPercents() {
        return (this.engagementRiskPoints * 100) / this.overallRiskPoints;
    }

    get wellnessRiskPointsPercents() {
        return (this.wellnessRiskPoints * 100) / this.overallRiskPoints;
    }

    static create(opts = {}) {
        return new Student(opts);
    }

    static fromAPI(json = {}) {
        const data = objectToCamelCase(json);
        const student = new Student(data);
        return student;
    }

    static toAPI(data) {
        this.excludeFields.forEach(item => {
            delete data[item];
        });
        return objectToSnakeCase(data);
    }

    copy() {
        return new Student(this);
    }
}

/*
 *  CS 07/19 TODO: This class was created with the intent of factoring out how category and risk level scores are computed
 *  from summary student data across the whole school.
 *
 */
export class ScoreManager {
    constructor(scores) {
        this.scores = scores;
    }

    get keys() {
        return Object.keys(this.scores);
    }

    get sum() {
        return this.keys.reduce((sum, key) => sum + this.scores[key], 0);
    }

    get asPercents() {
        return this.keys.reduce((acc, key) => {
            acc[key] = (this.scores[key] / this.sum) * 100;
            return acc;
        }, {});
    }
}
