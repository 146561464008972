<!--
To use a custom menu trigger, pass a template as a child of Dropdown, like below:

<Dropdown
  :options="options"
  @option-clicked="handleDropdownClick"
>
  <template v-slot:custom-menu-trigger>
    custom trigger here
  </template>
</Dropdown>

-->
<template>
    <div class="dropdown-container" tabindex="0" @click.stop @focusout="handleFocusOut">
        <div class="menu-trigger-wrapper" :class="{ disabled }" @click.stop="toggleMenu()">
            <slot name="custom-menu-trigger">
                <!-- custom-menu-trigger template renders here, if provided -->

                <!-- Standard  element (discarded by Vue when a custom-menu-trigger template is provided) -->
                <div class="default-menu-trigger">
                    <label :class="{ useStyledLabel }"> {{ label }} </label>
                    <div>
                        <svg width="10px" height="10px" class="arrow-icon" viewBox="0 0 5.9 17.51">
                            <use xlink:href="#icon-arrow-right-wide" />
                        </svg>
                    </div>
                </div>
            </slot>
        </div>
        <TransitionExpand>
            <div :class="['menu-container', menuContainerAlignmentClass]" v-if="showMenu">
                <div v-if="enableSearch" class="search-container">
                    <TextInput
                        :placeholder="searchPlaceholder"
                        iconType="search"
                        v-model="optionLabelSearch"
                        autoFocus
                    />
                </div>
                <div class="menu-item-container">
                    <LoadingSpinner v-if="showLoadingMenu" />
                    <div
                        v-else
                        :key="option.value"
                        v-for="option in renderedOptions"
                        class="menu-item"
                        :class="{
                            active: activeOptions.includes(option.value),
                            disabled: option.disabled
                        }"
                        @click.stop="onOptionClick(option)"
                    >
                        {{ option.label }}
                    </div>
                </div>
            </div>
        </TransitionExpand>
    </div>
</template>

<script>
import TransitionExpand from '@/components-deprecated/TransitionExpand';
import TextInput from '@/components-deprecated/global/TextInput';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';

export default {
    name: 'Dropdown',
    components: { TextInput, TransitionExpand, LoadingSpinner },
    props: {
        options: {
            type: Array,
            required: true,
            validator: function(options) {
                return options.every(
                    o =>
                        typeof o.label === 'string' &&
                        (typeof o.value === 'string' ||
                            typeof o.value === 'number' ||
                            typeof o.value === 'boolean' ||
                            o.value === null) &&
                        (o.disabled === undefined || typeof o.disabled === 'boolean')
                );
            }
        },
        label: {
            type: String,
            required: false
        },
        activeOptions: {
            // array of option values to highlight as selected - can be single or multi-select
            type: Array,
            required: false,
            default: () => []
        },
        enableSearch: {
            type: Boolean,
            default: false
        },
        searchPlaceholder: {
            type: String,
            default: 'Search...'
        },
        showLoadingMenu: {
            type: Boolean,
            default: false
        },
        // determines menu alignment relative to the trigger
        alignMenu: {
            type: String,
            default: 'left',
            validator: function(value) {
                return ['left', 'right'].includes(value);
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // the majority of usages use a styled label. set useStyledLabel to false if the context needs an unstyled label
        useStyledLabel: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            showMenu: false,
            optionLabelSearch: ''
        };
    },
    methods: {
        toggleMenu() {
            if (!this.disabled) {
                this.showMenu = !this.showMenu;

                // if menu is opening and search is enabled, and a search value already exists,
                // clear the search value
                if (this.showMenu && this.enableSearch && this.optionLabelSearch !== '') {
                    this.optionLabelSearch = '';
                }
            }
        },
        onOptionClick(option) {
            if (option.disabled !== true) {
                this.$emit('option-clicked', option.value);
                this.showMenu = false;
            }
        },
        handleFocusOut(event) {
            // if the target receiving focus (event.relatedTarget) is not within this.$el, click was outside - close menu.
            if (this.showMenu && !this.$el.contains(event.relatedTarget)) {
                this.showMenu = false;
            }
        }
    },
    computed: {
        renderedOptions() {
            if (
                this.enableSearch &&
                typeof this.optionLabelSearch === 'string' &&
                this.optionLabelSearch.trim().length &&
                Array.isArray(this.options)
            ) {
                return this.options.filter(o =>
                    (o.label || '')
                        .trim()
                        .toLowerCase()
                        .includes(this.optionLabelSearch.trim().toLowerCase())
                );
            }

            return this.options;
        },
        menuContainerAlignmentClass() {
            if (this.alignMenu === 'left' || this.alignMenu === 'right') {
                return `align-${this.alignMenu}`;
            }

            return 'align-left';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.dropdown-container {
    position: relative;
    font-family: $base-font-family;
    .menu-trigger-wrapper {
        cursor: pointer;

        &.disabled {
            cursor: default;
            .default-menu-trigger {
                label {
                    cursor: default;
                }
            }
        }
        .default-menu-trigger {
            display: flex;
            height: 32px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: $white-blue;
            padding: 0.25rem 1rem;
            border-radius: 5px;

            label {
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.disabled {
                    cursor: default;
                }

                &.useStyledLabel {
                    color: $main-gray;
                }
            }

            .arrow-icon {
                transform: rotate(90deg);
            }
        }
    }
    .menu-container {
        @include box-shadow;
        position: absolute;
        z-index: 1000;
        background-color: $white;

        min-width: 16rem;

        &.align-left {
            left: 0;
        }

        &.align-right {
            right: 0;
        }

        .search-container {
            padding: 0.75rem;
        }
        .menu-item-container {
            max-height: 25rem;
            overflow-y: auto;

            .menu-item {
                cursor: pointer;
                padding: 0.75rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover:not(.disabled),
                &.active {
                    background-color: $white-blue;
                }

                &.disabled {
                    color: gray;
                    cursor: default;
                }
            }
        }
    }
}
</style>
