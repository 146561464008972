<template>
    <div class="student-filter-container">
        <div class="row" v-if="shouldShowFilterChips">
            <Chips
                :chips="filterChips"
                :showCloseIcon="!readOnly"
                @chip-clicked="chip => onFilterClick(chip.filterKey, chip.value)"
            />
        </div>
        <div class="filters" v-if="!readOnly">
            <div class="row">
                <div class="input-container">
                    <Dropdown
                        :label="`Filter by ${filter.schoolId.label}`"
                        :options="options.schoolId"
                        :activeOptions="filter.schoolId.values"
                        @option-clicked="value => onFilterClick('schoolId', value)"
                        :showLoadingMenu="!options.schoolId.length"
                        enableSearch
                    />
                </div>
                <div class="input-container" v-if="schoolIsSelected">
                    <Dropdown
                        :label="`Filter by ${filter.phases.label}`"
                        :options="options.phases"
                        :activeOptions="filter.phases.values"
                        @option-clicked="value => onFilterClick('phases', value)"
                    />
                </div>
                <div v-if="schoolIsSelected" class="input-container">
                    <Dropdown
                        :label="`Filter by ${filter.messagingTrackIds.label}`"
                        :options="options.messagingTrackIds"
                        :activeOptions="filter.messagingTrackIds.values"
                        @option-clicked="value => onFilterClick('messagingTrackIds', value)"
                    />
                </div>
                <div class="input-container" v-if="schoolIsSelected">
                    <Dropdown
                        :label="`Filter by ${filter.tagIds.label}`"
                        :options="options.tagIds"
                        :activeOptions="filter.tagIds.values"
                        @option-clicked="value => onFilterClick('tagIds', value)"
                        :showLoadingMenu="!options.tagIds.length"
                        enableSearch
                    />
                </div>
                <div
                    class="input-container tag-relationship"
                    v-if="schoolIsSelected && showTagRelationship"
                >
                    <Dropdown
                        label="Tag Relationship"
                        :options="options.tagRelationship"
                        :activeOptions="filter.tagRelationship.values"
                        @option-clicked="value => onFilterClick('tagRelationship', value)"
                        :showLoadingMenu="!options.tagIds.length"
                    />
                </div>
            </div>

            <div class="row" v-if="schoolIsSelected">
                <div class="input-container">
                    <Dropdown
                        :label="`Filter by ${filter.firstTimeEngager.label}`"
                        :options="options.firstTimeEngager"
                        :activeOptions="filter.firstTimeEngager.values"
                        @option-clicked="value => onFilterClick('firstTimeEngager', value)"
                    />
                </div>
                <div class="input-container">
                    <Dropdown
                        :label="`Filter by ${filter.riskLevels.label}`"
                        :options="options.riskLevels"
                        :activeOptions="filter.riskLevels.values"
                        @option-clicked="value => onFilterClick('riskLevels', value)"
                    />
                </div>
                <div class="input-container">
                    <Dropdown
                        :label="`Filter by ${filter.riskDrivers.label}`"
                        :options="options.riskDrivers"
                        :activeOptions="filter.riskDrivers.values"
                        :showLoadingMenu="!options.riskDrivers.length"
                        @option-clicked="value => onFilterClick('riskDrivers', value)"
                        enableSearch
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from '@/components-deprecated/global/Dropdown';
import { USER_FIRST_TIME_ENGAGER, USER_PHASE } from '@/consts/user';
import { RISK_CATEGORIES, RISK_LEVELS } from '@/consts/risk';
import { TAG_RELATIONSHIP } from '@/consts/tag';
import Chips from '@/components-deprecated/global/Chips';
import { getStudentFilterStudentCount } from '@/api/student-filter';

export default {
    name: 'StudentFilter',
    components: {
        Chips,
        Dropdown
    },
    props: {
        studentFilter: {
            type: Object
        },
        riskBuckets: {
            type: Array,
            default: () => []
        },
        tags: {
            type: Array,
            default: () => []
        },
        schools: {
            type: Array,
            default: () => []
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        messagingTracks: {
            type: Array,
            default: () => []
        },
        isMultiMessageTrackEnabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            studentCount: 0,
            options: {
                schoolId: [],
                tagIds: [],
                tagRelationship: Object.values(TAG_RELATIONSHIP),
                phases: Object.values(USER_PHASE),
                firstTimeEngager: Object.values(USER_FIRST_TIME_ENGAGER),
                riskLevels: Object.values(RISK_LEVELS),
                riskDrivers: [],
                messagingTrackIds: []
            },
            filter: {
                schoolId: {
                    label: 'School',
                    values: [],
                    isMultiSelect: false
                },
                tagRelationship: {
                    label: 'Tag Relationship',
                    values: [],
                    isMultiselect: false
                },
                tagIds: {
                    label: 'Tag',
                    values: [],
                    isMultiSelect: true
                },
                phases: {
                    label: 'Phase',
                    values: [],
                    isMultiSelect: true
                },
                messagingTrackIds: {
                    label: 'Messaging Track',
                    values: [],
                    isMultiSelect: this.isMultiMessageTrackEnabled
                },
                firstTimeEngager: {
                    label: 'First Time Engager',
                    values: [],
                    isMultiSelect: false
                },
                riskLevels: {
                    label: 'Risk Level',
                    values: [],
                    isMultiSelect: true
                },
                riskDrivers: {
                    label: 'Risk Driver',
                    values: [],
                    isMultiSelect: false
                }
            },
            initialFilterPopulated: false
        };
    },
    computed: {
        shouldShowFilterChips() {
            return (
                this.schoolIsSelected &&
                Array.isArray(this.filterChips) &&
                this.filterChips.length > 0
            );
        },
        filterChips() {
            const chips = [];

            Object.keys(this.filter).forEach(filterKey => {
                this.filter[filterKey].values.forEach(value => {
                    const option = this.options[filterKey].find(item => item.value === value);
                    if (filterKey === 'tagRelationship') {
                        if (!this.showTagRelationship) {
                            return;
                        }
                    }

                    if (option) {
                        chips.push({
                            label: `${this.filter[filterKey].label}: ${option.label}`,
                            value,
                            filterKey
                        });
                    }
                });
            });

            return chips;
        },
        schoolIsSelected() {
            return !!(this.filter && this.filter.schoolId.values.length === 1);
        },
        showTagRelationship() {
            return (
                typeof this.filter === 'object' &&
                typeof this.filter.tagIds === 'object' &&
                Array.isArray(this.filter.tagIds.values) &&
                this.filter.tagIds.values.length >= 2
            );
        }
    },
    methods: {
        onFilterClick(filterKey, newValue) {
            if (!this.readOnly) {
                const { isMultiSelect, values: previousValues } = this.filter[filterKey];

                if (this.filter[filterKey].values.includes(newValue)) {
                    this.filter[filterKey].values = this.filter[filterKey].values.filter(
                        value => value !== newValue
                    );
                } else {
                    this.filter[filterKey].values = isMultiSelect
                        ? [...this.filter[filterKey].values, newValue]
                        : [newValue];
                }

                // if changing school, reset selected tagIds and tagRelationship:
                if (filterKey === 'schoolId') {
                    this.filter.tagIds.values = [];
                    this.filter.tagRelationship.values = [];
                    this.filter.messagingTrackIds.values = [];
                }

                if (filterKey === 'tagIds') {
                    // There are no tags. Automatically clear the tagRelationship.
                    if (this.filter.tagIds.values.length === 0) {
                        this.filter.tagRelationship.values = [];
                    }

                    // There was previously more or less than 1 tag and now there's 1.
                    // Automatically set the tagRelationship to "or" so the user doesn't need to
                    // pick one manually. The "or" and "and" options are functionally the same with
                    // one tag - there's no special reason for choosing "or".
                    if (previousValues.length !== 1 && this.filter.tagIds.values.length === 1) {
                        this.filter.tagRelationship.values = [TAG_RELATIONSHIP.or.value];
                    }

                    // There was previously 1 tag and now there's more. Clear the tagRelationship
                    // so the user has to consciously pick an option. This way we don't just save
                    // the automatically set value from the above case.
                    if (previousValues.length === 1 && this.filter.tagIds.values.length > 1) {
                        this.filter.tagRelationship.values = [];
                    }
                }

                this.emitFilter();
            }
        },
        getFormattedFilter() {
            const formattedFilter = {
                schoolId: null,
                firstTimeEngager: null,
                riskLevels: null,
                tagIds: null,
                tagRelationship: null,
                messagingTrackIds: null
            };

            // format generic keys
            Object.keys(formattedFilter).forEach(key => {
                const filter = this.filter[key];
                formattedFilter[key] = filter.isMultiSelect
                    ? filter.values
                    : filter.values.length === 1
                    ? filter.values[0]
                    : null;
            });

            // format phases - when phases is a blank array, set it to null.
            // this is the only filter like this - others are sent as empty arrays.
            // this is done to match what the old Chatbot Builder would send to the BE (and what would be saved in the DB).
            formattedFilter.phases =
                Array.isArray(this.filter.phases.values) && this.filter.phases.values.length
                    ? this.filter.phases.values
                    : null;

            // format riskCategory and riskBucketId - they are determined by filters.riskDriver
            if (this.filter.riskDrivers.values.length === 1) {
                // figure out if category or bucket
                const riskDriverOption = this.options.riskDrivers.find(
                    option => option.value === this.filter.riskDrivers.values[0]
                );

                if (riskDriverOption && riskDriverOption.isCategory) {
                    formattedFilter.riskCategory = this.filter.riskDrivers.values[0];

                    // clear any previous riskBucketId - studentFilter can have either riskCategory or riskBucketId
                    formattedFilter.riskBucketId = null;
                } else if (riskDriverOption && !riskDriverOption.isCategory) {
                    formattedFilter.riskBucketId = this.filter.riskDrivers.values[0];

                    // clear any previous riskCategory - studentFilter can have either riskCategory or riskBucketId
                    formattedFilter.riskCategory = null;
                }
            } else {
                // clear riskCategory abd riskBucketId when no riskDriver is selected
                formattedFilter.riskCategory = null;
                formattedFilter.riskBucketId = null;
            }

            return {
                ...formattedFilter,
                id: this.studentFilter.id
            };
        },
        populateInitialFilter(studentFilter) {
            if (typeof studentFilter === 'object') {
                if (studentFilter.schoolId) {
                    this.filter.schoolId.values = [studentFilter.schoolId];
                }

                if (typeof studentFilter.firstTimeEngager === 'boolean') {
                    this.filter.firstTimeEngager.values = [studentFilter.firstTimeEngager];
                }

                if (studentFilter.phases) {
                    this.filter.phases.values = studentFilter.phases;
                }

                if (studentFilter.tagIds) {
                    this.filter.tagIds.values = studentFilter.tagIds;
                }

                if (studentFilter.tagRelationship) {
                    this.filter.tagRelationship.values = [studentFilter.tagRelationship];
                }

                if (studentFilter.riskLevels) {
                    this.filter.riskLevels.values = studentFilter.riskLevels;
                }

                if (studentFilter.riskBucketId) {
                    this.filter.riskDrivers.values = [studentFilter.riskBucketId];
                } else if (studentFilter.riskCategory) {
                    this.filter.riskDrivers.values = [studentFilter.riskCategory];
                }

                if (this.isMultiMessageTrackEnabled && studentFilter.messagingTrackIds) {
                    this.filter.messagingTrackIds.values = studentFilter.messagingTrackIds;
                } else if (!this.isMultiMessageTrackEnabled && studentFilter.messagingTrackId) {
                    this.filter.messagingTrackIds.values = [studentFilter.messagingTrackId];

                    // While we transition from messagingTrackId to messagingTrackIds on the
                    // back end, this component is working with messagingTrackIds only,
                    // which gets translated above. When the filter is output in
                    // this.emitFilter, and this.isMultiMessageTrackEnabled is false,
                    // this.getFormattedFilter formats messagingTrackIds back to a string, but it's
                    // still called messagingTrackIds.

                    // The translation in this block from messagingTrackId to messagingTrackIds
                    // only affects this component's internal data, so unless a user interacts with
                    // this StudentFilter component and this.emitFilter is called, the parent
                    // ChatbotFlowBuilder does not know about this translation. So when a flow is
                    // saved with no change to the filter, messagingTrackIds is not found in
                    // ChatbotFlowBuilder's formatPayload function and gets sent as null to the API.
                    this.emitFilter();
                }

                this.initialFilterPopulated = true;
            }
        },
        async emitFilter() {
            this.$emit('filter-loading', true);
            const studentFilter = this.getFormattedFilter();
            let studentCount = 0;

            if (studentFilter.schoolId) {
                studentCount = await getStudentFilterStudentCount(studentFilter);
            }

            this.$emit('filter-changed', { ...studentFilter, studentCount });
            this.$emit('filter-loading', false);
        }
    },
    watch: {
        isMultiMessageTrackEnabled: {
            immdediate: true,
            handler(newValue) {
                this.filter.messagingTrackIds.isMultiSelect = newValue;
            }
        },
        messagingTracks: {
            immdediate: true,
            handler(newValue) {
                if (Array.isArray(newValue)) {
                    this.options.messagingTrackIds = [...newValue];
                }
            }
        },
        tags: {
            immediate: true,
            handler(newValue) {
                if (Array.isArray(newValue)) {
                    this.options.tagIds = [...newValue]
                        .sort((a, b) => {
                            // sort alphabetically with isPublic tags showing first
                            return (
                                Number(b.isPublic) - Number(a.isPublic) ||
                                a.name.localeCompare(b.name)
                            );
                        })
                        .map(tag => ({
                            label: tag.name,
                            value: tag.id
                        }));
                }
            }
        },
        schools: {
            immediate: true,
            handler(newValue) {
                if (Array.isArray(newValue)) {
                    this.options.schoolId = newValue.map(school => ({
                        label: school.name,
                        value: school.id
                    }));
                }
            }
        },
        riskBuckets: {
            immediate: true,
            handler(newValue) {
                if (Array.isArray(newValue)) {
                    // risk driver options are comprised of both the risk bucket categories and the risk buckets themselves
                    const riskBucketCategoryOptions = Object.values(RISK_CATEGORIES).map(
                        category => ({
                            ...category,
                            isCategory: true
                        })
                    );

                    const riskBucketOptions = this.riskBuckets.map(riskBucket => {
                        return {
                            value: riskBucket.id,
                            label: `${riskBucket.category}: ${riskBucket.name}`,
                            isCategory: false
                        };
                    });

                    this.options.riskDrivers = [...riskBucketCategoryOptions, ...riskBucketOptions];
                }
            }
        },
        studentFilter: {
            immediate: true,
            handler(newValue) {
                if (
                    !this.initialFilterPopulated &&
                    typeof newValue === 'object' &&
                    Object.keys(newValue).length
                ) {
                    this.populateInitialFilter(newValue);
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.student-filter-container {
    .row {
        display: flex;
        flex-direction: row;
        margin: 1rem 0;

        .input-container {
            width: 22%;
            position: relative;
            align-items: center;
            margin-right: 2rem;
        }

        .tag-relationship {
            width: 14rem;
        }
    }
}
</style>
