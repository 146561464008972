<template>
    <BoxContainer class="resource" :subtitle="resourceTitle">
        <div class="resource__content">
            <div class="resource__description" v-if="resourceDescription">
                {{ resourceDescription }}
            </div>
            <ResourceDetailItem
                v-for="topic in resourceTopics"
                :title="topic.title"
                :description="topic.description"
                :subtopics="topic.subtopics"
                :copyButton="topic.copyButton"
                @copy="onCopyTopic(topic.description)"
                :buttonText="topic.buttonText"
                :buttonLink="topic.buttonLink"
                :iconName="topic.iconName"
                :imageName="topic.imageName"
                :fullScreenImage="topic.fullScreenImage"
                :key="topic.title"
                :buttonPosition="topic.buttonPosition"
            />
        </div>
    </BoxContainer>
</template>

<script>
import copy from 'copy-to-clipboard';

import BoxContainer from '@/components-deprecated/BoxContainer';
import ResourceDetailItem from '@/components-deprecated/ResourceDetailItem';

export default {
    name: 'ResourceDetail',

    components: {
        BoxContainer,
        ResourceDetailItem
    },

    props: {
        resourceTitle: {
            type: String,
            default: 'Resource'
        },

        resourceDescription: {
            type: String,
            default: null
        },
        buttonLink: {
            type: String,
            default: null
        },
        copyButton: {
            type: Boolean,
            default: null
        },
        buttonText: {
            type: String,
            default: 'Copy'
        },
        resourceTopics: {
            type: Array,
            default: () => []
        }
    },

    methods: {
        onCopyTopic(description) {
            // Clear HTML tags
            const newDescription = description.replace(/<br>/g, '\n');
            const div = document.createElement('div');
            div.innerHTML = newDescription;
            copy(div.innerText);
            this.$Alert.alert({
                type: 'success',
                message: '<h2>Content copied to your clipboard!</h2>',
                timeout: 3000
            });
        },
        generateSubtitle(subtitle) {
            return subtitle[0].subSection;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.resource {
    &__content {
        padding: 25px 30px;
    }
    &__image {
        display: block;
        width: 100%;
        margin: 25px 0;
        border-radius: 25px;
    }
    &__description {
        padding: 0 15px 25px 15px;
        border-bottom: 2px solid $border-color;
    }
}
</style>
