import axios from 'axios';
import Auth from '../services/auth';

const analyticsEndpoint = process.env.VUE_APP_ANALYTICS_URL;
const edsightsStaff = 'EDSIGHTS-STAFF';

// This function determines whether or not we should skip a report. It's acting
// as a validation to make sure we have all the required data necessary before
// attempt to report. We're being over the top here because we want to avoid any
// disruption to the primary application.
const shouldSkip = ({ router, store }) => {
    if (
        !router ||
        !store ||
        !analyticsEndpoint ||
        !store.getters.userIsLoggedIn ||
        !store.getters.user.id ||
        !store.getters.user.schoolRef ||
        !store.getters.user.schoolRef.id ||
        !store.getters.user.schoolRef.name
    ) {
        return true;
    }

    return false;
};

// This function is part of the logic necessary to reduce the amount of times we
// report a group. We're required to do this in order to get group level
// reporting, however, we also don't want to do this for every single navigation
// event.
const shouldReportGroup = ({ groupReported, schoolId, schoolName }) => {
    if (!groupReported && schoolId && schoolName) {
        return true;
    }

    return false;
};

// This function reports groups.
const reportGroup = params => {
    const data = {
        url: params.to.fullPath,
        userId: params.userId,
        schoolId: params.schoolId,
        schoolName: params.schoolName
    };

    // we should use static values whenever we encounter a staff user who is
    // currently using the staff pages; we're doing this because we don't want
    // to erroneously attribute navigation events to the last school a staff
    // member visited
    if (params.isStaff && !params.staffSchoolViewEnabled) {
        data.schoolId = edsightsStaff;
        data.schoolName = edsightsStaff;
    }

    const options = Auth.getHeaders();
    axios.post(`${analyticsEndpoint}/posthog-group`, data, options).catch(err => {
        console.error(err);
    });
};

// Note that we're leaning on the next function to manage posthog reporting;
// there's indication that next isn't preferable in newer code bases but, for
// now, we're sticking with next because we're using it in several places
// https://router.vuejs.org/guide/advanced/navigation-guards#Optional-third-argument-next
// Also note that we're using a nested if/else structure because that matches
// the Vue documentation's version of a "GOOD" implementation. Long term, it is
// likely we'll stop using next altogether.
export const initiatePosthogTracking = ({ router, store }) => {
    // since initiatePosthogTracking is called once on application bootup, we
    // start with the understanding that we haven't yet reported the group
    let groupReported = false;

    // We need to subscribe to this mutation because we need to know when staff
    // navigate between schools. This allows us to correctly report staff
    // traffic to the correct group.
    store.subscribe((mutation, state) => {
        if (mutation.type === 'setStaffSchoolViewEnabled') {
            try {
                reportGroup({
                    to: { fullPath: router.currentRoute.fullPath },
                    userId: state.user.id,
                    schoolId: state.user.schoolRef.id,
                    schoolName: state.user.schoolRef.name,
                    isStaff: state.user.isStaff,
                    staffSchoolViewEnabled: state.staffSchoolViewEnabled
                });
            } catch (err) {
                console.error(err);
            }
        }
    });

    router.beforeEach((to, from, next) => {
        // stop processing if required data is missing
        if (shouldSkip({ router, store })) {
            next();
        } else {
            const userId = store.getters.user.id;
            const schoolId = store.getters.user.schoolRef.id;
            const schoolName = store.getters.user.schoolRef.name;
            const isStaff = store.getters.user.isStaff;
            const { staffSchoolViewEnabled } = store.state;

            // here we use the outer scope of initiatePosthogTracking to hold on
            // to a boolean that tells us whether or not we've already reported
            // the group data; once we report it, we update the boolean to
            // prevent any further reports of group data for admins - staff will
            // still report under the right conditions
            if (shouldReportGroup({ groupReported, schoolId, schoolName })) {
                try {
                    reportGroup({
                        userId,
                        schoolId,
                        schoolName,
                        isStaff,
                        staffSchoolViewEnabled,
                        to
                    });
                } catch (err) {
                    console.error(err);
                }

                // set reported to true because we want to reduce the number of
                // times we're reporting the group to posthog
                groupReported = true;
            }

            // post the navigation change to posthog
            const data = {
                url: to.fullPath,
                userId,
                schoolId,
                schoolName
            };

            if (isStaff && !staffSchoolViewEnabled) {
                data.schoolId = edsightsStaff;
                data.schoolName = edsightsStaff;
            }

            const options = Auth.getHeaders();

            // no need to wait for the response here as we don't want to block
            // the main app path
            axios.post(`${analyticsEndpoint}/posthog-proxy`, data, options).catch(err => {
                console.error(err);
            });

            next();
        }
    });
};
