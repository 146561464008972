import * as userGetters from './user';
import { csrfToken } from './csrf';

import axios from 'axios';
import Auth from '@/services/auth';
import CSRF from '@/services/csrf';

export default {
    apiClient,
    ...userGetters,
    csrfToken
};

/**
 * Get the axios API client.
 *
 * This conveniently sets the `baseURL` and `headers` for the API client,
 * so that we don't have to do this in every function that needs to call
 * the API.
 *
 * @param {object} state - The Vuex state, passed to all getters by default.
 * @param {object} getters - The Vuex getters, passed to all getters by default.
 *
 * @returns {object} - An instance of the axios API client.
 */
export function apiClient(state, getters) {
    const { location } = window;
    return axios.create({
        baseURL: `${location.protocol}//${location.host}/api/v1/`,
        headers: {
            ...CSRF.getHeaders(),
            ...Auth.getHeaders().headers
        }
    });
}
