<template>
    <input
        :value="modelValue"
        :name="name"
        :type="type"
        :placeholder="placeholder"
        :label="label"
        @focusout="focusout()"
        @input="input"
        ref="input"
        :aria-label="label ? label : undefined"
        :class="htmlClass ? htmlClass : ''"
    />
</template>

<script>
/* eslint-disable */
export default {
  name: 'ValidatedInput',
  props: ['modelValue', 'name', 'type', 'placeholder', 'htmlClass', 'label'],
  methods: {
    input({ target }) {
      this.$emit('update:modelValue', target.value)
      this.$emit('change', target)
    },
    focusout() {
      this.$emit('focusout', this.$refs.input)
    },
    focus() {
      this.$refs.input.focus()
      const { value } = this.$refs.input
      this.$refs.input.value = ''
      this.$refs.input.value = value
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

input {
  &:focus {
    outline: 2px solid $edsights-blue;
  }
}
</style>