<script>
import { nextTick } from 'vue';

import ManageStudents from './ManageStudents';
import Tags from '@/services/tags';
import Student from '@/services/students';

export default {
    name: 'ManageStudentsByTag',
    extends: ManageStudents,

    data() {
        return {
            subtitle: '',
            tagRef: null,
            params: {
                tagIds: this.$route.params.tag
            }
        };
    },
    async created() {
        if (this.$route.params.tag) {
            this.params.tagIds = this.$route.params.tag;

            if (this.$store.state.user.isStaff) {
                // limit this page to students the staff member has access to
                this.params = { ...this.params, school: this.$store.state.user.school };
            }
            const tag = await Tags.api.retrieveTag(this.$route.params.tag);

            this.tagRef = tag;
            this.title = `Manage Students in ${tag.name} tag`;
            if (this.$store.state.user.isStaff) {
                // since the school depends on the selected school from the managetags page
                // show the user the school as well (only staff)
                this.title = `${this.title} in school ${this.$store.state.user.schoolRef.name}`;
            }
        }
    },
    mounted() {
        this.$emit('shouldShowSchools', false);
    },
    unmounted() {
        this.$emit('shouldShowSchools', true);
    },
    methods: {
        uploadStudents() {
            this.$modal.show('modal-assign-students', {
                tag: this.tagRef,
                refreshPage: this.refreshPage
            });
            nextTick(() => {
                const focusModalOnOpen = document.querySelector('.vm--container');
                if (focusModalOnOpen) {
                    focusModalOnOpen.focus();
                }
            });
        },
        async removeSelectedStudents() {
            const result = confirm(`Unassign selected students from tag: ${this.tagRef.name}? `);

            if (result) {
                let students = [];
                if (this.isMainCheckboxSelected) {
                    students = await Student.api.listEmails(this.params);
                } else {
                    students = this.selectedStudents;
                }

                const studentEmails = students.map(student => student.email);
                try {
                    await Tags.api.clearUsers(this.tagRef.id, studentEmails);
                    this.$Alert.alert({
                        type: 'success',
                        message: `${studentEmails.length} user(s) unassigned from tag.`,
                        timeout: 7000
                    });
                } finally {
                    this.loadStudents(this.params);
                }
            }
        }
    }
};
</script>

<!--
    These styles are copied directly from the extended component because this component stopped
    being styled when we upgraded to Vue 3. It seems styling scoping has changed with extended components.
    Doing this for now to unblock us.
    We should figure out how to properly solve this or just get rid of this "extends" pattern
    as it's the only place in the app doing it.
-->
<style lang="scss" scoped>
.manage-students {
    padding-top: 50px;
}

.search {
    margin-right: 15px;
}

.btn-add {
    margin-right: 15px;
}

.header {
    margin-top: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.filters {
    width: 190px;
    margin-right: 3rem;
    margin-top: 1rem;
}

.manage-students :deep(.scroll-wrapper__elem) {
    max-height: calc(100vh - 170px);
}

@media screen and (max-width: 1230px) {
    .students-list :deep(.list) {
        width: 75%;
    }
}
</style>
