<template>
    <modal
        classes="v--modal"
        name="modal-csv-verify"
        width="800"
        height="auto"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
        :stack="false"
    >
        <div v-if="!loading" class="modal">
            <div v-if="this.validations.length > 0" class="error">
                ATTENTION: POTENTIAL UPLOAD ERRORS IDENTIFIED
                <ul class="dashed-list">
                    <li v-for="validation in this.validations" :key="validation">
                        {{ validation }}
                    </li>
                </ul>
            </div>
            <div class="header">Step 5: Verify Student Information</div>
            <div>
                Please note that if you are doing an "update" and a field is left blank it will not
                be updated
            </div>
            <div class="column" v-for="(values, key) in results" :key="key">
                <p class="column-header">{{ key }}</p>
                <p class="row-value" v-for="(value, index) in values" :key="`${value}_${index}`">
                    {{ value }}
                </p>
                <p class="row-value">...</p>
            </div>

            <div class="buttons">
                <Button styleType="off" @click="cancel">Cancel</Button>
                <Button style="margin-left: 1rem" @click="submit">Submit</Button>
            </div>
        </div>
        <LoadingSpinner v-else />
    </modal>
</template>

<script>
import Student from '@/services/students';

import Button from '@/components-deprecated/Button';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import TagCategoryMapper from '@/components-deprecated/staff/TagCategoryMapper';

export default {
    components: {
        Button,
        LoadingSpinner,
        TagCategoryMapper,
        LoadingSpinner
    },
    data() {
        return {
            loading: true,
            results: null,
            validations: [],
            onCancel: () => {},
            onSumbit: () => {}
        };
    },
    methods: {
        async beforeOpen(event) {
            this.onCancel = event.params.onCancel;
            this.onSubmit = event.params.onSubmit;
            const rawResults = await Student.api.validateStudentCsv(event.params.file);
            this.validations = rawResults.Validations ? rawResults.Validations : this.validations;
            delete rawResults.Validations;
            this.results = rawResults;
            this.loading = false;
        },

        cancel() {
            this.$modal.hide('modal-csv-verify');
            this.onCancel();
        },

        submit() {
            this.$modal.hide('modal-csv-verify');
            this.onSubmit();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/views/modal';
@import '@/styles/forms';
@import '@/styles/variables';

.modal {
    margin: 2rem;
}

.header {
    font-size: 1.1rem;
    font-weight: bold;
}

.column {
    margin: 3rem;
}

.column-header {
    font-weight: bold;
    margin: 0.5rem;
}

.row-value {
    margin: 0.5rem;
    margin-left: 2rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.error {
    color: red;
    font-style: italic;
    margin-bottom: 3rem;
}

ul.dashed-list {
    list-style-type: '- ';
    list-style-position: inside;
}
</style>
