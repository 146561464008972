'use strict';

export const getDefaultLineChartProperties = () => {
    return {
        pointBackgroundColor: '#068BFF',
        fill: false,
        borderColor: '#068BFF',
        pointBorderColor: '#068BFF',
        pointRadius: 4,
        borderWidth: 1,
        tension: 0.1
    };
};
