<template>
    <div class="statement">
        <section>
            <h1>Accessibility Statement</h1>
            <p>
                At EdSights, we are committed to ensuring digital accessibility for people with
                disabilities. We are continually improving the user experience for everyone and
                applying the relevant accessibility standards to achieve these goals. We strive to
                comply with the
                <a href="https://www.w3.org/TR/WCAG21/"
                    >Web Content Accessibility Guidelines (WCAG) 2.1</a
                >
                Level AA standards to enhance user experience and accessibility.
            </p>
        </section>
        <section>
            <h2>Compatibility</h2>
            <p>
                EdSights is designed to be compatible with major browsers and assistive
                technologies, including screen readers like the JAWS Screen Reader. Please note that
                EdSights does not fully support mobile devices and operating systems at this time.
            </p>
        </section>
        <section>
            <h2>Limitations and Alternatives</h2>
            <p>
                Despite our best efforts to ensure the accessibility of EdSights, there may be some
                limitations. Please be aware that our efforts are ongoing. If you encounter any
                limitations, please contact us as soon as possible so we can provide you with the
                necessary assistance. Please refer to our current Accessibility Conformance Report
                for more information.
            </p>
        </section>
        <section>
            <h2>Feedback and Contact Information</h2>
            <p>
                We welcome feedback on accessibility issues at
                <a href="mailto:access@edsights.io">access@edsights.io</a>. We aim to respond within
                2-5 business days.
            </p>
        </section>
        <section>
            <h2>Enforcement</h2>
            <p>
                Your feedback is taken seriously as we aim to comply with our accessibility policy
                and accommodate all users. In addition, we note that there are some areas of our
                website that we have yet to find accessible solutions for.
            </p>
        </section>
    </div>
</template>

<script>
export default {
    name: 'AccessibilityStatement',
    mounted() {}
};
</script>

<style lang="scss" scoped>
.statement {
    width: 100%;
    margin: 4rem auto;
    padding: 0 4rem;
    max-width: 1024px;
    h1 {
        text-align: center;
        margin-bottom: 2rem;
    }
    section {
        margin-bottom: 2rem;
    }
}
</style>
