<template>
    <div :class="{ filterContainer: active, off: !active }">
        <select v-model="selected" class="selectbox" v-if="!disabled">
            <option disabled :value="null">Click to select</option>
            <option :value="nullValue">{{ nullValue.name }}</option>
            <option :key="item.id" v-for="item in data" :value="item">
                <!-- InterventionTemplate is the only item without a "name" attribute -->
                {{ interventionText ? item.text : item.name }}
            </option>
        </select>
        <select v-if="disabled" class="selectbox" v-model="selected" disabled>
            <option :value="null">{{ this.data[0].name }}</option>
        </select>
        <button v-if="deleteButton" class="close-button" @click="removeFilter">X</button>
    </div>
</template>

<script>
export default {
    name: 'FilterButton',
    data() {
        return {
            selected: null,
            previous: null,
            active: true,
            nullValue: {
                id: null,
                name: '---------'
            }
        };
    },
    props: {
        functionType: {
            type: String
        },
        data: {
            type: Array
        },
        interventionText: {
            type: Boolean
        },
        deleteButton: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        initialValue: {
            type: Object
        },
        number: {
            type: Number
        }
    },
    watch: {
        initialValue() {
            // when an initial value is passed down, finds the value matching that id in data, and sets as selected

            this.selectInitialValue();
        },
        selected(newValue, oldValue) {
            this.previous = oldValue;
            if (this.active && this.number && this.initialValue) {
                this.$emit(this.functionType, this.selected, this.previous, this.number);
            } else if (this.active) {
                this.$emit(this.functionType, this.selected, this.previous);
            }
        }
    },
    mounted() {
        if (this.initialValue) {
            this.selectInitialValue();
        }
    },
    updated() {
        if (!this.selected && this.initialValue) {
            this.selectInitialValue();
        }
    },
    methods: {
        removeFilter() {
            this.active = false;
            this.$emit(`delete${this.functionType}`, this.selected);
            this.$emit(this.functionType);
        },
        selectInitialValue() {
            const index = this.data.findIndex(item => {
                return item.id === this.initialValue.id;
            });
            this.selected = this.data[index];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.dropdown-content__school-list {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;

    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    max-height: 200px;
    overflow-y: scroll;
}
.dropdown-content__school-list:focus,
.dropdown-content__school-list.show {
    display: block;
}
.school-list {
    list-style-type: none;
    cursor: pointer;
    max-height: 20px;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    margin-top: 1px;
    white-space: nowrap;
    overflow-y: hidden;
    &:hover {
        background-color: lightgray;
    }
}

.icon {
    stroke: $primary-brand-color;
    stroke-width: 5;
}
.template-info {
    display: flex;
    flex-direction: row;
    &__main-content {
        width: 100%;
    }
    &__survey-container {
        display: flex;
        flex-direction: row;
    }
    &__survey-question {
        width: 100%;
        min-width: 400px;
    }

    &__flow-name {
        display: flex;
        margin: 1rem;
        justify-content: center;
        flex-direction: row;
        max-width: 500px;
    }
    &__chatbot-actions {
        margin: 1rem 0rem;
        min-height: 200px;
    }
}
.instances {
    display: flex;
    &__instance-container {
        flex: 1;
    }
    &__add {
        margin-top: 1rem;
        margin-right: 1rem;
    }
}
.triggers__trigger-container__trigger__info {
    > * {
        margin: 1rem;
    }
    display: flex;
}
.instance {
    display: flex;
    margin: 1rem 0rem;
    min-height: 100px;
}
.instance-info {
    flex: 1;
    display: flex;

    &__school {
        flex: 1;
        margin: 1rem;
    }
    &__date-time {
        margin: 1rem;
    }
}
.workflow-actions {
    display: flex;
    justify-content: center;
    padding: 1rem;
}
.template__post {
    border-top: 1px solid $primary-brand-color;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    &__button {
        margin-top: 1rem;
    }
}
.chatbot-workflow {
    &__message {
        padding: 8px 25px;
        border: 2px solid red;
    }
}
.chatbot-subheader {
    border: 2px solid green;
    display: flex;
    flex-direction: row;
    background-color: #f3f7f9;
    padding: 20px 40px;
    widows: 100vw;
}
.subheader-options {
    margin-right: 50px;
}
.template {
    padding: 0 25px;
}
.button-icon__text {
    color: $primary-brand-color;
    margin-top: -12px;
}

.triggers__trigger-container__add {
    margin-top: 10px;
}
.add-first-question-button {
    padding: 0 20px;
}
.filterContainer {
    border-radius: 30px;
    border: 1px solid #1f8bfb;

    display: inline;
    padding: 6px;
    margin: 5px 10px;
}

.selectbox {
    border: 0px;
    width: auto;

    padding: 5px;
    margin: 10px;
    max-width: 500px;
    background-color: transparent;
}
.close-button {
    background-color: white;
    border: 0px;
}
.off {
    display: none;
}

.optiontext {
    font-family: 'Poppins-Light';
    font-size: 12px;
    color: #202123;
}
</style>
