/* Add or update a background operation */
export function UPDATE_BACKGROUND_OPERATION(state, backgroundOperation) {
    const index = state.backgroundOperations.findIndex(op => op.id === backgroundOperation.id);
    // If this background operation doesn't exist yet, add it to state
    if (index === -1) {
        state.backgroundOperations = [...state.backgroundOperations, backgroundOperation];
    } else {
        // If this operation already exists, update it
        // (Return a new array instead of modifying the existing one)
        const elementsBefore = state.backgroundOperations.slice(0, index);
        const elementsAfter = state.backgroundOperations.slice(
            index + 1,
            state.backgroundOperations.length
        );
        state.backgroundOperations = [...elementsBefore, backgroundOperation, ...elementsAfter];
    }
}

export function SET_BACKGROUND_OPERATION_COMPLETION_ACTION(
    state,
    { backgroundOperationId, action }
) {
    const index = state.backgroundOperationActions.findIndex(
        item => item.operationId === backgroundOperationId
    );
    if (index === -1) {
        state.backgroundOperationActions = [
            ...state.backgroundOperationActions,
            { operationId: backgroundOperationId, action: action }
        ];
    }
}

export function REMOVE_BACKGROUND_OPERATION(state, backgroundOperation) {
    state.backgroundOperations = state.backgroundOperations.filter(
        op => op.id != backgroundOperation.id
    );

    state.backgroundOperationActions = state.backgroundOperationActions.filter(
        item => item.operationId != backgroundOperation.id
    );
}
export function REMOVE_ALL_BACKGROUND_OPERATIONS(state) {
    state.backgroundOperations = [];
    state.backgroundOperationActions = [];
}
