<template>
    <!-- This loading button is not using the "disabled" prop and is instead always disabled
         because it should never be clickable.
    -->
    <button
        v-if="loading"
        class="button loading"
        :disabled="true"
        :class="{
            button_text: styleType === 'text',
            button_text_secondary: styleType === 'textSecondary'
        }"
    >
        <LoadingSpinner :color="loadingSpinnerColor" />
    </button>
    <button
        v-else-if="!loading && to == null && link == null"
        class="button"
        :type="type"
        :disabled="disabled"
        :class="{
            button_off: styleType === 'off',
            button_text: styleType === 'text',
            button_text_secondary: styleType === 'textSecondary',
            button_unbordered: unbordered
        }"
    >
        <slot></slot>
    </button>
    <router-link v-else-if="!loading && to != null" class="button" :to="to" :disabled="disabled">
        <slot></slot>
    </router-link>
    <a class="button" v-else-if="!loading && link != null" :href="link">
        <slot></slot>
    </a>
</template>

<script>
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
export default {
    name: 'Button',
    components: {
        LoadingSpinner
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        styleType: {
            type: String,
            default: 'primary'
        },
        unbordered: {
            type: Boolean,
            default: false
        },
        to: {
            type: Object,
            default: null
        },
        // URL to external resource
        link: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // type defaults to null since this component did not originally specify a type
        // html buttons without an explicit type get a type of "submit", which causes problems when using this button within forms for anything other than submitting
        // specify type as "button" to avoid this behavior
        type: {
            type: String,
            default: null
        }
    },
    computed: {
        loadingSpinnerColor() {
            if (this.styleType === 'text' || this.styleType === 'textSecondary') {
                // no color - pass undefined to use LoadingSpinner's default prop
                return undefined;
            } else {
                return 'white';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Poppins-SemiBold;
    font-size: 12px;
    color: $white;
    letter-spacing: 0.5px;
    text-align: center;
    background: $primary-brand-color;
    border-radius: 6px;
    border-color: $primary-brand-color;
    outline: none;
    cursor: pointer;

    &:focus {
        outline: 2px solid $edsights-blue;
        outline-offset: 2px;
    }

    &:disabled {
        cursor: not-allowed;

        // Visual styles for all disabled buttons except the loading button
        // which looks a bit different.
        &:not(.loading) {
            background-color: $btn-off-color;
            border-color: $btn-off-color;
            color: #fff;
            svg {
                fill: #fff;
            }
        }
    }

    &_off {
        color: $accessible-gray;
        border: 1px solid $black-alpha-0-25;
        background-color: white;
    }

    &_unbordered {
        border: 0;
    }

    &_text {
        color: $primary-brand-color;
        border: 0;
        font-family: $base-font-family;
        background-color: transparent;
    }

    &_text_secondary {
        color: $black-alpha-0-35;
        border: 0;
        font-family: $base-font-family;
        background-color: transparent;
    }
}
</style>
