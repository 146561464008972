<template>
    <div class="labels-table">
        <div class="labels-toolbar">
            <Button @click="$emit('createNew')">New</Button>
        </div>

        <Table :columns="columns" :data="data" class="labels">
            <template v-slot:rows="{ rows }">
                <tr class="row" v-for="row in rows" :key="`row-${row.id}`">
                    <td class="text">
                        <div class="content">
                            {{ row.text }}
                        </div>
                    </td>
                    <td class="actions">
                        <div class="content">
                            <Button styleType="transparent" @click="$emit('renameLabel', row.id)">
                                RENAME
                            </Button>
                            <Button styleType="transparent" @click="$emit('deleteLabel', row.id)">
                                DELETE
                            </Button>
                        </div>
                    </td>
                </tr>
            </template>
        </Table>
        <EmptyState
            v-if="!data.length"
            message="No results."
            sub-message="There are no labels currently in the system."
        />
    </div>
</template>

<script>
import Table from '@/components-deprecated/global/Table';
import Button from '@/components-deprecated/global/Button';
import EmptyState from '@/components-deprecated/global/EmptyState';

export default {
    name: 'LabelsTable',
    components: {
        EmptyState,
        Table,
        Button
    },
    props: {
        columns: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        }
    },
    methods: {
        toggleSelectedRow(rowId) {
            if (this.selectedRowId === rowId) {
                this.selectedRowId = null;
            } else {
                this.selectedRowId = rowId;
            }
        },
        removeFilterChip(chip) {
            const { filterType, value } = chip;
            if (filterType === 'status') {
                this.toggleStatusFilter(value);
            }

            if (filterType === 'label') {
                this.toggleLabelFilter(value);
            }
        },
        toggleStatusFilter(status) {
            if (this.filters.status === status) {
                this.filters.status = null;
            } else {
                this.filters.status = status;
            }
        },
        toggleLabelFilter(labelId) {
            if (this.filters.labels.includes(labelId)) {
                this.filters.labels = this.filters.labels.filter(l => l !== labelId);
            } else {
                this.filters.labels.push(labelId);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.labels-table {
    .labels-toolbar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 25px;
    }
    &:deep(.table-container table) {
        border-collapse: collapse;

        th,
        td {
            &:nth-of-type(1) {
                width: 50%;
            }

            &:nth-of-type(2) {
                width: auto;
            }
        }

        tbody {
            tr {
                height: 120px;
                &.row {
                    border-top: 1px solid $black-alpha-0-25;
                    &:first-of-type {
                        border-top: 0;
                    }
                    &:hover {
                        background-color: $white-blue;
                    }
                }

                td {
                    &.actions {
                        .content {
                            display: flex;
                            flex-direction: row;

                            button {
                                min-width: 0;
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
