<template>
    <div class="cancel-flow-modal-container">
        <Modal
            v-if="!showDraftPromptModal"
            title="Cancel Flow"
            primaryButtonTitle="OK"
            secondaryButtonTitle="Cancel"
            :showPrimaryButtonLoadingSpinner="saving"
            @secondary-button-clicked="onClose"
            @primary-button-clicked="confirmFunction"
        >
            <div class="modal-content">
                Are you sure you want to cancel the flow?
            </div>
        </Modal>
        <Modal
            v-if="showDraftPromptModal"
            title="Save as Draft?"
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            :showPrimaryButtonLoadingSpinner="saving"
            @secondary-button-clicked="saveAsCancelled"
            @primary-button-clicked="saveAsDraft"
        >
            <div class="modal-content">
                Would you like to save the flow as a draft before deleting it?
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';
import { updateChatbotFlow } from '@/api/chatbot-flow';
import { CHATBOT_FLOW_STATUS } from '@/consts/chatbot-flow';

export default {
    name: 'CancelFlowModal',
    components: { Modal },
    props: {
        flow: {
            type: Object,
            required: true,
            validator: function(value) {
                return (
                    typeof value === 'object' &&
                    typeof value.id === 'string' &&
                    [CHATBOT_FLOW_STATUS.SCHEDULED.value, CHATBOT_FLOW_STATUS.DRAFT.value].includes(
                        value.status
                    )
                );
            }
        }
    },
    data() {
        return {
            showDraftPromptModal: false,
            saving: false
        };
    },
    methods: {
        openDraftPromptModal() {
            this.showDraftPromptModal = true;
        },
        onClose() {
            this.$emit('onClose');
        },
        async saveAsDraft() {
            if (typeof this.flow === 'object' && typeof this.flow.id === 'string') {
                try {
                    this.saving = true;
                    await updateChatbotFlow(this.flow.id, {
                        status: CHATBOT_FLOW_STATUS.DRAFT.value
                    });
                    this.$Alert.alert({
                        type: 'success',
                        message: `Flow saved as draft.`,
                        timeout: 7000
                    });
                    this.$emit('onSavedAsDraft', this.flow.id);
                } finally {
                    this.saving = false;
                }
            }
        },
        async saveAsCancelled() {
            if (typeof this.flow === 'object' && typeof this.flow.id === 'string') {
                try {
                    this.saving = true;
                    await updateChatbotFlow(this.flow.id, {
                        status: CHATBOT_FLOW_STATUS.CANCELED.value
                    });
                    this.$Alert.alert({
                        type: 'success',
                        message: `Flow cancelled.`,
                        timeout: 7000
                    });
                    this.saving = false;
                    this.$emit('onSavedAsCancelled', this.flow.id);
                } finally {
                    this.saving = false;
                }
            }
        }
    },
    computed: {
        confirmFunction() {
            if (
                typeof this.flow === 'object' &&
                this.flow.status === CHATBOT_FLOW_STATUS.SCHEDULED.value
            ) {
                return this.openDraftPromptModal;
            }

            return this.saveAsCancelled;
        }
    }
};
</script>

<style lang="scss" scoped>
.cancel-flow-modal-container {
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>
