<template>
    <div class="error-main">
        <h1>Page Not Found</h1>
        <img class="error-img" src="../assets/edsights-logo-square.png" alt="EdSights logo" />
        <p class="error-link">
            <router-link :to="{ name: 'Home' }">
                Return Home
            </router-link>
        </p>
    </div>
</template>

<script>
export default {
    name: 'Error404'
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';
@import '../styles/mixins/animations';

.error {
    &-main {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        background-color: $mystic;
    }
    &-img {
        width: 10rem;
        height: 10rem;
        margin: 5rem;
        @include mild-pulse-animation();
    }
    &-link {
        font-size: 2rem;
        font-weight: bold;
    }
}
</style>
