import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';
import { strictEqual } from 'assert';

// creates a resource detail tag
export const createResourceDetailTag = async ({
    schoolId,
    resourceDetailTag,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof schoolId, 'string');
        strictEqual(typeof resourceDetailTag, 'object');

        const url = Api.getv2ApiBase(`/resource-detail-tag/${schoolId}`);
        const options = Api.getOptions();
        const payload = {
            resourceDetailId: resourceDetailTag.resourceDetailId,
            tagId: resourceDetailTag.tagId,
            instruction: resourceDetailTag.instruction,
            status: resourceDetailTag.status
        };

        const response = await axios.post(url, payload, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem creating the resource. Please try again.',
            ...errorHandlerOptions
        });
    }
};

// updates a resource detail tag
export const updateResourceDetailTag = async ({
    id,
    schoolId,
    resourceDetailTag,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof id, 'string');
        strictEqual(typeof schoolId, 'string');
        strictEqual(typeof resourceDetailTag, 'object');

        const url = Api.getv2ApiBase(`/resource-detail-tag/${id}/school/${schoolId}`);
        const options = Api.getOptions();
        const payload = {
            tagId: resourceDetailTag.tagId,
            instruction: resourceDetailTag.instruction,
            status: resourceDetailTag.status
        };

        const response = await axios.patch(url, payload, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem editing the resource. Please try again.',
            ...errorHandlerOptions
        });
    }
};

// deletes a resource detail tag
export const deleteResourceDetailTag = async ({ id, schoolId, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof id, 'string');
        strictEqual(typeof schoolId, 'string');

        const url = Api.getv2ApiBase(`/resource-detail-tag/${id}/school/${schoolId}`);
        const options = Api.getOptions();

        const response = await axios.delete(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem deleting the resource. Please try again.',
            ...errorHandlerOptions
        });
    }
};
