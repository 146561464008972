<template>
    <div class="knowledge-base container">
        <ButtonBack></ButtonBack>
        <div class="header-container">
            <div>
                <h2>{{ headerText }}</h2>
            </div>
            <div class="export-link clickable" style="display: none" @click="exportFAQs">
                Export All
                <svg class="icon-download" width="20px" height="20px" viewBox="0 0 20 20">
                    <use xlink:href="#icon-download-unstyled" />
                </svg>
            </div>
        </div>
        <div class="knowledge-base__body container">
            <ScrollWrapper class="scroll-wrapper side-bar box-no-padding">
                <LoadingSpinner v-if="globalLoading" class="faq-loading" />

                <!-- SIDEBAR -->
                <div v-if="!nextGenAIChatbotEnabled">
                    <div class="header" :class="{ hide: globalLoading }">
                        <h3 class="knowledge-base__title">Understandings</h3>
                        <div class="flex-between">
                            <Button
                                class="faq-button"
                                :class="{
                                    'selected-faq-topic-button': selectedFaqTopic.id === 'all'
                                }"
                                @click="onClickFaqTopic('all')"
                            >
                                All Understandings
                            </Button>
                            <span>{{ faqs.length }}</span>
                        </div>
                    </div>

                    <div :class="{ hide: globalLoading }">
                        <p class="faq-topic">Topics</p>
                        <div class="title-container" v-if="faqTopics.length > 0">
                            <div
                                class="flex-between"
                                style="padding-right: 2rem"
                                v-for="faqtopic in faqTopics"
                                :key="faqtopic.id"
                            >
                                <Button
                                    class="faq-button button-bottom faq-topic-button"
                                    :class="{
                                        'selected-faq-topic-button':
                                            selectedFaqTopic.id === faqtopic.id
                                    }"
                                    @click="onClickFaqTopic(faqtopic)"
                                >
                                    {{ faqtopic.name }}
                                </Button>
                                <span>{{ faqtopic.numberOfFaqs }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Resource Needs -->
                <div class="header" :class="{ hide: globalLoading }">
                    <h3 class="knowledge-base__title">NEED-BASED RESOURCES</h3>
                    <div class="flex-between">
                        <Button
                            class="faq-button"
                            :class="{
                                'selected-faq-topic-button':
                                    selectedRiskDriver.name === 'All Resources'
                            }"
                            @click="onClickRiskDriver('all')"
                        >
                            All Resources
                        </Button>
                        <span>{{ riskDriverTotal }}</span>
                    </div>
                </div>

                <div :class="{ hide: globalLoading }">
                    <p class="faq-topic">Drivers</p>
                    <div class="title-container">
                        <div
                            class="flex-between"
                            style="padding-right: 2rem"
                            v-for="riskDriver in riskDrivers"
                            :key="riskDriver.name"
                        >
                            <Button
                                class="faq-button button-bottom faq-topic-button"
                                :class="{
                                    'selected-faq-topic-button':
                                        selectedRiskDriver.name === riskDriver.name
                                }"
                                @click="onClickRiskDriver(riskDriver)"
                            >
                                {{ riskDriver.name }}
                            </Button>
                            <span>{{ riskDriver.count }}</span>
                        </div>
                    </div>
                </div>
            </ScrollWrapper>

            <!-- MAIN BOX -->
            <ScrollWrapper class="scroll-wrapper main-box box-no-padding">
                <LoadingSpinner v-if="globalLoading" class="faq-loading" />

                <!-- Understandings -->
                <template
                    v-if="!globalLoading && selectedFaqTopic.name && !nextGenAIChatbotEnabled"
                >
                    <div style="padding: 2rem 2rem 0 2rem">
                        <h3 class="knowledge-base__title">
                            {{ selectedFaqTopic.name }}
                        </h3>

                        <div class="filter-container">
                            <div style="width: 270px; padding-right: 2rem">
                                <TransitionDropdown
                                    :choices="filterChoices"
                                    :selected="filterValue"
                                    @on-select="onFilterChange"
                                />
                            </div>

                            <div>
                                <SearchInput
                                    :modelValue="questionFilter"
                                    class="navigation__search-input"
                                    @update:modelValue="questionFilterHandler($event)"
                                    :isSmall="true"
                                    :smallBorder="true"
                                    :placeholder="'Search Understanding'"
                                />
                            </div>
                        </div>

                        <div
                            style="display: flex; justify-content: space-between; border-bottom: 1px solid #d0cccc;"
                        >
                            <div style="width: 35%">Summary Question</div>
                            <div style="width: 63%">Chatbot Answer</div>
                        </div>
                    </div>

                    <div
                        class="content-box"
                        v-if="selectedFaqTopic.id !== 'all' && selectedFaqs.length > 0"
                    >
                        <faq-details
                            :topic="selectedFaqTopic"
                            :faqs="selectedFaqs.filter(f => f.topic.id == selectedFaqTopic.id)"
                            :selectedFaqInstance="selectedFaqInstance"
                            :errors="errors"
                            :loading="loading"
                            :deleteLoading="deleteLoading"
                            v-on:edit-faq="editFaqHandler"
                            v-on:status-change="statusChangeHandler"
                            v-on:create-faq-instance="onCreateFaqInstance"
                            v-on:update-faq-instance="onUpdateFaqInstance"
                            v-on:cancel-faq-instance="onCancelFaqInstance"
                            v-on:emoji-selected="selectEmojiHandler"
                        />
                    </div>

                    <div
                        class="content-box"
                        v-if="selectedFaqTopic.id === 'all' && selectedFaqs.length > 0"
                    >
                        <div v-for="topic in faqTopics" :key="topic.id">
                            <faq-details
                                :topic="topic"
                                :faqs="selectedFaqs"
                                :selectedFaqInstance="selectedFaqInstance"
                                :errors="errors"
                                :loading="loading"
                                :deleteLoading="deleteLoading"
                                v-on:edit-faq="editFaqHandler"
                                v-on:status-change="statusChangeHandler"
                                v-on:create-faq-instance="onCreateFaqInstance"
                                v-on:update-faq-instance="onUpdateFaqInstance"
                                v-on:cancel-faq-instance="onCancelFaqInstance"
                                v-on:emoji-selected="selectEmojiHandler"
                            />
                        </div>
                    </div>

                    <div class="content-box" v-if="selectedFaqs.length === 0">
                        <h2>No FAQs to show!</h2>
                    </div>
                </template>

                <!-- Resource Needs -->
                <template v-if="!globalLoading && selectedRiskDriver.name">
                    <div style="padding: 2rem 2rem 0 2rem">
                        <h3 class="knowledge-base__title">
                            {{ selectedRiskDriver.name }}
                        </h3>

                        <div class="filter-container">
                            <div style="width: 270px; padding-right: 2rem">
                                <TransitionDropdown
                                    :choices="filterChoices"
                                    :selected="resourceFilterValue"
                                    @on-select="onResourceFilterChange"
                                />
                            </div>

                            <div>
                                <SearchInput
                                    :modelValue="needFilter"
                                    class="navigation__search-input"
                                    @update:modelValue="needFilterHandler($event)"
                                    :isSmall="true"
                                    :smallBorder="true"
                                    :placeholder="'Search Resources'"
                                />
                            </div>
                        </div>

                        <div
                            style="display: flex; justify-content: space-between; border-bottom: 1px solid #d0cccc;"
                        >
                            <div style="width: 35%">Need</div>
                            <div style="width: 63%">Resource Name & Description</div>
                        </div>
                    </div>

                    <div
                        class="content-box"
                        v-if="selectedRiskDriver.name !== 'all' && selectedResourceNeeds.length > 0"
                    >
                        <resource-need-details
                            :risk="selectedRiskDriver"
                            :resourceNeeds="selectedResourceNeeds"
                            :selectedResourceDetail="selectedResourceDetail"
                            :errors="errors"
                            :loading="loading"
                            :deleteLoading="deleteLoading"
                            v-on:edit-detail="editResourceDetailHandler"
                            v-on:detail-status-change="resourceStatusChangeHandler"
                            v-on:create-resource-detail="onCreateResourceDetail"
                            v-on:update-resource-detail="onUpdateResourceDetail"
                            v-on:delete-resource-detail-tag="onDeleteResourceDetailTag"
                            v-on:cancel-resource-detail="onCancelResourceDetail"
                            v-on:emoji-selected="resourceEmojiHandler"
                        />
                    </div>

                    <div
                        class="content-box"
                        v-if="
                            selectedRiskDriver.name === 'All Resources' &&
                                selectedResourceNeeds.length > 0
                        "
                    >
                        <div v-for="riskDriver in riskDrivers" :key="riskDriver.name">
                            <resource-need-details
                                :risk="riskDriver"
                                :resourceNeeds="selectedResourceNeeds"
                                :selectedResourceDetail="selectedResourceDetail"
                                :errors="errors"
                                :loading="loading"
                                :deleteLoading="deleteLoading"
                                v-on:edit-detail="editResourceDetailHandler"
                                v-on:detail-status-change="resourceStatusChangeHandler"
                                v-on:create-resource-detail="onCreateResourceDetail"
                                v-on:update-resource-detail="onUpdateResourceDetail"
                                v-on:delete-resource-detail-tag="onDeleteResourceDetailTag"
                                v-on:cancel-resource-detail="onCancelResourceDetail"
                                v-on:emoji-selected="resourceEmojiHandler"
                            />
                        </div>
                    </div>

                    <div class="content-box" v-if="selectedResourceNeeds.length === 0">
                        <h2>No Resources to show!</h2>
                    </div>
                </template>
            </ScrollWrapper>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ScrollWrapper from '@/components-deprecated/ScrollWrapper';
import FAQ from '@/services/faqs';
import FAQInstance from '@/services/faqInstances';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import TransitionDropdown from '@/components-deprecated/inputs/TransitionDropdown';
import ButtonBack from '@/components-deprecated/ButtonBack';
import School from '@/services/schools';
import SearchInput from '@/components-deprecated/inputs/SearchInput';
import * as FAQTopicApi from '@/api/faq-topics';
import * as FAQApi from '@/api/faqs';
import * as ResourceNeedApi from '@/api/resource-need';
import * as ResourceDetailApi from '@/api/resource-detail';
import { createResourceDetailTag, updateResourceDetailTag } from '@/api/resource-detail-tag';
import faqDetails from './KnowledgeBase/FAQDetails.vue';
import resourceNeedDetails from './KnowledgeBase/ResourceNeedDetails';
import { isNextGenAIChatbotEnabled } from '@/lib/feature-flag';

export default {
    name: 'FAQs',
    components: {
        ScrollWrapper,
        LoadingSpinner,
        TransitionDropdown,
        ButtonBack,
        SearchInput,
        faqDetails,
        resourceNeedDetails
    },
    data() {
        return {
            faqs: [],
            selectedFaqTopic: { id: 'all', name: 'All Understandings' },
            selectedRiskDriver: {},
            selectedFaqInstance: {},
            selectedResourceDetail: {},
            selectedResourceDetailTag: {},
            errors: [],
            globalLoading: false,
            loading: false,
            deleteLoading: false,
            dropDownClass: School,
            faqSchool: null,
            filterChoices: FAQ.filterChoices,
            filterValue: FAQ.filterChoices[0],
            resourceFilterValue: FAQ.filterChoices[0],
            faqTopics: [],
            selectedFaqs: [],
            selectedResourceNeeds: [],
            questionFilter: '',
            needFilter: '',
            riskDrivers: [],
            resourceNeeds: [],
            riskDriverTotal: 0,
            nextGenAIChatbotEnabled: null
        };
    },
    async created() {
        this.globalLoading = true;
        await this.checkIfNextGenAIEnabled();
        await this.refresh();
        this.globalLoading = false;
    },

    mounted() {
        this.$emit('shouldShowSchools', false);
    },

    methods: {
        async refresh() {
            this.errors = [];
            const faqTopics = await FAQTopicApi.listFaqTopics();
            this.faqs = await FAQApi.listFaqs(this.$store.state.user.schoolRef.id);
            // get resource needs
            const response = await ResourceNeedApi.listResourceNeedBySchool(
                this.$store.state.user.schoolRef.id
            );
            this.riskDrivers = response.riskDrivers;
            this.resourceNeeds = response.resourceNeeds;
            this.riskDriverTotal = response.riskDriverTotal;
            // reset any selected faq instance or resource detail
            this.selectedFaqInstance = {};
            this.selectedResourceDetail = {};
            // add topic counts
            this.faqTopics = faqTopics.reduce((acc, topic) => {
                topic.numberOfFaqs = this.faqs.filter(f => (f.topic || {}).id === topic.id).length;
                acc.push(topic);
                return acc;
            }, []);

            this.applyFaqFilters();
            this.applyRiskFilters();
        },

        setFaqInstance(faqs) {
            return faqs.reduce((accumulator, f) => {
                if (!f.instance) {
                    f.instance = this.getEmptyFAQInstance(f.id);
                }
                accumulator.push(f);
                return accumulator;
            }, []);
        },

        onClickFaqTopic(faqtopic) {
            this.selectedRiskDriver = {};

            if (faqtopic === 'all') {
                this.selectedFaqTopic = { id: 'all', name: 'All Understandings' };
            } else {
                this.selectedFaqTopic = faqtopic;
            }

            this.applyFaqFilters();
        },

        applyFaqFilters() {
            let selectedFaqs = [];

            if (this.selectedFaqTopic.id === 'all') {
                selectedFaqs = this.faqs.filter(f => f.hasOwnProperty('id'));
            } else {
                selectedFaqs = this.faqs.filter(
                    f => (f.topic || {}).id === this.selectedFaqTopic.id
                );
            }

            // ensure each faq has an instance
            const reducedFaqs = this.setFaqInstance(selectedFaqs);

            // apply any selected filters
            const filteredByAnswerStatus = this.applyAnswerStatusFilter(reducedFaqs);

            this.selectedFaqs = this.applyQuestionFilter(filteredByAnswerStatus);
        },

        onClickRiskDriver(riskDriver) {
            this.selectedFaqTopic = {};

            if (riskDriver === 'all') {
                this.selectedRiskDriver = { name: 'All Resources' };
            } else {
                this.selectedRiskDriver = riskDriver;
            }

            this.applyRiskFilters();
        },

        applyRiskFilters() {
            let selectedResourceNeeds = [];

            if (this.selectedRiskDriver.name === 'All Resources') {
                selectedResourceNeeds = this.resourceNeeds.filter(r => r.hasOwnProperty('id'));
            } else {
                selectedResourceNeeds = this.resourceNeeds.filter(
                    r => r.riskDriverName === this.selectedRiskDriver.name
                );
            }

            // apply any selected filters
            const filteredByAnswerStatus = this.applyDetailStatusFilter(selectedResourceNeeds);

            this.selectedResourceNeeds = this.applyNeedFilter(filteredByAnswerStatus);
        },

        editFaqHandler(faqInstance) {
            this.errors = [];
            this.selectedFaqInstance = faqInstance;
        },

        editResourceDetailHandler(resourceDetail) {
            this.errors = [];
            this.selectedResourceDetail = resourceDetail;
        },

        statusChangeHandler(statusChoiceId) {
            this.selectedFaqInstance.status = statusChoiceId;
        },

        resourceStatusChangeHandler(statusChoiceId) {
            this.selectedResourceDetail.status = statusChoiceId;
        },

        selectEmojiHandler(value) {
            this.selectedFaqInstance.answer = this.selectedFaqInstance.answer += value;
        },

        resourceEmojiHandler(value) {
            this.selectedResourceDetail.instruction = this.selectedResourceDetail.instruction += value;
        },

        async onCreateFaqInstance() {
            if (this.checkFAQInstanceForErrors(true)) {
                return;
            }
            this.loading = true;
            try {
                await FAQInstance.api.create(this.selectedFaqInstance);
                await this.refresh();
            } finally {
                this.loading = false;
            }
        },

        async onCreateResourceDetail() {
            if (this.checkDetailForErrors(true)) {
                return;
            }
            this.loading = true;
            try {
                await ResourceDetailApi.createResourceDetail(this.selectedResourceDetail);
                await this.refresh();
            } finally {
                this.loading = false;
            }
        },

        async onUpdateFaqInstance() {
            if (this.checkFAQInstanceForErrors(false)) {
                return;
            }
            this.loading = true;
            try {
                if (
                    typeof this.selectedFaqInstance.answer === 'string' &&
                    !this.selectedFaqInstance.answer.trim()
                ) {
                    await FAQInstance.api.delete(this.selectedFaqInstance);
                } else {
                    const fields = ['answer', 'status'];
                    await FAQInstance.api.update(this.selectedFaqInstance, fields);
                }

                await this.refresh();
            } finally {
                this.loading = false;
            }
        },

        async onDeleteResourceDetailTag() {
            this.loading = true;

            await this.refresh();

            this.loading = false;
        },

        async onUpdateResourceDetail({ selectedResourceDetailTag }) {
            if (this.checkDetailForErrors(false)) {
                return;
            }
            if (selectedResourceDetailTag) {
                if (selectedResourceDetailTag.id) {
                    if (this.checkDetailTagForErrors(false, selectedResourceDetailTag)) {
                        return;
                    }
                } else {
                    if (this.checkDetailTagForErrors(true, selectedResourceDetailTag)) {
                        return;
                    }
                }
            }

            this.loading = true;

            try {
                // update or delete resource detail
                if (
                    typeof this.selectedResourceDetail.name === 'string' &&
                    !this.selectedResourceDetail.name.trim() &&
                    typeof this.selectedResourceDetail.instruction === 'string' &&
                    !this.selectedResourceDetail.instruction.trim()
                ) {
                    await ResourceDetailApi.deleteResourceDetail(
                        this.selectedResourceDetail.id,
                        this.user.school
                    );
                } else {
                    await ResourceDetailApi.updateResourceDetail(
                        this.selectedResourceDetail.id,
                        this.selectedResourceDetail
                    );
                }

                // add or update resource detail for the tag
                if (selectedResourceDetailTag) {
                    if (
                        typeof selectedResourceDetailTag.instruction === 'string' &&
                        selectedResourceDetailTag.instruction.trim() &&
                        typeof selectedResourceDetailTag.tagId === 'number' &&
                        selectedResourceDetailTag.tagId
                    ) {
                        if (selectedResourceDetailTag.id) {
                            // update api route
                            await updateResourceDetailTag({
                                id: selectedResourceDetailTag.id,
                                schoolId: this.user.school,
                                resourceDetailTag: selectedResourceDetailTag
                            });
                        } else {
                            // add api route
                            await createResourceDetailTag({
                                schoolId: this.user.school,
                                resourceDetailTag: selectedResourceDetailTag
                            });
                        }
                    }
                }

                await this.refresh();
            } finally {
                this.loading = false;
            }
        },

        onCancelFaqInstance() {
            this.errors = [];
            this.selectedFaqInstance = {};
        },

        onCancelResourceDetail() {
            this.errors = [];
            this.selectedResourceDetail = {};
        },

        checkFAQInstanceForErrors(creating) {
            const errors = [];

            // rules for newly created FAQ Instance
            if (creating) {
                if (
                    typeof this.selectedFaqInstance.answer === 'string' &&
                    !this.selectedFaqInstance.answer.trim()
                ) {
                    errors.push({
                        field: 'answer',
                        detail: 'Answer required.'
                    });
                }
            }

            this.errors = errors;
            return errors.length;
        },

        checkDetailForErrors(creating) {
            const errors = [];
            const hasName =
                typeof this.selectedResourceDetail.name === 'string' &&
                this.selectedResourceDetail.name.trim();
            const hasInstruction =
                typeof this.selectedResourceDetail.instruction === 'string' &&
                this.selectedResourceDetail.instruction.trim();
            const nameError = {
                field: 'name',
                detail: 'Resource name required.'
            };
            const instructionError = {
                field: 'instruction',
                detail: 'Resource description required.'
            };

            // rules for newly created detail
            if (creating) {
                if (!hasName) {
                    errors.push(nameError);
                }
                if (!hasInstruction) {
                    errors.push(instructionError);
                }
            }

            // rules for editing an already created detail
            else {
                // if name or instructions are blank while the other is filled, push an error
                // if both are blank, no errors are generated and the detail is deleted
                if (hasName && !hasInstruction) {
                    errors.push(instructionError);
                }

                if (!hasName && hasInstruction) {
                    errors.push(nameError);
                }
            }
            this.errors = errors;
            return errors.length;
        },

        checkDetailTagForErrors(creating, selectedResourceDetailTag) {
            const errors = [];
            const hasInstruction =
                typeof selectedResourceDetailTag.instruction === 'string' &&
                selectedResourceDetailTag.instruction.trim();
            const hasTag =
                typeof selectedResourceDetailTag.tagId === 'number' &&
                selectedResourceDetailTag.tagId;
            const instructionError = {
                field: 'tagInstruction',
                detail: 'Resource description required.'
            };
            const tagError = {
                field: 'tagId',
                detail: 'Tag is required.'
            };

            // rules for newly created detail
            if (creating) {
                if (!hasInstruction) {
                    errors.push(instructionError);
                }
                if (!hasTag) {
                    errors.push(tagError);
                }
            }

            // rules for editing an already created detail
            else {
                // if tag or instructions are blank while the other is filled, push an error
                // if both are blank, no errors are generated and the detail is deleted
                if (hasTag && !hasInstruction) {
                    errors.push(instructionError);
                }

                if (!hasTag && hasInstruction) {
                    errors.push(tagError);
                }
            }
            this.errors = errors;
            return errors.length;
        },

        getEmptyFAQInstance(faqId) {
            return FAQInstance.create({
                faq: faqId,
                school: this.user.school,
                status: 'PENDING'
            });
        },

        questionFilterHandler(value) {
            this.questionFilter = (value || '').trim().toLowerCase();
            this.applyFaqFilters();
        },

        needFilterHandler(value) {
            this.needFilter = (value || '').trim().toLowerCase();
            this.applyRiskFilters();
        },

        applyAnswerStatusFilter(filteredFaqs) {
            switch (this.filterValue.id) {
                case 'ALL':
                    return filteredFaqs;
                case 'ANSWERED':
                    return filteredFaqs.filter(f => f.instance.answer !== '');
                case 'UNANSWERED':
                    return filteredFaqs.filter(f => f.instance.answer === '');
                case 'APPROVED':
                    return filteredFaqs.filter(f => f.instance.status === 'APPROVED');
                case 'PENDING':
                    return filteredFaqs.filter(f => f.instance.status === 'PENDING');
                default:
                    return filteredFaqs;
            }
        },

        applyDetailStatusFilter(resourceNeeds) {
            switch (this.resourceFilterValue.id) {
                case 'ALL':
                    return resourceNeeds;
                case 'ANSWERED':
                    return resourceNeeds.filter(f => f.resourceDetail.instruction !== '');
                case 'UNANSWERED':
                    return resourceNeeds.filter(f => f.resourceDetail.instruction === '');
                case 'APPROVED':
                    return resourceNeeds.filter(f => f.resourceDetail.status === 'approved');
                case 'PENDING':
                    return resourceNeeds.filter(f => f.resourceDetail.status === 'pending');
                default:
                    return resourceNeeds;
            }
        },

        applyQuestionFilter(faqs) {
            if (this.questionFilter !== '') {
                return faqs.filter(f => f.question.toLowerCase().includes(this.questionFilter));
            }
            return faqs;
        },

        applyNeedFilter(resourceNeeds) {
            if (this.needFilter !== '') {
                return resourceNeeds.filter(f => f.need.toLowerCase().includes(this.needFilter));
            }
            return resourceNeeds;
        },

        onFilterChange(choice) {
            this.filterValue = choice;
            this.applyFaqFilters();
        },

        onResourceFilterChange(choice) {
            this.resourceFilterValue = choice;
            this.applyRiskFilters();
        },

        async exportFAQs() {
            await FAQ.api.export();
        },

        async checkIfNextGenAIEnabled() {
            if (this.$store.state.user.schoolRef) {
                this.nextGenAIChatbotEnabled = await isNextGenAIChatbotEnabled(
                    this.$store.state.user.schoolRef.id
                );
                if (this.nextGenAIChatbotEnabled === true) {
                    this.selectedFaqTopic = {};
                    this.selectedRiskDriver = { name: 'All Resources' };
                }
            } else {
                this.nextGenAIChatbotEnabled = false;
            }
        }
    },
    computed: {
        ...mapState(['user']),

        headerText() {
            if (this.nextGenAIChatbotEnabled === true) {
                return 'Need-Based Resources';
            }
            if (this.nextGenAIChatbotEnabled === false) {
                return 'Your Knowledge Base';
            }
            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/styles/mixins/buttons';
.flex-between {
    display: flex;
    justify-content: space-between;
}
.hide {
    visibility: hidden;
}
.knowledge-base {
    &__body {
        display: flex;
        flex-direction: row;
        padding-top: 30px;
        justify-content: space-evenly;
    }

    &__title {
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    &__description {
        font-size: 12px;
    }
}
.faq-loading {
    margin-top: 3rem;
}
.scroll-wrapper {
    max-height: calc(100vh - 160px);
}
.header-container {
    display: flex;
    padding: 2rem 0 0 2rem;
    justify-content: space-between;
}
.header {
    padding: 2rem;
}
.header-text {
    text-align: center;
    padding: 2rem;
}
.side-bar {
    margin-right: 15px;
    width: 20%;
}
.button-bottom {
    margin-bottom: 1.5rem;
}
.faq-topic {
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: 400;
    font-stretch: normal;
    font-size: 13.3333px;
    line-height: normal;
    font-family: Arial;
    padding-left: 2rem;
}
.faq-topic-button {
    margin-left: 2rem;
}
.faq-button {
    display: flex;
    border: none;
    cursor: pointer;
    letter-spacing: 1px;
    line-height: 2rem;
    background: white;
    text-align: left;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}
.selected-faq-topic-button {
    color: $accessible-gray;
}
.title-container {
    display: flex;
    margin: 2rem 0 0 2rem;
    flex-direction: column;
}
.checkbox {
    margin-left: 0.5rem;
    font-size: 1rem;
    color: $dodger-blue;
}
.main-box {
    width: 79%;
}
.content-box {
    padding: 1rem 4rem 2rem 2rem;
}
.description {
    color: $main-gray;
}
.button-container {
    display: flex;
    justify-content: flex-end;
}
.save-btn {
    @include base-button();
}
.delete-btn {
    @include secondary-button();
    margin-left: 1rem;
}
.scroll-wrapper {
    height: calc(100vh - 160px);
}
.answer {
    white-space: pre-line;
}

.filter-container {
    margin-bottom: 2rem;
    display: flex;
}

.clickable {
    cursor: pointer;
}

.export-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    float: right;
    margin-right: 4rem;
    font-size: 1.4rem;
    color: $primary-brand-color;
}

.icon-download {
    stroke: $primary-brand-color;
    margin-left: 1rem;
    vertical-align: sub;
}
</style>
