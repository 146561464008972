<template>
    <div class="line-chart-container">
        <Line
            :chartData="chartData"
            :chartOptions="chartOptions"
            :height="height"
            :ariaLabel="ariaLabel"
        />
    </div>
</template>

<script>
import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
} from 'chart.js';
import { Line } from 'vue-chartjs';
import 'chartjs-adapter-luxon';

Chart.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default {
    components: { Line },
    props: {
        chartData: {
            type: Object,
            required: true
        },
        height: {
            type: Number,
            required: false
        },
        // The ariaLabel should describe the chart and its data.
        // It gets applied as an aria-label on the chart's parent element.
        // Example: "Sales figures for the years 2022 to 2024. Sales in 2022: 987, Sales in 2023: 1209, Sales in 2024: 825."
        ariaLabel: {
            type: String,
            required: true,
            // Fallback provided because a blank aria-label can be harmful to older screen readers,
            // and something is better than nothing.
            // Please fix any usages where this prop validation is failing with a detailed value.
            default: 'A chart with data.'
        },
        showLegend: {
            type: Boolean,
            default: false
        },
        xAxisLabel: {
            type: String,
            required: false
        },
        yAxisLabel: {
            type: String,
            required: false
        },
        xAxisTickLimit: {
            type: Number,
            required: false
        },
        yAxisTickLimit: {
            type: Number,
            required: false
        },
        xAxisType: {
            type: String,
            default: 'category',
            validator: value => {
                // there are more types available, but these are what we use now:
                return ['category', 'time'].includes(value);
            }
        },
        xAxisTimeUnit: {
            type: String,
            required: false
        },
        // Default parser matches the format returned by Luxon's .toISO().
        // If using a time scale, make all dates (labels, min/max) match this
        // parser, or provide your own parser that matches all of your dates.
        xAxisTimeParser: {
            type: String,
            default: "yyyy-MM-dd'T'HH:mm:ss.SSSZZ"
        },
        xAxisMin: {
            type: String,
            required: false
        },
        xAxisMax: {
            type: String,
            required: false
        },
        yAxisMin: {
            type: Number,
            required: false
        },
        yAxisMax: {
            type: Number,
            required: false
        },
        tooltipCallbacks: {
            type: Object,
            required: false
        }
    },
    computed: {
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: this.showLegend
                    },
                    tooltip: {
                        enabled: true,
                        displayColors: false,
                        ...(this.tooltipCallbacks ? { callbacks: this.tooltipCallbacks } : {})
                    },
                    datalabels: {
                        display: false
                    }
                },
                scales: {
                    y: {
                        min: this.yAxisMin,
                        max: this.yAxisMax,
                        grid: {
                            drawBorder: true,
                            lineWidth: 0
                        },
                        title: {
                            display: Boolean(this.yAxisLabel),
                            text: this.yAxisLabel
                        },
                        ticks: {
                            maxTicksLimit: this.yAxisTickLimit,
                            font: {
                                size: 10
                            }
                        }
                    },
                    x: {
                        type: this.xAxisType,
                        min: this.xAxisMin,
                        max: this.xAxisMax,
                        // only used by chart.js when xAxisType is "time":
                        time: {
                            unit: this.xAxisTimeUnit,
                            parser: this.xAxisTimeParser
                        },
                        grid: {
                            drawBorder: true,
                            lineWidth: 0
                        },
                        title: {
                            display: Boolean(this.xAxisLabel),
                            text: this.xAxisLabel
                        },
                        ticks: {
                            maxRotation: 0,
                            font: {
                                size: 10
                            }
                        }
                    }
                }
            };
        }
    }
};
</script>

<style scoped lang="scss">
.line-chart-container {
}
</style>
