import { objectToCamelCase, objectToSnakeCase } from '../utils';
import api from './api';

export default class Insight {
    static api = api.create(Insight);

    static typeImages = {
        'Financial Insight': '#icon-risk-financial',
        'Academic Insight': '#icon-risk-academic',
        'Engagement Insight': '#icon-risk-engagement',
        'Wellness Insight': '#icon-risk-belief',
        'Overall Insight': '#icon-risk-overall'
    };

    constructor(insight) {
        // eslint-disable-next-line no-param-reassign
        insight = insight || {};
        const {
            id = null,
            lastEdited = null,
            datetimeCreated = null,
            title = '',
            description = '',
            explanation = '',
            chartTitle = '',
            chartType = '',
            chartLabels = '',
            templateType = '',
            userFriendlyType = null,
            scores = null,
            tag = null,
            riskBucket = null,
            riskDifference = null
        } = insight;
        Object.assign(this, {
            id,
            lastEdited,
            datetimeCreated,
            title,
            description,
            explanation,
            chartTitle,
            chartType,
            chartLabels,
            templateType,
            userFriendlyType,
            scores,
            tag,
            riskBucket,
            riskDifference
        });
    }

    static create(opts) {
        return new Insight(opts);
    }

    static fromAPI(json) {
        return new Insight(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }
}
