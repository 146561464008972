import { getInitialState } from '../index';
import store from '@/store';

export function UPDATE_USER(state, value) {
    state.user = {
        ...state.user,
        ...value
    };
}
// Update just the user's token on login
export function UPDATE_TOKEN(state, value) {
    state.userToken = value;
}
// Log out the user by resetting the state to defaults
export function DO_LOGOUT(state) {
    const initialState = getInitialState();
    Object.keys(initialState).forEach(key => {
        state[key] = initialState[key];
    });
}
