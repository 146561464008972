<template>
    <div class="line-number-textarea">
        <ElasticTextArea
            :name="name"
            :full-width="fullWidth"
            :modelValue="modelValue"
            :placeholder="placeholder"
            @update:modelValue="updateText($event)"
            @blur="$emit('blur')"
            ref="theTextArea"
        >
        </ElasticTextArea>
        <div v-if="isDisplayPlaceholder" class="line-number-textarea__placeholder">
            <slot name="placeholder">
                {{ placeholder }}
            </slot>
        </div>
    </div>
</template>

<script>
import ElasticTextArea from './ElasticTextArea';

export default {
    name: 'LineNumberTextArea',
    components: {
        ElasticTextArea
    },
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        name: {
            type: String,
            required: false
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            lines: [1]
        };
    },
    computed: {
        isDisplayPlaceholder() {
            return !this.modelValue;
        }
    },
    methods: {
        updateLineNumbers() {
            const value = this.modelValue;

            let lineCount = 1;
            if (value) {
                lineCount = value.split(/\r*\n/).length;
            }

            if (lineCount) {
                const lines = [];
                for (let i = 1; i <= lineCount; i++) {
                    lines.push(i);
                }
                this.lines = lines;
            } else {
                this.lines = [1];
            }
        },
        updateText(value) {
            this.$emit('update:modelValue', value);
            this.updateLineNumbers();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.line-number-textarea {
    display: flex;
    position: relative;
    overflow: auto;
    max-height: 15rem;
    background-color: $textarea-background-color;
    border: 2px solid $textarea-border-color;
    border-radius: 8px;
    padding: 15px;

    &__placeholder {
        position: absolute;
        left: 3px;
        top: -3px;
        padding: 15px;
        font-size: 0.9em;
        line-height: 24px;
        color: #aca9a9;
        background: transparent;
        pointer-events: none;
    }
}
.line-count-overlay {
    font-size: 1rem;
    line-height: 1.25;
    padding: 0 0.5rem;

    .line {
        margin: 0;
    }
}
textarea {
    // white-space: nowrap;
    // overflow: hidden;
    font-family: Arial;
    font-size: 1rem;
    line-height: 1.25;
    resize: vertical;
    background-color: transparent;
}
.full-width {
    width: 100%;
}
</style>
