<template>
    <div class="notes">
        <label for="textarea" class="notes__title h3">Notes</label>
        <ElasticTextArea
            class="notes__textarea"
            placeholder="Start typing..."
            :disabled="disableTextarea"
            v-model="noteText"
            bordered
            @onFocus="onFocus"
            id="textarea"
        />
        <div class="buttons" v-if="showSaveButton">
            <Button @click="onClickCancel" class="buttons__button" styleType="off">Cancel</Button>
            <Button @click="onClickSave" class="buttons__button" :disabled="disableTextarea"
                >Save</Button
            >
        </div>

        <div class="notes__items">
            <OverviewListItem
                class="notes__note"
                v-for="(note, key) in notes"
                :key="key"
                :title="getTitle(note)"
                :description="note.content"
                :date="note.createdAt && note.createdAt.toDateString()"
            />
        </div>
    </div>
</template>

<script>
import ElasticTextArea from '@/components-deprecated/ElasticTextArea';
import Button from '@/components-deprecated/Button';
import OverviewListItem from '@/components-deprecated/overview/OverviewListItem';

export default {
    name: 'Notes',

    components: {
        ElasticTextArea,
        Button,
        OverviewListItem
    },

    props: {
        notes: {
            type: Array,
            default: () => []
        },
        currentUserId: {
            type: [String, Number, Array],
            required: true
        },
        disableTextarea: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            noteText: '',
            showSaveButton: false
        };
    },

    methods: {
        onClickCancel() {
            this.showSaveButton = false;
            this.clearEnteredText();
        },
        onClickSave() {
            this.showSaveButton = false;
            if (this.disableTextarea) return;
            const text = this.noteText.trim();
            if (text.length !== 0) {
                this.$emit('save', text);
            }
        },
        clearEnteredText() {
            this.noteText = '';
        },
        getTitle(note) {
            if (this.currentUserId === note.author.id) {
                return 'You left a note';
            }

            return `${note.author.displayName} left a note`;
        },
        onFocus() {
            if (!this.showSaveButton) {
                this.showSaveButton = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.notes {
    &__title {
        text-transform: uppercase;
    }
    &__textarea {
        max-height: 150px;
        border-color: #696e80;
    }
    &__note {
        &:first-child {
            margin-top: 25px;
            border-top: 2px solid $border-color;
        }
    }
}

.buttons {
    display: flex;
    justify-content: flex-end;

    &__button {
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }
    }
}
</style>
