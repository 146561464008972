<template>
    <div class="container">
        <div v-if="showLoadingSpinner">
            <LoadingSpinner />
        </div>
        <div v-else class="manage-uploads">
            <div class="toolbar">
                <div class="filter-row">
                    <div class="filter-row__filter">
                        <SchoolDropDown @selected-school-id-updated="setSelectedSchool" />
                    </div>
                </div>
                <!-- <Button @click="onOpenUpload">New</Button> -->
            </div>
            <div class="table-header">
                <div class="table-column-header-medium">School</div>
                <div class="table-column-header-large">File</div>
                <div class="table-column-header-large">Upload Type</div>
                <div class="table-column-header-medium">Status</div>
                <div class="table-column-header-small" />
                <div class="table-column-header-small" />
                <div class="table-column-header-small" />
                <div class="table-column-header-small" />
            </div>
            <template v-for="upload in uploads.list" :key="upload.id">
                <ManageUploadsRow
                    :studentUpload="upload"
                    :uploadStatusOptions="uploadStatusOptions"
                    :uploadTypeOptions="uploadTypeOptions"
                    @refresh="listUploads"
                    @on-confirmation="onReceivedResults"
                />
            </template>
            <Paginator
                v-if="uploads.pagination.next != null"
                :loading="uploads.loadingNextPage"
                @next-page="uploads.addNextPage()"
            />
        </div>
        <ModalStaffStudentsUpload @results="onReceivedResults" @refresh="listUploads" />
        <ModalManageStudentUpload />
        <ModalLargeOperationResults />
        <ModalTagMapping />
        <ModalCsvVerify />
    </div>
</template>

<script>
import ModalStaffStudentsUpload from '@/components-deprecated/staff/ModalStaffStudentsUpload';
import ModalLargeOperationResults from '@/components-deprecated/admin/ModalLargeOperationResults';
import ModalManageStudentUpload from '@/components-deprecated/admin/ModalManageStudentUpload';
import ModalTagMapping from '@/components-deprecated/staff/ModalTagMapping';
import ModalCsvVerify from '@/components-deprecated/staff/ModalCsvVerify';
import Button from '@/components-deprecated/Button';
import Paginator from '@/components-deprecated/Paginator';

import StudentUploads from '@/services/studentUploads';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import ManageUploadsRow from '@/components-deprecated/staff/ManageUploadsRow';

import CollectionManager from '@/services/collectionManager';
import SchoolDropDown from '@/components-deprecated/inputs/SchoolDropDown';

export default {
    name: 'ManageUploads',
    components: {
        Button,
        LoadingSpinner,
        ManageUploadsRow,
        Paginator,
        ModalStaffStudentsUpload,
        ModalLargeOperationResults,
        ModalManageStudentUpload,
        ModalTagMapping,
        ModalCsvVerify,
        SchoolDropDown
    },
    data() {
        return {
            uploads: CollectionManager.create({
                ModelClass: StudentUploads
            }),

            uploadStatusOptions: [],
            uploadTypeOptions: [],
            showLoadingSpinner: false,
            selectedSchool: null
        };
    },
    async created() {
        this.showLoadingSpinner = true;

        this.uploads.filters = {
            ordering: '-status'
        };

        await this.listUploads();
        this.uploadStatusOptions = StudentUploads.options.STATUS_CHOICES;
        this.uploadTypeOptions = StudentUploads.options.UPLOAD_TYPE_CHOICES;
        this.showLoadingSpinner = false;
    },
    props: {
        refresh: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        selectedSchool() {
            let params = {
                ordering: '-status'
            };
            if (this.selectedSchool) {
                params.schools = this.selectedSchool;
            }
            this.uploads.filters = params;
            this.uploads.refresh();
        },
        async refresh(val) {
            if (val) {
                await this.uploads.refresh();
            }
        }
    },
    methods: {
        async listUploads() {
            await this.uploads.refresh();
        },
        onReceivedResults(results) {
            this.$modal.show('modal-large-operation-results', { results: results });
        },
        onOpenUpload() {
            this.$modal.show('modal-staff-students-upload');
        },
        setSelectedSchool(schoolId) {
            this.selectedSchool = schoolId;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.manage-uploads {
    margin: 2rem 3rem;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0rem;
    .filter-row {
        &__filter {
            display: flex;
            align-items: center;
            * {
                margin: 0 0.5rem;
            }
        }
    }
}

.table-header {
    display: flex;
    padding: 10px 20px;
    background-color: $white-blue;
}

.table-column-header {
    font-weight: bold;
}

.table-column-header-small {
    @extend .table-column-header;
    flex: 0.4;
}

.table-column-header-medium {
    @extend .table-column-header;
    flex: 0.7;
}

.table-column-header-large {
    @extend .table-column-header;
    flex: 1;
}
</style>
