<template>
    <div class="dashboard-container">
        <div class="status-container">
            <h2>Check Verification Status</h2>
            <div class="status-table-header">
                <div class="status-header-large">School</div>
                <div class="status-header-small">Twilio Status</div>
                <div class="status-header-small">Migration Status</div>
                <div class="status-header-small">Number</div>
                <div class="status-header-small">Action</div>
            </div>
            <div v-if="showLoadingSpinner">
                <LoadingSpinner />
            </div>
            <div v-else-if="showMessage">
                {{ message }}
            </div>
            <div class="school-table-rows" v-else>
                <div
                    v-for="school in schoolVerificationStatusList"
                    :key="school.schoolId"
                    class="status"
                >
                    <div class="status-details">
                        <div class="status-detail-cell-large">
                            {{ school.schoolName }}
                        </div>
                        <div class="status-detail-cell-small">
                            {{ snakeToTitle(school.twilioStatus) }}
                        </div>
                        <div class="status-detail-cell-small">
                            {{ displayMigrationStatus(school) }}
                        </div>
                        <div class="status-detail-cell-small">
                            {{ school.phoneNumber }}
                        </div>
                        <div class="status-detail-cell-small">
                            <Button
                                @click="selectSchoolHandler(school)"
                                v-if="displayMigrateButton(school) === true"
                            >
                                Migrate
                            </Button>
                        </div>
                    </div>
                </div>
                <Modal
                    v-if="isModalVisible"
                    title="Confirm Migration"
                    size="medium"
                    primaryButtonTitle="Confirm"
                    @secondary-button-clicked="isModalVisible = false"
                    @primary-button-clicked="confirmMigration"
                >
                    <div>
                        {{ message }}
                    </div>
                </Modal>
            </div>
        </div>
    </div>
</template>

<script>
import { startCase, toLower } from 'lodash';
import * as TwilioApi from '@/api/twilio';
import * as PhoneNumberRegistryApi from '@/api/phone-number-registry';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/Button';
import Modal from '@/components-deprecated/global/Modal';

export default {
    name: 'CheckVerification',
    components: {
        LoadingSpinner,
        Button,
        Modal
    },
    data() {
        return {
            showLoadingSpinner: true,
            schoolVerificationStatusList: null,
            message: '',
            showMessage: false,
            selectedSchool: '',
            isModalVisible: false
        };
    },
    created() {
        this.loadSchoolVerificationStatusList();
    },
    methods: {
        async loadSchoolVerificationStatusList() {
            try {
                const response = await TwilioApi.listTwilioSubAccountVerificationStatuses();
                if (response.message) {
                    this.message = response.message;
                    this.showMessage = true;
                } else {
                    this.schoolVerificationStatusList = response;
                }
            } catch (error) {
                console.error('Failed to load verification statuses:', error);
                this.message = 'Whoops. Could not load verification statuses.';
                this.showMessage = true;
            } finally {
                this.showLoadingSpinner = false;
            }
        },
        snakeToTitle(snakeCaseString) {
            return startCase(toLower(snakeCaseString));
        },
        displayMigrationStatus(school) {
            if (school.twilioStatus === 'NOT_SUBMITTED') {
                return 'n/a';
            }
            return school.migrationStatus ? 'Migrated' : 'Not migrated';
        },
        displayMigrateButton(school) {
            if (school.twilioStatus === 'TWILIO_APPROVED' && !school.migrationStatus) {
                return true;
            }

            return false;
        },
        selectSchoolHandler(school) {
            this.selectedSchool = school;
            this.isModalVisible = true;
            this.message = `Are you sure you want to migrate ${this.selectedSchool.schoolName}?`;
        },
        async confirmMigration() {
            try {
                const { logs, errors } = await PhoneNumberRegistryApi.migrateSchoolToPhoneRegistry(
                    this.selectedSchool.schoolId
                );
                // migrateSchoolToPhoneRegistry returns a one element array if an error occurs
                if (errors && errors[0]) {
                    throw errors[0];
                }
                // migrateSchoolToPhoneRegistry returns a one element array if successful
                this.$Alert.alert({
                    type: 'success',
                    message: `${logs[0]}. Refreshing...`,
                    timeout: 3000
                });
                this.loadSchoolVerificationStatusList();
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: error,
                    timeout: 3000
                });
            } finally {
                this.isModalVisible = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.school-table-rows {
    width: 100%;
}
.status {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
}
.status-table-header {
    display: flex;
    padding: 10px 20px;
    background-color: #f4f7f9;
    justify-content: space-evenly;
    font-weight: bold;
    width: 100%;
    margin-top: 1rem;
}
.status:hover {
    background-color: #f4f7f9;
}
.status-header-small {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.status-header-large {
    flex: 0.9;
}
.status-details {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 20px;
    width: 100%;
}
.status-detail-cell-small {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
}
.status-detail-cell-large {
    flex: 0.9;
}
</style>
