<template>
    <div class="sitemap">
        <h1>Sitemap</h1>
        <ul>
            <li>
                <router-link :to="{ name: 'Home' }">
                    Home
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'ChatbotMessagingPage' }">
                    Chatbot Messaging
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'EngagementAnalyticsPage' }">
                    Engagement Analytics
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'InterventionsPage' }">
                    Interventions
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'AnalysisStudents' }">
                    Students
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'InsightsPage' }">
                    Retention Insights
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'KnowledgeAnalyticsPage' }">
                    Knowledge Analytics
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Alerts' }">
                    Alerts
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Resources' }">
                    Resources
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'Settings' }">
                    Settings
                </router-link>
                <ul>
                    <!-- <li>
                        <router-link :to="{ name: 'ManageStudents' }">
                            Manage Students
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'ManageCoAdmins' }">
                            Manage Co-Admins
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'KnowledgeBase' }">
                            Manage Knowledge Base
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'ManageTags' }">
                            Manage Tags
                        </router-link>
                    </li> -->
                    <li>
                        <router-link :to="{ name: 'KeyInformation' }">
                            Account Information
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'AccessibilityStatement' }">
                            Accessibility Statement
                        </router-link>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Sitemap',
    mounted() {}
};
</script>

<style lang="scss" scoped>
.sitemap {
    width: 100%;
    margin-top: 4rem;
    h1 {
        text-align: center;
        margin-bottom: 2rem;
    }
    a {
        font-size: 1.2rem;
    }
    ul {
        text-align: center;
    }
}
</style>
