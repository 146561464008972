import { User } from '../users';
import api from './api';

import { objectToCamelCase, objectToSnakeCase } from '../utils';

export default class Note {
    static api = api.create(Note);

    constructor(note) {
        // eslint-disable-next-line no-param-reassign
        note = note || {};
        const {
            id = null,
            content = '',
            student = '',
            createdAt = null,
            updatedAt = null,
            author = null
        } = note;
        Object.assign(this, {
            id,
            content,
            student,
            author: User.fromAPI(author),
            createdAt: new Date(createdAt),
            updatedAt: new Date(updatedAt)
        });
    }

    static create(opts) {
        return new Note(opts);
    }

    static fromAPI(json) {
        return new Note(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }
}
