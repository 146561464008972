<template>
    <div class="textarea-container">
        <textarea
            :rows="rows"
            :placeholder="placeholder"
            :value="modelValue"
            @input="onInput($event.target.value)"
            :disabled="disabled"
        />
    </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
    name: 'TextArea',
    props: {
        placeholder: {
            type: String,
            required: false
        },
        // auto-bound to v-model
        modelValue: {
            type: String,
            required: true
        },
        debounce: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 5
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.onInput = debounce(this.onInput, this.debounce ? 1000 : 0);
    },
    methods: {
        onInput(value) {
            this.$emit('update:modelValue', value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.textarea-container {
    position: relative;
    textarea {
        width: 100%;

        resize: none;
        outline: none;
        font-family: $base-font-family;
        font-size: 1rem;
        letter-spacing: $base-letter-spacing;
        border-radius: 0.4rem;
        border: $base-border-width solid $secondary-gray;
        background-color: $white-blue;
        padding: 0.5rem 4rem 0.5rem 1rem;
        min-height: 0;
        min-width: 0;
    }
}
</style>
