import axios from 'axios';

import { API_BASE, apiErrorHandler } from '../api';
import Auth from '../auth';
import CSRF from '../csrf';
import { objectToSnakeCase } from '../utils';

const MESSAGES_ENDPOINT = `${API_BASE}messages/`;
const STUDENT_MESSAGES_ENDPOINT = `${API_BASE}student-messages/`;
const CHATBOT_MESSAGES_ENDPOINT = `${API_BASE}chatbot-messages/`;

export class MessageAPI {
    /**
     * Initialize a `MessageAPI`` instance.
     *
     * @param {class} cls  The class to use to create Message objects.
     **/
    constructor(cls) {
        this.cls = cls;
    }

    /**
     * Factory method to create a new instance of `MessageAPI`.
     *
     * @param {class} cls      The class to use to create Message objects.
     **/
    static create(cls) {
        return new MessageAPI(cls);
    }

    /*
     * Retrieve a list of Messages from staff-only endpoint.
     */
    async list(params = {}) {
        const url = MESSAGES_ENDPOINT;
        const options = {
            headers: { ...CSRF.getHeaders() },
            params: objectToSnakeCase(params),
            ...Auth.getHeaders()
        };

        const promise = axios
            .get(url, options)
            .then(response => {
                const data = response.data.results ? response.data.results : response.data;
                return {
                    ...response.data,
                    results: data.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Message API Error' }));

        return promise;
    }

    async export(params = {}) {
        const url = `${MESSAGES_ENDPOINT}download/`;
        const options = {
            responseType: 'blob',
            headers: { ...CSRF.getHeaders() },
            params: objectToSnakeCase(params),
            ...Auth.getHeaders()
        };

        // Data stream is accessed by creating a link on the fly and programmatically clicking it, so that
        // it opens a new window and executes an automatic download of the file.
        const promise = axios
            .get(url, options)
            .then(response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data]));
                let file_name = `messages_export_${new Date().toUTCString()}.csv`;
                link.setAttribute('download', file_name + '.csv');
                link.click();
            })
            .catch(apiErrorHandler({ apiName: 'Export Messages API Error' }));
        return promise;
    }
}

export class StudentMessageAPI {
    /**
     * Initialize a `StudentMessageAPI`` instance.
     *
     * @param {class} cls  The class to use to create StudentMessage objects.
     **/
    constructor(cls) {
        this.cls = cls;
    }

    /**
     * Factory method to create a new instance of `StudentMessageAPI`.
     *
     * @param {class} cls      The class to use to create StudentMessage objects.
     **/
    static create(cls) {
        return new StudentMessageAPI(cls);
    }

    /*
     * Retrieve a list of StudentMessages (incoming messages to the chatbot)
     * from staff-only endpoint.
     */
    async list(params = {}) {
        const url = STUDENT_MESSAGES_ENDPOINT;
        const options = {
            headers: { ...CSRF.getHeaders() },
            params: objectToSnakeCase(params),
            ...Auth.getHeaders()
        };

        const promise = axios
            .get(url, options)
            .then(response => {
                const data = response.data.results ? response.data.results : response.data;
                return {
                    ...response.data,
                    results: data.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List StudentMessage API Error' }));

        return promise;
    }
}

export class ChatbotMessageAPI {
    /**
     * Initialize a `ChatbotMessageAPI`` instance.
     *
     * @param {class} cls  The class to use to create ChatbotMessage objects.
     **/
    constructor(cls) {
        this.cls = cls;
    }

    /**
     * Factory method to create a new instance of `ChatbotMessageAPI`.
     *
     * @param {class} cls      The class to use to create ChatbotMessage objects.
     **/
    static create(cls) {
        return new ChatbotMessageAPI(cls);
    }

    /*
     * Retrieve a list of ChatbotMessages (outgoing messages to students)
     * from staff-only endpoint.
     */
    async list(params = {}) {
        const url = CHATBOT_MESSAGES_ENDPOINT;
        const options = {
            headers: { ...CSRF.getHeaders() },
            params: objectToSnakeCase(params),
            ...Auth.getHeaders()
        };

        const promise = axios
            .get(url, options)
            .then(response => {
                const data = response.data.results ? response.data.results : response.data;
                return {
                    ...response.data,
                    results: data.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List ChatbotMessage API Error' }));

        return promise;
    }
}
