import api from './api';
import { objectToCamelCase, objectToSnakeCase } from '../utils';

export default class AlertType {
    static api = api.create(AlertType);

    constructor(answer) {
        const {
            id = null,
            datetimeCreated = null,
            school = '',
            emailAddresses = '',
            triggerCategory = '',
            content = ''
        } = answer;
        Object.assign(this, {
            id,
            datetimeCreated: new Date(datetimeCreated),
            school,
            emailAddresses,
            triggerCategory,
            content
        });
    }

    static create(opts) {
        return new AlertType(opts);
    }

    static fromAPI(json) {
        return new AlertType(objectToCamelCase(json));
    }

    static toAPI(alertType, fields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = alertType[field];
            });
        } else {
            data = alertType;
        }

        return objectToSnakeCase(data);
    }
    static checkIsInstance(obj, name) {
        if (!(obj instanceof AlertType)) {
            throw Error(`${name} must be an instance of the Alert class.`);
        }
    }
}
