import axios from 'axios';
import { apiErrorHandler } from '../services/api';
import * as Api from './index';

export const listTypes = async () => {
    const url = await Api.getIntegrationBase('/integration-type');
    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data.reduce((acc, typeObject) => {
                switch (typeObject.name.trim().toLowerCase()) {
                    case 'add':
                    case 'auto-integration':
                    case 'update':
                    case 'delete':
                        acc.push(typeObject);
                        break;
                }
                return acc;
            }, []);
        })
        .catch(
            apiErrorHandler({
                apiName: 'List Integration Type error'
            })
        );
};
