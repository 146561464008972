import BackgroundOperationAPI from './api';
import { objectToCamelCase, objectToSnakeCase } from '../utils';
import options from './options';

export default class BackgroundOperation {
    static api = BackgroundOperationAPI.create(BackgroundOperation);
    static readOnlyFields = ['id'];
    static options = options;
    constructor({
        id = '',
        title = '',
        status = '',
        itemCount = null,
        itemsProcessedCount = null,
        datetimeCreated = null,
        startedAt = null,
        completedAt = null,
        percentComplete = null,
        isComplete = null,
        result = null,
        error = null,
        file = null
    } = {}) {
        Object.assign(this, {
            id,
            title,
            status,
            itemCount,
            itemsProcessedCount,
            datetimeCreated,
            startedAt,
            completedAt,
            percentComplete,
            isComplete,
            result,
            error,
            file
        });
    }

    static create(opts) {
        return new BackgroundOperation(opts);
    }

    static fromAPI(json = {}) {
        return new BackgroundOperation(objectToCamelCase(json));
    }

    /**
     * Prep data for the API. Can elect to include or exlude specific fields.
     * The readOnlyFields are always excluded.
     **/
    static toAPI(backgroundOperation, fields = [], excludeFields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = backgroundOperation[field];
            });
        } else {
            data = backgroundOperation;
        }

        excludeFields = [...BackgroundOperation.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }
}
