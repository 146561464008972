<template>
    <div class="container resources-page">
        <div class="container__content">
            <ScrollWrapper class="box-no-padding scroll-wrapper resources-wrapper">
                <Resources
                    :resources="resources"
                    :selectedItem="selectedResource"
                    @selectedSubsection="sectionDetails"
                />
            </ScrollWrapper>
            <ScrollWrapper class="box-no-padding scroll-wrapper resources-details-wrapper">
                <ResourceDetail
                    v-show="
                        selectedResource !== null && selectedResource.subSection !== 'My Documents'
                    "
                    :resource-title="selectedResource.subSection"
                    :resource-description="sectionDescription"
                    :resource-topics="selectedResource.topics"
                    :button-text="selectedResource.buttonText"
                    :copy-button="selectedResource.copyButton"
                    :button-link="selectedResource.buttonLink"
                />
                <SchoolDocuments
                    v-show="
                        selectedResource !== null && selectedResource.subSection === 'My Documents'
                    "
                    :resource-title="selectedResource.subSection"
                />
            </ScrollWrapper>
        </div>
    </div>
</template>

<script>
import ScrollWrapper from '@/components-deprecated/ScrollWrapper';
import ResourceDetail from '@/components-deprecated/ResourceDetail';
import SchoolDocuments from '@/components-deprecated/SchoolDocuments';
import Resources from '@/components-deprecated/overview/Resources';
import resources from '@/resources.json';
export default {
    name: 'ResourcePage',
    components: {
        ScrollWrapper,
        ResourceDetail,
        Resources,
        SchoolDocuments
    },

    data() {
        return {
            resources,
            selectedResource: resources[0].subSections[0],
            sectionDescription: resources[0].description
        };
    },

    methods: {
        sectionDetails(resource, sectionDescription) {
            this.sectionDescription = sectionDescription;
            this.selectedResource = resource;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.resources-page {
    padding-top: 50px;
}

.scroll-wrapper :deep(.scroll-wrapper__elem) {
    height: calc(100vh - 160px);
}

.container__content {
    display: flex;
    justify-content: space-evenly;
}

.resources-wrapper {
    margin-right: 15px;
    width: 30%;
}

.resources-details-wrapper {
    width: 65%;
}

.loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}
:deep(.table-header) {
    justify-content: flex-end;
}
</style>
