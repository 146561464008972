import { objectToCamelCase, objectToSnakeCase } from '../utils';
import Student from '../students';
import School from '../schools';
import { MessageAPI, StudentMessageAPI, ChatbotMessageAPI } from './api';
import options from './options';

export class Message {
    static api = MessageAPI.create(Message);
    static options = options;

    constructor(message) {
        const {
            id = null,
            datetimeCreated = null,
            text = '',
            status = '',
            studentRef = null,
            schoolRef = null
        } = message;
        Object.assign(this, {
            id,
            datetimeCreated: new Date(datetimeCreated),
            text,
            status,
            studentRef: Student.fromAPI(studentRef),
            schoolRef: School.fromAPI(schoolRef)
        });
    }

    static create(opts) {
        return new Message(opts);
    }

    static fromAPI(json) {
        return new Message(objectToCamelCase(json));
    }

    static toAPI(message, fields = [], excludeFields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = message[field];
            });
        } else {
            data = message;
        }

        excludeFields = [...Message.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }
    static checkIsInstance(obj, name) {
        if (!(obj instanceof Message)) {
            throw Error(`${name} must be an instance of the Message class.`);
        }
    }
}

export class StudentMessage {
    static api = StudentMessageAPI.create(StudentMessage);
    static options = options;

    constructor(message) {
        const {
            id = null,
            datetimeCreated = null,
            text = '',
            respondentRef = null,
            schoolRef = null
        } = message;
        Object.assign(this, {
            id,
            datetimeCreated: new Date(datetimeCreated),
            text,
            studentRef: Student.fromAPI(respondentRef),
            schoolRef: School.fromAPI(schoolRef)
        });
    }

    static create(opts) {
        return new StudentMessage(opts);
    }

    static fromAPI(json) {
        return new StudentMessage(objectToCamelCase(json));
    }

    static toAPI(message, fields = [], excludeFields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = message[field];
            });
        } else {
            data = message;
        }

        excludeFields = [...StudentMessage.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }
    static checkIsInstance(obj, name) {
        if (!(obj instanceof StudentMessage)) {
            throw Error(`${name} must be an instance of the StudentMessage class.`);
        }
    }
}

export class ChatbotMessage {
    static api = ChatbotMessageAPI.create(ChatbotMessage);
    static options = options;

    constructor(message) {
        const {
            id = null,
            datetimeCreated = null,
            text = '',
            studentRef = null,
            schoolRef = null
        } = message;
        Object.assign(this, {
            id,
            datetimeCreated: new Date(datetimeCreated),
            text,
            studentRef: Student.fromAPI(studentRef),
            schoolRef: School.fromAPI(schoolRef)
        });
    }

    static create(opts) {
        return new ChatbotMessage(opts);
    }

    static fromAPI(json) {
        return new ChatbotMessage(objectToCamelCase(json));
    }

    static toAPI(message, fields = [], excludeFields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = message[field];
            });
        } else {
            data = message;
        }

        excludeFields = [...ChatbotMessage.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }
    static checkIsInstance(obj, name) {
        if (!(obj instanceof ChatbotMessage)) {
            throw Error(`${name} must be an instance of the ChatbotMessage class.`);
        }
    }
}
