import axios from 'axios';
import * as Api from './index';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';

export const getChatbotTemplates = ({ queryParams, paginationUrl, errorHandlerOptions = {} }) => {
    const url = paginationUrl || Api.getv2ApiBase('/chatbot-template');
    const defaultOptions = Api.getOptions();
    const params = {};

    if (typeof queryParams === 'object') {
        if (typeof queryParams.reusable === 'boolean') {
            params.reusable = queryParams.reusable;
        }

        if (typeof queryParams.page === 'number') {
            params.page = queryParams.page;
        }

        if (typeof queryParams.search === 'string' && queryParams.search.length) {
            params.search = queryParams.search;
        }

        if (Array.isArray(queryParams.labels) && queryParams.labels.length) {
            params.labels = queryParams.labels.join(',');
        }
    }

    return axios
        .get(url, { ...defaultOptions, params })
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                overrideMessage: 'There was a problem getting Chatbot Templates. Please try again.',
                ...errorHandlerOptions
            })
        );
};

export const getChatbotTemplate = ({ id, errorHandlerOptions = {} }) => {
    const url = Api.getv2ApiBase(`/chatbot-template/${id}`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                overrideMessage:
                    'There was a problem getting the Chatbot Template. Please try again.',
                ...errorHandlerOptions
            })
        );
};

export const updateChatbotFlowTemplate = ({ id, data, errorHandlerOptions = {} }) => {
    const url = Api.getv2ApiBase(`/chatbot-template/${id}`);
    const options = Api.getOptions();

    return axios
        .patch(url, data, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                overrideMessage:
                    'There was a problem updating the Chatbot Template. Please try again.',
                ...errorHandlerOptions
            })
        );
};

export const updateChatbotFlowTemplates = ({ chatbotFlowTemplates, errorHandlerOptions = {} }) => {
    return Promise.all(
        (Array.isArray(chatbotFlowTemplates) ? chatbotFlowTemplates : []).map(template => {
            const { id, data } = template;
            return updateChatbotFlowTemplate({
                id,
                data,
                errorHandlerOptions: {
                    rethrow: true,
                    enableAlert: false
                }
            });
        })
    ).catch(error =>
        v2ApiErrorHandler({
            error,
            overrideMessage:
                'There was a problem updating the Chatbot Template(s). Please try again.',
            ...errorHandlerOptions
        })
    );
};
