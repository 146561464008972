<template>
    <div class="student-item">
        <div>
            {{ student.firstName + ' ' + student.lastName }}
        </div>
        <div>
            {{ student.email }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ManageAdminStudentsListItem',
    props: {
        student: {
            required: true,
            validator: value => {
                return (
                    typeof value === 'object' &&
                    typeof value.firstName === 'string' &&
                    typeof value.lastName === 'string' &&
                    typeof value.email === 'string' &&
                    typeof value.id === 'string'
                );
            }
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        viewStudent() {
            this.$router.push({ name: 'StudentDetail', params: { id: this.student.id } });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.student-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    & > * {
        width: 50%;
    }
}
</style>
