import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';
import { strictEqual } from 'assert';

export const findSchoolDocumentById = async id => {
    try {
        strictEqual(typeof id, 'string');

        const url = Api.getv2ApiBase(`/school-document/${id}`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error
        });
    }
};

export const getSignedUrlForDownload = async id => {
    try {
        strictEqual(typeof id, 'string');

        const url = Api.getv2ApiBase(`/school-document/${id}/download`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error
        });
    }
};

export const downloadSchoolDocument = async ({
    signedUrl,
    schoolDocument,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof signedUrl, 'string');
        strictEqual(typeof schoolDocument, 'object');

        const response = await axios({
            url: signedUrl,
            method: 'GET',
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${schoolDocument.uploadedFileName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const listSchoolDocumentsBySchoolId = async schoolId => {
    try {
        strictEqual(typeof schoolId, 'string');

        const url = Api.getv2ApiBase(`/school-documents/${schoolId}`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error
        });
    }
};

export const getSignedUrlForUpload = async ({ schoolDocument, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof schoolDocument, 'object');

        const url = Api.getv2ApiBase(`/school-document/upload`);
        const options = Api.getOptions();

        const response = await axios.post(url, schoolDocument, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const uploadToSignedUrl = async ({ signedUrl, fileToUpload, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof signedUrl, 'string');
        strictEqual(typeof fileToUpload, 'object');

        // need to remove this header value for the upload to pass
        const config = {
            transformRequest: [
                (data, headers) => {
                    delete headers.common['X-Edsights-Version'];
                    return data;
                }
            ]
        };

        const response = await axios.put(signedUrl, fileToUpload, config);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const createSchoolDocument = async ({ schoolDocument, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof schoolDocument, 'object');

        const url = Api.getv2ApiBase(`/school-document`);
        const options = Api.getOptions();

        const response = await axios.post(url, schoolDocument, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const deleteSchoolDocument = async ({ id, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof id, 'string');

        const url = Api.getv2ApiBase(`/school-document/${id}`);
        const options = Api.getOptions();

        const response = await axios.delete(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};
