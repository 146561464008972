<template>
    <modal
        classes="v--modal"
        name="modal-create-mass-note"
        height="auto"
        :adaptive="true"
        width="800"
        :scrollable="true"
        :clickToClose="true"
        :stack="false"
        @closed="closeModal"
        @before-open="beforeOpen"
    >
        <div class="create-note" aria-labelledby="create-modal-heading">
            <div class="create-note__header" id="create-modal-heading">
                Create Note
            </div>
            <div class="create-note__body">
                <div class="create-note__details-container">
                    <textarea
                        class="create-note__text-area"
                        v-model="content"
                        placeholder="Type note..."
                        ref="input"
                    ></textarea>
                    <div class="create-note__associated-row">
                        Associated with {{ getStudentNames.join(', ') }}
                    </div>
                </div>
                <div class="create-note__buttons-container">
                    <button
                        class="create-note__button create-note__button--create"
                        @click="closeModal"
                    >
                        Cancel
                    </button>
                    <button
                        class="create-note__button create-note__button--create"
                        :disabled="!validateFields"
                        @click="createNote"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'ModalCreateNote',
    props: {
        success: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            content: '',
            selectedStudentsCount: 1,
            studentInfo: []
        };
    },
    computed: {
        validateFields() {
            if (this.content) {
                return true;
            }
            return false;
        },
        getStudentIds() {
            return this.studentInfo.map(s => s.id);
        },
        getStudentNames() {
            return this.studentInfo.map(s => s.firstName);
        }
    },
    methods: {
        beforeOpen(event) {
            this.interventionsTemplateId = event.params.interventionsTemplateId;
            this.studentInfo = event.params.students;
        },
        opened() {
            this.$refs.input.focus();
        },
        beforeClose() {
            this.content = '';
        },
        closeModal() {
            this.content = '';
            this.$modal.hide('modal-create-mass-note');
        },

        createNote() {
            if (!this.validateFields) {
                return;
            }
            const note = {
                content: this.content,
                students: this.getStudentIds
            };

            this.$emit('create-mass-note', note, this.interventionsTemplateId);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.create-note {
    cursor: default;

    &__header {
        color: white;
        background-color: #1c8efb;
        padding: 20px 25px;
        font-size: 20px;
    }

    &__body {
        padding: 35px 30px;
    }

    &__details-container {
    }

    &__text-area {
        resize: none;
        height: 340px;
        font-size: 16px;
        border: none;
        color: #696e80;
        font-family: $base-font-family;
        padding: 5px;
        margin: -5px;
    }

    &__associated-row {
        color: #96adc3;
        font-size: 12px;
        text-align: right;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f3f3;
    }

    &__buttons-container {
        display: flex;
        margin-top: 60px;
    }

    &__button {
        width: 90px;
        height: 38px;
        border: 1px solid #e7edf2;
        background-color: #e7edf2;
        color: #b7c1cc;
        border-radius: 3px;
        font-size: 15px;
        cursor: pointer;
        outline: none;

        &--create {
            margin-left: 10px;
            border-color: #46a1fc;
            color: #46a1fc;
            background-color: white;

            &[disabled] {
                background-color: #f9fbfe;
                color: #b7c1cc;
                border: 1px solid #e7edf2;
                cursor: not-allowed;
            }
        }

        &:hover {
            background-color: #f9fbfe;
        }
    }
}
textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder {
    color: #8ca4bd;
}
</style>
