<template>
    <div class="container">
        <ManageIntegrationUploads :integrationOrigin="integrationOrigin" />
    </div>
</template>

<script>
import ManageIntegrationUploads from '@/views/ManageIntegrationUploads';

export default {
    name: 'UploadsPage',
    props: { integrationOrigin: String },
    components: {
        ManageIntegrationUploads
    }
};
</script>

<style scoped></style>
