const REGEXP_RELATIVE_PATH = /^https?:\/\/[^/]+\/(.*)/;

// Function returns relative path of the passed url
export function getRelativePath(url) {
    const match = REGEXP_RELATIVE_PATH.exec(url);
    if (match != null) {
        return `/${match[1]}`;
    }
    return '';
}
