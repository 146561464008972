<template>
    <div class="card-container">
        <div class="card-header">
            <div class="card-title">
                {{ title }}
                <img
                    v-if="titleTooltip"
                    v-tooltip="titleTooltip"
                    class="tooltip"
                    src="@/assets/icons/question-mark.svg"
                    alt="Question mark"
                />
            </div>
            <div class="card-button" v-if="headerButtonText">
                <button @click="onHeaderButtonClick">{{ headerButtonText }} ></button>
            </div>
        </div>
        <div class="card-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        titleTooltip: {
            type: String,
            required: false
        },
        headerButtonText: {
            type: String,
            required: false
        }
    },
    methods: {
        onHeaderButtonClick() {
            this.$emit('headerButtonClick');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.card-container {
    border: 1px solid $secondary-gray;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    background-color: white;

    min-width: 450px;
    min-height: 200px;

    .card-header {
        display: flex;
        justify-content: space-between;
        .card-title {
            display: flex;
            align-items: center;
            font-weight: bold;
            .tooltip {
                margin-left: 4px;
                margin-bottom: 2px;
                width: 12px;

                // Hard coding a low z-index since if the main EdSights navigation had an open dropdown
                // that covered this element, it would show through/on top of the dropdown menu.
                z-index: 0;
            }
        }

        .card-button {
            button {
                cursor: pointer;
                background-color: transparent;
                border: 0;
            }
        }
    }
}
</style>
