<template>
    <div class="tag">
        <div class="tag__name text-bold">
            {{ name }}
        </div>
        <div class="tag__count text-bold">
            {{ studentCount }}
        </div>
        <div class="tag__actions text-bold">
            {{ actions }}
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';

export default {
    name: 'TagListItem',

    components: {
        Button
    },

    props: {
        name: {
            type: String,
            default: 'Tag Title'
        },
        studentCount: {
            type: String,
            default: 'Student Count'
        },
        actions: {
            type: String,
            default: 'Actions'
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.tag {
    display: flex;
    align-items: center;
    padding: 10px 45px;
    background-color: $white-blue;

    &__name {
        width: 25%;
    }

    &__count {
        width: 25%;
    }

    &__actions {
        width: 25%;
    }
}
</style>
