<template>
    <Modal
        :title="title"
        primaryButtonTitle="Save"
        :showPrimaryButtonLoadingSpinner="saving"
        @secondary-button-clicked="close"
        @primary-button-clicked="save"
    >
        <div>
            Are you sure you want to unlink the tag(s)?
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';

export default {
    name: 'RemoveAssignedTagModal',
    components: {
        Modal
    },
    props: {
        adminDisplayName: {
            type: String,
            required: true
        },
        saving: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        save() {
            this.$emit('save');
        }
    },
    computed: {
        title() {
            if (typeof this.adminDisplayName === 'string') {
                return `Unlink tags from ${this.adminDisplayName}`;
            }
            return 'Unlink tags';
        }
    }
};
</script>

<style lang="scss" scoped></style>
