export const RESET_INTERVENTIONS = 'RESET_INTERVENTIONS';
export const UPDATE_INTERVENTION_PARTIAL = 'UPDATE_INTERVENTION_PARTIAL';
export const SET_INTERVENTIONS = 'SET_INTERVENTIONS';
export const APPEND_INTERVENTIONS = 'APPEND_INTERVENTIONS';

export default {
    [RESET_INTERVENTIONS](state) {
        state.interventions = [];
        state.pagination = null;
        state.interventionsCount = 0;
    },
    [SET_INTERVENTIONS](state, { interventions, pagination, count }) {
        state.interventionsCount = count;
        state.pagination = pagination;
        state.interventions = interventions;
    },
    [APPEND_INTERVENTIONS](state, { interventions, pagination }) {
        state.pagination = pagination;
        state.interventions = [...state.interventions, ...interventions];
    },
    [UPDATE_INTERVENTION_PARTIAL](state, { id, intervention }) {
        const interventionIndex = state.interventions.findIndex(
            intervention => intervention.id === id
        );
        if (interventionIndex === -1) return;
        state.interventions[interventionIndex] = {
            ...state.interventions[interventionIndex],
            ...intervention
        };
    }
};
