import axios from 'axios';
import Auth from '../auth';
import { API_BASE, apiErrorHandler } from '../api';

import { objectToCamelCase, objectToSnakeCase } from '../utils';

// Endpoint
const INTERVENTION_TEMPLATE_ENDPOINT = `${API_BASE}interventions/`;

export default class InterventionTemplateAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new InterventionTemplateAPI(cls);
    }

    async retrieve(id) {
        const url = `${INTERVENTION_TEMPLATE_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))

            .catch(apiErrorHandler({ apiName: 'Retrieve Intervention Template Api Error' }));

        return promise;
    }

    async bulkMarkStudentsAsActionTaken(templateId, params) {
        // takes in the templateid to be passed as a uri param and the ids of the specific interventions to mark as data
        const url = `${INTERVENTION_TEMPLATE_ENDPOINT}${templateId}/bulk-mark-action-taken/`;
        const data = objectToSnakeCase(params);
        const options = {
            headers: await Auth.getHeaders().headers
        };

        return axios
            .patch(url, data, options)
            .then(response => response.data)
            .catch(
                apiErrorHandler({
                    apiName: 'Bulk mark action as taken API error'
                })
            );
    }
    async bulkMarkAsViewed(templateId, interventionIds) {
        const url = `${INTERVENTION_TEMPLATE_ENDPOINT}/${templateId}/bulk-mark-as-viewed/`;
        const data = objectToSnakeCase(interventionIds);

        const options = {
            headers: await Auth.getHeaders().headers
        };

        return axios
            .patch(url, data, options)
            .then(response => response.data)
            .catch(
                apiErrorHandler({
                    apiName: 'Bulk as viewed API error'
                })
            );
    }

    async list(params) {
        const url = INTERVENTION_TEMPLATE_ENDPOINT;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            },
            params
        };

        const promise = axios
            .get(url, options)
            .then(response => ({
                ...response.data,
                results: response.data.results.map(this.cls.fromAPI)
            }))
            .catch(apiErrorHandler({ apiName: 'List Intervention API error' }));
        return promise;
    }

    async listNextPage(url) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };
        const response = await axios.get(url, options);
        return {
            ...response.data,
            results: response.data.results.map(Intervention.fromAPI)
        };
    }

    markAsViewed(interventionId) {
        const url = `${INTERVENTION_TEMPLATE_ENDPOINT}/${interventionId}/mark_viewed/`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };
        return axios.post(url, null, options);
    }

    async create(obj) {
        const url = INTERVENTION_TEMPLATE_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = this.cls.toAPI(obj);

        const promise = axios
            .post(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Create Intervention Template Api Error' }));
        return promise;
    }
    async update(obj) {
        const url = `${INTERVENTION_TEMPLATE_ENDPOINT}${obj.id}/`;
        const data = this.cls.toAPI(obj);
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(
                apiErrorHandler({
                    apiName: 'Update Intervention Template API error'
                })
            );
        return promise;
    }

    async delete(id) {
        const url = `${INTERVENTION_TEMPLATE_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .delete(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Delete Intervention Template Api Error' }));
        return promise;
    }
}
