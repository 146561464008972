<template>
    <div class="popover-container">
        <div class="trigger-container" @click="toggle">
            <slot name="trigger"></slot>
        </div>
        <OverlayPanel ref="overlayPanel" :pt="pt" unstyled>
            <div class="content-container">
                <slot name="content"></slot>
            </div>
        </OverlayPanel>
    </div>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel';

export default {
    name: 'Popover',
    components: { OverlayPanel },
    data() {
        return {
            pt: {
                root: {
                    class: ['primevue-overlaypanel-root']
                },
                content: {
                    class: ['primevue-overlaypanel-content']
                }
            }
        };
    },
    methods: {
        toggle(event) {
            this.$refs.overlayPanel.toggle(event);
        }
    }
};
</script>

<style lang="scss">
// NOTE: This component makes use of unscoped, global styles since the underlying PrimeVue
// component works with teleports and are unable to be styled in a scoped way. For this reason
// we're being overly verbose with class names to avoid styling anything other than this file's contents.
.primevue-overlaypanel-root {
    margin-top: 10px;
    background: #ffffff;
    color: #334155;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

.primevue-overlaypanel-content {
    padding: 0.75rem;
}

// Put any non-global styles here:
.popover-container {
}
</style>
