<template>
    <div>
        <div v-if="faqs.length === 0">
            <h2>No FAQs to show!</h2>
        </div>

        <div v-else v-for="subtopic in topic.subtopics" :key="subtopic.id">
            <h2 class="topic-header" v-if="faqs.filter(faqsFilter.bind(null, subtopic)).length > 0">
                {{ topic.name }} / {{ subtopic.name }}
            </h2>
            <div
                v-for="faq in faqs.filter(faqsFilter.bind(null, subtopic))"
                :key="faq.id"
                class="faq-details"
            >
                <div class="flex-between">
                    <div class="faq-question">
                        {{ faq.question }}
                    </div>

                    <div class="flex-between faq-answer" v-if="selectedFaqInstance.faq === faq.id">
                        <div style="display: flex; position: relative;">
                            <EmojiPicker
                                style="padding-top: 2rem;"
                                @onSelection="selectEmojiHandler"
                            />

                            <div>
                                <p class="description">
                                    Please answer the question above in a short, SMS-friendly way.
                                </p>

                                <ElasticTextArea
                                    v-model="selectedFaqInstance.answer"
                                    class="answer-text-area"
                                    bordered
                                />
                                <FieldError :errors="errors" name="answer" />

                                <div style="display: flex;">
                                    <span style="padding-right: 1.0rem">
                                        Status
                                    </span>
                                    <TransitionDropdown
                                        style="width: 125px"
                                        :choices="statusChoices"
                                        :selected="
                                            statusChoices.find(
                                                s => s.id === selectedFaqInstance.status
                                            ) || {}
                                        "
                                        @on-select="statusChangeHandler"
                                    />
                                </div>

                                <div class="button-container">
                                    <LoadingSpinnerButton
                                        v-if="!selectedFaqInstance.id"
                                        class="save-btn"
                                        @click="$emit('create-faq-instance')"
                                        text="Create"
                                        :loading="loading"
                                    />

                                    <LoadingSpinnerButton
                                        v-if="selectedFaqInstance.id"
                                        class="save-btn"
                                        @click="$emit('update-faq-instance')"
                                        text="Save"
                                        :loading="loading"
                                    />

                                    <LoadingSpinnerButton
                                        class="delete-btn"
                                        @click="$emit('cancel-faq-instance')"
                                        text="Cancel"
                                        color="main-gray"
                                        :loading="loading"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="flex-between faq-answer"
                        v-on:click="faqClickHandler(faq.instance)"
                        v-else
                    >
                        <div v-if="faq.instance.answer">
                            <p>{{ faq.instance.answer }}</p>
                            <button class="text-button">Edit answer</button>
                        </div>
                        <div v-else>
                            <button class="text-button">Click to create</button>
                        </div>
                        <status-badge
                            v-if="faq.instance.answer"
                            :status-text="faq.instance.status"
                        />
                    </div>
                </div>
                <last-edited-date :date="faq.instance.last_edited" />
            </div>
        </div>
    </div>
</template>

<script>
import { clone } from 'lodash';
import statusBadge from '@/components-deprecated/StatusBadge';
import ElasticTextArea from '@/components-deprecated/ElasticTextArea';
import FieldError from '@/components-deprecated/FieldError';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import TransitionDropdown from '@/components-deprecated/inputs/TransitionDropdown';
import EmojiPicker from '@/components-deprecated/staff/EmojiPicker.vue';
import LastEditedDate from '@/components-deprecated/LastEditedDate';

export default {
    components: {
        statusBadge,
        ElasticTextArea,
        FieldError,
        LoadingSpinnerButton,
        TransitionDropdown,
        EmojiPicker,
        LastEditedDate
    },
    props: {
        topic: {
            type: Object
        },
        faqs: {
            type: Array
        },
        selectedFaqInstance: {
            type: Object
        },
        errors: {
            type: Array
        },
        loading: {
            type: Boolean
        },
        deleteLoading: {
            type: Boolean
        }
    },
    data: function() {
        return {
            statusChoices: [
                { id: 'APPROVED', name: 'Approved' },
                { id: 'PENDING', name: 'Pending' }
            ]
        };
    },
    methods: {
        faqsFilter: function(subtopic, faq) {
            return (faq.subtopic || {}).id === (subtopic || {}).id;
        },
        faqClickHandler: function(faqInstance) {
            this.$emit('edit-faq', clone(faqInstance));
        },
        statusChangeHandler: function(statusChoice) {
            this.$emit('status-change', statusChoice.id);
        },
        selectEmojiHandler: function(value) {
            this.$emit('emoji-selected', value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/styles/mixins/buttons';

.faq-question {
    width: 35%;
}

.faq-answer {
    width: 62%;
    cursor: pointer;
}

.faq-details {
    padding: 1rem 0 1rem 0;
    border-bottom: 1px solid #f0ecec;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.topic-header {
    padding: 2rem 0 2rem 0;
}

.description {
    color: $main-gray;
}

.button-container {
    display: flex;
    justify-content: flex-end;
}

.save-btn {
    @include base-button();
}

.delete-btn {
    @include secondary-button();
    margin-left: 1rem;
}

.answer-text-area {
    min-height: 200px;
}
</style>
