import axios from 'axios';
import { apiErrorHandler } from '../services/api';
import * as Api from './index';

// start integration tag mapping
export const startIntegrationTagMapping = async integrationId => {
    const url = await Api.getIntegrationBase(`/integration/${integrationId}/mapping/start`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Start Integration Mapping error'
            })
        );
};

// save integration tag mapping
export const saveIntegrationTagMapping = async (integrationId, dataToMap) => {
    const url = await Api.getIntegrationBase(`/integration/${integrationId}/mapping/map`);

    return axios
        .post(url, dataToMap, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Save Integration Mapping error'
            })
        );
};

// integrate!
export const integrate = async integrationId => {
    const url = await Api.getIntegrationBase(`/integration/${integrationId}/integrate`);

    return axios
        .put(url, {}, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Integrate error'
            })
        );
};
