<template>
    <div class="container engagement-analytics">
        <div class="header-container">
            <div class="description">
                <h1 class="h2">Student Engagement</h1>
                <p>
                    This is where you can monitor student engagement.
                </p>
            </div>
        </div>
        <div class="filters">
            <StudentAnalysisFilter
                @filterChanged="onFilterChanged"
                :showStudentSearch="false"
                :showTagFilter="true"
                :showRiskLevelFilter="false"
                :showRiskDriverFilter="false"
                :showPhaseFilter="false"
                :showNumberValidityFilter="false"
                :showOptInStatusFilter="false"
                :showActionTakenFilter="false"
                :showMoreFiltersButton="false"
            />
        </div>
        <div v-if="dataLoaded && hasData" class="content">
            <div class="stats">
                <div class="stats-item box">
                    <h2>{{ analytics.percentOptedIn }}% Opt-In</h2>
                    {{ analytics.percentOptedIn }}% of students on the platform are opted into
                    receiving texts
                    {{ mascotName ? `from ${mascotName}` : '' }}
                    <Emoji emoji="raisingHands" />
                </div>
                <div class="stats-item box">
                    <h2>{{ analytics.percentEngaged }}% Engagement</h2>
                    {{ analytics.percentEngaged }}% of students are actively engaged and texting
                    {{ mascotName }}
                    <Emoji emoji="huggingFace" />
                </div>
                <div class="stats-item box">
                    <h2>{{ analytics.messageCount }} Texts Received</h2>
                    {{ analytics.messageCount }} student texts received in the last 12 months or
                    since launching
                    <Emoji emoji="chatBubble" />
                </div>
            </div>
            <div class="chart box">
                <h3>Engagement over Time</h3>
                <p>
                    The number of student texts received over the last 12 months or since launching.
                </p>
                <TextsChart :messageCountByWeek="this.analytics.messageCountByWeek" />
            </div>
        </div>
        <div v-if="dataLoaded && !hasData">
            <h2 class="msg-empty-insights">Nothing to see here... yet!</h2>
            <h2 class="msg-empty-insights__smaller">
                Once we start engaging with your students you will see any interesting analytics and
                data trends here.
            </h2>
        </div>
        <LoadingSpinner v-if="!dataLoaded" />
    </div>
</template>

<script>
import { getEngagementAnalytics } from '@/api/analytics';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import TextsChart from './components/TextsChart';
import Emoji from '@/components-deprecated/global/Emoji';
import StudentAnalysisFilter from '@/components-deprecated/StudentAnalysisFilter';

export default {
    name: 'EngagementAnalyticsPage',
    components: {
        Emoji,
        TextsChart,
        LoadingSpinner,
        StudentAnalysisFilter
    },
    computed: {
        mascotName() {
            return this.$store.state.user.schoolRef.mascotName;
        },
        hasData() {
            return this.dataLoaded && this.analytics.messageCount > 0;
        }
    },
    data() {
        return {
            dataLoaded: false,
            analytics: {
                percentOptedIn: 0,
                percentEngaged: 0,
                messageCount: 0,
                messageCountByWeek: {}
            }
        };
    },
    methods: {
        async loadData(schoolId, tagIds) {
            if (schoolId) {
                this.dataLoaded = false;
                this.analytics = await getEngagementAnalytics({
                    schoolId,
                    tagIds
                });
                this.dataLoaded = true;
            }
        },
        async onFilterChanged(payload) {
            if (typeof payload === 'object') {
                await this.loadData(this.$store.state.user.school, payload.tagIds);
            }
        }
    },
    async created() {
        await this.loadData(this.$store.state.user.school);
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/styles/mixins/buttons';
.engagement-analytics {
    .header-container {
        margin-top: 1rem;
    }

    .description {
        margin: 3rem 2.75rem;
    }

    h3 {
        font-size: 17px;
        color: black;
    }

    .filters {
        padding: 0 20px 0 20px;

        :deep(.col) {
            margin: 0 0 1rem 0;

            .row {
                &.selected-filters-row {
                    margin-bottom: 12px;
                }
                &.filters-row {
                    margin: 0;
                }
            }
        }
    }
    .content {
        padding: 0 1rem 1rem;
        display: flex;
        flex-direction: column;

        .stats {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .stats-item {
                width: 30%;

                h2 {
                    color: $edsights-blue;
                }
            }
        }

        .chart {
            margin-top: 2rem;
        }
    }
    .spinner {
        margin-top: 10rem;
    }

    .msg-empty-insights {
        text-align: center;
        color: $accessible-gray;
        margin-top: 4rem;

        &__smaller {
            text-align: center;
            color: $accessible-gray;
            line-height: 2rem;
            font-size: 1rem;
            margin: 3rem 20%;
        }
    }
}
</style>
