import { objectToSnakeCase, objectToCamelCase } from '../utils';
import { API_BASE, apiErrorHandler } from '../api';
import axios from 'axios';
import Auth from '../auth';
import { validateEmail } from '@/lib/validate';

const ADMINS_ENDPOINT = `${API_BASE}admins/`;
const DEACTIVATE_ADMINS_ENDPOINT = `${API_BASE}admins/deactivate/`;
const INVITE_COADMINS_ENDPOINT = `${API_BASE}admins/invite-coadmins/`;
const RE_INVITE_COADMIN_ENDPOINT = `${API_BASE}admins/re-invite-coadmin/`;
const TOGGLE_WEEKLY_REPORT_ENDPOINT = `${API_BASE}admins/toggle-weekly-report/`;

export default class AdminAPI {
    /**
     * Initialize an `AdminAPI` instance.
     *
     * @param {class} cls  The class to use to create Admin objects.
     **/
    constructor(cls) {
        this.cls = cls;
    }
    /**
     * Factory method to create a new instance of `AdminAPI`.
     *
     * @param {class} cls      The class to use to create Admin objects.
     **/
    static create(cls) {
        return new AdminAPI(cls);
    }

    /**
     * @function     Retrieve
     * @description  Retrieve the data for a given admin.
     * @param        id <String>  UUID of the admin to get info about.
     *
     **/
    async retrieve(id) {
        const url = `${ADMINS_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        return axios
            .get(url, options)
            .then(response => response.data)
            .then(this.cls.fromAPI)
            .catch(apiErrorHandler({ apiName: 'Retrieve Admin Api Error' }));
    }

    async list(params) {
        const url = ADMINS_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers,
            params: objectToSnakeCase(params)
        };

        const promise = axios
            .get(url, options)
            .then(response => response.data)
            .then(data => {
                return {
                    ...data,
                    results: data.results.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Admin Api Error' }));

        return promise;
    }

    /**
     * @function      update
     * @description   Set a current admin's data via the API
     * @param         admin <Object> - The updated admin data
     */
    async update(admin, fields = [], excludeFields = []) {
        this.cls.checkIsInstance(admin, 'The `admin` argument');

        const url = `${ADMINS_ENDPOINT}${admin.id}/`;
        let data = this.cls.toAPI(admin, fields, excludeFields);
        const options = {
            headers: await Auth.getHeaders().headers
        };

        return axios
            .patch(url, data, options)
            .then(response => response.data)
            .then(this.cls.fromAPI)
            .catch(
                apiErrorHandler({
                    apiName: 'Update Admin API error'
                })
            );
    }

    /**
     *  Allows users with the appropriate permissions to deactivate admins.
     *
     *  @param  adminIds Array<String> List of admin ids to deactivate.
     */
    async deactivate(adminIds = null, allAdminsSelected = false) {
        const url = DEACTIVATE_ADMINS_ENDPOINT;
        let data = {};
        if (allAdminsSelected) {
            data = { all_admins_selected: true };
        } else {
            data = { admin_ids: adminIds };
        }
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .post(url, data, options)
            .then(response => response.data)
            .catch(
                apiErrorHandler({
                    apiName: 'Deactivate Admins API error'
                })
            );
        return promise;
    }

    /**
     * @function      inviteCoadmins
     * @description   Endpoint for a school admin to upload a list of emails and
     *                create new co-admins from each email.
     * @param         emails <Array> - A list of emails to create new co-admins.
     */
    async inviteCoadmins(emails, adminType = null) {
        const url = INVITE_COADMINS_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = {
            emails: emails
        };

        if (adminType) {
            data.admin_type = adminType;
        }

        const promise = axios
            .post(url, data, options)
            .then(response => objectToCamelCase(response.data))
            .catch(
                apiErrorHandler({
                    apiName: 'Invite Co-Admins API error'
                })
            );
        return promise;
    }

    async reInviteCoadmin(email) {
        validateEmail(email);
        const url = RE_INVITE_COADMIN_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = {
            email
        };

        return (
            axios
                .post(url, data, options)
                // apiErrorHandler re-throws by default
                .catch(
                    apiErrorHandler({
                        apiName: 'Re-invite Co-Admin'
                    })
                )
        );
    }

    async toggleWeeklyReportSubscription() {
        const url = TOGGLE_WEEKLY_REPORT_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .post(url, {}, options)
            .then(response => response.data)
            .catch(apiErrorHandler({ apiName: 'Toggle Weekly Activity Report API error' }));
    }
}
