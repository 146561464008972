<template>
    <div class="create-reusable-template-from-chatbot-flow-button-container">
        <div class="tooltip-container" @click="openConfirmationModal">
            <img
                v-tooltip="'Create Template'"
                class="tooltip-image"
                src="@/assets/icons/copy-file.svg"
                @click="openConfirmationModal"
                alt="Copy file"
            />
        </div>
        <Modal
            v-if="showConfirmationModal"
            title="Create reusable template"
            primaryButtonTitle="Confirm"
            :showPrimaryButtonLoadingSpinner="loading"
            @secondary-button-clicked="closeConfirmationModal"
            @primary-button-clicked="copy"
        >
            <div>
                Are you sure you want to create a reusable template from this flow?
            </div>
        </Modal>
    </div>
</template>

<script>
import { copyChatbotFlow } from '@/api/chatbot-flow';
import Modal from '@/components-deprecated/global/Modal';
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';

export default {
    name: 'CreateReusableTemplateFromChatbotFlowButton',
    components: { Modal },
    props: {
        chatbotFlowId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            showConfirmationModal: false
        };
    },
    methods: {
        openConfirmationModal() {
            this.showConfirmationModal = true;
        },
        closeConfirmationModal() {
            this.showConfirmationModal = false;
        },
        async copy() {
            try {
                this.loading = true;
                await copyChatbotFlow({
                    id: this.chatbotFlowId,
                    // Set the flow's status to "CANCELED" so that the flow doesn't show up on the FLows page,
                    // Use the null date placeholder, since we don't want to save an actual date on these reusable templates.
                    // Set template.reusable to true so that it shows up on the Templates page.
                    overrides: {
                        status: CHATBOT_FLOW_STATUS.CANCELED.value,
                        date: DRAFT_NULL_DATE_PLACEHOLDER,
                        template: {
                            reusable: true
                        }
                    },
                    errorHandlerOptions: { rethrow: true, enableAlert: true }
                });

                this.$Alert.alert({
                    type: 'success',
                    message: 'Template created.',
                    timeout: 10000
                });
            } finally {
                this.loading = false;
                this.closeConfirmationModal();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.create-reusable-template-from-chatbot-flow-button-container {
    display: flex;
    margin-bottom: 1px;
    cursor: pointer;
}
</style>
