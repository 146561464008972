<template>
    <div>
        <div class="row">
            <p class="tag-category-mapping-name">{{ tagCategoryMapping.rawName }}</p>
            <select
                class="dropdown"
                v-model="tagCategoryMapping.tagCategory"
                @change="onTagCategoryChangeEvent($event)"
            >
                <option value="CUSTOM_NEW">Custom New</option>
                <option
                    v-for="tagCategory in tagCategories"
                    :value="tagCategory.id"
                    :key="tagCategory.id"
                >
                    <p v-if="tagCategory.isPublic">Public:</p>
                    <p v-else>Custom:</p>
                    {{ tagCategory.name }}
                </option>
            </select>
        </div>
        <div class="tag-mapping" v-for="tagMapping in tagMappings" :key="tagMapping.id">
            <div class="row">
                <p class="tag-name">{{ tagMapping.rawName }}</p>
                <select class="dropdown" @change="onTagChange(tagMapping, $event)">
                    <option
                        v-for="tagChoice in tagChoices"
                        :value="tagChoice.id"
                        :key="tagChoice.id"
                        :selected="tagChoice.id == tagMapping.tag"
                    >
                        {{ tagChoice.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tagCategoryMapping: {
            type: Object,
            required: true
        },
        tagMappings: {
            type: Array,
            required: true
        },
        tagCategories: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            tagChoices: []
        };
    },
    created() {
        let tagCategory = this.tagCategories.find(
            category => category.id === this.tagCategoryMapping.tagCategory
        );

        this.handleTagCategoryChange(tagCategory);
    },
    methods: {
        onTagCategoryChangeEvent(event) {
            let tagCategory = this.tagCategories.find(
                category => category.id === event.target.value
            );

            this.handleTagCategoryChange(tagCategory);
        },

        handleTagCategoryChange(tagCategory) {
            if (tagCategory) {
                this.tagChoices = tagCategory.tagsRef;
                if (!tagCategory.isPublic) {
                    this.tagChoices.push({ id: 'CUSTOM_NEW', name: 'Custom New' });
                } else {
                    // This block accounts for the scenario wherein a user changes the
                    // selected tag category to a public category. When this happens, we
                    // need to update any tag mapping values that are currently mapped to
                    // CUSTOM_NEW and just set it to the first, related public tag that
                    // is available.
                    this.tagMappings.forEach(tagMapping => {
                        if (tagMapping.tag === 'CUSTOM_NEW') {
                            this.updateTagMapping(tagMapping, this.tagChoices[0].id);
                        }
                    });
                }

                this.$emit('on-update-tag-category', this.tagCategoryMapping, tagCategory.id);
            } else {
                this.tagChoices = [{ id: 'CUSTOM_NEW', name: 'Custom New' }];
                this.tagCategoryMapping.tagCategory = 'CUSTOM_NEW';
                this.$emit('on-update-tag-category', this.tagCategoryMapping, 'CUSTOM_NEW');
            }

            // If any of the TagMappings don't have a default tag then set it here
            let unmappedTagMappings = this.tagMappings.filter(tagMapping => !tagMapping.tag);
            unmappedTagMappings.forEach(tagMapping =>
                this.updateTagMapping(tagMapping, this.tagChoices[0].id)
            );
        },

        onTagChange(tagMapping, event) {
            this.updateTagMapping(tagMapping, event.target.value);
        },

        updateTagMapping(tagMapping, mapTo) {
            this.$emit('on-update-tag', tagMapping, mapTo);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/views/modal';
@import '@/styles/forms';
@import '@/styles/variables';

.tag-category-mapping-name {
    flex: 3;
    font-size: 1.1rem;
    font-weight: bold;
}

.tag-mapping {
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.tag-name {
    flex: 3;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dropdown {
    flex: 1;
    border-radius: 0;
    height: 100%;
    padding: 0.25rem 0.5rem;
}
</style>
