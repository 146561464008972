<template>
    <div class="background-operation">
        <div
            class="copy"
            :class="{ 'copy-complete': backgroundOperation.isComplete }"
            @click="onClickResults"
        >
            <h2
                :class="{
                    'text-primary': !backgroundOperation.error,
                    'text-warning': backgroundOperation.error
                }"
            >
                {{ title }}
            </h2>
            {{ subtitle }}
        </div>
        <div class="progress-bar">
            <ProgressBar
                :percentComplete="backgroundOperation.percentComplete"
                :showPercentage="true"
            />
        </div>
        <div class="close" @click="removeOperation">X</div>
        <ModalLargeOperationResults />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import BackgroundOperation from '@/services/backgroundOperations';
import ProgressBar from '@/components-deprecated/ProgressBar';
import ModalLargeOperationResults from '@/components-deprecated/admin/ModalLargeOperationResults';

// Check API every 2000 ms (2 seconds)
const POLLING_INTERVAL = 2000;

export default {
    name: 'BackgroundOperation',
    components: {
        ProgressBar,
        ModalLargeOperationResults
    },
    props: {
        backgroundOperation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            pollingTimeout: null
        };
    },
    async created() {
        await this.refresh(POLLING_INTERVAL);
    },
    unmounted() {
        clearTimeout(this.pollingTimeout);
    },
    methods: {
        ...mapActions(['removeBackgroundOperation', 'updateBackgroundOperation']),
        removeOperation() {
            this.removeBackgroundOperation(this.backgroundOperation);
        },
        /* Recursive function to check for progress updates on our BackgroundOperation */
        async refresh(repeatInterval) {
            clearTimeout(this.pollingTimeout);
            try {
                const response = await BackgroundOperation.api.retrieve(
                    this.backgroundOperation.id
                );
                this.updateBackgroundOperation(response);
                if (response.isComplete) {
                    return;
                }

                // Call this function again after polling interval
                this.pollingTimeout = setTimeout(async () => {
                    await this.refresh(POLLING_INTERVAL);
                }, repeatInterval);

                // If the API throws an error:
            } catch (e) {
                const newInterval = repeatInterval * 2;
                // Wait twice as long before calling this function again, to prevent a
                // situation where our API calls are taking up too many server resources.
                this.pollingTimeout = setTimeout(async () => {
                    await this.refresh(newInterval);
                }, newInterval);
            }
        },
        onClickResults() {
            // Don't display results if operation isn't yet complete.
            if (!this.backgroundOperation.isComplete) {
                return;
            } else if (this.backgroundOperation.status === BackgroundOperation.options.FAILED) {
                this.$modal.show('modal-large-operation-results', {
                    error: this.backgroundOperation.error
                });
                // If operation succeeded, first check for a file
            } else if (this.backgroundOperation.file) {
                window.open(this.backgroundOperation.file);
                // If no file, display JSON results
            } else if (this.backgroundOperation.result) {
                let action = this.$store.state.backgroundOperationActions.find(
                    item => item.operationId === this.backgroundOperation.id
                );
                if (action) {
                    action.action(this.backgroundOperation.result[0].value);
                } else {
                    this.$modal.show('modal-large-operation-results', {
                        results: this.backgroundOperation.result
                    });
                }
            }
            // Clear operation from state once results have been viewed.
            //this.removeOperation()
        }
    },
    computed: {
        title() {
            if (!this.backgroundOperation.isComplete) {
                return `${this.backgroundOperation.title} . . .`;
            } else if (this.backgroundOperation.status === BackgroundOperation.options.FAILED) {
                return 'Operation failed.';
            } else {
                return 'Operation complete.';
            }
        },
        subtitle() {
            if (!this.backgroundOperation.isComplete) {
                return 'You can keep working while we load your data.';
            } else if (this.backgroundOperation.status === BackgroundOperation.options.FAILED) {
                return 'Click to view error.';
                // If operation succeeded, first check for a file
            } else if (this.backgroundOperation.file) {
                return 'Click to download your file.';
                // If no file, display JSON results
            } else if (this.backgroundOperation.result) {
                return 'Click to view results.';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.background-operation {
    width: 100%;
    background: white;
    box-shadow: 0 2px 5px $very-light-gray;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.copy {
    margin-left: 1rem;
    padding: 1rem;
    width: 50%;
}
.copy-complete {
    cursor: pointer;
}
.progress-bar {
    width: 100%;
    margin: 0 1rem;
}
.close {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 1rem;
    padding: 1rem;
    cursor: pointer;
}
</style>
