import axios from 'axios';
import { apiErrorHandler } from '../services/api';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';

// find points of contact
export const findPointsOfContact = () => {
    const url = Api.getv2ApiBase('/user/points-of-contact');

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Find School By Id error'
            })
        );
};

export const listUsersByEmail = ({ params }) => {
    const url = Api.getv2ApiBase(`/user/list-by-email`);
    const options = Api.getOptions();

    return axios
        .get(url, { ...options, params })
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

// Retires a single user or users in bulk
export const retireUsers = ({
    userEmails,
    schoolId,
    includeAdmins = false,
    errorHandlerOptions = {}
}) => {
    const url = Api.getv2ApiBase(`/user/retire`);
    const payload = { userEmails, schoolId, includeAdmins }

    return axios
        .patch(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                ...errorHandlerOptions
            }));
};
