<template>
    <div class="button-dropdown-container">
        <Dropdown :options="options" @option-clicked="handleDropdownClick">
            <template v-slot:custom-menu-trigger>
                <Button :styleType="styleType" :disabled="disabled">
                    <slot></slot>
                    <svg class="icon" viewBox="0 0 292.362 292.362">
                        <use xlink:href="#caret-down" />
                    </svg>
                </Button>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import Button from '@/components-deprecated/global/Button';
import Dropdown from '@/components-deprecated/global/Dropdown';

export default {
    name: 'ButtonDropdown',
    components: {
        Button,
        Dropdown
    },
    methods: {
        handleDropdownClick(value) {
            this.$emit('option-clicked', value);
        }
    },
    props: {
        options: {
            type: Array,
            required: true,
            validator: function(options) {
                return options.every(
                    o => typeof o.label === 'string' && typeof o.value === 'string'
                );
            }
        },
        styleType: {
            type: String,
            default: 'primary',
            validator: function(value) {
                return ['primary', 'secondary'].includes(value);
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.button-dropdown-container {
    .icon {
        height: 0.83rem;
        width: 0.83rem;
        margin-left: 0.5rem;
        margin-bottom: 0.1rem;
    }
}
</style>
