import axios from 'axios';

import { API_BASE, apiErrorHandler } from '../api';
import { getv2ApiBase } from '../../api/index';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import Auth from '../auth';

const ALERT_TYPES__ENDPOINT = `${API_BASE}alert-types/`;

export default class AlertTypeAPI {
    /**
     * Initialize an `AlertTypeAPI`` instance.
     *
     * @param {class} cls  The class to use to create Alert Type objects.
     **/
    constructor(cls) {
        this.cls = cls;
    }

    /**
     * Factory method to create a new instance of `AlertAPI`.
     *
     * @param {class} cls      The class to use to create Alert Type objects.
     **/
    static create(cls) {
        return new AlertTypeAPI(cls);
    }

    /*
     * Retrieve a list of Alert Types
     */
    async list(params = {}) {
        const url = ALERT_TYPES__ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers,
            params
        };

        const promise = axios
            .get(url, options)
            .then(response => {
                const data = response.data.results ? response.data.results : response.data;
                return {
                    ...response.data,
                    results: data.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Alert Types API Error' }));

        return promise;
    }

    /*
     * Retrieve a list of Alert Types
     */
    async listV2(schoolId) {
        const url = getv2ApiBase(`/alert-types/school/${schoolId}`);
        const options = {
            headers: Auth.getHeaders().headers
        };
        try {
            const response = await axios.get(url, options);
            const data = response.data.results ? response.data.results : response.data;
            return {
                ...response.data,
                results: data
            };
        } catch (error) {
            v2ApiErrorHandler({ error, overrideMessage: 'List Alert Tyeps API Error' });
        }
    }

    async update(alertType, fieldsToUpdate) {
        const url = `${ALERT_TYPES__ENDPOINT}${alertType.id}/`;
        const data = this.cls.toAPI(alertType, fieldsToUpdate);
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(
                apiErrorHandler({
                    apiName: 'Update Alert Type API error'
                })
            );
        return promise;
    }

    async updateV2(alertType, fieldsToUpdate) {
        const url = getv2ApiBase(`/alert-types/school/${alertType.schoolId}/${alertType.id}`);
        const data = fieldsToUpdate.reduce((res, i) => {
            res[i] = alertType[i];
            return res;
        }, {});
        const options = {
            headers: Auth.getHeaders().headers
        };
        try {
            const response = await axios.patch(url, data, options);
            return this.cls.fromAPI(response.data);
        } catch (error) {
            v2ApiErrorHandler({ error, overrideMessage: 'Update Alert Type API error' });
        }
    }
}
