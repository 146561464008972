<template>
    <div class="tag-container" v-tooltip="!isExperimentalAccessibilityEnabled ? toolTip : {}">
        <p class="tag">{{ text }}</p>
    </div>
</template>

<script>
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
export default {
    name: 'TagItem',

    props: {
        text: {
            type: String,
            default: null
        },
        toolTip: {
            type: String
        }
    },
    computed: {
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.tag-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    padding: 0 15px;
    border-radius: 15px;
    border: 1px solid $main-gray;
    color: $main-gray;
    flex-shrink: 1;
    max-width: 140px;
}

.tag {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $accessible-gray;
}
</style>
