<template>
    <label
        class="search-input"
        :class="{
            'search-input--small': $attrs.isSmall,
            'search-input--small-border': $attrs.smallBorder
        }"
    >
        <svg v-if="!$attrs.isSmall" class="icon-search" viewBox="0 0 50 50">
            <use xlink:href="#icon-search" />
        </svg>
        <input
            class="search-input__input"
            :aria-label="ariaLabel"
            type="text"
            ref="search"
            :value="localValue"
            @input="onInput"
            @click="$emit('onSearchStudentClick')"
            :placeholder="$attrs.placeholder"
        />
        <svg v-if="$attrs.isSmall" class="icon-search" viewBox="0 0 50 50">
            <use xlink:href="#icon-search" />
        </svg>
    </label>
</template>

<script>
import { debounce } from '@/services/debounce';

export default {
    name: 'SearchInput',
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        ariaLabel: {
            type: String,
            default: 'Search'
        }
    },
    data() {
        return {
            localValue: this.modelValue
        };
    },
    methods: {
        // Delay emitting an event with the search paramter until 70ms have passed since the
        // function was last called.
        onInput: debounce(function({ target }) {
            this.localValue = target.value;
            this.$emit('update:modelValue', target.value);
        }, 20)
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.icon-search {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    fill: $main-gray;
    position: absolute;
    right: 4px;
}

.search-input {
    display: flex;
    align-items: center;
    padding: 0;
    border-radius: 5px;
    position: relative;

    &__input {
        min-height: auto;
        height: 40px;
        width: 100%;
        border: 1px solid $dark-gray;
        background: #fff;
        padding: 12px 40px 12px 16px;
        min-width: unset;
        font-size: 14px;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }

    &--small {
        .search-input__input {
            height: 32px;
            width: 260px;
            border: 0;
        }

        .icon-search {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }

    &--small-border {
        border: 1px solid $secondary-gray;
        border-radius: 5px;
    }
}
</style>
