<template>
    <div class="container knowledge-analytics">
        <div class="header-container">
            <div class="description">
                <h1 class="h2">Knowledge Analytics</h1>
                <p>
                    This is where you can monitor the topics and questions that students are asking
                    about the most when using the reactive (FAQ) capability.
                </p>
            </div>
        </div>
        <div v-if="webAnalyticsEnabled" class="analytics-type">
            <DropdownV2
                :options="sourceTypes"
                :selectedOptionValue="analyticsType"
                @onChange="sourceTypeChangeHandler"
            />
        </div>
        <template v-if="dataLoaded">
            <div v-if="hasData" class="content">
                <div v-if="analytics.topTopics.length" class="chart box">
                    <h2>What topics are students asking about most?</h2>
                    <TopicsChart
                        :topics="analytics.topTopics"
                        :totalQuestions="analytics.totalQuestions"
                    />
                </div>
                <div class="data-lists">
                    <div v-if="analytics.topSubtopics.length" class="box list">
                        <h2>The subtopics students are asking about most</h2>
                        <ul>
                            <li
                                class="item"
                                v-for="subtopic in analytics.topSubtopics"
                                :key="subtopic.id"
                            >
                                {{ subtopic.topicName }} / {{ subtopic.name }}
                            </li>
                        </ul>
                    </div>

                    <div v-if="analytics.topQuestions.length" class="box list">
                        <h2>
                            The top questions students are asking
                        </h2>
                        <ul>
                            <li
                                class="item"
                                v-for="question in analytics.topQuestions"
                                :key="question.id"
                            >
                                {{ question.question }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="isStaff && analytics.messageCountByMonth" class="chart box">
                    <h3>How many questions students asked per month</h3>
                    <QuestionsChart :messageCountByMonth="analytics.messageCountByMonth" />
                </div>
            </div>
            <div v-if="!hasData">
                <h2 class="msg-empty-insights">Nothing to see here... yet!</h2>
                <h2 class="msg-empty-insights__smaller">
                    Once we have enough data on the questions that students are asking you will be
                    able to see the most popular questions here.
                </h2>
            </div>
        </template>
        <LoadingSpinner v-if="!dataLoaded" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import DropdownV2 from '@/components-deprecated/global/v2/DropdownV2.vue';
import { isDbSchoolFeatureFlagEnabled } from '@/lib/feature-flag';
import { getKnowledgeAnalytics } from '@/api/analytics';
import TopicsChart from './components/TopicsChart';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import QuestionsChart from '@/views/KnowledgeAnalytics/components/QuestionsChart';

export default {
    name: 'KnowledgeAnalyticsPage',
    components: { QuestionsChart, TopicsChart, LoadingSpinner, DropdownV2 },
    computed: {
        ...mapState(['user']),
        hasData() {
            // page shows an empty state when there's fewer than 5 topQuestions
            return this.dataLoaded && this.analytics.topQuestions.length >= 5;
        },
        isStaff() {
            return this.user.isStaff;
        }
    },
    data() {
        return {
            dataLoaded: false,
            webAnalyticsEnabled: false,
            sourceTypes: [
                { id: 1, label: 'Show SMS Chatbot Analytics', value: 'sms' },
                { id: 2, label: 'Show Web Chatbot Analytics', value: 'web' }
            ],
            analyticsType: 'sms',
            analyticsCache: {},
            analytics: {
                sms: {
                    topQuestions: [],
                    topTopics: [],
                    topSubtopics: [],
                    totalQuestions: 0
                },
                web: {
                    topQuestions: [],
                    topTopics: [],
                    topSubtopics: [],
                    totalQuestions: 0
                }
            }
        };
    },
    methods: {
        sourceTypeChangeHandler(sourceType) {
            this.analyticsType = sourceType.value;
            this.loadData(this.user.school, sourceType.value);
        },
        loadData: async function(schoolId, sourceType = 'sms') {
            if (schoolId) {
                if (this.analyticsCache[sourceType]) {
                    this.analytics = this.analyticsCache[sourceType];
                    return;
                }
                this.dataLoaded = false;
                const result = await getKnowledgeAnalytics(
                    schoolId,
                    sourceType,
                    this.webAnalyticsEnabled
                );
                this.analyticsCache[sourceType] = result;
                this.analytics = result;
                this.dataLoaded = true;
            }
        }
    },
    async created() {
        const [webKnowledgeAnalytics, webChatbotFeature] = await Promise.all([
            isDbSchoolFeatureFlagEnabled(this.user.school, 'WEB_KNOWLEDGE_ANALYTICS'),
            isDbSchoolFeatureFlagEnabled(this.user.school, 'WEB_CHATBOT_FEATURE')
        ]);

        this.webAnalyticsEnabled = webKnowledgeAnalytics && webChatbotFeature;

        await this.loadData(this.user.school);
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/styles/mixins/buttons';

.knowledge-analytics {
    .header-container {
        margin-top: 1rem;

        .description {
            margin: 3rem 2.75rem;
        }
    }

    h3 {
        font-size: 17px;
        color: black;
    }

    h2 {
        font-size: 17px;
        color: black;
    }

    .analytics-type {
        margin: 1rem;
        display: flex;
        align-items: stretch;
        flex-direction: row;
        overflow: visible;
    }

    .content {
        padding: 1rem;
        display: flex;
        flex-direction: column;

        & > div {
            margin-bottom: 2rem;
        }

        .data-lists {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .list {
                width: 48%;

                h3 {
                    margin-bottom: 1rem;
                }
                .item {
                    display: flex;
                    align-items: center;
                    margin: 0 2rem;
                    height: 3.5rem;
                    border-bottom: 1px solid $tranquil;
                }
            }
        }
    }
    .spinner {
        margin-top: 10rem;
    }

    .msg-empty-insights {
        text-align: center;
        color: $accessible-gray;
        margin-top: 4rem;

        &__smaller {
            text-align: center;
            color: $accessible-gray;
            line-height: 2rem;
            font-size: 1rem;
            margin: 3rem 20%;
        }
    }

    .choices {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 25px;
    }

    .choice {
        display: inline-flex;
        padding: 25px 10px;
        border: 2px solid $primary-brand-color;
        color: $main-gray;

        &__text {
            font-size: 13px;
            text-align: center;
        }

        &__radio {
            padding: 5px;
            align-self: flex-end;
        }
    }
}
:deep(.default-menu-trigger) {
    gap: 5px;
}
</style>
