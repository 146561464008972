import * as Api from '@/api/index';
import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { strictEqual } from 'assert';

export const createReportRequest = ({ payload, errorHandlerOptions = {} }) => {
    strictEqual(typeof payload, 'object', 'payload must be an object');
    strictEqual(typeof errorHandlerOptions, 'object', 'errorHandlerOptions must be an object');
    const url = Api.getv2ApiBase(`/report-request`);
    const options = Api.getOptions();

    return axios
        .post(url, payload, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                ...errorHandlerOptions
            })
        );
};

export const getReportRequestDownloadUrlById = ({ id, errorHandlerOptions = {} }) => {
    strictEqual(typeof id, 'string', 'id must be a string');
    strictEqual(typeof errorHandlerOptions, 'object', 'errorHandlerOptions must be an object');

    const url = Api.getv2ApiBase(`/report-request/get-download-url/${id}`);
    const options = Api.getOptions();

    return axios
        .get(url, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                ...errorHandlerOptions
            })
        );
};

export const starfishNotesReadinessBySchoolId = ({ schoolId, errorHandlerOptions = {} }) => {
    strictEqual(typeof schoolId, 'string', 'schoolId must be a string');

    const url = Api.getv2ApiBase(`/report-request/starfish-notes/${schoolId}`);
    const options = Api.getOptions();

    return axios
        .get(url, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                ...errorHandlerOptions
            })
        );
};
