<template>
    <section class="intervention-group">
        <div class="group-header">
            <h2 class="chatbot-template-name">
                {{ formattedChatbotTemplateName }}
            </h2>

            <div class="tooltip-container" v-if="touchpoint.renderedDescription">
                <img
                    v-tooltip="touchpoint.renderedDescription"
                    v-if="!isExperimentalAccessibilityEnabled"
                    class="tooltip-image"
                    src="@/assets/icons/question-mark.svg"
                    alt="Question mark"
                    tabIndex="0"
                />
            </div>
            <div v-if="touchpoint.chatbotTemplate.createReport" class="download-report">
                <ReportRequestDownloadButton
                    reportType="CHATBOT_CONVERSATIONS"
                    :reportOptions="{
                        chatbotFlowId: touchpoint.chatbotFlow.id,
                        touchpointId: touchpoint.id,
                        cleanOutput: true
                    }"
                />
            </div>
        </div>
        <InterventionCard
            class="intervention-card"
            :key="interventionTemplate.id"
            v-for="(interventionTemplate, index) in touchpoint.interventionTemplates"
            :interventionTemplate="interventionTemplate"
            :chatbotTemplate="
                touchpoint.chatbotTemplate
                    ? { ...touchpoint.chatbotTemplate, touchpoint: touchpoint.id }
                    : null
            "
            :chatbotFlow="touchpoint.chatbotFlow"
            :interventionIndex="index + 1"
            :interventionCount="touchpoint.interventionTemplates.length"
            :showDetailsLink="true"
            :reportOptions="{
                chatbotFlowId: touchpoint.chatbotFlow.id,
                touchpointId: touchpoint.id,
                cleanOutput: true
            }"
        />
    </section>
</template>

<script>
import InterventionCard from '@/components-deprecated/overview/InterventionCard';
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
import { formatAccessibleString } from '@/lib/string-helpers';

export default {
    name: 'TouchpointInterventionGroup',

    components: {
        InterventionCard,
        ReportRequestDownloadButton
    },

    props: {
        touchpoint: {
            type: Object,
            required: true
        }
    },
    computed: {
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        formattedChatbotTemplateName() {
            if (
                !this.touchpoint ||
                !this.touchpoint.chatbotTemplate ||
                !this.touchpoint.chatbotTemplate.name
            ) {
                return '';
            }

            return formatAccessibleString(
                this.touchpoint.chatbotTemplate.name,
                this.isExperimentalAccessibilityEnabled
            );
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.intervention-group {
    margin: 4rem 10rem;

    &:first-child {
        margin-top: 2rem;
    }
}

.group-header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.chatbot-template-name {
    font-size: 1.25rem;
}

.tooltip-image {
    width: 16px;
    vertical-align: sub;
}

.tooltip-container {
    padding: 0rem 1rem;
}

.chatbot-template-download {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    margin-left: 3rem;
    color: $primary-brand-color;
}

.icon-download {
    stroke: $primary-brand-color;
    margin-left: 1rem;
}

.intervention-card {
    margin: 1rem 0;
}

.download-report {
    display: flex;
}
</style>
