import axios from 'axios';

import { API_BASE, apiErrorHandler } from '../api';
import Auth from '../auth';
import moment from 'moment';

const CHATBOT_FLOWS_ENDPOINT = `${API_BASE}chatbot-flows/`;

export default class ChatbotFlowsAPI {
    constructor(cls) {
        this.cls = cls;
    }

    static create(cls) {
        return new ChatbotFlowsAPI(cls);
    }

    async list(params = {}) {
        const options = {
            headers: await Auth.getHeaders().headers,
            params
        };

        if (
            typeof options.params === 'object' &&
            typeof options.params.page === 'number' &&
            typeof options.params.page_size === 'number'
        ) {
            options.params = {
                ...options.params,
                page_size: 10
            };
        }

        const promise = axios
            .get(CHATBOT_FLOWS_ENDPOINT, options)

            .then(response => {
                return {
                    ...response.data,
                    results: response.data.results.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Chatbot Flows API Error' }));

        return promise;
    }

    async retrieve(id) {
        const url = `${CHATBOT_FLOWS_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Retrieve Chatbot Flows API Error' }));

        return promise;
    }

    async update(answer) {
        const url = `${CHATBOT_FLOWS_ENDPOINT}${answer.id}/`;
        const data = this.cls.toAPI(answer);
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(
                apiErrorHandler({
                    apiName: 'Update Chatbot Flows API error'
                })
            );
        return promise;
    }

    async updateStatus(flow, newStatus) {
        const url = `${CHATBOT_FLOWS_ENDPOINT}${flow.id}/status/`;
        const data = {
            status: newStatus
        };
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(
                apiErrorHandler({
                    apiName: 'Update Status Chatbot Flows API error'
                })
            );

        return promise;
    }

    async create(flow) {
        const url = CHATBOT_FLOWS_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = this.cls.toAPI(flow);

        const promise = axios
            .post(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Create Chatbot Flows Api Error' }));
        return promise;
    }

    async delete(id) {
        const url = `${CHATBOT_FLOWS_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .delete(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Delete Chatbot Flows Api Error' }));
        return promise;
    }

    async downloadConversations(
        flowId,
        school,
        interventionTemplateId,
        tagCategoriesToInclude,
        cleanOutput = true,
        interventionTemplateIds
    ) {
        // Adding the responseType to axios here so that it is aware that it is expecting a stream of data.
        // This blob is then accessed by creating a link on the fly and programmatically clicking it so that
        // it opens a new window and executes an automatic download of the file
        // If a user can't download it may be because their pop up blocker is blocking it.

        const params = {
            school: school.id,
            // on the BE, clean_output is only looked at for staff users.
            // It is ignored for non-staff (they always receive the clean output)
            clean_output: cleanOutput
        };

        if (interventionTemplateId) {
            params.intervention_template_id = interventionTemplateId;
        }

        if (tagCategoriesToInclude) {
            params.tag_category_ids = tagCategoriesToInclude;
        }

        if (interventionTemplateIds) {
            params.intervention_template_ids = interventionTemplateIds;
        }

        const url = `${CHATBOT_FLOWS_ENDPOINT}${flowId}/download/`;
        const options = {
            responseType: 'blob',
            headers: await Auth.getHeaders().headers,
            params: params
        };

        const promise = axios.get(url, options).then(response => {
            const url_dl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url_dl;
            let file_name = `${moment().format()}_${school.name}`;
            link.setAttribute('download', file_name + '.xlsx');
            link.click();
        });

        return promise;
    }
}
