<template>
    <modal
        name="modal-starfish-reports"
        height="auto"
        width="800"
        :minWidth="700"
        :scrollable="true"
        :clickToClose="true"
        :stack="false"
    >
        <div class="modal" aria-labelledby="starfish-reports-modal-heading">
            <header>
                <h2 id="export-modal-heading">Attributes</h2>
            </header>
            <div class="modal-input-container">
                <p class="subheading">
                    Contains EdSights Risk Level data for each Risk Driver and cumulative Overall
                    Risk.
                </p>
                <ReportRequestDownloadButton
                    reportType="STARFISH_ATTRIBUTES"
                    buttonText="Download Starfish Attributes"
                />
            </div>
            <header v-if="starfishNotesReady">
                <h2 id="export-modal-heading">Notes</h2>
            </header>
            <div v-if="starfishNotesReady" class="modal-input-container">
                <p class="subheading">
                    Contains EdSights Intervention data for all Interventions from the time period
                    selected.
                </p>
                <p>
                    The Starfish note file is generated based on your selected date range.
                    Guidelines are listed below. Note that the report may take a few minutes to
                    generate; please leave your browser open until the file is downloaded.
                </p>
                <ul>
                    <li>You must define both a start and end date.</li>
                    <li>Reports cannot span more than 365 days.</li>
                    <li>
                        Reports will cover the period from midnight on the start date up to midnight
                        on the end date.
                    </li>
                </ul>
                <div class="section">
                    <h3>START DATE</h3>
                    <div>
                        <DateInput v-model="startDate" />
                    </div>
                </div>
                <div class="section">
                    <h3>END DATE</h3>
                    <div>
                        <DateInput v-model="endDate" />
                    </div>
                </div>
                <ReportRequestDownloadButton
                    reportType="STARFISH_NOTES"
                    buttonText="Download Starfish Notes"
                    :reportOptions="{
                        startDate,
                        endDate
                    }"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import DateInput from '@/components-deprecated/global/DateInput';
import { starfishNotesReadinessBySchoolId } from '@/api/report-request';
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton';

export default {
    components: {
        ReportRequestDownloadButton,
        DateInput
    },
    data() {
        return {
            startDate: null,
            endDate: null,
            starfishNotesReady: false
        };
    },
    async created() {
        this.starfishNotesReady = await starfishNotesReadinessBySchoolId({
            schoolId: this.$store.state.user.schoolRef.id
        });
    },
    methods: {
        close() {
            this.$modal.hide('modal-starfish-reports');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins/lists';
@import '../../styles/views/modal-small';

.modal {
    padding: 2rem 3rem;
    cursor: default;
}

p {
    color: black;
    margin: 0rem 3rem;
}

ul {
    color: black;
    margin: 0rem 5rem;
}

p.subheading {
    margin: 0rem 0rem 1rem 0rem;
}

.modal-input-container {
    padding: 1rem 0rem 2rem 0rem;
    border: 0;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Left-align items within each section */
    margin: 1rem 3rem;
}
</style>
