<template>
    <div class="dashboard-container">
        <div class="submit-container">
            <h2>Submit a Toll Free Verification</h2>
            <div style="margin-left: -12rem">
                <div class="label-input-container">
                    <div class="label-input-container labels">
                        <p>School Name</p>
                        <p>Street Address</p>
                        <p>City</p>
                        <p>State</p>
                        <p>Postal Code</p>
                        <p>Contact First Name</p>
                        <p>Contact Last Name</p>
                        <p>Contact Email</p>
                        <p>Contact Phone #</p>
                        <p>Website</p>
                        <p>Opt In Image Url</p>
                        <p>Message Volume</p>
                        <p>Additional Info</p>
                    </div>
                    <div class="label-input-container inputs">
                        <v-select
                            :options="schools"
                            v-model="selectedSchool"
                            label="name"
                            @update:modelValue="schoolSelectionHandler"
                            placeholder="Search for a school..."
                            :clearable="false"
                            class="school-dropdown"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessStreetAddress"
                            placeholder="1 Main Street"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessCity"
                            placeholder="New York"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessStateProvinceRegion"
                            placeholder="NY"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessPostalCode"
                            placeholder="55555"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessContactFirstName"
                            placeholder="John"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessContactLastName"
                            placeholder="Doe"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessContactEmail"
                            placeholder="JohnDoe@edsights.edu"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessContactPhone"
                            placeholder="1234567890"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessWebsite"
                            placeholder="www.edsights.io"
                        />
                        <input
                            class="input-box verification"
                            v-model="optInImageUrls"
                            placeholder="https://drive.google.com/file/d/1gAq9g"
                        />
                        <v-select
                            :options="messageVolumeList"
                            v-model="messageVolume"
                            @update:modelValue="volumeSelectHandler"
                            placeholder="Choose a volume..."
                            :clearable="false"
                            class="dropdown"
                        />
                        <textarea
                            v-model="additionalInformation"
                            class="input-textarea"
                            placeholder="EdSights University, through www.edsights.io, adheres to legal texting standards..."
                        ></textarea>
                    </div>
                </div>
            </div>
            <Button
                width="26rem"
                @click="submitPhoneVerificationHandler"
                v-if="!showLoadingSpinner"
            >
                Submit
            </Button>
            <div v-else>
                <LoadingSpinner />
            </div>
        </div>
    </div>
</template>

<script>
import * as TwilioApi from '@/api/twilio';
import * as PhoneNumberRegistryApi from '@/api/phone-number-registry';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/global/Button';

export default {
    name: 'SubmitVerification',
    components: {
        LoadingSpinner,
        Button
    },
    data() {
        return {
            showLoadingSpinner: false,
            selectedSchool: null,
            schools: [],
            businessName: '',
            businessStreetAddress: '',
            businessCity: '',
            businessStateProvinceRegion: '',
            businessPostalCode: '',
            businessContactFirstName: '',
            businessContactLastName: '',
            businessContactEmail: '',
            businessContactPhone: '',
            businessWebsite: '',
            optInImageUrls: '',
            additionalInformation: '',
            messageVolume: '',
            messageVolumeList: [
                '1',
                '10',
                '1,000',
                '10,000',
                '100,000',
                '250,000',
                '500,000',
                '750,000',
                '1,000,000',
                '5,000,000',
                '10,000,000'
            ],
            businessCountry: 'US',
            optInType: 'WEB_FORM',
            notificationEmail: 'engineering@edsights.io',
            useCaseCategories: ['CHARITY_NONPROFIT', 'HIGHER_EDUCATION'],
            useCaseSummary:
                'Support students via text, remind them of deadlines, and answer their questions.',
            productionMessageSample:
                "Just a friendly reminder to complete your FAFSA by our priority deadline of May 1st to ensure you're considered for all possible financial aid. Reply STOP to opt out."
        };
    },
    created() {
        this.loadSchoolsWithoutTwilioSubAccounts();
    },
    methods: {
        async loadSchoolsWithoutTwilioSubAccounts() {
            this.schools = await TwilioApi.listSchoolsWithUnverifiedSubAccounts();
        },
        schoolSelectionHandler(school) {
            this.selectedSchool = school;
            this.schoolId = school.id;
            this.businessName = school.name;
        },
        volumeSelectHandler(value) {
            this.messageVolume = value;
        },
        resetForm() {
            this.selectedSchool = null;
            this.schoolId = '';
            this.businessStreetAddress = '';
            this.businessCity = '';
            this.businessStateProvinceRegion = '';
            this.businessPostalCode = '';
            this.businessContactFirstName = '';
            this.businessContactLastName = '';
            this.businessContactEmail = '';
            this.businessContactPhone = '';
            this.businessName = '';
            this.businessWebsite = '';
            this.optInImageUrls = '';
            this.additionalInformation = '';
            this.messageVolume = '';
        },
        async submitPhoneVerificationHandler() {
            this.showLoadingSpinner = true;
            try {
                const payload = {
                    schoolId: this.schoolId,
                    businessStreetAddress: this.businessStreetAddress,
                    businessCity: this.businessCity,
                    businessStateProvinceRegion: this.businessStateProvinceRegion,
                    businessPostalCode: this.businessPostalCode,
                    businessContactFirstName: this.businessContactFirstName,
                    businessContactLastName: this.businessContactLastName,
                    businessContactEmail: this.businessContactEmail,
                    businessContactPhone: `1${this.businessContactPhone}`, // 1 for country code
                    businessName: this.businessName,
                    businessWebsite: this.businessWebsite,
                    optInType: this.optInType,
                    optInImageUrls: [this.optInImageUrls],
                    additionalInformation: this.additionalInformation,
                    messageVolume: this.messageVolume,
                    businessCountry: this.businessCountry,
                    notificationEmail: this.notificationEmail,
                    useCaseCategories: this.useCaseCategories,
                    useCaseSummary: this.useCaseSummary,
                    productionMessageSample: this.productionMessageSample
                };

                const isValidated = PhoneNumberRegistryApi.validateTwilioVerification(payload);
                if (!isValidated.status) {
                    throw isValidated.message;
                }

                const response = await PhoneNumberRegistryApi.submitPhoneVerification({ payload });
                if (response) {
                    this.$Alert.alert({
                        type: 'success',
                        message: 'Toll Free Verification submitted successfully.',
                        timeout: 3000
                    });
                    this.resetForm();
                }
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: error,
                    timeout: 3000
                });
                console.error(error);
            } finally {
                this.showLoadingSpinner = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}
.submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.label-input-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    padding-bottom: 1rem;

    &.labels {
        flex-direction: column;
        gap: 8px;
        padding-top: 4px;
    }

    &.inputs {
        flex-direction: column;
        gap: 7px;
    }
}
.input-box {
    height: 27px;
    width: 25rem;
    padding-left: 10px;
    border: 1px solid #3c3c3c42;
    border-radius: 3px;

    &.verification {
        width: 26rem;
    }
}
.dropdown {
    width: 26rem;
}
.input-textarea {
    height: 10rem;
    width: 26rem;
    border-radius: 3px;
    border-color: #3c3c3c42;
}
</style>
