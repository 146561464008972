import { objectToSnakeCase, objectToCamelCase } from '../utils';
import api from './api';
import Tag from '@/services/tags';

export default class TagCategories {
    static api = api.create(TagCategories);
    static readOnlyFields = ['id'];
    constructor({ id = null, name = '', isPublic = false, tagsRef = [] } = {}) {
        Object.assign(this, {
            id,
            name,
            isPublic,
            tagsRef: tagsRef.map(t => {
                let tag = {
                    ...t,
                    category: id
                };

                return Tag.fromAPI(tag);
            })
        });
    }
    static create(data = {}) {
        return new TagCategories(data);
    }
    static fromAPI(json = {}) {
        const jsonCamel = objectToCamelCase(json);
        return new TagCategories(jsonCamel);
    }
    static toAPI(d, fields = [], excludeFields = []) {
        let data = {};
        if (fields.length > 0) {
            fields.forEach(f => {
                data[f] = d[f];
            });
        } else {
            data = d;
        }
        excludeFields = [...TagCategories.readOnlyFields, ...excludeFields];
        excludeFields.forEach(f => {
            delete data[f];
        });
        return objectToSnakeCase(data);
    }
}
