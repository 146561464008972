<template>
    <div class="button-container">
        <button
            :type="type"
            :disabled="disabled"
            :class="styleType"
            :style="{
                width: width
            }"
        >
            <slot></slot>
        </button>
    </div>
</template>

<script>
export default {
    name: 'Button',
    props: {
        styleType: {
            type: String,
            default: 'primary',
            validator: function(value) {
                return ['primary', 'secondary', 'transparent'].includes(value);
            }
        },
        type: {
            type: String,
            default: 'button',
            validator: function(value) {
                return ['button', 'submit'].includes(value);
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.button-container {
    button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 7.5rem;
        height: 3.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: $semi-bold-font-family;
        font-size: 1rem;
        color: $white;
        letter-spacing: 0.05rem;
        border-radius: 0.5rem;
        outline: none;
        cursor: pointer;
        padding: 0 1rem 0 1rem;

        &.primary {
            background: $edsights-blue;
            border-color: $edsights-blue;
        }

        &.secondary {
            color: rgb(147, 148, 153);
            border: $base-border-width solid $black-alpha-0-25;
            background: white;
        }

        &.transparent {
            color: #38a0fa;
            border: none;
            background: rgba(0, 0, 0, 0);
        }

        &:disabled {
            cursor: not-allowed;
            background-color: $btn-off-color;
            border-color: $btn-off-color;
            color: #fff;
        }
    }
}
</style>
