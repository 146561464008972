import FAQAPI from './api';
import { objectToCamelCase, objectToSnakeCase } from '../utils';
import FAQInstance from '@/services/faqInstances';

export default class FAQ {
    static api = FAQAPI.create(FAQ);
    static readOnlyFields = ['id', 'intentName', 'instance'];
    static filterChoices = [
        { id: 'ALL', name: 'View All' },
        { id: 'APPROVED', name: 'View Approved Only' },
        { id: 'PENDING', name: 'View Pending Approval Only' },
        { id: 'ANSWERED', name: 'View Answered Only' },
        { id: 'UNANSWERED', name: 'View Unanswered Only' }
    ];
    constructor({ id = '', question = '', school = null, intentName = '', instance = null } = {}) {
        Object.assign(this, {
            id,
            question,
            school,
            intentName,
            instance: instance ? FAQInstance.fromAPI(instance) : null
        });
    }

    static create(opts) {
        return new FAQ(opts);
    }

    static fromAPI(json = {}) {
        return new FAQ(objectToCamelCase(json));
    }

    /**
     * Prep data for the API. Can elect to include or exlude specific fields.
     * The readOnlyFields are always excluded.
     **/
    static toAPI(faq, fields = [], excludeFields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = faq[field];
            });
        } else {
            data = faq;
        }

        excludeFields = [...FAQ.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }
}
