<template>
    <div class="settings container" id="settingsBody">
        <h1 class="settings__header">Settings</h1>
        <LoadingSpinner v-if="isLoading" />
        <div v-else class="settings__body">
            <!-- Only for admins who can manage students -->
            <div class="section">
                <h2 class="section__label">Enrolled Students</h2>
                <div class="section__content">
                    <div class="annotated-link" v-if="userCanManageStudents()">
                        <a class="annotated-link__link" @click="onEditStudents" href="#"
                            >Manage Students</a
                        >
                        <span class="annotated-link__description">Add and remove students.</span>
                    </div>
                    <div
                        class="annotated-link"
                        tabindex="0"
                        v-tooltip="!isExperimentalAccessibilityEnabled ? toolTipText : {}"
                        v-else
                    >
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <span class="disabled">Manage Students</span>
                            <span style="margin-left: 7px; padding-top: 7px;">
                                <svg width="20px" height="16px" viewBox="0 0 26 26">
                                    <use xlink:href="#icon-lock"></use>
                                </svg>
                            </span>
                        </div>
                        <span class="annotated-link__description">Add and remove students.</span>
                    </div>
                </div>
            </div>

            <!-- Only for admins who can manage admins -->
            <div class="section">
                <h2 class="section__label">Co-Admins</h2>
                <div class="section__content">
                    <div class="annotated-link" v-if="userCanManageAdmins()">
                        <a class="annotated-link__link" @click="onEditCoAdmins" href="#"
                            >Manage Co-Admins</a
                        >
                        <span class="annotated-link__description"
                            >Add and remove co-administrators, and set permission levels.</span
                        >
                    </div>
                    <div
                        class="annotated-link"
                        tabindex="0"
                        v-tooltip="!isExperimentalAccessibilityEnabled ? toolTipText : {}"
                        v-else
                    >
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <span class="disabled">Manage Co-Admins</span>
                            <span style="margin-left: 7px; padding-top: 7px;">
                                <svg width="20px" height="16px" viewBox="0 0 26 26">
                                    <use xlink:href="#icon-lock"></use>
                                </svg>
                            </span>
                        </div>
                        <span class="annotated-link__description"
                            >Add and remove co-administrators, and set permission levels.</span
                        >
                    </div>
                </div>
            </div>

            <div class="section">
                <h2 class="section__label">Alerts</h2>
                <div class="section__content">
                    <div class="annotated-link" v-if="userCanManageAlerts()">
                        <a class="annotated-link__link" @click="onManageAlerts" href="#"
                            >Manage Alerts</a
                        >
                        <span class="annotated-link__description"
                            >Add, edit, or remove alert admins and customize alert messages</span
                        >
                    </div>
                    <div
                        class="annotated-link"
                        tabindex="0"
                        v-tooltip="!isExperimentalAccessibilityEnabled ? toolTipText : {}"
                        v-else
                    >
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <span class="disabled">Manage Alerts</span>
                            <span style="margin-left: 7px; padding-top: 7px;">
                                <svg width="20px" height="16px" viewBox="0 0 26 26">
                                    <use xlink:href="#icon-lock"></use>
                                </svg>
                            </span>
                        </div>
                        <span class="annotated-link__description"
                            >Add, edit, or remove alert admins and customize alert messages</span
                        >
                    </div>
                </div>
            </div>

            <!-- Needs-Based Resources / v1 Knowledge Base / Non-Gen AI-->
            <div class="section">
                <h2 class="section__label">{{ needsBasedResourceSectionText }}</h2>
                <div class="section__content">
                    <div class="annotated-link" v-if="canManageKnowledgeBase">
                        <a class="annotated-link__link" @click="onManageKnowledgeBase" href="#">{{
                            needsBasedResourceLinkText
                        }}</a>
                        <span class="annotated-link__description">{{
                            needsBasedResourceDescriptionText
                        }}</span>
                    </div>
                    <div
                        class="annotated-link"
                        tabindex="0"
                        v-tooltip="!isExperimentalAccessibilityEnabled ? toolTipTextKB : {}"
                        v-else
                    >
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <span class="disabled">{{ needsBasedResourceLinkText }}</span>
                            <span style="margin-left: 7px; padding-top: 7px;">
                                <svg width="20px" height="16px" viewBox="0 0 26 26">
                                    <use xlink:href="#icon-lock"></use>
                                </svg>
                            </span>
                        </div>
                        <span class="annotated-link__description">{{
                            needsBasedResourceDescriptionText
                        }}</span>
                    </div>
                </div>
            </div>

            <!-- Knowledge Base v2 / Next Gen AI -->
            <div v-if="nextGenAIChatbotEnabled" class="section">
                <h2 class="section__label">Knowledge Base</h2>
                <div class="section__content">
                    <div class="annotated-link" v-if="canManageKnowledgeBase">
                        <a class="annotated-link__link" @click="onManageKnowledgeBasev2" href="#"
                            >Manage Knowledge Base</a
                        >
                        <span class="annotated-link__description"
                            >Add, update, or remove Q&A from the chatbot's reactive knowledge
                            base</span
                        >
                    </div>
                    <div
                        class="annotated-link"
                        tabindex="0"
                        v-tooltip="!isExperimentalAccessibilityEnabled ? toolTipTextKB : {}"
                        v-else
                    >
                        <div style="display: flex; flex-direction: row; align-items: center">
                            <span class="disabled">Manage Knowledge Base</span>
                            <span style="margin-left: 7px; padding-top: 7px">
                                <svg width="20px" height="16px" viewBox="0 0 26 26">
                                    <use xlink:href="#icon-lock"></use>
                                </svg>
                            </span>
                        </div>
                        <span class="annotated-link__description"
                            >Add, update, or remove Q&A from the chatbot's reactive knowledge
                            base</span
                        >
                    </div>
                </div>
            </div>

            <div class="section">
                <h2 class="section__label">Messaging</h2>
                <div class="section__content">
                    <div class="annotated-link">
                        <a class="annotated-link__link" @click="onManageMessagingTracks" href="#">
                            {{ `${this.isStaff ? 'Manage Messaging' : 'View Messaging'}` }}
                        </a>
                        <span class="annotated-link__description">
                            {{
                                `${
                                    this.isStaff
                                        ? 'Set messaging tracks based on tags.'
                                        : 'View messaging tracks based on tags.'
                                }`
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="section">
                <h2 class="section__label">Tags</h2>
                <div class="section__content">
                    <div class="annotated-link" v-if="userCanManageTags()">
                        <a class="annotated-link__link" @click="onManageTags" href="#"
                            >Manage Tags</a
                        >
                        <span class="annotated-link__description"
                            >Add and remove student tags.</span
                        >
                    </div>
                    <div
                        class="annotated-link"
                        tabindex="0"
                        v-tooltip="!isExperimentalAccessibilityEnabled ? toolTipText : {}"
                        v-else
                    >
                        <div style="display: flex; flex-direction: row; align-items: center;">
                            <span class="disabled">Tags</span>
                            <span style="margin-left: 7px; padding-top: 7px;">
                                <svg width="20px" height="16px" viewBox="0 0 26 26">
                                    <use xlink:href="#icon-lock"></use>
                                </svg>
                            </span>
                        </div>
                        <span class="annotated-link__description"
                            >Add and remove student tags.</span
                        >
                    </div>
                </div>
            </div>

            <div class="section">
                <h2 class="section__label">Key Information</h2>
                <div class="section__content">
                    <div class="annotated-link">
                        <a class="annotated-link__link" @click="onKeyInformation" href="#">
                            {{ `${this.isStaff ? 'Edit' : 'View'} Account Information` }}
                        </a>
                        <span class="annotated-link__description"
                            >View key account information that we have on file.</span
                        >
                    </div>
                </div>
            </div>

            <div class="section" v-if="showWebChatbotSettings">
                <h2 class="section__label">Web Chatbot</h2>
                <div class="section__content">
                    <div class="annotated-link">
                        <a class="annotated-link__link" @click="onManageWebChatbot">
                            Manage Web Chatbot
                        </a>
                        <span class="annotated-link__description">
                            View preferences for the web chatbot.
                        </span>
                    </div>
                </div>
            </div>

            <div class="section" v-if="adminAccount">
                <h2 class="section__label">Notification Settings</h2>
                <div class="section__content">
                    <div class="notification-block">
                        <BaseCheckbox
                            v-model="subscribedToWeeklyReports"
                            label="Receive Weekly Activity Reports?"
                        />
                    </div>
                </div>
            </div>

            <div class="section">
                <h2 class="section__label">Change Password</h2>
                <div class="section__content">
                    <form class="password-form" @submit.prevent="onSubmitPasswordChangeForm">
                        <label class="field">
                            <span class="field__label">Current Password</span>
                            <input
                                class="field__input"
                                type="password"
                                autocomplete="current-password"
                                v-model="passwordForm.oldPassword"
                            />
                        </label>
                        <router-link class="forgot-password-link" :to="{ name: 'ForgotPassword' }"
                            >Forgot your password?</router-link
                        >
                        <label class="field">
                            <span class="field__label">New Password</span>
                            <input
                                class="field__input"
                                type="password"
                                autocomplete="new-password"
                                v-model="passwordForm.newPassword1"
                            />
                        </label>
                        <label class="field">
                            <span class="field__label">Verify Password</span>
                            <input
                                class="field__input"
                                type="password"
                                v-model="passwordForm.newPassword2"
                            />
                        </label>
                        <loading-spinner-button
                            type="submit"
                            :text="'Change Password'"
                            class="button"
                            :loading="passwordForm.saving"
                            :disabled="!enteredPasswords"
                        ></loading-spinner-button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '@/services/auth';
import Admin from '@/services/admins';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import { BaseCheckbox } from '@edsights/ui-core';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import {
    isExperimentalAccessibilityEnabled,
    isDbSchoolFeatureFlagEnabled,
    isNextGenAIChatbotEnabled,
    isWebChatbotEnabled,
    isWebChatbotSettingsPageEnabled
} from '@/lib/feature-flag';


export default {
    components: {
        LoadingSpinnerButton,
        BaseCheckbox,
        LoadingSpinner
    },
    data() {
        return {
            isLoading: true,
            nextGenAIChatbotEnabled: null,
            isWebChatbotEnabled: false,
            isWebChatbotSettingsPageEnabled: false,
            passwordForm: {
                oldPassword: '',
                newPassword1: '',
                newPassword2: '',
                saving: false
            },
            toolTipText:
                'This settings page is only accessible to Unlimited Administrators. Reach out to an Unlimited Administrator to make changes.',
            toolTipTextKB:
                'This settings page is only accessible to Unlimited Administrators or Limited Administrators with the Knowledge Base permission. Reach out to an Unlimited Administrator to make changes.'
        };
    },
    async created() {
        try {
            await Promise.all([
                this.checkIfNextGenAIEnabled(),
                this.checkIfWebChatbotEnabled()
            ]);

            this.isLoading = false;
        } catch (error) {
            console.log(error);
        }
    },
    computed: {
        adminAccount() {
            return this.$store.state.user.adminAccount;
        },
        subscribedToWeeklyReports: {
            get() {
                return this.adminAccount.subscribedToWeeklyReports;
            },
            set(value) {
                this.onToggleWeeklyReportSubscription(value);
            }
        },
        enteredPasswords() {
            return (
                this.passwordForm.oldPassword &&
                this.passwordForm.newPassword1 &&
                this.passwordForm.newPassword2
            );
        },
        canManageKnowledgeBase() {
            return (
                this.adminAccount &&
                (this.adminAccount.canAccessKb || this.adminAccount.type === 'MASTER')
            );
        },
        isStaff() {
            return this.$store.state.user.isStaff;
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        // the wording of the "original" knowledge base changes if a school has the next gen ai chatbot enabled
        // next gen enabled schools will see "Need-Based Resources", others will see "Knowledge Base"
        needsBasedResourceSectionText() {
            if (this.nextGenAIChatbotEnabled === true) {
                return 'Need-Based Resources';
            }
            if (this.nextGenAIChatbotEnabled === false) {
                return 'Knowledge Base';
            }
            return '';
        },
        needsBasedResourceLinkText() {
            if (this.nextGenAIChatbotEnabled === true) {
                return 'Manage Need-Based Resources';
            }
            if (this.nextGenAIChatbotEnabled === false) {
                return 'Manage Knowledge Base';
            }
            return '';
        },
        needsBasedResourceDescriptionText() {
            if (this.nextGenAIChatbotEnabled === true) {
                return 'Add or update the resources that the chatbot shares through its proactive framework';
            }
            if (this.nextGenAIChatbotEnabled === false) {
                return "Add and remove resources from the chatbot's knowledge base";
            }
            return '';
        },
        showWebChatbotSettings() {
            return this.isWebChatbotEnabled && this.isWebChatbotSettingsPageEnabled;
        }
    },
    methods: {
        onEditCoAdmins() {
            this.$router.push({ name: 'ManageCoAdmins' });
        },
        onEditStudents() {
            this.$router.push({ name: 'StudentUploadsAdmin' });
        },
        onManageKnowledgeBase() {
            this.$router.push({ name: 'KnowledgeBase' });
        },
        onManageKnowledgeBasev2() {
            this.$router.push({ name: 'KnowledgeBasev2' });
        },
        onManageMessagingTracks() {
            this.$router.push({ name: 'MessagingTracks' });
        },
        onManageWebChatbot() {
            this.$router.push({ name: 'ManageWebChatbot' });
        },
        onManageTags() {
            this.$router.push({ name: 'ManageTags' });
        },
        onKeyInformation() {
            this.$router.push({ name: 'KeyInformation' });
        },
        onManageAlerts() {
            this.$router.push({ name: 'ManageAlerts' });
        },
        onToggleWeeklyReportSubscription(value) {
            // Call the API to toggle the subscription
            Admin.api.toggleWeeklyReportSubscription(value).then(() => {
                // Commit the mutation with the updated adminAccount
                this.$store.commit('UPDATE_USER', {
                    adminAccount: { 
                        ...this.adminAccount, 
                        subscribedToWeeklyReports: value 
                    }
                });
            });
        },
        onSubmitPasswordChangeForm() {
            this.passwordForm.saving = true;
            auth.changePassword(this.passwordForm)
                .then(() => {
                    this.passwordForm = {
                        oldPassword: '',
                        newPassword1: '',
                        newPassword2: '',
                        saving: true
                    };
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Password changed!</h2>',
                        timeout: 7000
                    });
                })
                .finally(() => {
                    this.passwordForm.saving = false;
                });
        },
        userCanManageStudents() {
            if (
                this.adminAccount &&
                this.adminAccount.permissions.canImportStudents &&
                this.adminAccount.permissions.canViewAllStudents
            ) {
                return true;
            }
            return false;
        },
        userCanManageAdmins() {
            if (this.adminAccount && this.adminAccount.permissions) {
                return this.adminAccount.permissions.canInviteCoadmins;
            }
            return false;
        },
        userCanManageTags() {
            if (this.adminAccount && this.adminAccount.type === 'MASTER') {
                return true;
            }
            return false;
        },
        userCanManageAlerts() {
            if (this.adminAccount && this.adminAccount.permissions) {
                return this.adminAccount.permissions.canChangePermissions;
            }
            return false;
        },
        async checkIfNextGenAIEnabled() {
            if (this.$store.state.user.schoolRef) {
                this.nextGenAIChatbotEnabled = await isNextGenAIChatbotEnabled(
                    this.$store.state.user.schoolRef.id
                );
            } else {
                this.nextGenAIChatbotEnabled = false;
            }
        },
        async checkIfWebChatbotEnabled() {
            if (this.$store.state.user.schoolRef) {
                this.isWebChatbotEnabled = await isWebChatbotEnabled(
                    this.$store.state.user.schoolRef.id
                );

                // If enabled, also check if the settings page is enabled
                // (this only needs to exist during development of the settings page,
                // after it's done it'll always be enabled):
                if (this.isWebChatbotEnabled) {
                    this.isWebChatbotSettingsPageEnabled = await isWebChatbotSettingsPageEnabled(
                        this.$store.state.user.schoolRef.id
                    );
                }
            } else {
                this.isWebChatbotEnabled = false;
                this.isWebChatbotSettingsPageEnabled = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/forms';
@import '../../styles/mixins/buttons';

$break-point: 768px;

.button {
    width: 12rem;
}

// Settings modal layout styles
.v--modal-overlay[data-modal='modal-settings'] {
    background-color: $black-alpha-0-5;

    & :deep(.v--modal-box) {
        margin-top: $nav-panel-height;
    }

    & :deep(.v--modal) {
        border-radius: 0;
    }
}

.settings {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__header {
        padding: 1rem;
        border-bottom: 2px solid #f0f0f0;
        font-size: 22px;
        font-family: inherit;
    }

    &__body {
        // Intentionally Blank
    }
}

// Component style for a large link with small
// descriptive text beneath it.
.annotated-link {
    &__link {
        display: inline-block;
        cursor: pointer;
        font-size: $large-font-size;
        text-decoration: underline;
    }

    &__description {
        display: block;
        color: $accessible-gray;
        font-size: $small-font-size;
    }
}

// Component style for a sectioned interface with
// large label on the left and content on the right.
.section {
    display: flex;
    flex-direction: row;
    padding: 2rem 2rem;
    border-bottom: 1px solid $mystic;
    align-items: flex-start;
    transition: all 1s linear;

    @media screen and (max-width: $break-point) {
        flex-direction: column;
    }

    &__label {
        flex: 1;
        font-size: $large-font-size;
        font-family: inherit;
        font-weight: normal;
    }

    &__content {
        flex: 2;
        flex-direction: column;
        justify-content: space-around;
    }
}

.password-form {
    @extend %form;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 25rem;
}

.field {
    display: flex;
    margin-bottom: 10px;

    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 200px;
        padding-right: 20px;
    }

    input.field__input {
        min-height: auto;
        padding: 5px 5px;
        border-radius: 0;
    }
}

.notification-block {
    display: flex;

    &__title {
        margin-right: 1rem;
        font-size: $large-font-size;
    }
}

.forgot-password-link {
    margin-bottom: 0.5rem;
    text-decoration: underline;
}

button[disabled] {
    color: gray;
    font-size: $large-font-size;
}

:deep(.checkbox__checkmark) {
    margin-right: 0;
}

:deep(.checkbox__label) {
    font-size: 1.2rem;
}
</style>
