<template>
    <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
        <slot />
    </transition>
</template>

<script>
// component for expanded list
export default {
    name: 'TransitionExpand',
    methods: {
        enter(element) {
            const currentElement = element;
            const width = getComputedStyle(currentElement).width;

            currentElement.style.width = width;
            currentElement.style.position = 'absolute';
            currentElement.style.visibility = 'hidden';
            currentElement.style.height = 'auto';

            const height = getComputedStyle(currentElement).height;

            currentElement.style.width = null;
            currentElement.style.position = null;
            currentElement.style.visibility = null;
            currentElement.style.height = 0;

            // Force repaint to make sure the
            // animation is triggered correctly.
            void getComputedStyle(currentElement).height;

            // Trigger the animation.
            // We use `setTimeout` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            setTimeout(() => {
                currentElement.style.height = height;
            });
        },
        afterEnter(element) {
            const currentElement = element;
            currentElement.style.height = 'auto';

            this.$emit('after-expand');
        },
        leave(element) {
            const currentElement = element;
            const height = getComputedStyle(currentElement).height;

            currentElement.style.height = height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            void getComputedStyle(currentElement).height;

            setTimeout(() => {
                currentElement.style.height = 0;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
* {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.expand-enter-active,
.expand-leave-active {
    transition: height 0.5s ease-in-out;
    overflow: hidden;
}

.expand-enter,
.expand-leave-to {
    height: 0;
}
</style>
