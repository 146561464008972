<template>
    <button class="row clickable" @click="navigateBack">
        <svg width="14px" height="14px" class="arrow" viewBox="0 0 5.9 17.51">
            <use xlink:href="#icon-arrow-right-wide" />
        </svg>
        <span class="back-link">Back to {{ this.previousPageDisplayName }}</span>
    </button>
</template>

<script>
export default {
    name: 'ButtonBack',
    props: {
        // Name that will be displayed. Ex: "Settings" or "Key Information"
        previousPageDisplayName: {
            type: String,
            default: 'Settings'
        },
        // Name of route that will be navigated to. Ex: "Settings" or "KeyInformation"
        previousPageRouteName: {
            type: String,
            default: 'Settings'
        }
    },
    methods: {
        navigateBack() {
            this.$router.push({
                name: this.previousPageRouteName
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.row {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 1rem;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.clickable {
    cursor: pointer;
}

.arrow {
    transform: rotate(-180deg);
    margin-right: 1rem;
}

.back-link {
    font-size: 1.25rem;
    font-weight: bold;
    background: none;
    border: 0;
    letter-spacing: 1px;
}
</style>
