import axios from 'axios';

import { API_BASE, apiErrorHandler } from '@/services/api';
import Auth from '../auth';

const TAG_CATEGORY_MAPPING_ENDPOINT = `${API_BASE}/tag-category-mappings/`;

export default class TagCategoryMappingsAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new TagCategoryMappingsAPI(cls);
    }

    async createMapping(tagCategoryMapping) {
        const url = TAG_CATEGORY_MAPPING_ENDPOINT;
        const options = {
            headers: Auth.getHeaders().headers
        };

        const data = this.cls.toAPI(tagCategoryMapping);

        const promise = axios
            .post(url, data, options)
            .then(res => this.cls.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Create Tag Category Mapping Api Error' }));

        return promise;
    }

    async retrieve(tagCategoryMappingId, params = {}) {
        const url = `${TAG_CATEGORY_MAPPING_ENDPOINT}${tagCategoryMappingId}/`;
        const options = {
            params: { ...params },
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };

        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Retrieve Tag Api Error' }));

        return promise;
    }

    async update(tagCategoryMapping) {
        const url = `${TAG_CATEGORY_MAPPING_ENDPOINT}${tagCategoryMapping.id}/`;
        const options = {
            headers: Auth.getHeaders().headers
        };

        const data = this.cls.toAPI(tagCategoryMapping);

        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Update Tag Category Mapping Api Error' }));
        return promise;
    }

    async delete(tagCategoryMappingId) {
        const url = `${TAG_CATEGORY_MAPPING_ENDPOINT}${tagCategoryMappingId}/`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };

        const promise = axios
            .delete(url, options)
            .catch(apiErrorHandler({ apiName: 'Delete Tag Category Mapping Api Error' }));
        return promise;
    }
}
