<template>
    <div class="component" @click="toggle" ref="dropdown">
        <Button
            v-bind="$attrs"
            class="outline-button"
            :class="{
                'outline-button_disabled': disabled,
                button_secondary: isSecondary,
                button_secondary_opened: opened
            }"
            @click.prevent.stop="toggle"
            :disabled="disabled"
            :loading="loading"
        >
            <slot></slot>
        </Button>

        <div
            class="dropdown"
            :class="{
                dropdown_opened: opened,
                'dropdown--top-side': openTop
            }"
        >
            <button
                class="dropdown__item"
                v-for="(item, key) in actions"
                :key="key"
                @click.prevent.stop="onClick({ handler: item.onclick })"
            >
                {{ item.name }}
            </button>
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';

export default {
    name: 'ButtonDropDown',

    inheritAttrs: false,

    components: {
        Button
    },

    props: {
        actions: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        openTop: {
            type: Boolean,
            default: false
        },
        isSecondary: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            opened: false
        };
    },
    mounted() {
        this.onClickDocument = this.onClickDocument.bind(this);
        document.addEventListener('click', this.onClickDocument);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.onClickDocument);
    },
    methods: {
        toggle() {
            if (!this.disabled) {
                this.opened = !this.opened;
                this.$emit('btn-toggle', this.opened);
            }
        },
        onClick({ handler }) {
            this.opened = false;
            if (handler) {
                handler();
            }
            this.$emit('btn-toggle', this.opened);
        },
        onClickDocument(event) {
            // Check is click happened on button or other places of documents. If other places - hide dropdown
            if (!this.$refs.dropdown.contains(event.target)) {
                this.opened = false;
                this.$emit('btn-toggle', this.opened);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.component {
    display: inline-flex;
    position: relative;
    cursor: pointer;

    &__arrow {
        align-self: center;
        margin-left: 5px;
        color: #b4b4b5;
    }
}

.dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    display: none;
    width: 200px;
    max-height: 0;
    font-weight: normal;
    background-color: $white;
    border-radius: 5px;
    overflow: hidden;
    z-index: 20;

    &--top-side {
        transform: translateY(-100%);
        top: 60px;
        right: unset;
        left: 0;
    }

    &__item {
        padding: 15px 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            background-color: #eef9ff;
            cursor: pointer;
        }
    }

    &_opened {
        display: flex;
        flex-direction: column;
        max-height: 500px;

        button {
            border: 0;
            background: transparent;

            &:focus {
                box-shadow: inset 0 0 0 2px $edsights-blue;
                outline: none;
            }
        }
    }
}

.icon {
    position: relative;
    top: 2px;
    width: 15px;
    height: 15px;
    margin-left: 15px;
}

.reversed {
    transform: rotate(180deg);
}

.outline-button {
    border-width: 1px;
    transition: none;

    &_disabled {
        color: $main-gray;
        background-color: $light-gray;
        border-width: 0px;
        cursor: not-allowed;

        .icon {
            visibility: hidden;
        }
    }
}

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Poppins-SemiBold;
    font-size: 12px;
    color: $white;
    letter-spacing: 0.5px;
    text-align: center;
    background: $edsights-blue;
    border-radius: 6px;
    border-color: $edsights-blue;
    padding: 0rem 3rem;
    outline: none;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        background-color: $btn-off-color;
        border-color: $btn-off-color;
    }

    &_secondary {
        color: #fff;
        border: 1px solid $black-alpha-0-25;
        background-color: white;

        &_opened {
            border: 1px solid $primary-brand-color;
            color: white;
        }
    }
}
</style>
