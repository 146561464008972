<template>
    <div class="tags">
        <TagItem
            class="tags__tag"
            :class="{ tags__tag_more: tag === '...' }"
            v-for="(tag, key) in tagsToDisplay"
            :key="key"
            :text="tag"
        />
    </div>
</template>

<script>
import TagItem from '@/components-deprecated/TagItem';

export default {
    name: 'StudentItemTags',

    components: {
        TagItem
    },

    props: {
        tags: {
            type: Array,
            default: () => ['Athlete', 'Female', 'Off Campus', 'Other', 'Tag']
        }
    },

    computed: {
        tagsToDisplay() {
            const tags = this.tags.map(({ name }) => name);
            if (tags.length > 3) {
                return [...tags.slice(0, 2), '...'];
            }
            return tags;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.tags {
    display: inline-flex;
    justify-content: flex-end;

    &__tag {
        margin-right: 5px;
        font-size: 12px;

        &_more {
        }
    }
}
</style>
