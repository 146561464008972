import * as Api from '@/api/index';
import axios from 'axios';
import { strictEqual } from 'assert';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';

export const getTouchpointsBySchoolId = async ({
    schoolId,
    page,
    page_size,
    isCustom,
    errorHandlerOptions = {}
} = {}) => {
    try {
        strictEqual(typeof schoolId, 'string');
        const url = Api.getv2ApiBase(`/touch-point/school/${schoolId}`);
        const defaultOptions = Api.getOptions();
        const params = {
            page,
            page_size,
            isCustom
        };

        const response = await axios.get(url, { ...defaultOptions, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the Touchpoints. Please try again.',
            ...errorHandlerOptions
        });
    }
};

export const getTouchpointsBySchoolIdWithInterventionStats = async ({
    schoolId,
    page,
    page_size,
    interventionTemplateType,
    errorHandlerOptions = {}
} = {}) => {
    try {
        strictEqual(typeof schoolId, 'string');
        const url = Api.getv2ApiBase(`/touch-point/with-intervention-stats/school/${schoolId}`);
        const defaultOptions = Api.getOptions();
        const params = {
            page,
            page_size,
            interventionTemplateType
        };

        const response = await axios.get(url, { ...defaultOptions, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the Touchpoints. Please try again.',
            ...errorHandlerOptions
        });
    }
};

export const findTouchpointById = async ({
    id,
    returnRendered = false,
    includeInterventionTemplates = false,
    errorHandlerOptions = {}
} = {}) => {
    try {
        strictEqual(typeof id, 'string');
        strictEqual(typeof errorHandlerOptions, 'object');
        const url = Api.getv2ApiBase(`/touch-point/${id}`);
        const defaultOptions = Api.getOptions();
        const params = {};

        if (typeof returnRendered === 'boolean') {
            params.returnRendered = returnRendered;
        }

        if (typeof includeInterventionTemplates === 'boolean') {
            params.includeInterventionTemplates = includeInterventionTemplates;
        }

        const response = await axios.get(url, { ...defaultOptions, params });

        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the Touchpoint. Please try again.',
            ...errorHandlerOptions
        });
    }
};
