<template>
    <div class="text-input-container">
        <input
            type="text"
            :placeholder="placeholder"
            :value="modelValue"
            @input="onInput($event.target.value)"
            :disabled="disabled"
            ref="input"
        />
        <svg v-if="iconType === 'search'" class="icon-search" viewBox="0 0 50 50">
            <use xlink:href="#icon-search" />
        </svg>
    </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
    name: 'TextInput',
    props: {
        placeholder: {
            type: String,
            required: false
        },
        iconType: {
            type: String,
            required: false,
            validator: function(value) {
                return ['search'].includes(value);
            }
        },
        // auto-bound to v-model
        modelValue: {
            type: String,
            required: true
        },
        debounce: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autoFocus: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.onInput = debounce(this.onInput, this.debounce ? 1000 : 0);
    },
    mounted() {
        if (this.autoFocus) {
            this.$refs.input.focus();
        }
    },
    methods: {
        onInput(value) {
            this.$emit('update:modelValue', value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.text-input-container {
    position: relative;

    input {
        width: 100%;

        outline: none;
        font-family: $base-font-family;
        font-size: 1rem;
        letter-spacing: $base-letter-spacing;
        border-radius: 0.4rem;
        border: $base-border-width solid $secondary-gray;
        background-color: $white-blue;
        padding: 0.5rem 4rem 0.5rem 1rem;
        min-height: 0;
        min-width: 0;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }

    .icon-search {
        position: absolute;
        right: 0;
        top: 0.6rem;
        width: 1.5rem;
        height: 1.5rem;
        fill: $main-gray;
    }
}
</style>
