<template>
    <div class="result-box">
        <!-- Only return first N results -->
        <div
            v-for="student in $attrs.students.slice(0, $attrs.maxStudents)"
            :key="student.id"
            @click="onClickStudent(student)"
            class="result-box__result"
        >
            {{ student.firstName }} {{ student.lastName }}
        </div>
        <div v-if="$attrs.students.length" @click="onClickSeeAll()" class="result-box__result">
            See All
        </div>
        <div v-else>
            No Results
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        onClickSeeAll() {
            this.$emit('seeAll');
        },
        onClickStudent(student) {
            this.$emit('student', student);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.result-box {
    display: block;
    width: 260px;
    position: absolute;
    color: black;
    padding: 0.5rem 1rem;
    line-height: 1.8rem;
    background-color: white;

    &__result {
        cursor: pointer;
    }
}
</style>
