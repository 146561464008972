<template>
    <modal
        classes="v--modal"
        name="school-documents-upload-modal"
        width="800"
        height="auto"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        :stack="false"
        @before-open="beforeOpen"
        @closed="closed"
        tabIndex="-1"
    >
        <div class="modal">
            <header class="modal__header">
                <h3 class="modal__header__title">
                    Upload New File for {{ this.$store.state.user.schoolRef.name }}
                </h3>
            </header>
            <div class="modal__content">
                <div class="step">
                    <span class="step-title"> Step 1: Name File </span>

                    <input type="text" v-model="fileName" />
                </div>
                <div class="step">
                    <span class="step-title"> Step 2: Upload File </span>
                    <br />
                    <div class="upload-container">
                        <br />
                        <ChooseFileCancelable
                            :file-type="'*'"
                            :readAsString="false"
                            @loaded="fileUploaded"
                        >
                            <template v-slot:message>
                                <h3 class="upload-message">Upload your File</h3>
                            </template>
                        </ChooseFileCancelable>
                    </div>
                </div>
                <div class="step">
                    <Button class="modal__btn" styleType="off" @click="onCancel">Cancel</Button>
                    <Button class="modal__btn" @click="onSubmit">Submit</Button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Button from '@/components-deprecated/Button';
import ChooseFileCancelable from '@/components-deprecated/ChooseFileCancelable';

export default {
    components: {
        Button,
        ChooseFileCancelable
    },
    data() {
        return {
            file: null,
            fileName: '',
            createdBy: null,
            schoolId: null,
            uploadedFileName: '',
            mimeType: ''
        };
    },
    computed: {
        userIsStaff() {
            return this.$store.state.user.isStaff;
        }
    },
    methods: {
        fileUploaded(file) {
            this.file = file;
            this.uploadedFileName = file.name;
            this.mimeType = file.type;
        },
        async beforeOpen(event) {
            this.createdBy = this.$store.state.user.id;
            this.schoolId = this.$store.state.user.schoolRef.id;
            if (event.params) {
                this.handler = event.params.handler;
            }
        },
        closed() {
            this.file = null;
            this.fileName = '';
            this.createdBy = null;
            this.schoolId = null;
            this.uploadedFileName = '';
            this.mimeType = '';
        },
        onCancel() {
            this.$emit('onModalCancel');
        },
        onSubmit() {
            const schoolDocument = {};
            try {
                if (!this.file || !this.fileName) {
                    throw new Error('Incomplete form');
                }
                schoolDocument.fileName = this.fileName;
                schoolDocument.createdBy = this.createdBy;
                schoolDocument.schoolId = this.schoolId;
                schoolDocument.uploadedFileName = this.uploadedFileName;
                schoolDocument.mimeType = this.mimeType;
                const fileToUpload = this.file;
                this.$emit('createSchoolDocument', { schoolDocument, fileToUpload });
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: 'You must complete all values in the form.',
                    timeout: 5000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/views/modal';
@import '../styles/forms';
@import '../styles/variables';

.modal {
    &__header {
        background-color: white;
        padding: 1rem 0rem;
        margin: 0rem;
        border-bottom: solid 1px #e3eded;
        &__title {
            margin: 0 2rem;
            font-weight: bold;
            font-size: 1.5rem;
            color: #202123;
        }
    }
}
.step-title {
    font-weight: bold;
}

.modal__content {
    padding: 0rem 0rem;
}

.modal__btn {
    margin-left: 15px;
}

.step {
    margin: 2rem 2rem;
}

.step-error {
    color: red;
    font-size: 10px;
    font-style: italic;
}
.step-info {
    color: $primary-brand-color;
    font-size: 10px;
    font-style: italic;
}
</style>
