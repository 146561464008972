import { objectToSnakeCase, objectToCamelCase } from '../utils';
import api from './api';

export default class TagMappings {
    static api = api.create(TagMappings);
    static readOnlyFields = ['id'];
    constructor({ id = null, tagCategoryMapping = null, rawName = null, tag = null } = {}) {
        Object.assign(this, {
            id,
            tagCategoryMapping,
            rawName,
            tag
        });
    }
    static create(data = {}) {
        return new TagMappings(data);
    }
    static fromAPI(json = {}) {
        const jsonCamel = objectToCamelCase(json);
        return new TagMappings(jsonCamel);
    }
    static toAPI(d, fields = [], excludeFields = []) {
        let data = {};
        if (fields.length > 0) {
            fields.forEach(f => {
                data[f] = d[f];
            });
        } else {
            data = d;
        }
        excludeFields = [...TagMappings.readOnlyFields, ...excludeFields];
        excludeFields.forEach(f => {
            delete data[f];
        });
        return objectToSnakeCase(data);
    }
}
