import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';

export const listSchoolsWithUnverifiedSubAccounts = () => {
    const url = Api.getv2ApiBase('/twilio/unverified-subaccounts');

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const listTwilioSubAccountVerificationStatuses = () => {
    const url = Api.getv2ApiBase('/twilio/verification-statuses');

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};
