import axios from 'axios';

import { API_BASE, apiErrorHandler } from '@/services/api';
import Auth from '../auth';
import { getv2ApiBase } from '../../api/index';
import store from '../../store';

const TAG_CAT_ENDPOINT = `${API_BASE}tag_categories/`;

export default class TagCategoriesAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new TagCategoriesAPI(cls);
    }

    async createTagCategory(tagCategory) {
        const url = TAG_CAT_ENDPOINT;
        const data = tagCategory;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .post(url, data, options)
            .then(res => res)
            .catch(apiErrorHandler({ apiName: 'Create Tag Category Api Error' }));
        return promise;
    }
    async list(params = {}) {
        let url = TAG_CAT_ENDPOINT;
        if (params.v2) {
            params.schoolId = store.getters.user.school;
            url = getv2ApiBase(`/tag-category`);
        }
        const options = {
            headers: await Auth.getHeaders().headers,
            params: this.cls.toAPI(params)
        };

        try {
            const response = await axios.get(url, options);

            const result = {
                ...response.data,
                results: response.data.results.map(res => this.cls.fromAPI(res))
            }

            return result;
        } catch (ex) {
            const errorHandlerFunc = apiErrorHandler({ apiName: 'Create Tag Category Api Error' });
            errorHandlerFunc(ex);
        }
    }

    async delete(tagCategory) {
        const url = `${TAG_CAT_ENDPOINT}${tagCategory.id}/`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };

        const promise = axios
            .delete(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Delete Tag Category Api Error' }));

        return promise;
    }

    async update(tagCategory) {
        const url = `${TAG_CAT_ENDPOINT}${tagCategory.id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .patch(url, tagCategory, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Update Tag Category Api Error' }));

        return promise;
    }
    /*
     * This function fetches the first page of the tag Category list and can be used to
     * pre populate the cache. We have instances where we need the first page several times and this
     * avoids several calls to the api
     */
    warmUpCache(schoolId) {
        this.list({
            page: 1,
            page_size: 25,
            schoolId: schoolId,
            has_students: true,
            slim: true,
            v2: true
        });
    }
}
