<template>
    <div class="tags-dropdown-container">
        <TransitionDropdown
            style="width: 125px"
            :choices="tagOptions"
            :selected="computedSelectedTag"
            @on-select="onTagSelected"
        />
    </div>
</template>

<script>
import TransitionDropdown from '@/components-deprecated/inputs/TransitionDropdown';

export default {
    name: 'ResourceTagsDropdown',
    components: {
        TransitionDropdown
    },
    data() {
        return {
            localSelectedTag: this.selectedTag
        };
    },
    props: {
        selectedTag: {
            type: Object,
            required: true
        },
        value: {
            type: String,
            required: true
        },
        resourceBindingTags: {
            type: Object,
            required: true
        }
    },
    methods: {
        onTagSelected(tag) {
            this.localSelectedTag = tag;
            this.$emit('onTagSelected', tag);
        }
    },
    computed: {
        tagOptions() {
            // get list of tags for drop-down to preview different resources based on tags
            if (this.value !== '') {
                const defaultOption = { id: 'default', name: 'Default' };
                return [
                    defaultOption,
                    ...Object.entries(this.resourceBindingTags).map(([id, name]) => ({
                        id,
                        name
                    }))
                ];
            }

            return [];
        },
        computedSelectedTag() {
            return this.localSelectedTag;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.tags-dropdown-container {
    display: flex;
    flex-direction: row;

    .icon-button {
        cursor: pointer;
        .icon {
            height: 30px;
            width: 30px;
        }
    }
}
</style>
