import axios from 'axios';
import { strictEqual } from 'assert';

import { apiErrorHandler } from '@/services/api';
import * as Api from './index';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';

export const findById = async ({ id, includeFull, errorHandlerOptions = {} }) => {
    try {
        const url = Api.getv2ApiBase(`/chatbot-flow/${id}`);
        const options = Api.getOptions();

        const params = {};

        if (typeof includeFull === 'boolean') {
            params.includeFull = includeFull;
        }

        const response = await axios.get(url, { ...options, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the flow. Please try again.',
            ...errorHandlerOptions
        });
    }
};

export const getChatbotFlows = async ({
    statuses,
    page,
    page_size,
    labels,
    schoolId,
    name,
    errorHandlerOptions = {}
}) => {
    try {
        const defaultOptions = Api.getOptions();
        const url = Api.getv2ApiBase('/chatbot-flow');
        const params = {
            page,
            page_size,
            schoolId,
            statuses
        };

        if (name && name.trim() !== "") {
            params.name = name;
        }

        if (Array.isArray(labels) && labels.length > 0) {
            params.labels = labels.join(',');
        }

        if (Array.isArray(statuses)) {
            params.statuses = statuses.join(',');
        }

        const response = await axios.get(url, { ...defaultOptions, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the chatbot flows list. Please try again.',
            ...errorHandlerOptions
        })
    }
};

export const updateChatbotFlow = (id, chatbotFlow, errorHandlerOptions = {}) => {
    const url = Api.getv2ApiBase(`/chatbot-flow/${id}`);
    const options = Api.getOptions();

    return axios
        .patch(url, chatbotFlow, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                ...errorHandlerOptions
            })
        );
};

export const validateChatbotFlow = ({ chatbotFlow, errorHandlerOptions = {} }) => {
    const url = Api.getv2ApiBase(`/chatbot-flow/validate`);
    const options = Api.getOptions();

    return axios
        .post(url, chatbotFlow, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                ...errorHandlerOptions
            })
        );
};

export const createFlowsFromTemplateIds = ({ schoolId, templateIds, errorHandlerOptions = {} }) => {
    const url = Api.getv2ApiBase(`/chatbot-flow/create-from-templates`);
    const options = Api.getOptions();

    const payload = {
        schoolId,
        chatbotTemplateIds: templateIds,
        status: CHATBOT_FLOW_STATUS.DRAFT.value,
        date: DRAFT_NULL_DATE_PLACEHOLDER
    };

    return axios
        .post(url, payload, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                overrideMessage: 'There was a problem creating the flows. Please try again.',
                ...errorHandlerOptions
            })
        );
};

export const validateChatbotFlowTemplateBindings = ({
    chatbotFlowId,
    errorHandlerOptions = {}
}) => {
    const url = Api.getv2ApiBase(`/chatbot-flow/${chatbotFlowId}/validate-template-bindings`);
    const options = Api.getOptions();

    return axios
        .get(url, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                overrideMessage:
                    'Whoops. We were not able to validate this chatbot flows template bindings. Please try again.',
                ...errorHandlerOptions
            })
        );
};

export const createFlow = ({ payload, errorHandlerOptions = {} } = {}) => {
    const url = Api.getv2ApiBase(`/chatbot-flow`);
    const options = Api.getOptions();

    return axios
        .post(url, payload, options)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                ...errorHandlerOptions
            })
        );
};

export const copyChatbotFlow = async ({ id, overrides = {}, errorHandlerOptions = {} } = {}) => {
    try {
        strictEqual(typeof id, 'string', 'id must be a string');
        strictEqual(typeof overrides, 'object', 'overrides must be an object');
        strictEqual(typeof errorHandlerOptions, 'object', 'errorHandlerOptions must be an object');

        const url = Api.getv2ApiBase(`/chatbot-flow/copy/${id}`);
        const options = Api.getOptions();

        const payload = { overrides };
        const response = await axios.post(url, payload, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem. Please try again.',
            ...errorHandlerOptions
        });
    }
};
