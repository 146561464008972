<template>
    <modal
        class="reset-password-modal"
        name="modal-reset-admin-password"
        height="auto"
        :clickToClose="false"
        :stack="false"
        :scrollable="false"
        @before-open="beforeOpen"
        tabIndex="-1"
    >
        <div class="container">
            <h2>Reset Password</h2>
            <div class="separator"></div>
            <p>for {{ admin.email }}</p>

            <form v-on:submit.prevent="handleFormSubmit()" class="reset-form">
                <div v-if="formErrors && formErrors.message" class="reset-error">
                    <p>{{ formErrors.message }}</p>
                </div>

                <div class="input">
                    <input type="text" v-model="password" placeholder="New password" required />
                    <span v-if="formErrors && formErrors.new_password1" class="field-error">
                        <span v-for="error in formErrors.new_password1">{{ error }}</span>
                    </span>
                </div>
                <div class="input">
                    <input
                        type="text"
                        v-model="confirmPassword"
                        placeholder="Confirm new password"
                        required
                    />
                    <span v-if="formErrors && formErrors.new_password2" class="field-error">
                        <span v-for="error in formErrors.new_password2">{{ error }}</span>
                    </span>
                </div>

                <div class="footer">
                    <button type="button" class="cancel-btn" @click="onClose">
                        Cancel
                    </button>
                    <loading-spinner-button
                        class="save-btn"
                        type="submit"
                        text="Save"
                        :loading="loading"
                    />
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton.vue';
import { resetAdminPassword } from '@/api/admin';

const getInitialData = () => ({
    password: '',
    confirmPassword: '',
    formErrors: null,
    loading: false,
    admin: {}
});

export default {
    name: 'ModalResetAdminPassword',
    components: { LoadingSpinnerButton },
    data() {
        return getInitialData();
    },
    methods: {
        async beforeOpen(event) {
            // reset state for reuse across session
            Object.assign(this.$data, getInitialData());

            if (event.params.admin) {
                this.admin = event.params.admin;
            }
        },
        handleFormSubmit() {
            this.formErrors = null;
            this.loading = true;

            resetAdminPassword({
                id: this.admin.id,
                password: this.password,
                confirmPassword: this.confirmPassword
            })
                .then(() => {
                    this.formErrors = null;
                    this.loading = false;
                    this.onClose();

                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>New password has been set.</h2>',
                        timeout: 6000
                    });
                })
                .catch(error => {
                    const { response, message } = error;
                    this.loading = false;
                    if (response) {
                        this.formErrors = response.data;
                    }
                    if (message) {
                        this.formErrors = { message };
                    }
                });
        },
        onClose() {
            this.$modal.hide('modal-reset-admin-password');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/forms';
@import '../../styles/mixins/alerts';
@import '@/styles/variables';
@import '@/styles/mixins/buttons';

.container {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .input {
        margin-bottom: 1rem;
        width: 30rem;
    }
}

.reset-form {
    margin-top: 2rem;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 1rem 0;
}

.save-btn {
    @include base-button();
}

.cancel-btn {
    @include secondary-button();
    margin-right: 1rem;
}

.reset-error {
    @include alert-error();
}

.separator {
    width: 100%;
    height: 1px;
    background: #e3eded;
    margin: 1.5rem 0;
}
</style>
