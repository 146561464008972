import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import modules from './modules';

import { User } from '@/services/users';

// Cycle the hash when we need to 'clear' local storage
// TODO: Automatically generate a new hash on new builds
const STORAGE_HASH = 'a923qfjnasddd';
export const STORAGE_KEY = `edsights-${STORAGE_HASH}`;

export function getInitialState() {
    return {
        user: User.create(),
        userToken: null,
        globalLoading: false,
        backgroundOperations: [],
        backgroundOperationActions: [],
        staffSchoolViewEnabled: false,
        pendingAlertCount: 0
    };
}

export default createStore({
    // Do not run strict mode in production,
    // because it runs a deep watcher!
    strict: process.env.NODE_ENV !== 'production',
    state: getInitialState(),
    mutations,
    actions,
    getters,
    modules,
    plugins: [
        createPersistedState({
            key: STORAGE_KEY
        })
    ]
});
