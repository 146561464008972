<template>
    <div class="dashboard-container">
        <h1>Create SFTP User</h1>
        <div class="label-input-container">
            <div class="label-container">
                <p>School</p>
                <p>Username</p>
                <p>SSH Public Key</p>
            </div>
            <div class="input-container">
                <v-select
                    :options="schools"
                    v-model="selectedSchool"
                    label="name"
                    @update:modelValue="schoolSelectionHandler"
                    placeholder="Search for a school..."
                    :clearable="false"
                    class="school-dropdown"
                />
                <input
                    class="input-box"
                    v-model="userName"
                    placeholder="edsightsuniversityoftechnology"
                />
                <textarea
                    class="input-textarea"
                    v-model="sshKey"
                    placeholder="please paste the key exactly as is, no lines or spaces afterward"
                ></textarea>
            </div>
            <div class="instruction-container">
                <ol>
                    <li>
                        Choose a school from the dropdown list.
                    </li>
                    <li>
                        Username is populated automatically with no spaces/caps/special characters.
                        Only overwrite this if the username is missing characters.
                    </li>
                    <li>
                        Insert SSH Key. Preferred ssh key would be single line format. If multi line
                        format is inserted, the tool shouldn't have a problem importing, but if
                        there is a problem, notify engineering to fix it.
                    </li>
                </ol>
            </div>
        </div>
        <Button
            class="button-create"
            width="30rem"
            @click="createSftpUserHandler"
            v-if="!showLoadingSpinner"
        >
            Create User
        </Button>
        <div v-else>
            <LoadingSpinner />
        </div>
        <ul class="list-container" v-if="isUserCreated">
            <li>{{ successLog1 }}</li>
            <li>{{ successLog2 }}</li>
            <li>{{ successLog3 }}</li>
        </ul>
    </div>
</template>

<script>
import * as SchoolApi from '@/api/school';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/global/Button';

export default {
    name: 'CreateSftpUser',
    components: {
        LoadingSpinner,
        Button
    },
    data() {
        return {
            schoolId: '',
            userName: '',
            sshKey: '',
            schools: [],
            isUserCreated: false,
            successLog1: '',
            successLog2: '',
            successLog3: '',
            showLoadingSpinner: false,
            selectedSchool: ''
        };
    },
    created() {
        this.loadSftpSchoolList();
    },
    methods: {
        async loadSftpSchoolList() {
            this.schools = await SchoolApi.listSchoolsWithoutStorageBucket();
        },
        formatString(inputString) {
            const formattedString = inputString.replace(/[\s\p{P}\d]/gu, '').toLowerCase();
            return formattedString;
        },
        schoolSelectionHandler(value) {
            const selected = this.schools.find(school => school.id === value.id);
            if (selected) {
                this.selectedSchool = selected;
                this.userName = this.formatString(selected.name);
                this.schoolId = selected.id;
            }
        },
        async createSftpUserHandler() {
            this.showLoadingSpinner = true;
            try {
                const payload = {
                    schoolId: this.schoolId,
                    userName: this.userName,
                    sshKey: this.sshKey
                };

                const response = await SchoolApi.createSftpUser({ payload });
                if (response) {
                    this.isUserCreated = true;
                    [this.successLog1, this.successLog2, this.successLog3] = response.logs;
                    this.$Alert.alert({
                        type: 'success',
                        message: 'SFTP User Created!',
                        timeout: 3000
                    });
                    this.selectedSchool = '';
                    this.schoolId = '';
                    this.sshKey = '';
                    this.userName = '';
                }
            } catch (error) {
                console.error(error);
            } finally {
                this.showLoadingSpinner = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.label-input-container {
    display: flex;
    gap: 1rem;
    margin-left: 27.5rem;
    margin-top: 2rem;
}
.label-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 5px;
}
.input-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 25rem;
}
.input-box {
    height: 27px;
    width: 30rem;
    padding-left: 10px;
    border: 1px solid #3c3c3c42;
    border-radius: 3px;
}
.input-textarea {
    height: 10rem;
    width: 30rem;
    border-radius: 3px;
    border-color: #3c3c3c42;
}
.button-create {
    margin-left: 10px;
}
.school-dropdown {
    width: 30rem;
}
.instruction-container {
    width: 40rem;
    padding-left: 6rem;
}
.list-container {
    margin-left: 10rem;
}
</style>
