<template>
    <div class="menubar-container" :class="typeClass">
        <Menubar_ :model="items" :ariaLabel="ariaLabel" :pt="pt" unstyled>
            <template #start>
                <slot name="start"></slot>
            </template>
            <template #end>
                <slot name="end"></slot>
            </template>
        </Menubar_>
    </div>
</template>

<script>
import Menubar_ from 'primevue/menubar';

export default {
    name: 'Menubar',
    components: { Menubar_ },
    props: {
        items: {
            type: Array,
            required: true
        },
        ariaLabel: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: true,
            validator: value => {
                return ['MAIN_NAVIGATION'].includes(value);
            }
        }
    },
    data() {
        return {
            // See "Pass through" section here to see the definition of
            // each option: https://v3.primevue.org/menubar/
            pt: {
                root: {
                    class: ['root']
                },
                menu: {
                    class: ['menu']
                },
                menuitem: {
                    class: ['menuitem']
                },
                content: {
                    class: ['content']
                },
                action: {
                    class: ['action']
                },
                submenu: {
                    class: ['submenu']
                },
                end: {
                    class: ['end']
                },
                button: {
                    class: ['button']
                }
            }
        };
    },
    computed: {
        typeClass() {
            const typeClassMap = {
                MAIN_NAVIGATION: 'main-navigation'
            };

            return typeClassMap[this.type] || '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.menubar-container {
    height: $navigation-height;

    // Note: to style these pass-through sections, we looked at the
    // .p-menubar styles in a theme file for guidance. We took only
    // what is needed, and added our own colors/sizing.
    // Example theme: 'node_modules/primevue/resources/themes/lara-dark-cyan/theme.css'
    &.main-navigation {
        :deep() {
            .root {
                display: flex;
                background: $dark-gray;
            }

            .menu {
                display: flex;
                height: $navigation-height;
                margin: 0;
                padding: 0;
                list-style: none;
                outline: none;
            }

            .menuitem {
                cursor: pointer;
                &:hover,
                &.active-route {
                    background-color: $light-gray-2;
                    outline: 0;
                }

                // in unstyled mode primevue seems to not place the focus
                // class as seen in the theme file, but does apply a data-p-focused attribute:
                &[data-p-focused='true'] {
                    outline: 2px solid $edsights-blue;
                }
            }

            .content {
                height: $navigation-height;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .action {
                color: white;
                width: 100%;
                height: 100%;
                padding: 0 10px 0 10px;

                display: flex;
                align-items: center;
            }

            .submenu {
                position: absolute;
                background: $dark-gray;
                width: 175px;
            }

            .end {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            // only used when smaller screen breakpoints are used - hiding for now
            .button {
                display: none;
            }
        }
    }
}
</style>
