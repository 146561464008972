<template>
    <div class="date-input-container">
        <input
            type="date"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            :disabled="disabled"
        />
    </div>
</template>

<script>
export default {
    name: 'DateInput',
    props: {
        // auto-bound to v-model
        // format: YYYY-MM-DD
        modelValue: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.date-input-container {
    position: relative;
    input {
        width: 100%;

        outline: none;
        font-family: $base-font-family;
        font-size: 1rem;
        letter-spacing: $base-letter-spacing;
        border-radius: 0.4rem;
        border: $base-border-width solid $secondary-gray;
        background-color: $white-blue;
        padding: 0.5rem 1rem;
    }
}
</style>
