<template>
    <div class="bar-chart-container">
        <Bar :chartData="chartData" :chartOptions="chartOptions" :ariaLabel="ariaLabel" />
    </div>
</template>

<script>
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'vue-chartjs';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        chartData: {
            type: Object,
            default: null
        },
        title: {
            type: String,
            required: false
        },
        suggestedMin: {
            type: Number,
            default: 1
        },
        suggestedMax: {
            type: Number,
            default: 100
        },
        showXGridLines: {
            type: Boolean,
            default: false
        },
        showYGridLines: {
            type: Boolean,
            default: true
        },
        showLegend: {
            type: Boolean,
            default: true
        },
        displayXTicks: {
            type: Boolean,
            default: true
        },
        displayYTicks: {
            type: Boolean,
            default: true
        },
        displayTooltipColors: {
            type: Boolean,
            default: true
        },
        tooltipsEnabled: {
            type: Boolean,
            default: true
        },
        tooltipCallbacks: {
            type: Object,
            required: false
        },
        xTicksCallback: {
            type: Function,
            required: false
        },
        tooltipYAlign: {
            type: String,
            required: false
        },
        tooltipXAlign: {
            type: String,
            required: false
        },
        tooltipBackgroundColor: {
            type: String,
            required: false
        },
        tickLabelFontColor: {
            type: String,
            default: 'black'
        },
        tickLabelFontFamily: {
            type: String,
            default: 'Poppins-Regular, sans-serif'
        },
        // The ariaLabel should describe the chart and its data.
        // It gets applied as an aria-label on the chart's parent element.
        // Example: "Sales figures for the years 2022 to 2024. Sales in 2022: 987, Sales in 2023: 1209, Sales in 2024: 825."
        ariaLabel: {
            type: String,
            required: true,
            // Fallback provided because a blank aria-label can be harmful to older screen readers,
            // and something is better than nothing.
            // Please fix any usages where this prop validation is failing with a detailed value.
            default: 'A chart with data.'
        },
        // indexAxis "x" means vertical bars, and "y" means horizontal bars
        indexAxis: {
            type: String,
            default: 'y'
        },
        // Enables the `chartjs-plugin-datalabels` plugin to show values at the end of bars
        displayDataLabels: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        chartOptions() {
            return {
                indexAxis: this.indexAxis,
                layout: {
                    padding: {
                        // top padding accommodates data labels when this.displayDataLabels is true
                        top: 20
                    }
                },
                plugins: {
                    tooltip: {
                        enabled: this.tooltipsEnabled,
                        displayColors: this.displayTooltipColors,
                        callbacks: this.tooltipCallbacks,
                        yAlign: this.tooltipYAlign,
                        xAlign: this.tooltipXAlign,
                        backgroundColor: this.tooltipBackgroundColor
                    },
                    legend: {
                        display: this.showLegend
                    },
                    datalabels: {
                        display: this.displayDataLabels,
                        anchor: 'end',
                        align: 'end',
                        color: 'black'
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            display: this.displayXTicks,
                            callback: this.xTicksCallback,
                            suggestedMax: this.suggestedMax,
                            suggestedMin: this.suggestedMin,
                            color: this.tickLabelFontColor,
                            font: {
                                family: this.tickLabelFontFamily
                            }
                        },
                        grid: {
                            display: this.showXGridLines,
                            drawBorder: false
                        }
                    },
                    y: {
                        ticks: {
                            display: this.displayYTicks,
                            beginAtZero: true,
                            color: this.tickLabelFontColor,
                            font: {
                                family: this.tickLabelFontFamily
                            }
                        },
                        grid: {
                            display: this.showYGridLines,
                            drawBorder: false
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            };
        }
    }
};
</script>

<style scoped>
.bar-chart-container {
    position: relative;
}
</style>
