<template>
    <Modal
        title="Add/Edit Linked Tags"
        primaryButtonTitle="Confirm"
        secondaryButtonTitle="Cancel"
        @secondary-button-clicked="onClose"
        @primary-button-clicked="save"
        :showPrimaryButtonLoadingSpinner="showLoadingSpinner"
        :primaryButtonDisabled="showLoadingSpinner"
        :secondaryButtonDisabled="showLoadingSpinner"
        size="large"
    >
        <div class="content">
            <p>Select your desired Tags</p>
            <StudentAnalysisFilter
                @filterChanged="selectionChange"
                :showStudentSearch="false"
                :showTagFilter="true"
                :showRiskLevelFilter="false"
                :showRiskDriverFilter="false"
                :showPhaseFilter="false"
                :showNumberValidityFilter="false"
                :showOptInStatusFilter="false"
                :showActionTakenFilter="false"
                :showMoreFiltersButton="false"
                :initialTags="tags"
            />
        </div>
    </Modal>
</template>

<script>
import StudentAnalysisFilter from '@/components-deprecated/StudentAnalysisFilter.vue';
import Modal from '@/components-deprecated/global/Modal';

export default {
    name: 'MessagingTracksEditTagsModal',
    components: { StudentAnalysisFilter, Modal },
    data() {
        return {
            showLoadingSpinner: false,
            selectedTags: null
        };
    },
    props: {
        tags: {
            type: Object,
            required: true
        }
    },
    methods: {
        save() {
            this.showLoadingSpinner = true;
            this.$emit('save', this.selectedTags);
        },
        onClose() {
            this.$emit('onClose');
        },
        selectionChange(payload) {
            if (!(typeof payload === 'object')) {
                return;
            }

            if (typeof payload.tagIds === 'string') {
                this.selectedTags = payload.tagIds.split(',').map(t => parseInt(t));
            } else {
                this.selectedTags = [];
            }
        }
    }
};
</script>
