<template>
    <div class="rename-label-modal-container">
        <Modal
            title="Rename Label"
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            :primaryButtonDisabled="disableSave"
            :showPrimaryButtonLoadingSpinner="saving"
            @secondary-button-clicked="onCancel"
            @primary-button-clicked="onSave"
        >
            <div class="modal-content">
                <TextInput placeholder="Rename label..." v-model="textInputValue" />
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';
import TextInput from '@/components-deprecated/global/TextInput';

export default {
    name: 'RenameLabelModal',
    components: { Modal, TextInput },
    props: {
        saving: {
            type: Boolean,
            default: false
        },
        labelText: {
            type: String,
            required: true
        }
    },
    created() {
        this.textInputValue = this.labelText;
    },
    data() {
        return {
            textInputValue: ''
        };
    },
    methods: {
        onSave() {
            this.$emit('onSave', this.textInputValue);
        },
        onCancel() {
            this.$emit('onCancel');
        }
    },
    computed: {
        disableSave() {
            return this.textInputValue === this.labelText || !this.textInputValue;
        }
    }
};
</script>

<style lang="scss" scoped>
.rename-label-modal-container {
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>
