<template>
    <div class="header">
        <div class="header__name">Messaging Track</div>
        <div class="header__count">Student Count</div>
        <div class="header__linked">Linked Tags</div>
    </div>
</template>

<script>
export default {
    name: 'MessagingTracksHeader'
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: $white-blue;

    & > * {
        width: 15%;
    }

    &__name {
        width: 30%;
    }

    &__linked {
        width: 60%;
    }

    &__count {
        width: 10%;
    }
}
</style>
