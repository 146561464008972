<template>
    <div class="container">
        <TabsContainer initialTabId="Create SFTP User" class="nested-tabs-container">
            <Tab id="Create SFTP User">
                <CreateSFTPUser />
            </Tab>
            <Tab id="Auto Integrations">
                <ToggleAutoIntegrations />
            </Tab>
        </TabsContainer>
    </div>
</template>

<script>
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import CreateSFTPUser from '@/views/staff/StaffTools/components/SftpComponents/CreateSFTPUser.vue';
import ToggleAutoIntegrations from '@/views/staff/StaffTools/components/SftpComponents/ToggleAutoIntegrations';

export default {
    name: 'SFTPTools',
    components: {
        TabsContainer,
        Tab,
        CreateSFTPUser,
        ToggleAutoIntegrations
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.container {
    :deep() {
        .nested-tabs-container .tabs .tab {
            font-size: 1rem !important;

            &:hover {
                border-bottom: 3px solid #43d1ff !important;
            }

            &.is-active {
                color: #43d1ff !important;
                border-bottom: 3px solid #43d1ff !important;
            }
        }
    }
}
</style>
