<template>
    <label class="checkbox" :class="{ 'checkbox--align-center': alignCenter }">
        <input
            class="checkbox__input"
            type="checkbox"
            :disabled="disabled"
            :value="modelValue"
            :checked="modelValue"
            @click="onClick"
            ref="checkbox"
            :aria-describedby="label ? checkboxLabelId : undefined"
            :aria-label="label ? undefined : ariaLabel"
        />
        <span
            class="checkbox__checkmark"
            :class="{
                'checkbox__checkmark--circle': isCircle,
                'checkbox__checkmark--small': isSmall,
                'checkbox__checkmark--medium': isMedium
            }"
        ></span>
        <span class="checkbox__label" :id="checkboxLabelId">{{ label }}</span>
    </label>
</template>

<script>
import { v4 } from 'uuid';

export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        alignCenter: {
            type: Boolean,
            default: false
        },
        isCircle: {
            type: Boolean,
            default: false
        },
        isSmall: {
            type: Boolean,
            default: false
        },
        isMedium: {
            type: Boolean,
            default: false
        },
        ariaLabel: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            checkboxLabelId: `checkbox-label-${v4()}`
        };
    },
    methods: {
        onClick({ target }) {
            if (!this.disabled) {
                this.$emit('update:modelValue', target.checked);
            }
        },
        turnOff() {
            this.$refs.checkbox.checked = false;
        },
        turnOn() {
            this.$refs.checkbox.checked = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mixins/checkboxes';

.checkbox {
    @include checkbox();
    cursor: pointer;

    &:focus-within {
        .checkbox__checkmark {
            outline: 2px solid $edsights-blue;
        }
    }

    &--align-center {
        justify-content: center;
    }

    &__checkmark--circle {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        margin-right: 0;
        background: #f4f8fa;
        border: 2px solid #e9e8e9;
    }

    &__checkmark--circle.checkbox__checkmark:after {
        display: block;
        left: 10px;
        top: 4px;
        width: 7px;
        height: 13px;
        border: solid #e9e8e9;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }

    &__input {
        appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        z-index: 1;
        outline: 0 none;
        border: 1px solid $dark-gray;
        border-radius: 6px;
    }

    &__input:checked ~ &__checkmark--circle.checkbox__checkmark {
        border: 2px solid $primary-brand-color;
        background-color: $primary-brand-color;
    }

    &__checkmark--small {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    &__checkmark--small.checkbox__checkmark:after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
        border-width: 0 2px 2px 0;
    }

    &__checkmark--medium {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        margin-right: 0;
    }

    &__checkmark--medium.checkbox__checkmark:after {
        left: 5px;
        top: 1px;
        width: 4px;
        height: 8px;
        border-width: 0 2px 2px 0;
    }
}
</style>
