'use strict';

// This file contains things to help in the Vue 2 to Vue 3 upgrade.
// These solutions are only to aid the migration and should likely not be
// re-used anywhere else.

import moment from 'moment';

/**
 *  Used in places that were using the "moment" filter provided by vue-moment.
 *  This does the same thing using moment directly so we can get rid of vue-moment.
 *  Anything using this should eventually use the lib/dates.js module which uses Luxon.
 *  This was done instead of converting to lib/dates.js because moment accepts both strings
 *  and objects, we do not yet have a function in lib/dates.js that does that yet.
 *  Also, the format strings accepted by moment and Luxon differ and will take time to get right.
 * @param date {string|object}
 * @param format {string}
 * @returns {string}
 */
export const vueMomentFilterReplacement = (date, format) => {
    if (!date || !format) {
        return '';
    }

    return moment(date).format(format);
};
