<template>
    <div class="student-voice-score-card-container">
        <Card
            :title="title"
            :titleTooltip="titleTooltip"
            :headerButtonText="headerButtonText"
            @headerButtonClick="onHeaderButtonClick"
        >
            <div class="student-voice-score-card-content">
                <div v-if="enableFilters" class="filter-row">
                    <StudentAnalysisFilter
                        :singleSelectTags="true"
                        :showStudentSearch="false"
                        :showTagFilter="true"
                        :showRiskLevelFilter="false"
                        :showRiskDriverFilter="false"
                        :showPhaseFilter="false"
                        :showNumberValidityFilter="false"
                        :showOptInStatusFilter="false"
                        :showActionTakenFilter="false"
                        :showMoreFiltersButton="false"
                        :hideClearButton="true"
                        @filterChanged="filterChanged"
                    />
                </div>
                <LoadingSpinner v-if="loading" />
                <template v-else-if="showUnfilteredMessage">
                    Select a tag to view your SVS by Tag and compare the satisfaction levels across
                    different student populations.
                </template>
                <template v-else-if="showNoFilterResultsMessage">
                    There currently isn't enough data on the selected tag to generate a Tag SVS
                    score. Try again by selecting a different tag with more students.
                </template>
                <template v-else>
                    <ScoreProgress
                        :score="score"
                        :comparedScore="comparedScore"
                        :comparedScoreLabel="comparedScoreLabel"
                        :differenceLabelPrefix="differenceLabelPrefix"
                        showScoreGradient
                        showProgressBarColors
                    />
                    <Distribution
                        :promoterCount="promoterCount"
                        :passiveCount="passiveCount"
                        :detractorCount="detractorCount"
                    />
                </template>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from '@/components-deprecated/global/Card.vue';
import Distribution from './components/Distribution/Distribution.vue';
import StudentAnalysisFilter from '@/components-deprecated/StudentAnalysisFilter.vue';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';
import ScoreProgress from '../components/ScoreProgress/ScoreProgress.vue';

export default {
    name: 'StudentVoiceScoreCard',
    components: {
        StudentAnalysisFilter,
        LoadingSpinner,
        Distribution,
        Card,
        ScoreProgress
    },
    props: {
        comparedScore: {
            type: Number,
            required: false
        },
        comparedScoreLabel: {
            type: String,
            required: false
        },
        score: {
            type: Number,
            required: false
        },
        detractorCount: {
            type: Number,
            required: false
        },
        passiveCount: {
            type: Number,
            required: false
        },
        promoterCount: {
            type: Number,
            required: false
        },
        enableFilters: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Student Voice Score'
        },
        titleTooltip: {
            type: String,
            default: "Your school's latest SVS"
        },
        loading: {
            type: Boolean,
            default: false
        },
        headerButtonText: {
            type: String,
            required: false
        },
        differenceLabelPrefix: {
            type: String,
            required: false
        },
        differenceLabelSuffix: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            appliedFilter: null
        };
    },
    methods: {
        filterChanged(filter) {
            this.appliedFilter = filter;
            this.$emit('filterChanged', filter);
        },
        onHeaderButtonClick() {
            this.$emit('headerButtonClick');
        }
    },
    computed: {
        // Note: The filtering component we're using supports many filters, but we're only using
        // tagIds, so we only check tagIds here.
        hasAppliedFilters() {
            return this.appliedFilter !== null && this.appliedFilter.tagIds;
        },
        hasScore() {
            return typeof this.score === 'number';
        },
        showUnfilteredMessage() {
            return !this.loading && this.enableFilters && !this.hasAppliedFilters && !this.hasScore;
        },
        showNoFilterResultsMessage() {
            return !this.loading && this.enableFilters && this.hasAppliedFilters && !this.hasScore;
        }
    }
};
</script>

<style lang="scss" scoped>
.student-voice-score-card-container {
    .student-voice-score-card-content {
    }
}
</style>
