<template>
    <div class="forgot-password-success">
        <app-header></app-header>
        <div class="main">
            <img src="../../assets/edsights-logo.png" class="logo" alt="EdSights Logo" />
            <h2 class="subtitle">Help your students succeed.</h2>
            <p class="success">
                <svg class="icon" viewBox="0 0 477.857 477.857">
                    <use xlink:href="#icon-email-sent"></use>
                </svg>
                <span class="success-title">Email Sent!</span>
            </p>
            <p>
                Check your email to continue resetting your password. You may have to wait a few
                minutes or check your spam folder.
            </p>
            <p>
                If you are still having trouble, please reach out to us at
                <span class="contact-email">hello@edsights.io</span>
            </p>
            <p>
                <router-link class="redirect-link" :to="{ name: 'Login' }">
                    Return to log in page
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components-deprecated/AppHeader';

export default {
    name: 'ForgotPasswordSuccess',
    components: {
        AppHeader
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/forms';
@import '../../styles/mixins/alerts';
@import '../../styles/mixins/animations';

@import '../../styles/auth-pages';

.forgot-password-success {
    @extend %auth-page;
}

.success {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    .success-title {
        color: $mine-shaft;
        font-size: 1.4rem;
        font-weight: bold;
        margin-left: 0.5rem;
    }
}

.icon {
    fill: $mine-shaft;
    width: 3em;
    height: 3em;
    vertical-align: middle;
    margin: 0 1rem;
    @include mild-pulse-animation();
}

.contact-email {
    color: $primary-brand-color;
    font-size: 1.1rem;
    font-weight: 500;
    width: 100%;
    text-align: left;
}

.redirect-link {
    color: $primary-brand-color;
    font-size: 1.1rem;
    font-weight: 500;
}

.login-error {
    @include alert-error();
}
</style>
