<template>
    <div class="main">
        <div class="aa-top">
            <img class="logo" src="../assets/edsights-logo.png" alt="EdSights logo" />
            <br />
            <h2>{{ msg }}</h2>
            <br />
            <div class="aa-box">
                <p v-if="!error">
                    To undo this action, log into the EdSights dashboard here. If you do not have
                    access to the EdSights dashboard or are unable to log in please reach out to
                    your EdSights point of contact or to hello@edsights.io
                </p>
            </div>
            <br />
            <Button class="btn" type="button" @click="goToAlerts">Log In and view Alerts</Button>
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
import { alertAction } from '@/api/alerts';
export default {
    name: 'AlertAction',
    components: {
        Button
    },
    data() {
        return {
            msg: '',
            error: false
        };
    },
    async created() {
        const { code, action, st } = this.$route.query;

        const { status, msg } = await alertAction(code, action, st);

        this.error = status === 'error' ? true : false;
        this.msg = msg;
    },
    methods: {
        goToAlerts() {
            this.$router.push({ name: 'ManageAlerts' });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';
@import '../styles/mixins/animations';

.main {
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.btn {
    padding: 5px;
}

.box-container {
    margin: 2rem 2rem 0 2rem;
}
.aa-top {
    text-align: center;
    margin: 5rem;
    position: relative;
    z-index: 2;
    .logo {
        max-width: 14rem;
        margin-left: -0.5rem;
    }
}
.aa-box {
    text-align: center;
    max-width: 35rem;
    margin: auto;
}
</style>
