import axios from 'axios';
import { apiErrorHandler } from '../services/api';
import * as Api from './index';
import { strictEqual } from 'assert';

// creates a resource detail
export const createResourceDetail = resourceDetail => {
    strictEqual(typeof resourceDetail, 'object', 'resourceDetail must be an object');
    strictEqual(typeof resourceDetail.schoolId, 'string', 'schoolId must be a string');

    const url = Api.getv2ApiBase(`/resource-detail/${resourceDetail.schoolId}`);
    const payload = {
        status: resourceDetail.status,
        name: resourceDetail.name,
        instruction: resourceDetail.instruction,
        resourceNeedId: resourceDetail.resourceNeedId,
        schoolId: resourceDetail.schoolId
    };

    return axios
        .post(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Create Resource Detail error'
            })
        );
};

// updates a resource detail
export const updateResourceDetail = (id, resourceDetail) => {
    strictEqual(typeof id, 'string', 'id must be a string');
    strictEqual(typeof resourceDetail, 'object', 'resourceDetail must be an object');
    strictEqual(typeof resourceDetail.schoolId, 'string', 'schoolId must be a string');

    const url = Api.getv2ApiBase(`/resource-detail/${id}/school/${resourceDetail.schoolId}`);
    const payload = {
        status: resourceDetail.status,
        name: resourceDetail.name,
        instruction: resourceDetail.instruction
    };

    return axios
        .put(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Update Resource Detail error'
            })
        );
};

export const deleteResourceDetail = (id, schoolId) => {
    strictEqual(typeof id, 'string', 'id must be a string');
    strictEqual(typeof schoolId, 'string', 'schoolId must be a string');

    const url = Api.getv2ApiBase(`/resource-detail/${id}/school/${schoolId}`);

    return axios.delete(url, Api.getOptions()).catch(
        apiErrorHandler({
            apiName: 'Delete Resource Detail error'
        })
    );
};
