import store, { addAlert, removeAlert } from './store';
import AlertAlert from './AlertAlert.vue';

const Plugin = {
    install: app => {
        app.config.globalProperties.$Alert = {
            alert: addAlert,
            remove: removeAlert,
            alerts: store.alerts,
            nextAlertID: store.nextAlertID
        };

        app.component(AlertAlert.name, AlertAlert);
    }
};

// This exports a plugin for use with Vue Options API components,
// And also a function for use with Vue Composition API components, or in plain .js files.
export default { alert: addAlert, Plugin };
