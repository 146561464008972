import axios from 'axios';
import { apiErrorHandler } from '../services/api';
import { objectToCamelCase } from '../services/utils';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';
import { strictEqual } from 'assert';

// find school by id
export const findById = (schoolId, bustCache = false) => {
    const url = Api.getv2ApiBase(`/school/${schoolId}?bustCache=${bustCache}`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Find School By Id error'
            })
        );
};

// find school template bindings by school id
export const findTemplateBindingsBySchoolId = schoolId => {
    const url = Api.getv2ApiBase(`/school/${schoolId}/template-bindings`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

// update school information
export const updateKeyInformation = (schoolId, keyInformation) => {
    const url = Api.getv2ApiBase(`/school/${schoolId}/key-information`);

    return axios
        .put(url, keyInformation, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Save School Key Information error'
            })
        );
};

export const listSchools = ({ queryParams, paginationUrl, errorHandlerOptions = {} } = {}) => {
    const url = paginationUrl || Api.getv2ApiBase('/school');
    const defaultOptions = Api.getOptions();
    const params = {
        // BE requires both page and page_size - set some defaults here
        page: 1,
        page_size: 20
    };

    if (typeof queryParams === 'object') {
        if (typeof queryParams.page === 'number') {
            params.page = queryParams.page;
        }

        if (typeof queryParams.pageSize === 'number') {
            params.page_size = queryParams.pageSize;
        }

        if (typeof queryParams.isActive === 'boolean') {
            params.is_active = queryParams.isActive;
        }
    }

    return axios
        .get(url, { ...defaultOptions, params })
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                overrideMessage: 'There was a problem loading the schools. Please try again.',
                ...errorHandlerOptions
            })
        );
};

const RISK_SCORE_TYPES = ['academic', 'engagement', 'financial', 'wellness'];
const RISK_LEVEL_TYPES = ['lowRisk', 'mediumRisk', 'highRisk'];

const parseOverallRiskScores = overallRiskScores => {
    let parsedScores = {};
    RISK_LEVEL_TYPES.forEach(type => {
        parsedScores[type + 'Scores'] = overallRiskScores
            ? Object.values(overallRiskScores[type])
            : [];
    });
    return parsedScores;
};

const getRiskCategoryPercents = categoryRiskScores => {
    // Create object with default values
    let categoryRiskPercents = RISK_SCORE_TYPES.reduce((prev, curr) => {
        prev[curr + 'RiskPercent'] = 0;
        return prev;
    }, {});
    // If no category risk scores are passed in return the default object.
    if (!categoryRiskScores) {
        return categoryRiskPercents;
    }
    const sum = RISK_SCORE_TYPES.reduce((sum, scoreType) => sum + categoryRiskScores[scoreType], 0);
    RISK_SCORE_TYPES.forEach(scoreType => {
        if (sum > 0) {
            categoryRiskPercents[scoreType + 'RiskPercent'] =
                (categoryRiskScores[scoreType] / sum) * 100;
        }
    });
    return categoryRiskPercents;
};

const getRiskLevelPercents = riskLevelSums => {
    // Create object with default values
    const riskLevelPercents = RISK_LEVEL_TYPES.reduce((prev, curr) => {
        prev[curr + 'Percent'] = 0;
        return prev;
    }, {});
    // If no risk level sums are passed in return the default object.
    if (!riskLevelSums) {
        return riskLevelPercents;
    }
    const sum = RISK_LEVEL_TYPES.reduce((sum, levelType) => sum + riskLevelSums[levelType], 0);
    RISK_LEVEL_TYPES.forEach(levelType => {
        if (sum > 0) {
            riskLevelPercents[levelType + 'Percent'] = +((riskLevelSums[levelType] / sum) * 100);
        }
    });
    return riskLevelPercents;
};

const formatSummaryStats = ({
    categoryRiskScores = null,
    overallRiskLevelSums = null,
    overallRiskScores = null,
    studentCount = 0
}) => {
    return {
        categoryRiskScores,
        overallRiskLevelSums,
        studentCount,
        ...parseOverallRiskScores(overallRiskScores),
        ...getRiskCategoryPercents(categoryRiskScores),
        ...getRiskLevelPercents(overallRiskLevelSums)
    };
};

export const initialSummaryStats = () => {
    return formatSummaryStats({});
};

export const getSummaryStats = params => {
    const { school, ...query } = params;
    const url = Api.getv2ApiBase(`/school/${school}/summary-stats`);
    const options = Api.getOptions();

    return axios.get(url, { ...options, params: query }).then(response => {
        const data = objectToCamelCase(response.data);
        return formatSummaryStats(data);
    });
};

export const sendChatbotMessage = ({ message, schoolId }) => {
    const url = Api.getv2ApiBase('/chatbot');
    const payload = {
        schoolId,
        message
    };

    return axios
        .post(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => {
            console.error(error);
        });
};

// create a school dashboard
export const createSchool = ({ payload }) => {
    const url = Api.getv2ApiBase('/school');

    return axios
        .post(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

// deactivate a school
export const deactivateSchool = schoolId => {
    const url = Api.getv2ApiBase(`/school/${schoolId}/deactivate`);
    const payload = { schoolId };

    return axios
        .patch(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const createSftpUser = ({ payload }) => {
    const { schoolId, ...query } = payload;
    const url = Api.getv2ApiBase(`/school/${schoolId}/create-sftp`);

    return axios
        .post(url, query, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const listSchoolsWithoutStorageBucket = () => {
    const url = Api.getv2ApiBase('/school/list-schools-without-storage-bucket');

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const listSchoolsWithStorageBuckets = () => {
    const url = Api.getv2ApiBase('/school/list-schools-with-storage-buckets');

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const toggleFeatureFlagForSchool = ({ payload }) => {
    const { schoolId, ...query } = payload;
    const url = Api.getv2ApiBase(`/school/${schoolId}/toggle-feature-flag`);

    return axios
        .post(url, query, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const listFeatureFlagsBySchoolId = schoolId => {
    const url = Api.getv2ApiBase(`/school/${schoolId}/feature-flags`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const getInternalChatbotHistory = ({ schoolId, errorHandlerOptions = {} }) => {
    const url = Api.getv2ApiBase(`/chatbot/${schoolId}/admin-chatbot-history`);

    return axios
        .get(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                ...errorHandlerOptions,
                error
            })
        );
};

export const forgetAdminChatbotHistory = schoolId => {
    const url = Api.getv2ApiBase(`/chatbot/${schoolId}/admin-chatbot-history`);

    return axios
        .delete(url, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const updateSchoolWebChatbotSettings = async ({
    schoolId,
    color,
    mascotImageDataUrl,
    webChatbotMascotWidthPixels,
    webChatbotMascotHeightPixels,
    webChatbotScreenPosition,
    webChatbotLeadCaptureEnabled,
    webChatbotLeadCaptureProspectiveStudentEmails,
    webChatbotLeadCaptureAppliedAdmittedStudentEmails,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof schoolId, 'string', 'schoolId must be a string');

        const url = Api.getv2ApiBase(`/school/${schoolId}/web-chatbot-settings`);
        const data = {
            color,
            mascotImageDataUrl,
            webChatbotMascotWidthPixels,
            webChatbotMascotHeightPixels,
            webChatbotScreenPosition,
            webChatbotLeadCaptureEnabled,
            webChatbotLeadCaptureProspectiveStudentEmails,
            webChatbotLeadCaptureAppliedAdmittedStudentEmails
        };
        return await axios.patch(url, data, Api.getOptions()).then(response => {
            return response.data;
        });
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};
