import * as EmailValidator from 'email-validator';

export const validateEmail = email => {
    if (typeof email !== 'string') {
        throw 'Email is not a string';
    }

    const validEmail = EmailValidator.validate(email);

    if (!validEmail) {
        throw `Invalid email: ${email}`;
    }
};
