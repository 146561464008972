<template>
    <div class="text-input-with-search-container">
        <Dropdown :options="filteredOptions" @option-clicked="onOptionClick">
            <template v-slot:custom-menu-trigger>
                <TextInput
                    :placeholder="placeholder"
                    :modelValue="modelValue"
                    @update:modelValue="onInput"
                />
            </template>
        </Dropdown>
    </div>
</template>

<script>
import Dropdown from '@/components-deprecated/global/Dropdown';
import TextInput from '@/components-deprecated/global/TextInput';

export default {
    name: 'TextInputWithSearch',
    components: { TextInput, Dropdown },
    props: {
        placeholder: {
            type: String,
            required: false,
            default: null
        },
        options: {
            type: Array,
            required: true,
            validator: function(options) {
                return (
                    Array.isArray(options) &&
                    options.every(o => typeof o.label === 'string' && typeof o.value === 'string')
                );
            }
        },
        modelValue: {
            type: String,
            required: true
        }
    },
    methods: {
        onOptionClick(value) {
            const option = this.options.find(option => option.value === value);

            if (typeof option === 'object') {
                this.onInput(option.label);
            }
        },
        onInput(value) {
            this.$emit('update:modelValue', value);
        }
    },
    computed: {
        filteredOptions() {
            return this.options.filter(option =>
                option.label
                    .toLowerCase()
                    .trim()
                    .includes(this.modelValue.toLowerCase().trim())
            );
        },
        selectedOption() {
            if (Array.isArray(this.options) && typeof this.modelValue === 'string') {
                return this.options.find(l => l.label === this.modelValue);
            }
            return null;
        }
    },
    watch: {
        selectedOption(selectedOption) {
            this.$emit('option-selected', selectedOption);
        }
    }
};
</script>

<style lang="scss" scoped>
.text-input-with-search-container {
}
</style>
