<template>
    <div ref="analysisStudentTop" class="container">
        <div class="analysis">
            <ModalCreateMassNote @create-mass-note="createMassNote" />

            <div v-if="insight" class="analysis-header">
                <div class="insight-row">
                    <div class="insight-row__block">
                        <div class="insight-row__info-row">
                            <template>
                                <svg
                                    class="insight-row__swg"
                                    height="40"
                                    width="40"
                                    viewBox="0 0 24 15.94"
                                >
                                    <use xlink:href="#icon-group" />
                                </svg>
                            </template>
                            <div class="insight-row__info-container">
                                <div class="insight-row__info-title">{{ insight.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="analysis-content">
                <SummaryStudents
                    class="box"
                    :summary="filteredSummaryStats || summaryStatsFromCache"
                />
                <StudentsList
                    class="box list"
                    :students="studentCollection.list"
                    :total-students-count="totalStudentCount"
                    :hide-filter="hideFilter"
                    :initial-search-query="initialSearchQuery"
                    @create-mass-note="openModalCreateNote"
                    @filter="onChangeFilter"
                    @email="onEmail"
                    @export="onExport"
                    @students-next-page="getNextStudents"
                    :loading-students="
                        studentCollection.loadingNextPage || studentCollection.refreshing
                    "
                    :hasMoreStudents="studentCollection.pagination.next != null"
                    :pagination-loading="studentCollection.loadingNextPage"
                    :loadingActions="actionsLoading"
                    :showStarfishReports="true"
                />
            </div>

            <risk-driver-info-modal ref="RiskDriverInfoModal"></risk-driver-info-modal>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, useStore } from 'vuex';
import { computed } from 'vue';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import RiskDriverInfoModal from '@/components-deprecated/RiskDriverInfoModal';
import SummaryStudents from '@/components-deprecated/SummaryStudents';
import StudentsList from '@/components-deprecated/tables/StudentsList';
import ModalCreateMassNote from '@/components-deprecated/admin/ModalCreateMassNote';
import Note from '@/services/notes';
import Student from '@/services/students';
import Insight from '@/services/insights';
import CollectionManager from '@/services/collectionManager';
import { getSummaryStats } from '@/api/school';
import { useSummaryStats } from '@/queries/summaryStats';

export default {
    name: 'AnalysisStudents',
    components: {
        RiskDriverInfoModal,
        SummaryStudents,
        StudentsList,
        ModalCreateMassNote
    },
    setup() {
        const store = useStore();
        const user = computed(() => store.getters.user);
        const school = user.value.school;

        const { data, isLoading } = useSummaryStats({ school });

        return {
            summaryStatsFromCache: data,
            summaryStatsLoading: isLoading
        };
    },
    data() {
        return {
            loading: false,
            students: [],
            tags: [],
            selectedFilterGroups: [],
            filteredSummaryStats: null,
            params: null,
            tagId: null,
            filters: Student.options.FILTERS,
            // Insight isn't null if it's detail insight page
            insight: null,
            // Initial search query, which will be autopopulated to the search input
            initialSearchQuery: this.$route.params.searchQuery,
            studentCollection: CollectionManager.create({
                ModelClass: Student
            }),
            actionsLoading: false
        };
    },

    computed: {
        ...mapState(['user', 'staffSchoolViewEnabled']),
        hideFilter() {
            return this.tagId != null || this.insight != null;
        },
        totalStudentCount() {
            return this.studentCollection.pagination.totalCount;
        }
    },
    async created() {
        this.applyTag();
        this.applySearchQuery();
        this.loadStudents(this.params);
        this.loadStats(this.params);
    },
    mounted() {
        this.$emit('shouldShowSchools', true);
    },
    methods: {
        ...mapActions(['updateBackgroundOperation']),
        async getNextStudents() {
            this.studentCollection.addNextPage();
        },
        /**
         * Applies tag to the search filter from params of the current URL
         */
        applyTag() {
            this.tagId = this.$route.query.tag;
            this.params = { tagIds: this.tagId };
        },
        /**
         * Applies search query to the search filter from params of the current URL
         */
        applySearchQuery() {
            // If search term was passed in, apply search filter
            const search = this.initialSearchQuery;
            if (this.params == null) {
                this.params = { search };
            } else {
                this.params.search = search;
            }
        },
        onChangeFilter(filters) {
            this.params = filters;

            this.loadStudents(filters);
            this.loadStats(filters);
        },
        async loadStudents(apiParams = {}) {
            const params = { ...apiParams };
            params.ordering = 'last_name';

            if (this.staffSchoolViewEnabled) {
                params.school = this.user.school;
            }

            this.studentCollection.filters = params;
            this.studentCollection.refresh();
        },
        async loadStats(apiParams = {}) {
            const params = {
                school: this.user.school
            };

            Object.keys(apiParams).forEach(key => {
                if (apiParams[key]) {
                    params[key] = apiParams[key];
                }
            });

            if (Object.keys(params).length > 1) {
                const stats = await getSummaryStats(params);
                this.filteredSummaryStats = stats;
            } else {
                this.filteredSummaryStats = null;
            }
        },
        /**
         * Fetches email addresses of relevant students.
         *
         * If the number of students is small, emails will be copied to clipboard;
         * otherwise emails will be downloaded via CSV.
         *
         * @param {Array} students: If passed in, this array is the list of students used.
         *    If no array passed in, current parameters are used to find relevant students.
         *
         */
        async onEmail(students = null) {
            this.actionsLoading = true;
            try {
                // If individual students have been passed in:
                if (students) {
                    // If there aren't very many, copy emails to clipboard
                    if (students.length < Student.options.MAX_EMAILS_ON_CLIPBOARD) {
                        let emailsToCopy = students.map(student => student.email);
                        emailsToCopy = emailsToCopy.join(';');
                        copy(emailsToCopy);
                        this.$Alert.alert({
                            type: 'success',
                            message: '<h2>Emails copied to your clipboard!</h2>',
                            timeout: 3000
                        });
                        // Otherwise, download a CSV with student emails
                    } else {
                        const studentIds = students.map(student => student.id);
                        const response = await Student.api.downloadStudentData({
                            studentIds,
                            emailOnly: true
                        });
                        if (response.isBackground) {
                            this.updateBackgroundOperation(response.backgroundOp);
                        } else {
                            this.downloadFile(response.file);
                        }
                    }
                    return;
                }

                // If no students were passed in, use all students meeting the current parameters.
                // If there aren't very many, copy emails to clipboard
                if (this.totalStudentCount < Student.options.MAX_EMAILS_ON_CLIPBOARD) {
                    const emailsFromServer = await Student.api.listEmails(this.params);
                    let emailsToCopy = emailsFromServer.map(({ email }) => email);
                    emailsToCopy = emailsToCopy.join(';');
                    copy(emailsToCopy);
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Emails copied to your clipboard!</h2>',
                        timeout: 3000
                    });
                    // Otherwise, download a CSV with student emails
                } else {
                    const response = await Student.api.downloadStudentData({
                        params: this.params,
                        emailOnly: true
                    });
                    if (response.isBackground) {
                        this.updateBackgroundOperation(response.backgroundOp);
                    } else {
                        this.downloadFile(response.file);
                    }
                }
            } finally {
                this.actionsLoading = false;
            }
        },
        /* Export a CSV of relevant students. */
        async onExport({ studentIds, tagCategories } = {}) {
            this.actionsLoading = true;
            try {
                const response = await Student.api.downloadStudentData({
                    studentIds,
                    tagCategories,
                    params: this.params
                });

                // If the response we received is a background operation, add it to global state
                if (response.isBackground) {
                    this.updateBackgroundOperation(response.backgroundOp);
                } else {
                    this.downloadFile(response.file);
                }
            } finally {
                this.actionsLoading = false;
            }
        },
        /* Initiate a file download from the browser. */
        downloadFile(file) {
            // Create a link on the fly and programmatically click it to execute automatic download.
            // If a user can't download, it may be because their pop up blocker is blocking it.
            const url_dl = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement('a');
            link.href = url_dl;
            let file_name = `${moment().format()}_${this.user.schoolRef.name}`;
            link.setAttribute('download', file_name + '.csv');
            link.click();
        },
        async openModalCreateNote({ isAllSelected, students }) {
            let studentList = students;
            if (isAllSelected) {
                studentList = await Student.api.listEmails(this.params);

                // getting all students from server
            }
            this.$modal.show('modal-create-mass-note', {
                students: studentList
            });
        },
        async createMassNote(note) {
            try {
                await Note.api.createMassNote(note);
                this.$modal.hide('modal-create-mass-note');
                this.$Alert.alert({
                    type: 'success',
                    message: '<h2>Note Successfully Created!</h2>',
                    timeout: 3000
                });
            } catch (err) {
                this.$alert.alert({
                    type: 'error',
                    message: '<h2>Error creating Note</h2>',
                    timeout: 3000
                });
                return;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/forms';
@import '../../styles/mixins/buttons';
@import '../../styles/mixins/lists';
@import '../../styles/mixins/text';

.loading {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}

.container {
    padding: 20px 50px;
    height: 100%;
    max-width: unset;
    overflow-y: scroll;
}

.analysis {
    margin: 0 auto;
    height: inherit;

    .analysis__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &-content {
        height: 100%;
    }

    .info-box {
        padding: 1rem calc(1rem + 75px) 1rem calc(1rem + 45px);
        margin-bottom: 1.5rem;
        line-height: 1.5;
        background-color: $white-blue;
        color: black;
        margin-top: 10px;
    }

    &__risk {
        &-level {
            &--row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
            }
        }
        &-icon {
            width: 2rem;
            height: 2rem;
            color: $gainsboro;
        }
        &-icon--large {
            width: 4rem;
            height: 4rem;
        }
        &-indicator {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: transparent;
            margin-right: 1rem;
            &--low {
                background-color: $dodger-blue;
            }
            &--high {
                background-color: $red;
            }
            &--medium {
                background-color: $yellow;
            }
        }
    }
}

.action-button {
    @include action-button();
    margin-left: 0.5rem;
}

.list {
    margin-top: 1rem;
    overflow: visible;
    padding-bottom: 10px;
}

.text-centered {
    @include text-centered();
}

.icon-HIGH_RISK {
    color: $red;
}

.icon-MEDIUM_RISK {
    color: $yellow;
}

.icon-hamburger {
    width: 28px;
    height: 28px;
    fill: $primary-brand-color;
}

.return-link {
    height: 2rem;
    width: 15rem;
    padding: 8px 12px 8px 7px;
    color: black;
    font-size: 16px;
    align-self: center;
    cursor: pointer;
}

.return-icon {
    transform: rotate(180deg);
    margin-right: 5px;
}

.insight-row {
    display: flex;
    align-items: center;
    cursor: default;

    &__block {
        margin-top: 50px;
        margin-bottom: 50px;
        border: 1px solid #7ec1fe;
        margin-right: 10px;
        max-width: 950px;
        width: 100%;
        padding: 22px 28px 15px 35px;
        background-color: #f7f9fb;
        border-radius: 20px/10px;

        &--selected {
            background-color: #54aeff;
        }
    }

    &__info-row {
        display: flex;
    }

    &__swg {
        margin-right: 30px;
        fill: #068bff;

        &--selected {
            fill: white;
        }
    }

    &__info-title {
        color: #2698fe;
        font-size: 15px;

        &--selected {
            color: white;
        }
    }

    &__info-text {
        margin-top: 10px;
        font-size: 15px;
        max-width: 545px;

        &--selected {
            color: white;
        }
    }

    &__info-highlight {
        font-weight: bold;
    }

    &__date {
        font-size: 11px;
        color: #9b9d9e;
    }
}

.v--modal-overlay.scrollable .v--modal-box {
    margin-bottom: 0;
}

.box-students {
    margin-top: 35px;

    &__btn-email {
        margin-right: 15px;
    }
}

.students-wrapper {
    display: flex;
}
@media only screen and (max-width: 768px) {
    .container {
        padding: 20px 20px;
        overflow: auto;
    }
    .list {
        overflow: auto;
        height: auto;
    }
}
</style>

<style>
.v-select.single.unsearchable input[type='search'] {
    max-width: none;
    opacity: 1;
}
.v-select.unsearchable > .dropdown-toggle > .selected-tag + input[type='search'] {
    max-width: 1px;
}
.v-select .selected-tag {
    position: absolute;
    pointer-events: none;
}

.analysis .simplebar-scrollbar:before {
    background-color: #0a8bff;
    border-radius: 0px;
}

.v--modal-overlay.scrollable .v--modal-box {
    margin-bottom: 0;
}
</style>
