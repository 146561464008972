import axios from 'axios';

import { API_BASE, apiErrorHandler } from '@/services/api';
import { getv2ApiBase } from '../../api/index';
import Auth from '../auth';
import { objectToSnakeCase } from '../utils';

const SCHOOLS_ENDPOINT = `${API_BASE}schools/`;

// Determine if the outgoing request is a simple pagination request. This means
// the request is asking the server to page and provides a page number and page
// size value
const isSimplePaginationRequest = params => {
    if (typeof params !== 'object') {
        return false;
    }

    const keys = Object.keys(params);

    if (keys.length !== 2) {
        return false;
    }

    if (keys.indexOf('page') === -1 || keys.indexOf('page_size') === -1) {
        return false;
    }

    return true;
};

export default class SchoolAPI {
    constructor(cls) {
        this.cls = cls;
    }

    static create(cls) {
        return new SchoolAPI(cls);
    }

    async list(params) {
        // Note: we're using different url's as we migrate from Django to Node.
        const url = isSimplePaginationRequest(params) ? getv2ApiBase('/school') : SCHOOLS_ENDPOINT;

        const options = {
            headers: await Auth.getHeaders().headers,
            params: this.cls.toAPI(params)
        };

        const promise = axios
            .get(url, options)
            .then(res => {
                const returnObj = {
                    ...res.data,
                    results: res.data.results
                };

                // Note: Node returns everything in camel case, but the existing ui code
                // works with snake case; convert it
                if (isSimplePaginationRequest(params)) {
                    returnObj.results = res.data.results.map(result => objectToSnakeCase(result));
                }

                return returnObj;
            })
            .catch(apiErrorHandler({ apiName: 'Staff Api Get Schools Error' }));

        return promise;
    }
}
