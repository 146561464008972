import Auth from '../services/auth';
import Axios from 'axios';

let integrationIsolationFeatureFlag;
let nodeApi;

const getIntegrationIsolationFlag = () => {
    // here we definitely want to call the node api
    const url = `${process.env.VUE_APP_API_V2}/api/v2/is-feature-flag-enabled/INTEGRATION_ISOLATION?bustCache=true`;
    return Axios.get(url, getOptions());
};

const getFeatureFlagNodeHost = async () => {
    if (integrationIsolationFeatureFlag === undefined) {
        const response = await getIntegrationIsolationFlag();
        integrationIsolationFeatureFlag = response.data || false;

        if (integrationIsolationFeatureFlag) {
            // have a fallback just in case
            nodeApi = process.env.VUE_APP_INTEGRATION_API_HOST || process.env.VUE_APP_API_V2;
        } else {
            nodeApi = process.env.VUE_APP_API_V2;
        }
    }

    return nodeApi;
};

// returns the v2 api base path
// we should eventually set the api path as an axios.default
export const getv2ApiBase = url => {
    return `${process.env.VUE_APP_API_V2}/api/v2${url}`;
};

// gets the integration base
export const getIntegrationBase = async url => {
    const host = await getFeatureFlagNodeHost();
    return `${host}/api/v2${url}`;
};

// placeholder for options that are applied to all routes
// we should eventually set these as an axios.default
export const getOptions = () => {
    return {
        headers: {
            'Content-Type': 'application/json',
            ...Auth.getHeaders().headers
        }
    };
};

// converts an object into an encoded query string
export const serializeObjectToQueryString = data => {
    if (typeof data !== 'object') {
        return '';
    }

    return Object.keys(data)
        .map(keyName => `${encodeURIComponent(keyName)}=${encodeURIComponent(data[keyName])}`)
        .join('&');
};
