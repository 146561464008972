import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { validateEmail } from '@/lib/validate';
import * as Api from './index';

export const registerSubAccount = ({ payload }) => {
    const url = Api.getv2ApiBase('/phone-number-registry/register-sub-account');

    return axios
        .post(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const submitPhoneVerification = ({ payload }) => {
    const url = Api.getv2ApiBase('/phone-number-registry/submit-phone-verification');

    return axios
        .post(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const migrateSchoolToPhoneRegistry = schoolId => {
    const url = Api.getv2ApiBase('/phone-number-registry/migrate-school-to-phone-registry');
    const payload = { schoolId };

    return axios
        .post(url, payload, Api.getOptions())
        .then(response => {
            return response.data;
        })
        .catch(error => v2ApiErrorHandler({ error }));
};

export const validateTwilioVerification = payload => {
    const phoneNumberRegex = /^1\d{10}$/;
    const additionalInfoRegex = /^.{0,500}$/;
    try {
        if (!payload.businessName) {
            throw 'School Name is required';
        }
        if (!payload.businessStreetAddress) {
            throw 'Street address is required';
        }
        if (!payload.businessCity) {
            throw 'City is required';
        }
        if (!payload.businessStateProvinceRegion) {
            throw 'State is required';
        }
        if (!payload.businessPostalCode) {
            throw 'Postal Code is required';
        }
        if (!payload.businessContactFirstName) {
            throw 'Contact First Name is required';
        }
        if (!payload.businessContactLastName) {
            throw 'Contact Last Name is required';
        }
        if (!payload.businessContactEmail) {
            throw 'Contact Email is required';
        }
        validateEmail(payload.businessContactEmail);
        if (!payload.businessContactPhone) {
            throw 'Contact Phone Number is required';
        }
        if (phoneNumberRegex.test(payload.businessContactPhone) === false) {
            throw 'Phone number must be in the format 1234567890.';
        }
        if (!payload.businessWebsite) {
            throw 'Website is required';
        }
        if (payload.optInImageUrls[0] === '') {
            throw 'Opt In Image Url is required';
        }
        if (!payload.messageVolume) {
            throw 'Message Volume is required';
        }
        if (!payload.additionalInformation) {
            throw 'Additional Info is required';
        }
        if (additionalInfoRegex.test(payload.additionalInformation) === false) {
            throw 'Additional Information must be 500 characters or less.';
        }
        return { status: true };
    } catch (error) {
        return { status: false, message: error };
    }
};
