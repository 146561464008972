import * as Api from '@/api/index';
import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { strictEqual } from 'assert';

export const listAdminAssignedTagsByAdminId = async ({ adminId, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof adminId, 'string');
        strictEqual(typeof errorHandlerOptions, 'object');

        const url = Api.getv2ApiBase(`/admin-assigned-tag/${adminId}`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage:
                "There was a problem loading the admin's assigned tags. Please try again.",
            ...errorHandlerOptions
        });
    }
};

export const listTagsAvailableForAdminAssignment = async ({
    adminId,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof adminId, 'string');
        strictEqual(typeof errorHandlerOptions, 'object');

        const url = Api.getv2ApiBase(
            `/admin-assigned-tag/${adminId}/tags-available-for-assignment`
        );
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage:
                'There was a problem loading the tags available for the admin. Please try again.',
            ...errorHandlerOptions
        });
    }
};

export const deleteAdminAssignedTagById = async ({ id, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof id, 'string');
        strictEqual(typeof errorHandlerOptions, 'object');

        const url = Api.getv2ApiBase(`/admin-assigned-tag/${id}`);
        const options = Api.getOptions();

        const response = await axios.delete(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage:
                "There was a problem removing the admin's assigned tag. Please try again.",
            ...errorHandlerOptions
        });
    }
};

export const createAdminAssignedTag = async ({ adminId, tagId, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof adminId, 'string');
        strictEqual(typeof tagId, 'number');
        strictEqual(typeof errorHandlerOptions, 'object');

        const url = Api.getv2ApiBase(`/admin-assigned-tag`);
        const options = Api.getOptions();

        const payload = {
            adminId,
            tagId
        };

        const response = await axios.post(url, payload, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage:
                'There was a problem assigning the tag to the admin. Please try again.',
            ...errorHandlerOptions
        });
    }
};
