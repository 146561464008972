<template>
    <div class="staff-banner-container">
        <BackToStaffPortalButton />
        <div class="schoolName">{{ schoolName }}</div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import BackToStaffPortalButton from '@/components-deprecated/staff/BackToStaffPortalButton';

export default {
    name: 'StaffSchoolBanner',
    components: { BackToStaffPortalButton },
    computed: {
        ...mapState(['user']),
        schoolName() {
            const { schoolRef } = this.user;
            if (schoolRef) {
                return schoolRef.name;
            }
            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.staff-banner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $staff-school-banner-height;
    background-color: $primary-brand-color;
    color: white;

    .schoolName {
        font-size: 16px;
        font-weight: bold;
    }
}
</style>
