<template>
    <div class="chart" :aria-label="ariaLabel" role="img">
        <div class="flat-bar-background">
            <span class="left-axis">
                <sup>{{ labels.xMin }}</sup>
            </span>
            <div v-for="(score, label) in displayableScores(scores)" v-bind:key="score.displayName">
                <p :style="[DisplayNameStyles(label, score, scores, labels)]">
                    {{ score.displayName }}
                </p>
                <p
                    v-if="type === 'RISK_BUCKET_BY_TAG' || type === 'RISK_CATEGORY_BY_TAG'"
                    :style="[ValueStyles(label, score, scores, labels)]"
                >
                    {{ Math.round(score.value * 100) }}
                </p>
                <p v-else :style="[ValueStyles(label, score, scores, labels)]">{{ score.value }}</p>
                <div v-bind:style="[VerticalLineStyles(label, score, scores, labels)]"></div>
            </div>
            <span class="right-axis">
                <sup>{{ labels.xMax }}</sup>
            </span>
        </div>
    </div>
</template>

<script>
// Hard-coding these colors since they were previously imported from the SCSS variables, but that
// isn't working after the move to Vue 3.
// To do: Rather than figure out the import issue (robably related to babel or the way scss is loaded),
// since this is a one-off pattern, make everything in this component class based, and use the SCSS variables in those
// classes, like done throughout the app.

// Each color is listed with its equivalent from our SCSS variables.
// $primary-brand-color
const edsights_blue = '#0B6EC4';
// $color-high-risk
const edsights_purple = '#6c08fd';
// $accessible-gray
const edsights_grey = '#757575';

export default {
    name: 'HorizontalChart',
    props: {
        scores: {
            type: Object,
            default: null
        },
        labels: {
            type: Object,
            default: null
        },
        type: {
            type: String,
            default: null
        },
        riskDriver: {
            type: String,
            required: true
        }
    },
    methods: {
        /**
         * Determines styling of graph point(vertical line) for each set of scores passed from the back end
         * @param {label} - score identifier(labels)
         * @param {score} - individual score Object containing displayName and score value
         * @param {scores} - Object containing nested-Object list of individual scores
         * @param {labels} - Object containing min and max x-axis values
         *
         *
         */
        VerticalLineStyles(label, score, scores, labels) {
            let modifiedScore = score.value;
            const baseStyles = {
                width: Math.round(modifiedScore * 100) + '%',
                position: 'absolute',
                bottom: '0px',
                zIndex: 2
            };
            const position = this.getScorePosition(score, scores);

            if (label === 'oppositeMeanRiskScore') {
                if (modifiedScore < scores['taggedMeanRiskScore']['value']) {
                    baseStyles['borderRight'] = `solid 1px ${edsights_blue}`;
                } else {
                    baseStyles['borderRight'] = `solid 1px ${edsights_purple}`;
                }
            } else if (label === 'taggedMeanRiskScore') {
                if (modifiedScore < scores['oppositeMeanRiskScore']['value']) {
                    baseStyles['borderRight'] = `solid 1px ${edsights_blue}`;
                } else {
                    baseStyles['borderRight'] = `solid 1px ${edsights_purple}`;
                }
            } else {
                baseStyles['borderRight'] = `solid 1px ${edsights_grey}`;
            }

            if (position === 'farLeft') {
                baseStyles['height'] = '120px';
            } else if (position === 'farRight') {
                baseStyles['height'] = '80px';
            } else {
                baseStyles['height'] = '100px';
            }

            modifiedScore = modifiedScore * 100;
            baseStyles['width'] =
                this.dynamicPadding(labels.xMin, labels.xMax, Math.round(Number(modifiedScore))) +
                '%';
            return baseStyles;
        },

        DisplayNameStyles(label, score, scores, labels) {
            let modifiedScore = score.value;
            const baseStyles = {
                position: 'absolute',
                fontSize: '9px',
                lineHeight: 1.2
            };
            const position = this.getScorePosition(score, scores);
            if (position === 'farLeft') {
                baseStyles['bottom'] = '120px';
            } else if (position === 'farRight') {
                baseStyles['bottom'] = '80px';
            } else {
                baseStyles['bottom'] = '100px';
            }

            if (label === 'oppositeMeanRiskScore') {
                if (modifiedScore < scores['taggedMeanRiskScore']['value']) {
                    baseStyles['color'] = `${edsights_blue}`;
                } else {
                    baseStyles['color'] = `${edsights_purple}`;
                }
            } else if (label === 'taggedMeanRiskScore') {
                if (modifiedScore < scores['oppositeMeanRiskScore']['value']) {
                    baseStyles['color'] = `${edsights_blue}`;
                } else {
                    baseStyles['color'] = `${edsights_purple}`;
                }
            } else {
                baseStyles['color'] = `${edsights_grey}`;
            }

            modifiedScore = modifiedScore * 100;
            baseStyles['left'] =
                this.dynamicPadding(labels.xMin, labels.xMax, Math.round(Number(modifiedScore))) +
                '%';
            return baseStyles;
        },

        // todo: MP 12/10/19 dynamic padding needs to be updated min values greater than 0
        /**
         * Determines padding for score vertical line, label and score  identifiers
         * @param {min} - graph overall min score
         * @param {max} - graph overall max score
         * @param {score} - student score passed from backend
         */
        dynamicPadding(min, max, score) {
            if (min < 0) {
                const diff = min * -1;
                max = max + diff;
                score = score + diff;
                min = diff + min;
            } else if (min > 0) {
                const diff = min;
                max = max - diff;
                score = score - diff;
                min = diff - min;
            }
            return (score / max) * 100;
        },

        ValueStyles(label, score, scores, labels) {
            const baseStyles = {
                position: 'absolute',
                top: '55px',
                fontSize: '9px',
                lineHeight: 1.2,
                margin: '3px',
                fontWeight: 'bold'
            };
            const position = this.getScorePosition(score, scores);
            if (position === 'farLeft') {
                baseStyles['left'] = Math.round(score.value * 100 - 5) + '%';
            } else if (position === 'farRight') {
                baseStyles['left'] = Math.round(score.value * 100 + 0) + '%';
            } else {
                baseStyles['left'] = Math.round(score.value * 100 - 2) + '%';
            }

            if (label === 'oppositeMeanRiskScore') {
                if (score.value < scores['taggedMeanRiskScore']['value']) {
                    baseStyles['color'] = `${edsights_blue}`;
                } else {
                    baseStyles['color'] = `${edsights_purple}`;
                }
            } else if (label === 'taggedMeanRiskScore') {
                if (score.value < scores['oppositeMeanRiskScore']['value']) {
                    baseStyles['color'] = `${edsights_blue}`;
                } else {
                    baseStyles['color'] = `${edsights_purple}`;
                }
            } else {
                baseStyles['color'] = `${edsights_grey}`;
            }

            return baseStyles;
        },

        /**
         * Determines position of score values on horizontal graph
         *
         * @param {inidividualScore} -
         * @param {scoreList} -
         *
         */
        getScorePosition(individualScore, scoreList) {
            const scores = [];
            const keys = Object.keys(scoreList);
            keys.forEach(key => {
                scores.push(scoreList[key].value);
            });
            scores.sort();
            if (scores[0] === individualScore.value) {
                return 'farLeft';
            } else if (scores[scores.length - 1] === individualScore.value) {
                return 'farRight';
            } else {
                return 'middle';
            }
        },

        displayableScores(scores) {
            for (let [key, value] of Object.entries(scores)) {
                if (value.displayName === '') {
                    delete scores[key];
                }
            }
            return scores;
        }
    },
    computed: {
        ariaLabel() {
            const title = `Chart showing the distribution of students at risk for the ${this.riskDriver} risk driver by tag:`;
            if (this.scores) {
                const scores = Object.values(this.displayableScores(this.scores)).sort(
                    (a, b) => a.value - b.value
                );

                const length = scores.length;
                const dataString = scores.reduce((acc, score, index) => {
                    return `${acc}${score.displayName}: ${Math.round(score.value * 100)}%${
                        index === length - 1 ? '.' : ', '
                    }`;
                }, '');

                return `${title} ${dataString}`;
            }

            return `${title} No student data yet.`;
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.chart {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
    align-items: flex-end;
    margin-bottom: 40px;
}
.flat-bar-background {
    position: relative;
    z-index: 1;
    height: 50px;
    border-radius: 3px;
    width: 434.7px;
    border: solid 1px #949090;
    background-color: #fcfcfc;
}
.left-axis {
    color: #333;
    position: absolute;
    top: 45px;
}
.right-axis {
    color: #333;
    position: absolute;
    top: 45px;
    left: 100%;
}
</style>
