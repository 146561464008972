<template>
    <div class="school-dropdown-container">
        <DropDownSelect
            :items="schoolsList.list"
            nullDisplay="Search or filter by school..."
            displayKey="name"
            valueKey="id"
            v-model="selectedSchoolId"
            v-model:itemsRef="selectedSchoolRef"
            :hasNext="schoolsList.pagination.hasNextPage"
            @load-more="schoolsList.addNextPage()"
            @search-term="onSearchSchools"
            searchable
            :showIcon="false"
        />
    </div>
</template>

<script>
import CollectionManager from '@/services/collectionManager';
import School from '@/services/schools';
import DropDownSelect from '@/components-deprecated/inputs/DropDownSelect';

export default {
    name: 'SchoolDropDown',
    components: {
        DropDownSelect
    },
    props: {
        // pass initialSchoolId to have the dropdown pre-select a school
        initialSchoolId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            selectedSchoolId: null,
            selectedSchoolRef: null,
            schoolsList: CollectionManager.create({
                ModelClass: School
            })
        };
    },
    created: async function() {
        await this.schoolsList.refresh();
        if (this.initialSchoolId) {
            this.selectedSchoolId = this.initialSchoolId;
            this.selectedSchoolRef = this.schoolsList.list.find(
                s => s.id === this.selectedSchoolId
            );
        }
    },
    methods: {
        async onSearchSchools(val) {
            this.schoolsList.filters.search = val;
            await this.schoolsList.refresh();
        }
    },
    watch: {
        selectedSchoolId(value) {
            this.$emit('selected-school-id-updated', value, this.selectedSchoolRef);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.school-dropdown-container {
    width: 30rem;
    height: 40px;
    display: flex;
    align-items: center;

    :deep(.dropdown) {
        .dd-item {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
        }
        .dropdown-input-container {
            width: 100%;
            align-items: center;
            border: 1px solid $secondary-gray;
            border-radius: 5px;

            > .search,
            .selected-items {
                border: none;
                background: transparent;
                height: 32px;
                min-width: unset;
                font-size: 14px;
            }
            &.disabled {
                border: 1px solid gray;
            }
        }
    }
}
</style>
