<template>
    <div class="status-container">
        <div class="status-badge">
            <span
                class="status-circle"
                :class="{
                    pending: status.toLowerCase() === 'pending',
                    approved: status.toLowerCase() === 'approved'
                }"
            ></span>
            <span>{{ status }}</span>
        </div>
    </div>
</template>

<script>
import { capitalize } from 'lodash';

export default {
    components: {},
    props: {
        statusText: {
            type: String,
            default: ''
        }
    },
    computed: {
        status: function() {
            return capitalize(this.statusText);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixins/spinners';
@import '../styles/variables';

.status-container {
    display: flex;
    justify-content: flex-end;
    margin-left: 3rem;
}

.status-badge {
    font-size: 0.9rem;
    border: solid 1px #f0ecec;
    border-radius: 0.5rem;
    padding: 0rem 0.5rem;
    height: 27px;
    min-width: 95px;
}

.status-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.pending {
    background-color: #bdbdbd;
}

.approved {
    background-color: $green;
}
</style>
