'use strict';

import { Chart } from 'chart.js';
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels';

// Register any global plugins here and add a note on how to enable them in charts:
export const registerGlobalChartJsPlugins = () => {
    // Data Labels plugin
    // Enable: chartOptions.plugins.datalabels.display (true / false)
    Chart.register(ChartJsPluginDataLabels);
};
