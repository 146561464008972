<template>
    <div class="paginator-container">
        <LoadingSpinner v-if="loading" />
        <!-- PB Current implementation is only show more -->
        <Button
            v-if="!loading"
            class="btn-load"
            @click="$emit('next-page')"
            :unbordered="true"
            type="button"
            aria-label="Load more"
        >
            <span>
                <svg
                    width="30px"
                    height="15px"
                    class="btn-load__icon"
                    :class="{ horizontal: orientation == 'horizontal' }"
                    viewBox="0 0 290 290"
                >
                    <use xlink:href="#caret-down" />
                </svg>
            </span>
        </Button>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
export default {
    name: 'Paginator',
    components: {
        Button,
        LoadingSpinner
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        orientation: {
            type: String,
            default: 'vertical'
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.paginator-container {
    width: 100%;
}
.btn-load {
    width: 100%;
    background-color: transparent;
    &__icon {
        fill: gray;
        &:hover {
            fill: $primary-brand-color;
        }
    }
    &__icon.horizontal {
        transform: rotate(-90deg);
    }
}
</style>
