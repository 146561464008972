<template>
    <div v-if="touchpoint" class="touchpoint-card">
        <div class="card-column" v-if="!isExperimentalAccessibilityEnabled">
            <img
                v-if="touchpoint.isCustom"
                class="icon"
                src="@/assets/icons/feather.png"
                alt="Feather representing a custom touchpoint"
            />
            <img
                v-else
                class="icon"
                src="@/assets/icons/edsights-e.png"
                alt="EdSights logo representing a non-custom touchpoint"
            />
        </div>
        <div class="card-column wide">
            <div class="card-row header-row">
                <p class="card-header">{{ formattedChatbotTemplateName }}</p>
                <p class="blue-count badge">{{ touchpoint.studentCount }} students reached</p>
            </div>
            <div class="card-row type-description-row">
                {{ getTouchpointTypeString(touchpoint.isCustom) }}
            </div>
            <div class="card-row touchpoint-description-row">
                {{ formattedTouchpointDescription }}
            </div>
            <div class="card-row link-row">
                <div class="card-col">
                    <button
                        class="card-row clickable"
                        :class="getRowClass(groupedIntervention.type)"
                        :key="groupedIntervention.type"
                        v-for="groupedIntervention in groupedInterventions"
                        :aria-label="
                            getInterventionDescription(groupedIntervention.type) +
                                ` - ` +
                                formattedChatbotTemplateName
                        "
                        @click="navigateToIntervention"
                    >
                        <p :class="getCountClass(groupedIntervention.type)">
                            {{ groupedIntervention.interventions.length }}
                        </p>
                        {{ getInterventionDescription(groupedIntervention.type) }}
                    </button>
                </div>
                <div v-if="touchpoint.chatbotTemplate.createReport">
                    <ReportRequestDownloadButton
                        reportType="CHATBOT_CONVERSATIONS"
                        :ariaLabel="'Download Report: ' + formattedChatbotTemplateName"
                        :reportOptions="{
                            chatbotFlowId: this.touchpoint.chatbotFlow.id,
                            interventionTemplates: this.touchpoint.interventionTemplates,
                            cleanOutput: true
                        }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportRequestDownloadButton from '@/components-deprecated/ReportRequestDownloadButton';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
import { formatAccessibleString } from '@/lib/string-helpers';

export default {
    name: 'TouchpointCard',
    components: {
        ReportRequestDownloadButton
    },
    props: {
        touchpoint: {
            type: Object,
            default: null
        }
    },
    computed: {
        // Groups associated interventions by type to display at the bottom of the card
        groupedInterventions() {
            let groupedInterventions = {};

            this.touchpoint.interventionTemplates.forEach(it => {
                // only continue if this intervention template has at least one student
                if (it.studentCount === 0) {
                    return;
                }

                let type = it.type;

                if (!(type in groupedInterventions)) {
                    groupedInterventions[type] = [];
                }

                groupedInterventions[type].push(it);
            });

            let items = Object.keys(groupedInterventions).map(key => {
                return {
                    type: key,
                    interventions: groupedInterventions[key]
                };
            });

            return items.sort((a, b) => {
                return a.type.localeCompare(b.type);
            });
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        formattedChatbotTemplateName() {
            if (
                !this.touchpoint ||
                !this.touchpoint.chatbotTemplate ||
                !this.touchpoint.chatbotTemplate.name
            ) {
                return '';
            }

            return formatAccessibleString(
                this.touchpoint.chatbotTemplate.name,
                this.isExperimentalAccessibilityEnabled
            );
        },
        formattedTouchpointDescription() {
            if (!this.touchpoint || !this.touchpoint.renderedDescription) {
                return '';
            }

            return formatAccessibleString(
                this.touchpoint.renderedDescription,
                this.isExperimentalAccessibilityEnabled
            );
        }
    },
    methods: {
        getRowClass(type) {
            if (type === 'CHATBOT') {
                return 'chatbot-row';
            } else {
                return 'admin-row';
            }
        },
        getCountClass(type) {
            if (type === 'CHATBOT') {
                return 'count';
            } else {
                return 'blue-count';
            }
        },
        getTouchpointTypeString(isCustom) {
            if (isCustom) {
                return 'Custom Touchpoint';
            } else {
                return 'EdSights Touchpoint';
            }
        },
        getInterventionDescription(type) {
            if (type === 'CHATBOT') {
                return 'Action Taken by EdSights';
            } else {
                const description = 'Opportunity for Follow Up ✨';
                return formatAccessibleString(description, this.isExperimentalAccessibilityEnabled);
            }
        },
        navigateToIntervention() {
            this.$emit('navigate-to-intervention', this.touchpoint.chatbotTemplate.id);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.touchpoint-card {
    padding: 2rem 2rem 2rem 1.5rem;
    border: 1px solid $white-smoke;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.card-header {
    font-weight: bold;
}

.card-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
}

.wide {
    flex: 15;
}

.card-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1rem;
}

.header-row {
    margin-top: 0rem;
}

.icon {
    height: 40px;
    width: 40px;
}

.count {
    color: $dark-gray;
    background-color: rgba($dark-gray, 0.1);
    border-radius: 0.5rem;
    padding: 0rem 0.5rem;
    margin-right: 0.5rem;
    min-width: 20px;
}

.blue-count {
    color: $primary-dark;
    background-color: rgba($primary-dark, 0.1);
    border-radius: 0.5rem;
    padding: 0rem 0.5rem;
    margin-right: 0.5rem;
    min-width: 20px;
}

.student-count {
    font-size: 0.9rem;
}

.badge {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
    font-weight: bold;
}

.type-description-row {
    font-weight: bold;
    font-size: 0.9rem;
    margin-top: 0rem;
}

.touchpoint-description-row {
    width: 90%;
}

.chatbot-row {
    color: $dark-gray;
    font-weight: bold;
    margin-top: 0.5rem;
    border: 0;
    background: transparent;
    letter-spacing: 1px;
    font-size: 12px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.admin-row {
    color: $primary-brand-color;
    font-weight: bold;
    margin-top: 0.5rem;
    border: 0;
    background: transparent;
    letter-spacing: 1px;
    font-size: 12px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.link-row {
    margin-top: 1.25rem;
    justify-content: space-between;
}

.clickable {
    cursor: pointer;
}

.download-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    margin-left: 3rem;
    color: $primary-brand-color;
}

.icon-download {
    stroke: $primary-brand-color;
    margin-left: 1rem;
}
</style>
