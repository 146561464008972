<template>
    <modal
        class="reset-password-modal"
        name="modal-remove-faq-v2"
        height="auto"
        :clickToClose="false"
        :stack="false"
        :scrollable="false"
        @before-open="beforeOpen"
        tabIndex="-1"
    >
        <div class="container">
            <h2>Remove question</h2>

            <div>"{{ faq.questionText }}"</div>
            <p>
                Are you sure you want to delete this question? This action cannot be undone.
            </p>

            <div class="footer">
                <Button type="button" class="cancel-btn" @click="onClose">
                    Cancel
                </Button>
                <loading-spinner-button
                    class="save-btn"
                    text="Remove"
                    :loading="loading"
                    @click="archiveHandler()"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton.vue';
import * as FAQv2Api from '@/api/faqs-v2';

export default {
    name: 'ModalRemoveFaqV2',
    components: { LoadingSpinnerButton },
    data() {
        return {
            loading: false,
            faq: {}
        };
    },
    methods: {
        beforeOpen(event) {
            if (event.params.faq) {
                this.faq = event.params.faq;
            }
        },
        onClose() {
            this.$modal.hide('modal-remove-faq-v2');
        },
        async archiveHandler() {
            this.loading = true;
            await FAQv2Api.archiveFaq(this.faq.id);
            this.loading = false;
            this.$emit('refresh-faqs');
            this.$modal.hide('modal-remove-faq-v2');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/forms';
@import '../../styles/mixins/alerts';
@import '@/styles/variables';
@import '@/styles/mixins/buttons';

.container {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .input {
        margin-bottom: 1rem;
        width: 30rem;
    }
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 1rem 0;
}

.save-btn {
    @include base-button();
}

.cancel-btn {
    @include secondary-button();
    margin-right: 1rem;
}
</style>
