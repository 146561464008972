<template>
    <FlowDateEditor
        :date="date"
        :flowId="id"
        :flowStatus="status"
        @flow-date-updated="$emit('flow-date-updated', $event)"
    />
</template>

<script setup>
import FlowDateEditor from './FlowDateEditor.vue';

const props = defineProps({
    date: {
        type: String,
        required: true
    },
    id: {
        type: String,
        required: true
    },
    status: {
        type: String,
        required: true
    }
});

defineEmits(['flow-date-updated']);
</script>

<style lang="scss" scoped>
.date-content {
    white-space: nowrap;
}
</style>
