<template>
    <div class="scroll-wrapper">
        <div class="scroll-wrapper__elem">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.scroll-wrapper {
    display: flex;
    justify-content: center;

    &__elem {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 100%;
        width: calc(100% + 45px);
    }
}
</style>
