import axios from 'axios';

import Auth from '../auth';
import { API_BASE, apiErrorHandler } from '../api';
import { objectToSnakeCase } from '../utils';

// Endpoint
const RISK_BUCKET_SCORES_ENDPOINT = `${API_BASE}risk-bucket-scores/`;

export default class RiskBucketScoreAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new RiskBucketScoreAPI(cls);
    }

    async list(params = {}) {
        const url = RISK_BUCKET_SCORES_ENDPOINT;
        const options = {
            headers: Auth.getHeaders().headers,
            params: objectToSnakeCase(params)
        };

        const promise = axios
            .get(url, options)
            .then(response => {
                return {
                    ...response.data,
                    results: response.data.results.map(datum => this.cls.fromAPI(datum))
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Risk Bucket Scores API error' }));
        return promise;
    }
}
