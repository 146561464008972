<template>
    <section class="box-container">
        <Header
            class="box-container__header"
            :class="{
                'box-container__header_no-splitter': hideSplitter,
                'box-container__header_fixed': fixedHeader
            }"
            :ariaLabel="ariaLabel"
            :showMoreTo="showMoreTo"
        >
            <template v-slot:title>
                <h1 v-if="title" class="box-container__title">{{ title }}</h1>
            </template>

            <template v-slot:subtitle>
                <h2 v-if="subtitle">{{ subtitle }}</h2>
            </template>

            <template v-slot:subtitle-right>
                <slot name="header-subtitle-right"></slot>
            </template>
        </Header>
        <slot>
            <div class="box-container__empty-msg empty-msg">
                <h2 class="empty-msg__title text-gray">{{ emptyTitle }}</h2>
                <p class="empty-msg__description text-gray">{{ emptyDescription }}</p>
            </div>
        </slot>
    </section>
</template>

<script>
import Header from '@/components-deprecated/overview/Header';

export default {
    name: 'BoxContainer',

    components: {
        Header
    },

    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        showMoreTo: {
            type: Object,
            default: null
        },
        hideSplitter: {
            type: Boolean,
            default: false
        },
        emptyTitle: {
            type: String,
            default: 'Nothing to see here ... yet!'
        },
        emptyDescription: {
            type: String,
            default: ''
        },
        fixedHeader: {
            type: Boolean,
            default: false
        },
        ariaLabel: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.box-container {
    position: relative;

    &__header {
        border-bottom: 1px solid $border-color;
        padding: 25px;

        &_no-splitter {
            border-bottom: 0;
            padding-bottom: 0;
            padding-top: 0;
        }

        &_fixed {
            position: sticky;
            top: 0;
            background-color: $white;
        }
    }

    &__content {
        padding: 25px;
    }

    &__title {
        text-transform: uppercase;
        font-size: inherit;
        color: inherit;
        letter-spacing: inherit;
    }
}

.empty-msg {
    height: 60%;
    padding: 25px;
    padding-top: 40%;

    &__title {
        margin-bottom: 25px;
        text-align: center;
    }

    &__description {
        text-align: center;
    }
}
</style>
