<template>
    <div class="forgot-password">
        <app-header></app-header>

        <div class="main">
            <img src="../../assets/edsights-logo.png" class="logo" alt="EdSights logo" />
            <h1 class="title">Help your students succeed.</h1>
            <p class="subtitle">
                Please enter your email address below, and we'll send you a link to reset your
                password
            </p>

            <form class="form" v-on:submit.prevent="handleFormSubmit()">
                <div v-if="formErrors && formErrors.non_field_errors">
                    <p class="form-error" v-for="error in formErrors.non_field_errors">
                        {{ error }}
                    </p>
                </div>
                <div class="field-group">
                    <label for="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        v-model="email"
                        placeholder="Email Address"
                        autocomplete="email"
                    />
                    <span v-if="formErrors && formErrors.email" class="field-error">
                        <span v-for="error in formErrors.email">{{ error }}</span>
                    </span>
                </div>

                <loading-spinner-button
                    type="submit"
                    class="submit-button"
                    :text="'Request Password Reset'"
                    :loading="sendingRequest"
                ></loading-spinner-button>

                <div>
                    <router-link :to="{ name: 'Login' }" class="link">
                        Back to Login
                    </router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Auth from '@/services/auth';

import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import AppHeader from '@/components-deprecated/AppHeader';

export default {
    name: 'ForgotPassword',
    components: {
        AppHeader,
        LoadingSpinnerButton
    },
    data() {
        return {
            email: '',
            formErrors: null,
            uid: null,
            token: null,
            sendingRequest: false
        };
    },
    methods: {
        handleFormSubmit() {
            this.sendingRequest = true;
            const email = this.email.toLowerCase();
            Auth.passwordResetRequest(email)
                .then(response => {
                    this.formErrors = null;
                    this.$router.replace({ name: 'ForgotPasswordSuccess' });
                })
                .catch(error => {
                    this.sendingRequest = false;
                    const { response } = error;
                    if (response) {
                        this.formErrors = response.data;
                        return;
                    }
                    throw error;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/auth-pages';

.forgot-password {
    @extend %auth-page;
}
</style>
