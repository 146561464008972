import { objectToSnakeCase, objectToCamelCase } from '@/services/utils';
import api from './api';

export default class InterventionItems {
    static api = api.create(InterventionItems);
    static readOnlyFields = ['id'];
    constructor({
        id = null,
        student = '',
        template = '',
        outreachStatus = false,
        dateAdded = '',
        adminViewed = false,
        studentRef = [],
        templateRef = null,
        lastActivityRef = null
    } = {}) {
        Object.assign(this, {
            id,
            student,
            template,
            outreachStatus,
            dateAdded,
            studentRef,
            adminViewed,
            templateRef,
            lastActivityRef,
            renderedText: templateRef ? templateRef.renderedText : null
        });
    }
    static create(data = {}) {
        return new InterventionItems(data);
    }
    static fromAPI(json = {}) {
        const jsonCamel = objectToCamelCase(json);
        return new InterventionItems(jsonCamel);
    }
    static toAPI(d, fields = [], excludeFields = []) {
        let data = {};
        if (fields.length > 0) {
            fields.forEach(f => {
                data[f] = d[f];
            });
        } else {
            data = d;
        }
        excludeFields = [...InterventionItems.readOnlyFields, ...excludeFields];
        excludeFields.forEach(f => {
            delete data[f];
        });
        return objectToSnakeCase(data);
    }

    get adminInitials() {
        if (!this.lastActivityRef) {
            return '';
        }

        let adminName = this.lastActivityRef.adminRef.displayName;
        if (!adminName) {
            return '';
        }

        let initials = adminName
            .split(' ') // split into array of first and last name
            .map(n => n[0]) // get the first letter of each name
            .join('') // join first letters together
            .toUpperCase();

        return initials;
    }

    get adminFullName() {
        if (!this.lastActivityRef) {
            return '';
        }

        return this.lastActivityRef.adminRef.displayName;
    }
}
