<template>
    <div class="chart-container">
        <LineChart
            :chartData="chartData"
            x-axis-label="Time"
            y-axis-label="Texts"
            :y-axis-tick-limit="3"
            :x-axis-tick-limit="12"
            :tooltip-callbacks="tooltipCallbacks"
            :aria-label="chartAriaLabel"
            :height="200"
        />
    </div>
</template>

<script>
import LineChart from '@/components-deprecated/charts/LineChart';
import { getDefaultLineChartProperties } from '@/components-deprecated/charts/constants';

export default {
    name: 'TextsChart',
    components: { LineChart },
    props: {
        messageCountByWeek: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            tooltipCallbacks: {
                label: function(context) {
                    if (context && context.formattedValue) {
                        return `${context.formattedValue} texts`;
                    }
                    return '';
                }
            }
        };
    },
    computed: {
        chartData() {
            const labels = [];
            const data = [];

            if (this.messageCountByWeek && typeof this.messageCountByWeek === 'object') {
                Object.keys(this.messageCountByWeek).forEach(key => {
                    labels.push(key);
                    data.push(this.messageCountByWeek[key]);
                });
            }

            return {
                labels,
                datasets: [
                    {
                        data,
                        ...getDefaultLineChartProperties()
                    }
                ]
            };
        },
        chartAriaLabel() {
            const title =
                'Number of student texts over the last 12 months or since launching, by week:';
            if (this.messageCountByWeek) {
                const length = Object.keys(this.messageCountByWeek).length;
                const dataString = Object.keys(this.messageCountByWeek).reduce(
                    (acc, week, index) => {
                        const count = this.messageCountByWeek[week];
                        return `${acc}${week}: ${count}${index === length - 1 ? '.' : ', '}`;
                    },
                    ''
                );

                return `${title} ${dataString}`;
            }

            return `${title} No student text data yet.`;
        }
    }
};
</script>

<style lang="scss" scoped>
.chart-container {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin-top: 2rem;

    & > div {
        width: 95%;
    }
}
</style>
