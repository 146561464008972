<template>
    <div class="student-voice-score-insights-card-container">
        <Card title="SVS Insights" titleTooltip="Insights related to your school's SVS">
            <div class="student-voice-score-insights-card-content">
                <EmptyState subMessage="Stay tuned - more SVS insights are coming soon." />
            </div>
        </Card>
    </div>
</template>

<script>
import Card from '@/components-deprecated/global/Card.vue';
import EmptyState from '@/components-deprecated/global/EmptyState.vue';

export default {
    name: 'StudentVoiceScoreInsights',
    components: { EmptyState, Card }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.student-voice-score-insights-card-container {
    width: 100%;
    .student-voice-score-insights-card-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}
</style>
