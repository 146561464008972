<template>
    <div class="container col">
        <div :key="touchpoint.id" v-for="touchpoint in touchpoints" class="row">
            <h2 class="date">
                <p class="month-day">{{ formatDate(touchpoint.chatbotFlow.date, 'MMM DD') }}</p>
                <p class="year">{{ formatDate(touchpoint.chatbotFlow.date, 'YYYY') }}</p>
            </h2>
            <div class="shape-container">
                <span class="circle" />
                <span class="vertical-line" />
            </div>
            <TouchpointCard
                class="card"
                :touchpoint="touchpoint"
                @navigate-to-intervention="navigateToIntervention"
            />
        </div>
        <Paginator
            v-if="hasMoreItems"
            :loading="loadingNextPage"
            :nextPage="hasMoreItems"
            @next-page="$emit('next-page')"
        />

        <ModalReportDownload />
    </div>
</template>

<script>
import ModalReportDownload from '@/components-deprecated/admin/ModalReportDownload';
import TouchpointCard from '@/components-deprecated/admin/TouchpointCard';
import Paginator from '@/components-deprecated/Paginator';
import { vueMomentFilterReplacement } from '@/lib/vue-migration-helpers';

export default {
    name: 'Touchpoints',
    components: {
        ModalReportDownload,
        TouchpointCard,
        Paginator
    },
    props: {
        touchpoints: {
            type: Array,
            required: true
        },
        hasMoreItems: {
            type: Boolean,
            default: false
        },
        loadingNextPage: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        formatDate(date, format) {
            return vueMomentFilterReplacement(date, format);
        },
        navigateToIntervention(chatbotTemplateId) {
            this.$emit('navigate-to-intervention', chatbotTemplateId);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.date {
    text-align: right;
    color: $accessible-gray;
    flex: 1;
    font-weight: inherit;
}

.month-day {
    font-size: 2rem;
}

.year {
    font-size: 1.75rem;
    margin-top: 1rem;
}

.shape-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 0.25;
}

.circle {
    height: 1rem;
    width: 1rem;
    background-color: $edsights-blue;
    border-radius: 50%;
}

.vertical-line {
    width: 1px;
    background-color: $very-light-gray;
    height: 100%;
}

.card {
    margin: 0.5rem 5rem 2rem;
    flex: 10;
}
</style>
