'use strict';

import Pusher from 'pusher-js';
import { strictEqual } from 'assert';
import { poll } from '@edsights/polling';

let client = null;
const logToConsole = false;

// Track subscribed channels, so we can disconnect when no longer subscribed
// and cut down on concurrent connections:
const subscribedChannels = new Set();

// Check for disconnect every 20 seconds:
poll(async () => {
    if (client && client.connection.state === 'connected' && subscribedChannels.size === 0) {
        client.disconnect();
    }
}, 20000);

const initializeClient = async () => {
    strictEqual(
        typeof process.env.VUE_APP_PUSHER_APP_KEY,
        'string',
        'process.env.VUE_APP_PUSHER_APP_KEY must be a string'
    );

    if (!client) {
        Pusher.logToConsole = logToConsole;
        client = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
            cluster: 'us2'
        });
    }

    // Establish a connection. Does nothing if already connected.
    // Creating the client above creates the initial connection. This ensures the client is connected
    // in case client.disconnect() has been called.
    client.connect();

    // Return a promise that resolves when the connection is established, or rejects on failure.
    return new Promise((resolve, reject) => {
        // Resolve if already connected:
        if (client.connection.state === 'connected') {
            resolve();
        } else {
            // Bind to connected/failed events:
            client.connection.bind('connected', () => {
                resolve();
            });

            client.connection.bind('failed', () => {
                reject(new Error('Pusher connection failed.'));
            });
        }
    });
};

// Subscribes to a channel and binds provided events and callback functions to the channel, and
// unsubscribes from the channel after any of the callbacks has been run.
export const subscribeAndExecuteOnEvents = async ({ channelName, eventCallbacks }) => {
    strictEqual(typeof channelName, 'string', 'channelName must be a string');
    strictEqual(Array.isArray(eventCallbacks), true, 'eventCallbacks must be an array');
    strictEqual(
        eventCallbacks.every(
            item =>
                typeof item === 'object' &&
                typeof item.eventName === 'string' &&
                typeof item.callback === 'function'
        ),
        true,
        'eventCallbacks must contain objects with an event (string) and callback (function)'
    );

    await initializeClient();

    const onSubscriptionSuccess = channel => {
        // Add channel to set of subscribed channels:
        subscribedChannels.add(channelName);

        // Bind events/callbacks to channel:
        eventCallbacks.forEach(item => {
            channel.bind(item.eventName, async data => {
                try {
                    await item.callback(data.message);
                } finally {
                    unsubscribe({ channelName });
                }
            });
        });
    };

    // Return promise that resolves/rejects based on subscription success/failure:
    return new Promise(async (resolve, reject) => {
        // Subscribe to channel:
        const channel = client.subscribe(channelName);

        // Channel object already subscribed:
        if (channel.subscribed) {
            onSubscriptionSuccess(channel);
            resolve(channel);
        } else {
            // Channel not yet subscribed. Bind to success/failure:
            channel.bind('pusher:subscription_succeeded', () => {
                onSubscriptionSuccess(channel);
                resolve(channel);
            });

            channel.bind('pusher:subscription_error', () => {
                reject(new Error(`Channel subscription failed.`));
            });
        }
    });
};

export const unsubscribe = ({ channelName }) => {
    strictEqual(typeof channelName, 'string', 'channelName must be a string');
    subscribedChannels.delete(channelName);
    client.unsubscribe(channelName);
};
