export default {
    edsights: {
        header: {
            bg: '#0575d6',
            text: '#ffffff'
        },
        launcher: {
            bg: '#0575d6'
        },
        messageList: {
            bg: '#ffffff'
        },
        sentMessage: {
            bg: '#0575d6',
            text: '#ffffff'
        },
        receivedMessage: {
            bg: '#eaeaea',
            text: '#222222'
        },
        userInput: {
            bg: '#f4f7f9',
            text: '#565867'
        },
        userList: {
            bg: '#fff',
            text: '#212121'
        }
    },
    dark: {
        header: {
            bg: '#34495e',
            text: '#ecf0f1'
        },
        launcher: {
            bg: '#34495e'
        },
        messageList: {
            bg: '#2c3e50'
        },
        sentMessage: {
            bg: '#7f8c8d',
            text: '#ecf0f1'
        },
        receivedMessage: {
            bg: '#95a5a6',
            text: '#ecf0f1'
        },
        userInput: {
            bg: '#34495e',
            text: '#ecf0f1'
        },
        userList: {
            bg: '#2c3e50',
            text: '#ecf0f1'
        }
    }
};
