export const COLOR_CHART_RISK_LOW = 'rgb(242,243,248)'; // rgb(112,199,98)
export const COLOR_CHART_RISK_LOW_ACCESSIBLE = 'rgb(112,199,98)';
export const COLOR_CHART_RISK_LOW_DARKER = 'rgb(213,213,210)'; // rgb(26,133,7)
export const COLOR_CHART_RISK_LOW_DARKER_ACCESSIBLE = 'rgb(26,133,7)';
export const COLOR_CHART_RISK_MEDIUM = 'rgb(98,168,253)';
export const COLOR_CHART_RISK_MEDIUM_DARKER = 'rgb(70,136,253)';
export const COLOR_CHART_RISK_HIGH = 'rgb(144,95,253)';
export const COLOR_CHART_RISK_HIGH_DARKER = 'rgb(108,8,253)';
export const COLOR_PRIMARY = '#0A8BFF';

export const getColors = isExperimentalAccessibilityEnabled => {
    if (isExperimentalAccessibilityEnabled === true) {
        return {
            // We map some colors to their accessible counterpart:
            COLOR_CHART_RISK_LOW: COLOR_CHART_RISK_LOW_ACCESSIBLE,
            COLOR_CHART_RISK_LOW_DARKER: COLOR_CHART_RISK_LOW_DARKER_ACCESSIBLE,

            // Rest of colors:
            COLOR_CHART_RISK_MEDIUM,
            COLOR_CHART_RISK_MEDIUM_DARKER,
            COLOR_CHART_RISK_HIGH,
            COLOR_CHART_RISK_HIGH_DARKER,
            COLOR_PRIMARY: COLOR_PRIMARY
        };
    } else {
        return {
            COLOR_CHART_RISK_LOW,
            COLOR_CHART_RISK_LOW_DARKER,
            COLOR_CHART_RISK_MEDIUM,
            COLOR_CHART_RISK_MEDIUM_DARKER,
            COLOR_CHART_RISK_HIGH,
            COLOR_CHART_RISK_HIGH_DARKER,
            COLOR_PRIMARY: COLOR_PRIMARY
        };
    }
};
