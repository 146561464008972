import { objectToCamelCase, objectToSnakeCase } from '../utils';

export default class User {
    constructor(user) {
        // eslint-disable-next-line no-param-reassign
        user = user || {};
        const {
            id = null,
            displayName = null,
            email = '',
            phoneNumber = '',
            firstName = '',
            lastName = '',
            isAdmin = false,
            isStaff = false,
            school = null,
            schoolRef = null
        } = user;
        Object.assign(this, {
            id,
            displayName,
            email,
            phoneNumber,
            firstName,
            lastName,
            isAdmin,
            isStaff,
            school,
            schoolRef
        });
    }

    static create(opts) {
        return new User(opts);
    }

    static fromAPI(json) {
        return new User(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }

    get fullName() {
        return `${this.firstName} ${this.lastName}`;
    }

    clone() {
        return new User(this);
    }
}
