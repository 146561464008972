<template>
    <div class="insights">
        <BoxContainer
            class="insights__header"
            title="Insights"
            subtitle="You'll find this interesting..."
            ariaLabel="Full Page - Insights"
            emptyDescription="Once we start engaging with your students you will see any interesting analytics and data trends here."
            :showMoreTo="showMoreTo"
            fixedHeader
        >
            <template v-if="insights.length !== 0">
                <slot v-for="insight in insights" name="insight" v-bind="insight">
                    <!-- Fallback content, if nothing is passed into Insights component slot (i.e. the home page) -->
                    <OverviewListItem
                        :key="insight.id"
                        :title="insight.userFriendlyType"
                        :description="insight.title"
                        :selected="insight === selectedItem"
                        :date="insight.lastEdited"
                        buttonText="View More"
                        @click="onSelectInsight(insight)"
                    />
                </slot>
            </template>
            <OverviewListItem
                v-else-if="displayEmptyMsgAsListItem"
                title="Coming Soon!"
                description="Insights will be displayed here as we learn more about your students."
                selected
            />
        </BoxContainer>
    </div>
</template>

<script>
import Header from '@/components-deprecated/overview/Header';
import OverviewListItem from '@/components-deprecated/overview/OverviewListItem';
import BoxContainer from '@/components-deprecated/BoxContainer';

export default {
    name: 'Insights',

    components: {
        Header,
        OverviewListItem,
        BoxContainer
    },

    props: {
        insights: {
            type: Array,
            required: true
        },
        showMoreTo: {
            type: Object,
            default: null
        },
        selectedItem: {
            type: Object,
            default: null
        },
        displayEmptyMsgAsListItem: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onSelectInsight(insight) {
            this.$router.push({
                name: 'InsightDetail',
                params: { id: insight.id, pageName: 'Dashboard' }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.insights {
    position: relative;
    height: 100%;
}

.splitter {
    position: absolute;
    left: -20px;
    right: -20px;
    height: 2px;
    background-color: $border-color;
}
</style>
