export const isString = value => {
    return typeof value === 'string';
};

export const isObject = value => {
    return typeof value === 'object';
};

export const isFn = value => {
    return typeof value === 'function';
};

export const isFunction = value => {
    return typeof value === 'function';
};
