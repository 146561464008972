<template>
    <div class="input-container" @click.stop>
        <button
            class="input-group cursor-pointer"
            @click.stop="isDropdownActive = !isDropdownActive"
        >
            <label class="input-label faded">
                {{ selected.name }}
            </label>
            <div>
                <svg width="10px" height="10px" class="arrow-reversed" viewBox="0 0 5.9 17.51">
                    <use xlink:href="#icon-arrow-right-wide" />
                </svg>
            </div>
        </button>
        <TransitionExpand>
            <div class="transition-container" v-if="isDropdownActive">
                <button
                    :key="choice.id"
                    v-for="choice in choices"
                    class="dropdown-choice"
                    @click.stop="onTouchpointFilterSelect(choice)"
                >
                    {{ choice.name }}
                </button>
            </div>
        </TransitionExpand>
    </div>
</template>

<script>
import TransitionExpand from '@/components-deprecated/TransitionExpand';

export default {
    name: 'TransitionDropdown',
    components: {
        TransitionExpand
    },

    data() {
        return {
            isDropdownActive: false
        };
    },

    props: {
        choices: {
            type: Array,
            required: true
        },
        selected: {
            type: Object,
            required: true
        }
    },

    created() {
        window.addEventListener('click', this.closeEvent);
    },

    beforeUnmount() {
        window.removeEventListener('click', this.closeEvent);
    },

    methods: {
        onTouchpointFilterSelect(choice) {
            this.$emit('on-select', choice);
            this.isDropdownActive = false;
        },

        closeEvent(e) {
            if (this.$el.contains(e.target) !== true) {
                this.isDropdownActive = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.input-container {
    width: 100%;
    position: relative;
    align-items: center;
    // margin-right: 2rem;
}

.filter-button {
    margin-right: 1rem;
    color: $accessible-gray;

    &:last-child {
        margin-right: 4rem;
    }
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $white-blue;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    border: 0;
    height: 32px;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }

    > input {
        width: 100%;
        border: none;
        outline: none;
        background-color: inherit;
        padding-right: 3rem;
        font: inherit;
        color: $input-gray;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.input-label {
    cursor: pointer;
    background-color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;
}

.arrow {
    transform: rotate(-90deg);
    &-reversed {
        transform: rotate(90deg);
    }
}

.transition-container {
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: $white;
    left: 0;
    right: 0;
    max-height: 400px;
}

.dropdown-choice {
    cursor: pointer;
    padding: 0.75rem 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;
    border: 0;
    background: #fff;
    text-align: left;
    margin-bottom: 2px;
    line-height: 26px;

    &:hover {
        background-color: $white-blue;
    }

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}
</style>
