<template>
    <div class="indicator-container">
        <div class="indicator" :class="indicatorClassRisk"></div>
        <div class="indicator-text">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: 'IndicatorRiskLevel',

    props: {
        riskLevel: {
            type: String,
            validator: level =>
                ['LOW_RISK', 'MEDIUM_RISK', 'HIGH_RISK', 'NOT_APPLICABLE'].includes(level),
            required: true
        }
    },

    computed: {
        indicatorClassRisk() {
            switch (this.riskLevel) {
                case 'LOW_RISK':
                    return 'indicator_low-risk';
                case 'MEDIUM_RISK':
                    return 'indicator_medium-risk';
                case 'HIGH_RISK':
                    return 'indicator_high-risk';
            }

            return '';
        },
        text() {
            switch (this.riskLevel) {
                case 'LOW_RISK':
                    return 'Low';
                case 'MEDIUM_RISK':
                    return 'Medium';
                case 'HIGH_RISK':
                    return 'High';
            }

            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.indicator-container {
    display: flex;
    align-items: center;
}

.indicator {
    width: 8px;
    height: 8px;
    margin-right: 15px;
    border-radius: 50%;

    &_low-risk {
        background-color: $color-low-risk;
    }

    &_medium-risk {
        background-color: $color-medium-risk;
    }

    &_high-risk {
        background-color: $color-high-risk;
    }
}
</style>
