<template>
    <div class="labels">
        <div class="description">
            <h2>Labels</h2>
            <p>
                Manage your Flow and Template labels.
            </p>
        </div>

        <div class="box">
            <BoxContainer title="Labels" hideSplitter>
                <LoadingSpinner v-if="loading" />
                <LabelsTable
                    v-if="!loading"
                    :columns="tableColumns"
                    :data="tableData"
                    @renameLabel="openRenameLabelModal"
                    @deleteLabel="openDeleteLabelModal"
                    @createNew="openCreateLabelModal"
                />
            </BoxContainer>
        </div>

        <RenameLabelModal
            v-if="this.renameLabelModalData.showModal"
            :labelText="this.renameLabelModalData.label.text"
            :saving="this.renameLabelModalData.saving"
            @onSave="renameLabel"
            @onCancel="closeRenameLabelModal"
        />

        <DeleteLabelModal
            v-if="this.deleteLabelModalData.showModal"
            :labelText="this.deleteLabelModalData.label.text"
            :saving="this.deleteLabelModalData.saving"
            @onSave="deleteLabel"
            @onCancel="closeDeleteLabelModal"
        />

        <CreateChatbotFlowLabelModal
            v-if="this.showCreateLabelModal"
            @close-modal="this.closeCreateLabelModal"
            @label-created="onLabelCreated"
        />
    </div>
</template>

<script>
import BoxContainer from '@/components-deprecated/BoxContainer';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import LabelsTable from '@/views/staff/ChatbotFlowList/components/LabelsTable';
import {
    deleteChatbotFlowLabelById,
    getChatbotFlowLabels,
    updateChatbotFlowLabelById
} from '@/api/chatbot-flow-labels';
import RenameLabelModal from '@/views/staff/ChatbotFlowList/components/RenameLabelModal';
import DeleteLabelModal from '@/views/staff/ChatbotFlowList/components/DeleteLabelModal';
import CreateChatbotFlowLabelModal from '@/views/staff/CreateChatbotFlowLabelModal';

export default {
    name: 'Labels',
    components: {
        LabelsTable,
        BoxContainer,
        LoadingSpinner,
        RenameLabelModal,
        DeleteLabelModal,
        CreateChatbotFlowLabelModal
    },
    data() {
        return {
            loading: false,
            labels: null,
            renameLabelModalDataDefaults: {
                showModal: false,
                label: null,
                saving: false
            },
            renameLabelModalData: { ...this.renameLabelModalDataDefaults },
            deleteLabelModalDataDefaults: {
                showModal: false,
                label: null,
                saving: false
            },
            deleteLabelModalData: { ...this.deleteLabelModalDataDefaults },
            showCreateLabelModal: false
        };
    },
    computed: {
        tableColumns() {
            return [
                { dataKey: 'text', label: 'Label Name' },
                { dataKey: 'actions', label: 'Actions' }
            ];
        },
        tableData() {
            if (Array.isArray(this.labels)) {
                return this.labels.reduce((acc, label) => {
                    if (
                        typeof label === 'object' &&
                        typeof label.id === 'string' &&
                        typeof label.text === 'string'
                    ) {
                        acc.push({
                            id: label.id,
                            text: label.text
                        });
                        return acc;
                    }
                    return acc;
                }, []);
            }

            return [];
        }
    },
    async created() {
        try {
            this.loading = true;
            this.labels = await getChatbotFlowLabels();
            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.$Alert.alert({
                type: 'error',
                message: 'There was a problem loading the labels. Please try again.'
            });
        }
    },
    methods: {
        openRenameLabelModal(labelId) {
            if (Array.isArray(this.labels) && typeof labelId === 'string') {
                const label = this.labels.find(l => l.id === labelId);
                if (typeof label === 'object') {
                    this.renameLabelModalData = {
                        ...this.renameLabelModalDataDefaults,
                        showModal: true,
                        label
                    };
                }
            }
        },
        closeRenameLabelModal() {
            this.renameLabelModalData = this.renameLabelModalDataDefaults;
        },
        async renameLabel(labelText) {
            try {
                this.renameLabelModalData.saving = true;
                await updateChatbotFlowLabelById({
                    labelId: this.renameLabelModalData.label.id,
                    label: { text: labelText },
                    errorHandlerOptions: { enableAlert: true, rethrow: true }
                });
                if (Array.isArray(this.labels)) {
                    this.labels = this.labels.map(label => {
                        if (label.id === this.renameLabelModalData.label.id) {
                            return {
                                ...label,
                                text: labelText
                            };
                        }

                        return label;
                    });
                }
                this.$Alert.alert({
                    type: 'success',
                    timeout: 7000,
                    message: `Label renamed.`
                });
                this.closeRenameLabelModal();
            } catch (error) {
                this.renameLabelModalData.saving = false;
            }
        },
        openDeleteLabelModal(labelId) {
            if (typeof labelId === 'string' && Array.isArray(this.labels)) {
                const label = this.labels.find(l => l.id === labelId);
                if (typeof label === 'object') {
                    this.deleteLabelModalData = {
                        ...this.deleteLabelModalDataDefaults,
                        showModal: true,
                        label
                    };
                }
            }
        },
        closeDeleteLabelModal() {
            this.deleteLabelModalData = this.deleteLabelModalDataDefaults;
        },
        async deleteLabel() {
            try {
                this.deleteLabelModalData.saving = true;
                await deleteChatbotFlowLabelById({
                    labelId: this.deleteLabelModalData.label.id,
                    errorHandlerOptions: { enableAlert: true, rethrow: true }
                });
                this.labels = this.labels.filter(l => l.id !== this.deleteLabelModalData.label.id);
                this.$Alert.alert({
                    type: 'success',
                    timeout: 7000,
                    message: `Label deleted.`
                });
                this.closeDeleteLabelModal();
            } catch (error) {
                this.deleteLabelModalData.saving = false;
            }
        },
        openCreateLabelModal() {
            this.showCreateLabelModal = true;
        },
        closeCreateLabelModal() {
            this.showCreateLabelModal = false;
        },
        onLabelCreated(label) {
            if (
                typeof label === 'object' &&
                typeof label.id === 'string' &&
                typeof label.text === 'string' &&
                Array.isArray(this.labels)
            ) {
                this.labels.unshift(label);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.labels {
    min-height: 100vh;
    .description {
        margin-bottom: 10px;
    }
    .box {
        min-height: 500px;
    }
}
</style>
