<template>
    <div class="chart-container">
        <div class="progress-bar" aria-hidden="true" role="img" alt="">
            <div class="progress-bar__progress" :style="{ width: `${value}%` }"></div>
        </div>

        <p v-if="displayValue" class="chart-container__value">{{ valueToDisplay }}%</p>
    </div>
</template>

<script>
export default {
    name: 'ChartProgress',

    props: {
        value: {
            type: Number,
            default: 50,
            validator: value => (value >= 0 && value <= 100) || isNaN(value)
        },
        displayValue: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        valueToDisplay() {
            if (Number.isNaN(this.value)) return 0;
            return this.value.toFixed(1);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.chart-container {
    display: flex;
    align-items: center;

    &__value {
        margin-left: 20px;
        width: 30px;
    }
}

.progress-bar {
    width: 100%;
    background-color: #d8d8d8;
    height: 2px;
    border-radius: 6px;

    &__progress {
        height: 2px;
        background-color: $edsights-blue;
    }
}
</style>
