import { objectToCamelCase, objectToSnakeCase } from '@/services/utils';
import SchoolAPI from './api';

export default class School {
    static api = SchoolAPI.create(School);
    static readOnlyFields = ['id'];

    constructor({ id = null, name = '' } = {}) {
        Object.assign(this, { id, name });
    }

    static create(opts = {}) {
        return School.create(opts);
    }
    static fromAPI(json) {
        return new School(objectToCamelCase(json));
    }
    static toAPI(d) {
        let data = d;

        return objectToSnakeCase(data);
    }
}
