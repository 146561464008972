import axios from 'axios';

import Auth from '../auth';
import { API_BASE, apiErrorHandler } from '../api';
import { isDefined } from '../utils';

// Endpoint
const NOTES_ENDPOINT = `${API_BASE}notes/`;

export default class NoteAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new NoteAPI(cls);
    }

    async createNote(note) {
        const url = NOTES_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = this.cls.toAPI(note);

        const promise = axios
            .post(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Create Note Api Error' }));
        return promise;
    }

    async createMassNoteForIntervention(note, interventionTemplateId) {
        const url = `${NOTES_ENDPOINT}bulk-create-for-intervention/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        let data = {
            note: note,
            interventionTemplateId: interventionTemplateId
        };

        const promise = axios
            .post(url, data, options)
            .then(response => response.data)

            .catch(apiErrorHandler({ apiName: 'Create Tag Category Api Error' }));
        return promise;
    }

    async createMassNote(note) {
        const url = `${NOTES_ENDPOINT}bulk-create-mass/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        let data = {
            note: note
        };

        const promise = axios
            .post(url, data, options)
            .then(response => response.data)

            .catch(apiErrorHandler({ apiName: 'Create Tag Category Api Error' }));
        return promise;
    }

    async list({ studentID = '' } = {}) {
        const url = NOTES_ENDPOINT;
        const params = {
            student: studentID
        };
        const options = {
            headers: await Auth.getHeaders().headers,
            params
        };

        const promise = axios
            .get(url, options)
            .then(response => {
                return {
                    ...response.data,
                    results: data.map(datum => this.cls.fromAPI(datum))
                };
            })
            .catch(apiErrorHandler({ apiName: 'Get Note Data API error' }));
        return promise;
    }

    async retrieve(noteId) {
        const url = `${NOTES_ENDPOINT}${noteId}`;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Get Note Info API error' }));

        return promise;
    }

    async update(note) {
        const url = `${NOTES_ENDPOINT}${note.id}/`;
        const data = this.cls.toAPI(note);
        const options = {
            headers: Auth.getHeaders().headers
        };

        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: "Update Note's data API error" }));
        return promise;
    }

    async delete(noteId) {
        const url = `${NOTES_ENDPOINT}${noteId}`;
        const options = {
            headers: Auth.getHeaders().headers
        };

        const promise = axios
            .delete(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Delete Note Info API error' }));
        return promise;
    }
}
