<template>
    <div class="header">
        <div class="header__date">Date</div>
        <div class="header__school">School</div>
        <div class="header__status">Status</div>
        <div class="header__name">Student Name</div>
        <div class="header__email">Student Email</div>
        <div class="header__number">Student #</div>
        <div class="header__text">Text</div>
        <div></div>
    </div>
</template>

<script>
export default {
    name: 'ChatbotTextsListHeader'
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: $white-blue;

    &__date {
        width: 15%;
    }

    &__school {
        width: 15%;
    }

    &__status {
        width: 10%;
    }

    &__name {
        width: 12%;
    }

    &__email {
        width: 15%;
    }

    &__number {
        width: 10%;
    }

    &__text {
        width: 28%;
    }
}
</style>
