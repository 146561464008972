<template>
    <div :class="`chart-container ${chartSizeClass}`">
        <BarChart
            :chartData="chartData"
            :suggestedMin="0"
            :suggestedMax="null"
            :showYGridLines="false"
            :showLegend="false"
            :displayXTicks="false"
            :displayTooltipColors="false"
            :tooltipsEnabled="!isExperimentalAccessibilityEnabled"
            :tooltipCallbacks="tooltipCallbacks"
            tooltipYAlign="center"
            tooltipXAlign="right"
            :ariaLabel="chartAriaLabel"
        />
    </div>
</template>

<script>
import BarChart from '@/components-deprecated/charts/BarChart.vue';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'TopicsChart',
    components: { BarChart },
    props: {
        topics: {
            type: Array,
            default: null
        },
        totalQuestions: {
            type: Number,
            default: 0
        }
    },
    data: function(vm) {
        return {
            tooltipCallbacks: {
                title: function(tooltipItem) {
                    if (Array.isArray(tooltipItem) && tooltipItem[0] && tooltipItem[0].label) {
                        return tooltipItem[0].label;
                    }
                    return '';
                },
                label: function(context) {
                    if (context && context.parsed && context.parsed.x && vm.totalQuestions) {
                        return `${vm.getTopicPercentage(context.parsed.x)}% of questions`;
                    }
                    return '';
                }
            }
        };
    },
    methods: {
        getTopicPercentage(topicCount) {
            if (this.totalQuestions) {
                return Math.round((topicCount / this.totalQuestions) * 100);
            }

            return 0;
        }
    },
    computed: {
        chartData() {
            return {
                labels: this.computeLabels,
                datasets: [
                    {
                        backgroundColor: '#008CFF',
                        pointBackgroundColor: 'white',
                        borderWidth: 0,
                        barWidth: 1,
                        pointBorderColor: '#249EBF',
                        data: this.computeData
                    }
                ]
            };
        },
        computeLabels() {
            if (Array.isArray(this.topics)) {
                return this.topics.map(topic => {
                    if (this.isExperimentalAccessibilityEnabled) {
                        return `${topic.name} (${this.getTopicPercentage(topic.count)}%)`;
                    }

                    // After upgrading to chart.js 3.9.1 and vue-chart.js 4.1.2,
                    // long y-axis labels are cut off by the canvas border. Adding
                    // some padding to the string for now:
                    return ' ' + topic.name;
                });
            }
            return [];
        },
        computeData() {
            if (Array.isArray(this.topics)) {
                return this.topics.map(topic => topic.count);
            }

            return [];
        },
        chartAriaLabel() {
            const title = 'Top topics students are talking about ranked by percentage:';
            if (Array.isArray(this.topics)) {
                const topicsLength = this.topics.length;
                const dataString = this.topics.reduce((acc, topic, index) => {
                    return `${acc}${topic.name}: ${this.getTopicPercentage(topic.count)}%${
                        index === topicsLength - 1 ? '.' : ', '
                    }`;
                }, '');

                return `${title} ${dataString}`;
            }

            return `${title} No topic data yet.`;
        },
        chartSizeClass() {
            if (Array.isArray(this.topics)) {
                const itemsCount = this.topics.length;
                return `chart-size-${itemsCount}-items`;
            }
            return '';
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.chart-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;

    $maxItems: 10;
    @for $i from 1 through $maxItems {
        &.chart-size-#{$i}-items {
            :deep(.bar-chart-container) {
                width: 95%;
                & > div {
                    $baseBarHeight: 30;
                    height: #{($i + 1) * $baseBarHeight}px;
                }
            }
        }
    }
}
</style>
