import Checkbox from '@/components-deprecated/inputs/Checkbox';
import Checkboxv2 from '@/components-deprecated/inputs/Checkbox';
import CheckboxSimple from '@/components-deprecated/inputs/CheckboxSimple';
import PhoneNumber from '@/components-deprecated/inputs/PhoneNumber';
import SearchInput from '@/components-deprecated/inputs/SearchStudents';
import TagsCheckbox from '@/components-deprecated/inputs/TagsCheckbox';
import TypeAheadSearch from '@/components-deprecated/inputs/ValidatedInput';
import RadioButton from '@/components-deprecated/inputs/RadioButton';

export {
    Checkbox,
    Checkboxv2,
    CheckboxSimple,
    PhoneNumber,
    SearchInput,
    TagsCheckbox,
    TypeAheadSearch,
    RadioButton
};
