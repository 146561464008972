import axios from 'axios';
import * as Api from './index';
import { apiErrorHandler } from '@/services/api';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { strictEqual } from 'assert';

export const getChatbotFlowLabels = () => {
    const url = Api.getv2ApiBase('/chatbot-flow-labels');
    const options = Api.getOptions();

    return axios
        .get(url, options)
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Get Chatbot Flow Labels'
            })
        );
};

export const createChatbotFlowLabel = text => {
    if (typeof text !== 'string' || !text.trim().length) {
        throw new Error(`Parameter 'text' must be a non-blank string.`);
    }

    const url = Api.getv2ApiBase(`/chatbot-flow-labels`);
    const options = Api.getOptions();

    return axios
        .post(url, { text }, options)
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'Create Chatbot Flow Label'
            })
        );
};

export const deleteChatbotFlowLabelById = async ({ labelId, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof labelId, 'string');
        strictEqual(typeof errorHandlerOptions, 'object');

        const url = Api.getv2ApiBase(`/chatbot-flow-labels/${labelId}`);
        const options = Api.getOptions();

        const response = await axios.delete(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            // If the back end sends any message other than the "read-only" message,
            // show an override message instead.
            overrideMessage:
                error.response.data.message && error.response.data.message.includes('read-only')
                    ? null
                    : 'There was a problem deleting the label. Please try again.',
            ...errorHandlerOptions
        });
    }
};

export const updateChatbotFlowLabelById = async ({ labelId, label, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof labelId, 'string');
        strictEqual(typeof label, 'object');
        strictEqual(typeof errorHandlerOptions, 'object');

        const url = Api.getv2ApiBase(`/chatbot-flow-labels/${labelId}`);
        const options = Api.getOptions();

        const response = await axios.patch(url, label, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem updating the label. Please try again.',
            ...errorHandlerOptions
        });
    }
};
