<template>
    <div class="component" @click="toggle" ref="dropdown">
        <slot v-if="!!$slots.default"></slot>
        <div v-if="!!$slots.default && arrowEnabled" class="component__arrow">
            <svg
                width="10px"
                height="10px"
                :class="opened ? 'reversed' : ''"
                :style="{ color: arrowColor }"
                viewBox="0 0 300 300"
            >
                <use xlink:href="#icon-arrow-down" />
            </svg>
        </div>
        <button
            v-if="!$slots.default"
            class="outline-button"
            :class="{ 'outline-button_disabled': disabled }"
            @click.prevent.stop="toggle"
        >
            <span>Actions</span>
            <svg width="30px" height="30px" class="icon" viewBox="0 0 300 300">
                <use xlink:href="#icon-arrow-down" />
            </svg>
        </button>
        <div
            class="dropdown"
            :class="{
                dropdown_opened: opened,
                'dropdown--top-side': openTop
            }"
        >
            <button
                class="dropdown__item"
                v-for="(item, key) in actions"
                :key="key"
                @click.prevent.stop="onClick({ handler: item.onclick })"
            >
                {{ item.name }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        actions: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        arrowEnabled: {
            type: Boolean,
            default: false
        },
        arrowColor: {
            type: String,
            default: ''
        },
        openTop: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            opened: false
        };
    },
    mounted() {
        this.onClickDocument = this.onClickDocument.bind(this);
        document.addEventListener('click', this.onClickDocument);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.onClickDocument);
    },
    methods: {
        toggle() {
            if (!this.disabled) {
                this.opened = !this.opened;
            }
        },
        onClick({ handler }) {
            this.opened = false;
            if (handler) {
                handler();
            }
        },
        onClickDocument(event) {
            // Check is click happened on button or other places of documents. If other places - hide dropdown
            if (!this.$refs.dropdown.contains(event.target)) {
                this.opened = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.component {
    display: inline-flex;
    position: relative;
    cursor: pointer;

    &__arrow {
        align-self: center;
        margin-left: 5px;
        color: #b4b4b5;
    }
}

.dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    display: none;
    width: 200px;
    max-height: 0;
    font-weight: normal;
    background-color: $white;
    border-radius: 5px;
    overflow: hidden;
    z-index: 50;

    &--top-side {
        transform: translateY(-100%);
        top: 60px;
        right: unset;
        left: 0;
    }

    &__item {
        padding: 15px 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        background: #fff;
        border: 0;
        text-align: left;

        &:focus {
            box-shadow: inset 0 0 0 2px $edsights-blue;
            outline: none;
        }

        &:hover {
            background-color: #eef9ff;
            cursor: pointer;
        }
    }

    &_opened {
        display: flex;
        flex-direction: column;
        max-height: 500px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    }
}

.icon {
    position: relative;
    top: 2px;
    width: 15px;
    height: 15px;
    margin-left: 15px;
}

.reversed {
    transform: rotate(180deg);
}

.outline-button {
    border-width: 1px;
    transition: none;

    &_disabled {
        color: $main-gray;
        background-color: $light-gray;
        border-width: 0px;
        cursor: not-allowed;

        .icon {
            visibility: hidden;
        }
    }
}
</style>
