import axios from 'axios';
import { API_BASE, apiErrorHandler } from '../api';
import Auth from '../auth';
import { objectToSnakeCase } from '../utils';
import moment from 'moment';

const FAQ_ENDPOINT = `${API_BASE}faqs/`;

export default class FAQAPI {
    constructor(cls) {
        this.cls = cls;
    }

    static create(cls) {
        return new FAQAPI(cls);
    }

    list(params) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            },
            params: objectToSnakeCase(params)
        };

        const promise = axios
            .get(`${FAQ_ENDPOINT}`, options)
            .then(response => response.data)
            .then(data => {
                return {
                    ...data,
                    results: data.results.map(this.cls.fromAPI)
                };
            })
            .catch(
                apiErrorHandler({
                    apiName: 'List FAQ API error'
                })
            );
        return promise;
    }

    async export() {
        // Adding the responseType to axios here so that it is aware that it is expecting a stream of data.
        // This blob is then accessed by creating a link on the fly and programmatically clicking it so that
        // it opens a new window and executes an automatic download of the file
        // If a user can't download it may be because their pop up blocker is blocking it.

        const url = `${FAQ_ENDPOINT}export/`;
        const options = {
            responseType: 'blob',
            headers: await Auth.getHeaders().headers
        };

        const promise = axios.get(url, options).then(response => {
            const url_dl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url_dl;
            let file_name = `FAQs_${moment().format()}`;
            link.setAttribute('download', file_name + '.xlsx');
            link.click();
        });

        return promise;
    }
}
