<template>
    <textarea
        :name="name"
        :class="{
            'full-width': fullWidth,
            scroll: maxHeight,
            textarea_bordered: bordered
        }"
        ref="theTextArea"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="$emit('onFocus')"
        @input="updateText()"
        @blur="$emit('blur')"
        style="overflowY: scroll;"
        :readonly="readonly"
    ></textarea>
</template>

<script>
export default {
    name: 'ElasticTextArea',
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        name: {
            type: String,
            required: false
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        maxHeight: {
            type: String,
            required: false
        },
        placeholder: {
            type: String,
            required: false
        },
        bordered: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            initialHeight: null
        };
    },
    mounted() {
        const ref = this.$refs['theTextArea'];

        if (this.maxHeight) {
            ref.style.maxHeight = this.maxHeight;
        }
        this.initialHeight = this.initialHeight || ref.style.height;
    },
    methods: {
        updateText() {
            this.$emit('update:modelValue', this.$el.value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

textarea {
    resize: both;
    overflow: hidden;
    border: none;
    padding: 3px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
    &::placeholder {
        color: $input-gray;
    }
}

.textarea_bordered {
    padding: 25px;
    background-color: $textarea-background-color;
    border: 1px solid $textarea-border-color;

    &:focus {
        border: 1px solid $textarea-border-color;
    }
}

.full-width {
    width: 100%;
}

.scroll {
    overflow: auto;
}
</style>
