/* InterventionTemplate Type choices */
const ADMIN = 'ADMIN';
const CHATBOT = 'CHATBOT';
const TYPE_CHOICES = {
    ADMIN,
    CHATBOT
};

export default {
    ADMIN,
    CHATBOT,
    TYPE_CHOICES
};
