<template>
    <div class="flow-date-container" @click.stop>
        <div v-if="showDateEditor" class="date-editor">
            <DateTimeInput v-model="inputDate" />
            <Button style-type="textSecondary" @click="toggleDateEditor(false)">
                Cancel
            </Button>
            <Button
                style-type="text"
                @click="onSaveClick"
                :loading="saving"
                :disabled="disableSave"
            >
                Save
            </Button>
        </div>
        <div v-else-if="flowInitiated" class="date readonly">
            {{ getLongDate }}
        </div>
        <div
            v-else
            class="date"
            @dblclick="toggleDateEditor(true)"
            title="Double click to edit scheduled date."
        >
            {{ getLongDate }}
        </div>
        <Modal
            v-if="showConfirmationModal"
            title="Attention: You are editing a scheduled flow."
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            @secondary-button-clicked="toggleConfirmationModal(false)"
            @primary-button-clicked="onConfirm"
        >
            <div class="confirmation-modal-content">
                <label>You are changing the date to:</label>
                <div class="date">{{ getInputLongDate }}</div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { formatLong, formatISOToHTMLDateTime, formatHTMLDateTimeToISO } from '@/lib/dates';
import DateTimeInput from '@/components-deprecated/global/DateTimeInput';
import Button from '@/components-deprecated/Button';
import { updateChatbotFlow } from '@/api/chatbot-flow';
import Modal from '@/components-deprecated/global/Modal';
import { CHATBOT_FLOW_STATUS, DRAFT_NULL_DATE_PLACEHOLDER } from '@/consts/chatbot-flow';

export default {
    name: 'FlowDate',
    components: { Modal, DateTimeInput, Button },
    props: {
        date: {
            type: String,
            required: true
        },
        flowId: {
            type: String,
            required: true
        },
        flowStatus: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            inputDate: null,
            showDateEditor: false,
            showConfirmationModal: false,
            saving: false
        };
    },
    methods: {
        toggleDateEditor(show) {
            if (!show) {
                this.inputDate = null;
                this.showDateEditor = false;
                return;
            }

            if (
                this.flowStatus === CHATBOT_FLOW_STATUS.DRAFT.value &&
                this.date === DRAFT_NULL_DATE_PLACEHOLDER
            ) {
                // draft flow has been previously saved with the null date placeholder.
                // clear out the date so it appears blank
                this.inputDate = null;
            } else {
                // format function returns empty string when invalid date provided
                // when empty, set this.inputDate to null for consistency with default state
                this.inputDate = formatISOToHTMLDateTime(this.date) || null;
            }

            this.showDateEditor = show;
        },
        onSaveClick() {
            const valid = this.isValid();
            if (!valid) {
                return;
            }

            if (this.flowStatus === CHATBOT_FLOW_STATUS.SCHEDULED.value) {
                this.toggleConfirmationModal(true);
            } else {
                this.save();
            }
        },
        toggleConfirmationModal(show) {
            this.showConfirmationModal = show;
        },
        onConfirm() {
            this.toggleConfirmationModal(false);
            this.save();
        },
        isValid() {
            const date = formatHTMLDateTimeToISO(this.inputDate);
            const now = new Date().toISOString();

            if (this.flowStatus !== CHATBOT_FLOW_STATUS.DRAFT.value && date <= now) {
                this.$Alert.alert({
                    type: 'error',
                    message: 'Date must be in the future.',
                    timeout: 7000
                });

                return false;
            }

            return true;
        },
        async save() {
            try {
                this.saving = true;

                let date;
                if (this.flowStatus === CHATBOT_FLOW_STATUS.DRAFT.value && !this.inputDate) {
                    // editing a draft flow with no date selected - save the placeholder date
                    date = DRAFT_NULL_DATE_PLACEHOLDER;
                } else {
                    date = formatHTMLDateTimeToISO(this.inputDate);
                }
                const updatedFlow = await updateChatbotFlow(this.flowId, { date });
                if (typeof updatedFlow === 'object') {
                    this.$emit('flowDateUpdated', updatedFlow);
                }
                this.toggleDateEditor(false);
                this.saving = false;
            } catch (error) {
                this.saving = false;
                this.$Alert.alert({
                    type: 'error',
                    message: 'There was a problem re-scheduling the flow. Please try again.',
                    timeout: 7000
                });
            }
        }
    },
    computed: {
        getLongDate() {
            if (
                this.flowStatus === CHATBOT_FLOW_STATUS.DRAFT.value &&
                this.date === DRAFT_NULL_DATE_PLACEHOLDER
            ) {
                return 'Date TBD';
            }
            return formatLong(this.date);
        },
        getInputLongDate() {
            const isoDate = formatHTMLDateTimeToISO(this.inputDate);
            return formatLong(isoDate);
        },
        disableSave() {
            const date = this.date;
            const isoInputDate = formatHTMLDateTimeToISO(this.inputDate);
            // disable save when date has not changed
            return date === isoInputDate;
        },
        flowInitiated() {
            return this.flowStatus === CHATBOT_FLOW_STATUS.INITIATED.value;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.flow-date-container {
    .date-editor {
        display: flex;
        align-items: center;
        justify-content: center;

        .button {
            min-width: 0;
            margin-left: 1rem;
        }
    }

    .date {
        cursor: pointer;

        &.readonly {
            cursor: auto;
        }
    }

    .confirmation-modal-content {
        .date {
            font-size: $large-font-size;
        }
    }
}
</style>
