<template>
    <div class="container">
        <LoadingSpinner v-if="globalLoading" />
        <div v-else class="manage-admins">
            <ButtonBack></ButtonBack>

            <ScrollWrapper class="box">
                <h1 class="h2">Manage Admins</h1>
                <List :loading="globalLoading">
                    <template v-slot:selected-items-count>
                        <p>{{ numAdminsSelected }}</p>
                    </template>
                    <template v-slot:list-actions>
                        <div class="input-group bordered">
                            <input
                                placeholder="Search Admins"
                                v-model="searchText"
                                @input="executeFilter"
                            />
                            <svg class="icon-search" viewBox="0 0 50 50">
                                <use xlink:href="#icon-search" />
                            </svg>
                        </div>
                        <Button class="admins-list__btn-add" @click="onClickAddNew">
                            Add New
                        </Button>
                        <Button
                            class="button-remove"
                            styleType="off"
                            @click="removeSelectedAdmins"
                            :disabled="selectedAdmins.length === 0"
                        >
                            Remove
                        </Button>
                    </template>
                    <template v-slot:list-header>
                        <AdminListItemHeader
                            :selected="allAdminsSelected"
                            @select="toggleAllAdmins(true)"
                            @unselect="toggleAllAdmins(false)"
                        />
                    </template>
                    <template v-slot:list-items>
                        <div id="admin-list">
                            <AdminListItem
                                v-for="admin in admins.list"
                                :key="admin.id"
                                :admin="admin"
                                :isStaff="user.isStaff"
                                :selected="
                                    Boolean(selectedAdmins.find(item => item.id === admin.id))
                                "
                                @editTags="onClickEditTags"
                                @editPassword="onClickEditPassword"
                                @select="selectAdmin"
                                @unselect="unselectAdmin"
                            />
                        </div>
                    </template>
                </List>
                <Paginator
                    v-if="admins.pagination.next != null"
                    :loading="admins.loadingNextPage"
                    @next-page="admins.addNextPage()"
                />
            </ScrollWrapper>
        </div>
        <ModalResetAdminPassword />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import Button from '@/components-deprecated/Button';
import List from '@/components-deprecated/tables/List';
import AdminListItem from '@/components-deprecated/tables/AdminListItem';
import AdminListItemHeader from '@/components-deprecated/tables/AdminListItemHeader';
import ScrollWrapper from '@/components-deprecated/ScrollWrapper';
import Admin from '@/services/admins';
import Paginator from '@/components-deprecated/Paginator';
import CollectionManager from '@/services/collectionManager';
import ButtonBack from '@/components-deprecated/ButtonBack';
import { debounce } from '@/services/utils';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import ModalResetAdminPassword from '@/views/modals/ModalResetAdminPassword.vue';

export default {
    name: 'ManageCoAdmins',
    components: {
        ModalResetAdminPassword,
        Button,
        ButtonBack,
        List,
        ScrollWrapper,
        AdminListItemHeader,
        AdminListItem,
        Paginator,
        LoadingSpinner
    },
    elQuerySelectorToDetectScroll: '.manage-admins .scroll-wrapper__elem',
    data() {
        return {
            admins: CollectionManager.create({
                ModelClass: Admin
            }),
            globalLoading: false,
            selectedAdmins: [],
            allAdminsSelected: false,
            totalAdminsSelected: 0,
            searchText: '',
            executeFilter: () => {}
        };
    },
    async created() {
        this.globalLoading = true;
        if (this.staffSchoolViewEnabled) {
            this.admins.filters.schools = this.user.school;
        }
        this.executeFilter = debounce(this.executeFilterUnbounced, 700);
        await this.admins.refresh();
        this.globalLoading = false;
    },
    watch: {
        selectedAdminsLength(val) {
            // watcher that checks or unchecks the select all checkbox
            if (
                (this.totalAdminsSelected !== this.admins.pagination.totalCount) |
                    (val < this.admins.list.length) &&
                this.admins.list.length > 0
            ) {
                this.allAdminsSelected = false;
                this.totalAdminsSelected = val;
            } else {
                this.allAdminsSelected = true;
            }
        }
    },
    computed: {
        ...mapState(['user', 'staffSchoolViewEnabled']),
        numAdminsSelected() {
            if (this.allAdminsSelected) {
                return `${this.admins.pagination.totalCount} admin${
                    this.admins.pagination.totalCount !== 1 ? 's' : ''
                } selected`;
            }
            const length = this.selectedAdmins.length;
            if (length) {
                return `${length} admin${length !== 1 ? 's' : ''} selected`;
            } else {
                return '';
            }
        },
        selectedAdminsLength() {
            return this.selectedAdmins.length;
        }
    },
    methods: {
        toggleAllAdmins(val = !this.allAdminsSelected) {
            this.allAdminsSelected = val;
            if (this.allAdminsSelected) {
                this.selectedAdmins = this.admins.list;
                this.totalAdminsSelected = this.admins.pagination.totalCount;
            } else {
                this.selectedAdmins = [];
                this.totalAdminsSelected = 0;
            }
        },
        onClickEditPassword(admin) {
            this.$modal.show('modal-reset-admin-password', { admin });
        },
        async executeFilterUnbounced() {
            this.globalLoading = true;
            this.admins.filters.search = this.searchText;
            await this.admins.refresh();
            this.globalLoading = false;
        },
        onClickAddNew() {
            this.$router.push({ name: 'InviteCoAdmins' });
        },
        onClickEditTags(admin) {
            this.$router.push({
                name: 'ManageAdminTags',
                params: { adminId: admin.id }
            });
        },
        selectAdmin(admin) {
            this.selectedAdmins.push(admin);
        },
        unselectAdmin(admin) {
            this.selectedAdmins = this.selectedAdmins.filter(item => item.id !== admin.id);
        },
        /* Only select main checkbox if all admins in the database are selected. */
        async removeSelectedAdmins() {
            const singleSelectedAdmin = this.selectedAdmins.length === 1 && this.selectedAdmins[0];
            let result;

            if (singleSelectedAdmin) {
                result = confirm(`Are you sure you want to remove ${singleSelectedAdmin.email}?`);
            } else {
                result = confirm('Are you sure you want to remove these admins? ');
            }
            if (!result) {
                return;
            }

            // if the user tries to deactivate themselves then show them a warning that
            // they wont be deactived.
            if (this.user.isAdmin) {
                if (
                    this.allAdminsSelected ||
                    this.selectedAdmins.filter(a => a.id === this.user.id).length
                ) {
                    this.$Alert.alert({
                        type: 'info',
                        message: `You have selected to deactivate yourself, your account will not be deactivated.`,
                        timeout: 3000
                    });
                }
            }
            let adminIds = null;
            if (!this.allAdminsSelected) {
                adminIds = this.selectedAdmins.map(admin => admin.id);
            }

            try {
                // if all admins are selected from server pass in the isAllSelected
                // this will reduce the request size.
                await Admin.api.deactivate(adminIds, this.allAdminsSelected);
                this.$Alert.alert({
                    type: 'success',
                    message: `${adminIds.length} admin(s) removed.`,
                    timeout: 7000
                });
            } finally {
                this.selectedAdmins = [];
                this.totalAdminsSelected = 0;
                this.allAdminsSelected = false;
                this.admins.refresh();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mixins/buttons';

.container {
    min-height: 100vh;
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin-right: 2rem;
    position: relative;
    border-radius: 4px;

    > input {
        width: 100%;
        border: none;
        outline: none;
        background-color: $white-blue;
        padding: 0.25rem 1rem;
        padding-right: 3rem;
        font: inherit;
        color: $input-gray;
        border-radius: 4px;

        &:focus {
            outline: 2px solid $edsights-blue;
        }
    }

    .icon-search {
        position: absolute;
        right: 8px;
    }
}

.bordered {
    border: 1px solid $silver;
}

.button-remove {
    margin-right: 2rem;
}

.icon-search {
    height: 15px;
    width: 15px;
    margin-right: 0;
}

.admins-list {
    &__btn-add {
        @include base-button();
        margin-right: 15px;
    }
}

.manage-admins :deep(.scroll-wrapper__elem) {
    max-height: calc(100vh - 170px);
}

@media screen and (max-width: 1230px) {
    .admins-list :deep(.list) {
        width: 75%;
    }
}

:deep(.table-header) {
    justify-content: flex-end;
}
</style>
<style lang="scss">
#admin-list:last-child {
    margin-bottom: 45px;
}
</style>
