<template>
    <div class="header">
        <div>Name</div>
        <div>Email</div>
    </div>
</template>

<script>
export default {
    name: 'ManageAdminStudentsListHeader'
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: $white-blue;

    & > * {
        width: 50%;
    }
}
</style>
