<template>
    <div class="password-reset-confirm">
        <div>
            <h1>EdSights</h1>
            <h2>Help your students succeed.</h2>
            <form v-on:submit.prevent="handleFormSubmit()" class="reset-form">
                <div v-if="formErrors && formErrors.non_field_errors" class="reset-error">
                    <p v-for="error in formErrors.non_field_errors">{{ error }}</p>
                </div>
                <div class="field-group password-reset-confirm__input">
                    <input type="password" v-model="password" placeholder="New password" />
                    <span v-if="formErrors && formErrors.new_password1" class="field-error">
                        <span v-for="error in formErrors.new_password1">{{ error }}</span>
                    </span>
                </div>
                <div class="field-group password-reset-confirm__input">
                    <input
                        type="password"
                        v-model="confirmPassword"
                        placeholder="Confirm new password"
                    />
                    <span v-if="formErrors && formErrors.new_password2" class="field-error">
                        <span v-for="error in formErrors.new_password2">{{ error }}</span>
                    </span>
                </div>

                <loading-spinner-button
                    type="submit"
                    :text="'Set New Password'"
                    :loading="loading"
                />
            </form>
        </div>
        <img class="logo" src="../../assets/edsights-logo-square.png" alt="EdSights logo" />
    </div>
</template>

<script>
import Auth from '@/services/auth';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';

export default {
    name: 'PasswordResetConfirm',
    components: {
        LoadingSpinnerButton
    },
    data() {
        return {
            password: '',
            confirmPassword: '',
            formErrors: null,
            uid: null,
            token: null,
            loading: false
        };
    },
    created() {
        this.uid = this.$route.params.uid;
        this.token = this.$route.params.token;
    },
    methods: {
        handleFormSubmit() {
            this.formErrors = null;
            this.loading = true;
            Auth.passwordResetConfirmRequest(
                this.password,
                this.confirmPassword,
                this.uid,
                this.token
            )
                .then(() => {
                    this.formErrors = null;
                    this.loading = false;
                    this.$router.push({ name: 'Login' });
                    this.$Alert.alert({
                        type: 'success',
                        message:
                            '<h2>Your new password has been set. Please login again here!</h2>',
                        timeout: 6000
                    });
                })
                .catch(error => {
                    const { response } = error;
                    this.loading = false;
                    if (response) {
                        this.formErrors = response.data;
                    }
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/forms';
@import '../../styles/mixins/alerts';
@import '../../styles/mixins/buttons';

.password-reset-confirm {
    width: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 3rem;

    &__input {
        margin-bottom: 1rem;
        width: 30rem;
    }
}

.field-error {
    display: flex;
    flex-direction: column;
    width: 42rem;
}

.reset-error {
    @include alert-error();
}

.logo {
    width: 30rem;
}

h1,
h2 {
    margin-bottom: 1rem;
}

button {
    @include base-button;
    width: 100%;
    height: 3rem;
    border-width: 1px;
}
</style>
