<template>
    <div class="empty-state-container">
        <h2 class="message">{{ message }}</h2>
        <h2 class="sub-message">
            {{ subMessage }}
        </h2>
    </div>
</template>

<script>
export default {
    name: 'EmptyState',
    props: {
        message: {
            type: String,
            default: 'Nothing to see here...yet!'
        },
        subMessage: {
            type: String,
            required: false
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.empty-state-container {
    .message {
        text-align: center;
        color: $accessible-gray;
        margin-top: 4rem;
    }
    .sub-message {
        text-align: center;
        color: $accessible-gray;
        line-height: 2rem;
        font-size: 1rem;
        margin: 3rem;
    }
}
</style>
