const NEW = 'NEW';
const IN_PROGRESS = 'IN_PROGRESS';
const SUCCEEDED = 'SUCCEEDED';
const FAILED = 'FAILED';

export default {
    NEW,
    IN_PROGRESS,
    SUCCEEDED,
    FAILED
};
