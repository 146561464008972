<template>
    <div class="container">
        <div v-if="!linkInvalid">
            <h1>Welcome to Edsights!</h1>
            <h3>Please use the form below to finish setting up your account.</h3>
            <div class="account-form">
                <label class="field">
                    <span class="field__label">First Name</span>
                    <input class="field__input" type="text" v-model="userForm.firstName" />
                </label>
                <label class="field">
                    <span class="field__label">Last Name</span>
                    <input class="field__input" type="text" v-model="userForm.lastName" />
                </label>
                <label class="field">
                    <span class="field__label">New Password</span>
                    <input
                        class="field__input"
                        type="password"
                        v-model="passwordForm.newPassword1"
                    />
                </label>
                <label class="field">
                    <span class="field__label">Verify Password</span>
                    <input
                        class="field__input"
                        type="password"
                        v-model="passwordForm.newPassword2"
                    />
                </label>
                <loading-spinner-button
                    type="submit"
                    class="button"
                    text="Submit"
                    :loading="passwordForm.saving"
                    @click="submitForm"
                ></loading-spinner-button>
            </div>
        </div>

        <div v-if="linkInvalid">
            <h1>Link Expired or Invalid</h1>
            <p>
                It looks like this 'magic login' link is expired or invalid. Please return to the
                <router-link :to="{ name: 'Home' }">login page</router-link>. If you need to reset
                your password, you can do that from the login page as well.
            </p>
        </div>
    </div>
</template>

<script>
import Auth from '@/services/auth';
import Admin from '@/services/admins';

import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';

export default {
    name: 'MagicLink',
    components: {
        LoadingSpinnerButton
    },
    data() {
        return {
            linkInvalid: false,
            registering: true,
            passwordForm: {
                newPassword1: '',
                newPassword2: '',
                saving: false
            },
            userForm: {
                firstName: '',
                lastName: ''
            }
        };
    },
    created() {
        const userId = this.$route.query.uid;
        const magicToken = this.$route.query.token;
        // Call endpoint with the user's ID and magic token
        Auth.magicLinkAuth(userId, magicToken)
            .then(data => {
                // Update Vuex store with user creds and info
                this.$store.commit('UPDATE_TOKEN', data.token);
                this.$store.commit('UPDATE_USER', {
                    ...Admin.fromAPI(data)
                });
                this.registering = false;
            })
            .catch(error => {
                // On failure, show failure message
                this.linkInvalid = true;
                throw error;
            });
    },
    computed: {
        // simple validation rules to block submission with.
        // the BE has more specific rules (like password length) which will be caught and logged during submission
        validation() {
            return {
                firstName: {
                    valid: this.userForm.firstName.trim() !== '',
                    message: 'First name is required.'
                },
                lastName: {
                    valid: this.userForm.lastName.trim() !== '',
                    message: 'Last name is required.'
                },
                password: {
                    valid:
                        this.passwordForm.newPassword1.trim() !== '' &&
                        this.passwordForm.newPassword2.trim() !== '' &&
                        this.passwordForm.newPassword1 === this.passwordForm.newPassword2,
                    message: "Passwords missing or don't match."
                }
            };
        }
    },
    methods: {
        async submitForm() {
            this.passwordForm.saving = true;
            try {
                const invalidFields = Object.values(this.validation).filter(field => !field.valid);

                if (invalidFields.length) {
                    const validationMessages = invalidFields
                        .map(field => field.message)
                        .join('<br />');

                    this.$Alert.alert({
                        type: 'error',
                        message: `<h3>Error...</h3><br />${validationMessages}`,
                        timeout: 3000
                    });
                } else {
                    await Auth.changePassword({
                        oldPassword: '',
                        newPassword1: this.passwordForm.newPassword1,
                        newPassword2: this.passwordForm.newPassword2
                    });

                    // Update user's first and last name
                    let user = this.$store.state.user;
                    user = Object.assign(
                        {},
                        {
                            ...user,
                            ...this.userForm
                        }
                    );

                    let admin = new Admin(user);
                    const updatedUser = await Admin.api.update(admin);
                    this.$store.commit('UPDATE_USER', {
                        ...Admin.fromAPI(updatedUser)
                    });
                    // Navigate to next page
                    this.$router.push({ name: 'Home', query: { showSettingsModal: true } });
                }
            } catch (err) {
                let msg = null;
                if (err.new_password1 != null) {
                    msg = err.new_password1;
                } else if (err.new_password2 != null) {
                    msg = err.new_password2;
                }

                if (msg != null) {
                    this.$Alert.alert({
                        type: 'error',
                        message: `<h3>Error...</h3><br />${msg}`,
                        timeout: 3000
                    });
                }
            } finally {
                this.passwordForm.saving = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins/buttons.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.account-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.button {
    @include base-button();
    margin-top: 1rem;
    align-self: center;
    padding: 1rem;
    width: 50%;
}
</style>
