import axios from 'axios';

import { API_BASE, apiErrorHandler } from '@/services/api';
import Auth from '../auth';

const STAFF_ENDPOINT = `${API_BASE}staff/`;

export default class StaffAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new StaffAPI(cls);
    }

    async retrieve(id) {
        const url = `${STAFF_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .get(url, options)
            .then(res => this.cls.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Staff Api Error' }));

        return promise;
    }

    async update(userId, school) {
        const url = `${STAFF_ENDPOINT}${userId}/`;
        const data = { school: school.id };
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .patch(url, data, options)
            .then(res => this.cls.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Staff API Update Error' }));

        return promise;
    }
}
