<template>
    <div class="container">
        <h1 class="dashboard__title">My EdSights</h1>
        <div class="dashboard__row">
            <div class="dashboard__elem-wrapper">
                <div class="dashboard__elem box-no-padding">
                    <StudentRisks
                        :isLoading="summaryStatsLoading"
                        :summary="summaryStatsFromCache"
                        :showMoreTo="{ name: 'AnalysisStudents' }"
                    />
                </div>
            </div>
            <div class="dashboard__elem-wrapper">
                <div class="dashboard__elem box-no-padding">
                    <InterventionsWidget
                        :interventions="interventions"
                        :showMoreTo="{ name: 'InterventionsPage' }"
                        :pageName="'Dashboard'"
                    />
                </div>
            </div>
            <div class="dashboard__elem-wrapper">
                <div class="dashboard__elem box-no-padding">
                    <Insights :insights="insights" :showMoreTo="{ name: 'InsightsPage' }" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import StudentRisks from '@/components-deprecated/overview/StudentRisks';
import InterventionsWidget from '@/components-deprecated/overview/InterventionsWidget';
import Insights from '@/components-deprecated/overview/Insights';
import Insight from '@/services/insights';
import { listInterventionTemplates } from '@/api/intervention-template';
import { mapState, useStore } from 'vuex';
import { useSummaryStats } from '@/queries/summaryStats';

export default {
    name: 'Overview',
    components: {
        StudentRisks,
        InterventionsWidget,
        Insights
    },
    setup() {
        const store = useStore();
        const user = computed(() => store.getters.user);
        const school = user.value.school;

        const { data, isLoading } = useSummaryStats({ school });

        return {
            summaryStatsFromCache: data,
            summaryStatsLoading: isLoading
        };
    },
    data() {
        return {
            loading: true,
            insights: [],
            interventions: [],
            academicRiskDriver: 0,
            engagementRiskDriver: 0,
            financialRiskDriver: 0,
            wellnessRiskDriver: 0,
            lowRisk: 0,
            mediumRisk: 0,
            highRisk: 0,
            lowRiskScores: [],
            mediumRiskScores: [],
            highRiskScores: []
        };
    },
    computed: {
        ...mapState(['user'])
    },
    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            this.loading = true;

            try {
                await Promise.all([this.fetchInsights(), this.fetchInterventions()]);
            } finally {
                this.loading = false;
            }
        },
        async fetchInsights() {
            this.insights = (await Insight.api.list({ expired: false })).results;
        },
        async fetchInterventions() {
            // Get all Intervention Templates associated with the user's school
            const interventionTemplatesResponse = await listInterventionTemplates({
                filters: {
                    limit: 25
                }
            });
            this.interventions = interventionTemplatesResponse;
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    max-width: 1400px;
    padding: 25px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.dashboard {
    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__title {
        margin-top: 10px;
        margin-bottom: 25px;
    }

    &__elem-wrapper {
        width: 31%;
        height: calc(100vh - 200px);
        margin-bottom: 20px;
    }

    &__elem {
        overflow-y: scroll;
        height: 100%;
        min-height: 250px;
        width: calc(100% + 15px);
    }
}

@media screen and (max-width: 1150px) {
    .dashboard {
        &__elem-wrapper {
            width: 49%;
        }
    }
}

@media screen and (max-width: 760px) {
    .dashboard {
        &__elem-wrapper {
            width: 100%;
            height: unset;
        }
    }
}
</style>
