import Alerts from '@/lib/alerts';
/**
 * Handle an error from the V2 EdSights API
 * @param {object} options - Options object.
 * @param {object} options.error - Error object caught while making an API request.
 * @param {boolean} options.rethrow - Causes error to be rethrown when true.
 * @param {boolean} options.enableAlert - Causes a Vue alert to be triggered when true.
 * @param {string} options.overrideMessage - Message to override response message and fallback message.
 * @param {string} options.fallbackMessage - Message to display if no override message and no response message present.
 * @param {string} options.messagePrefix - Prefix that will show before the message. A line break is placed between the prefix and message.
 */
export const v2ApiErrorHandler = ({
    error,
    rethrow = false,
    enableAlert = true,
    overrideMessage = '',
    fallbackMessage = 'Something went wrong.',
    messagePrefix = ''
}) => {
    if (enableAlert) {
        const message = `${messagePrefix ? messagePrefix + '</br>' : ''}${overrideMessage ||
            getResponseMessage(error) ||
            fallbackMessage}`;
        Alerts.alert({
            type: 'error',
            timeout: 10000,
            message: `<div role=status>${message}</div>`
        });
    }

    // eslint-disable-next-line no-console
    console.error(error);

    if (rethrow) {
        throw error;
    }
};

const getResponseMessage = error => {
    if (
        typeof error === 'object' &&
        typeof error.response === 'object' &&
        typeof error.response.data === 'object' &&
        typeof error.response.data.message === 'string'
    ) {
        return error.response.data.message;
    }

    return null;
};
