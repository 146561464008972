import students from '@/services/students';
import Admin from '@/services/admins';
import Staff from '@/services/staff';

export function refreshProfile({ commit, state }) {
    if (state.user.isAdmin && !state.user.isStaff) {
        Admin.api.retrieve(state.user.id).then(data => {
            commit('UPDATE_USER', {
                ...data
            });
        });
    } else if (state.user.isStaff) {
        Staff.api.retrieve(state.user.id).then(data => {
            commit('UPDATE_USER', {
                ...data
            });
        });
    }
}

export function updateUserRequest({ commit, state }, object) {
    return Admin.api.update({ ...state.user, ...object }).then(data => {
        commit('UPDATE_USER', {
            ...data
        });
    });
}
