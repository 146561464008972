<template>
    <svg
        :viewBox="viewBox"
        :style="{
            height: height,
            fill: this.color,
            color: this.color,
            verticalAlign: 'bottom'
        }"
        ref="container"
    >
        <use :xlink:href="`#icon-${name}`"></use>
    </svg>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true
        },
        height: {
            type: String,
            required: false
        },
        color: {
            type: String,
            default: null
        },
        viewBox: {
            type: String,
            default: '0 0 24 24'
        }
    }
};
</script>
