<template>
    <div class="full-calendar-container">
        <FullCalendar_ :options="calendarOptions" />
    </div>
</template>

<script>
import FullCalendar_ from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

export default {
    name: 'FullCalendar',
    components: { FullCalendar_ },
    props: {
        events: {
            type: Array,
            required: true,
            validator: value => {
                return value.every(
                    item =>
                        item &&
                        typeof item === 'object' &&
                        typeof item.id === 'string' &&
                        typeof item.title === 'string' &&
                        typeof item.date === 'string' &&
                        (!item.backgroundColor || typeof item.backgroundColor === 'string')
                );
            }
        }
    },
    data() {
        return {};
    },
    methods: {
        dayClicked(day) {
            this.$emit('dayClick', day.date);
        },
        eventClicked(eventClickInfo) {
            // FullCalendar_ maps the provided event.id to publicId
            const id = eventClickInfo.event._def.publicId;
            this.$emit('eventClick', id);
        }
    },
    computed: {
        calendarOptions() {
            return {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                // dateClick is from interactionPlugin (whereas eventClick is standard).
                dateClick: this.dayClicked,
                eventClick: this.eventClicked,
                events: this.events
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.full-calendar-container {
}
</style>
