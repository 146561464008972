import axios from 'axios';
import { API_BASE, apiErrorHandler } from '../services/api';
import Auth from '../services/auth';

const faqTopicEndpoint = `${API_BASE}faqs/`;

export const listFaqs = school => {
    let listFaqsEndpoint = `${API_BASE}faqs/`;
    const options = {
        headers: {
            'Content-Type': 'application/json',
            ...Auth.getHeaders().headers
        }
    };

    if (school) {
        listFaqsEndpoint = `${faqTopicEndpoint}?for_school=${school}`;
    }

    return axios
        .get(`${listFaqsEndpoint}`, options)
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'List FAQ error'
            })
        );
};
