<template>
    <modal
        classes="v--modal"
        name="modal-select-tag"
        height="auto"
        width="600"
        :minWidth="320"
        :scrollable="true"
        :clickToClose="true"
        :stack="false"
        @before-open="beforeOpen"
    >
        <div class="modal">
            <header class="modal__header">
                <h2 class="modal__header__title">Select Student Tag</h2>
            </header>
            <main class="modal__content">
                <TextInput
                    class="search-input"
                    @update:modelValue="searchTags"
                    placeholder="Search tags"
                    iconType="search"
                    v-model="inputTag"
                />
                <div class="modal__tags">
                    <LoadingSpinner
                        v-if="!initialTagCategoriesLoaded || tagCategories.refreshing"
                    />
                    <template v-else>
                        <span v-for="(tagCategory, id) in tagCategoryList" :key="id">
                            <span class="tag-category">
                                {{ tagCategory.name }}
                            </span>
                            <div
                                :key="tag.id"
                                v-for="tag in tagCategory.tagsRef"
                                class="tag-item"
                                @click="onSelect(tag)"
                            >
                                <span>
                                    {{ tag.name }}
                                </span>
                            </div>
                        </span>
                        <Paginator
                            v-if="tagCategories.pagination.next"
                            :loading="tagCategories.loadingNextPage"
                            @next-page="tagCategories.addNextPage()"
                        />
                    </template>
                </div>
            </main>
        </div>
    </modal>
</template>

<script>
import CollectionManager from '@/services/collectionManager';
import TagCategories from '@/services/tagCategories';
import Paginator from '@/components-deprecated/Paginator';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import { debounce } from '@/services/debounce';
import TextInput from '@/components-deprecated/global/TextInput';

export default {
    components: {
        TextInput,
        LoadingSpinner,
        Paginator
    },
    config: {
        silent: true
    },
    data() {
        return {
            loading: false,
            tagCategories: CollectionManager.create({
                ModelClass: TagCategories,
                v2: true,
                slim: true
            }),
            inputTag: '',
            initialTagCategoriesLoaded: false,
            tagResource: null
        };
    },
    mounted() {
        this.onTagSearch = debounce(this.onTagSearch, 500);
    },
    computed: {
        tagCategoryList() {
            if (typeof this.tagCategories === 'object' && Array.isArray(this.tagCategories.list)) {
                return this.tagCategories.list.filter(
                    tc =>
                        typeof tc === 'object' && Array.isArray(tc.tagsRef) && tc.tagsRef.length > 0
                );
            }

            return [];
        }
    },
    methods: {
        beforeOpen(event) {
            if (!this.initialTagCategoriesLoaded) {
                this.loadInitialTagCategories();
            }
            this.resetSearch();
            this.tagResource = event.params.tagResource;
            this.updateHandler = event.params.updateHandler ? event.params.updateHandler : () => {};
        },
        // loads the initial list (first page) of tag categories
        async loadInitialTagCategories() {
            await this.tagCategories.refresh();
            this.initialTagCategoriesLoaded = true;
        },
        onSelect(tag) {
            this.tagResource.tagId = tag.id;
            this.tagResource.tagName = tag.name;
            this.$modal.hide('modal-select-tag');
        },
        searchTags(value) {
            if (typeof value === 'string' && value.length) {
                this.tagCategories.filters.search = value;
            } else {
                this.tagCategories.filters.search = null;
            }
            this.tagCategories.refresh();
        },
        // Resets tagCategories and the search term.
        // This way, this modal will not show the previous search results if opened again after a search.
        // This is needed since "closing" this modal just hides it - all data remains intact unless cleared.
        resetSearch() {
            if (typeof this.tagCategories.filters.search === 'string') {
                this.tagCategories.filters.search = null;
                this.tagCategories.refresh();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins/lists';

@import '../../styles/views/modal-small';

.modal {
    padding: 2rem 3rem;
    &__tags {
        height: 300px;
        max-height: 300px;
        border: 2px solid $secondary-gray;
        border-radius: 5px;
        overflow-y: scroll;
        padding: 10px 10px;
    }

    &__header {
        padding: 5px 20px;
        color: $white;
        background-color: $white;
        margin-bottom: 0;
        &__title {
            font-size: 1.7rem;
        }
    }

    &__content {
        padding: 10px 20px;

        &__link {
            margin: 15px 0;
            font-weight: bold;
            cursor: pointer;
            display: inline-block;
        }

        .search-input {
            padding-bottom: 1rem;
        }
    }

    &__btn {
        margin: 15px auto 0 auto;
    }

    &__subtitle {
        margin-bottom: 5px;
    }
}

.tag-category {
    font-weight: bolder;
    font-family: Poppins-SemiBold;
    text-transform: uppercase;
    font-size: 1.1rem;
    padding: 1rem;
}
.tag-item {
    padding-left: 20px;
}
.tag-item:hover {
    background-color: #eef9ff;
}
</style>
