export const DYNAMIC_MODAL_DISABLED_ERROR =
    '[vue-js-modal] ' +
    '$modal() received object as a first argument, but dynamic modals are ' +
    'switched off. https://github.com/euvl/vue-js-modal/#dynamic-modals';

export const UNSUPPORTED_ARGUMENT_ERROR =
    '[vue-js-modal] ' + '$modal() received an unsupported argument as a first argument.';

export const HIDE_ALL_RESTRICTION_ERROR =
    '[vue-js-modal] ' +
    '$modal.hideAll() call will be ignored because dynamic modals are not enabled.';
