<template>
    <div class="large-operation-result">
        <ul>
            <li>
                <div class="result">
                    <span class="message">
                        <span class="emphasis">{{ result.value.length }}</span> {{ result.message }}
                    </span>
                    <img
                        v-if="result.value.length"
                        class="icon"
                        :src="icon"
                        alt="Show values"
                        @click="toggleShowValues"
                    />
                </div>
                <div class="values" v-if="showValues">
                    <p v-for="value in result.value">{{ value }}</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
/**
 * Display an individual result from a LargeOperation (both synchronous and asynchronous/background).
 *
 * @prop {Object} result - Each object must have the following fields:
 *    - 'name' - Unique name
 *    - 'message' - Human-readable message to be displayed by UI
 *    - 'value' - Relevant value
 *
 */
import TransitionExpand from '@/components-deprecated/TransitionExpand';
export default {
    name: 'LargeOperationResult',
    components: {
        TransitionExpand
    },
    props: {
        result: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showValues: false
        };
    },
    methods: {
        toggleShowValues() {
            this.showValues = !this.showValues;
        }
    },
    computed: {
        icon() {
            const icons = [
                require('@/assets/icons/eye-outline.svg'),
                require('@/assets/icons/eye-off-outline.svg')
            ];
            if (this.showValues) {
                return icons[0];
            } else {
                return icons[1];
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.emphasis {
    text-decoration: underline;
    font-weight: bold;
}
.result {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.icon {
    width: 2rem;
    margin-left: 1rem;
    cursor: pointer;
}
.values {
    border: 1px solid $main-gray;
    border-radius: 10px;
    padding: 1rem;
    overflow: scroll;
    max-height: 20rem;
}
</style>
