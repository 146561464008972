<template>
    <modal
        classes="v--modal"
        name="modal-large-operation-results"
        height="auto"
        width="800"
        :clickToClose="true"
        :adaptive="true"
        :scrollable="true"
        @before-open="beforeOpen"
    >
        <div class="modal">
            <h2 class="title">{{ title }}</h2>
            <div v-if="results" class="results">
                <LargeOperationResult
                    v-for="result in results"
                    :key="result.name"
                    :result="result"
                />
            </div>
            <div v-if="error">
                <p class="error-message">
                    Error from server:<span class="error-text">{{ error }}</span>
                </p>
                <p>
                    If you're unsure what to do about this error, reach out to your EdSights
                    consultant.
                </p>
            </div>
            <div class="buttons">
                <Button v-if="next" @click="onNext()"> Next </Button>
                <Button v-else @click="onDone()"> Done </Button>
            </div>
        </div>
    </modal>
</template>

<script>
/**
 * Display results from a LargeOperation (both synchronous and asynchronous/background).
 *
 * @param {Array} results - Array of objects. Each object should have the following fields:
 *    - 'name' - Unique name
 *    - 'message' - Human-readable message to be displayed by UI
 *    - 'value' - Relevant value
 *
 * @param {string} error - Error string from server.
 *
 */
import LargeOperationResult from '@/components-deprecated/LargeOperationResult';
import Button from '@/components-deprecated/Button';
export default {
    name: 'ModalLargeOperationResults',
    components: {
        LargeOperationResult,
        Button
    },
    data() {
        return {
            results: [],
            error: null,
            next: null
        };
    },
    methods: {
        beforeOpen(event) {
            this.results = event.params.results;
            this.error = event.params.error;
            this.next = event.params.next;
        },
        onNext() {
            this.$modal.hide('modal-large-operation-results');
            this.next();
        },
        onDone() {
            this.$modal.hide('modal-large-operation-results');
        }
    },
    computed: {
        title() {
            if (this.results) {
                return 'Operation complete.';
            } else if (this.error) {
                return 'Operation failed.';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.results {
    margin: 1rem 0 0 1.5rem;
}
.buttons {
    display: flex;
    justify-content: flex-end;
}
.error-message {
    margin: 1rem 0;
}
.error-text {
    margin: 0 1rem;
    color: $amaranth;
}
</style>
