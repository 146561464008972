import moment from 'moment';
import { LOW_RISK, MEDIUM_RISK, HIGH_RISK } from '@/consts';

export function formattedDate(date) {
    return moment(date).format('L');
}

export function formattedDateTime(date) {
    return moment(date).format('LLL');
}

export function formatRiskLevel(level) {
    switch (level) {
        case LOW_RISK:
            return 'Low';
        case MEDIUM_RISK:
            return 'Medium';
        case HIGH_RISK:
            return 'High';
        default:
            return 'N/A';
    }
}

export function formatRiskAbbreviations(level) {
    switch (level) {
        case LOW_RISK:
            return 'L';
        case MEDIUM_RISK:
            return 'M';
        case HIGH_RISK:
            return 'H';
        default:
            return 'N/A';
    }
}
