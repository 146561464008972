import axios from 'axios';
import { strictEqual } from 'assert';

/**
 * Downloads a file from a url by getting the data, creating a new Blob, and linking to that Blob with
 * a temporary HTML anchor element, and simulating a "click" on the element. This will prompt the browser
 * to save the Blob data as a local file.
 * @param url {string} - url where file is located
 * @returns {Promise}
 */
export const downloadFileFromUrl = url => {
    strictEqual(typeof url, 'string', 'url must be a string');
    const urlParts = url.split('/');
    const fileName = decodeURIComponent(urlParts[urlParts.length - 1] || '')
        .split('?')
        .shift();
    return axios
        .get(url, {
            responseType: 'blob'
        })
        .then(response => {
            const url_dl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url_dl;
            link.setAttribute('download', fileName);
            link.click();
            link.remove();
        });
};
