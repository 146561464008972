import axios from 'axios';

import { API_BASE, apiErrorHandler } from '@/services/api';
import LargeOperation from '@/services/largeOperations';
import Auth from '../auth';

const TAG_MAPPING_ENDPOINT = `${API_BASE}/tag-mappings/`;

export default class TagMappingsAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new TagMappingsAPI(cls);
    }

    async retrieve(id) {
        const url = `${TAG_MAPPING_ENDPOINT}${id}/`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };
        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Retrieve Tag Mapping Error' }));
        return promise;
    }

    async list(params = {}) {
        const url = TAG_MAPPING_ENDPOINT;

        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            },
            params
        };

        const promise = axios
            .get(url, options)
            .then(res => res.data.results.map(this.cls.fromAPI))
            .catch(apiErrorHandler({ apiName: 'List Tag Mappings Api Error' }));

        return promise;
    }

    async createMapping(tagMapping) {
        const url = TAG_MAPPING_ENDPOINT;
        const options = {
            headers: Auth.getHeaders().headers
        };

        const data = this.cls.toAPI(tagMapping);

        const promise = axios
            .post(url, data, options)
            .then(res => this.cls.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Create Tag Mapping Api Error' }));

        return promise;
    }

    async update(tagMapping) {
        const url = `${TAG_MAPPING_ENDPOINT}${tagMapping.id}/`;
        const options = {
            headers: Auth.getHeaders().headers
        };

        const data = this.cls.toAPI(tagMapping);

        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Update Tag Mapping Api Error' }));
        return promise;
    }

    async delete(tagMappingId) {
        const url = `${TAG_MAPPING_ENDPOINT}${tagMappingId}/`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };

        const promise = axios
            .delete(url, options)
            .catch(apiErrorHandler({ apiName: 'Delete Tag Mapping Api Error' }));
        return promise;
    }

    async createTagMappingsFromCSV(csv, schoolId) {
        const url = `${TAG_MAPPING_ENDPOINT}upload-csv/`;
        const options = {
            headers: {
                ...Auth.getHeaders().headers,
                'Content-Type': 'multipart/form-data'
            }
        };
        const data = new FormData();
        data.append('file', csv);
        data.append('school_id', schoolId);

        const promise = axios
            .put(url, data, options)
            .then(res => LargeOperation.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Create Tag Mappings API error' }));

        return promise;
    }
}
