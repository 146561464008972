<template>
    <div class="container-risks">
        <BoxContainer
            title="Student Risk"
            ariaLabel="Full Page - Student Risk"
            subtitle="Review your Students' Risk Levels"
            :showMoreTo="showMoreTo"
        >
            <div class="content__container">
                <div class="text-gray content__title-chart">Risk Levels</div>
                <div class="content__chart-container">
                    <!-- RK 10/2019: commented out b/c histogram is not in -->
                    <!-- perfect state to deploy to prod -->
                    <!-- <div class="charts"> -->
                    <!-- <ChartRisks
              v-if="!isLoading"
              class="chart"
              :height="180"
              :lowRiskValues="summary.lowRiskScores"
              :mediumRiskValues="summary.mediumRiskScores"
              :highRiskValues="summary.highRiskScores"
              :lowRiskPercent="summary.lowRiskPercent"
              :mediumRiskPercent="summary.mediumRiskPercent"
              :highRiskPercent="summary.highRiskPercent"
          ></ChartRisks>-->
                    <!-- </div> -->
                </div>
                <div class="content__row charts-circle">
                    <div class="charts-circle__chart-wrapper">
                        <PieChart
                            :color="chartColors.COLOR_CHART_RISK_LOW_DARKER"
                            :value="summary.lowRiskPercent"
                            :ariaLabelTitle="getRiskLevelChartAriaTitle('low')"
                        />
                        <div class="chart-circle__title">Low</div>
                    </div>
                    <div class="charts-circle__chart-wrapper">
                        <PieChart
                            :color="chartColors.COLOR_CHART_RISK_MEDIUM_DARKER"
                            :value="summary.mediumRiskPercent"
                            :ariaLabelTitle="getRiskLevelChartAriaTitle('medium')"
                        />
                        <div class="chart-circle__title">Medium</div>
                    </div>
                    <div class="charts-circle__chart-wrapper">
                        <PieChart
                            :color="chartColors.COLOR_CHART_RISK_HIGH_DARKER"
                            :value="summary.highRiskPercent"
                            :ariaLabelTitle="getRiskLevelChartAriaTitle('high')"
                        />
                        <div class="chart-circle__title">High</div>
                    </div>
                </div>
                <div class="text-gray content__title-chart">Risk Drivers</div>
                <div class="risk-driver">
                    <p class="risk-driver__label">Academic</p>
                    <ChartProgress
                        class="risk-driver__chart"
                        :value="summary.academicRiskPercent"
                    />
                </div>
                <div class="risk-driver">
                    <p class="risk-driver__label">Engagement</p>
                    <ChartProgress
                        class="risk-driver__chart"
                        :value="summary.engagementRiskPercent"
                    />
                </div>
                <div class="risk-driver">
                    <p class="risk-driver__label">Financial</p>
                    <ChartProgress
                        class="risk-driver__chart"
                        :value="summary.financialRiskPercent"
                    />
                </div>
                <div class="risk-driver">
                    <p class="risk-driver__label">Wellness</p>
                    <ChartProgress
                        class="risk-driver__chart"
                        :value="summary.wellnessRiskPercent"
                    />
                </div>
            </div>
            <LoadingSpinner v-if="isLoading" />
        </BoxContainer>
    </div>
</template>

<script>
import PieChart from '@/components-deprecated/charts/PieChart';
import ChartProgress from '@/components-deprecated/charts/ChartProgress';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import BoxContainer from '@/components-deprecated/BoxContainer';
import { getColors } from '@/consts/colors';
import { initialSummaryStats } from '@/api/school';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'StudentRisks',
    components: {
        PieChart,
        ChartProgress,
        LoadingSpinner,
        BoxContainer
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        summary: {
            type: Object,
            default: () => initialSummaryStats()
        },
        showMoreTo: {
            type: Object,
            default: null
        }
    },
    methods: {
        getRiskLevelChartAriaTitle(riskLevel) {
            return `Pie chart showing the percentage of your students with a ${riskLevel} risk level:`;
        },
        getRiskDriverChartAriaTitle(riskDriver) {
            return `Progress bar representing the percentage of your students with the ${riskDriver} risk driver. :`;
        }
    },
    computed: {
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        chartColors() {
            return getColors(this.isExperimentalAccessibilityEnabled);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header {
    font-size: 24px;
    cursor: pointer;
    color: black;
    display: flex;
    border-bottom: 1px solid transparent;
}

.content {
    &__container {
        margin-top: 25px;
        padding: 0 2rem;
    }

    &__subtitle {
        margin: 20px 0;
    }

    &__title-chart {
        margin-bottom: 10px;
    }

    &__chart-container {
        margin-bottom: 20px;
        text-align: center;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        max-width: 835px;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    &__text {
        font-size: 15px;
        width: calc(100% - 45px);
    }

    &__icon {
        fill: #068bff;
        margin-right: 20px;
        align-self: center;
    }

    &__row-left-part {
        display: flex;
        width: calc(100% - 140px);
    }

    &__row-right-part {
        font-size: 15px;
        width: 120px;
    }
}

.text-highlight {
    font-weight: bold;
}

.charts-circle {
    margin: 15px auto;
    margin-bottom: 30px;
    max-width: 440px;
}

.chart-circle {
    margin: 0 auto;

    &__title {
        margin-top: 15px;
        text-align: center;
    }
}

.risk-driver {
    display: flex;
    margin: 15px 0;

    &__label {
        width: 120px;
    }

    &__chart {
        flex-grow: 1;
    }
}

.charts {
    display: flex;
    width: 100%;
    padding-bottom: 1px;
    padding-left: 2px;
    border-bottom: 2px solid $secondary-gray;
    border-left: 2px solid $secondary-gray;
}

.chart {
    margin-right: 4px;
    width: 100%;
}
</style>
