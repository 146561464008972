import * as userMutations from './user';
import * as loadingMutations from './loading';
import * as backgroundOperationMutations from './backgroundOperations';
import * as staffSchoolViewMutations from './staffSchoolView';
import * as pendingAlertCountMutations from './pendingAlertCount';

export default {
    ...userMutations,
    ...loadingMutations,
    ...backgroundOperationMutations,
    ...staffSchoolViewMutations,
    ...pendingAlertCountMutations
};
