<template>
    <div
        class="dropdown-container"
        ref="combobox"
        role="combobox"
        tabindex="0"
        :aria-label="label || ariaLabel"
        :aria-labelledby="ariaLabelledBy"
        :aria-controls="menuContainerId"
        :aria-expanded="showMenu.toString()"
        @focusout="handleFocusOut"
        @keydown.down.prevent="onDownArrowKey"
        @keydown.up.prevent="onUpArrowKey"
        @keydown.space.prevent="onSpaceKey"
        @keydown.enter.prevent="onEnterKey"
        @keydown.esc.prevent="onEscKey"
    >
        <label>{{ label }}</label>
        <div class="menu-trigger-wrapper" @click.stop="openMenu()">
            <slot name="custom-menu-trigger">
                <!-- custom-menu-trigger template renders here, if provided -->

                <!-- Standard  element (discarded by Vue when a custom-menu-trigger template is provided) -->
                <div class="default-menu-trigger">
                    <label> {{ selectedOptionLabel }} </label>
                    <div>
                        <svg
                            width="10px"
                            height="10px"
                            class="arrow-icon"
                            :class="{ 'menu-open': showMenu }"
                            viewBox="0 0 5.9 17.51"
                        >
                            <use xlink:href="#icon-arrow-right-wide" />
                        </svg>
                    </div>
                </div>
            </slot>
        </div>
        <div
            :class="['menu-container', menuContainerAlignmentClass]"
            :id="menuContainerId"
            v-if="showMenu"
        >
            <div class="menu-item-container" ref="listbox" role="listbox">
                <div
                    :key="option.value"
                    v-for="option in renderedOptions"
                    class="menu-item"
                    :class="{
                        active: option[optionValueKey] === selectedOptionValue,
                        disabled: option.disabled
                    }"
                    tabindex="0"
                    @click.stop="onOptionClick(option)"
                    role="option"
                    :id="`option-${option[optionValueKey]}`"
                >
                    {{ option[optionLabelKey] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue';
import { v4 } from 'uuid';

export default {
    name: 'DropdownV2',
    props: {
        options: {
            type: Array,
            required: true
        },
        selectedOptionValue: {
            required: false,
            validator(value) {
                return (
                    typeof value === 'string' ||
                    typeof value === 'boolean' ||
                    typeof value === 'number'
                );
            }
        },
        // key from option objects that should be used as a label
        optionLabelKey: {
            type: String,
            default: 'label'
        },
        // key from option objects that should be used as a value
        optionValueKey: {
            type: String,
            default: 'value'
        },
        label: {
            type: String,
            required: false
        },
        ariaLabel: {
            type: String,
            required: false
        },
        ariaLabelledBy: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            showMenu: false,
            menuContainerId: `menu-container-${v4()}`
        };
    },
    methods: {
        openMenu() {
            if (!this.disabled) {
                this.showMenu = true;
            }
        },
        closeMenu() {
            this.showMenu = false;
        },
        onOptionClick(option) {
            if (option.disabled !== true) {
                this.onOptionSelect(option);
                this.showMenu = false;
            }
        },
        onOptionSelect(option) {
            if (option.disabled !== true) {
                this.$emit('onChange', option);
            }
        },
        handleFocusOut(event) {
            // If the target receiving focus (event.relatedTarget) is not within this.$el, the click
            // was outside.
            if (this.showMenu && !this.$el.contains(event.relatedTarget)) {
                this.showMenu = false;
            }
        },
        getActiveElement() {
            return document.activeElement;
        },
        focusFirstOption() {
            nextTick(() => {
                const listbox = this.$refs.listbox;
                if (listbox && listbox.children[0]) {
                    listbox.children[0].focus();
                }
            });
        },
        focusNextOption() {
            const activeElement = this.getActiveElement();
            const nextElement = activeElement.nextElementSibling;

            if (nextElement) {
                nextElement.focus();
            }
        },
        focusPreviousOption() {
            const activeElement = this.getActiveElement();
            const previousElement = activeElement.previousElementSibling;

            if (previousElement) {
                previousElement.focus();
            }
        },
        focusCombobox() {
            this.$refs.combobox.focus();
        },
        isComboboxFocused() {
            const comboboxElement = this.$refs.combobox;
            return document.activeElement === comboboxElement;
        },
        openMenuAndFocusFirstOption() {
            if (!this.showMenu) {
                this.openMenu();

                // Add a delay so a screen reader announces that the menu is open, then
                // separately announces that the first option is focused. Note: Vue's $nextTick
                // is too fast for this scenario, hence the plain setTimeout.
                setTimeout(() => {
                    this.focusFirstOption();
                }, 100);
            }
        },
        onDownArrowKey() {
            if (!this.showMenu) {
                this.openMenuAndFocusFirstOption();
                return;
            }

            const comboboxFocused = this.isComboboxFocused();
            if (comboboxFocused) {
                this.focusFirstOption();
                return;
            }

            this.focusNextOption();
        },
        onSpaceKey() {
            if (!this.showMenu) {
                this.openMenuAndFocusFirstOption();
            }
        },
        onUpArrowKey() {
            if (this.showMenu) {
                this.focusPreviousOption();
            }
        },
        onEnterKey() {
            const activeElement = this.getActiveElement();
            const id = activeElement.id;
            const value = id.replace('option-', '');
            const option = this.options.find(
                // All values get converted to strings when bound to "id" in the template.
                // Here we ensure we're comparing strings, in case the provided optionValueKey points
                // to a non-string that was stringified in the template.
                option => option[this.optionValueKey].toString() === value
            );

            this.onOptionSelect(option);
            this.closeMenu();
            this.focusCombobox();
        },
        onEscKey() {
            if (this.showMenu) {
                this.closeMenu();

                this.focusCombobox();
            }
        },
        // For use by parent components that reference this component as a ref
        // so focus can be triggered externally.
        focus() {
            this.$refs.combobox.focus();
        }
    },
    computed: {
        renderedOptions() {
            if (Array.isArray(this.options)) {
                return this.options;
            }

            return [];
        },
        menuContainerAlignmentClass() {
            if (this.alignMenu === 'left' || this.alignMenu === 'right') {
                return `align-${this.alignMenu}`;
            }

            return 'align-left';
        },
        selectedOptionLabel() {
            if (['string', 'number', 'boolean'].includes(typeof this.selectedOptionValue)) {
                return this.options.find(
                    option => option[this.optionValueKey] === this.selectedOptionValue
                )[this.optionLabelKey];
            }

            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.dropdown-container {
    position: relative;
    font-family: $base-font-family;

    outline: 0;
    &:focus {
        .menu-trigger-wrapper {
            outline: 2px solid $edsights-blue;
        }
    }
    .menu-trigger-wrapper {
        cursor: pointer;
        border-radius: 5px;

        &.disabled {
            cursor: default;
            .default-menu-trigger {
                label {
                    cursor: default;
                }
            }
        }
        .default-menu-trigger {
            display: flex;
            height: 32px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: $white-blue;
            padding: 0.25rem 1rem;
            border-radius: 5px;

            label {
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.disabled {
                    cursor: default;
                }

                &.useStyledLabel {
                    color: $main-gray;
                }
            }

            .arrow-icon {
                transform: rotate(90deg);

                &.menu-open {
                    transform: rotate(270deg);
                }
            }
        }
    }
    .menu-container {
        @include box-shadow;
        position: absolute;
        z-index: 1000;
        background-color: $white;

        min-width: 16rem;

        &.align-left {
            left: 0;
        }

        &.align-right {
            right: 0;
        }

        .search-container {
            padding: 0.75rem;
        }
        .menu-item-container {
            max-height: 25rem;
            overflow-y: auto;
            padding: 2px;
            .menu-item {
                cursor: pointer;
                padding: 0.75rem;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover:not(.disabled),
                &.active {
                    background-color: $white-blue;
                }

                &.disabled {
                    color: gray;
                    cursor: default;
                }

                &:focus {
                    outline: 2px solid $edsights-blue;
                }
            }
        }
    }
}
</style>
