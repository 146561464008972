<template>
    <div class="login">
        <div class="login__top">
            <img class="logo" src="../../assets/edsights-logo.png" alt="EdSights logo" />
            <h1>Log in to your account</h1>
        </div>
        <div class="login__bottom">
            <div class="login__form-box box">
                <LoginForm
                    v-if="showLoginForm"
                    :loading="loginLoading"
                    @submitLogin="handleLoginFormSubmit"
                />
                <MfaForm
                    v-if="showMfaForm"
                    :loading="mfaLoading"
                    :phoneNumber="mfaUserPhoneNumber"
                    @submitMfa="handleMfaFormSubmit"
                />
            </div>
        </div>
        <div class="login-background"></div>
    </div>
</template>

<script>
import { login, verifyMfa } from '@/api/authentication';
import LoginForm from '@/views/authentication/LoginForm';
import MfaForm from '@/views/authentication/MfaForm';

export default {
    name: 'Login',
    components: {
        MfaForm,
        LoginForm
    },
    data() {
        return {
            showLoginForm: true,
            showMfaForm: false,
            mfaUserId: '',
            mfaUserPhoneNumber: '',
            loginLoading: false,
            mfaLoading: false
        };
    },
    methods: {
        async handleLoginFormSubmit(loginForm = {}) {
            this.loginLoading = true;
            const loginResponse = await login({
                email: loginForm.email,
                password: loginForm.password,
                errorHandlerOptions: { rethrow: false, enableAlert: true }
            });
            this.loginLoading = false;

            if (typeof loginResponse !== 'object' || typeof loginResponse.user !== 'object') {
                return;
            }

            const { user, mfaRequired } = loginResponse;
            if (mfaRequired) {
                this.showLoginForm = false;
                this.showMfaForm = true;
                this.mfaUserId = user.id;
                this.mfaUserPhoneNumber = user.phoneNumber;
            } else {
                this.onAfterAuthentication(user);
            }
        },
        async handleMfaFormSubmit({ mfaCode }) {
            this.mfaLoading = true;
            const user = await verifyMfa({
                userId: this.mfaUserId,
                mfaCode,
                errorHandlerOptions: { rethrow: false, enableAlert: true }
            });
            this.mfaLoading = false;

            if (typeof user === 'object') {
                this.onAfterAuthentication(user);
            }
        },
        onAfterAuthentication(user) {
            if (typeof user !== 'object') {
                return;
            }

            // Set user and token in the store
            this.$store.commit('UPDATE_USER', user);
            this.$store.commit('UPDATE_TOKEN', user.token);

            // Redirect to next page
            if (this.$route.query.redirect) {
                this.$router.replace({ path: this.$route.query.redirect });
            } else {
                this.$router.push({ name: 'Home' });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/login';
</style>
