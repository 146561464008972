import * as Api from '@/api/index';
import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';

export const login = async ({ email, password, errorHandlerOptions = {} }) => {
    try {
        const url = Api.getv2ApiBase(`/login/`);

        const response = await axios.post(url, { email, password });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};

export const verifyMfa = async ({ userId, mfaCode, errorHandlerOptions = {} }) => {
    try {
        const url = Api.getv2ApiBase(`/mfa/`);

        const response = await axios.post(url, { userId, mfaCode });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            ...errorHandlerOptions
        });
    }
};
