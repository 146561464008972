/**
 * @module       utils
 * @description  Utility functions.
 *
 * @author  William Huster <william@aspire.is>
 */

const Utils = {
    toSnakeCase,
    toCamelCase,
    objectToCamelCase,
    objectToSnakeCase,
    isObject,
    isDefined,
    formatDateShort,
    formatNumberAsUSD
};

export default Utils;

/**
 * True/False, the given value is defined.
 **/
export function isDefined(value) {
    return typeof value !== 'undefined';
}

/**
 * toSnakeCase
 * Transform a string value from `camelCase` style notation to `snake_case` notation.
 * This is useful for translating Python-serialized JSON objects to JavaScript objects.
 */
export function toSnakeCase(value) {
    let upperChars = value.match(/([A-Z])/g);

    if (!upperChars) {
        return value;
    }

    let str = value.toString();
    for (let i = 0, n = upperChars.length; i < n; i++) {
        str = str.replace(new RegExp(upperChars[i]), '_' + upperChars[i].toLowerCase());
    }

    if (str.slice(0, 1) === '_') {
        str = str.slice(1);
    }

    return str;
}

/**
 * Transform a string value from `snake_case` style notation to `camelCase` notation.
 * This is useful for translating Python-serialized JSON objects to JavaScript objects.
 *
 * @param {String} value - The string value to transform.
 */
export function toCamelCase(value) {
    if (value === value.toUpperCase()) return value;

    return value
        .split('_')
        .map(function(word, index) {
            // Do nothing with the first word
            if (index === 0) {
                return word;
            }
            // If it is not the first word only upper case the first char and lowercase the rest.
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join('');
}

/**
 * Check whether a value is an Object
 */
export function isObject(value) {
    return value !== null && value instanceof Object && !Array.isArray(value);
}

/**
 * Check whether a value is an Object or Array
 */
export function isObjectOrArray(value) {
    return value !== null && value instanceof Object;
}

/**
 * Transform the string-based keys of a JavaScript object to `camelCase` style notation.
 * This is useful for translating the style of object keys after making an API call to
 * the Python-based API, which uses `snake_case` style notation by default.
 *
 * Works on both objects, arrays, and any combination of nested objects and arrays.
 */
export function objectToCamelCase(obj) {
    if (isObject(obj)) {
        return Object.keys(obj).reduce((acc, snakeKey) => {
            const camelKey = toCamelCase(snakeKey);
            acc[camelKey] = isObjectOrArray(obj[snakeKey])
                ? objectToCamelCase(obj[snakeKey])
                : obj[snakeKey];
            return acc;
        }, {});
    }
    if (Array.isArray(obj)) {
        return obj.reduce((acc, val, index) => {
            acc[index] = isObjectOrArray(val) ? objectToCamelCase(val) : val;
            return acc;
        }, []);
    }
}

/**
 * Transform the string-based keys of a JavaScript object to `snake_case` style notation.
 * This is useful for translating the `camelCase` style of JavaScript keys BEFORE posting
 * the data to the Python-based API, which uses `snake_case` style notation by default.
 */
export function objectToSnakeCase(value) {
    if (isObject(value)) {
        return Object.keys(value).reduce((acc, camelKey) => {
            const snakeKey = toSnakeCase(camelKey);
            acc[snakeKey] = isObject(value[camelKey])
                ? objectToSnakeCase(value[camelKey])
                : value[camelKey];
            return acc;
        }, {});
    }
}

/**
 *@function       debounce
 *@description    delay calling of callback function on set time
 *@params         callback <Function> : a function is being returned; time <Number> : delay time in milliseconds
 *@returns        {Function}
 */

export function debounce(callback, time) {
    let timer = null;
    return function(...args) {
        const onComplete = () => {
            callback.apply(this, args);
            timer = null;
        };

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(onComplete, time);
    };
}

/**
 * Returns a alphabetically sorted array of Objects, sorted by a 'property' (String) and excluding a specified 'exception' (String)
 */
export function sortAlphabetically(arr, property, exception = null) {
    arr.sort(function(a, b) {
        if (a[property] !== exception && b[property] !== exception) {
            var textA = a[property].toUpperCase();
            var textB = b[property].toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        }
    });
    return arr;
}

/**
 * Format a number as US Dollars
 *
 * @param {String, Number} value - The value to format as US Dollars
 */
export function formatNumberAsUSD(value) {
    // Ignore falsey values, except zero, because zero dollars is OK!
    if (value !== 0 && !value) return '';
    return Number(value).toLocaleString('en', {
        style: 'currency',
        currency: 'USD'
    });
}

/**
 * Format a Date string or object.
 *
 * Returns 'N/A' if a falsey value is given.
 *
 * @param {String, Date} value - The date string or object to format.
 */
export function formatDateShort(value) {
    if (!value) return 'N/A';
    const date = new Date(value);
    return date.toLocaleDateString(['en-US'], {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
    });
}
