<template>
    <div class="score-progress-container">
        <div class="score-container">
            <Score
                :score="score"
                :comparedScore="comparedScore"
                :showGradient="showScoreGradient"
            />
        </div>
        <div class="progress-container">
            <div class="compared-score" v-if="renderedComparedScore">
                <div v-if="renderedComparedScore.differenceLabelPrefix">
                    {{ renderedComparedScore.differenceLabelPrefix }}
                </div>
                <div :class="renderedComparedScore.differenceClass">
                    {{ renderedComparedScore.differenceLabel }}
                </div>
                <div>{{ renderedComparedScore.differenceLabelSuffix }}</div>
            </div>
            <ProgressBar
                class="progress-bar"
                :class="getProgressBarClass"
                :percent="progressBarData.percent"
                :markerPercent="progressBarData.markerPercent"
                showBarLabels
                minBarLabel="-100"
                maxBarLabel="100"
                :markerLabel="progressBarData.markerLabel"
            />
        </div>
    </div>
</template>

<script>
import Score from './components/Score/Score.vue';
import ProgressBar from '@/components-deprecated/global/ProgressBar.vue';

export default {
    name: 'ScoreProgress',
    props: {
        score: {
            type: Number,
            required: false
        },
        comparedScore: {
            type: Number,
            required: false
        },
        comparedScoreLabel: {
            type: String,
            default: 'Average score'
        },
        differenceLabelPrefix: {
            type: String,
            required: false
        },
        // Pass a custom suffix, otherwise the comparedScoreLabel is used as default.
        differenceLabelSuffix: {
            type: String,
            required: false
        },
        // Pass true to calculate rendered difference from compared score (comparedScore - score),
        // rather than the default (score - comparedScore).
        invertDifference: {
            type: Boolean,
            default: false
        },
        // Pass true to show score with a gradient background, colored based on the score's relative
        // value to the comparedScore. Otherwise, static default coloring is used.
        showScoreGradient: {
            type: Boolean,
            default: false
        },
        // Pass true to color the bar based on the score's relative value to
        // the comparedScore. Otherwise, static default coloring is used.
        showProgressBarColors: {
            type: Boolean,
            default: false
        }
    },
    components: { Score, ProgressBar },
    methods: {
        // ProgressBar works with percentages (empty is 0, full is 100).
        // Translate the score so that a -100 score results in 0%, and +100 score in 100%.
        getProgressBarPercentFromScore(score) {
            const svsMin = -100;
            const svsMax = 100;
            return typeof score === 'number' &&
                // Score must be between or equal to the expected min and max SVS.
                score >= svsMin &&
                score <= svsMax &&
                // Confirm we don't end up with a denominator of 0. Should be impossible, but just
                // in case the hardcoded svsMin and svsMax above are changed.
                svsMax - svsMin !== 0
                ? ((score - svsMin) / (svsMax - svsMin)) * 100
                : null;
        },
        getDifference() {
            return this.invertDifference
                ? this.comparedScore - this.score
                : this.score - this.comparedScore;
        }
    },
    computed: {
        renderedComparedScore() {
            if (
                typeof this.score === 'number' &&
                typeof this.comparedScore === 'number' &&
                this.comparedScoreLabel &&
                typeof this.comparedScoreLabel === 'string'
            ) {
                const difference = this.getDifference();
                const differenceAbsoluteValue = Math.abs(difference);

                return {
                    differenceLabel:
                        difference === 0
                            ? `Equal to`
                            : `${differenceAbsoluteValue} ${difference > 0 ? 'above' : 'below'}`,
                    differenceClass:
                        difference === 0 ? null : `${difference > 0 ? 'above' : 'below'}`,
                    comparedScoreLabel: this.comparedScoreLabel,
                    differenceLabelPrefix: this.differenceLabelPrefix,
                    differenceLabelSuffix: this.differenceLabelSuffix || this.comparedScoreLabel
                };
            }

            return null;
        },
        progressBarData() {
            const percentages = {
                percent: 0,
                markerPercent: 0,
                markerLabel: null
            };

            if (typeof this.score === 'number') {
                percentages.percent = this.getProgressBarPercentFromScore(this.score);
            }

            if (typeof this.comparedScore === 'number') {
                percentages.markerPercent = this.getProgressBarPercentFromScore(this.comparedScore);
                percentages.markerLabel = `${this.comparedScoreLabel} (${this.comparedScore.toFixed(
                    0
                )})`;
            }

            return percentages;
        },
        getProgressBarClass() {
            if (
                this.showProgressBarColors &&
                typeof this.score === 'number' &&
                typeof this.comparedScore === 'number'
            ) {
                const difference = this.score - this.comparedScore;
                if (difference > 0) {
                    return 'greater-than';
                } else if (difference < 0) {
                    return 'less-than';
                }
            }
            return '';
        }
    }
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
.score-progress-container {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 12px 0 36px 12px;
    flex-wrap: wrap;

    .score-container {
        padding-top: 20px;
    }
    .progress-container {
        display: flex;
        flex-direction: column;
        width: 50%;
        .compared-score {
            margin-bottom: 30px;
            font-weight: bold;
            .above {
                color: $green;
            }
            .below {
                color: $amaranth;
            }
        }
        .progress-bar {
            width: 100%;
            height: 12px;

            &.greater-than {
                :deep(.progress-bar-filled) {
                    background-color: #a5cdb7;
                }
            }

            &.less-than {
                :deep(.progress-bar-filled) {
                    background-color: #e0aaa0;
                }
            }
        }
    }
}
</style>
