import axios from 'axios';
import { API_BASE, apiErrorHandler } from '../services/api';
import Auth from '../services/auth';

const faqTopicEndpoint = `${API_BASE}faq-topics/`;

export const listFaqTopics = () => {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            ...Auth.getHeaders().headers
        }
    };

    return axios
        .get(`${faqTopicEndpoint}`, options)
        .then(response => {
            return response.data;
        })
        .catch(
            apiErrorHandler({
                apiName: 'List FAQ Topic error'
            })
        );
};
