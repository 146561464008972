export function updateBackgroundOperation({ commit }, backgroundOperation) {
    commit('UPDATE_BACKGROUND_OPERATION', backgroundOperation);
}

export function setBackgroundOperationCompletionAction(
    { commit },
    { backgroundOperationId, action }
) {
    commit('SET_BACKGROUND_OPERATION_COMPLETION_ACTION', { backgroundOperationId, action });
}

export function removeBackgroundOperation({ commit }, backgroundOperation) {
    commit('REMOVE_BACKGROUND_OPERATION', backgroundOperation);
}

export function removeAllBackgroundOperations({ commit }) {
    commit('REMOVE_BACKGROUND_OPERATIONS');
}
