<template>
    <div class="dropdown-container">
        <!--
      this router-link is disabled by overriding :event
      it is used only to highlight when a sub-route within parentRoute is active, but can't be clicked itself
    -->
        <router-link class="navigation__item label" :to="{ path: parentRoute }" :event="null">
            <span class="navigation__item__text">{{ menuLabel }}</span>
        </router-link>
        <div class="menu">
            <router-link
                v-for="item in menuItems"
                v-bind:key="item.label"
                class="navigation__item dropdown-item"
                :to="{ name: item.routeName }"
                @click="untoggleSchool(item.untoggleSchool)"
            >
                <span class="navigation__item__text">{{ item.label }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavigationDropDown',
    props: {
        menuLabel: String,
        menuItems: Array,
        // defining parentRoute allow the dropdown label to be highlighted when any sub-routes (in menuItems) are active
        parentRoute: String
    },
    methods: {
        untoggleSchool(untoggleSchool) {
            if (untoggleSchool) {
                this.$parent.$emit('untoggled-school');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';

.dropdown-container {
    &:hover {
        background-color: $light-gray-2;

        .menu {
            visibility: visible;
        }
    }

    &:focus-within {
        .menu {
            visibility: visible;
        }
    }

    .label {
        padding: 0 1rem;
        height: 45px;
        display: flex;
        align-items: center;
        color: white;

        &.active {
            background-color: $light-gray-2;
        }

        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 2px $edsights-blue;
        }
    }

    .menu {
        visibility: hidden;
        position: absolute;
    }

    .dropdown-item {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        height: 45px;

        background-color: $dark-gray;

        font-size: 10px;
        color: white;

        &:hover,
        &.router-link-exact-active {
            background-color: $light-gray-2;
        }
    }
}
</style>
