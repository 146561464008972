import axios from 'axios';
import { objectToCamelCase, objectToSnakeCase } from '../utils';
import { API_BASE, apiErrorHandler } from '../api';
import Auth from '../auth';
import LargeOperation from '@/services/largeOperations';

// Endpoints
const STUDENTS_ENDPOINT = `${API_BASE}students/`;
const DOWNLOAD_STUDENTS_CSV = `${API_BASE}students/download-csv/`;

export default class StudentAPI {
    /**
     * Initialize a `StudentAPI`` instance.
     *
     * @param {class} cls  The class to use to create Student objects.
     **/
    constructor(cls) {
        this.cls = cls;
    }

    /**
     * Factory method to create a new instance of `StudentAPI`.
     *
     * @param {class} cls      The class to use to create Student objects.
     **/
    static create(cls) {
        return new StudentAPI(cls);
    }

    /**
     * @function     get
     * @description  Get the data for a given student.
     * @param        id <String>  UUID of the student to get info about.
     *                                An unlimited admin can get user info about any student
     *                                or admin at their school. A limited admin can only get
     *                                info on students assigned to them.
     *                                Student users can only get info about their own user profile.
     **/
    async retrieve(id) {
        const url = `${STUDENTS_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Retrieve Student Api Error' }));

        return promise;
    }

    async list(params) {
        const url = STUDENTS_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers,
            params: objectToSnakeCase(params)
        };

        const promise = axios
            .get(url, options)
            .then(response => response.data)
            .then(data => ({
                ...data,
                results: data.results.map(this.cls.fromAPI)
            }))
            .catch(apiErrorHandler({ apiName: 'List Students API errror' }));
        return promise;
    }

    /**
     * @function      update
     * @description   Set a current user's data via the API
     * @param         student <Object> - a new student's data
     */
    async update(student) {
        const url = `${STUDENTS_ENDPOINT}${student.id}/`;
        const data = this.cls.toAPI(student);
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(
                apiErrorHandler({
                    apiName: 'Update Student API error'
                })
            );
        return promise;
    }

    async bulkUpdate(data) {
        /**
         * expects list of students or allStudents boolean
         * if allStudents boolean is true then it expects fields and the filters to apply
         *
         */
        const url = `${STUDENTS_ENDPOINT}bulk-update/`;
        let d = {};
        const options = {
            headers: await Auth.getHeaders().headers
        };
        if (data.students) {
            d['students'] = data.students.map(s => this.cls.toAPI(s));
            d['fields'] = this.cls.toAPI(data.fields);
        }
        if (data.allStudents && data.fields) {
            d['all_students'] = data.allStudents;
            d['fields'] = this.cls.toAPI(data.fields);
        }
        if (data.filters) {
            options.params = this.cls.toAPI(data.filters);
        }
        try {
            let res = await axios.patch(url, d, options);

            return LargeOperation.fromAPI(res.data);
        } catch {
            apiErrorHandler({
                apiName: 'Deactivate Students API error'
            });
        }
    }
    /**
     *  Allows admins with the appropriate permissions to deactivate students.
     *
     *  @param  studentIds Array<String> List of student ids to deactivate.
     */
    async deactivate(studentIds) {
        const url = `${STUDENTS_ENDPOINT}deactivate/`;
        const data = studentIds;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .post(url, data, options)
            .then(response => response.data)
            .catch(
                apiErrorHandler({
                    apiName: 'Deactivate Students API error'
                })
            );
        return promise;
    }

    async listEmails(params) {
        const url = `${STUDENTS_ENDPOINT}all-emails/`;
        const options = {
            headers: await Auth.getHeaders().headers,
            params: objectToSnakeCase(params)
        };

        const promise = axios
            .get(url, options)
            .then(response => response.data.map(s => objectToCamelCase(s)))
            .catch(apiErrorHandler({ apiName: 'List Student Emails API error' }));
        return promise;
    }

    /*
     * Assign a list of students (identified via email) to an admin.
     *
     * Returns: LargeOperation
     */
    async assignStudentsToAdmin(adminId, emails) {
        const url = `${STUDENTS_ENDPOINT}assign-to-admin/`;
        const data = { ...objectToSnakeCase({ adminId: adminId, emails: emails }) };
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .post(url, data, options)
            .then(res => LargeOperation.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Assign Students to Admin API error' }));
        return promise;
    }

    /*
     * Create students from a CSV.
     *
     * Returns: LargeOperation
     */
    async createStudentsFromCSV(csv, schoolId) {
        const url = `${STUDENTS_ENDPOINT}upload-csv/`;
        const options = {
            headers: {
                ...(await Auth.getHeaders().headers),
                'Content-Type': 'multipart/form-data'
            }
        };
        const data = new FormData();
        data.append('file', csv);
        data.append('school_id', schoolId);

        const promise = axios
            .put(url, data, options)
            .then(res => LargeOperation.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Create Student CSV API error' }));

        return promise;
    }

    async validateStudentCsv(csv) {
        const url = `${STUDENTS_ENDPOINT}validate-csv/`;
        const options = {
            headers: {
                ...Auth.getHeaders().headers,
                'Content-Type': 'multipart/form-data'
            }
        };
        /* eslint-disable-next-line */
    const data = new FormData()
        data.append('file', csv);

        const promise = axios
            .put(url, data, options)
            .then(res => res.data)
            .catch(apiErrorHandler({ apiName: 'Validate Student CSV API error' }));
        return promise;
    }

    /*
     * Update students from a CSV.
     *
     * Returns: LargeOperation
     */
    async updateStudentsFromCSV(csv, schoolId) {
        const url = `${STUDENTS_ENDPOINT}upload-csv/`;
        const options = {
            headers: {
                ...(await Auth.getHeaders().headers),
                'Content-Type': 'multipart/form-data'
            }
        };
        /* eslint-disable-next-line */
    const data = new FormData()
        data.append('file', csv);
        data.append('school_id', schoolId);

        const promise = axios
            .patch(url, data, options)
            .then(res => LargeOperation.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Update Student CSV API error' }));
        return promise;
    }

    /*
     * Deactivate students from a CSV.
     *
     * Returns: LargeOperation
     */
    async deactivateStudentsFromCSV(csv, schoolId, deactivationReason) {
        const url = `${STUDENTS_ENDPOINT}upload-csv/`;
        const headers = {
            ...(await Auth.getHeaders().headers),
            'Content-Type': 'multipart/form-data'
        };
        /* eslint-disable-next-line */
    const data = new FormData()
        data.append('file', csv);
        data.append('school_id', schoolId);
        data.append('deactivation_reason', deactivationReason);

        const promise = axios
            .delete(url, {
                headers: headers,
                data: data
            })
            .then(res => LargeOperation.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Delete Student CSV API error' }));
        return promise;
    }

    async getStudentListFromCsv(school, file, chatbotMode = true) {
        const url = `${STUDENTS_ENDPOINT}select-students-from-csv/`;
        const options = {
            headers: {
                ...(await Auth.getHeaders().headers),
                'Content-Type': 'multipart/form-data'
            }
        };
        const data = new FormData();
        data.append('file', file);
        data.append('school', school);
        data.append('chatbot_mode', chatbotMode);
        const promise = axios
            .post(url, data, options)
            .then(res => objectToCamelCase(res.data))
            .catch(apiErrorHandler({ apiName: 'Select Students from CSV API error' }));
        return promise;
    }

    /**
     *  Download risk data about students in a CSV.
     *
     *  Optional params:
     *    @param  studentIds Array<String> List of student ids to download.
     *    @param  params <Object> List of filters to narrow down students, if no specific studentIds are provided.
     *    @param  emailOnly <Boolean> If true, only return CSV with student emails.
     *
     *  Returns: LargeOperation
     */
    async downloadStudentData({ studentIds, tagCategories, params, emailOnly = false }) {
        const options = {
            headers: await Auth.getHeaders().headers
        };
        if (params) {
            options['params'] = objectToSnakeCase(params);
        }

        const data = objectToSnakeCase({
            studentIds: studentIds,
            tagCategories: tagCategories,
            emailOnly: emailOnly
        });
        const promise = axios
            .post(DOWNLOAD_STUDENTS_CSV, data, options)
            .then(res => LargeOperation.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Download Student CSV API error' }));

        return promise;
    }
}
