<template>
    <div class="container student" v-if="student != null">
        <StudentBlockInfo
            class="box student__part_left"
            :student="student"
            @envelope="copyEmailToClipboard"
            @onSaveButtonClicked="onSaveButtonClicked"
        />
        <div class="student__part_right">
            <ScrollWrapper class="box interventions-wrapper" v-if="interventions.length !== 0">
                <InterventionsWidget
                    :interventions="interventions"
                    :pageName="'Student Detail'"
                    :subtitle="`Interventions ${student.firstName} was mentioned in`"
                    :hasMoreItems="interventionItems.pagination.next != null"
                    :loadingNextPage="refreshing"
                    @interventions-next-page="getMoreInterventionItems()"
                />
            </ScrollWrapper>
            <Notes
                ref="notes"
                class="box student__notes"
                :notes="student.notes"
                :currentUserId="currentUser.id"
                :disableTextarea="creatingNote"
                @save="onAddNewNote"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import copy from 'copy-to-clipboard';

import StudentBlockInfo from '@/components-deprecated/StudentBlockInfo';
import InterventionsWidget from '@/components-deprecated/overview/InterventionsWidget';
import ScrollWrapper from '@/components-deprecated/ScrollWrapper';
import Notes from '@/components-deprecated/Notes';
import CollectionManager from '@/services/collectionManager';
import InterventionItem from '@/services/interventionItems';
import Note from '@/services/notes';
import Student from '@/services/students';

export default {
    name: 'StudentDetail',
    components: {
        StudentBlockInfo,
        InterventionsWidget,
        ScrollWrapper,
        Notes
    },
    data() {
        return {
            interventionItems: CollectionManager.create({
                ModelClass: InterventionItem
            }),
            student: null,
            creatingNote: false,
            refreshing: false
        };
    },
    computed: {
        ...mapState({
            currentUser: store => store.user
        }),
        isStaff() {
            return this.$store.state.user.isStaff;
        },
        staffSchool() {
            if (this.isStaff) {
                return this.$store.state.user.school;
            }
            return null;
        },
        studentId() {
            return this.$route.params.id;
        },
        interventions() {
            return this.interventionItems.list.map(ii => {
                let intervention = ii.templateRef;
                intervention.mostRecentInterventionDate = ii.dateAdded;
                return intervention;
            });
        }
    },
    watch: {
        staffSchool(val, prev) {
            if (val && this.isStaff && val !== prev) {
                this.$router.push({ name: 'AnalysisStudents' });
            }
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        async loadData() {
            this.loadInterventionItems();
            this.loadStudentData();
        },
        async loadInterventionItems() {
            this.refreshing = true;
            this.interventionItems.filters = {
                student: this.studentId
            };
            await this.interventionItems.refresh();
            this.refreshing = false;
        },
        async getMoreInterventionItems() {
            this.refreshing = true;
            await this.interventionItems.addNextPage();
            this.refreshing = false;
        },
        async loadStudentData() {
            this.loadingStudent = true;

            try {
                this.student = await Student.api.retrieve(this.studentId);
            } finally {
                this.loadingStudent = false;
            }
        },
        copyEmailToClipboard() {
            copy(this.student.email);
            this.$Alert.alert({
                type: 'success',
                message: '<h2>Email copied to your clipboard!</h2>',
                timeout: 3000
            });
        },
        async onAddNewNote(note) {
            this.creatingNote = true;

            try {
                await this.createNewNote(note);
                await this.loadStudentData();
                this.$refs.notes.clearEnteredText();
            } finally {
                this.creatingNote = false;
            }
        },
        createNewNote(noteText) {
            return Note.api.createNote({
                student: this.student.id,
                content: noteText
            });
        },
        async onSaveButtonClicked(student) {
            let editedStudentData = {
                id: this.studentId,
                first_name: student.firstName,
                last_name: student.lastName,
                student_id: student.studentId,
                phone_number: student.phoneNumber,
                opted_out_of_sms: student.optedOutOfSms
            };
            const editStudentResponse = await Student.api.update(editedStudentData);
            this.student = editStudentResponse;
        }
    }
};
</script>

<style lang="scss" scoped>
.student {
    display: flex;
}
.interventions-wrapper {
    height: 40rem;
    margin-bottom: 15px;
}
.student {
    justify-content: space-between;
    &__part_left {
        overflow-y: auto;
        width: 40%;
        max-height: calc(100vh - 80px);
    }

    &__part_right {
        width: 58%;
    }
}
</style>
