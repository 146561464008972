<template>
    <header class="header-overview">
        <div class="header-overview__title-wrapper">
            <div class="header-overview__title h3" v-if="$slots.title">
                <slot name="title"></slot>
            </div>
            <router-link
                class="header-overview__show-more text-gray"
                v-if="showMoreTo != null"
                :to="showMoreTo"
                :aria-label="ariaLabel"
            >
                Full Page
            </router-link>
        </div>
        <div class="subtitle-wrapper">
            <slot v-if="$slots.subtitle" name="subtitle"></slot>
            <div v-if="$slots['subtitle-right']" class="header-overview__show-more">
                <slot name="subtitle-right"></slot>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'OverviewHeader',

    props: {
        title: {
            type: String,
            default: null
        },
        subtitle: {
            type: String,
            default: null
        },
        showMoreTo: {
            type: Object,
            default: null
        },
        ariaLabel: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header-overview {
    &__title {
        text-transform: uppercase;
    }

    &__title-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    &__show-more {
        margin-left: auto;

        &:visited {
            color: $darker-gray;
        }
    }
}

.subtitle-wrapper {
    display: flex;
    justify-content: space-between;
}
</style>
