export const USER_PHASE = {
    ADJUSTMENT: {
        value: 'ADJUSTMENT',
        label: 'Adjustment'
    },
    GROWTH: {
        value: 'GROWTH',
        label: 'Growth'
    },
    PREPARING_TO_LEAVE: {
        value: 'PREPARING_TO_LEAVE',
        label: 'Preparing to leave'
    },
    ANNOUNCEMENTS: {
        value: 'ANNOUNCEMENTS',
        label: 'Announcements'
    },
    NO_PHASE: {
        value: 'NO_PHASE',
        label: 'No Phase'
    }
};

export const USER_FIRST_TIME_ENGAGER = {
    true: {
        value: true,
        label: 'Yes'
    },
    false: {
        value: false,
        label: 'No'
    }
};
