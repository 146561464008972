<template>
    <div class="container">
        <TabsContainer initialTabId="Toggle Feature Flags" class="nested-tabs-container">
            <Tab id="Toggle Feature Flags">
                <ToggleFeatureFlags />
            </Tab>
        </TabsContainer>
    </div>
</template>

<script>
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import ToggleFeatureFlags from '@/views/staff/StaffTools/components/FeatureFlagComponents/ToggleFeatureFlags';

export default {
    name: 'FeatureFlagTools',
    components: {
        TabsContainer,
        Tab,
        ToggleFeatureFlags
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.container {
    :deep() {
        .nested-tabs-container .tabs .tab {
            font-size: 1rem !important;

            &:hover {
                border-bottom: 3px solid #43d1ff !important;
            }

            &.is-active {
                color: #43d1ff !important;
                border-bottom: 3px solid #43d1ff !important;
            }
        }
    }
}
</style>
