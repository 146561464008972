import User from '../users/models';
import api from './api';
import options from './options';

import { objectToCamelCase, objectToSnakeCase } from '../utils';

export default class Admin extends User {
    static api = api.create(Admin);
    static options = options;
    static readOnlyFields = [];

    constructor(admin) {
        // eslint-disable-next-line no-param-reassign
        admin = admin || {};
        const {
            id = null,
            firstName = '',
            lastName = '',
            email = '',
            displayName = '',
            isAdmin = false,
            isStaff = false,
            adminAccount = null,
            school = null,
            schoolRef = null
        } = admin;
        super(admin);
        Object.assign(this, {
            id,
            firstName,
            lastName,
            email,
            displayName,
            isAdmin,
            isStaff,
            adminAccount,
            school,
            schoolRef
        });
    }

    get permission() {
        return this.isStaff ? 'Unlimited Admin' : 'Limited Admin';
    }

    copy() {
        return new Admin(this);
    }

    update(data = {}) {
        return Object.assign(this.copy(), data);
    }

    static create(opts) {
        return new Admin(opts);
    }

    static fromAPI(json = {}) {
        return new Admin(objectToCamelCase(json));
    }

    static toAPI(admin, fields = [], excludeFields = []) {
        let data = {};
        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = admin[field];
            });
        } else {
            data = admin;
        }

        excludeFields = [...Admin.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }

    static checkIsInstance(obj, name) {
        if (!(obj instanceof Admin)) {
            throw Error(`${name} must be an instance of the Admin class.`);
        }
    }
}
