<template>
    <div>
        <div class="table-header">
            <h2 v-if="title">{{ title }}</h2>
            <div class="table-header__actions-block">
                <slot name="list-actions"></slot>
            </div>
        </div>
        <div v-if="subtitle" class="subtitle text-gray">{{ subtitle }}</div>

        <transition name="selected-items">
            <slot name="selected-items-count" class="selected-items"></slot>
        </transition>

        <div class="list-wrapper">
            <div class="list">
                <slot name="list-filters" />

                <div class="list__content">
                    <slot name="list-header" />
                    <slot name="list-items" />
                </div>
            </div>
        </div>
        <LoadingSpinner v-if="loading" />
    </div>
</template>

<script>
/**
 *  Wrapper component for rendering a list of objects.  Provides consistant styling and allows customizable
 *  list content through the use of slots.
 *
 *  Available slots:
 *
 *    "list-actions" - These are buttons(or perhaps a search bar) to display on the top right of the table
 *                     representing actions the user can do on the table.
 *
 *    "selected-items-count" - This is the text to display if items are being selected,
 *                             For example a computed property could be passed in from the
 *                             parent component saying "9 students selected".
 *
 *    "list-header" - The header of the list
 *
 *    "list-items" - The items/rows of the list
 *
 *    "list-filters" - Slot for filters to render beside the table.
 *
 */
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
export default {
    name: 'List',
    components: {
        LoadingSpinner
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/styles/mixins/lists';

.list-wrapper {
    display: flex;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    &__actions-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 40%;
        margin-right: 16px;
    }
}

.list {
    display: flex;
    width: 100%;

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width: 1295px) {
    .list {
        &__item {
            justify-content: flex-start;
        }

        &__item-level {
            margin-right: 40px;
        }

        &__item-driver {
            width: 120px;
        }

        &__item-name {
            margin-right: 10px;
            flex: 0.5;
        }

        &__item-email {
            max-width: 100px;
        }
    }
}

@media screen and (max-width: 960px) {
    .list {
        &__item {
            padding: 4px 0 4px 0;
        }

        &__item-checkbox {
            padding: 0;
            padding-left: 5px;
        }

        &__item-name {
            max-width: 100px;
        }

        &__item-driver {
            width: 82px;
        }

        &__item-level {
            width: 74px;
            margin-right: 5px;
        }

        &__item-email {
            margin-right: 10px;
        }
    }
}

.subtitle {
    margin-bottom: 25px;
    width: 80%;
}

.selected-items {
    margin-bottom: 10px;
    overflow: hidden;
}

.selected-items-enter-active {
    transition: all 0.25s linear;
}

.selected-items-leave-active {
    transition: all 0.25s linear;
}

.selected-items-enter,
.selected-items-leave-to {
    max-height: 0;
    opacity: 0;
    // margin-bottom: 0;
}

.selected-items-enter-to,
.selected-items-leave {
    max-height: 50px;
    opacity: 1;
    margin-bottom: 10px;
}
</style>
