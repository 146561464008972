<template>
    <div class="chart-container">
        <BarChart
            :chartData="chartData"
            :showYGridLines="false"
            :showXGridLines="false"
            :showLegend="false"
            :displayYTicks="false"
            :displayTooltipColors="false"
            :tooltipsEnabled="!isExperimentalAccessibilityEnabled"
            :tooltipCallbacks="tooltipCallbacks"
            :xTicksCallback="formatXTicks"
            :ariaLabel="chartAriaLabel"
            indexAxis="x"
            displayDataLabels
        />
    </div>
</template>

<script>
import BarChart from '@/components-deprecated/charts/BarChart';
import { formatISO, FORMATS } from '@/lib/dates';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'QuestionsChart',
    components: { BarChart },
    props: {
        messageCountByMonth: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            tooltipCallbacks: {
                title: function(tooltipItem) {
                    if (Array.isArray(tooltipItem) && tooltipItem[0] && tooltipItem[0].label) {
                        return formatISO(tooltipItem[0].label, FORMATS.MONTH_LONG_YEAR_LONG);
                    }
                    return '';
                },
                label: function(context) {
                    if (context && context.formattedValue) {
                        return `${context.formattedValue} questions`;
                    }
                    return '';
                }
            }
        };
    },
    methods: {
        formatXTicks(labelIndex) {
            if (typeof labelIndex !== 'number') {
                return '';
            }

            const label = this.computeLabels[labelIndex];

            if (this.isExperimentalAccessibilityEnabled) {
                return formatISO(label, FORMATS.MONTH_SHORT_YEAR_SHORT);
            }
            return formatISO(label, FORMATS.MONTH_SHORT);
        }
    },
    computed: {
        chartData() {
            return {
                labels: this.computeLabels,
                datasets: [
                    {
                        backgroundColor: '#008CFF',
                        pointBackgroundColor: 'white',
                        borderWidth: 0,
                        // set minimum (pixels) so 0 values still have a visible bar with tooltip
                        minBarLength: 1,
                        pointBorderColor: '#249EBF',
                        data: this.computeData
                    }
                ]
            };
        },
        computeLabels() {
            if (Array.isArray(this.messageCountByMonth)) {
                return this.messageCountByMonth.map(item => {
                    // labels are defined as dates, then are formatted in
                    // formatXTicks and tooltipCallbacks
                    return item.monthStart;
                });
            }
            return [];
        },
        computeData() {
            if (Array.isArray(this.messageCountByMonth)) {
                return this.messageCountByMonth.map(item => item.count);
            }

            return [];
        },
        chartAriaLabel() {
            const title = 'Number of questions asked by students per month:';
            if (Array.isArray(this.messageCountByMonth)) {
                const length = this.messageCountByMonth.length;
                const dataString = this.messageCountByMonth.reduce((acc, monthCount, index) => {
                    return `${acc}${formatISO(
                        monthCount.monthStart,
                        FORMATS.MONTH_LONG_YEAR_LONG
                    )}: ${monthCount.count}${index === length - 1 ? '.' : ', '}`;
                }, '');

                return `${title} ${dataString}`;
            }

            return `${title} No question data yet.`;
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.chart-container {
    :deep(.bar-chart-container) {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 2rem;

        & > div {
            min-height: 15rem;
            max-height: 15rem;
            width: 95%;
        }
    }
}
</style>
