<template>
    <div class="box summary">
        <h1 class="summary-title">Students Summary</h1>
        <div class="summary-container">
            <div class="summary-block">
                <h2 class="summary-block__title">Risk Level</h2>
                <div class="summary-block__content summary-block__content_risk">
                    <div class="charts-circle">
                        <div class="charts-circle__chart-wrapper">
                            <PieChart
                                :color="chartColors.COLOR_CHART_RISK_LOW_DARKER"
                                :value="summary.lowRiskPercent"
                                :ariaLabelTitle="getRiskLevelChartAriaTitle('low')"
                            />
                            <div class="chart-circle__title">
                                {{ summary.lowRiskScores.length }} Students at Low Risk
                            </div>
                        </div>
                        <div class="charts-circle__chart-wrapper">
                            <PieChart
                                :color="chartColors.COLOR_CHART_RISK_MEDIUM_DARKER"
                                :value="summary.mediumRiskPercent"
                                :ariaLabelTitle="getRiskLevelChartAriaTitle('medium')"
                            />
                            <div class="chart-circle__title">
                                {{ summary.mediumRiskScores.length }} Students at Medium Risk
                            </div>
                        </div>
                        <div class="charts-circle__chart-wrapper">
                            <PieChart
                                :color="chartColors.COLOR_CHART_RISK_HIGH_DARKER"
                                :value="summary.highRiskPercent"
                                :ariaLabelTitle="getRiskLevelChartAriaTitle('high')"
                            />
                            <div class="chart-circle__title">
                                {{ summary.highRiskScores.length }} Students at High Risk
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="summary-block">
                <h2 class="summary-block__title">
                    <span>Risk Driver</span>
                    <RiskDriverExplanation v-if="!isExperimentalAccessibilityEnabled"/>
                </h2>
                <div class="summary-block__content summary-block__content_risk">
                    <div class="risk-driver">
                        <svg
                            v-if="isExperimentalAccessibilityEnabled"
                            class="student__risk-icon"
                            role="img"
                        >
                            <use xlink:href="#icon-risk-academic" role="img" />
                        </svg>
                        <p class="risk-driver__label">Academic</p>
                        <ChartProgress
                            class="risk-driver__chart"
                            :value="summary.academicRiskPercent"
                        />
                    </div>
                    <div class="risk-driver">
                        <svg
                            v-if="isExperimentalAccessibilityEnabled"
                            class="student__risk-icon"
                            role="img"
                        >
                            <use xlink:href="#icon-risk-engagement" role="img" />
                        </svg>
                        <p class="risk-driver__label">Engagement</p>
                        <ChartProgress
                            class="risk-driver__chart"
                            :value="summary.engagementRiskPercent"
                        />
                    </div>
                    <div class="risk-driver">
                        <svg
                            v-if="isExperimentalAccessibilityEnabled"
                            class="student__risk-icon"
                            role="img"
                        >
                            <use xlink:href="#icon-risk-financial" role="img" />
                        </svg>
                        <p class="risk-driver__label">Financial</p>
                        <ChartProgress
                            class="risk-driver__chart"
                            :value="summary.financialRiskPercent"
                        />
                    </div>
                    <div class="risk-driver">
                        <svg
                            v-if="isExperimentalAccessibilityEnabled"
                            class="student__risk-icon-belief"
                            role="img"
                        >
                            <use xlink:href="#icon-risk-belief" role="img" />
                        </svg>
                        <p class="risk-driver__label">Wellness</p>
                        <ChartProgress
                            class="risk-driver__chart"
                            :value="summary.wellnessRiskPercent"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PieChart from '@/components-deprecated/charts/PieChart';
import ChartProgress from '@/components-deprecated/charts/ChartProgress';
import RiskDriverExplanation from '@/components-deprecated/RiskDriverExplanation';
import { getColors } from '@/consts/colors';
import { initialSummaryStats } from '@/api/school';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'SummaryStudents',
    components: {
        PieChart,
        ChartProgress,
        RiskDriverExplanation
    },
    props: {
        summary: {
            type: Object,
            default: () => initialSummaryStats()
        }
    },
    methods: {
        getRiskLevelChartAriaTitle(riskLevel) {
            return `Pie chart showing the percentage of your students with a ${riskLevel} risk level:`;
        },
        getRiskDriverChartAriaTitle(riskDriver) {
            return `Progress bar representing the percentage of your students with the ${riskDriver} risk driver. :`;
        }
    },
    computed: {
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        },
        chartColors() {
            return getColors(this.isExperimentalAccessibilityEnabled);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.summary {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 10;
    margin-bottom: 1.5rem;
    padding: 15px 20px;
    overflow: visible;
}

.summary-container {
    display: flex;
    justify-content: space-between;
}

.summary-block {
    width: 45%;
    position: relative;

    .risk-driver-exp {
        top: 30px;
    }

    &__question-icon {
        width: 16px;
        vertical-align: middle;
    }

    &__title {
        display: flex;
        align-items: center;
    }
}

.charts-circle {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 30px;

    &__chart-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.chart-circle {
    max-width: 430px;
    margin: 0 auto;

    &__title {
        margin-top: 15px;
        text-align: center;
    }
}

.risk-driver {
    display: flex;
    margin: 15px 0;
    align-items: center;
    &__label {
        width: 170px;
    }

    &__chart {
        flex-grow: 1;
    }
}

.summary-title {
    text-transform: uppercase;
    font-size: inherit;
    font-weight: inherit;
    color: $primary-brand-color;
    letter-spacing: inherit;
}
.divider {
    position: absolute;
    left: 50%;
    top: 10%;
    bottom: 10%;
    border-left: 1px solid lightgrey;
}

.student__risk-icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    color: $dark-gray;
}
.student__risk-icon-belief {
    width: 23px;
    height: 23px;
    margin: 4px 10px 4px 4px;
    background: #000;
    border-radius: 100px;
    background: $dark-gray;
}

@media only screen and (max-width: 768px) {
    .summary {
        position: relative;

        &-container {
            display: block;
        }

        &-block {
            width: 100%;
        }

        .divider {
            display: none;
        }
    }
}
</style>
