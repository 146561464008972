<template>
    <div class="container">
        <div class="back-navigation">
            <button class="back-button" @click="navigateBack">
                {{ '<' }} Back to {{ pageName }}
            </button>
        </div>

        <InsightComponent v-if="loaded" :insight="insight" :detailed="true" />
    </div>
</template>

<script>
import Insight from '@/services/insights';

import InsightComponent from '@/components-deprecated/InsightComponent';
export default {
    name: 'InsightDetail',
    components: {
        InsightComponent
    },
    data() {
        return {
            insight: null,
            loaded: false,
            pageName: 'Insights'
        };
    },
    async created() {
        if (this.$route.params.pageName) {
            this.pageName = this.$route.params.pageName;
        }

        const id = this.$route.params.id;
        await this.loadInsight(id);
    },
    methods: {
        async loadInsight(id) {
            try {
                this.loaded = false;
                const response = await Insight.api.retrieve(id);
                this.insight = response;
            } finally {
                this.loaded = true;
            }
        },
        navigateBack() {
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.container {
    padding-top: 50px;
}

.back-navigation {
    margin-bottom: 2rem;

    p {
        cursor: pointer;
        font-size: 1.5rem;
        text-decoration: bold;
    }
}

.back-button {
    background: transparent;
    border: 0;
    font-size: 1.5rem;
    letter-spacing: 1px;
    cursor: pointer;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}
</style>
