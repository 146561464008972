<template>
    <Modal
        :title="title"
        primaryButtonTitle="Save"
        :showPrimaryButtonLoadingSpinner="saving"
        @secondary-button-clicked="close"
        @primary-button-clicked="save"
    >
        <div>{{ message }}?</div>
    </Modal>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';

export default {
    name: 'RemoveResourceDetailModal',
    components: {
        Modal
    },
    props: {
        tagName: {
            type: String,
            required: true
        },
        saving: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        save() {
            this.$emit('save');
        }
    },
    computed: {
        title() {
            return 'Delete Tagged Resource';
        },
        message() {
            if (typeof this.tagName === 'string') {
                return `Are you sure you want to delete the resource for tag: ${this.tagName}`;
            }
            return 'Are you sure you want to delete the tagged resource?';
        }
    }
};
</script>

<style lang="scss" scoped></style>
