import StudentUploadAPI from './api';
import { objectToCamelCase, objectToSnakeCase } from '../utils';
import School from '@/services/schools';
import options from './options';

export default class StudentUpload {
    static api = StudentUploadAPI.create(StudentUpload);
    static options = options;
    static readOnlyFields = ['id', 'datetimeCreated', 'schoolRef'];
    constructor({
        id = '',
        title = '',
        file = null,
        notes = '',
        uploadType = '',
        uploadTypeRef = '',
        status = '',
        statusRef = '',
        schoolRef = null,
        datetimeCreated = null,
        displayDetails = false
    } = {}) {
        Object.assign(this, {
            id,
            title,
            file,
            notes,
            uploadType,
            uploadTypeRef,
            status,
            statusRef,
            schoolRef: schoolRef ? School.fromAPI(schoolRef) : null,
            datetimeCreated,
            displayDetails
        });
    }

    static create(opts) {
        return new StudentUpload(opts);
    }

    static fromAPI(json = {}) {
        return new StudentUpload(objectToCamelCase(json));
    }

    /**
     * Prep data for the API. Can elect to include or exlude specific fields.
     * The readOnlyFields are always excluded.
     **/
    static toAPI(studentUpload, fields = [], excludeFields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = studentUpload[field];
            });
        } else {
            data = studentUpload;
        }

        excludeFields = [...StudentUpload.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }
}
