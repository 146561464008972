<template>
    <div>
        <div v-if="resourceNeeds.length === 0">
            <h2>No Resources to show!</h2>
        </div>
        <div v-else v-for="subdriver in risk.subdrivers" :key="subdriver.id">
            <h2 class="topic-header" v-if="resourceNeedBySubdriver[subdriver.id]">
                {{ risk.name }} / {{ subdriver.name }}
            </h2>
            <div v-if="resourceNeedBySubdriver[subdriver.id]" class="faq-details">
                <div class="flex-between" v-if="resourceNeedBySubdriver[subdriver.id].id">
                    <div class="faq-question">
                        {{ resourceNeedBySubdriver[subdriver.id].need }}
                    </div>

                    <div
                        class="resource-detail-container faq-answer"
                        v-on:click="
                            resourceClickHandler(
                                resourceNeedBySubdriver[subdriver.id].resourceDetail
                            )
                        "
                        v-if="
                            selectedResourceDetail.resourceNeedId !==
                                resourceNeedBySubdriver[subdriver.id].id && userIsStaff
                        "
                    >
                        <div
                            style="display: flex; justify-content: space-between; padding-bottom: 2rem;"
                        >
                            <div v-if="resourceNeedBySubdriver[subdriver.id].resourceDetail.name">
                                {{ resourceNeedBySubdriver[subdriver.id].resourceDetail.name }}
                            </div>
                            <div v-else>
                                <a>Resource Name (e.g. Advisors)</a>
                            </div>

                            <status-badge
                                v-if="resourceNeedBySubdriver[subdriver.id].resourceDetail.status"
                                :status-text="
                                    resourceNeedBySubdriver[subdriver.id].resourceDetail.status
                                "
                            />
                        </div>

                        <div
                            v-if="resourceNeedBySubdriver[subdriver.id].resourceDetail.instruction"
                        >
                            {{ resourceNeedBySubdriver[subdriver.id].resourceDetail.instruction }}
                        </div>
                        <div v-else>
                            <a>Resource description and instructions on how to access it.</a>
                        </div>
                        <div
                            class="tag-resource-details-read"
                            v-for="resourceDetailTag in resourceNeedBySubdriver[subdriver.id]
                                .resourceDetail.resourceDetailTags"
                            :key="resourceDetailTag.id"
                        >
                            <div
                                @click.stop="
                                    resourceDetailTagClickHandler(
                                        resourceNeedBySubdriver[subdriver.id].resourceDetail,
                                        resourceDetailTag
                                    )
                                "
                            >
                                <div
                                    style="display: flex; justify-content: space-between; padding-top: 2rem;"
                                >
                                    <p class="description">
                                        Custom resource based on tag
                                        {{ resourceDetailTag.tagName }}:
                                    </p>

                                    <status-badge
                                        v-if="resourceDetailTag.status"
                                        :status-text="resourceDetailTag.status"
                                    />
                                </div>

                                <div v-if="resourceDetailTag.instruction">
                                    {{ resourceDetailTag.instruction }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="resource-detail-container faq-answer"
                        v-on:click="
                            resourceClickHandler(
                                resourceNeedBySubdriver[subdriver.id].resourceDetail
                            )
                        "
                        v-if="
                            selectedResourceDetail.resourceNeedId !==
                                resourceNeedBySubdriver[subdriver.id].id && !userIsStaff
                        "
                    >
                        <div
                            style="display: flex; justify-content: space-between; padding-bottom: 2rem;"
                        >
                            <div v-if="resourceNeedBySubdriver[subdriver.id].resourceDetail.name">
                                <a>{{
                                    resourceNeedBySubdriver[subdriver.id].resourceDetail.name
                                }}</a>
                            </div>
                            <div v-else class="faq-answer-admin">
                                Resource Name (e.g. Advisors)
                            </div>

                            <status-badge
                                v-if="resourceNeedBySubdriver[subdriver.id].resourceDetail.status"
                                :status-text="
                                    resourceNeedBySubdriver[subdriver.id].resourceDetail.status
                                "
                            />
                        </div>

                        <div
                            v-if="resourceNeedBySubdriver[subdriver.id].resourceDetail.instruction"
                        >
                            <a>{{
                                resourceNeedBySubdriver[subdriver.id].resourceDetail.instruction
                            }}</a>
                        </div>
                        <div v-else class="description-admin">
                            Resource description and instructions on how to access it.
                        </div>
                    </div>

                    <div
                        class="flex-between faq-answer"
                        v-if="
                            selectedResourceDetail.resourceNeedId ===
                                resourceNeedBySubdriver[subdriver.id].id
                        "
                    >
                        <div style="display: flex; position: relative;">
                            <div>
                                <div style="margin-bottom: 2rem;" v-if="userIsStaff">
                                    <p class="description">
                                        Input the resource name.
                                    </p>
                                    <Input v-model.trim="selectedResourceDetail.name" />
                                    <FieldError :errors="errors" name="name" />
                                </div>
                                <div style="margin-bottom: 2rem;" v-if="!userIsStaff">
                                    <p class="description">
                                        Resource name:
                                    </p>
                                    <label>{{ selectedResourceDetail.name }}</label>
                                </div>

                                <div style="margin-bottom: 2rem;" v-if="userIsStaff">
                                    <div>
                                        <div class="description">
                                            Describe the resource and explain how to access it in a
                                            short, SMS-friendly way.
                                        </div>
                                        <div class="description">
                                            This resource is applicable to all students except those
                                            in selected tags below.
                                        </div>
                                    </div>

                                    <div style="display: flex">
                                        <EmojiPicker
                                            style="margin-left: -34px;"
                                            @onSelection="selectEmojiHandler"
                                        />

                                        <ElasticTextArea
                                            v-model="selectedResourceDetail.instruction"
                                            class="answer-text-area"
                                            bordered
                                        />
                                    </div>
                                    <FieldError :errors="errors" name="instruction" />
                                </div>
                                <div style="margin-bottom: 2rem;" v-if="!userIsStaff">
                                    <p class="description">
                                        Resource description:
                                    </p>
                                    <label>{{ selectedResourceDetail.instruction }}</label>
                                </div>
                                <div style="display: flex;margin-bottom: 2rem;">
                                    <span style="padding-right: 1.0rem">
                                        Status
                                    </span>
                                    <TransitionDropdown
                                        style="width: 125px"
                                        :choices="statusChoices"
                                        :selected="
                                            statusChoices.find(
                                                s => s.id === selectedResourceDetail.status
                                            ) || {}
                                        "
                                        @on-select="statusChangeHandler"
                                    />
                                    <last-edited-date :date="selectedResourceDetail.updatedAt" />
                                </div>
                                <div>
                                    <div
                                        class="tag-resource-details"
                                        v-if="
                                            userIsStaff &&
                                                selectedResourceDetailTag &&
                                                !isAddingResourceTag
                                        "
                                    >
                                        <div style="padding-top: 15px;">
                                            Customize resource based on student tag:
                                        </div>
                                        <div style="padding-top: 10px;" class="description">
                                            Describe the resource and explain how to access it in a
                                            short, SMS-friendly way.
                                        </div>
                                        <div class="description">
                                            This resource is applicable to students in the selected
                                            tag.
                                        </div>

                                        <div style="display: flex">
                                            <EmojiPicker
                                                style="margin-left: -34px;"
                                                @onSelection="onTagResourceEmojiSelected"
                                            />

                                            <ElasticTextArea
                                                v-model="selectedResourceDetailTag.instruction"
                                                class="answer-text-area"
                                                bordered
                                            />
                                        </div>
                                        <FieldError :errors="errors" name="tagInstruction" />
                                        <div style="margin-bottom: 2rem;" v-if="!userIsStaff">
                                            <p class="description">
                                                Resource description:
                                            </p>
                                            <label>{{
                                                selectedResourceDetailTag.instruction
                                            }}</label>
                                        </div>
                                        <FieldError :errors="errors" name="tagId" />
                                        <div style="display: flex;margin-top: 2rem;">
                                            <div
                                                class="remove-icon"
                                                @click="openRemoveResourceDetailModal"
                                                v-if="selectedResourceDetailTag.id"
                                            ></div>
                                            <span style="padding-right: 1.0rem">
                                                Status
                                            </span>
                                            <TransitionDropdown
                                                style="width: 125px"
                                                :choices="statusChoices"
                                                :selected="statusValue"
                                                @on-select="onTagResourceStatusSelected"
                                            />
                                            <div v-if="selectedResourceDetailTag.tagId">
                                                <span
                                                    style="padding-right: 1.0rem; padding-left: 1.0rem;"
                                                >
                                                    Tag:
                                                    {{ selectedResourceDetailTag.tagName }}
                                                </span>
                                            </div>
                                            <div style="padding-left: 1.0rem;">
                                                <a
                                                    v-if="selectedResourceDetailTag.tagId"
                                                    @click="
                                                        onClickSelectTag(selectedResourceDetailTag)
                                                    "
                                                >
                                                    Edit Tag
                                                </a>
                                                <a
                                                    v-else
                                                    @click="
                                                        onClickSelectTag(selectedResourceDetailTag)
                                                    "
                                                >
                                                    Select Tag
                                                </a>
                                            </div>
                                        </div>
                                        <last-edited-date
                                            :date="selectedResourceDetailTag.updatedAt"
                                        />
                                    </div>
                                    <div
                                        class="tag-resource-details-read"
                                        v-for="resourceDetailTag in selectedResourceDetail.resourceDetailTags.filter(
                                            x => x !== selectedResourceDetailTag
                                        )"
                                        :key="resourceDetailTag.id"
                                    >
                                        <div
                                            @click.stop="
                                                resourceDetailTagClickHandler(
                                                    resourceNeedBySubdriver[subdriver.id]
                                                        .resourceDetail,
                                                    resourceDetailTag
                                                )
                                            "
                                        >
                                            <div
                                                style="display: flex; justify-content: space-between; padding-top: 2rem;"
                                            >
                                                <p class="description">
                                                    Custom resource based on tag
                                                    {{ resourceDetailTag.tagName }}:
                                                </p>

                                                <status-badge
                                                    v-if="resourceDetailTag.status"
                                                    :status-text="resourceDetailTag.status"
                                                />
                                            </div>

                                            <div v-if="resourceDetailTag.instruction">
                                                {{ resourceDetailTag.instruction }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="tag-resource-details"
                                        v-if="
                                            userIsStaff &&
                                                selectedResourceDetailTag &&
                                                isAddingResourceTag
                                        "
                                    >
                                        <div style="padding-top: 15px;">
                                            Customize resource based on student tag:
                                        </div>
                                        <div style="padding-top: 10px;" class="description">
                                            Describe the resource and explain how to access it in a
                                            short, SMS-friendly way.
                                        </div>
                                        <div class="description">
                                            This resource is applicable to students in the selected
                                            tag.
                                        </div>

                                        <div style="display: flex">
                                            <EmojiPicker
                                                style="margin-left: -34px;"
                                                @onSelection="onTagResourceEmojiSelected"
                                            />

                                            <ElasticTextArea
                                                v-model="selectedResourceDetailTag.instruction"
                                                class="answer-text-area"
                                                bordered
                                            />
                                        </div>
                                        <FieldError :errors="errors" name="tagInstruction" />
                                        <div style="margin-bottom: 2rem;" v-if="!userIsStaff">
                                            <p class="description">
                                                Resource description:
                                            </p>
                                            <label>{{
                                                selectedResourceDetailTag.instruction
                                            }}</label>
                                        </div>
                                        <FieldError :errors="errors" name="tagId" />
                                        <div style="display: flex;margin-top: 2rem;">
                                            <div
                                                class="remove-icon"
                                                @click="openRemoveResourceDetailModal"
                                                v-if="selectedResourceDetailTag.id"
                                            ></div>
                                            <span style="padding-right: 1.0rem">
                                                Status
                                            </span>
                                            <TransitionDropdown
                                                style="width: 125px"
                                                :choices="statusChoices"
                                                :selected="statusValue"
                                                @on-select="onTagResourceStatusSelected"
                                            />
                                            <div v-if="selectedResourceDetailTag.tagId">
                                                <span
                                                    style="padding-right: 1.0rem; padding-left: 1.0rem;"
                                                >
                                                    Tag:
                                                    {{ selectedResourceDetailTag.tagName }}
                                                </span>
                                            </div>
                                            <div style="padding-left: 1.0rem;">
                                                <a
                                                    v-if="selectedResourceDetailTag.tagId"
                                                    @click="
                                                        onClickSelectTag(selectedResourceDetailTag)
                                                    "
                                                >
                                                    Edit Tag
                                                </a>
                                                <a
                                                    v-else
                                                    @click="
                                                        onClickSelectTag(selectedResourceDetailTag)
                                                    "
                                                >
                                                    Select Tag
                                                </a>
                                            </div>
                                        </div>
                                        <last-edited-date
                                            :date="selectedResourceDetailTag.updatedAt"
                                        />
                                    </div>
                                </div>
                                <div
                                    v-if="selectedResourceDetail.id && !isAddingResourceTag"
                                    style="display: flex;margin-bottom: 2rem;"
                                >
                                    <a @click="onClickAddResourceDetailTag()">
                                        + Filter Resource by Tag
                                    </a>
                                </div>
                                <p class="description" v-if="!userIsStaff">
                                    Reach out to your EdSights point of contact to edit this
                                    resource.
                                </p>
                                <div class="button-container">
                                    <LoadingSpinnerButton
                                        v-if="!selectedResourceDetail.id"
                                        class="save-btn"
                                        @click="$emit('create-resource-detail')"
                                        text="Create"
                                        :loading="loading"
                                    />

                                    <LoadingSpinnerButton
                                        v-if="selectedResourceDetail.id"
                                        class="save-btn"
                                        @click="
                                            $emit('update-resource-detail', {
                                                selectedResourceDetailTag
                                            })
                                        "
                                        text="Save"
                                        :loading="loading"
                                    />

                                    <LoadingSpinnerButton
                                        class="delete-btn"
                                        @click="$emit('cancel-resource-detail')"
                                        text="Cancel"
                                        color="main-gray"
                                        :loading="loading"
                                    />
                                    <ModalSelectTag />
                                    <RemoveResourceDetailModal
                                        v-if="removeResourceDetailModalData.show"
                                        @close="removeResourceDetailModalData.show = false"
                                        @save="
                                            onDeleteResourceDetailTag(
                                                selectedResourceDetailTag.id,
                                                selectedResourceDetailTag.tagName
                                            )
                                        "
                                        :tagName="selectedResourceDetailTag.tagName"
                                        :saving="removeResourceDetailModalData.saving"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <last-edited-date
                    :date="resourceNeedBySubdriver[subdriver.id].resourceDetail.updatedAt"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { clone } from 'lodash';
import statusBadge from '@/components-deprecated/StatusBadge';
import ElasticTextArea from '@/components-deprecated/ElasticTextArea';
import FieldError from '@/components-deprecated/FieldError';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import TransitionDropdown from '@/components-deprecated/inputs/TransitionDropdown';
import EmojiPicker from '@/components-deprecated/staff/EmojiPicker.vue';
import Input from '@/components-deprecated/Input';
import LastEditedDate from '@/components-deprecated/LastEditedDate';
import ModalSelectTag from '@/components-deprecated/admin/ModalSelectTag';
import RemoveResourceDetailModal from './RemoveResourceDetailModal';
import { deleteResourceDetailTag } from '@/api/resource-detail-tag';

export default {
    components: {
        LastEditedDate,
        statusBadge,
        ElasticTextArea,
        FieldError,
        LoadingSpinnerButton,
        TransitionDropdown,
        EmojiPicker,
        Input,
        ModalSelectTag,
        RemoveResourceDetailModal
    },
    props: {
        risk: {
            type: Object
        },
        resourceNeeds: {
            type: Array
        },
        selectedResourceDetail: {
            type: Object
        },
        errors: {
            type: Array
        },
        loading: {
            type: Boolean
        },
        deleteLoading: {
            type: Boolean
        }
    },
    data: function() {
        return {
            statusChoices: [
                { id: 'approved', name: 'Approved' },
                { id: 'pending', name: 'Pending' }
            ],
            statusValue: { id: 'pending', name: 'Pending' },
            selectedResourceDetailTag: null,
            removeResourceDetailModalData: {
                show: false,
                saving: false
            },
            isAddingResourceTag: false
        };
    },
    methods: {
        resourceClickHandler(resourceDetail) {
            this.isAddingResourceTag = false;
            this.selectedResourceDetailTag = null;
            if (this.userIsStaff) {
                this.$emit('edit-detail', clone(resourceDetail));
            } else {
                if (resourceDetail.name) {
                    this.$emit('edit-detail', clone(resourceDetail));
                }
            }
        },
        resourceDetailTagClickHandler(resourceDetail, resourceDetailTag) {
            this.isAddingResourceTag = false;
            if (this.userIsStaff) {
                this.$emit('edit-detail', clone(resourceDetail));
            } else {
                if (resourceDetail.name) {
                    this.$emit('edit-detail', clone(resourceDetail));
                }
            }
            this.selectedResourceDetailTag = resourceDetailTag;
            this.statusValue =
                this.statusChoices.find(s => s.id === this.selectedResourceDetailTag.status) || {};
        },
        statusChangeHandler: function(statusChoice) {
            this.$emit('detail-status-change', statusChoice.id);
        },
        selectEmojiHandler: function(value) {
            this.$emit('emoji-selected', value);
        },
        selectEmojiHandlerTag: function(value) {
            this.$emit('emoji-selected-tag', value);
        },
        onClickSelectTag(tagResource) {
            this.$modal.show('modal-select-tag', { tagResource });
        },
        onTagResourceStatusSelected(status) {
            this.statusValue = status;
            this.selectedResourceDetailTag.status = status.id;
        },
        onTagResourceEmojiSelected(value) {
            this.selectedResourceDetailTag.instruction = this.selectedResourceDetailTag.instruction += value;
        },
        onClickAddResourceDetailTag() {
            this.isAddingResourceTag = true;
            this.selectedResourceDetailTag = {
                resourceDetailId: this.selectedResourceDetail.id,
                tagId: null,
                tagName: '',
                status: 'pending'
            };
        },
        openRemoveResourceDetailModal() {
            this.removeResourceDetailModalData.show = true;
        },
        async onDeleteResourceDetailTag(id, tagName) {
            try {
                this.removeResourceDetailModalData.saving = true;

                await deleteResourceDetailTag({
                    id,
                    schoolId: this.$store.state.user.schoolRef.id
                });

                this.$Alert.alert({
                    type: 'success',
                    message: `You successfully deleted the resource for tag: ${tagName}`,
                    timeout: 5000
                });

                this.$emit('delete-resource-detail-tag');
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: 'There was a problem deleting the resource. Please try again.',
                    timeout: 5000
                });
            } finally {
                this.removeResourceDetailModalData.show = false;
                this.removeResourceDetailModalData.saving = false;
            }
        }
    },
    computed: {
        userIsStaff() {
            return this.$store.state.user.isStaff;
        },
        resourceNeedBySubdriver() {
            const result = {};
            this.resourceNeeds.forEach(need => {
                result[need.riskSubdriverId] = need;
            });
            return result;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@/styles/mixins/buttons';

.faq-question {
    width: 35%;
}

.faq-answer {
    width: 62%;
    cursor: pointer;
}

.faq-answer-admin {
    width: 62%;
    cursor: auto;
    color: $grey-suit;
}

.resource-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.faq-details {
    padding: 1rem 0 1rem 0;
    border-bottom: 1px solid #f0ecec;
}

.tag-resource-details {
    padding-left: 3rem;
    border-top: 1px solid #f0ecec;
}

.tag-resource-details-read {
    padding-left: 3rem;
    border-top: 1px solid #f0ecec;
}

.flex-between {
    display: flex;
    justify-content: space-between;
}

.topic-header {
    padding: 2rem 0 2rem 0;
}

.description {
    color: $main-gray;
    font-size: 10px;
}

.description-admin {
    cursor: auto;
    color: $grey-suit;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
}

.save-btn {
    @include base-button();
}

.remove-icon {
    cursor: pointer;
    padding: 15px;
    margin-right: 15px;
    background: url('../../assets/surveys/del_trash.png');
    background-repeat: no-repeat;
    background-size: 17px 22px;
}

.delete-btn {
    @include secondary-button();
    margin-left: 1rem;
}

.answer-text-area {
    min-height: 200px;
}

.tag-link {
    cursor: pointer;
    text-decoration: underline;
}
</style>
