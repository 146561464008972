/* Filter types for the Chatbot Builder */
const FILTER = 'filter';
const IMPORT = 'import';

const FILTER_TYPES = {
    FILTER,
    IMPORT
};

export default {
    FILTER,
    IMPORT,
    FILTER_TYPES
};
