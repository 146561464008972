<template>
    <div class="message">
        <div class="message__date">
            <p>{{ message.datetimeCreated.toLocaleString() }}</p>
        </div>

        <div class="message__school" v-tooltip="message.schoolRef.name">
            <p>{{ message.schoolRef.name }}</p>
        </div>

        <div class="message__status">
            <p>{{ message.status }}</p>
        </div>

        <div class="message__name" v-tooltip="message.studentRef.displayName">
            <p>{{ message.studentRef.displayName }}</p>
        </div>

        <div class="message__email" v-tooltip="message.studentRef.email">
            <p>{{ message.studentRef.email }}</p>
        </div>

        <div class="message__number">
            <p>{{ message.studentRef.phoneNumber }}</p>
        </div>

        <div class="message__text" v-tooltip="message.text">
            <p>{{ message.text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatbotTextsListItem',
    props: {
        message: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    p {
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 15px;
    }

    &__date {
        width: 15%;
    }

    &__school {
        width: 15%;
    }

    &__status {
        width: 10%;
    }

    &__name {
        width: 12%;
    }

    &__email {
        width: 15%;
    }

    &__number {
        width: 10%;
    }

    &__text {
        width: 28%;
    }
}
</style>
