import { DateTime } from 'luxon';

export const FORMATS = {
    TIME_12: 'h:mm a', // example: 9:30 AM, 3:30 PM
    TIME_24: 'HH:mm', // example: 09:30, 15:30
    DATE: 'yyyy-MM-dd', // example: 2022-05-08
    MONTH_SHORT: 'MMM', // example: Jan
    MONTH_SHORT_YEAR_SHORT: 'MMM yy', // example: Jan 23
    MONTH_LONG_YEAR_LONG: 'MMMM yyyy' // example: February 2022
};

/**
 * Formats DateTime string in ISO format into long format.
 * @param {string} dateTimeString - DateTime to format. Example: "2022-03-16T13:42:00.000Z".
 * @returns {string} - DateTime in form of "Wednesday, March 16, 2022 9:42 AM". Returns blank string if invalid dateTimeString provided.
 */
export const formatLong = dateTimeString => {
    if (typeof dateTimeString === 'string') {
        const dateTime = DateTime.fromISO(dateTimeString, { zone: 'America/New_York' });
        if (dateTime.isValid) {
            return dateTime.toFormat('cccc, MMMM dd, yyyy h:mm a');
        }
    }

    return '';
};

/**
 * Formats DateTime string in ISO format into a specified format.
 * @param {string} dateTimeString - DateTime to format. Example: "2022-03-16T13:42:00.000Z".
 * @param {string} toFormat - Output format. Example: "h:mm a".
 * @returns {string} - DateTime in form of "toFormat". Returns blank string if invalid dateTimeString or format provided.
 */
export const formatISO = (dateTimeString, toFormat) => {
    if (typeof dateTimeString === 'string' && typeof toFormat === 'string') {
        const dateTime = DateTime.fromISO(dateTimeString, { zone: 'America/New_York' });
        if (dateTime.isValid) {
            return dateTime.toFormat(toFormat);
        }
    }

    return '';
};

/**
 * Formats DateTime ISO string into form required for an HTML DateTime input.
 * @param {string} dateTimeString - DateTime to format. Example: "2022-03-16T13:42:00.000Z".
 * @returns {string} - DateTime in form of "2022-03-16T09:42". Returns blank string if invalid dateTimeString provided.
 */
export const formatISOToHTMLDateTime = dateTimeString => {
    if (typeof dateTimeString === 'string') {
        const dateTime = DateTime.fromISO(dateTimeString, { zone: 'America/New_York' });
        if (dateTime.isValid) {
            return dateTime.toFormat("yyyy-MM-dd'T'HH:mm");
        }
    }

    return '';
};

/**
 * Formats value of an HTML DateTime input into ISO date format.
 * @param {string} dateTimeString - String to convert to ISO, in form used by HTML DateTime inputs. Example: "2022-03-16T09:42".
 * @returns {string} - DateTime in ISO form of "2022-03-16T13:42:00.000Z". Returns blank string if invalid dateTimeString provided.
 */
export const formatHTMLDateTimeToISO = dateTimeString => {
    if (typeof dateTimeString === 'string') {
        const dateTime = DateTime.fromFormat(dateTimeString, "yyyy-MM-dd'T'HH:mm", {
            zone: 'America/New_York'
        });

        if (dateTime.isValid) {
            return dateTime.toUTC().toISO();
        }
    }

    return '';
};
