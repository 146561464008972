import { API_BASE, apiErrorHandler } from '@/services/api';

import Pagination from '@/services/pagination';
import { objectToCamelCase, objectToSnakeCase } from '@/services/utils/';
import Auth from '../auth';
import axios from 'axios';

const INTERVENTION_ITEMS_ENDPOINT = `${API_BASE}intervention-items/`;

export default class InterventionItemsAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new InterventionItemsAPI(cls);
    }

    async list(params = {}) {
        const url = INTERVENTION_ITEMS_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers,
            params: objectToSnakeCase(params)
        };

        const promise = axios
            .get(url, options)
            .then(res => {
                return {
                    ...res.data,
                    results: res.data.results.map(res => this.cls.fromAPI(res))
                };
            })

            .catch(apiErrorHandler({ apiName: 'Intervention Items API Error' }));

        return promise;
    }
}
