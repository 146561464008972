<template>
    <div class="score-progress-container">
        <div class="score-container">
            <Score
                :score="score"
                :comparedScore="comparedScore"
                :showGradient="showScoreGradient"
            />
        </div>
        <div class="progress-container">
            <div class="compared-score" v-if="renderedComparedScore">
                <div v-if="renderedComparedScore.differenceLabelPrefix">
                    {{ renderedComparedScore.differenceLabelPrefix }}
                </div>
                <div :class="renderedComparedScore.differenceClass">
                    {{ renderedComparedScore.differenceLabel }}
                </div>
                <div>{{ renderedComparedScore.differenceLabelSuffix }}</div>
            </div>
            <div class="compared-score" v-else>
                <div class="empty-title">No comparison data available</div>
            </div>

            <ProgressBar
                class="progress-bar"
                :class="progressBarClass"
                :percent="progressBarData.percent"
                :markerPercent="progressBarData.markerPercent"
                showBarLabels
                minBarLabel="-100"
                maxBarLabel="100"
                :markerLabel="progressBarData.markerLabel"
            />
        </div>
    </div>
</template>

<script setup>
import Score from '../Score/Score.vue';
import ProgressBar from '@/components-deprecated/global/ProgressBar.vue';
import { defineProps } from 'vue';

defineProps({
    score: {
        type: Number,
        required: false
    },
    comparedScore: {
        type: Number,
        required: false
    },
    // Pass true to show score with a gradient background, colored based on the score's relative
    // value to the comparedScore. Otherwise, static default coloring is used.
    showScoreGradient: {
        type: Boolean,
        default: false
    },
    renderedComparedScore: {
        type: Object,
        required: false
    },
    progressBarClass: {
        type: String,
        required: true
    },
    progressBarData: {
        type: Object,
        required: true
    }
});
</script>

<style scoped lang="scss">
@import 'complete-score-progress.scss';
</style>
