<template>
    <modal
        classes="v--modal"
        name="modal-rename-tag-category"
        height="auto"
        width="600"
        :clickToClose="true"
        :stack="false"
        @before-open="beforeOpen"
        tabindex="-1"
        aria-labeledby="modal-title"
    >
        <div class="modal" v-if="tagCategory != null">
            <header class="modal-header">
                <h2 id="modal-title" class="modal-header-title">
                    Rename {{ tagCategory.name }} tag group
                </h2>
            </header>
            <div class="modal-input-container">
                <div class="name-input-label">New Name</div>
                <ValidatedInput
                    ref="input"
                    v-model="newName"
                    class="name-input"
                    type="text"
                    placeholder="Enter a new name"
                    :regexp="/[A-z0-9 \/\-]{0,30}/"
                />
            </div>
            <div class="button-container">
                <Button styleType="off" type="submit" @click="close()">
                    Cancel
                </Button>
                <loading-spinner-button
                    class="modal-button base-button"
                    text="Save"
                    :loading="loading"
                    type="submit"
                    @click="onRename()"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import Button from '@/components-deprecated/Button';
import ValidatedInput from '@/components-deprecated/inputs/ValidatedInput';
import TagCategories from '@/services/tagCategories';

export default {
    name: 'ModalRenameTagCategory',
    components: {
        LoadingSpinnerButton,
        ValidatedInput,
        Button
    },
    data() {
        return {
            loading: false,
            tagCategory: null,
            newName: ''
        };
    },
    methods: {
        beforeOpen(event) {
            this.tagCategory = event.params.tagCategory;
            this.updateHandler = event.params.updateHandler ? event.params.updateHandler : () => {};
        },
        close() {
            this.$modal.hide('modal-rename-tag-category');
        },
        onRename() {
            this.loading = true;
            this.tagCategory.name = this.newName;

            TagCategories.api
                .update(this.tagCategory)
                .then(() => {
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Tag Group successfully renamed!</h2>',
                        timeout: 3000
                    });
                    this.updateHandler(this.tagCategory);
                    this.$modal.hide('modal-rename-tag-category');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.modal {
    padding: 0;
    &-header {
        padding: 2.5rem;
    }
    &-content {
        padding: 24px;
        display: block;
        text-align: right;

        &-text {
            text-align: left;
            color: #000;
        }
    }
    &-button {
        &:last-child {
            margin-left: 1rem;
        }
    }
}

.name-input-label {
    margin-left: 15px;
    color: $accessible-gray;
}

.name-input {
    border-radius: 10px;
    border: 1px solid #1f8bfb;
    padding: 10px;
    margin: 10px;
    margin-top: 0px;
    width: 80%;
    &::placeholder {
        color: $accessible-gray !important;
    }
    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.modal-input-container {
    padding: 2rem;
}

.button-container {
    margin: 2rem;
    display: flex;
    justify-content: flex-end;
}
</style>
