<template>
    <div class="schools-modal-container">
        <Modal
            title="Assign Templates to School"
            primaryButtonTitle="Save"
            secondaryButtonTitle="Cancel"
            :primaryButtonDisabled="disableSave"
            :showPrimaryButtonLoadingSpinner="saving"
            @secondary-button-clicked="onCancel"
            @primary-button-clicked="onSave"
        >
            <div class="modal-content">
                <LoadingSpinner v-if="loading" />
                <TextInputWithSearch
                    v-else
                    placeholder="Search for a school..."
                    :options="schoolOptions"
                    v-model="searchValue"
                    @option-selected="onOptionSelected"
                />
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';
import TextInputWithSearch from '@/components-deprecated/global/TextInputWithSearch';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import { listSchools } from '@/api/school';

export default {
    name: 'SchoolsModal',
    components: { LoadingSpinner, TextInputWithSearch, Modal },
    props: {
        saving: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            schoolOptions: [],
            searchValue: '',
            selectedSchoolOption: null,
            createMode: false,
            createValue: '',
            creating: false,
            loading: false
        };
    },
    async created() {
        this.loading = true;

        // back end requires page and pageSize.
        // here we load a large single page containing all schools so we can "search" by name in the UI
        // todo: add support for a "search" param to BE route and search by name here
        const schools = await listSchools({
            queryParams: {
                page: 1,
                pageSize: 500
            }
        });

        if (typeof schools === 'object' && Array.isArray(schools.results)) {
            this.schoolOptions = schools.results.map(s => ({ value: s.id, label: s.name }));
            this.loading = false;
        }
    },
    methods: {
        onOptionSelected(option) {
            this.selectedSchoolOption = option;
        },
        async onSave() {
            if (
                typeof this.selectedSchoolOption === 'object' &&
                typeof this.selectedSchoolOption.value === 'string' &&
                typeof this.selectedSchoolOption.label === 'string'
            ) {
                this.$emit('onSave', {
                    id: this.selectedSchoolOption.value,
                    name: this.selectedSchoolOption.label
                });
            }
        },
        onCancel() {
            this.$emit('onClose');
        }
    },
    computed: {
        disableSave() {
            return !this.selectedSchoolOption;
        }
    }
};
</script>

<style lang="scss" scoped>
.schools-modal-container {
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>
