import api from './api';

import { objectToCamelCase, objectToSnakeCase } from '../utils';

export default class RiskBucketScore {
    static api = api.create(RiskBucketScore);

    constructor(riskBucketScore) {
        // eslint-disable-next-line no-param-reassign
        riskBucketScore = riskBucketScore || {};
        const {
            id = null,
            student = null,
            riskBucket = null,
            riskBucketRef = null,
            riskLevel = null,
            riskPercentile = null,
            score = null
        } = riskBucketScore;
        Object.assign(this, {
            id,
            student,
            riskBucket,
            riskBucketRef,
            riskLevel,
            riskPercentile,
            score
        });
    }

    static create(opts) {
        return new RiskBucketScore(opts);
    }

    static fromAPI(json) {
        return new RiskBucketScore(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }
}
