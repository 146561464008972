<template>
    <form class="login__form" v-on:submit.prevent="handleSubmit">
        <div class="login__form-top">
            <h2>Verify log in</h2>
            <div class="info">Verification code sent to {{ phoneNumber }}.</div>
        </div>
        <div class="line-after"></div>
        <div class="login__form-bottom">
            <div class="fields">
                <div class="login__form-text">
                    <h3 style="color:#808080">Code</h3>
                </div>
                <div class="field-group">
                    <input
                        class="login-input"
                        type="text"
                        v-model="mfaForm.mfaCode"
                        placeholder="Code"
                        tabindex="1"
                    />
                </div>

                <div class="field-group">
                    <loading-spinner-button
                        type="submit"
                        class="login-btn"
                        text="Verify"
                        :loading="loading"
                        tabindex="2"
                    ></loading-spinner-button>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';

export default {
    name: 'MfaForm',
    components: { LoadingSpinnerButton },
    data() {
        return {
            mfaForm: {
                mfaCode: ''
            }
        };
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        },
        phoneNumber: {
            type: String,
            required: true
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('submitMfa', this.mfaForm);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/login';
</style>
