<template>
    <div class="edited-date">{{ formattedDate }}</div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'LastEditedDate',
    props: {
        date: {
            type: String,
            default: null
        }
    },
    computed: {
        formattedDate() {
            const date = typeof this.date === 'string' && moment(this.date);
            if (date && date.isValid()) {
                return `Updated ${date.format('MMM. D, YYYY')}`;
            }

            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.edited-date {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    color: $accessible-gray;
    font-size: 0.9rem;
    font-weight: bold;

    padding-top: 1.2rem;
}
</style>
