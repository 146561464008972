<template>
    <div class="complex-table">
        <table>
            <thead>
                <tr>
                    <th
                        v-for="(column, i) in columns"
                        :key="i"
                        :class="{ 'sticky-header': isHeaderSticky }"
                    >
                        <slot :name="'col-' + column" :column="column" :sortBy="sortBy">{{
                            column
                        }}</slot>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="busy" class="table-row__loading">
                    <td :colspan="columns.length" :rowspan="rows.length > 0 ? rows.length : 1">
                        <slot name="table-row-loading"> </slot>
                    </td>
                </tr>
                <tr v-if="!busy && rows.length <= 0" class="table-row__empty">
                    <td :colspan="columns.length" :rowspan="rows.length > 0 ? rows.length : 1">
                        <slot name="table-row-empty"> </slot>
                    </td>
                </tr>
                <template v-if="!busy">
                    <tr class="table-row" v-for="(row, index) in rows" :key="index">
                        <td v-for="(cell, index) in columns" :key="index">
                            <slot :name="'cell-' + cell" :row="row" :click="click">
                                {{ row[cell] }}
                            </slot>
                        </td>
                    </tr>
                </template>
                <tr class="table-row__footer">
                    <td :colspan="columns.length" :rowspan="rows.length > 0 ? rows.length : 1">
                        <slot name="table-row-footer"> </slot>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        columns: { type: Array, required: true },
        rows: { type: Array, required: true },
        busy: { type: Boolean, default: false }
    },
    data() {
        return {
            hiddenColumns: []
        };
    },
    computed: {
        isHeaderSticky() {
            return this.$attrs.hasOwnProperty('stickyHeader');
        }
    },

    methods: {
        click(val) {
            this.$emit('click', val);
        },
        sortBy(val) {
            this.$emit('sort-by', val);
        },
        clickedRow(val) {
            //sends back index of row clicked for further action

            this.$emit('row-clicked', val);
        }
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.complex-table {
    width: 100%;
}
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    margin-left: 10px;
}
th,
td {
    text-align: left;
    vertical-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 10px;
    max-width: 100px;
}
td {
    padding: 0.5rem;
}
//custom only for this app
thead {
    background-color: $white-blue;
    font-weight: normal;
}
th {
    background-color: $white-blue;
    font-weight: normal;
    height: 40px;
    text-transform: capitalize;
}
th.sticky-header {
    position: sticky;
    top: 0;
}
tbody {
    cursor: pointer;
}
.table-row:hover {
    background-color: $off-gray;
    cursor: pointer;
}
</style>
