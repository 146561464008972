<template>
    <modal
        name="modal-student-upload-complete"
        height="auto"
        width="90%"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div class="container" v-if="upload">
            <h2>Complete!</h2>
            <br />
            <p>
                Your spreadsheet has been successfully uploaded to EdSights! Once we've processed
                your file, your updated students will be shown on the dashboard.
            </p>
            <h3>Quick Review</h3>
            <table>
                <colgroup>
                    <col style="width:15%" />
                    <col style="width:85%" />
                </colgroup>
                <tbody>
                    <tr>
                        <td>
                            Upload Type
                        </td>
                        <td class="item">
                            {{ upload.uploadTypeRef }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Title
                        </td>
                        <td class="item">
                            {{ upload.title }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Notes
                        </td>
                        <td class="item">
                            {{ upload.notes }}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            File
                        </td>
                        <td class="download-link text-primary" @click="onDownloadSpreadsheet">
                            Download your spreadsheet
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="footer">
                <p class="footer-note text-gray">
                    Want to make a change? Email your EdSights consultant!
                </p>
                <button class="save-btn" @click="onDone">
                    Done
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import StudentUpload from '@/services/studentUploads';
export default {
    name: 'ModalStudentUploadComplete',
    components: {},
    data() {
        return {
            upload: null
        };
    },
    methods: {
        beforeOpen(event) {
            this.upload = event.params.upload;
        },
        beforeClose() {
            this.$emit('closing');
        },
        onDownloadSpreadsheet() {
            window.open(this.upload.file, '_blank');
        },
        onDone() {
            this.$modal.hide('modal-student-upload-complete');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/mixins/buttons';
.container {
    padding: 3rem;
}
h3 {
    margin: 2rem 0 1rem;
    font-size: 1.2rem;
}
table {
    width: 100%;
    margin-bottom: 5rem;
}
.item {
    font-weight: bold;
    word-break: break-all;
}
.download-link {
    font-weight: bold;
    cursor: pointer;
}
.footer {
    width: 100%;
    display: flex;
    margin: 2rem 0;
    justify-content: space-between;
}
.footer-note {
    font-size: 0.9rem;
}
.save-btn {
    @include base-button();
}
</style>
