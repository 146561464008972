<template>
    <div class="tab-content-container" v-if="isActive"><slot></slot></div>
</template>

<script>
export default {
    name: 'Tab',
    props: {
        id: {
            type: String,
            default: 'Tab'
        },
        // label will render as the tab's label in TabsContainer
        // otherwise, the id will be shown as the label
        label: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            isActive: false
        };
    },
    computed: {
        renderedLabel() {
            if (typeof this.label === 'string') {
                return this.label;
            }

            if (typeof this.id === 'string') {
                return this.id;
            }

            return 'Tab';
        }
    },
    inject: ['registerTab', 'unregisterTab'],
    mounted() {
        this.registerTab(this);
    },
    beforeUnmount() {
        this.unregisterTab(this.id);
    }
};
</script>
<style lang="scss" scoped>
.tab-content-container {
    width: 100%;
}
</style>
