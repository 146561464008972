<template>
    <div v-if="fieldError(name)" class="field-error">
        {{ fieldErrorDetail(name) }}
    </div>
</template>

<script>
export default {
    name: 'FieldError',
    props: {
        name: {
            type: String,
            default: null
        },
        errors: {
            type: Array,
            default: []
        }
    },
    computed: {
        fieldError: function(name) {
            return name => this.errors.filter(e => e.field === name).length > 0;
        },
        fieldErrorDetail: function(name) {
            return name => this.errors.filter(e => e.field === name)[0].detail;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.field-error {
    color: $amaranth;
}
</style>
