<template>
    <modal
        classes="v--modal"
        name="modal-alerts"
        height="auto"
        width="800"
        :minWidth="700"
        :scrollable="true"
        :clickToClose="true"
        :stack="false"
        tabindex="0"
    >
        <div class="modal">
            <AlertCard
                class="alert-card"
                v-for="alert in alerts"
                :key="alert.id"
                :alert="alert"
                :showStudentInfo="false"
                @on-status-update="updateStatus"
            />
        </div>
    </modal>
</template>

<script>
import AlertCard from '@/components-deprecated/admin/AlertCard';

export default {
    components: { AlertCard },
    props: {
        alerts: {
            type: Array,
            required: true
        }
    },
    data() {
        return {};
    },
    methods: {
        updateStatus(alertId, status, message) {
            this.$emit('update-status', alertId, status, message);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/mixins/lists';
@import '../../styles/views/modal-small';

.modal {
    padding: 2rem 3rem;
}

.alert-card {
    padding: 2rem;
    margin: 1rem;
    border-bottom: 1px solid $secondary-gray;
}
</style>
