<template>
    <Button
        class="back-to-staff-portal-button"
        style-type="text"
        :unbordered="true"
        @click="backToStaffPortal"
    >
        < Back to Staff Portal
    </Button>
</template>

<script>
import { mapActions } from 'vuex';
import Button from '@/components-deprecated/Button';

export default {
    name: 'BackToStaffPortalButton',
    components: { Button },
    methods: {
        ...mapActions(['setStaffSchoolViewEnabled']),
        backToStaffPortal() {
            this.setStaffSchoolViewEnabled(false);
            this.$router.push({ name: 'StaffHome' });
        }
    }
};
</script>

<style lang="scss" scoped>
.back-to-staff-portal-button {
    position: fixed;
    left: 0;
    padding-left: 25px;
    font-size: 14px;
    color: white;
}
</style>
