const MENTAL_HEALTH_TRIGGER = 'Mental Health';
const DROPOUT_TRIGGER = 'Dropout';
const VIOLENCE_TRIGGER = 'Violence';
const COVID_TRIGGER = 'COVID';
const TITLE_IX_TRIGGER = 'Title IX';

const triggerCategories = {
    MENTAL_HEALTH_TRIGGER: MENTAL_HEALTH_TRIGGER,
    DROPOUT_TRIGGER: DROPOUT_TRIGGER,
    VIOLENCE_TRIGGER: VIOLENCE_TRIGGER,
    COVID_TRIGGER: COVID_TRIGGER,
    TITLE_IX_TRIGGER: TITLE_IX_TRIGGER
};

const PENDING = 0;
const ACTION_TAKEN = 1;
const DISMISSED = 2;

const alertStatuses = {};
alertStatuses[PENDING] = 'Pending';
alertStatuses[ACTION_TAKEN] = 'Action Taken';
alertStatuses[DISMISSED] = 'Dismissed';

export default {
    MENTAL_HEALTH_TRIGGER,
    DROPOUT_TRIGGER,
    VIOLENCE_TRIGGER,
    COVID_TRIGGER,
    TITLE_IX_TRIGGER,
    triggerCategories,
    ACTION_TAKEN,
    PENDING,
    DISMISSED,
    alertStatuses
};
