import axios from 'axios';

import { API_BASE, apiErrorHandler } from '../api';
import Auth from '../auth';

const CHATBOT_TEMPLATE_ENDPOINT = `${API_BASE}chatbot-templates/`;

export default class ChatbotTemplateAPI {
    constructor(cls) {
        this.cls = cls;
    }

    static create(cls) {
        return new ChatbotTemplateAPI(cls);
    }

    async list(params = {}) {
        const options = {
            headers: await Auth.getHeaders().headers,
            params
        };

        const promise = axios
            .get(CHATBOT_TEMPLATE_ENDPOINT, options)

            .then(response => {
                return {
                    ...response.data,
                    results: response.data.results.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Chatbot Template API Error' }));

        return promise;
    }

    async retrieve(id, params = {}) {
        const url = `${CHATBOT_TEMPLATE_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers,
            params
        };
        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Retrieve Chatbot Template API Error' }));

        return promise;
    }

    async update(obj) {
        const url = `${CHATBOT_TEMPLATE_ENDPOINT}${obj.id}/`;
        const data = this.cls.toAPI(obj);
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(
                apiErrorHandler({
                    apiName: 'Chatbot Template API error'
                })
            );
        return promise;
    }

    async create(obj) {
        const url = CHATBOT_TEMPLATE_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = this.cls.toAPI(obj);

        const promise = axios
            .post(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Create Chatbot Template Api Error' }));
        return promise;
    }

    async delete(id) {
        const url = `${CHATBOT_TEMPLATE_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .delete(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Delete Chatbot Template Api Error' }));
        return promise;
    }
}
