import axios from 'axios';
import { API_BASE, apiErrorHandler } from '../api';
import Auth from '../auth';

const BACKGROUND_OPERATION_ENDPOINT = `${API_BASE}background-operations/`;

export default class BackgroundOperationAPI {
    constructor(cls) {
        this.cls = cls;
    }

    static create(cls) {
        return new BackgroundOperationAPI(cls);
    }

    async retrieve(id) {
        const url = `${BACKGROUND_OPERATION_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(
                // NIP 8/18/20 Don't show 500 alerts for Background Operations. We check this endpoint
                //             regularly, which could generate a flood of alerts
                apiErrorHandler({
                    apiName: 'Background Operation Retrieve API Error',
                    enable500Alert: false
                })
            );

        return promise;
    }
}
