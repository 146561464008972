<template>
    <modal
        classes="v--modal"
        name="integration-warnings-modal"
        width="800"
        height="auto"
        :clickToClose="false"
        :scrollable="true"
        :adaptive="true"
        :stack="false"
        @before-open="beforeOpen"
    >
        <div class="modal">
            <header class="modal__header">
                <h3 class="modal__header__title">
                    Review Warnings
                </h3>
            </header>
            <div class="modal__content">
                <p>
                    The integration file created the following warning(s). Cancel will mark the file
                    as invalid. Continue will proceed with the integration.
                </p>
                <div class="integration-log">
                    <p v-for="(finding, index) in warnings.split('\\\\')" :key="index">
                        {{ finding }}
                    </p>
                </div>
                <div class="buttons">
                    <Button @click="onCancel()" class="base-button">Cancel</Button>
                    <Button @click="onContinue()" class="base-button">Continue</Button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Button from '@/components-deprecated/Button';

export default {
    components: {
        Button
    },
    data() {
        return {
            warnings: '',
            integrationIndex: -1
        };
    },
    methods: {
        beforeOpen(event) {
            this.warnings = event.params.warnings;
            this.integrationIndex = event.params.integrationIndex;
        },
        onCancel() {
            this.$emit('onWarningsModalCancel', this.integrationIndex);
        },
        onContinue() {
            this.$emit('onWarningsModalContinue', this.integrationIndex);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/views/modal';
@import '../styles/forms';
@import '../styles/variables';

.modal {
    &__header {
        background-color: white;
        padding: 1rem 0rem;
        margin: 0rem;
        border-bottom: solid 1px #e3eded;
        &__title {
            margin: 0 2rem;
            font-weight: bold;
            font-size: 1.5rem;
            color: #202123;
        }
    }
}
.modal__content {
    padding: 0rem 0rem;
}
.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.base-button {
    margin-left: 1rem;
}
</style>
