<template>
    <div class="container">
        <TabsContainer initialTabId="School Dashboards">
            <Tab id="School Dashboards">
                <DashboardToolsTab />
            </Tab>
            <Tab id="User Tools">
                <UserToolsTab />
            </Tab>
            <Tab id="SFTP Tools">
                <SftpToolsTab />
            </Tab>
            <Tab id="Phone Verification">
                <PhoneVerificationTab />
            </Tab>
            <Tab id="Feature Flags">
                <FeatureFlagsTab />
            </Tab>
        </TabsContainer>
    </div>
</template>

<script>
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import DashboardToolsTab from '@/views/staff/StaffTools/components/DashboardTools';
import SftpToolsTab from '@/views/staff/StaffTools/components/SftpTools';
import UserToolsTab from '@/views/staff/StaffTools/components/UserTools';
import PhoneVerificationTab from '@/views/staff/StaffTools/components/PhoneVerificationTools';
import FeatureFlagsTab from '@/views/staff/StaffTools/components/FeatureFlagTools';

export default {
    name: 'StaffToolsList',
    components: {
        TabsContainer,
        Tab,
        DashboardToolsTab,
        UserToolsTab,
        SftpToolsTab,
        PhoneVerificationTab,
        FeatureFlagsTab
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.container {
    :deep() {
        .tabs-container .tabs .tab {
            font-size: 1.333rem;
        }
    }
}
</style>
