<template>
    <form class="login__form" v-on:submit.prevent="handleSubmit">
        <div class="login__form-top">
            <h2>Log in</h2>
        </div>
        <div class="line-after"></div>
        <div class="login__form-bottom">
            <div class="fields">
                <div class="login__form-text">
                    <label for="email" class="login__form-label">Email</label>
                </div>
                <div class="field-group">
                    <input
                        name="email"
                        id="email"
                        class="login-input"
                        type="text"
                        v-model="loginForm.email"
                        placeholder="Email"
                        autocomplete="email"
                    />
                </div>

                <div class="login__form-text">
                    <label for="password" class="login__form-label">Password</label>
                </div>
                <div class="field-group">
                    <input
                        name="password"
                        id="password"
                        class="login-input"
                        type="password"
                        v-model="loginForm.password"
                        placeholder="Password"
                        autocomplete="current-password"
                    />
                </div>
                <router-link class="login__form-label link" :to="{ name: 'ForgotPassword' }">
                    <span class=" link">Forgot password?</span>
                </router-link>

                <div class="field-group">
                    <loading-spinner-button
                        type="submit"
                        class="login-btn"
                        :text="'Sign in'"
                        :loading="loading"
                    ></loading-spinner-button>
                </div>
            </div>
        </div>
    </form>
</template>
<script>
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';

export default {
    name: 'LoginForm',
    components: { LoadingSpinnerButton },
    data() {
        return {
            loginForm: {
                email: '',
                password: ''
            }
        };
    },
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('submitLogin', {
                email: this.loginForm.email.toLowerCase().trim(),
                password: this.loginForm.password
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/login';
</style>
