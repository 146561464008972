import axios from 'axios';
import { API_BASE, apiErrorHandler } from '../api';
import { objectToSnakeCase } from '../utils';
import Auth from '../auth';
import store from '../../store';

const STUDENT_UPLOAD_ENDPOINT = `${API_BASE}uploads/student-uploads/`;

export default class StudentUploadAPI {
    constructor(cls) {
        this.cls = cls;
    }

    static create(cls) {
        return new StudentUploadAPI(cls);
    }

    list(params) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            },
            params: objectToSnakeCase(params)
        };

        const promise = axios
            .get(`${STUDENT_UPLOAD_ENDPOINT}`, options)
            .then(response => response.data)
            .then(data => {
                return {
                    ...data,
                    results: data.results.map(this.cls.fromAPI)
                };
            })
            .catch(
                apiErrorHandler({
                    apiName: 'List Student Upload API error'
                })
            );
        return promise;
    }

    async create(studentUpload) {
        const url = STUDENT_UPLOAD_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = new FormData();
        data.append('file', studentUpload.file);
        data.append('title', studentUpload.title);
        data.append('upload_type', studentUpload.uploadType);
        data.append('notes', studentUpload.notes);
        data.append('school', studentUpload.school);
        data.append('status', studentUpload.status);

        const promise = axios
            .post(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Create Student Upload API error' }));
        return promise;
    }

    async update(studentUpload) {
        const url = `${STUDENT_UPLOAD_ENDPOINT}${studentUpload.id}/`;
        const data = this.cls.toAPI(studentUpload);
        const options = {
            headers: Auth.getHeaders().headers
        };

        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Update Student Upload API error' }));
        return promise;
    }

    async delete(studentUpload) {
        const url = `${STUDENT_UPLOAD_ENDPOINT}${studentUpload.id}/`;
        const options = {
            headers: Auth.getHeaders().headers
        };

        const promise = axios
            .delete(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Delete Student Upload API error' }));
        return promise;
    }
}
