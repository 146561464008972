<template>
    <BoxContainer class="copypaste" :subtitle="title">
        <div class="copypaste__content">
            <div class="text-gray">
                Copy and paste a list of student emails. Only one email per row (works best from
                spreadsheet column).
            </div>
            <LineNumberTextArea class="copypaste__textarea" :full-width="true" v-model="text">
                <template slot="placeholder">
                    Email address...
                    <br />Email address... <br />Email address... <br />Email address... <br />Email
                    address... <br />Email address...
                    <br />
                </template>
            </LineNumberTextArea>
            <div class="copypaste__btns" v-if="text || modal">
                <Button class="copypaste__btn" styleType="off" @click="onClickCancel"
                    >Cancel</Button
                >
                <Button class="copypaste__btn" :loading="uploading" @click="onClickSave"
                    >Save</Button
                >
            </div>
        </div>
    </BoxContainer>
</template>

<script>
import BoxContainer from '@/components-deprecated/BoxContainer';
import LineNumberTextArea from '@/components-deprecated/LineNumberTextArea';
import Button from '@/components-deprecated/Button';

export default {
    name: 'CopyPasteEmails',

    components: {
        BoxContainer,
        LineNumberTextArea,
        Button
    },

    props: {
        uploading: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        modal: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            text: ''
        };
    },

    methods: {
        onClickCancel() {
            this.reset();
            this.$emit('cancel');
        },
        onClickSave() {
            const emails = this.text
                .split('\n')
                .map(email => email.trim())
                .filter(email => !!email);
            this.$emit('save', emails);
        },
        reset() {
            this.text = '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.copypaste {
    &__content {
        padding: 25px 45px;
    }

    &__textarea {
        margin-top: 25px;
        min-height: 200px;
    }

    &__btns {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }

    &__btn {
        width: 140px;
        margin-left: 15px;
    }
}
</style>
