<template>
    <BaseCard class="inline-score-progress-container-card">
        <template #content>
            <div class="inline-score-progress-container">
                <div class="tag-container">
                    {{ scoreProgressLabel }} ({{ studentsCount }})
                </div>
                <div class="score-container" v-if="studentsCount > 0">
                    {{ Math.round(score) }}
                </div>
                <div class="progress-container" v-if="studentsCount > 0">
                    <ProgressBar
                        class="progress-bar"
                        :class="progressBarClass"
                        :percent="progressBarData.percent"
                        :markerPercent="progressBarData.markerPercent"
                        :markerLabel="progressBarData.markerLabel"
                    />
                    <div class="compared-score" v-if="renderedComparedScore">
                        <div :class="renderedComparedScore.differenceClass">
                            {{ renderedComparedScore.differenceLabel }}
                        </div>
                    </div>
                </div>
                <div class="empty-progress-container" v-else>
                    N/A - No responses collected
                </div>
            </div>
        </template>
    </BaseCard>
</template>

<script setup>
import { BaseCard } from '@edsights/ui-core';
import ProgressBar from '@/components-deprecated/global/ProgressBar.vue';
import { defineProps } from 'vue';

defineProps({
    score: {
        type: Number,
        required: false
    },
    comparedScore: {
        type: Number,
        required: false
    },
    // Pass true to show score with a gradient background, colored based on the score's relative
    // value to the comparedScore. Otherwise, static default coloring is used.
    showScoreGradient: {
        type: Boolean,
        default: false
    },
    renderedComparedScore: {
        type: Object,
        required: true
    },
    progressBarClass: {
        type: String,
        required: true
    },
    progressBarData: {
        type: Object,
        required: true
    },
    studentsCount: {
        type: Number,
        default: 0
    },
    scoreProgressLabel: {
        type: String,
        required: false
    }
});
</script>

<style scoped lang="scss">
@import 'inline-score-progress';
</style>
