<template>
    <div
        :class="isExperimentalAccessibilityEnabled ? 'student accessible' : 'student'"
        @click="onClickStudent"
        role="row"
    >
        <div @click.stop class="student-checkbox" role="cell">
            <BaseCheckbox
                :modelValue="selected"
                @update:modelValue="toggleSelected"
                :ariaLabel="`Select ` + student.displayName"
            />
        </div>

        <slot name="row" v-bind="$props">
            <div
                :class="{ 'new-item': boldItem == true }"
                class="student-name"
                v-tooltip="
                    !isExperimentalAccessibilityEnabled
                        ? { content: student.displayName, autoHide: false }
                        : {}
                "
                role="cell"
            >
                <a class="student-name-link">{{ student.displayName }}</a>
            </div>

            <div :class="{ 'new-item': boldItem == true }" class="student-id" role="cell">
                {{ student.studentId }}
            </div>
            <div :class="{ 'new-item': boldItem == true }" class="student-level" role="cell">
                <div class="risk-indicator" :class="indicatorClass" />
                {{ formatRiskLevel(student.overallRiskLevel) }}
            </div>

            <!-- showRiskDrivers is false on Interventions Page -->

            <div v-if="showRiskDrivers" class="student-driver" role="cell">
                <button
                    class="student__icon"
                    :aria-label="
                        isExperimentalAccessibilityEnabled
                            ? formatTooltip('Financial', student.academicRiskLevel)
                            : ''
                    "
                    v-tooltip="
                        !isExperimentalAccessibilityEnabled
                            ? {
                                  content: formatTooltip('Financial', student.financialRiskLevel),
                                  autoHide: false
                              }
                            : {}
                    "
                >
                    <svg
                        v-if="student.financialRiskLevel"
                        class="student__risk-icon"
                        focusable="false"
                        aria-hidden="true"
                        :class="`icon-${student.financialRiskLevel}`"
                    >
                        <use xlink:href="#icon-risk-financial" />
                    </svg>
                    <span v-if="isExperimentalAccessibilityEnabled">{{
                        formatText(student.financialRiskLevel)
                    }}</span>
                </button>
                <button
                    class="student__icon"
                    :aria-label="
                        isExperimentalAccessibilityEnabled
                            ? formatTooltip('Academic', student.academicRiskLevel)
                            : ''
                    "
                    v-tooltip="
                        !isExperimentalAccessibilityEnabled
                            ? {
                                  content: formatTooltip('Academic', student.academicRiskLevel),
                                  autoHide: false
                              }
                            : {}
                    "
                >
                    <svg
                        v-if="student.academicRiskLevel"
                        class="student__risk-icon"
                        focusable="false"
                        aria-hidden="true"
                        :class="`icon-${student.academicRiskLevel}`"
                    >
                        <use xlink:href="#icon-risk-academic" />
                    </svg>
                    <span v-if="isExperimentalAccessibilityEnabled">{{
                        formatText(student.academicRiskLevel)
                    }}</span>
                </button>
                <button
                    class="student__icon"
                    :aria-label="
                        isExperimentalAccessibilityEnabled
                            ? formatTooltip('Engagement', student.engagementRiskLevel)
                            : ''
                    "
                    v-tooltip="
                        !isExperimentalAccessibilityEnabled
                            ? {
                                  content: formatTooltip('Engagement', student.engagementRiskLevel),
                                  autoHide: false
                              }
                            : {}
                    "
                >
                    <svg
                        v-if="student.engagementRiskLevel"
                        class="student__risk-icon"
                        focusable="false"
                        aria-hidden="true"
                        :class="`icon-${student.engagementRiskLevel}`"
                    >
                        <use xlink:href="#icon-risk-engagement" />
                    </svg>
                    <span v-if="isExperimentalAccessibilityEnabled">{{
                        formatText(student.engagementRiskLevel)
                    }}</span>
                </button>
                <button
                    class="student__icon"
                    :aria-label="
                        isExperimentalAccessibilityEnabled
                            ? formatTooltip('Wellness', student.wellnessRiskLevel)
                            : ''
                    "
                    v-tooltip="
                        !isExperimentalAccessibilityEnabled
                            ? {
                                  content: formatTooltip('Wellness', student.wellnessRiskLevel),
                                  autoHide: false
                              }
                            : {}
                    "
                >
                    <svg
                        v-if="student.wellnessRiskLevel"
                        class="student__risk-icon-belief"
                        focusable="false"
                        aria-hidden="true"
                        :class="`icon-belief-${student.wellnessRiskLevel}`"
                    >
                        <use xlink:href="#icon-risk-belief" />
                    </svg>
                    <span v-if="isExperimentalAccessibilityEnabled">{{
                        formatText(student.wellnessRiskLevel)
                    }}</span>
                </button>
                <span
                    v-if="
                        !student.engagementRiskLevel &&
                            !student.financialRiskLevel &&
                            !student.academicRiskLevel &&
                            !student.wellnessRiskLevel
                    "
                    >N/A</span
                >
            </div>
            <div
                :class="{ 'new-item': boldItem == true }"
                v-if="showDateAdded"
                class="student-date-added"
                role="cell"
            >
                <span>{{ formatDate(student.dateAdded) }}</span>
            </div>
            <div
                v-if="showOutreachStatus"
                class="student-outreach-status"
                :class="{ enabled: toggleOutreachEnabled }"
                @click.stop="toggleOutreach"
                role="cell"
            >
                <svg
                    width="30px"
                    height="30px"
                    class="outreach-status-icon"
                    :class="{
                        'icon-active': student.outreachStatus,
                        'icon-inactive': !student.outreachStatus
                    }"
                    viewBox="0 0 150 150"
                >
                    <use xlink:href="#icon-tick-circle" />
                </svg>
                <p
                    v-if="student.outreachStatus"
                    class="admin-initials"
                    v-tooltip="student.adminName"
                >
                    {{ student.adminInitials }}
                </p>
            </div>
        </slot>
    </div>
</template>

<script>
import { formatRiskLevel, formatRiskAbbreviations } from '@/services/filters';

import { BaseCheckbox } from '@edsights/ui-core';
import { LOW_RISK, MEDIUM_RISK, HIGH_RISK } from '@/consts';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';
import { vueMomentFilterReplacement } from '@/lib/vue-migration-helpers';

export default {
    name: 'StudentListItem',
    components: {
        BaseCheckbox
    },
    props: {
        // Emits with events
        selected: {
            type: Boolean,
            default: false
        },
        student: {
            type: Object,
            default: () => {}
        },
        showRiskDrivers: {
            type: Boolean,
            default: true
        },
        showOutreachStatus: {
            type: Boolean,
            default: true
        },
        showDateAdded: {
            type: Boolean,
            default: true
        },
        boldItem: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        indicatorClass() {
            switch (this.student.overallRiskLevel) {
                case LOW_RISK:
                    return 'risk-indicator--low';
                case MEDIUM_RISK:
                    return 'risk-indicator--medium';
                case HIGH_RISK:
                    return 'risk-indicator--high';
            }
            return '';
        },
        toggleOutreachEnabled() {
            return (
                this.student.outreachStatus === false ||
                (this.student.outreachStatus === true &&
                    this.student.adminId === this.$store.state.user.id)
            );
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    },

    methods: {
        formatRiskLevel(payload) {
            return formatRiskLevel(payload);
        },
        formatDate(date) {
            return vueMomentFilterReplacement(date, 'MM/DD/YYYY');
        },
        onClickStudent() {
            this.$emit('studentClick', this.student);
        },
        toggleSelected(value) {
            if (value) {
                this.$emit('select', this.student);
            } else {
                this.$emit('unselect', this.student);
            }
        },
        toggleOutreach() {
            if (this.toggleOutreachEnabled) {
                let markAs = !this.student.outreachStatus;
                this.$emit('toggleOutreach', markAs, this.student.id);
            }
        },
        formatTooltip(riskDriver, riskLevel) {
            return `${riskDriver} Risk Level: ${formatRiskLevel(riskLevel)}`;
        },
        formatText(riskLevel) {
            return `${formatRiskAbbreviations(riskLevel)}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.student {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 15px 4px 15px;
    height: 40px;
    border-bottom: none;
    font-size: 14px;

    & > * {
        display: inline-block;
        margin-right: 2.5%;
    }

    &:hover {
        background-color: $off-gray;
        cursor: pointer;
    }
    &-checkbox {
        width: 5%;
    }

    &-level {
        width: 20%;
        display: inline-flex;
        align-items: center;
    }

    &-name {
        width: 25%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &-name-link {
        color: #000;
    }

    &-id {
        width: 20%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &-driver {
        width: 25%;
        display: inline-flex;
        align-items: center;
    }

    &-date-added {
        width: 20%;
        display: inline-flex;
        align-items: center;
    }

    &-outreach-status {
        width: 20%;
        display: inline-flex;
        align-items: center;
        cursor: default;

        &.enabled {
            cursor: pointer;
        }
    }

    &__risk-icon {
        width: 30px;
        height: 30px;
        color: $gainsboro;
    }

    &__risk-icon-belief {
        background-color: $gainsboro;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-left: 3px;
    }

    &__icon {
        background: none;
        border: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    &.accessible {
        .student__icon {
            border: 1px solid $accessible-gray;
            padding: 0 4px;
            margin-right: 4px;
            border-radius: 100px;
            min-height: 36px;
            span {
                margin-left: 4px;
                padding-right: 4px;
            }
        }
        .student__risk-icon {
            color: $accessible-gray;
        }

        .student__risk-icon-belief {
            background-color: $accessible-gray;
        }

        .icon-HIGH_RISK {
            color: $color-high-risk;
        }

        .icon-MEDIUM_RISK {
            color: $color-medium-risk;
        }

        .icon-LOW_RISK {
            color: #198507;
        }

        .icon-belief-HIGH_RISK {
            background-color: $color-high-risk;
        }

        .icon-belief-MEDIUM_RISK {
            background-color: $color-medium-risk;
        }

        .icon-belief-LOW_RISK {
            background-color: #198507;
        }
        .risk-indicator {
            &--low {
                background-color: #198507;
            }
            &--high {
                background-color: $color-high-risk;
            }
            &--medium {
                background-color: $color-medium-risk;
            }
        }
    }
}

.icon-belief-HIGH_RISK {
    background-color: $color-high-risk;
}

.icon-belief-MEDIUM_RISK {
    background-color: $color-medium-risk;
}

.icon-belief-LOW_RISK {
    background-color: $color-low-risk;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-left: 3px;
}

.icon-HIGH_RISK {
    color: $color-high-risk;
}

.icon-MEDIUM_RISK {
    color: $color-medium-risk;
}

.icon-LOW_RISK {
    color: $color-low-risk;
}

.risk-indicator {
    min-height: 10px;
    min-width: 10px;
    max-height: 10px;
    max-width: 10px;

    border-radius: 50%;
    background-color: transparent;
    margin-right: 1rem;
    &--low {
        background-color: $color-low-risk;
    }
    &--low-accessible {
        background-color: #198507;
    }
    &--high {
        background-color: $color-high-risk;
    }
    &--medium {
        background-color: $color-medium-risk;
    }
}
.outreach-status-icon.icon-inactive {
    fill: $secondary-gray;
}
.outreach-status-icon.icon-active {
    fill: $primary-brand-color;
}

.admin-initials {
    margin-left: 0.5rem;
}
</style>
