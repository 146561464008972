<template>
    <modal
        classes="v--modal"
        name="modal-delete-tag"
        height="300"
        width="600"
        :clickToClose="true"
        :stack="false"
        @before-open="beforeOpen"
        tabindex="-1"
        aria-labelledby="modal-title"
    >
        <div class="modal">
            <header class="modal-header">
                <h2 id="modal-title" class="modal-header-title">{{ title }}</h2>
            </header>
            <div class="modal-content">
                <p>
                    You're about to permanently delete {{ tags.length }} tag<template
                        v-if="tags.length > 1"
                        >s</template
                    >. This action cannot be undone.
                </p>
                <br />
                <p>If you're not sure, you can click Cancel instead.</p>
            </div>
            <div class="button-container">
                <Button styleType="off" type="submit" @click="close()">
                    Cancel
                </Button>
                <loading-spinner-button
                    class="modal-button base-button"
                    text="Delete"
                    :loading="loading"
                    type="submit"
                    @click="onDelete()"
                />
            </div>
        </div>
    </modal>
</template>
<script>
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import Tags from '@/services/tags';
import Button from '@/components-deprecated/Button';

export default {
    components: {
        LoadingSpinnerButton,
        Button
    },
    data() {
        return {
            loading: false,
            tags: []
        };
    },
    computed: {
        title() {
            if (this.tags.length > 1) {
                return `Delete ${this.tags.length} tags?`;
            } else if (this.tags.length == 1) {
                return `Delete ${this.tags[0].name} tag?`;
            }
            return '';
        }
    },
    methods: {
        beforeOpen(event) {
            this.tags = event.params.tags;
            this.updateHandler = event.params.updateHandler ? event.params.updateHandler : () => {};
        },
        close() {
            this.$modal.hide('modal-delete-tag');
        },
        onDelete() {
            this.loading = true;
            let promises = this.tags.map(tag => {
                return Tags.api.deleteTag(tag.id);
            });
            Promise.all(promises)
                .then(() => {
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Tag(s) successfully deleted!</h2>',
                        timeout: 3000
                    });
                    this.updateHandler();
                    this.$modal.hide('modal-delete-tag');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';

.modal {
    padding: 0;
    &-header {
        padding: 2.5rem;
    }
    &-content {
        padding: 24px;
        display: block;

        &-text {
            text-align: left;
            color: #000;
        }
    }
    &-button {
        &:last-child {
            margin-left: 1rem;
        }
    }
}

.name-input-label {
    margin-left: 15px;
    color: $accessible-gray;
}

.modal-content {
    padding: 2rem;
}

.button-container {
    margin: 2rem;
    display: flex;
    justify-content: flex-end;
}
</style>
