<!--
To use a custom header template and/or rows template, use the named slots like below:

<Table :data="data">
  <template v-slot:header>
    <tr>
      <th>Name</th>
      <th>Labels</th>
    </tr>
  </template>

  <template v-slot:rows="{ rows }">
    <tr v-for="row in rows" :key="row.id">
      <td>
        <h3>{{ row.name }}</h3>
        {{ row.date }}
      </td>
      <td>
        {{ row.labels }}
      </td>
    </tr>
  </template>
</Table>

-->
<template>
    <div class="table-container">
        <table v-if="data">
            <thead v-if="showHeader">
                <slot name="header">
                    <!-- Custom header template renders here, if provided -->

                    <!-- Standard  element (discarded by Vue when custom header template is provided) -->
                    <tr v-if="columns">
                        <th scope="col" v-for="column in columns" :key="column.dataKey">
                            {{ column.label }}
                        </th>
                    </tr>
                </slot>
            </thead>
            <tbody>
                <slot name="rows" v-bind:rows="rows">
                    <!-- Custom row template renders here, if provided -->

                    <!-- Standard  element (discarded by Vue when custom row template is provided) -->
                    <tr v-for="row in rows" :key="row.id">
                        <td v-for="cell in row.cells" :key="cell.dataKey">{{ cell.data }}</td>
                    </tr>
                </slot>
            </tbody>
        </table>
        <Paginator
            v-if="hasNextPage"
            :current-page="currentPage"
            :nextPage="hasNextPage"
            @next-page="$emit('next-page')"
            :loading="nextPageLoading"
        ></Paginator>
    </div>
</template>

<script>
import Paginator from '@/components-deprecated/Paginator';

export default {
    name: 'Table',
    components: { Paginator },
    props: {
        columns: {
            type: Array,
            required: false,
            validator: function(columns) {
                return columns.every(
                    c => typeof c.label === 'string' && typeof c.dataKey === 'string'
                );
            }
        },
        data: {
            type: Array,
            required: true,
            validator: function(data) {
                // if you aren't using custom rows. each data item must have a matching key for every
                // column's "dataKey"
                return data.every(d => typeof d.id === 'string');
            }
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        },
        currentPage: {
            type: [String, Number],
            required: false
        },
        hasNextPage: {
            type: Boolean,
            default: false
        },
        nextPageLoading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        /**
         * When no custom row slot template is in use, this computes cell data based on the "datakey"
         * for each item in "columns". To show a value for a particular column, the items in "data"
         * must have a key matching the column's "dataKey". When a custom row slot template is in use,
         * this returns the unmodified "data" prop for use in the custom template.
         * @returns {{cells: [], id: String}[]|*[]}
         */
        rows() {
            const customRowsSlot = this.$slots.rows;
            if (customRowsSlot && Array.isArray(this.data)) {
                return this.data;
            }
            if (!customRowsSlot && Array.isArray(this.columns) && Array.isArray(this.data)) {
                return this.data.map(row => {
                    const cells = [];
                    const rowId = row.id;
                    this.columns.forEach(column => {
                        const { dataKey } = column;
                        cells.push({
                            dataKey: dataKey,
                            data: row[dataKey]
                        });
                    });
                    return {
                        id: rowId,
                        cells
                    };
                });
            }

            return [];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
table {
    width: 100%;
    text-align: left;
    border: none;
    th,
    td {
        padding: 0 10px;
    }

    thead {
        background-color: $white-blue;
        th {
            font-size: 14px;
        }
    }
}
</style>
