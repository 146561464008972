import axios from 'axios';
import Auth from '../auth';
import Pagination from '../pagination';
import { API_BASE, apiErrorHandler } from '../api';
import LargeOperation from '@/services/largeOperations';

// Endpoints
const TAGS_ENDPOINT = `${API_BASE}tags/`;

export default class TagsAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new TagsAPI(cls);
    }

    async clearUsers(tagID, users) {
        const url = `${TAGS_ENDPOINT}${tagID}/clear-users/`;
        const data = { users };
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Clear User Tags Api Error' }));
        return promise;
    }

    /**
     * @function      createTag
     * @description   Create a tag for the given user.
     * @param         tags <String> name of new tag
     **/

    async createTag(tag) {
        const url = TAGS_ENDPOINT;
        const data = tag;
        const options = {
            headers: await Auth.getHeaders().headers
        };

        const promise = axios
            .post(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Create Tag Api Error' }));
        return promise;
    }

    /**
     * @function      createTags
     * @description   Create tags for the given user.
     * @param         tags <Array> of Objects with 'name' and 'category' properties of new tags
     **/
    async createTags(tags) {
        const url = TAGS_ENDPOINT;
        const data = tags;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .post(url, data, options)
            .then(response => response.data.map(this.cls.fromAPI))
            .catch(apiErrorHandler({ apiName: 'Create Tag Api Error' }));
        return promise;
    }

    async list(params = {}) {
        const url = TAGS_ENDPOINT;

        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            },
            params
        };

        const promise = axios
            .get(url, options)

            .then(response => {
                const data = response.data.results ? response.data.results : response.data;
                return {
                    ...response.data,
                    results: data.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Tags Api Error' }));

        return promise;
    }

    async listAllTags() {
        let response = await this.list();

        let pagination = Pagination.create({ next: response.next, count: response.count });
        let totalPageCount = Pagination.calcTotalPages(pagination);
        if (totalPageCount > 1) {
            for (let i = 1; i < totalPageCount; i++) {
                const filtersForNextPage = {
                    page: i + 1
                };
                const nextResponse = await this.list(filtersForNextPage);
                response = {
                    ...nextResponse,
                    results: [...response.results, ...nextResponse.results]
                };
            }
        }

        return response;
    }

    async retrieveTag(tagId, params = {}) {
        const url = `${TAGS_ENDPOINT}${tagId}/`;
        const options = {
            params: { ...params },
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };
        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Retrieve Tag Api Error' }));
        return promise;
    }

    async updateTag(tag) {
        const url = `${TAGS_ENDPOINT}${tag.id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .patch(url, tag, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Update Tag Api Error' }));
        return promise;
    }

    async deleteTag(tagId) {
        const url = `${TAGS_ENDPOINT}${tagId}/`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };
        const promise = axios
            .delete(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Delete Tag Api Error' }));
        return promise;
    }

    async updateTags(tags) {
        const url = `${TAGS_ENDPOINT}bulk-update/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .patch(url, tags, options)
            .then(response => response.data.map(this.cls.fromAPI))
            .catch(apiErrorHandler({ apiName: 'Bulk Update Tags Api Error' }));
        return promise;
    }

    /*
     * Assign a list of students (identified via email) to a Tag.
     *
     * Returns: LargeOperation
     */
    async addUsersByEmail(tagId, emails) {
        const url = `${TAGS_ENDPOINT}${tagId}/add-users/`;
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };
        const promise = axios
            .patch(url, { emails }, options)
            .then(res => LargeOperation.fromAPI(res.data))
            .catch(apiErrorHandler({ apiName: 'Add Users by Tag API Error' }));
        return promise;
    }

    async mergeTags(tags, newName) {
        const url = `${TAGS_ENDPOINT}merge/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = {
            tags: tags,
            new_name: newName
        };
        const promise = axios
            .post(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Merge Tags Api Error' }));
        return promise;
    }
}
