<template>
    <modal
        classes="v--modal"
        name="integration-tag-mapping-modal"
        width="800"
        height="auto"
        :scrollable="true"
        :clickToClose="false"
        :adaptive="true"
        @before-open="beforeOpen"
        @opened="opened"
        :stack="false"
    >
        <div v-if="!loading" class="modal">
            <div v-if="dataToMap.length === 0">
                <p>
                    All data has been previously mapped! Please click Submit to continue!
                </p>
            </div>

            <div
                v-else
                class="tag-category"
                v-for="categoryToMap in dataToMap"
                :key="categoryToMap.id"
            >
                <IntegrationTagCategoryMapper
                    :categoryToMap="categoryToMap"
                    :tagCategoryMappings="tagCategoryMappings"
                    :tagMappings="tagMappings"
                    :tagCategories="tagCategories"
                    @on-update-tag-category="updateTagCategoryMapping"
                    @on-update-tag="updateTagMapping"
                />
            </div>

            <div class="buttons">
                <Button styleType="off" @click="close">Close</Button>
                <LoadingSpinnerButton
                    class="base-button"
                    :loading="isSubmitting"
                    :text="'Submit'"
                    @click="submit"
                />
            </div>
        </div>
        <LoadingSpinner v-else />
    </modal>
</template>

<script>
import * as TagApi from '@/api/tags';
import * as stringHelpers from '@/lib/string-helpers';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import Button from '@/components-deprecated/Button';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import IntegrationTagCategoryMapper from '@/components-deprecated/staff/IntegrationTagCategoryMapper';

export default {
    components: {
        Button,
        LoadingSpinner,
        IntegrationTagCategoryMapper,
        LoadingSpinnerButton
    },
    data() {
        return {
            integrationId: null,
            loading: true,
            isSubmitting: false,
            school: null,
            tagMappings: [],
            tagCategoryMappings: [],
            tagCategories: [],
            dataToMap: [],
            mappingPayload: {
                create: {},
                mapCategories: [],
                mapTags: []
            },
            onSubmit: () => {}
        };
    },
    methods: {
        async beforeOpen(event) {
            this.loading = true;
            this.integrationId = event.params.integration.id;

            const startMappingResponse = await TagApi.startIntegrationTagMapping(
                this.integrationId
            );

            this.tagCategories = startMappingResponse.currentData;
            this.dataToMap = startMappingResponse.dataToMap;

            this.loading = false;
            this.onSubmit = event.params.onSubmit;
        },

        async opened() {},

        // store a tag category mapping
        updateTagCategoryMapping(dataToMap, mappedCategoryId, selectedTagChoiceId) {
            const categoryName = dataToMap.header.csvHeader;
            const existingCategoryMapping = this.tagCategoryMappings.find(
                c => stringHelpers.trimAndLower(c.name) === stringHelpers.trimAndLower(categoryName)
            );
            if (existingCategoryMapping) {
                this.tagCategoryMappings = [
                    ...this.tagCategoryMappings.filter(
                        t =>
                            stringHelpers.trimAndLower(t.name) !==
                            stringHelpers.trimAndLower(categoryName)
                    ),
                    { name: categoryName, mappedCategoryId, tags: dataToMap.tags }
                ];
            } else {
                this.tagCategoryMappings = this.tagCategoryMappings.concat({
                    name: categoryName,
                    mappedCategoryId,
                    tags: dataToMap.tags
                });
            }

            // set all tags under the current category to the first choice under the mapped category
            dataToMap.tags.forEach(t =>
                this.updateTagMapping(t, selectedTagChoiceId, categoryName)
            );
        },

        // store a tag mapping
        updateTagMapping(tagNameToMap, mappedTagId, categoryName) {
            // when we're finding and/or adding tags to map, we must consider both the
            // tag name and the category name because the user will sometimes use the
            // same tag name under multiple categories
            const existingTagMapping = this.tagMappings.find(
                t =>
                    stringHelpers.trimAndLower(t.name) ===
                        stringHelpers.trimAndLower(tagNameToMap) &&
                    stringHelpers.trimAndLower(t.categoryName) ===
                        stringHelpers.trimAndLower(categoryName)
            );
            if (existingTagMapping) {
                this.tagMappings = [
                    ...this.tagMappings.filter(
                        // maintain mappings that are a different category
                        t =>
                            stringHelpers.trimAndLower(t.categoryName) !==
                            stringHelpers.trimAndLower(categoryName)
                    ),
                    ...this.tagMappings.filter(
                        // maintain maps that are the same category but a different tag
                        t =>
                            stringHelpers.trimAndLower(t.name) !==
                                stringHelpers.trimAndLower(tagNameToMap) &&
                            stringHelpers.trimAndLower(t.categoryName) ===
                                stringHelpers.trimAndLower(categoryName)
                    ),
                    { name: tagNameToMap, mappedTagId: mappedTagId, categoryName } // add the new mapping
                ];
            } else {
                this.tagMappings = this.tagMappings.concat({
                    name: tagNameToMap,
                    mappedTagId: mappedTagId,
                    categoryName
                });
            }
        },

        close() {
            this.tagMappings = [];
            this.tagCategoryMappings = [];
            this.tagCategories = [];
            this.dataToMap = [];
            this.$modal.hide('integration-tag-mapping-modal');
        },

        // returns true if a mapped category was previously mapped
        // this can occur if a new tag is provided under a previously mapped category
        wasCategoryPreviouslyMapped(tagCategoryMapping) {
            // look for a matching category in the dataToMap array by name
            const dataToMapCategory = this.dataToMap.find(
                c =>
                    stringHelpers.trimAndLower(c.header.csvHeader) ===
                    stringHelpers.trimAndLower(tagCategoryMapping.name)
            );

            // if a match was found, check for a categoryDetails object - which indicates a previous mapping
            if (dataToMapCategory) {
                return (
                    dataToMapCategory.header.categoryDetails &&
                    dataToMapCategory.header.categoryDetails.hasOwnProperty('hasMap')
                );
            }

            return false;
        },

        reducePreviouslyMappedCategoryTags(acc, tc) {
            if (tc.mappedCategoryId !== 'CUSTOM_NEW') {
                // USER provided category matches existing category/map
                const dataToMapCategory = this.dataToMap.find(
                    c =>
                        stringHelpers.trimAndLower(c.header.csvHeader) ===
                        stringHelpers.trimAndLower(tc.name)
                );
                if (
                    dataToMapCategory &&
                    dataToMapCategory.header &&
                    dataToMapCategory.header.categoryDetails &&
                    dataToMapCategory.header.categoryDetails.hasOwnProperty('hasMap')
                ) {
                    const tagsToMap = this.tagMappings.filter(
                        tm =>
                            stringHelpers.trimAndLower(tm.categoryName) ===
                                stringHelpers.trimAndLower(tc.name) &&
                            tm.mappedTagId !== 'CUSTOM_NEW'
                    );

                    // build a mapTag object for each tag
                    tagsToMap.forEach(t => {
                        const tagToMap = {
                            tagName: t.name,
                            tagId: t.mappedTagId,
                            categoryId: dataToMapCategory.header.categoryDetails.id
                        };

                        // if the category was mapped, use the mappingId
                        // otherwise use the category name
                        if (dataToMapCategory.header.categoryDetails.mappingId) {
                            tagToMap.categoryMapId =
                                dataToMapCategory.header.categoryDetails.mappingId;
                        } else {
                            tagToMap.categoryName = tc.name;
                        }

                        acc.push(tagToMap);
                    });
                }
            }
            return acc;
        },

        reducePreviouslyMappedCategoryNewTags(acc, tc) {
            if (tc.mappedCategoryId !== 'CUSTOM_NEW') {
                // USER provided category matches existing category/map
                const dataToMapCategory = this.dataToMap.find(
                    c =>
                        stringHelpers.trimAndLower(c.header.csvHeader) ===
                        stringHelpers.trimAndLower(tc.name)
                );
                if (
                    dataToMapCategory &&
                    dataToMapCategory.header &&
                    dataToMapCategory.header.categoryDetails &&
                    dataToMapCategory.header.categoryDetails.hasOwnProperty('hasMap') &&
                    dataToMapCategory.header.categoryDetails.hasMap
                ) {
                    const tagsToCreate = this.tagMappings.filter(
                        tm =>
                            stringHelpers.trimAndLower(tm.categoryName) ===
                                stringHelpers.trimAndLower(tc.name) &&
                            tm.mappedTagId === 'CUSTOM_NEW'
                    );

                    // build a mapTag object for each tag
                    tagsToCreate.forEach(t => {
                        acc.push({ name: t.name, category: tc.mappedCategoryId });
                    });
                }
            }
            return acc;
        },

        reduceSavedNotMappedCategories(acc, data) {
            if (
                data.header.hasOwnProperty('categoryDetails') &&
                data.header.categoryDetails.hasMap === false
            ) {
                const categoryName = data.header.csvHeader;

                const tagsToCreate = this.tagMappings.filter(
                    tm =>
                        stringHelpers.trimAndLower(tm.categoryName) ===
                            stringHelpers.trimAndLower(categoryName) &&
                        tm.mappedTagId === 'CUSTOM_NEW'
                );
                tagsToCreate.forEach(t => {
                    acc.push({ name: t.name, category: data.header.categoryDetails.id });
                });
            }
            return acc;
        },

        /*
         * The submit function takes all the information that is visually displayed
         * on the modal and reshapes it in a format the server can understand. There
         * are several reshaping scenarios the code must address.
         * 1. Create new category and new tag
         * 2. Use existing category, create new tag
         * 3. Use existing category, create new tag mapping
         * 4. Use existing category map, create new tag
         * 5. Use existing category map, create new tag map
         * 6. Create new category map, create new tag
         * 7. Create new category map, create new tag map
         */
        async submit() {
            try {
                this.isSubmitting = true;

                // const publicTagCategoryOptions = this.tagCategories.filter(tc => )
                const dataToSubmit = { create: {}, mapCategories: [], mapTags: [] };

                // === Create Categories: new tag categories that were mapped to CUSTOM_NEW and their tags
                // SCENARIO #1
                dataToSubmit.create.categories = this.tagCategoryMappings
                    .filter(tc => tc.mappedCategoryId === 'CUSTOM_NEW')
                    .map(tc => {
                        return { name: tc.name, tags: tc.tags };
                    });

                // === Create Tags: new tags under an existing category that was **not** mapped
                // SCENARIO #2
                dataToSubmit.create.tags = this.dataToMap.reduce(
                    this.reduceSavedNotMappedCategories,
                    []
                );

                // === mapped categories and tags that were **not** previously mapped
                // build a list of categories that were mapped to existing categories
                // and have not previously been mapped
                // SCENARIO #6
                dataToSubmit.mapCategories = this.tagCategoryMappings
                    .filter(
                        tc =>
                            tc.mappedCategoryId !== 'CUSTOM_NEW' &&
                            !this.wasCategoryPreviouslyMapped(tc)
                    )
                    .map(tc => {
                        return { name: tc.name, category: tc.mappedCategoryId };
                    });

                // build a list of tags we want to create where the category is mapped
                const newTags = this.tagMappings.reduce((acc, current) => {
                    // short circuit
                    if (current.mappedTagId !== 'CUSTOM_NEW') {
                        return acc;
                    }

                    // find the id of the existing category that matches our current tag's category
                    const mappedCategory = dataToSubmit.mapCategories.find(
                        mappedCategory =>
                            stringHelpers.trimAndLower(mappedCategory.name) ===
                            stringHelpers.trimAndLower(current.categoryName)
                    );

                    if (mappedCategory && mappedCategory.category) {
                        acc.push({ name: current.name, category: mappedCategory.category });
                    }

                    // we have the tag name, and the category id we need so update the accumulator and return
                    return acc;
                }, []);

                if (newTags && newTags.length > 0) {
                    dataToSubmit.create.tags.push(...newTags);
                }

                // SCENARIO #7
                // build a list of mapped category names
                const mappedCategoryNames = dataToSubmit.mapCategories.map(c => c.name);
                // filter out any tags that are under categories that have not been mapped
                const tagsToMap = this.tagMappings.filter(
                    tm =>
                        mappedCategoryNames.includes(tm.categoryName) &&
                        tm.mappedTagId !== 'CUSTOM_NEW'
                );

                // build a list of tags from the mapCategories array
                dataToSubmit.mapTags = tagsToMap.map(t => {
                    return {
                        tagName: t.name,
                        tagId: t.mappedTagId,
                        categoryId: dataToSubmit.mapCategories.find(
                            c =>
                                stringHelpers.trimAndLower(c.name) ===
                                stringHelpers.trimAndLower(t.categoryName)
                        ).category,
                        categoryName: t.categoryName
                    };
                });
                //=== end of mapped categories and tags that were **not** previously mapped

                // === new tags under previously mapped categories
                // SCENARIO's #3 and #5
                const previouslyMappedCategoryTags = this.tagCategoryMappings.reduce(
                    this.reducePreviouslyMappedCategoryTags,
                    []
                );
                dataToSubmit.mapTags = dataToSubmit.mapTags.concat(previouslyMappedCategoryTags);

                // SCENARIO #4
                const previouslyMappedCategoryNewTags = this.tagCategoryMappings.reduce(
                    this.reducePreviouslyMappedCategoryNewTags,
                    []
                );
                dataToSubmit.create.tags = dataToSubmit.create.tags.concat(
                    previouslyMappedCategoryNewTags
                );

                // === save and close
                await TagApi.saveIntegrationTagMapping(this.integrationId, dataToSubmit);
                this.isSubmitting = false;
                this.close();
                await this.onSubmit();
            } catch (error) {
                this.isSubmitting = false;
                this.$Alert.alert({
                    type: 'error',
                    message: error.message,
                    timeout: 10000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/views/modal';
@import '@/styles/forms';
@import '@/styles/variables';

// After upgrade to Vue 3, vue-js-modal's "ResizeObserver' isn't properly resizing very large modals,
// making them unscrollable. We will be removing that modal package soon, and this is the only modal
// affected so far.
// Doing this for now so the modal can be scrolled.
.modal {
    max-height: 450px;
    overflow: auto;
}
.tag-category {
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $secondary-gray;
}

.tag-category-name {
    flex: 3;
    font-size: 1.1rem;
    font-weight: bold;
}

.tag {
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dropdown {
    flex: 1;
    border-radius: 0;
    height: 100%;
    padding: 0.25rem 0.5rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.base-button {
    margin-left: 1rem;
}
</style>
