<template>
    <div class="student-list-container">
        <div class="col" v-if="shouldShowFilterChips">
            <Chips
                :chips="filterChips"
                showCloseIcon
                @chip-clicked="chip => onFilterClick(chip.value)"
            />
        </div>
        <div class="filters" v-if="!readOnly">
            <div class="col">
                <div v-if="!schoolId" class="input-container">
                    <Dropdown
                        :label="`Filter by School`"
                        :options="schoolOptions"
                        :activeOptions="[schoolId]"
                        @option-clicked="onFilterClick"
                        :showLoadingMenu="!schoolOptions.length"
                        enableSearch
                    />
                </div>
                <div v-if="schoolId">
                    <ChooseFileCancelable
                        :file="csv"
                        :disabled="!schoolId"
                        :readAsString="false"
                        @loaded="onFileLoaded"
                    >
                        <template v-slot:message>
                            <span class="text-bold text-primary">
                                Upload your CSV of student emails.
                            </span>
                        </template>
                    </ChooseFileCancelable>
                    Download a sample template CSV file
                    <a :href="'/chatbot-flow-builder/import-student-list-template.csv'" download>
                        here </a
                    >.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from '@/components-deprecated/global/Dropdown';
import Chips from '@/components-deprecated/global/Chips';
import { listSchools } from '@/api/school';
import ChooseFileCancelable from '@/components-deprecated/ChooseFileCancelable';
import Student from '@/services/students';

export default {
    name: 'StudentList',
    components: {
        Chips,
        Dropdown,
        ChooseFileCancelable
    },
    props: {
        studentList: {
            type: Object
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        schools: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            schoolOptions: [],
            schoolId: null,
            csv: null,
            studentIds: []
        };
    },
    created() {
        if (
            typeof this.studentList === 'object' &&
            typeof this.studentList.schoolId === 'string' &&
            Array.isArray(this.studentList.studentIds)
        ) {
            this.schoolId = this.studentList.schoolId;
            this.studentIds = this.studentList.studentIds;
        }
    },
    computed: {
        shouldShowFilterChips() {
            return Array.isArray(this.filterChips) && this.filterChips.length > 0;
        },
        filterChips() {
            if (Array.isArray(this.schoolOptions) && this.schoolId) {
                return this.schoolOptions
                    .filter(option => option.value === this.schoolId)
                    .map(option => ({
                        ...option,
                        label: `School: ${option.label}`
                    }));
            }

            return [];
        },
        schoolIsSelected() {
            return !!this.schoolId;
        },
        studentCount() {
            if (Array.isArray(this.studentIds)) {
                return this.studentIds.length;
            }

            return 0;
        }
    },
    methods: {
        onFilterClick(value) {
            this.schoolId = value === this.schoolId ? null : value;

            this.emitList();
        },
        async onFileLoaded(file) {
            this.$emit('list-loading', true);
            this.csv = file;
            if (!file) {
                this.studentIds = [];
                this.emitList();
                this.$emit('list-loading', false);
                return;
            }

            const res = await Student.api.getStudentListFromCsv(this.schoolId, file);
            this.studentIds = res.ids;
            if (typeof res === 'object' && Array.isArray(res.ids)) {
                this.studentIds = res.ids;
                this.emitList();
            }
            this.$emit('list-loading', false);
        },
        emitList() {
            this.$emit('list-changed', {
                schoolId: this.schoolId,
                studentIds: this.studentIds,
                studentCount: this.studentCount
            });
        }
    },
    watch: {
        schoolId(value, oldValue) {
            if (!value || (value && oldValue && value !== oldValue)) {
                this.csv = null;
                this.studentIds = [];
            }
        },
        schools: {
            immediate: true,
            handler(newValue) {
                if (Array.isArray(newValue)) {
                    this.schoolOptions = newValue.map(school => ({
                        label: school.name,
                        value: school.id
                    }));
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.student-list-container {
    :deep(.choose-file) {
        padding-left: 0;
    }
    .col {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;

        .input-container {
            width: 22%;
            position: relative;
            align-items: center;
            margin-right: 2rem;
        }
    }
}
</style>
