<template>
    <div class="progress-bar-container">
        <div class="progress-bar-and-marker">
            <div class="progress-bar">
                <div class="progress-bar-filled" :style="filledWidthStyle" />
            </div>
            <div class="progress-bar-marker" :style="markerStyle" v-if="markerPercent != null">
                <div class="marker" />
                <div class="marker-label" v-if="markerLabel">{{ markerLabel }}</div>
            </div>
        </div>
        <div class="progress-bar-labels" v-if="showBarLabels">
            <div class="minimum">{{ minBarLabel }}</div>
            <div class="maximum">{{ maxBarLabel }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        percent: {
            type: Number,
            required: true
        },
        markerPercent: {
            type: Number,
            default: null
        },
        markerLabel: {
            type: String,
            required: false
        },
        showBarLabels: {
            type: Boolean,
            default: false
        },
        minBarLabel: {
            type: String,
            default: '0%'
        },
        maxBarLabel: {
            type: String,
            default: '100%'
        }
    },
    computed: {
        filledWidthStyle() {
            let width = '0%';

            if (typeof this.percent === 'number') {
                width = `${this.percent}%`;
            }

            return { width };
        },
        markerStyle() {
            let left = '0%';
            if (typeof this.markerPercent === 'number') {
                left = `${this.markerPercent}%`;
            }
            return { left };
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.progress-bar-container {
    width: 15rem;
    height: 0.5rem;

    // arbitrarily large radius for rounded corners no matter the width/height of container,
    // since it can be resized by a parent component's styles.
    $border-radius: 999999px;
    .progress-bar-and-marker {
        position: relative;
        height: 100%;
        .progress-bar {
            border-radius: $border-radius;
            width: 100%;
            height: 100%;
            background-color: rgba($edsights-blue, 0.1);
            .progress-bar-filled {
                height: 100%;
                border-radius: $border-radius;
                background-color: $edsights-blue;
            }
        }

        .progress-bar-marker {
            position: absolute;
            display: flex;
            height: 100%;
            top: -50%;

            .marker {
                height: 200%;
                width: 3px;
                border-radius: $border-radius;
                background-color: black;
            }

            .marker-label {
                font-size: 10px;
                white-space: nowrap;
                position: absolute;
                top: -25px;
            }
        }
    }

    .progress-bar-labels {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
}
</style>
