<template>
    <modal
        :classes="modalClasses"
        name="modal-reassign-tags"
        height="400"
        width="600"
        :clickToClose="true"
        :stack="false"
        @before-open="beforeOpen"
        tabindex="-1"
        aria-labeledby="modal-title"
    >
        <div class="reassign-modal">
            <header class="modal-header">
                <h2 class="modal-header-title" id="modal-title">
                    Reassign {{ tags.length }} tag<template v-if="tags.length > 1">s</template> to
                    different tag group
                </h2>
            </header>
            <div class="workflow-separator" />
            <LoadingSpinner v-if="loadingInitialData" />
            <template v-else>
                <div class="modal-content">
                    <div class="section-label">Tags</div>
                    <div class="section-content">
                        <StudentItemTags :tags="tags" />
                    </div>
                    <div class="section-label">New Group</div>
                    <div class="section-content">
                        <v-select
                            :options="tagCategories.list"
                            v-model="newGroup"
                            :clearable="false"
                            placeholder="Search an existing tag group"
                            label="name"
                            maxWidth="20rem"
                        />
                    </div>
                </div>
                <div class="button-container">
                    <Button styleType="off" type="submit" @click="close()">
                        Cancel
                    </Button>
                    <loading-spinner-button
                        class="modal-button base-button"
                        text="Save"
                        :loading="loadingReassignment"
                        type="submit"
                        @click="onReassign()"
                    />
                </div>
            </template>
        </div>
    </modal>
</template>

<script>
import Button from '@/components-deprecated/Button';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import StudentItemTags from '@/components-deprecated/tables/StudentItemTags';
import CollectionManager from '@/services/collectionManager';
import Tags from '@/services/tags';
import TagCategories from '@/services/tagCategories';
import { mapState } from 'vuex';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner.vue';

export default {
    name: 'ModalReassignTags',
    components: {
        LoadingSpinner,
        Button,
        LoadingSpinnerButton,
        StudentItemTags
    },
    data() {
        return {
            tagCategories: CollectionManager.create({
                ModelClass: TagCategories
            }),
            loadingReassignment: false,
            loadingInitialData: false,
            tags: [],
            newGroup: null,
            modalClasses: []
        };
    },
    methods: {
        async beforeOpen(event) {
            // This modal is pretty slow to load data - adding this loading var.
            // To do: this whole modal needs to be re-thought. Why are we loading so much data?
            this.loadingInitialData = true;
            this.modalClasses = ['v--modal'];
            // The tags we receive are not fully populated, causing an error when trying to update them
            let tagPromises = event.params.tags.map(tag => {
                return Tags.api.retrieveTag(tag.id);
            });

            this.tags = await Promise.all(tagPromises);
            this.updateHandler = event.params.updateHandler ? event.params.updateHandler : () => {};

            this.tagCategories.filters = {
                is_public: false
            };

            if (this.staffSchoolViewEnabled) {
                this.tagCategories.filters.schools = this.user.school;
            }

            await this.tagCategories.refresh();
            while (this.tagCategories.pagination.hasNextPage) {
                await this.tagCategories.addNextPage();
            }

            this.loadingInitialData = false;
        },
        close() {
            this.$modal.hide('modal-reassign-tags');
        },
        onReassign() {
            this.tags.forEach(tag => {
                tag.category = this.newGroup.id;
            });

            this.loadingReassignment = true;
            Tags.api
                .updateTags(this.tags)
                .then(() => {
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Tags successfully reassigned!</h2>',
                        timeout: 3000
                    });
                    this.updateHandler();
                })
                .catch(err => {
                    this.$Alert.alert({
                        type: 'error',
                        message: `<h2>Error encountered when updating tags</h2>`,
                        timeout: 3000
                    });
                })
                .finally(() => {
                    this.loadingReassignment = false;
                    this.$modal.hide('modal-reassign-tags');
                });
        }
    },
    computed: {
        ...mapState(['user', 'staffSchoolViewEnabled'])
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

:deep(.v--modal) {
    overflow: visible !important;
}

.modal {
    overflow: visible !important;

    &-header {
        padding: 2.5rem;
        padding-bottom: 1.5rem;
    }

    &-content {
        padding: 2rem;
        padding-top: 2.5rem;
        overflow-y: visible;
        display: block;

        &-text {
            text-align: left;
            color: #000;
        }
    }

    &-button {
        &:last-child {
            margin-left: 1rem;
        }
    }
}

.workflow-separator {
    border-bottom: 2px solid #ebf2f1;
    margin: auto;
    width: 90%;
}

.section-label {
    margin-left: 15px;
    margin-bottom: 5px;
    color: $accessible-gray;
}

.section-content {
    padding: 2rem;
    padding-top: 0;
}

:deep(.v-select input) {
    font-family: inherit;
}
:deep(.v-select input::placeholder) {
    color: $input-gray !important;
}

:deep(.v-select:focus-within) {
    outline: 2px solid $edsights-blue;
}

.button-container {
    margin: 2rem;
    display: flex;
    justify-content: flex-end;
}
</style>
