<template>
    <div class="header">
        <div class="header__checkbox">
            <BaseCheckbox
                class="checkbox"
                :modelValue="selected"
                @update:modelValue="toggleSelected"
                ariaLabel="Select All"
            />
        </div>
        <div class="header__name">{{ name }}</div>
        <div class="header__email">{{ email }}</div>
        <div class="header__edit"></div>
    </div>
</template>

<script>
import { BaseCheckbox } from '@edsights/ui-core';

export default {
    name: 'ManageStudentsListHeader',
    components: {
        BaseCheckbox
    },
    props: {
        name: {
            type: String,
            default: 'Name'
        },
        email: {
            type: String,
            default: 'Email'
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggleSelected(value) {
            if (value) {
                this.$emit('select');
            } else {
                this.$emit('unselect');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header {
    display: flex;
    padding: 10px 20px;
    background-color: $white-blue;

    &__checkbox {
        width: 10%;
    }

    &__name {
        width: 25%;
        margin-right: 4rem;
    }

    &__email {
        width: 50%;
        margin-right: 4rem;
    }

    &__edit {
        flex: 1;
    }
}
.header__checkbox {
    display: flex;
}
</style>
