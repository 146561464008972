/**
 *@function       debounce
 *@description    delay calling of callback function on set time
 *@params         callback <Function> : a function is being returned; time <Number> : delay time in milliseconds
 *@returns        {Function}
 */

export function debounce(callback, time) {
    let timer = null;
    return function(...args) {
        const onComplete = () => {
            callback.apply(this, args);
            timer = null;
        };

        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(onComplete, time);
    };
}
