<template>
    <ManageUploads />
</template>

<script>
import ManageUploads from '@/views/staff/ManageUploads';
export default {
    name: 'Uploadsv1Page',
    components: { ManageUploads }
};
</script>

<style scoped></style>
