/**
 * This file provides custom errors that we can leverage in a uniform manner in
 * the FE code. There are some conventions we follow here in order to make error
 * handling consistent throughout the FE code.
 *
 * name: must exactly match the exported value
 * level: may be one of the following: info, success, warning, or error - This
 *        value may be used with our current Alert system
 * message: not strictly necessary, but should help give the user an indication
 *          of what to do next
 */
export const UNEXPECTED_NON_ERROR_RESPONSE = {
    name: 'UNEXPECTED_NON_ERROR_RESPONSE',
    level: 'warning',
    message: 'The operation completed but returned an unexpected response. Refresh your page.'
};
