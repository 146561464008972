import InterventionTemplate from '@/services/interventionTemplates';
import * as mutations from './mutations';
import { getRelativePath } from '@/services/strings';

export default {
    fetchInterventions,
    fetchInterventionsNextPage,
    markInterventionAsViewed
};

async function fetchInterventions({ commit }, apiParams) {
    commit(mutations.RESET_INTERVENTIONS);
    const { results: interventions, next: pagination, count } = await InterventionTemplate.api.list(
        apiParams
    );
    commit(mutations.SET_INTERVENTIONS, { interventions, pagination, count });
}

async function fetchInterventionsNextPage({ state, commit }) {
    if (state.pagination == null) return;
    const url = getRelativePath(state.pagination);
    const {
        results: interventions,
        next: pagination
    } = await InterventionTemplate.api.listNextPage(url);
    commit(mutations.APPEND_INTERVENTIONS, { interventions, pagination });
}

async function markInterventionAsViewed({ commit }, interventionId) {
    await InterventionTemplate.api.markAsViewed(interventionId);
    commit(mutations.UPDATE_INTERVENTION_PARTIAL, {
        id: interventionId,
        intervention: { adminViewed: true }
    });
}
