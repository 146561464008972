<template>
    <div class="phone">
        <input type="number" class="phone__cell" data-pos="1" @keydown="onkeyup" />
        <input type="number" class="phone__cell" data-pos="2" @keydown="onkeyup" />
        <input type="number" class="phone__cell" data-pos="3" @keydown="onkeyup" />
        <span class="phone__dash">-</span>
        <input type="number" class="phone__cell" data-pos="4" @keydown="onkeyup" />
        <input type="number" class="phone__cell" data-pos="5" @keydown="onkeyup" />
        <input type="number" class="phone__cell" data-pos="6" @keydown="onkeyup" />
        <span class="phone__dash">-</span>
        <input type="number" class="phone__cell" data-pos="7" @keydown="onkeyup" />
        <input type="number" class="phone__cell" data-pos="8" @keydown="onkeyup" />
        <input type="number" class="phone__cell" data-pos="9" @keydown="onkeyup" />
        <input type="number" class="phone__cell" data-pos="10" @keydown="onkeyup" />
    </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
    data() {
        return {
            numbers: new Array(10)
        };
    },

    methods: {
        onkeyup(e) {
            e.preventDefault();
            if (e.keyCode === 8) {
                this.toPrevInput(e);
            } else if (e.keyCode !== 13) {
                this.toNextInput(e);
            }
            const number = this.numbers.join('');
            const isFullfilled = number.length === 10;
            this.$emit('update:modelValue', { isFullfilled, number });

            if (e.keyCode === 13 && isFullfilled) {
                this.$emit('submit');
            }
        },
        toPrevInput(e) {
            this.numbers[e.target.dataset.pos - 1] = null;
            nextTick(() => {
                e.target.value = '';
                const prevInput = this.getPrevInput(e.target);
                if (prevInput != null) {
                    prevInput.focus();
                } else {
                    // Foucsout
                }
            });
        },
        toNextInput(e) {
            this.numbers[e.target.dataset.pos - 1] = e.key;
            nextTick(() => {
                e.target.value = e.key;
                const nextInput = this.getNextInput(e.target);
                if (nextInput != null) {
                    nextInput.focus();
                } else {
                    // Foucsout
                }
            });
        },
        getNextInput(element) {
            const nextElement = element.nextElementSibling;
            if (nextElement == null) {
                return null;
            } else if (nextElement.nodeName === 'INPUT') {
                return nextElement;
            } else {
                return this.getNextInput(nextElement);
            }
        },
        getPrevInput(element) {
            const prevElement = element.previousElementSibling;
            if (prevElement == null) {
                return null;
            } else if (prevElement.nodeName === 'INPUT') {
                return prevElement;
            } else {
                return this.getPrevInput(prevElement);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.phone {
    &__cell {
        max-width: 1.5rem;
        min-height: 2rem;
        font-size: 1.5em;
        text-align: center;
        border-radius: 3px;
        outline: none;
        border-width: 0;
        background: #e2e2e2;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
    }

    &__dash {
        margin: 0 2em;
        margin: 0 0.3em;
        font-size: 1.5em;
    }
}
</style>
