<template>
    <div class="labels-container">
        <div v-if="readOnly && !modelValue.length">No labels.</div>
        <Chips
            :chips="selectedLabels"
            :showCloseIcon="!readOnly"
            @chip-clicked="chip => onOptionClick(chip.value)"
        >
            <template v-slot:after-chips>
                <div class="add-create">
                    <Dropdown
                        class="add-label"
                        :options="labelDropdownOptions"
                        :activeOptions="modelValue"
                        @option-clicked="onOptionClick"
                        enableSearch
                        :disabled="readOnly"
                    >
                        <template v-slot:custom-menu-trigger>
                            <div class="add-item">+ <label>Add Label</label></div>
                        </template>
                    </Dropdown>
                </div>
            </template>
        </Chips>
        <CreateChatbotFlowLabelModal
            v-if="showCreateChatbotFlowLabelModal"
            @close-modal="toggleCreateChatbotFlowLabelModal"
            @label-created="onLabelCreated"
        />
    </div>
</template>
<script>
import Chips from '@/components-deprecated/global/Chips';
import CreateChatbotFlowLabelModal from '@/views/staff/CreateChatbotFlowLabelModal';
import Dropdown from '@/components-deprecated/global/Dropdown';
import { getChatbotFlowLabels } from '@/api/chatbot-flow-labels';

export default {
    name: 'ChatbotFlowLabels',
    components: { Chips, CreateChatbotFlowLabelModal, Dropdown },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        readOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            labels: [],
            showCreateChatbotFlowLabelModal: false
        };
    },
    async created() {
        const labels = await getChatbotFlowLabels();
        if (Array.isArray(labels)) {
            this.labels = labels;
        }
    },
    methods: {
        onOptionClick(labelValue) {
            if (!this.readOnly) {
                if (labelValue === 'create-label') {
                    this.toggleCreateChatbotFlowLabelModal();
                } else if (this.modelValue.includes(labelValue)) {
                    this.removeLabel(labelValue);
                } else {
                    this.addLabel(labelValue);
                }
            }
        },
        addLabel(labelValue) {
            this.$emit('update:modelValue', [...this.modelValue, labelValue]);
        },
        removeLabel(labelValue) {
            this.$emit(
                'update:modelValue',
                this.modelValue.filter(id => id !== labelValue)
            );
        },
        toggleCreateChatbotFlowLabelModal() {
            this.showCreateChatbotFlowLabelModal = !this.showCreateChatbotFlowLabelModal;
        },
        onLabelCreated(label) {
            if (
                typeof label === 'object' &&
                typeof label.text === 'string' &&
                typeof label.id === 'string'
            ) {
                // add label to list of options
                this.labels.push(label);
                // map label to flow
                this.addLabel(label.id);
            }
        }
    },
    computed: {
        labelDropdownOptions() {
            if (Array.isArray(this.labels) && Array.isArray(this.modelValue)) {
                return [
                    { value: 'create-label', label: 'Create new...' },
                    ...this.labels.map(l => ({ value: l.id, label: l.text }))
                ];
            }
            return [];
        },
        selectedLabels() {
            if (Array.isArray(this.labels) && Array.isArray(this.modelValue)) {
                return this.labels
                    .filter(label => this.modelValue.includes(label.id))
                    .map(label => ({ label: label.text, value: label.id }));
            }
            return [];
        }
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';

.labels-container {
    display: flex;

    .add-create {
        display: flex;
        align-items: center;

        .add-item {
            color: #38a0fa;
            font-size: 2rem;
            background-color: white;
            border: none;
            display: flex;

            label {
                cursor: pointer;
                padding-left: 0.5rem;
                color: $base-font-color;
                font-size: 1rem;
            }

            &:hover {
                label {
                    color: #38a0fa;
                }
            }
        }
    }
}
</style>
