<template>
    <input
        type="text"
        class="input"
        :value="modelValue"
        v-on="{
            input: this.onInput
        }"
    />
</template>

<script>
export default {
    name: 'Input',
    props: {
        modelValue: {
            type: String,
            required: false
        }
    },
    methods: {
        onInput({ target }) {
            this.$emit('update:modelValue', target.value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.input {
    padding: 10px 15px;
    background: $textarea-background-color;
    border: 2px solid $textarea-border-color;

    &:focus {
        border: 2px solid $edsights-blue;
    }
}
</style>
