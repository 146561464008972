<template>
    <div class="container insights-page">
        <div class="content__toggle" role="tablist" aria-label="Insight tabs">
            <button
                id="active-tab"
                role="tab"
                :class="
                    selectedTab === 'ACTIVE'
                        ? 'tab content__toggle__selected-item'
                        : 'tab content__toggle__item'
                "
                @click="selectTab('ACTIVE')"
                :aria-selected="selectedTab === 'ACTIVE' ? 'true' : 'false'"
            >
                Active
            </button>
            <button
                id="inactive-tab"
                role="tab"
                :class="
                    selectedTab === 'INACTIVE'
                        ? 'tab content__toggle__selected-item'
                        : 'tab content__toggle__item'
                "
                @click="selectTab('INACTIVE')"
                :aria-selected="selectedTab === 'INACTIVE' ? 'true' : 'false'"
            >
                Inactive
            </button>
        </div>

        <div
            v-show="!loading"
            class="content"
            role="tabpanel"
            :id="selectedTab === 'ACTIVE' ? 'insight-panel-active' : 'insight-panel-inactive'"
            :aria-labelledby="selectedTab === 'ACTIVE' ? 'active-tab' : 'inactive-tab'"
        >
            <div class="content__insights" v-if="insights.list.length">
                <InsightComponent
                    v-for="insight in insights.list"
                    :key="insight.id"
                    :insight="insight"
                    :detailed="false"
                    style="marginBottom: 1rem;"
                />

                <Paginator
                    v-if="insights.pagination.hasNextPage"
                    :loading="loadingNextPage"
                    :nextPage="insights.pagination.hasNextPage"
                    @next-page="loadMoreInsights"
                    style="marginBottom: 3rem; marginTop: 3rem;"
                />
            </div>

            <div v-if="!insights.list.length">
                <h2 class="msg-empty-insights">Nothing to see here... yet!</h2>
                <h2 class="msg-empty-insights__smaller">
                    Once we start engaging with your students you will see any interesting analytics
                    and data trends here.
                </h2>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import InsightComponent from '@/components-deprecated/InsightComponent';

import Paginator from '@/components-deprecated/Paginator';
import Insight from '@/services/insights';
import CollectionManager from '@/services/collectionManager';

export default {
    name: 'InsightsPage',
    components: {
        InsightComponent,
        Paginator
    },

    data() {
        return {
            selectedInsight: null,
            selectedTab: 'ACTIVE',
            loading: false,
            loadingNextPage: false,
            insights: CollectionManager.create({
                ModelClass: Insight,
                filters: {
                    expired: false
                }
            }),
            scrollEnabled: true
        };
    },
    computed: {
        ...mapState(['user'])
    },
    async mounted() {
        await this.loadInsights();
        this.$emit('shouldShowSchools', true);
    },
    methods: {
        async loadInsights() {
            this.loading = true;

            await this.insights.refresh();

            this.loading = false;
        },
        async loadMoreInsights() {
            try {
                this.loadingNextPage = true;
                this.insights.addNextPage();
            } finally {
                this.loadingNextPage = false;
            }
        },
        onSelectInsight(insight) {
            this.selectedInsight = insight;
        },
        selectTab(tab) {
            if (this.selectedTab !== tab) {
                this.selectedTab = tab;
                this.insights.filters = {
                    expired: tab === 'ACTIVE' ? false : true
                };
                this.loadInsights();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.insights-page {
    padding: 3rem 5rem;
}

.tab {
    all: unset;
    font-family: 'Poppins-SemiBold', serif;
    font-size: 20px;
    letter-spacing: 0.83px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    &__toggle {
        display: flex;
        justify-content: space-between;
        width: 18rem;
        margin-bottom: 3rem;

        &__item {
            cursor: pointer;
            color: $accessible-gray;

            &:focus {
                outline: 2px solid $edsights-blue;
            }
        }

        &__selected-item {
            cursor: pointer;
            color: black;
            border-bottom: 1px solid gray;

            &:focus {
                outline: 2px solid $edsights-blue;
            }
        }
    }

    &__insights {
        display: flex;
        flex-direction: column;
    }
}

.insights-wrapper {
    margin-right: 15px;
    width: 30%;
}

.loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
}

.msg-empty-insights {
    margin: 25px 25px 0;
    text-align: center;
}

.msg-empty-insights {
    text-align: center;
    color: $accessible-gray;
    margin-top: 4rem;

    &__smaller {
        text-align: center;
        color: $accessible-gray;
        line-height: 2rem;
        font-size: 1rem;
        margin: 3rem 20%;
    }
}

.loading-msg {
    display: flex;
    justify-content: center;
}
</style>
