import { User } from '../users';
import api from './api';

import { objectToCamelCase, objectToSnakeCase } from '../utils';

export default class RiskBucket {
    static api = api.create(RiskBucket);

    constructor(riskBucket) {
        // eslint-disable-next-line no-param-reassign
        riskBucket = riskBucket || {};
        const { id = null, name = '', category = '' } = riskBucket;
        Object.assign(this, {
            id,
            name,
            category
        });
    }

    static create(opts) {
        return new RiskBucket(opts);
    }

    static fromAPI(json) {
        return new RiskBucket(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }
}
