<template>
    <div class="container">
        <loading-spinner-button
            class="reset-button"
            text="Reset Chat"
            :loading="loadingHistory"
            @click="forgetHistory"
        />
        <div class="edsights-web-chatbot-container" v-if="school && !loadingHistory">
            <Launcher
                :always-scroll-to-bottom="alwaysScrollToBottom"
                :close="closeChat"
                :colors="colors"
                :message-list="messageList"
                :message-styling="messageStyling"
                :new-messages-count="newMessagesCount"
                :on-message-was-sent="onMessageWasSent"
                :open="openChat"
                :participants="participants"
                :show-close-button="true"
                :show-launcher="true"
                :show-emoji="false"
                :show-file="false"
                :show-typing-indicator="showTypingIndicator"
                :disable-user-list-toggle="true"
                :title="title"
                :showCloseButton="false"
                @onType="handleOnType"
            >
                <template v-slot:text-message-body="scopedProps">
                    <p class="sc-message--text-content" v-html="scopedProps.messageText"></p>
                    <p
                        v-if="scopedProps.message.data.meta"
                        class="sc-message--meta"
                        :style="{ color: scopedProps.messageColors.color }"
                    >
                        {{ scopedProps.message.data.meta }}
                    </p>
                </template>
            </Launcher>
        </div>
        <div style="margin-top: 10rem;" v-else>
                        <LoadingSpinner />
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import baseColors from './colors';
import * as SchoolApi from '@/api/school';
import { formatSchoolName } from '@edsights/school-helpers';
import Launcher from './vue3-beautiful-chat/src/Launcher.vue';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton.vue';

export default {
    name: 'App',
    props: {
        schoolToken: {
            type: String
        }
    },
    components: {
        Launcher,
        LoadingSpinner,
        LoadingSpinnerButton
    },
    data() {
        return {
            loading: true,
            loadingHistory: false,
            participants: [
                {
                    id: 'edsights-chatbot',
                    name: 'EdSights Chatbot',
                    imageUrl:
                        'https://edsights-mascot-images-production.s3.amazonaws.com/default-mascot/robot.png'
                }
            ],
            title: 'Hi there!',
            titleImageUrl: '',
            messageList: [],
            newMessagesCount: 0,
            isChatOpen: false,
            showTypingIndicator: '',
            colors: baseColors.edsights,
            alwaysScrollToBottom: true,
            // the message styling library was causing problems with reponses that contain "/"
            // it was trying to italicize the text after the "/" which also caused URLs to not
            // display properly
            messageStyling: false,
            userIsTyping: false,
            school: null,
            userSessionId: null
        };
    },
    computed: {},
    async mounted() {
        try {
            this.school = await SchoolApi.findById(this.$store.state.user.schoolRef.id);

            this.showTypingIndicator = 'edsights-chatbot';
            this.userSessionId = uuidv4();

            if (this.school.mascotName === '') {
                this.school.mascotName = 'Chatbot';
            }
            if (this.school.color) {
                this.setColor(this.school.color);
            }
            if (this.school.mascotUrl) {
                this.participants[0].imageUrl = this.school.mascotUrl;
            }

            // turn off mascot typing indicator
            this.showTypingIndicator = '';
            // update the name of the chatbot
            this.participants[0].name = this.school.mascotName;
            // update the header
            this.title = this.school.mascotName;

            this.loadingHistory = true;
            await this.loadChatbotHistory();
            this.loadingHistory = false;
        } catch (error) {
            console.error(error.message);
        }
    },
    methods: {
        async loadChatbotHistory() {
            const chatHistory = await SchoolApi.getInternalChatbotHistory({
                schoolId: this.$store.state.user.schoolRef.id,
                errorHandlerOptions: {
                    overrideMessage: 'Whoops. There was an issue loading recent history. Please try again.'
                }
            });

            if (chatHistory.length === 0) {
                this.messageList = [
                    {
                        type: 'text',
                        author: 'edsights-chatbot',
                        id: 'edsights-chatbot',
                        data: {
                            text: `Hi there! My name is ${
                                this.school.mascotName
                            }, your personal helper bot. You can ask me questions about ${formatSchoolName(
                                this.school.name
                            )}. When possible, try using full sentences like "What scholarships do you offer?"\n\nWhat can I help with today?`
                        }
                    }
                ];
                return;
            }
        
            this.messageList = chatHistory.map((message) => ({
                type: 'text',
                author: message.sender === 'student' ? 'me' : 'edsights-chatbot',
                id: message.sender === 'student' ? this.userSessionId : 'edsights-chatbot',
                data: { text: message.message }
            }));
        },
        handleTyping(text) {
            this.showTypingIndicator =
                text.length > 0 ? this.participants[this.participants.length - 1].id : '';
        },
        async onMessageWasSent(message) {
            try {
                this.messageList = [
                    ...this.messageList,
                    Object.assign({}, message, { id: this.userSessionId })
                ];

                this.showTypingIndicator = 'edsights-chatbot';

                const response = await SchoolApi.sendChatbotMessage({
                    schoolId: this.$store.state.user.schoolRef.id,
                    message: message.data.text
                });

                this.messageList.push({
                    type: 'text',
                    author: 'edsights-chatbot',
                    id: 'edsights-chatbot',
                    data: {
                        text: response.message
                    }
                });

                this.newMessagesCount = this.isChatOpen
                    ? this.newMessagesCount
                    : this.newMessagesCount + 1;
            } catch (error) {
                console.error(message);
            } finally {
                this.showTypingIndicator = '';
            }
        },
        openChat() {
            this.isChatOpen = true;
            this.newMessagesCount = 0;
        },
        closeChat() {
            this.isChatOpen = false;
        },
        setColor(color) {
            this.colors.launcher.bg = color;
            this.colors.header.bg = color;
            this.colors.sentMessage.bg = color;
        },
        handleOnType() {
            this.$root.$emit('onType');
            this.userIsTyping = true;
        },
        async forgetHistory() {
            this.loadingHistory = true;
            await SchoolApi.forgetAdminChatbotHistory(this.$store.state.user.schoolRef.id)
            await this.loadChatbotHistory();
            this.loadingHistory = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins/buttons';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.edsights-web-chatbot-container {
    padding: 0.8rem;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    :deep() {
        .sc-chat-window {
            z-index: 9999999999;
            font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
            width: 70% !important;
            height: 550px;
        }

        .sc-message {
            width: 100%;
            padding: 10px;
        }

        .sc-message--avatar {
            min-height: 40px;
            min-width: 40px;
        }

        .sc-message--content.received p.sc-message--text-content {
            color: rgb(34, 34, 34) !important;
            white-space: pre-line;
        }

        .sc-message--text-content {
            margin: 14px 0 14px 0 !important;
        }
    }
}

.reset-button {
    @include base-button();
    margin-top: 1rem;
    width: 10%;
}
</style>
