<template>
    <div class="radio" @click="onChange()">
        <input
            :id="inputId"
            :name="name"
            type="radio"
            :value="inputVal"
            :checked="inputVal === modelValue"
        />
        <label :for="inputId" class="radio-opts">{{ label }}</label>
        <span class="checkmark" :style="{ width: width + 'px', height: height + 'px' }"></span>
    </div>
</template>

<script>
export default {
    name: 'RadioButton',
    props: {
        modelValue: {
            type: [Boolean, String]
        },
        inputId: {
            type: String,
            required: true
        },
        inputVal: {
            type: [Boolean, String]
        },
        name: {
            type: String
        },
        label: {
            type: String,
            required: true
        },
        width: {
            type: [String, Number],
            default: 20
        },
        height: {
            type: [String, Number],
            default: 20
        }
    },
    methods: {
        onChange() {
            this.$emit('update:modelValue', this.inputVal);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.radio {
    display: flex;
    position: relative;
    padding-left: 10px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;

    &:focus-within {
        .checkmark {
            outline: 2px solid $edsights-blue;
        }
    }
}

.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    display: block;
    position: absolute;
    background-color: $white;
    margin-right: 0rem;
    border: 1px solid #979797;
    border-radius: 5px;
    left: 1px;
}

/* On mouse-over change color */
.radio input ~ .checkmark {
    background-color: white;
}

/* When the radio button is checked add color */
.radio input:checked ~ .checkmark {
    background-color: $primary-brand-color;
    border: none;
}

/* Create the indicator hidden when not checked */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the indicator when checked */
.radio input:checked ~ .checkmark:after {
    display: block;
}

.radio input:checked ~ .radio-opts {
    color: #202123;
}

/* Style the indicator*/
.radio .checkmark:after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.radio-opts {
    border: none;
    cursor: pointer;
    width: 100%;
    margin-left: 45px;
    color: #202123;
    font-size: 1.1rem;
}
</style>
