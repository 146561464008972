import * as Api from '@/api/index';
import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';

const getMessagingTrackTagBaseUrl = (messagingTrackId, tagId) =>
    Api.getv2ApiBase('/messaging-tracks') + `/${messagingTrackId}/tag/${tagId}`;

const getMessagingTrackTagsBaseUrl = (messagingTrackId) =>
    Api.getv2ApiBase('/messaging-tracks') + `/${messagingTrackId}/tags`;

export const listMessagingTracks = async schoolId => {
    try {
        const url = Api.getv2ApiBase(`/messaging-tracks/school/${schoolId}?full=true`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading messaging tracks'
        });
    }
};

export const addTagMessagingTrack = async (messagingTrackId, tagId) => {
    try {
        const url = getMessagingTrackTagBaseUrl(messagingTrackId, tagId);
        const options = Api.getOptions();

        const response = await axios.put(url, null, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem adding a tag to Messaging Tracks'
        });
    }
};

export const deleteTagMessagingTrack = async (messagingTrackId, tagId) => {
    try {
        const url = getMessagingTrackTagBaseUrl(messagingTrackId, tagId);
        const options = Api.getOptions();

        const response = await axios.delete(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem deleting a tag to Messaging Tracks'
        });
    }
};

export const replaceMessagingTrackTags = async (messagingTrackId, tagIds) => {
    try {
        const url = getMessagingTrackTagsBaseUrl(messagingTrackId);
        const options = Api.getOptions();

        const response = await axios.post(url, tagIds, options)
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem replacing tags for Messaging Track'
        });

    }
}
