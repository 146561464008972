import * as Api from '@/api/index';
import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { strictEqual } from 'assert';

export const findInterventionTemplateById = async interventionTemplateId => {
    try {
        strictEqual(typeof interventionTemplateId, 'string');
        const url = Api.getv2ApiBase(`/intervention-template/${interventionTemplateId}`);
        const options = Api.getOptions();
        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'Unable to get Intervention Template.'
        });
    }
};

export const listStudentsByInterventionTemplateId = async ({
    paginationUrl,
    queryParams,
    filterParams,
    errorHandlerOptions = {}
} = {}) => {
    let url = paginationUrl || Api.getv2ApiBase(`/intervention-template/students`);
    const defaultOptions = Api.getOptions();
    const params = {};

    if (typeof queryParams === 'object') {
        if (typeof queryParams.interventionTemplateId === 'string') {
            params.interventionTemplateId = queryParams.interventionTemplateId;
        }

        if (typeof queryParams.page === 'number') {
            params.page = queryParams.page;
        }

        if (typeof queryParams.pageSize === 'number') {
            params.page_size = queryParams.pageSize;
        }
    }
    if (typeof filterParams === 'object') {
        params.overallRiskLevel = filterParams.overallRiskLevel;
        params.academicRiskLevel = filterParams.academicRiskLevel;
        params.financialRiskLevel = filterParams.financialRiskLevel;
        params.engagementRiskLevel = filterParams.engagementRiskLevel;
        params.wellnessRiskLevel = filterParams.wellnessRiskLevel;
        params.tags = filterParams.tags;
        params.outreachStatus = filterParams.outreachStatus;
        params.search = filterParams.search;
    }

    try {
        const response = await axios.get(url, { ...defaultOptions, params });
        return response.data;
    } catch (error) {
        return v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the students. Please try again.',
            ...errorHandlerOptions
        });
    }
};

export const listInterventionTemplates = async ({ filters }) => {
    try {
        const url = Api.getv2ApiBase(`/intervention-template`);
        const options = Api.getOptions();
        const params = {};
        if (typeof filters === 'object' && typeof filters.chatbotTemplateId === 'string') {
            params.chatbotTemplateId = filters.chatbotTemplateId;
        }
        if (typeof filters === 'object' && typeof filters.limit === 'number') {
            params.limit = filters.limit;
        }
        const response = await axios.get(url, { ...options, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'Unable to get Intervention Templates.'
        });
    }
};

export const bulkMarkActionTaken = async ({
    queryParams,
    filterParams,
    errorHandlerOptions = {}
} = {}) => {
    const url = Api.getv2ApiBase('/intervention-template/bulk-mark-action-taken/');
    const defaultOptions = Api.getOptions();
    const params = {};

    if (typeof queryParams === 'object') {
        if (typeof queryParams.interventionTemplateId === 'string') {
            params.interventionTemplateId = queryParams.interventionTemplateId;
        }

        if (typeof queryParams.newOutreachStatus === 'boolean') {
            params.newOutreachStatus = queryParams.newOutreachStatus;
        }
    }

    if (typeof filterParams === 'object') {
        params.overallRiskLevel = filterParams.overallRiskLevel;
        params.academicRiskLevel = filterParams.academicRiskLevel;
        params.financialRiskLevel = filterParams.financialRiskLevel;
        params.engagementRiskLevel = filterParams.engagementRiskLevel;
        params.wellnessRiskLevel = filterParams.wellnessRiskLevel;
        params.tags = filterParams.tags;
        params.outreachStatus = filterParams.outreachStatus;

        if (Array.isArray(filterParams.interventionIds)) {
            params.interventionIds = filterParams.interventionIds.join(',');
        }
    }

    try {
        const response = await axios.post(url, null, { ...defaultOptions, params });
        return response.data;
    } catch (error) {
        return v2ApiErrorHandler({
            error,
            overrideMessage:
                'There was a problem marking the students as "Action Taken". Please try again.',
            ...errorHandlerOptions
        });
    }
};
