<template>
    <div class="dashboard-container">
        <h1>Deactivate a School</h1>
        <div class="school-dropdown">
            <v-select
                :options="schools"
                v-model="selectedSchool"
                label="name"
                @update:modelValue="schoolSelectionHandler"
                placeholder="Search for a school..."
                :clearable="false"
            />
        </div>
        <Button
            class="button-deactivate"
            width="30rem"
            @click="showConfirmationModalHandler"
            v-if="!showLoadingSpinner"
        >
            Deactivate
        </Button>
        <div v-else>
            <LoadingSpinner />
        </div>
        <Modal
            v-if="showConfirmationModal"
            title="Confirm Changes"
            size="medium"
            primaryButtonTitle="Confirm"
            @secondary-button-clicked="showConfirmationModal = false"
            @primary-button-clicked="() => deactivateSchoolHandler()"
        >
            <div>
                {{ message }}
            </div>
        </Modal>
        <ul v-if="isSchoolDeactivated">
            <li>{{ successLog }}</li>
        </ul>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import * as SchoolApi from '@/api/school';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Modal from '@/components-deprecated/global/Modal';
import Button from '@/components-deprecated/global/Button.vue';

export default {
    name: 'DeactivateSchool',
    components: {
        LoadingSpinner,
        vSelect,
        Modal,
        Button
    },
    data() {
        return {
            isSchoolDeactivated: false,
            showLoadingSpinner: false,
            schoolId: '',
            schools: [],
            selectedSchool: '',
            successLog: '',
            showConfirmationModal: false
        };
    },
    created() {
        this.loadSchools();
    },
    methods: {
        schoolSelectionHandler(value) {
            const selected = this.schools.find(school => school.id === value.id);
            if (selected) {
                this.selectedSchool = selected;
            }
        },
        showConfirmationModalHandler() {
            if (!this.selectedSchool) {
                this.$Alert.alert({
                    type: 'warn',
                    message: 'Please select a school...',
                    timeout: 3000
                });
            }
            if (this.selectedSchool) {
                this.showConfirmationModal = true;
            }
        },
        async loadSchools() {
            this.schools = (
                await SchoolApi.listSchools({ queryParams: { pageSize: 500, isActive: true } })
            ).results;
        },
        async deactivateSchoolHandler() {
            this.showLoadingSpinner = true;
            const response = await SchoolApi.deactivateSchool(this.selectedSchool.id);
            if (response) {
                this.successLog = response;
                this.isSchoolDeactivated = true;
                this.$Alert.alert({
                    type: 'success',
                    message: 'Success!',
                    timeout: 3000
                });
            }
            this.showLoadingSpinner = false;
            this.selectedSchool = '';
            this.showConfirmationModal = false;
            // refresh school list without refreshing entire page
            this.loadSchools();
        }
    },
    computed: {
        message() {
            return `Are you sure you want to deactivate ${this.selectedSchool.name}?`;
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.button-deactivate {
    margin-top: 2rem;
}
.school-dropdown {
    margin-top: 2rem;
    width: 30rem;
}
</style>
