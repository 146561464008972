import { useQuery } from '@tanstack/vue-query';
import { getSummaryStats } from '@/api/school';

const DEFAULT_STALE_TIME = 60000 * 60;

const summaryStatsKeys = {
    all: school => ['summaryStats', school],
    filtered: (school, filter) => [...summaryStatsKeys.all(school), filter]
};

export const useSummaryStats = ({ filter, school, staleTime = DEFAULT_STALE_TIME }) => {
    return useQuery(
        summaryStatsKeys.filtered(school, filter),
        async () => {
            const params = {
                school,
                ...filter
            };

            const stats = await getSummaryStats(params);
            return stats;
        },
        {
            staleTime
        }
    );
};
