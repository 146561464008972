<template>
    <div class="chart-container" :style="{ width: `${width}px`, height: `${height}px` }">
        <Pie
            :chartData="chartData"
            :chartOptions="chartOptions"
            :height="height"
            :width="width"
            :ariaLabel="formattedAriaLabel"
            role="img"
        />
        <div v-if="text == null" class="chart-container__value">
            {{ valueToDisplay }}<span v-if="displayPercents">%</span>
        </div>
        <div v-else class="chart-container__value">{{ text }}</div>
        <svg
            class="chart-container__icon"
            :width="width"
            :height="height"
            viewBox="0 0 76 76"
            aria-hidden="true"
        >
            <g
                id="Dashboard"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-dasharray="1"
            >
                <g
                    id="Dashboard-Copy"
                    transform="translate(-352.000000, -585.000000)"
                    :stroke="color"
                    stroke-width="3"
                >
                    <g id="Risk-Level" transform="translate(160.000000, 587.000000)">
                        <g id="Group-5" transform="translate(194.000000, 0.000000)">
                            <path
                                d="M36,72 C55.882251,72 72,55.882251 72,36 C72,16.117749 55.882251,0 36,0 C16.117749,0 0,16.117749 0,36 C0,55.882251 16.117749,72 36,72 Z"
                                id="Oval-4"
                                transform="translate(36.000000, 36.000000) scale(-1, 1) translate(-36.000000, -36.000000) "
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'vue-chartjs';

Chart.register(ArcElement, Tooltip, Legend);

export default {
    name: 'PieChart',
    components: { Pie },
    props: {
        value: {
            type: Number,
            default: 60
        },
        text: {
            type: String,
            default: null
        },
        width: {
            type: Number,
            default: 75
        },
        height: {
            type: Number,
            default: 75
        },
        color: {
            type: String,
            required: true
        },
        max: {
            type: Number,
            default: null
        },
        displayPercents: {
            type: Boolean,
            default: true
        },
        // Gets combined with the provided value to form an aria-label.
        // Example: "A pie chart showing the percentage of engaged students:" will be formatted with
        // the value as "A pie chart showing the percentage of engaged students: 80%".
        ariaLabelTitle: {
            type: String,
            required: true,
            // Fallback is provided, but please fix any usages where this prop validation is failing
            // with a detailed value.
            default: 'Pie chart:'
        }
    },
    computed: {
        chartData() {
            return {
                labels: ['', ''],
                datasets: [
                    {
                        data: [this.value, this.max || 100 - this.value],
                        backgroundColor: [this.color, 'transparent'],
                        borderWidth: 0
                    }
                ]
            };
        },
        chartOptions() {
            return {
                responsive: false,
                maintainAspectRatio: false,
                cutout: '92%',
                rotation: 0.5 * Math.PI,
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: true,
                        displayColors: false
                    },
                    datalabels: {
                        display: false
                    }
                }
            };
        },
        valueToDisplay() {
            if (Number.isNaN(this.value)) return 0;
            return this.value.toFixed(1);
        },
        formattedAriaLabel() {
            return `${this.ariaLabelTitle} ${this.valueToDisplay}${
                this.displayPercents ? '%' : ''
            }`;
        }
    }
};
</script>

<style lang="scss" scoped>
.chart-container {
    position: relative;

    &__icon {
        position: absolute;
        left: 0;
        top: 0;
    }

    &__value {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 18px;
        font-weight: bold;
    }
}
</style>
