<template>
    <div class="touchpoint-interventions">
        <div class="interventions__items">
            <template v-if="touchpoints.length !== 0">
                <TouchpointInterventionGroup
                    :key="touchpoint.id"
                    v-for="touchpoint in touchpoints"
                    :touchpoint="touchpoint"
                />
            </template>
            <OverviewListItem
                v-if="touchpoints.length === 0 && displayEmptyMsgAsListItem"
                title="Coming Soon!"
                description="Suggested interventions, as well as actions taken by EdSights, will be displayed here as we learn more about your students."
                selected
            />
            <Paginator
                v-if="hasMoreItems"
                :loading="loadingNextPage"
                :nextPage="hasMoreItems"
                @next-page="$emit('interventions-next-page')"
            />
        </div>

        <ModalReportDownload />
    </div>
</template>

<script>
import TouchpointInterventionGroup from '@/components-deprecated/overview/TouchpointInterventionGroup';
import ModalReportDownload from '@/components-deprecated/admin/ModalReportDownload';
import OverviewListItem from '@/components-deprecated/overview/OverviewListItem';
import Paginator from '@/components-deprecated/Paginator';
import { strictEqual } from 'assert';

export default {
    name: 'TouchpointInterventions',

    components: {
        TouchpointInterventionGroup,
        ModalReportDownload,
        OverviewListItem,
        Paginator
    },

    props: {
        hasMoreItems: {
            type: Boolean,
            default: false
        },
        touchpoints: {
            type: Array,
            default: () => []
        },
        showMoreTo: {
            type: Object,
            default: null
        },
        displayEmptyMsgAsListItem: {
            type: Boolean,
            default: false
        },
        loadingNextPage: {
            type: Boolean,
            default: false
        },
        templateTypeFilter: {
            type: String,
            default: 'ALL'
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.touchpoint-interventions {
    position: relative;
    height: 100%;
}
</style>
