<template>
    <div class="score-container">
        <span class="score" :class="gradientClass">
            {{ renderedScore }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'Score',
    props: {
        score: {
            type: Number,
            required: false
        },
        comparedScore: {
            type: Number,
            required: false
        },
        showGradient: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        renderedScore() {
            if (typeof this.score === 'number') {
                return this.score;
            }
            return '--';
        },
        gradientClass() {
            if (
                this.showGradient &&
                typeof this.score === 'number' &&
                typeof this.comparedScore === 'number'
            ) {
                const difference = this.score - this.comparedScore;
                if (difference > 0) {
                    return 'greater-than';
                } else if (difference < 0) {
                    return 'less-than';
                }
            }
            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
$score-font-size: 72px;

.score-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: $score-font-size;

    .score {
        font-size: $score-font-size;
        font-weight: bold;
        line-height: 1;
        color: $edsights-blue;

        &.greater-than,
        &.less-than {
            color: transparent;
        }

        &.greater-than {
            background: linear-gradient(to top, $edsights-blue, #a5cdb7 120%);
            -webkit-background-clip: text;
        }
        &.less-than {
            background: linear-gradient(to top, $edsights-blue, #e0aaa0 120%);
            -webkit-background-clip: text;
        }
    }
}
</style>
