<template>
    <div class="emoji-picker-container">
        <button class="icon-button" @click.stop="shouldShowDropdown = !shouldShowDropdown">
            <img class="icon" src="@/assets/icons/emoji.png" alt="Emoji" />
        </button>
        <TransitionExpand class="transition-container" v-if="shouldShowDropdown">
            <div class="transition-container">
                <!-- The old EmojiPicker library we used did not support skin tones, and this one
                does by default. We're turning skin tones off to match prior functionality, but can
                turn them on if ever requested. -->
                <EmojiPicker_ :style="emojiPickerStyle" @select="onEmojiSelect" disableSkinTones />
            </div>
        </TransitionExpand>
    </div>
</template>

<script>
import EmojiPicker_ from 'vue3-emoji-picker';
import TransitionExpand from '@/components-deprecated/TransitionExpand';
import 'vue3-emoji-picker/css';

export default {
    name: 'EmojiPicker',
    components: {
        EmojiPicker_,
        TransitionExpand
    },
    props: {
        emojiPickerStyle: {
            type: Object
        }
    },
    data() {
        return {
            shouldShowDropdown: false
        };
    },
    created() {
        window.addEventListener('click', this.closeEvent);
    },
    methods: {
        onEmojiSelect(emoji) {
            this.shouldShowDropdown = false;
            this.$emit('onSelection', emoji.i);
        },
        closeEvent(e) {
            if (this.$el.contains(e.target) !== true) {
                this.shouldShowDropdown = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';

.emoji-picker-container {
    display: flex;
    flex-direction: row;
}

.icon-button {
    cursor: pointer;
    border: 0;
    background: transparent;
    height: 30px;
    width: 30px;
    margin-right: 4px;
}

.icon {
    height: 30px;
    width: 30px;
    margin-right: 5px;
}

.transition-container {
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: $white;
    margin-left: 3rem;
    max-height: 400px;
}
</style>
