<template>
    <modal
        classes="v--modal"
        name="integration-upload-modal"
        width="800"
        height="auto"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        :stack="false"
        @before-open="beforeOpen"
        @closed="closed"
    >
        <div class="modal">
            <header class="modal__header">
                <h3 class="modal__header__title">
                    Manage Students
                    {{
                        integrationSchool && integrationSchool.id
                            ? `at ${integrationSchool.name}`
                            : ''
                    }}
                </h3>
            </header>
            <div class="modal__content">
                <div class="step" v-if="userIsStaff">
                    <div class="school-filter">
                        <span class="filter-label"> School filter: </span>
                        <div class="school-dropdown">
                            <FilterButton
                                :data="schools.list"
                                @school="selectSchool"
                                functionType="school"
                                :initialValue="resetSchoolObject"
                            ></FilterButton>
                        </div>
                    </div>
                </div>
                <div class="step">
                    <span class="step-title"> Step 1: Integration Type </span>

                    <div class="upload-type">
                        Select a type:
                        <select v-model="integrationType">
                            <option v-for="type in types" :key="type.id" :value="type">
                                {{ type.name }}
                            </option>
                        </select>
                        <p v-if="integrationType && integrationType.name">
                            Download a sample template CSV file
                            <a :href="integrationTemplateUrl()">here</a>.
                        </p>
                        <span v-if="integrationType && integrationType.name === 'Delete'"
                            >Select a reason:</span
                        >
                        <select
                            v-model="integrationDeactivationReason"
                            v-if="integrationType && integrationType.name === 'Delete'"
                        >
                            <option
                                v-for="reason in integrationDeactivationReasons"
                                :key="reason"
                                :value="reason === 'Did not persist' ? 'Withdrew' : reason"
                            >
                                {{ reason }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="step">
                    <span class="step-title"> Step 2: Enter a Title </span>

                    <input type="text" v-model="integrationTitle" />
                </div>
                <div class="step">
                    <span class="step-title"> Step 3: Upload your file </span>
                    <br />
                    <div class="upload-container">
                        <br />
                        <ChooseFileCancelable :readAsString="false" @loaded="fileUploaded">
                            <template v-slot:message>
                                <h3 class="upload-message">Upload your Spreadsheet</h3>
                            </template>
                        </ChooseFileCancelable>
                    </div>
                </div>
                <div class="step">
                    <br />
                    <Button @click="onSubmit">Submit</Button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Button from '@/components-deprecated/Button';
import CollectionManager from '@/services/collectionManager';
import School from '@/services/schools';
import FilterButton from '@/components-deprecated/FilterButton';
import ChooseFileCancelable from '@/components-deprecated/ChooseFileCancelable';

export default {
    components: {
        Button,
        FilterButton,
        ChooseFileCancelable
    },
    data() {
        return {
            schools: CollectionManager.create({
                ModelClass: School
            }),
            resetSchoolObject: { id: null, name: null },
            integrationType: null,
            integrationSchool: null,
            integrationTitle: null,
            integrationCreatedBy: null,
            integrationFile: null,
            integrationDeactivationReason: '',
            integrationDeactivationReasons: ['Graduated', 'Did not persist', 'Transferred', 'OTHER']
        };
    },
    props: {
        types: {
            validator: value => Array.isArray(value),
            required: true
        }
    },
    computed: {
        userIsStaff() {
            return this.$store.state.user.isStaff;
        },
        defaultSchool() {
            return !this.userIsStaff ? this.$store.state.user.schoolRef : null;
        }
    },
    methods: {
        integrationTemplateUrl() {
            if (this.integrationType && this.integrationType.name) {
                let filename = '';
                switch (this.integrationType.name) {
                    case 'Add':
                        filename = 'add';
                        break;
                    case 'Update':
                        filename = 'update';
                        break;
                    case 'Delete':
                    default:
                        filename = 'delete';
                        break;
                }
                return `../integration-templates/${filename}-template.csv`;
            }
            return '';
        },
        fileUploaded(file) {
            this.integrationFile = file;
        },
        selectSchool(school = null) {
            if (school && !school.id) {
                this.resetSchoolObject = { id: null, name: null };
                return;
            }
            this.integrationSchool = school;
        },
        async listSchools() {
            if (this.userIsStaff) {
                await this.schools.refresh();
                while (this.schools.pagination.hasNextPage) {
                    this.schools.addNextPage();
                }
            }
        },
        async beforeOpen(event) {
            this.integrationCreatedBy = this.$store.state.user.id;
            this.schools = CollectionManager.create({
                ModelClass: School
            });
            await this.listSchools();
            if (event.params) {
                this.handler = event.params.handler;
            }
        },
        closed() {
            this.integrationType = null;
            this.integrationSchool = this.defaultSchool;
            this.integrationTitle = null;
            this.integrationCreatedBy = null;
            this.integrationFile = null;
            this.integrationDeactivationReason = '';
        },
        onSubmit() {
            const integrationData = {};
            try {
                // set the school
                if (this.integrationSchool) {
                    integrationData.integrationSchool = JSON.parse(
                        JSON.stringify(this.integrationSchool)
                    );
                } else {
                    integrationData.integrationSchool = {
                        id: this.defaultSchool.id,
                        name: ''
                    };
                }

                // set the remaining values
                integrationData.integrationType = JSON.parse(JSON.stringify(this.integrationType));
                integrationData.integrationDeactivationReason =
                    integrationData.integrationType.name === 'Delete'
                        ? this.integrationDeactivationReason
                        : '';
                integrationData.integrationTitle = this.integrationTitle;
                integrationData.integrationCreatedBy = this.integrationCreatedBy;
                integrationData.integrationFile = this.integrationFile;

                if (
                    !integrationData.integrationTitle ||
                    !integrationData.integrationCreatedBy ||
                    !integrationData.integrationFile ||
                    (integrationData.integrationDeactivationReason === '' &&
                        integrationData.integrationType.name === 'Delete')
                ) {
                    throw new Error('Incomplete form');
                }
                this.$emit('createIntegration', integrationData);
                this.$modal.hide('integration-upload-modal');
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: 'You must complete all values in the form.',
                    timeout: 5000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/views/modal';
@import '../styles/forms';
@import '../styles/variables';

.modal {
    &__header {
        background-color: white;
        padding: 1rem 0rem;
        margin: 0rem;
        border-bottom: solid 1px #e3eded;
        &__title {
            margin: 0 2rem;
            font-weight: bold;
            font-size: 1.5rem;
            color: #202123;
        }
    }
}
.download-link {
    &.disabled {
        color: gray;
        &:hover {
            cursor: not-allowed;
        }
    }
    &:hover {
        cursor: pointer;
    }
}
.step-title {
    font-weight: bold;
}

.modal__content {
    padding: 0rem 0rem;
}

.modal__btn {
    display: inline-block;
    margin: 0;
    margin-right: 15px;
}

.modal__btn-wrapper {
    display: flex;
    justify-content: flex-end;
}
.step {
    margin: 2rem 2rem;
}
.school-filter {
    display: flex;
}
.filter-label {
    margin-top: 1.5rem;
}
.school-dropdown {
    height: 20px;
}
:deep(.upload-type) {
    // v-deep is a utility to select a deep component when using scoped
    // targeting in this case only the dd in upload-type
    > .dropdown {
        // manually setting input style here

        input[type='text'] {
            width: 100%;

            height: 20px;
            border-radius: 6px;
            box-shadow: 0 5px 20px 0 #f6f9fe;
            border: solid 1px #e3eded;
            background-color: rgba(0, 0, 0, 0.02);
        }
    }
}
.step-error {
    color: red;
    font-size: 10px;
    font-style: italic;
}
.step-info {
    color: $primary-brand-color;
    font-size: 10px;
    font-style: italic;
}

form {
    @extend %form;
    input[type='radio'] + label {
        min-width: 1.5rem;
        width: 1.5rem;
    }
}
</style>
