<template>
    <StaffStudentsList />
</template>

<script>
import StaffStudentsList from '@/components-deprecated/staff/StaffStudentsList';
export default {
    name: 'StaffStudentsPage',
    components: { StaffStudentsList }
};
</script>

<style scoped></style>
