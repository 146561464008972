import * as Api from '@/api/index';
import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';

export const listInstitutionTypeCategories = ({ errorHandlerOptions = {} }) => {
    const url = Api.getv2ApiBase('/institution-type-category');
    const defaultOptions = Api.getOptions();

    return axios
        .get(url, defaultOptions)
        .then(response => {
            return response.data;
        })
        .catch(error =>
            v2ApiErrorHandler({
                error,
                overrideMessage:
                    'There was a problem getting Institution Type Categories. Please try again.',
                ...errorHandlerOptions
            })
        );
};
