<!--
To include an element that will be appended to the end of (and wrap with) the chips,include an
"after-chips" template:

<Chips :chips="chipOptions" @chip-clicked="handleChipClick">
  <template v-slot:after-chips>
    element goes here (example: button or dropdown to add more chips)
</template>
</Chips>

-->

<template>
    <div class="chips-container">
        <div
            class="chip"
            v-for="chip in chips"
            :key="chip.value"
            @click="$emit('chip-clicked', chip)"
            :title="chip.tooltip ? chip.tooltip : getFormattedChipLabel(chip)"
        >
            <div class="label">
                {{ getFormattedChipLabel(chip) }}
            </div>
            <div class="close" v-if="showCloseIcon">
                &#x2715;
            </div>
        </div>
        <slot name="after-chips"></slot>
    </div>
</template>

<script>
export default {
    name: 'Chips',
    props: {
        chips: {
            type: Array,
            required: true,
            validator: function(options) {
                return options.every(
                    o =>
                        typeof o.label === 'string' &&
                        (typeof o.value === 'string' ||
                            typeof o.value === 'number' ||
                            typeof o.value === 'boolean') &&
                            // Include an optional tooltip string, otherwise the chip's label is used as a tooltip.
                            (typeof o.tooltip === 'undefined' || typeof o.tooltip === 'string')
                );
            }
        },
        labelPrefix: {
            type: String,
            required: false,
            default: ''
        },
        showCloseIcon: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        getFormattedChipLabel(chip) {
            return `${this.labelPrefix}${chip.label}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.chips-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.75rem;

    .chip {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border: $base-border-width solid $primary-brand-color;
        padding: 0 1rem;
        margin-right: 0.4rem;
        border-radius: 1.15rem;
        font-family: $base-font-family;
        font-weight: bold;
        font-size: 1rem;
        height: 2.3rem;
        white-space: nowrap;

        .label {
            max-width: 20rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .close {
            margin-left: 0.3rem;
            font-size: 1.3rem;
            font-weight: normal;
            color: $black-alpha-0-5;
        }
    }
}
</style>
