import * as Api from '@/api/index';
import axios from 'axios';
const DISMISSED = 'DISMISSED';
const ACTION_TAKEN = 'ACTION_TAKEN';

export const alertAction = async (code, action, st) => {
    try {
        if (!code || !action || !st) {
            return { status: 'error', msg: 'Invalid Request'};
        }

        if (action !== DISMISSED && action !== ACTION_TAKEN) {
            return {status: 'error', msg: 'Invalid action provided'};
        }

        const url = Api.getv2ApiBase(`/alerts/code/${code}/${action}?st=${st}`);
        const options = Api.getOptions();

        await axios.put(url, options);
        switch (action) {
            case DISMISSED:
                return {status: 'ok', msg: 'You have successfully dismissed the Alert!'};
            case ACTION_TAKEN:
                return {status: 'ok', msg: 'You have successfully actioned the Alert!'};
        }
    } catch (error) {
        const errMsg =
            error && error.response && error.response.data ? error.response.data.message : '';
        return { status: 'error', msg: errMsg };
    }
};
