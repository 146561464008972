<template>
    <Button style-type="text" :unbordered="true" @click="goToEnterSchoolPage">
        Enter School
    </Button>
</template>

<script>
import Button from '@/components-deprecated/Button';

export default {
    name: 'StaffEnterSchoolButton',
    components: { Button },
    methods: {
        goToEnterSchoolPage() {
            this.$router.push({ name: 'StaffEnterSchoolPage' });
        }
    }
};
</script>

<style lang="scss" scoped></style>
