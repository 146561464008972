import FAQInstanceAPI from './api';
import { objectToCamelCase, objectToSnakeCase } from '../utils';

export default class FAQInstance {
    static api = FAQInstanceAPI.create(FAQInstance);
    static readOnlyFields = ['id'];
    constructor({ id = '', faq = null, answer = '', school = null, status = 'PENDING' } = {}) {
        Object.assign(this, {
            id,
            faq,
            answer,
            school,
            status
        });
    }

    static create(opts) {
        return new FAQInstance(opts);
    }

    static fromAPI(json = {}) {
        return new FAQInstance(objectToCamelCase(json));
    }

    /**
     * Prep data for the API. Can elect to include or exlude specific fields.
     * The readOnlyFields are always excluded.
     **/
    static toAPI(faqInstance, fields = [], excludeFields = []) {
        // By default, send the whole object
        let data = {};

        // If it's a partial update, get only the fields specified
        if (fields.length > 0) {
            fields.forEach(field => {
                data[field] = faqInstance[field];
            });
        } else {
            data = faqInstance;
        }

        excludeFields = [...FAQInstance.readOnlyFields, ...excludeFields];
        excludeFields.forEach(item => {
            delete data[item];
        });

        return objectToSnakeCase(data);
    }
}
