<script setup>
import { computed } from 'vue';
import { BaseInputNumber, BaseFileUpload } from '@edsights/ui-core';

const props = defineProps({
    isStaff: {
        type: Boolean,
        required: true
    },
    uploadedMascotImageDataUrl: {
        type: String,
        default: null
    },
    mascotUrl: {
        type: String,
        default: null
    },
    webChatbotMascotWidthPixels: {
        type: Number,
        required: true
    },
    webChatbotMascotHeightPixels: {
        type: Number,
        required: true
    },
    defaultSettings: {
        type: Object,
        required: true
    }
});

const emit = defineEmits([
    'update:uploadedMascotImageDataUrl',
    'update:webChatbotMascotWidthPixels',
    'update:webChatbotMascotHeightPixels'
]);

const imageSource = computed(() => {
    return props.uploadedMascotImageDataUrl || props.mascotUrl;
});

function onMascotImageFileSelect(files) {
    emit('update:uploadedMascotImageDataUrl', files[0]);
}
</script>

<template>
    <div class="mascot-image">
        <div class="mascot-image-upload">
            <label>Image</label>

            <div class="mascot-image-preview">
                <div
                    class="preview"
                    :style="{
                        backgroundImage: `url(${imageSource})`,
                        minWidth: `${webChatbotMascotWidthPixels ||
                            defaultSettings.webChatbotMascotWidthPixels}px`,
                        maxWidth: `${webChatbotMascotWidthPixels ||
                            defaultSettings.webChatbotMascotWidthPixels}px`,
                        minHeight: `${webChatbotMascotHeightPixels ||
                            defaultSettings.webChatbotMascotHeightPixels}px`,
                        maxHeight: `${webChatbotMascotHeightPixels ||
                            defaultSettings.webChatbotMascotHeightPixels}px`
                    }"
                    alt="The mascot image/avatar"
                />
            </div>
            <br />
            <BaseFileUpload
                class="uploader"
                v-if="isStaff"
                label="Update"
                accept="image/png,image/jpg,image/jpeg,image/webp"
                returnFilesAsDataUrls
                :maxFileSizeBytes="512000"
                @onSelect="onMascotImageFileSelect"
            />
        </div>
        <div class="mascot-image-dimensions">
            <div class="mascot-image-dimension-inputs">
                <BaseInputNumber
                    class="dimension-input"
                    :readOnly="!isStaff"
                    label="Width (px)"
                    :modelValue="webChatbotMascotWidthPixels"
                    @update:modelValue="$emit('update:webChatbotMascotWidthPixels', $event)"
                    :placeholder="`${defaultSettings.webChatbotMascotWidthPixels}`"
                    :min="0"
                    :max="80"
                />
                <BaseInputNumber
                    class="dimension-input"
                    :readOnly="!isStaff"
                    label="Height (px)"
                    :modelValue="webChatbotMascotHeightPixels"
                    @update:modelValue="$emit('update:webChatbotMascotHeightPixels', $event)"
                    :placeholder="`${defaultSettings.webChatbotMascotHeightPixels}`"
                    :min="0"
                    :max="80"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.mascot-image {
    display: flex;

    .mascot-image-preview {
        display: flex;
        flex-direction: column;

        // This preview style mirrors the styling
        // of profile images in the Web Chatbot.
        // This element has a dynamic backbround image
        // and max width/height controlled by the template.
        .preview {
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            border-radius: 50%;
            align-self: center;
        }
    }
    .mascot-image-upload {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        & > label {
            line-height: normal;
            margin-bottom: 0.2rem;
        }
    }

    .mascot-image-dimensions {
        display: flex;
        flex-direction: column;

        .mascot-image-dimension-inputs {
            display: flex;

            .dimension-input {
                width: 80px;
                margin-right: 10px;
            }
        }
    }
}
</style>
