<template>
    <label class="checkbox" :class="{ 'checkbox--align-center': alignCenter }">
        <input class="checkbox__input" type="checkbox" @click="input" ref="checkbox" />
        <span class="checkbox__checkmark"></span>
        <span class="checkbox__label">
            {{ label }}
        </span>
    </label>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        val: {
            default: ''
        },
        selectedItems: {
            type: Array,
            default: null
        },
        watch: {
            type: Boolean,
            default: true
        },
        alignCenter: {
            type: Boolean,
            default: false
        },
        checked: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        input(event) {
            const { target } = event;
            this.$emit('click', event);
            if (this.selectedItems == null) {
                this.$emit('update:modelValue', target.checked);
                return;
            }

            if (target.checked === true) {
                this.selectedItems.push(this.val);
            } else {
                const selectedItems = this.selectedItems.filter(item => item !== this.val);
                this.$emit('update:selectedItems', selectedItems);
            }
        },
        turnoff() {
            this.$emit('update:modelValue', false);
            this.$refs.checkbox.checked = false;
        },
        turnon() {
            this.$emit('update:modelValue', true);
            this.$refs.checkbox.checked = true;
        }
    },
    mounted() {
        if (this.selectedItems == null) {
            this.$refs.checkbox.checked = this.checked;
            return;
        }
        if (this.selectedItems.includes(this.val) && !this.$refs.checkbox.checked) {
            this.$refs.checkbox.checked = true;
        }
        if (!this.selectedItems.includes(this.val) && this.$refs.checkbox.checked) {
            this.$refs.checkbox.checked = false;
        }
    },
    watch: {
        selectedItems(selectedItems) {
            if (!this.watch) {
                return;
            }
            if (
                ~selectedItems.findIndex(item => item === this.val) &&
                !this.$refs.checkbox.checked
            ) {
                this.$refs.checkbox.checked = true;
            }
            if (
                !~selectedItems.findIndex(item => item === this.val) &&
                this.$refs.checkbox.checked
            ) {
                this.$refs.checkbox.checked = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins/checkboxes';

.checkbox {
    @include checkbox();

    input:focus {
        + .checkbox__checkmark {
            outline: 2px solid $edsights-blue;
        }
    }

    &--align-center {
        justify-content: center;
    }

    &__checkmark {
        width: 17px;
        height: 17px;
        margin-left: 1rem;

        &::after {
            left: 6px;
            top: 2px;
            width: 3px;
            height: 8px;
        }
    }
}
</style>
