import axios from 'axios';
import { API_BASE, apiErrorHandler } from '../api';
import Auth from '../auth';

const INSIGHTS_ENDPOINT = `${API_BASE}insights/`;

export default class InsightsAPI {
    constructor(cls) {
        this.cls = cls;
    }

    static create(cls) {
        return new InsightsAPI(cls);
    }

    async list(params = {}) {
        const options = {
            headers: await Auth.getHeaders().headers,
            params
        };

        const promise = axios
            .get(INSIGHTS_ENDPOINT, options)

            .then(response => {
                return {
                    ...response.data,
                    results: response.data.results.map(this.cls.fromAPI)
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Insights API Error' }));

        return promise;
    }

    async retrieve(id) {
        const url = `${INSIGHTS_ENDPOINT}${id}/`;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const promise = axios
            .get(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Retrieve Insights API Error' }));

        return promise;
    }
}
