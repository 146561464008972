/**
 * Statuses
 */
const NEW = 'NEW';
const IN_PROGRESS = 'IN_PROGRESS';
const IMPORTED = 'IMPORTED';

// Use this for dropdown menus
const STATUS_CHOICES = [
    { value: NEW, label: 'New' },
    { value: IN_PROGRESS, label: 'In Progress' },
    { value: IMPORTED, label: 'Imported' }
];

/**
 * Upload Types
 */
const ADD = 'ADD';
const UPDATE = 'UPDATE';
const REMOVE_GRADUATED = 'REMOVE_GRADUATED';
const REMOVE_WITHDREW = 'REMOVE_WITHDREW';
const REMOVE_TRANSFERRED = 'REMOVE_TRANSFERRED';
const REMOVE_OTHER = 'REMOVE_OTHER';
const OTHER = 'OTHER';

// Use this for dropdown menus: original
const UPLOAD_TYPE_CHOICES = [
    { value: ADD, label: 'Add New Students' },
    { value: UPDATE, label: 'Update Student Information' },
    { value: REMOVE_GRADUATED, label: 'Remove Students: Graduated' },
    { value: REMOVE_WITHDREW, label: 'Remove Students: Withdrew' },
    { value: REMOVE_TRANSFERRED, label: 'Remove Students: Transferred' },
    { value: REMOVE_OTHER, label: 'Remove Students: Other' },
    { value: OTHER, label: 'Other' }
];

// Use this for dropdown menus: updated
const UPLOAD_TYPE_CHOICES_UPDATED = [
    { value: ADD, label: 'Add New Students' },
    { value: UPDATE, label: 'Update Student Information' },
    { value: REMOVE_GRADUATED, label: 'Remove Students: Graduated' },
    { value: REMOVE_WITHDREW, label: 'Remove Students: Did not persist' },
    { value: REMOVE_TRANSFERRED, label: 'Remove Students: Transferred' },
    { value: REMOVE_OTHER, label: 'Remove Students: Other' },
    { value: OTHER, label: 'Other' }
];

export default {
    NEW,
    IN_PROGRESS,
    IMPORTED,
    ADD,
    UPDATE,
    REMOVE_GRADUATED,
    REMOVE_WITHDREW,
    REMOVE_TRANSFERRED,
    OTHER,
    STATUS_CHOICES,
    UPLOAD_TYPE_CHOICES,
    UPLOAD_TYPE_CHOICES_UPDATED,
    REMOVE_OTHER
};
