<template>
    <div class="flow-school-info-data-item">
        <span>{{ label }}</span>
        <strong>{{ value }}</strong>
    </div>
</template>

<script setup>
defineProps({
        label: {
        type: String,
        required: true
    },
    value: {
        type: String,
        required: true
    }
});
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.flow-school-info-data-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;

    span {
        font-size: $small-font-size;
        color: $light-gray-2;
        font-family: $base-font-family;
    }

    strong {
        font-size: $base-font-size;
        color: $title-color;
        font-family: $semi-bold-font-family;
    }
}
</style>
