<template>
    <div class="app-header">
        <div class="logo">
            <img src="@/assets/edsights-logo-square.png" alt="EdSights logo" />
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppHeader'
};
</script>

<style lang="scss" scoped>
@import '../styles/header';

.app-header {
    @extend %header;
}
</style>
