import { objectToCamelCase, objectToSnakeCase } from '../utils';
import api from './api';
import options from './options';

export default class InterventionTemplate {
    static api = api.create(InterventionTemplate);
    static options = options;

    constructor({
        id = null,
        type = null,
        studentCount = null,
        text = '',
        renderedText = '',
        adminViewed = null,
        mostRecentInterventionDate = null
    } = {}) {
        Object.assign(this, {
            id,
            type,
            studentCount,
            text,
            renderedText,
            adminViewed,
            mostRecentInterventionDate
        });
    }

    /* A "suggested intervention" is one that is admin-facing. */
    get isSuggested() {
        return this.type === InterventionTemplate.options.ADMIN;
    }

    static create(opts) {
        return new InterventionTemplate(opts);
    }

    static fromAPI(json) {
        return new InterventionTemplate(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }
}
