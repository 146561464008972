<template>
    <button :disabled="disabled || loading">
        {{ !loading ? text : '' }}
        <loading-spinner v-if="loading" :color="color"></loading-spinner>
    </button>
</template>

<script>
import LoadingSpinner from './LoadingSpinner';

export default {
    name: 'LoadingSpinnerButton',
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        color: {
            type: String,
            required: false,
            default: 'white'
        }
    },
    components: {
        LoadingSpinner
    }
};
</script>

<style lang="scss" scoped></style>
