<template>
    <div class="students-list">
        <div class="header-container">
            <h2>{{ title }}</h2>
            <div
                v-if="schoolIsStarfishReady && canAccessStarfishReports && showStarfishReports"
                class="starfish-export-button-container"
            >
                <div
                    v-if="!loadingStudents"
                    class="download-link clickable"
                    @click="onStarfishDownloadClick"
                >
                    Starfish Exports
                </div>
                <ModalStarfishReports />
            </div>
        </div>
        <div class="table-header">
            <StudentAnalysisFilter
                @filterChanged="filterChanged"
                :showStudentSearch="showStudentSearch"
                :showTagFilter="showTagFilter"
                :showRiskLevelFilter="showRiskLevelFilter"
                :showRiskDriverFilter="showRiskDriverFilter"
                :showPhaseFilter="showPhaseFilter"
                :showNumberValidityFilter="showNumberValidityFilter"
                :showOptInStatusFilter="showOptInStatusFilter"
                :showActionTakenFilter="showActionTakenFilter"
                :showMoreFiltersButton="showMoreFiltersButton"
            />
        </div>
        <div v-if="subtitle" class="subtitle text-gray">{{ subtitle }}</div>
        <div class="student-actions">
            <transition name="selected-students">
                <div
                    v-if="selectedStudentsCount !== 0 && !loadingStudents"
                    class="selected-students"
                    role="alert"
                >
                    <span>{{ msgStudentsSelected }}</span>
                    <div class="table-header__actions-block">
                        <!-- ACTION BUTTONS -->
                    </div>
                </div>
                <div
                    v-else-if="selectedStudentsCount !== 0 && loadingStudents"
                    class="selected-students"
                >
                    <LoadingSpinner />
                </div>
            </transition>
            <div class="actions-container">
                <ButtonDropDown
                    :disabled="selectedStudents.length <= 0 || loadingActions"
                    @btn-toggle="onActionDdToggle"
                    :isSecondary="selectedStudents.length <= 0 ? true : false"
                    :actions="actionItems"
                    :loading="loadingActions"
                >
                    Actions
                    <span>
                        <svg
                            width="10px"
                            height="10px"
                            class="dd-icon"
                            :class="{
                                'dd-icon__enabled': selectedStudents.length >= 1,
                                'dd-icon__open': actionDropBtnOpened
                            }"
                            viewBox="0 0 292.362 292.362"
                        >
                            <use xlink:href="#caret-down" />
                        </svg>
                    </span>
                </ButtonDropDown>
            </div>
        </div>

        <div class="students-wrapper">
            <LoadingSpinner v-if="loadingStudents" />
            <div v-else class="list" role="table">
                <StudentListItemHeader
                    ref="tableHeader"
                    :show-risk-drivers="showRiskDrivers ? true : false"
                    :selected="isMainCheckboxSelected"
                    @select="onTurnOnMainCheckbox"
                    @unselect="onTurnOffMainCheckbox"
                    :showDateAdded="showDateAdded"
                    :showOutreachStatus="showOutreachStatus"
                />
                <div role="rowgroup">
                    <StudentListItem
                        v-for="student in students"
                        :key="student.id"
                        :show-risk-drivers="showRiskDrivers ? true : false"
                        :selected="
                            selectedStudents.find(item => item.id === student.id) ? true : false
                        "
                        :student="student"
                        @select="onSelectStudent"
                        @unselect="onUnselectStudent"
                        @studentClick="onClickStudent"
                        @toggleOutreach="toggleOutreach"
                        :showDateAdded="showDateAdded"
                        :showOutreachStatus="showOutreachStatus"
                        :boldItem="
                            unviewedViewedInterventionDetails.findIndex(
                                i => i.student == student.id
                            ) > -1
                                ? true
                                : false
                        "
                    />
                </div>
                <div class="table-footer" role="rowgroup">
                    <div role="cell">
                        <Paginator
                            v-if="hasMoreStudents"
                            :loading="loadingStudents"
                            :nextPage="hasMoreStudents"
                            @next-page="$emit('students-next-page')"
                        />
                    </div>
                </div>
                <div v-if="!students || students.length === 0">
                    <div class="h4">Nothing to see here, yet.</div>
                    <p>
                        As students fill out the survey and engage over text, you will see the
                        results populate here.
                    </p>
                </div>
            </div>
        </div>
        <risk-driver-info-modal ref="RiskDriverInfoModal"></risk-driver-info-modal>
        <ModalReportDownload />
    </div>
</template>

<script>
import Student from '@/services/students';

import RiskDriverInfoModal from '@/components-deprecated/RiskDriverInfoModal';
import ButtonDropDown from '@/components-deprecated/ButtonDropDown';
import StudentListItem from '@/components-deprecated/tables/StudentListItem';
import StudentListItemHeader from '@/components-deprecated/tables/StudentListItemHeader';
import Paginator from '@/components-deprecated/Paginator';
import StudentAnalysisFilter from '@/components-deprecated/StudentAnalysisFilter';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import ModalReportDownload from '@/components-deprecated/admin/ModalReportDownload';
import ModalStarfishReports from '@/components-deprecated/admin/ModalStarfishReports';
import { isUIFeatureFlagEnabled, isDbSchoolFeatureFlagEnabled } from '@/lib/feature-flag';
import { starfishNotesReadinessBySchoolId } from '@/api/report-request';

export default {
    components: {
        StudentListItem,
        StudentListItemHeader,
        RiskDriverInfoModal,
        ButtonDropDown,
        Paginator,
        StudentAnalysisFilter,
        LoadingSpinner,
        ModalReportDownload,
        ModalStarfishReports
    },
    props: {
        // temporarily adding pagination params here PB
        hasMoreStudents: {
            default: false,
            type: Boolean
        },
        title: {
            type: String,
            default: 'Students'
        },
        showRiskDrivers: {
            type: Boolean,
            default: true
        },
        subtitle: {
            type: String,
            default: null
        },
        students: {
            type: Array,
            default: () => []
        },
        totalStudentsCount: {
            type: Number,
            default: 0
        },
        hideFilter: {
            type: Boolean,
            default: false
        },
        displayBtnExport: {
            type: Boolean,
            default: true
        },
        displayBtnEmail: {
            type: Boolean,
            default: true
        },
        initialSearchQuery: {
            type: String,
            default: ''
        },
        selectedInterventionId: {
            type: Number,
            default: 0
        },
        showOutreachStatus: {
            type: Boolean,
            default: false
        },
        showDateAdded: {
            type: Boolean,
            default: false
        },
        showActionDropBtn: {
            type: Boolean,
            default: false
        },
        unviewedViewedInterventionDetails: {
            type: Array,
            default: () => []
        },
        loadingStudents: {
            type: Boolean,
            default: false
        },
        loadingActions: {
            type: Boolean,
            default: false
        },
        showStudentSearch: {
            type: Boolean,
            default: true
        },
        showTagFilter: {
            type: Boolean,
            default: true
        },
        showRiskLevelFilter: {
            type: Boolean,
            default: true
        },
        showRiskDriverFilter: {
            type: Boolean,
            default: true
        },
        showPhaseFilter: {
            type: Boolean,
            default: true
        },
        showNumberValidityFilter: {
            type: Boolean,
            default: true
        },
        showOptInStatusFilter: {
            type: Boolean,
            default: true
        },
        showActionTakenFilter: {
            type: Boolean,
            default: false
        },
        showMoreFiltersButton: {
            type: Boolean,
            default: true
        },
        showStarfishReports: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            searchFilter: this.$props.initialSearchQuery,
            selectedFilterGroups: [],
            selectedStudents: [],
            selectedFilters: [],
            isMainCheckboxSelected: false,
            LOW_RISK: Student.options.LOW_RISK,
            HIGH_RISK: Student.options.HIGH_RISK,
            MEDIUM_RISK: Student.options.MEDIUM_RISK,
            currentInterventionId: 0,
            actionDropBtn: this.actionDropBtnItems,
            actionDropBtnOpened: false,
            actionDropBtnItems: [
                { name: 'Export', onclick: this.showDownloadModal },
                { name: 'Copy Emails', onclick: this.onEmail },
                { name: 'Create Note', onclick: this.onCreateMassNote }
            ],
            schoolIsStarfishReady: false
        };
    },
    async created() {
        // Starfish reports will be released per school. We're using school
        // level feature flags in the database to drive this.
        const schoolId = this.$store.state.user.schoolRef.id;
        const featureFlagName = 'ENABLE_STARFISH';
        this.schoolIsStarfishReady = await isDbSchoolFeatureFlagEnabled(schoolId, featureFlagName);
    },
    computed: {
        canAccessStarfishReports() {
            return (
                (this.$store.state.user.adminAccount &&
                    this.$store.state.user.adminAccount.type === 'MASTER') ||
                this.$store.state.user.isStaff
            );
        },
        selectedStudentsCount() {
            return this.selectedStudents.length;
        },
        msgStudentsSelected() {
            if (this.isMainCheckboxSelected) {
                return `${this.totalStudentsCount} student${
                    this.totalStudentsCount !== 1 ? 's' : ''
                } selected`;
            } else {
                const count = this.selectedStudentsCount;
                return `${count} student${count !== 1 ? 's' : ''} selected`;
            }
        },
        actionItems() {
            let actions = this.actionDropBtnItems;

            if (this.showOutreachStatus) {
                actions.push({ name: 'Mark Action Taken', onclick: this.onMarkActionTaken });
            }

            return actions;
        }
    },
    watch: {
        // If an action has been completed, clear all selected students.
        loadingActions(isLoading) {
            if (!isLoading) {
                this.clearSelectedStudents();
            }
        },
        searchFilter() {
            this.filterChanged();
        },
        selectedStudents() {
            this.isMainCheckboxSelected =
                this.selectedStudents.length === this.students.length && this.students.length !== 0;
        },
        students() {
            if (this.isMainCheckboxSelected) {
                this.selectedStudents = [...this.students];
            }
            if (this.selectedInterventionId !== this.currentInterventionId) {
                //with every newly selected Intervention, clear selectedStudents
                this.clearSelectedStudents();
            }
        }
    },
    methods: {
        onStarfishDownloadClick() {
            this.$modal.show('modal-starfish-reports');
        },
        onActionDdToggle(val) {
            this.actionDropBtnOpened = val;
        },
        filterChanged(selectedFilters = this.selectedFilters) {
            this.selectedFilters = selectedFilters;
            this.$emit('filter', selectedFilters);
        },
        /**
         * If main checkbox is selected emits object with property `isAllSelected` to hit no-pagination endpoint for all emails, otherwise emits array of selected emails
         */

        onEmail() {
            if (this.isMainCheckboxSelected) {
                this.$emit('email');
            } else {
                this.$emit('email', this.selectedStudents);
            }
        },
        showDownloadModal() {
            this.$modal.show('modal-report-download', {
                title: 'Export Download Options',
                downloadReport: this.onExport
            });
        },
        async onExport({ selectedTagCategories }) {
            let tagCategoriesToInclude = '';
            if (selectedTagCategories) {
                tagCategoriesToInclude = selectedTagCategories.map(category => category.id).join();
            }

            if (this.isMainCheckboxSelected) {
                this.$emit('export', { tagCategories: tagCategoriesToInclude });
            } else {
                this.$emit('export', {
                    studentIds: this.selectedStudents.map(student => student.id),
                    tagCategories: tagCategoriesToInclude
                });
            }
        },
        onCreateMassNote() {
            if (this.isMainCheckboxSelected) {
                this.$emit('create-mass-note', {
                    isAllSelected: true,
                    students: this.selectedStudents
                });
            } else {
                this.$emit('create-mass-note', {
                    students: this.selectedStudents
                });
            }
            this.clearSelectedStudents();
        },
        toggleOutreach(markAs, student) {
            this.$emit('markActionTaken', { action: markAs, students: [student] });
        },
        onMarkActionTaken() {
            if (this.isMainCheckboxSelected) {
                this.$emit('markActionTaken', { action: true, isAllSelected: true });
            } else {
                this.$emit('markActionTaken', {
                    action: true,
                    students: this.selectedStudents.map(student => student.id)
                });
            }
        },
        onClickEdit(...args) {
            this.$emit('edit', ...args);
        },
        onTurnOnMainCheckbox() {
            this.isMainCheckboxSelected = true;
            this.selectedStudents = [...this.students];
        },
        onTurnOffMainCheckbox() {
            this.isMainCheckboxSelected = false;
            this.selectedStudents = [];
        },
        onSelectStudent(student) {
            this.selectedStudents.push(student);
        },
        onUnselectStudent(student) {
            this.selectedStudents = this.selectedStudents.filter(
                selectedStudent => selectedStudent !== student
            );
        },
        onEditTags(...args) {
            this.$emit('editTags', ...args);
        },
        onClickStudent(student) {
            this.redirectToStudentDetail(student.id);
        },
        redirectToStudentDetail(id) {
            const routeData = this.$router.resolve({
                name: 'StudentDetail',
                params: { id: id }
            });
            window.open(routeData.href, '_blank');
        },
        clearSelectedStudents() {
            this.selectedStudents = [];
        }
    }
};
</script>

<style lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins/lists';
.student-actions {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    .actions-container {
        margin-left: auto;
        margin-right: 2.6rem;
        .button {
            margin: 0rem;
            min-width: 50px;

            padding: 0 3rem;
        }
    }
}
.students-list {
    .students-wrapper {
        display: flex;
        position: relative;

        .risk-driver-exp {
            max-width: 500px;
            bottom: 100%;
            right: 0;
        }
    }

    .table-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &__name {
            font-size: 23px;
        }

        &__actions-block {
            display: flex;
            align-items: center;
            margin: 1.5rem 1rem;
        }

        &__search {
            margin-right: 20px;
        }

        &__btn-email {
            margin-right: 15px;
        }
    }

    .list {
        width: 100%;
        height: calc(100vh - 550px);
        overflow-y: auto;

        .header {
            z-index: 8;
        }
    }

    @media screen and (max-width: 1295px) {
        .list {
            &__item {
                justify-content: flex-start;
            }

            &__item-level {
                margin-right: 40px;
            }

            &__item-driver {
                width: 120px;
            }

            &__item-name {
                margin-right: 10px;
                flex: 0.5;
            }

            &__item-email {
                max-width: 100px;
            }
        }
    }

    @media screen and (max-width: 960px) {
        .list {
            &__item {
                padding: 4px 0 4px 0;
            }

            &__item-checkbox {
                padding: 0;
                padding-left: 5px;
            }

            &__item-name {
                max-width: 100px;
            }

            &__item-driver {
                width: 82px;
            }

            &__item-level {
                width: 74px;
                margin-right: 5px;
            }

            &__item-email {
                margin-right: 10px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .list {
            height: auto;
        }
        .filters {
            height: auto;

            &__title {
                position: relative;
            }
        }
        .table-header {
            height: auto;
            flex-wrap: wrap;

            &__actions-block {
                flex-wrap: wrap;
                margin-right: 1rem;
            }

            &__search {
                margin-bottom: 10px;
            }
        }
        .students-wrapper {
            display: block;
            margin-top: 10px;
        }
    }

    .subtitle {
        margin-bottom: 25px;
        width: 80%;
    }
    .selected-students {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 25px;
    }

    .selected-students-enter-active {
        transition: all 0.25s linear;
    }

    .selected-students-leave-active {
        transition: all 0.25s linear;
    }

    .selected-students-enter,
    .selected-students-leave-to {
        max-height: 0;
        opacity: 0;
        // margin-bottom: 0;
    }

    .selected-students-enter-to,
    .selected-students-leave {
        max-height: 50px;
        opacity: 1;
        margin-bottom: 10px;
    }
    .dd-icon {
        margin-left: 1rem;
        fill: #b4b4b5;
        &__enabled {
            fill: white;
        }
        &__open {
            fill: white;
        }
    }

    .icon-download {
        stroke: $primary-brand-color;
        margin-left: 1rem;
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        .starfish-export-button-container {
            .download-link {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-weight: bold;
                margin-left: 3rem;
                color: $primary-brand-color;
            }
        }
    }
}
</style>
