import Staff from '@/services/staff';

export function setStaffSchoolViewEnabled({ commit }, enabled) {
    commit('setStaffSchoolViewEnabled', enabled);
}

export function updateStaffUserAndEnterSchoolMode({ commit, state }, schoolId) {
    const school = { id: schoolId };
    // Staff.api.update() updates the school associated to a staff user (user.school_id in database)
    return Staff.api.update(state.user.id, school).then(data => {
        commit('UPDATE_USER', {
            ...data
        });
        commit('setStaffSchoolViewEnabled', true);
    });
}
