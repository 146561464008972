<template>
    <modal
        classes="v--modal"
        name="modal-staff-students-upload"
        width="800"
        height="auto"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
        @closed="closed"
        :stack="false"
    >
        <div class="modal">
            <header class="modal__header">
                <h3 class="modal__header__title">
                    Manage Students
                    {{ uploadObject.schoolRef ? `at ${uploadObject.schoolRef.name}` : '' }}
                </h3>
            </header>
            <div class="modal__content">
                <div class="step">
                    <span class="step-title"> Step 1: Select a school </span>

                    <div class="school-filter">
                        <span class="filter-label"> School filter: </span>
                        <div class="school-dropdown">
                            <FilterButton
                                :data="schools.list"
                                @school="selectSchool"
                                functionType="school"
                                :initialValue="{ id: uploadObject.school, name: uploadObject.name }"
                                :initialValueLength="0"
                            ></FilterButton>
                        </div>
                    </div>
                </div>
                <div class="step">
                    <span class="step-title"> Step 2: Student upload type </span>

                    <div class="upload-type">
                        <v-select
                            class="status-dropdown"
                            :options="StudentUpload.options.UPLOAD_TYPE_CHOICES"
                            v-model="uploadObject.uploadTypeRef"
                            :clearable="false"
                        />
                    </div>
                    <span class="step-details">
                        <span
                            class="step-error"
                            v-if="uploadObject.uploadType == StudentUpload.options.OTHER"
                        >
                            Please select a different upload type
                        </span>
                        <br />
                    </span>
                </div>
                <div class="step">
                    <span class="step-title"> Step 3: Enter a title </span>

                    <input type="text" v-model="uploadObject.title" />
                </div>
                <div class="step">
                    <span class="step-title"> Step 4: Upload your file </span>
                    <br />
                    <span class="step-details">
                        <span
                            class="step-error"
                            v-if="hasClickedDownload && !uploadObject.uploadType"
                        >
                            Please select an upload type.
                        </span>
                        <br />
                        Upload the students you want to perform the above action on.
                        <span
                            @click="onDownloadSampleSpreadsheet"
                            :class="{ disabled: !uploadObject.uploadType }"
                            class="download-link text-primary"
                            >Download</span
                        >
                        this sample spreadsheet to see the format you should use for your upload.
                    </span>
                    <div class="upload-container">
                        <br />
                        <ChooseFileCancelable :readAsString="false" @loaded="fileUploaded">
                            <template v-slot:message>
                                <h3 class="upload-message">Upload your Spreadsheet</h3>
                            </template>
                        </ChooseFileCancelable>
                    </div>
                </div>
                <div class="step">
                    Additional notes:
                    <ElasticTextArea
                        class="notes__textarea"
                        placeholder="Start typing..."
                        v-model="uploadObject.notes"
                        bordered
                    />
                </div>
                <div class="step">
                    <span class="step-detail">
                        <span v-if="complete" class="step-info">
                            Note: This flow differs from the upload flow for admins. Changes from
                            your CSV will be implemented immediately.
                        </span>
                    </span>
                    <br />
                    <Button v-if="!loading" :disabled="!complete" @click="onSubmit">Submit</Button>
                    <span v-else>
                        <LoadingSpinner />
                    </span>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Student from '@/services/students';
import School from '@/services/schools';
import TagMapping from '@/services/tagMappings';
import CollectionManager from '@/services/collectionManager';
import ChooseFileCancelable from '@/components-deprecated/ChooseFileCancelable';
import StudentUpload from '@/services/studentUploads';
import ElasticTextArea from '@/components-deprecated/ElasticTextArea';
import Button from '@/components-deprecated/Button';
import FilterButton from '@/components-deprecated/FilterButton';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import BackgroundOperation from '@/services/backgroundOperations';
import moment from 'moment';
import { mapActions } from 'vuex';
export default {
    components: {
        ChooseFileCancelable,
        ElasticTextArea,
        Button,
        FilterButton,
        LoadingSpinner
    },
    props: {
        cancelBtn: {
            type: String,
            default: 'Skip'
        }
    },
    data() {
        return {
            moment,
            uploadObject: StudentUpload.create(),
            StudentUpload,
            schools: CollectionManager.create({
                ModelClass: School
            }),
            loading: false,
            complete: false,
            //show a small error if someone tries to download a spread sheet without
            // selecting a type
            hasClickedDownload: false
        };
    },
    watch: {
        getUploadType(val) {
            if (val !== this.uploadObject.uploadType) {
                this.uploadObject.uploadType = val;
            }
        },
        uploadObject: {
            deep: true,
            immediate: true,
            handler() {
                this.complete =
                    !!this.uploadObject.school &&
                    !!this.uploadObject.uploadType &&
                    !!this.uploadObject.file &&
                    !!this.uploadObject.title.length &&
                    this.uploadObject.uploadType != StudentUpload.options.OTHER;
            }
        }
    },
    methods: {
        ...mapActions(['updateBackgroundOperation', 'setBackgroundOperationCompletionAction']),
        selectSchool(school = null) {
            this.uploadObject.schoolRef = school;
            this.uploadObject.school = school ? school.id : null;
        },
        async listSchools() {
            await this.schools.refresh();
            while (this.schools.pagination.hasNextPage) {
                // TODO implement a dropdown pagination for schools. Current implementaion is temporory to get feature out
                this.schools.addNextPage();
            }
        },

        async beforeOpen(event) {
            this.uploadObject = StudentUpload.create();
            this.schools = CollectionManager.create({
                ModelClass: School
            });
            await this.listSchools();
            if (event.params) {
                this.handler = event.params.handler;
            }
        },
        closed() {
            this.$emit('refresh');
        },
        async onDownloadSampleSpreadsheet() {
            if (!this.uploadObject.uploadType) {
                this.hasClickedDownload = true;
                return;
            }
            let filename = '';
            switch (this.uploadObject.uploadType) {
                case StudentUpload.options.ADD:
                    filename = 'Student Importer Template - Add';
                    break;
                case StudentUpload.options.UPDATE:
                    filename = 'Student Importer Template - Update';
                    break;
                case StudentUpload.options.REMOVE_GRADUATED:
                    filename = 'Student Importer Template - Remove Graduated';
                    break;
                case StudentUpload.options.REMOVE_WITHDREW:
                    filename = 'Student Importer Template - Remove Withdrew';
                    break;
                case StudentUpload.options.REMOVE_TRANSFERRED:
                    filename = 'Student Importer Template - Remove Transferred';
                    break;
                case StudentUpload.options.REMOVE_OTHER:
                    filename = 'Student Importer Template - Remove Other';
                    break;
                case StudentUpload.options.OTHER:
                    filename = 'Student Importer Template - Other';
                    break;
            }
            window.open(`/static/${filename}.csv`);
            this.sampleDownloaded = true;
        },
        async fileUploaded(file) {
            this.uploadObject.file = file;
        },
        async uploadFile() {
            let response;

            try {
                switch (this.uploadObject.uploadType) {
                    case StudentUpload.options.ADD:
                        response = await Student.api.createStudentsFromCSV(
                            this.uploadObject.file,
                            this.uploadObject.school
                        );
                        break;
                    case StudentUpload.options.UPDATE:
                        response = await Student.api.updateStudentsFromCSV(
                            this.uploadObject.file,
                            this.uploadObject.school
                        );
                        break;
                    case StudentUpload.options.REMOVE_GRADUATED:
                        response = await Student.api.deactivateStudentsFromCSV(
                            this.uploadObject.file,
                            this.uploadObject.school,
                            'GRADUATED'
                        );
                        break;
                    case StudentUpload.options.REMOVE_WITHDREW:
                        response = await Student.api.deactivateStudentsFromCSV(
                            this.uploadObject.file,
                            this.uploadObject.school,
                            'WITHDREW'
                        );
                        break;
                    case StudentUpload.options.REMOVE_TRANSFERRED:
                        response = await Student.api.deactivateStudentsFromCSV(
                            this.uploadObject.file,
                            this.uploadObject.school,
                            'TRANSFERRED'
                        );
                        break;
                    case StudentUpload.options.REMOVE_OTHER:
                        response = await Student.api.deactivateStudentsFromCSV(
                            this.uploadObject.file,
                            this.uploadObject.school,
                            'OTHER'
                        );
                        break;
                }
                // Create a Student Upload to track our action
                this.uploadObject.status = StudentUpload.options.IMPORTED;
                await StudentUpload.api.create(this.uploadObject);

                // If the response we received is a Background Operation, add it to global state
                if (response.isBackground) {
                    this.updateBackgroundOperation(response.backgroundOp);
                    // If not, emit results so parent component can show them
                } else {
                    this.$emit('results', response.results);
                }
                this.$modal.hide('modal-staff-students-upload');
            } finally {
                this.loading = false;
            }
        },
        showTagMappingModal(tagMappings) {
            this.$modal.show('modal-tag-mapping', {
                tagMappings: tagMappings,
                school: this.uploadObject.school,
                onSubmit: this.uploadFile
            });
        },
        async onVerify() {
            const response = await TagMapping.api.createTagMappingsFromCSV(
                this.uploadObject.file,
                this.uploadObject.school
            );

            if (response.isBackground) {
                this.updateBackgroundOperation(response.backgroundOp);
                this.setBackgroundOperationCompletionAction({
                    backgroundOperationId: response.backgroundOp.id,
                    action: this.showTagMappingModal.bind(this)
                });
                this.loading = false;
            } else if (response.results[0].value.length > 0) {
                this.showTagMappingModal(response.results[0].value);
            } else {
                await this.uploadFile();
                this.loading = false;
            }
        },
        onCancel() {
            this.$modal.show('modal-staff-students-upload');
            this.loading = false;
        },
        async onSubmit() {
            this.loading = true;

            if (
                this.uploadObject.uploadType === StudentUpload.options.ADD ||
                this.uploadObject.uploadType === StudentUpload.options.UPDATE
            ) {
                this.$modal.hide('modal-staff-students-upload');
                this.$modal.show('modal-csv-verify', {
                    file: this.uploadObject.file,
                    onSubmit: this.onVerify,
                    onCancel: this.onCancel
                });
            } else {
                await this.uploadFile();
                this.loading = false;
            }
        }
    },

    computed: {
        getUploadType() {
            // reducer from v-select is not working as expected using this instead
            return this.uploadObject.uploadTypeRef.value
                ? this.uploadObject.uploadTypeRef.value
                : null;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/views/modal';
@import '../../styles/forms';
@import '../../styles/variables';

.modal {
    &__header {
        background-color: white;
        padding: 1rem 0rem;
        margin: 0rem;
        border-bottom: solid 1px #e3eded;
        &__title {
            margin: 0 2rem;
            font-weight: bold;
            font-size: 1.5rem;
            color: #202123;
        }
    }
}
.download-link {
    &.disabled {
        color: gray;
        &:hover {
            cursor: not-allowed;
        }
    }
    &:hover {
        cursor: pointer;
    }
}
.step-title {
    font-weight: bold;
}

.modal__content {
    padding: 0rem 0rem;
}

.modal__btn {
    display: inline-block;
    margin: 0;
    margin-right: 15px;
}

.modal__btn-wrapper {
    display: flex;
    justify-content: flex-end;
}
.step {
    margin: 2rem 2rem;
}
.school-filter {
    display: flex;
}
.filter-label {
    margin-top: 1.5rem;
}
.school-dropdown {
    height: 20px;
}
:deep(.upload-type) {
    // v-deep is a utility to select a deep component when using scoped
    // targeting in this case only the dd in upload-type
    > .dropdown {
        // manually setting input style here

        input[type='text'] {
            width: 100%;

            height: 20px;
            border-radius: 6px;
            box-shadow: 0 5px 20px 0 #f6f9fe;
            border: solid 1px #e3eded;
            background-color: rgba(0, 0, 0, 0.02);
        }
    }
}
.step-error {
    color: red;
    font-size: 10px;
    font-style: italic;
}
.step-info {
    color: $primary-brand-color;
    font-size: 10px;
    font-style: italic;
}

form {
    @extend %form;
    input[type='radio'] + label {
        min-width: 1.5rem;
        width: 1.5rem;
    }
}
</style>
