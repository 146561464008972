export const CHATBOT_FLOW_STATUS = {
    SCHEDULED: {
        value: 'SCHEDULED',
        label: 'Scheduled'
    },
    INITIATED: {
        value: 'INITIATED',
        label: 'Sent'
    },
    DRAFT: {
        value: 'DRAFT',
        label: 'Draft'
    },
    CANCELED: {
        value: 'CANCELED',
        label: 'Cancelled'
    }
};
// placeholder date for draft flows (saved in lieu of a null date)
export const DRAFT_NULL_DATE_PLACEHOLDER = '5555-05-05T00:55:00.000Z';
