export const INTERVENTION_TEMPLATE_TYPES = {
    ADMIN: {
        value: 'ADMIN',
        isOpportunityForFollowUp: true
    },
    CHATBOT: {
        value: 'CHATBOT',
        isOpportunityForFollowUp: false
    }
};
