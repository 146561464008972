<template>
    <BoxContainer class="document" :subtitle="resourceTitle">
        <div class="document__content">
            <div class="document__description" v-if="userIsStaff">
                Here you can upload documents that you want to share with your partner.
            </div>
            <div class="document__description" v-if="!userIsStaff">
                Here you can download documents that your EdSights point of contact has shared with
                you.
            </div>
        </div>
        <SchoolDocumentsUploadModal
            @createSchoolDocument="createSchoolDocument"
            @onModalCancel="onModalCancel"
        />
        <List v-if="dataLoaded" class="document__content">
            <template v-slot:list-actions>
                <Button class="base-button" v-if="userIsStaff" @click="onOpenUpload">
                    New
                </Button>
            </template>
            <template v-slot:list-items>
                <table>
                    <colgroup>
                        <col style="width:75%" />
                        <col style="width:25%" />
                    </colgroup>
                    <thead>
                        <th class="column-header">File Name</th>
                        <th class="column-header">Actions</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr v-for="document in documents" :key="document.id" class="document-row">
                            <td>{{ document.fileName }}</td>
                            <td>
                                <button
                                    class="download-link text-primary"
                                    @click="onDownload(document)"
                                >
                                    DOWNLOAD
                                </button>
                                <button
                                    class="delete-link text-primary"
                                    v-if="userIsStaff"
                                    @click="onDelete(document.id)"
                                >
                                    DELETE
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </List>
        <LoadingSpinner v-if="!dataLoaded" />
    </BoxContainer>
</template>

<script>
import { nextTick } from 'vue';

import Button from '@/components-deprecated/Button';
import BoxContainer from '@/components-deprecated/BoxContainer';
import SchoolDocumentsUploadModal from '@/components-deprecated/SchoolDocumentsUploadModal';
import * as SchoolDocumentApi from '@/api/school-document';
import List from '@/components-deprecated/tables/List';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';

export default {
    name: 'SchoolDocuments',

    components: {
        Button,
        BoxContainer,
        SchoolDocumentsUploadModal,
        List,
        LoadingSpinner
    },

    data() {
        return {
            dataLoaded: false,
            documents: []
        };
    },

    computed: {
        userIsStaff() {
            return this.$store.state.user.isStaff;
        }
    },

    props: {
        resourceTitle: {
            type: String,
            default: 'My Documents'
        }
    },

    methods: {
        onOpenUpload() {
            this.$modal.show('school-documents-upload-modal');
            nextTick(() => {
                const documentsModal = document.querySelector('.vm--container');
                if (documentsModal) {
                    documentsModal.focus();
                }
            });
        },
        onModalCancel() {
            this.$modal.hide('school-documents-upload-modal');
        },
        async createSchoolDocument({ schoolDocument, fileToUpload }) {
            try {
                // get signed url from AWS
                const signedUrl = await SchoolDocumentApi.getSignedUrlForUpload({
                    schoolDocument,
                    errorHandlerOptions: { enableAlert: false, rethrow: true }
                });
                // upload document to S3 bucket
                await SchoolDocumentApi.uploadToSignedUrl({
                    signedUrl,
                    fileToUpload,
                    errorHandlerOptions: { enableAlert: false, rethrow: true }
                });
                // insert school document record
                await SchoolDocumentApi.createSchoolDocument({
                    schoolDocument,
                    errorHandlerOptions: { enableAlert: false, rethrow: true }
                });
                // reload data
                this.loadData();
                this.$Alert.alert({
                    type: 'success',
                    message: 'File successfully uploaded.',
                    timeout: 3000
                });
                this.$modal.hide('school-documents-upload-modal');
            } catch (error) {
                console.log(error);
                this.$Alert.alert({
                    type: 'error',
                    message: 'There was a problem uploading the file. Please try again.',
                    timeout: 3000
                });
            }
        },
        loadData: async function() {
            this.dataLoaded = false;
            const result = await SchoolDocumentApi.listSchoolDocumentsBySchoolId(
                this.$store.state.user.schoolRef.id
            );
            this.documents = result.schoolDocuments;
            this.dataLoaded = true;
        },
        async onDownload(schoolDocument) {
            try {
                const signedUrl = await SchoolDocumentApi.getSignedUrlForDownload(
                    schoolDocument.id
                );
                if (signedUrl) {
                    return await SchoolDocumentApi.downloadSchoolDocument({
                        signedUrl,
                        schoolDocument,
                        errorHandlerOptions: { enableAlert: false, rethrow: true }
                    });
                }
            } catch (error) {
                console.log(error);
                this.$Alert.alert({
                    type: 'error',
                    message: 'There was a problem downloading the file. Please try again.',
                    timeout: 3000
                });
            }
        },
        async onDelete(id) {
            try {
                await SchoolDocumentApi.deleteSchoolDocument({
                    id,
                    errorHandlerOptions: { enableAlert: false, rethrow: true }
                });
                await this.loadData();
            } catch (error) {
                console.log(error);
                this.$Alert.alert({
                    type: 'error',
                    message: 'There was a problem deleting the file. Please try again.',
                    timeout: 3000
                });
            }
        }
    },
    async created() {
        await this.loadData();
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/mixins/tables';
@import '@/styles/mixins/buttons';

.document {
    &__content {
        padding: 25px 30px;
    }

    &__description {
        padding: 0 15px 25px 15px;
        border-bottom: 2px solid $border-color;
    }

    &:deep(table) {
        th {
            padding: 0px 10px;
        }

        td {
            padding: 20px 10px 20px 10px;
        }

        tr {
            border-bottom: 1px solid $border-color;
        }
    }
}

.scroll-container {
    overflow: overlay;
}

table {
    @include table();
}

.column-header {
    font-weight: bold;
}

.document-row {
    margin: 1rem;
}

.base-button {
    @include base-button();
}

.download-link {
    font-weight: bold;
    cursor: pointer;
    background: none;
    border: 0;
    padding: 8px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.delete-link {
    font-weight: bold;
    cursor: pointer;
    margin-left: 1rem;
    background: none;
    border: 0;
    padding: 8px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}
</style>
