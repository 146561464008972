import axios from 'axios';
import { API_BASE, apiErrorHandler } from '../api';
import Auth from '../auth';

const FAQ_INSTANCE_ENDPOINT = `${API_BASE}faq-instances/`;

export default class FAQInstanceAPI {
    constructor(cls) {
        this.cls = cls;
    }

    static create(cls) {
        return new FAQInstanceAPI(cls);
    }

    list({ pagination, filters } = {}) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
                ...Auth.getHeaders().headers
            }
        };

        const promise = axios
            .get(`${FAQ_INSTANCE_ENDPOINT}`, options)
            .then(response => response.data)
            .then(data => {
                return {
                    ...data,
                    results: data.results.map(this.cls.fromAPI)
                };
            })
            .catch(
                apiErrorHandler({
                    apiName: 'List FAQ Instance API error'
                })
            );
        return promise;
    }

    async create(faqInstance) {
        const url = FAQ_INSTANCE_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers
        };
        const data = this.cls.toAPI(faqInstance);

        const promise = axios
            .post(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Create FAQInstance API error' }));
        return promise;
    }

    async update(faqInstance) {
        const url = `${FAQ_INSTANCE_ENDPOINT}${faqInstance.id}/`;
        const data = this.cls.toAPI(faqInstance);
        const options = {
            headers: Auth.getHeaders().headers
        };

        const promise = axios
            .patch(url, data, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Update FAQInstance API error' }));
        return promise;
    }

    async delete(faqInstance) {
        const url = `${FAQ_INSTANCE_ENDPOINT}${faqInstance.id}`;
        const options = {
            headers: Auth.getHeaders().headers
        };

        const promise = axios
            .delete(url, options)
            .then(response => this.cls.fromAPI(response.data))
            .catch(apiErrorHandler({ apiName: 'Delete FAQInstance API error' }));
        return promise;
    }
}
