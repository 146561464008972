<template>
    <div>
        <div v-if="showLoadingSpinner">
            <LoadingSpinner />
        </div>
        <div else class="manage-uploads">
            <div class="school-filter-toolbar">
                <div class="filter-row" v-if="isStaff">
                    <div class="school-filter">
                        <SchoolDropDown @selected-school-id-updated="setSelectedSchool" />
                    </div>
                </div>
                <Button @click="onOpenUpload">New</Button>
            </div>
            <div class="integration-table">
                <div class="integration-table-header">
                    <div class="integration-header-small">Logs</div>
                    <div class="integration-header-medium">File</div>
                    <div class="integration-header-medium" v-if="isStaff">School</div>
                    <div class="integration-header-medium">Title</div>
                    <div class="integration-header-medium">Type</div>
                    <div class="integration-header-medium">Status</div>
                    <div class="integration-header-small"></div>
                </div>
                <div class="integration-table-rows">
                    <div
                        class="integration"
                        v-for="integration in displayIntegrations"
                        :key="integration.id"
                    >
                        <div class="integration-details">
                            <div class="integration-detail-cell-small" style="color: #38a0fa;">
                                <span
                                    style="cursor: pointer;"
                                    @click="toggleLogView(integration)"
                                    >{{ getDetailPromptText(integration) }}</span
                                >
                            </div>
                            <div class="integration-detail-cell-medium" style="color: #38a0fa;">
                                <span style="cursor: pointer;" @click="requestCsvUrl(integration)"
                                    >Download</span
                                >
                            </div>
                            <div class="integration-detail-cell-medium" v-if="isStaff">
                                {{ integration.school }}
                            </div>
                            <div class="integration-detail-cell-medium">
                                {{ integration.title }}
                            </div>
                            <div class="integration-detail-cell-medium">
                                {{ integration.integrationType }}
                            </div>
                            <div class="integration-detail-cell-medium">
                                {{ integration.integrationStatus }}
                            </div>
                            <div class="integration-detail-cell-small">
                                <Button
                                    :loading="integrationActionLoading.includes(integration.id)"
                                    v-if="
                                        integration.integrationStatus !== 'Integrated' &&
                                            integration.integrationStatus !== 'Error' &&
                                            !integration.integrationType
                                                .trim()
                                                .toLowerCase()
                                                .startsWith('auto')
                                    "
                                    :disabled="disableActionButton(integration)"
                                    @click="integrationActionHandler(integration)"
                                >
                                    {{ getActionTextByStatus(integration.integrationStatus) }}
                                </Button>
                            </div>
                        </div>
                        <div class="integration-log" v-if="integration.id === viewLogsId">
                            <div
                                class="integration-log-cell"
                                v-for="log in integration.logs"
                                :key="log.id"
                            >
                                <p v-if="log.createdAt">Date: {{ log.createdAt }}</p>
                                <p>Status: {{ log.integrationStatus }}</p>
                                <p>Created By: {{ log.email }}</p>
                                <!--
                                    The two details here exist only because, within
                                    integration logs, we have two styles of line separation.
                                    We'll eventually close this gap but for now it's
                                    easy enough to manipulate the text.
                                -->
                                <div>
                                    <details
                                        class="log-details"
                                        v-if="log.detail.split('\n').length > 1"
                                    >
                                        <summary>{{ logDetails(log, '\n').summary }}</summary>
                                        <span>{{ logDetails(log, '\n').details }}</span>
                                    </details>
                                    <details
                                        class="log-details"
                                        v-else-if="log.detail.split('\\\\').length > 1"
                                    >
                                        <summary>{{ logDetails(log, '\\\\').summary }}</summary>
                                        <span>{{ logDetails(log, '\\\\').details }}</span>
                                    </details>
                                    <span v-else>{{ log.detail }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Paginator
                            v-if="integrationCollection.nextPage"
                            :loading="integrationCollection.loadingNextPage"
                            :nextPage="integrationCollection.hasMoreItems"
                            @next-page="integrationCollection.addNextPage()"
                        />
                </div>
            </div>
        </div>
        <ModalIntegrationUpload :types="typeArray" @createIntegration="createIntegration" />
        <modal-integration-tag-mapping />
        <ModalIntegrationWarnings
            @onWarningsModalCancel="onWarningsModalCancel"
            @onWarningsModalContinue="onWarningsModalContinue"
        />
    </div>
</template>
<script>
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/Button';
import ModalIntegrationUpload from '@/components-deprecated/ModalIntegrationUpload';
import * as IntegrationStatusApi from '@/api/integration-status';
import * as IntegrationTypeApi from '@/api/integration-type';
import * as IntegrationApi from '@/api/integration';
import * as IntegrationLogsApi from '@/api/integration-logs';
import ModalIntegrationTagMapping from '@/components-deprecated/ModalIntegrationTagMapping.vue';
import ModalIntegrationWarnings from '@/components-deprecated/ModalIntegrationWarnings.vue';
import * as TagApi from '@/api/tags';
import SchoolDropDown from '@/components-deprecated/inputs/SchoolDropDown';
import { isDbSchoolFeatureFlagEnabled } from '@/lib/feature-flag';
import v2CollectionManager from '@/services/v2CollectionManager';
import Paginator from '@/components-deprecated/Paginator';

export default {
    name: 'ManageUploads',
    props: {
        integrationOrigin: String
    },
    components: {
        Button,
        LoadingSpinner,
        ModalIntegrationUpload,
        ModalIntegrationTagMapping,
        ModalIntegrationWarnings,
        SchoolDropDown,
        Paginator
    },
    data() {
        return {
            showLoadingSpinner: false,
            viewLogsId: null,
            integrationStatusArray: [],
            integrationTypeArray: [],
            selectedSchool: null,
            integrationActionLoading: [],
            integrationCollection: v2CollectionManager.create({
                listType: 'integrations',
                paginationOptions: {
                    size: 200
                },
                filters: {
                    integrationOrigin: this.integrationOrigin
                },
                errorHandlerOptions: {
                    enableAlert: true,
                    overrideMessage:
                        'There was a problem loading the integration list. Please try again.',
                    rethrow: false
                }
            })
        };
    },
    async created() {
        await this.loadIntegrations();
        this.integrationStatusArray = await IntegrationStatusApi.listStatuses();
        this.integrationTypeArray = await IntegrationTypeApi.listTypes();
    },
    computed: {
        isStaff() {
            return this.$store.state.user.isStaff;
        },
        typeArray() {
            return JSON.parse(JSON.stringify(this.integrationTypeArray));
        },
        displayIntegrations() {
            if (this.integrationCollection.list.length === 0) {
                return this.integrationCollection.list;
            }

            // allow staff to filter integrations by school
            if (this.selectedSchool) {
                return this.integrationCollection.list.filter(integration => {
                    return integration.schoolId === this.selectedSchool;
                });
            }

            // mark timed out integrations as error
            this.integrationCollection.list.forEach(integration => {
                if (integration.integrationStatus === 'New' && !this.shouldPoll(integration)) {
                    integration.integrationStatus = 'Error';
                }
            });

            return this.integrationCollection.list;
        }
    },
    methods: {
        async loadIntegrations() {
            this.integrationCollection.refresh();
        },
        logDetails(log, splitStr) {
            const result = {
                summary: '',
                details: ''
            };

            if (
                typeof log !== 'object' ||
                typeof log.detail !== 'string' ||
                typeof splitStr !== 'string'
            ) {
                result.summary = 'Received invalid log';
                return result;
            }

            const splitArray = log.detail.split(splitStr);
            result.summary = splitArray.shift();
            result.details = splitArray.join('\n');
            return result;
        },
        integrationIndexIsValid(integrationIndex) {
            // calculate out of bounds
            const integrationCount = this.integrationCollection.list.length;
            const outOfBounds = integrationIndex >= integrationCount || integrationIndex < 0;

            // handle strange, out of bounds edge cases
            if (typeof integrationIndex !== 'number' || outOfBounds) {
                this.$Alert.alert({
                    type: 'error',
                    message:
                        'Something went wrong. Please review your integration warnings before continuing.',
                    timeout: 5000
                });
                return false;
            }
            return true;
        },
        onWarningsModalContinue(integrationIndex) {
            if (this.integrationIndexIsValid(integrationIndex)) {
                // click the 'next' button for the user
                const currentIntegration = this.integrationCollection.list[integrationIndex];
                this.integrationActionHandler(currentIntegration);
            }

            // hide the modal
            this.$modal.hide('integration-warnings-modal');
        },
        async onWarningsModalCancel(integrationIndex) {
            if (this.integrationIndexIsValid(integrationIndex)) {
                // since the warnings modal is currently only enabled after the file
                // upload, we know we can set the integration status to InvalidCsv if
                // the user decides not to continue
                const integration = this.integrationCollection.list[integrationIndex];
                const response = await IntegrationApi.setInvalidStatus(integration.id);

                if (response.status === 200) {
                    const updatedIntegration = await IntegrationApi.getIntegrationById(
                        integration.id
                    );
                    this.integrationCollection.list[integrationIndex] = updatedIntegration[0];
                    this.integrationCollection.list = [...this.integrationCollection.list];
                } else {
                    this.$Alert.alert({
                        type: 'error',
                        message:
                            'Something went wrong. Please refresh your page before continuing.',
                        timeout: 5000
                    });
                }
            }

            // hide the modal
            this.$modal.hide('integration-warnings-modal');
        },
        onOpenUpload() {
            this.$modal.show('integration-upload-modal');
        },
        integrationActionHandler(integration) {
            if (integration && integration.integrationStatus) {
                switch (integration.integrationStatus.trim().toLowerCase()) {
                    case 'validcsv':
                        this.$modal.show('integration-tag-mapping-modal', {
                            integration,
                            onSubmit: async () => {
                                this.integrationActionLoading.push(integration.id);
                                await this.pollForUpdates(integration.id);
                            }
                        });
                        break;
                    case 'invalidcsv':
                    case 'invalidheaders':
                    case 'invalidvalues':
                    case 'failedintegration':
                        this.onOpenUpload();
                        break;
                    case 'validvalues':
                        this.integrationActionLoading.push(integration.id);
                        this.integrate(integration.id);
                        break;
                }
            }
        },
        clearIntegrationActionLoading(integrationId) {
            this.integrationActionLoading = this.integrationActionLoading.filter(
                i => i !== integrationId
            );
        },
        getDetailPromptText(integration) {
            if (integration.id === this.viewLogsId) {
                return 'Hide';
            } else {
                return 'View';
            }
        },
        async requestCsvUrl(integration) {
            const signedCsvUrl = await IntegrationApi.getCsvUrl(integration.id);
            if (signedCsvUrl && signedCsvUrl.data) {
                return await IntegrationApi.getCsv(signedCsvUrl.data, integration.title);
            }
        },
        viewingLogs(integration) {
            return integration.id === this.viewLogsId;
        },
        async toggleLogView(integration) {
            if (this.viewLogsId === integration.id) {
                this.viewLogsId = null;
            } else {
                if (!integration.logs) {
                    const logs = await IntegrationLogsApi.listLogsByIntegrationId(integration.id);
                    integration.logs = logs;

                    // If we're in an error state, add a non-permanent message to help instruct the user on what they should do next
                    if (integration.integrationStatus === 'Error') {
                        const temporaryErrorLog = {
                            detail: 'ERROR. Please reach out to your point of contact for help.',
                            email: 'System Generated',
                            id: Date.now(),
                            integrationId: integration.id,
                            integrationStatus: 'Error'
                        };
                        integration.logs = [temporaryErrorLog, ...integration.logs];
                    }
                }

                if (integration.logs.length === 0) {
                    this.$Alert.alert({
                        type: 'info',
                        message: 'This integration has no logs to display.',
                        timeout: 5000
                    });
                    this.viewLogsId = null;
                } else {
                    this.viewLogsId = integration.id;
                    integration.logs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                }
            }
        },
        getActionTextByStatus(integrationStatus) {
            let actionText;
            switch (integrationStatus.trim().toLowerCase()) {
                case 'new':
                    actionText = 'Waiting';
                    break;
                case 'invalidcsv':
                case 'invalidheaders':
                case 'invalidvalues':
                case 'failedintegration':
                    actionText = 'Re-upload File';
                    break;
                case 'validcsv':
                    actionText = 'Map Headers';
                    break;
                case 'validheaders':
                    actionText = 'Map Values';
                    break;
                case 'validvalues':
                    actionText = 'Integrate';
                    break;
                case 'integrated':
                    actionText = 'Done';
                    break;
                default:
                    actionText = 'Cancel';
            }
            return actionText;
        },
        disableActionButton(integration) {
            let disabled = false;

            switch (integration.integrationStatus.trim().toLowerCase()) {
                case 'new':
                case 'integrated':
                    disabled = true;
                    break;
            }

            // for now, auto integrations should not enable the button
            if (
                integration.integrationType
                    .trim()
                    .toLowerCase()
                    .startsWith('auto')
            ) {
                return true;
            }

            return disabled;
        },
        async createIntegration(event) {
            try {
                // get the New status id
                const newStatus = this.integrationStatusArray.find(integrationStatus => {
                    return integrationStatus.name.trim().toLowerCase() === 'new';
                });
                event.integrationStatusId = newStatus.id;

                // create the integration
                const integrationResponse = await IntegrationApi.createIntegration(event);

                // retrieve the new integration from the server
                const integrationId = integrationResponse.integration.id;
                const newIntegration = await IntegrationApi.getIntegrationById(integrationId);

		// use a feature flag to decide how we are going to handle
		// everything that happens after clicking integrate
		let statusToPollFor = null;
                const showPublicTags = await isDbSchoolFeatureFlagEnabled(
		    newIntegration[0].schoolId,
		    'SHOW_PUBLIC_TAGS'
		);
		// basically, if we don't show public tags, then keep polling
		// until the integration is complete
		if (!showPublicTags) {
                    statusToPollFor = 'integrated';
		}

                // display the new integration
                this.integrationCollection.list.unshift(newIntegration[0]);

                // upload the csv file
                const signedUrl = integrationResponse.signedUrl;
                await IntegrationApi.uploadToSignedUrl(signedUrl, event.integrationFile);

                this.integrationActionLoading.push(integrationId);
                this.pollForUpdates(integrationId, statusToPollFor);
            } catch (error) {
                console.error(error);
            }
        },
        async integrate(integrationId) {
            await TagApi.integrate(integrationId);
            await this.pollForUpdates(integrationId, 'integrated');
        },
        shouldPoll(integration) {
            // no polling if integration is older than 1 hour
            const rightNow = new Date();
            const oneHourAgo = rightNow.setHours(rightNow.getHours() - 1);
            if (new Date(integration.createdAt) < oneHourAgo) {
                return false;
            }
            return true;
        },
        async pollForUpdates(integrationId, statusToStopPolling, timeoutMs) {
            const maxTimeout = 900000; // ~15 minutes
            if (!timeoutMs) {
                timeoutMs = 100;
            }
            setTimeout(async () => {
                // get the integration
                const updatedIntegration = await IntegrationApi.getIntegrationById(integrationId);

                // setup the status related boolean condition that stops polling; doing
                // this to maintain backwards compatibility
                let stopPollingByStatus = false;
                if (statusToStopPolling && typeof statusToStopPolling === 'string') {
                    stopPollingByStatus =
                        updatedIntegration[0].integrationStatus.trim().toLowerCase() ===
                        statusToStopPolling;
                } else {
                    stopPollingByStatus =
                        updatedIntegration[0].integrationStatus.trim().toLowerCase() !== 'new';
                }

                if (
                    updatedIntegration &&
                    updatedIntegration[0] &&
                    updatedIntegration[0].integrationStatus &&
                    typeof updatedIntegration[0].integrationStatus === 'string' &&
                    stopPollingByStatus
                ) {
                    this.clearIntegrationActionLoading(integrationId);
                    const existingIndex = this.integrationCollection.list.findIndex(integration => {
                        return integration.id === updatedIntegration[0].id;
                    });

                    // update the value first, then replace the entire array with itself to trigger the Vue observer
                    this.integrationCollection.list[existingIndex] = updatedIntegration[0];
                    const logs = await IntegrationLogsApi.listLogsByIntegrationId(
                        updatedIntegration[0].id
                    );
                    this.integrationCollection.list[existingIndex].logs = logs;
                    this.integrationCollection.list[existingIndex].logs.sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    );
                    this.integrationCollection.list = [...this.integrationCollection.list];

                    // if the latest logs have warnings and the integration status is
                    // validcsv, display them in a modal
                    const validCsvStatus =
                        updatedIntegration[0].integrationStatus.trim().toLowerCase() === 'validcsv';
                    const detailString = logs[logs.length - 1].detail;
                    if (detailString.indexOf('WARNING') > -1 && validCsvStatus) {
                        const modalData = {
                            warnings: detailString,
                            integrationIndex: existingIndex
                        };
                        this.$modal.show('integration-warnings-modal', modalData);
                    }
                } else {
                    // we need to try again so recalculate the timeout
                    timeoutMs += timeoutMs;

                    // only retry if we haven't exceeded our max timeout
                    if (timeoutMs <= maxTimeout) {
                        this.pollForUpdates(integrationId, statusToStopPolling, timeoutMs);
                    }
                }
            }, timeoutMs);
        },
        setSelectedSchool(schoolId) {
            this.selectedSchool = schoolId;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.manage-uploads {
    margin: 2rem 3rem;
}

.school-filter-toolbar {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0rem;

    .filter-row {
        display: flex;
        align-items: center;

        * {
            margin: 0 0.5rem;
        }

        label {
            white-space: nowrap;
            font-size: 14px;
            color: black;
            font-weight: bold;
        }

        .school-filter {
            width: 30rem;
            height: 40px;
            display: flex;
            align-items: center;
        }
    }

    button {
        margin-left: auto;
    }
}

.integration-table-header {
    display: flex;
    padding: 10px 20px;
    background-color: $white-blue;
    justify-content: space-between;
    font-weight: bold;
}

.integration:hover {
    background-color: #f4f7f9;
}

.integration-header {
    flex: 1;
}

.integration-header-small {
    flex: 0.3;
}

.integration-header-medium {
    flex: 0.7;
}

.integration-details {
    display: flex;
    padding: 10px 20px;
}

.integration-detail-cell {
    flex: 1;
}

.integration-detail-cell-small {
    flex: 0.3;
}

.integration-detail-cell-medium {
    flex: 0.7;
}

.integration-log-cell {
    padding: 10px 80px;
    border-bottom: 1px solid #000;
}

.log-details {
    white-space: pre-wrap;
    overflow-wrap: break-word;
}
</style>
