import * as Api from '@/api/index';
import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { strictEqual } from 'assert';

export const listAdminAssignedStudentsByAdminId = async ({
    adminId,
    filters,
    errorHandlerOptions = {}
}) => {
    try {
        strictEqual(typeof adminId, 'string');
        strictEqual(typeof errorHandlerOptions, 'object');

        const url = Api.getv2ApiBase(`/admin-assigned-student/${adminId}`);
        const options = Api.getOptions();

        const params = {};

        if (typeof filters === 'object' && Array.isArray(filters.tagIds)) {
            params.tagIds = filters.tagIds.join(',');
        }

        const response = await axios.get(url, { ...options, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage:
                "There was a problem loading the admin's assigned students. Please try again.",
            ...errorHandlerOptions
        });
    }
};
