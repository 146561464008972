<template>
    <modal
        classes="v--modal"
        name="modal-tag-mapping"
        width="800"
        height="auto"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
        @opened="opened"
        :stack="false"
    >
        <div v-if="!loading" class="modal">
            <div
                class="tag-category"
                v-for="tagCategoryMapping in tagCategoryMappings"
                :key="tagCategoryMapping.id"
            >
                <TagCategoryMapper
                    :tagCategoryMapping="tagCategoryMapping"
                    :tagMappings="getTagMappings(tagCategoryMapping.id)"
                    :tagCategories="tagCategories.list"
                    @on-update-tag-category="updateTagCategoryMapping"
                    @on-update-tag="updateTagMapping"
                />
            </div>

            <div class="buttons">
                <Button styleType="off" @click="close">Close</Button>
                <LoadingSpinnerButton
                    class="base-button"
                    :loading="isSubmitting"
                    :text="'Submit'"
                    @click="submit"
                />
            </div>
        </div>
        <LoadingSpinner v-else />
    </modal>
</template>

<script>
const pLimit = require('p-limit');

import TagCategory from '@/services/tagCategories';
import Tag from '@/services/tags';
import TagCategoryMapping from '@/services/tagCategoryMappings';
import TagMapping from '@/services/tagMappings';
import CollectionManager from '@/services/collectionManager';

import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import Button from '@/components-deprecated/Button';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import TagCategoryMapper from '@/components-deprecated/staff/TagCategoryMapper';

export default {
    components: {
        Button,
        LoadingSpinner,
        TagCategoryMapper,
        LoadingSpinner,
        LoadingSpinnerButton
    },
    data() {
        return {
            loading: true,
            isSubmitting: false,
            school: null,
            tagMappings: [],
            tagCategoryMappings: [],
            tagCategories: CollectionManager.create({
                ModelClass: TagCategory
            }),
            onSubmit: () => {}
        };
    },
    methods: {
        async beforeOpen(event) {
            this.onSubmit = event.params.onSubmit;
            this.school = event.params.school;

            // Max number of tagMappingPromises that will run at a time
            const maxConcurrency = 3;
            const limit = pLimit(maxConcurrency);

            let tagMappingPromises = event.params.tagMappings.map(id =>
                limit(() => TagMapping.api.retrieve(id))
            );
            this.tagMappings = await Promise.all(tagMappingPromises);

            let tagMappingCategories = new Set();
            this.tagMappings.forEach(tagMapping =>
                tagMappingCategories.add(tagMapping.tagCategoryMapping)
            );

            let categoryPromises = Array.from(tagMappingCategories).map(
                async tagCategoryMappingId => {
                    return TagCategoryMapping.api.retrieve(tagCategoryMappingId);
                }
            );

            this.tagCategoryMappings = await Promise.all(categoryPromises);
        },

        async opened() {
            if (!this.school) {
                return;
            }

            this.loading = true;

            this.tagCategories.filters = { schools: this.school };
            await this.tagCategories.refresh();
            while (this.tagCategories.pagination.hasNextPage) {
                await this.tagCategories.addNextPage();
            }

            this.loading = false;
        },

        getTagMappings(tagCategoryMappingId) {
            return this.tagMappings.filter(
                tagMapping => tagMapping.tagCategoryMapping === tagCategoryMappingId
            );
        },

        updateTagCategoryMapping(tagCategoryMapping, tagCategory) {
            tagCategoryMapping.tagCategory = tagCategory;
        },

        updateTagMapping(tagMapping, tag) {
            tagMapping.tag = tag;
        },

        close() {
            this.$modal.hide('modal-tag-mapping');
        },

        async submit() {
            // Max number of tagPromises that will run at a time
            const maxConcurrency = 7;
            const limit = pLimit(maxConcurrency);

            this.isSubmitting = true;
            let tagPromises;
            let categoryPromises = this.tagCategoryMappings.map(async tagCategoryMapping => {
                // If we're mapping this to a custom TagCategory
                if (tagCategoryMapping.tagCategory === 'CUSTOM_NEW') {
                    let customTagCategories = (
                        await TagCategory.api.list({
                            name: tagCategoryMapping.rawName,
                            school: this.school
                        })
                    ).results;

                    var customTagCategory;

                    // If a tag category does not exist yet then create one
                    if (customTagCategories.length === 0) {
                        customTagCategory = (
                            await TagCategory.api.createTagCategory({
                                name: tagCategoryMapping.rawName,
                                school: this.school
                            })
                        ).data;
                    } else {
                        // If a tag category does exist then grab it to use for custom Tag creation
                        customTagCategory = customTagCategories[0];
                    }
                } else {
                    // Otherwise we want to stick with a TagCategoryMapping, so update it with the new info
                    tagCategoryMapping = await TagCategoryMapping.api.update(tagCategoryMapping);
                }

                let tagMappings = this.getTagMappings(tagCategoryMapping.id);
                tagPromises = tagMappings.map(async tagMapping => {
                    // Using limit here will prevent more than the specified number of promises running at once
                    return limit(async () => {
                        // Follows a similar flow as above. If custom then either create new custom Tag if needed
                        if (tagMapping.tag === 'CUSTOM_NEW') {
                            let customTags = (await Tag.api.list({ name: tagMapping.rawName }))
                                .results;
                            if (customTags.length === 0) {
                                // If the TagCategory already exists as a custom category but we're creating a new custom tag then we need to grab the custom TagCategory
                                // New Criteria: Only attempt to retrieve a tag category if the user specified they wanted to create a new tag category
                                if (
                                    !customTagCategory &&
                                    tagCategoryMapping.tagCategory === 'CUSTOM_NEW'
                                ) {
                                    customTagCategory = (
                                        await TagCategory.api.list({
                                            name: tagCategoryMapping.rawName,
                                            school: this.school
                                        })
                                    ).results[0];
                                }

                                // When the user wants to associate with an existing tag category but create a new tag we need to appropriately associate
                                // the correct tag category id before we create the new tag
                                let tagCategoryIdToAssociate;
                                if (customTagCategory && customTagCategory.id) {
                                    tagCategoryIdToAssociate = customTagCategory.id;
                                } else {
                                    tagCategoryIdToAssociate = tagCategoryMapping.tagCategory;
                                }

                                const newTag = await Tag.api.createTag({
                                    name: tagMapping.rawName,
                                    school: this.school,
                                    category: tagCategoryIdToAssociate
                                });

                                // Associate the tagMapping value to the new tag
                                tagMapping.tag = newTag.id;
                                tagMapping = await TagMapping.api.update(tagMapping);
                            }
                        } else {
                            tagMapping = await TagMapping.api.update(tagMapping);
                        }
                    });
                });
            });

            await Promise.all(categoryPromises);
            // This await was previously defined inside the categoryPromises. The
            // intention is to finish all tag category work AND all tag work. However,
            // awaiting inside another promise does not give us the desired affect.
            await Promise.all(tagPromises);

            const tagCategoryMappingDeletePromises = this.tagCategoryMappings.map(
                tagCategoryMapping => {
                    if (tagCategoryMapping.tagCategory === 'CUSTOM_NEW') {
                        return TagCategoryMapping.api.delete(tagCategoryMapping.id);
                    }
                }
            );
            await Promise.all(tagCategoryMappingDeletePromises);

            this.isSubmitting = false;
            this.close();
            this.onSubmit();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/views/modal';
@import '@/styles/forms';
@import '@/styles/variables';

.tag-category {
    margin: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid $secondary-gray;
}

.tag-category-name {
    flex: 3;
    font-size: 1.1rem;
    font-weight: bold;
}

.tag {
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dropdown {
    flex: 1;
    border-radius: 0;
    height: 100%;
    padding: 0.25rem 0.5rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.base-button {
    margin-left: 1rem;
}
</style>
