<template>
    <div class="container">
        <div class="toolbar">
            <div class="school-filter">
                <SchoolDropDown @selected-school-id-updated="setSelectedSchoolId" />
            </div>
        </div>
        <ChatbotTexts :selectedSchool="selectedSchoolId" />
    </div>
</template>

<script>
import ChatbotTexts from '@/views/staff/_ChatbotTexts';
import SchoolDropDown from '@/components-deprecated/inputs/SchoolDropDown';

export default {
    name: 'ChatbotTextsPage',
    components: {
        ChatbotTexts,
        SchoolDropDown
    },
    data() {
        return {
            selectedSchoolId: null
        };
    },
    methods: {
        setSelectedSchoolId(schoolId) {
            this.selectedSchoolId = schoolId;
        }
    }
};
</script>

<style lang="scss" scoped>
.school-filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    * {
        margin: 0 0.5rem;
    }
}
</style>
