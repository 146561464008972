<template>
    <div>
        <div class="row">
            <div class="risk-icon-container">
                <svg
                    v-if="riskCategory === 'wellness'"
                    class="risk-icon-belief"
                    :class="`icon-belief-${riskLevel}`"
                    role="img"
                >
                    <title>{{ `${riskCategory} driver` }}</title>
                    <use :xlink:href="icon" />
                </svg>
                <svg
                    v-if="riskCategory !== 'wellness'"
                    class="risk-icon"
                    :class="`icon-${riskLevel}`"
                    role="img"
                >
                    <title>{{ `${riskCategory} driver` }}</title>
                    <use :xlink:href="icon" />
                </svg>
            </div>
            <div class="risk-category-container">
                <p
                    class="risk-category"
                    v-tooltip="
                        !isExperimentalAccessibilityEnabled ? getRiskTooltip(riskCategory) : {}
                    "
                >
                    {{ stylizedRiskCategory }} Driver
                </p>

                <div class="risk-level-container">
                    <div class="risk-level" :class="getRiskLevelClass(riskLevel)">
                        {{ getStylizedRiskLevel(riskLevel) }}
                    </div>
                </div>
            </div>
            <div class="col risk-percentile-column">
                <p class="risk-percentile">{{ getStylizedRiskPercentile(riskPercentile) }}</p>
                <p class="risk-percentile-message">Higher than {{ riskPercentile }}% of peers</p>
            </div>
        </div>
        <button
            v-if="hasSubcategories"
            class="row subcategories-button"
            @click="showSubcategories = !showSubcategories"
            :aria-expanded="showSubcategories"
        >
            <svg
                width="10px"
                height="10px"
                :class="showSubcategories ? 'arrow-up' : 'arrow-down'"
                viewBox="0 0 5.9 17.51"
                aria-hidden="true"
            >
                <use xlink:href="#icon-arrow-right-wide" />
            </svg>
            {{ showSubcategories ? 'Hide Risk Subcategories' : 'Show Risk Subcategories' }}
        </button>
        <TransitionExpand v-if="showSubcategories">
            <ul class="subcategory-container" :id="`${riskCategory}-subcategory-container`">
                <li
                    v-for="riskSubcategory in riskSubcategories"
                    :key="riskSubcategory.id"
                    class="item"
                >
                    <div class="row subcategory">
                        <p
                            class="risk-category"
                            v-tooltip="
                                !isExperimentalAccessibilityEnabled
                                    ? getRiskTooltip(
                                          riskCategory,
                                          riskSubcategory.riskBucketRef.name
                                      )
                                    : {}
                            "
                        >
                            {{ riskSubcategory.riskBucketRef.name }}
                        </p>
                        <div class="risk-level-container">
                            <div
                                class="risk-level"
                                :class="getRiskLevelClass(riskSubcategory.riskLevel)"
                            >
                                {{ getStylizedRiskLevel(riskSubcategory.riskLevel) }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </TransitionExpand>
    </div>
</template>

<script>
import TransitionExpand from '@/components-deprecated/TransitionExpand';
import resources from '@/resources.json';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'StudentBlockInfoRiskDriver',

    components: {
        TransitionExpand
    },

    data() {
        return {
            showSubcategories: false,
            riskDriverDetails: []
        };
    },

    created() {
        // get risk driver details from resources json
        const gettingStarted = resources.find(r => r.title === 'Getting Started');
        const riskDriversSection = gettingStarted.subSections.find(
            s => s.subSection === 'Risk Drivers'
        );
        this.riskDriverDetails = riskDriversSection.topics;
    },
    props: {
        riskCategory: {
            type: String,
            required: true
        },
        riskLevel: {
            type: String,
            required: true
        },
        riskPercentile: {
            type: Number,
            required: true
        },
        riskSubcategories: {
            type: Array,
            required: true
        }
    },

    computed: {
        stylizedRiskCategory() {
            return this.stylizeWord(this.riskCategory);
        },
        icon() {
            switch (this.riskCategory) {
                case 'academic':
                    return '#icon-risk-academic';
                case 'engagement':
                    return '#icon-risk-engagement';
                case 'financial':
                    return '#icon-risk-financial';
                case 'wellness':
                    return '#icon-risk-belief';
                default:
                    return '';
            }
        },
        hasSubcategories() {
            return Array.isArray(this.riskSubcategories) && this.riskSubcategories.length > 0;
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    },

    methods: {
        getTooltip(risk, subcategory) {
            if (risk && subcategory) {
                const sub = risk.subtopics.find(s => s.title === subcategory);
                return (sub || {}).description;
            }
            return (risk || {}).description;
        },
        getRiskTooltip(riskDriver, subcategory) {
            // the risk drivers do not match up exactly with the titles stored in resources.json
            // so this function directly handles the translation. In the next iteration StudentBlockInfo
            // should pull the drivers directly from resources.json
            switch (riskDriver) {
                case 'academic': {
                    const academic = this.riskDriverDetails.find(
                        r => r.title === 'Academic Engagement'
                    );
                    return this.getTooltip(academic, subcategory);
                }
                case 'engagement': {
                    const engagement = this.riskDriverDetails.find(
                        r => r.title === 'Social Engagement'
                    );
                    return this.getTooltip(engagement, subcategory);
                }
                case 'financial': {
                    const financial = this.riskDriverDetails.find(
                        r => r.title === 'Financial Distress'
                    );
                    return this.getTooltip(financial, subcategory);
                }
                case 'wellness': {
                    const wellness = this.riskDriverDetails.find(r => r.title === 'Wellness');
                    return this.getTooltip(wellness, subcategory);
                }
                default:
                    return '';
            }
        },
        stylizeWord(word) {
            return word[0].toUpperCase() + word.substring(1).toLowerCase();
        },
        getStylizedRiskPercentile(riskPercentile) {
            let riskPercentileInteger = Number(riskPercentile).toFixed(0);
            let tenths = riskPercentileInteger % 10;
            let hundredths = riskPercentileInteger % 100;
            if (tenths == 1 && hundredths != 11) {
                return riskPercentileInteger + 'st';
            }
            if (tenths == 2 && hundredths != 12) {
                return riskPercentileInteger + 'nd';
            }
            if (tenths == 3 && hundredths != 13) {
                return riskPercentileInteger + 'rd';
            }
            return riskPercentileInteger + 'th';
        },
        getStylizedRiskLevel(riskLevel) {
            return riskLevel
                .split('_')
                .map(this.stylizeWord)
                .join(' ');
        },
        getRiskLevelClass(riskLevel) {
            switch (riskLevel) {
                case 'LOW_RISK':
                    return 'low-risk-level';
                case 'MEDIUM_RISK':
                    return 'medium-risk-level';
                case 'HIGH_RISK':
                    return 'high-risk-level';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.col {
    display: flex;
    flex-direction: column;
}

.item {
    margin-left: 40px;
}

.risk-category-container {
    display: flex;
    flex: 1;
}

.subcategory-container {
    li::marker {
        color: #808080;
        opacity: 0.75;
    }
}

.risk-category {
    font-weight: bold;
}

.risk-percentile-column {
    color: rgba(0, 0, 0, 0.6);
    flex: 0.75;
}

.risk-percentile {
    font-size: 1.2rem;
    font-weight: bold;
}

.risk-percentile-message {
    font-size: 0.85rem;
}

.risk-icon-container {
    display: flex;
    flex: 0.125;
}

.risk-icon {
    width: 30px;
    height: 30px;
}

.risk-icon-belief {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-left: 3px;
}

.icon-belief-HIGH_RISK {
    background-color: $color-high-risk;
}

.icon-belief-MEDIUM_RISK {
    background-color: $color-medium-risk;
}

.icon-belief-LOW_RISK {
    background-color: $color-low-risk;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-left: 3px;
}

.icon-HIGH_RISK {
    color: $color-high-risk;
}

.icon-MEDIUM_RISK {
    color: $color-medium-risk;
}

.icon-LOW_RISK {
    color: $color-low-risk;
}

.risk-level-container {
    display: flex;
    justify-content: flex-start;
    margin-left: 1rem;
}

.risk-level {
    padding: 0.1rem 1rem;
    border-radius: 5%;
}

.low-risk-level {
    color: #000;
    background-color: rgba(213, 213, 210, 0.1);
}

.medium-risk-level {
    color: rgb(70, 136, 253);
    background-color: rgba(70, 136, 253, 0.1);
}

.high-risk-level {
    color: rgb(108, 8, 253);
    background-color: rgba(108, 8, 253, 0.1);
}

.arrow {
    &-right {
        flex: 0.125;
    }
    &-up {
        transform: rotate(-90deg);
        margin-right: 0.75rem;
    }
    &-down {
        transform: rotate(90deg);
        margin-right: 0.75rem;
    }
}

.subcategories-button {
    all: unset;
    cursor: pointer;
    opacity: 0.75;
    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.subcategory {
    margin: 0.5rem 0;
}
</style>
