<template>
    <div class="chatbot-text-container" :class="{ highlight }">
        <div v-if="selectedTabName === 'Write' && !readOnly" class="actions">
            <EmojiPicker
                @onSelection="onEmojiSelected"
                :emojiPickerStyle="{ position: 'absolute', right: '40px' }"
            />
            <BindingsDropdown :bindings="bindings" @onSelect="onBindingSelected" />
        </div>
        <TabsContainer @tab-selected="onTabSelected">
            <Tab id="Write">
                <TextArea
                    :modelValue="modelValue"
                    @update:modelValue="value => $emit('update:modelValue', value)"
                    :placeholder="placeholder"
                    :disabled="readOnly"
                    :rows="rows"
                />
            </Tab>
            <Tab id="Preview">
                <div class="input-group input-option" v-if="resourceBindingTags && modelValue">
                    <ResourceTagsDropdown
                        :value="modelValue"
                        :resourceBindingTags="relatedTags"
                        :selectedTag="selectedTag"
                        @onTagSelected="onTagSelected"
                    />
                </div>
                <TextArea :modelValue="previewMessage" :rows="rows" disabled />
            </Tab>
        </TabsContainer>
    </div>
</template>

<script>
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import EmojiPicker from '@/components-deprecated/staff/EmojiPicker';
import BindingsDropdown from './BindingsDropdown';
import ResourceTagsDropdown from './ResourceTagsDropdown';
import TextArea from '@/components-deprecated/global/TextArea';

export default {
    name: 'ChatbotFlowTextArea',
    components: {
        TextArea,
        TabsContainer,
        Tab,
        EmojiPicker,
        BindingsDropdown,
        ResourceTagsDropdown
    },
    props: {
        bindings: {
            type: Object,
            required: true
        },
        resourceBindingTags: {
            type: Object
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        highlight: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        // value is auto-bound to v-model
        modelValue: {
            type: String,
            required: true
        },
        rows: {
            type: Number,
            default: 5
        }
    },
    data() {
        return {
            initialTabName: 'Write',
            selectedTabName: this.initialTabName,
            selectedTag: { id: 'default', name: 'Default' }
        };
    },
    methods: {
        onInput(value) {
            this.$emit('update:modelValue', value);
        },
        onEmojiSelected(value) {
            this.$emit('update:modelValue', this.modelValue + value);
        },
        onBindingSelected(value) {
            this.$emit('update:modelValue', this.modelValue + value);
        },
        onTabSelected(value) {
            this.selectedTabName = value;
        },
        onTagSelected(tag) {
            this.selectedTag = tag;
        },
        computeRelatedTags(bindings, modelValue) {
            // We filter tags so the dropdown only shows ones related to the used template bindings.
            // modelValue is what the user puts into the textbox.
            // Example of what bindings looks like:
            // {
            //   "{{resource.detail.academic.careerReadiness}}": {50002: 'This is the description for the Andrew tag'},
            //   "{{resource.detail.academic.courseEngagement}}": {50002: 'This is the description for the Andrew tag'},
            //   "{{resource.name.academic.careerReadiness}}": "Advisor",
            //   "{{resource.name.academic.courseEngagement}}": "Advisor"
            // }

            const regex = /{{resource\.detail.*?}}/g;

            // Extract all matching template drivers from the modelValue
            // Example: ['{{resource.detail.academic.careerReadiness}}', '{{resource.detail.academic.courseEngagement}}']
            const extractedResourceDrivers = modelValue.match(regex);

            // If no relevant drivers are found, return an empty object
            if (!extractedResourceDrivers || extractedResourceDrivers.length === 0) {
                return {};
            }
        
            // Obtain the relevant tagId-value pairs from the bindings.
            const tagIdKeyPairs = extractedResourceDrivers.reduce((accumulator, driver) => {
                const tagIdValuePairs = bindings[driver];
            
                if (tagIdValuePairs && typeof tagIdValuePairs === 'object') {
                    // Destructure to exclude the 'default' key
                    const { default: _, ...filteredTags } = tagIdValuePairs;

                    Object.keys(filteredTags).forEach((key) => {

                        if (this.resourceBindingTags[key]) {
                            // Map the tagId to its corresponding value(tag name) in resourceBindingTags
                            accumulator[key] = this.resourceBindingTags[key];
                        }
                    });
                }
            
                return accumulator;
            }, {});
        
            // The resulting tagIdKeyPairs will look like:
            // {
            //   50002: "Andrew"
            // }
            return tagIdKeyPairs;
        }
    },
    computed: {
        relatedTags() {
          return this.computeRelatedTags(this.bindings, this.modelValue);
        },
        bindingKeys() {
            if (typeof this.bindings === 'object') {
                return Object.keys(this.bindings);
            }

            return [];
        },
        previewMessage() {
            if (typeof this.modelValue === 'string') {
                let preview = this.modelValue;
                if (preview !== '') {
                    this.bindingKeys.forEach(bindingKey => {
                        if (this.bindings[bindingKey]) {
                            if (this.bindings[bindingKey]['default']) {
                                if (
                                    this.selectedTag.id !== 'default' &&
                                    this.bindings[bindingKey][this.selectedTag.id]
                                ) {
                                    preview = preview.replaceAll(
                                        bindingKey,
                                        this.bindings[bindingKey][this.selectedTag.id]
                                    );
                                } else {
                                    preview = preview.replaceAll(
                                        bindingKey,
                                        this.bindings[bindingKey]['default']
                                    );
                                }
                            } else {
                                preview = preview.replaceAll(bindingKey, this.bindings[bindingKey]);
                            }
                        }
                    });
                }

                return preview;
            }

            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.chatbot-text-container {
    position: relative;
    width: 100%;
    min-width: 25rem;
    background-color: #fafafa;
    border-radius: 1rem;
    border: $base-border-width solid #e5eeed;

    &.highlight {
        border: $base-border-width solid $edsights-blue;
    }

    padding: 0 12px 0 12px;

    :deep() {
        .tabs-container {
            .tabs {
                padding-bottom: 1rem;
            }

            .tab-content-container .textarea-container textarea:disabled {
                border-color: transparent;
                color: $base-font-color;
            }
        }
    }

    .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        top: 1rem;
        padding-right: 1rem;
    }

    .input-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left: 1rem;

        > :last-child {
            margin-left: 0;
        }
    }

    .input-option {
        margin: 0.3rem 0;
    }
}
</style>
