<template>
    <div class="scroll-container">
        <div class="container">
            <ButtonBack></ButtonBack>
            <h1 class="h2">Manage Students</h1>
            <br />
            <p>
                EdSights wants to make it easy for you to safely add, update, and remove students
                and student information. <br />Upload a spreadsheet of student information and
                EdSights will verify it and import it for you.
            </p>
            <List>
                <template v-slot:list-actions>
                    <SearchInput class="search" v-model="params.search" />
                    <button class="base-button" @click="onClickEdit">
                        Edit
                    </button>
                </template>
                <template v-slot:list-items>
                    <table>
                        <colgroup>
                            <col style="width:35%" />
                            <col style="width:15%" />
                            <col style="width:30%" />
                            <col style="width:20%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th class="column-header">File</th>
                                <th class="column-header">Status</th>
                                <th class="column-header">Upload Type</th>
                                <th class="column-header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="upload in uploads.list"
                                :key="upload.id"
                                class="student-upload-row"
                                @mouseover="onMouseOver(upload)"
                                @mouseleave="onMouseLeave(upload)"
                            >
                                <td class="file" @click="onClickFile(upload)">
                                    <button
                                        v-if="uploadIsImported(upload)"
                                        role="button"
                                        class="text-primary text-bold table-button"
                                    >
                                        {{ upload.title }}
                                    </button>
                                    <button
                                        v-else
                                        class="text-primary text-bold table-button"
                                        role="button"
                                    >
                                        {{ upload.title }}
                                    </button>
                                    <span class="small"
                                        >Uploaded
                                        {{ formatDateShort(upload.datetimeCreated) }}</span
                                    >
                                </td>
                                <td>{{ upload.statusRef }}</td>
                                <td>
                                    {{
                                        upload.uploadTypeRef.indexOf('Withdrew') > 0
                                            ? 'Remove Students - Did not persist'
                                            : upload.uploadTypeRef
                                    }}
                                </td>
                                <td>
                                    <LoadingSpinnerButton
                                        class="delete-btn"
                                        @click="onClickDelete(upload)"
                                        text="Delete"
                                        color="#808080"
                                        :loading="deleteLoading"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </List>
            <Paginator
                v-if="uploads.pagination.next != null"
                :loading="uploads.loadingNextPage"
                @next-page="uploads.addNextPage()"
            />
            <ModalCreateStudentUpload @closing="onCloseCreateUpload" />
            <ModalStudentUploadComplete @closing="uploads.refresh()" />
        </div>
    </div>
</template>

<script>
import { nextTick } from 'vue';

import { mapState } from 'vuex';
import Paginator from '@/components-deprecated/Paginator';
import SearchInput from '@/components-deprecated/inputs/SearchInput';
import List from '@/components-deprecated/tables/List';
import CollectionManager from '@/services/collectionManager';
import StudentUpload from '@/services/studentUploads';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import ModalCreateStudentUpload from '@/views/modals/ModalCreateStudentUpload';
import ModalStudentUploadComplete from '@/views/modals/ModalStudentUploadComplete';
import ButtonBack from '@/components-deprecated/ButtonBack';
import { formatDateShort } from '../services/utils';

export default {
    name: 'StudentUploadsAdmin',
    components: {
        ButtonBack,
        List,
        SearchInput,
        LoadingSpinnerButton,
        ModalCreateStudentUpload,
        ModalStudentUploadComplete,
        Paginator
    },
    data() {
        return {
            uploads: CollectionManager.create({
                ModelClass: StudentUpload
            }),
            params: {},
            deleteLoading: false
        };
    },
    async created() {
        if (this.staffSchoolViewEnabled) {
            this.params.schools = this.user.school;
            this.uploads.filters = this.params;
        }
        this.uploads.refresh();
    },
    methods: {
        formatDateShort,
        onMouseOver(upload) {
            upload.displayDetails = true;
        },
        onMouseLeave(upload) {
            upload.displayDetails = false;
        },
        onClickEdit() {
            this.$modal.show('modal-create-student-upload');

            nextTick(() => {
                const createStudentUploadModal = document.querySelector('.vm--container');
                if (createStudentUploadModal) {
                    createStudentUploadModal.focus();
                }
            });
        },
        /* Click the file title to download it. */
        async onClickFile(upload) {
            window.open(upload.file, '_blank');
        },
        async onClickDelete(upload) {
            let confirmation = confirm('Delete this student upload?');
            if (!confirmation) {
                return;
            }
            this.deleteLoading = true;
            try {
                await StudentUpload.api.delete(upload);
                this.uploads.refresh();
            } finally {
                this.deleteLoading = false;
            }
        },
        uploadIsImported(upload) {
            return upload.status == StudentUpload.options.IMPORTED;
        },
        /* Handle the "create upload" modal being closed */
        onCloseCreateUpload(upload) {
            // If an upload was created, open the completion modal and pass it in
            if (upload) {
                this.$modal.show('modal-student-upload-complete', { upload });
            }
        }
    },
    watch: {
        'params.search'() {
            this.uploads.filters = this.params;
            this.uploads.refresh();
        }
    },
    computed: {
        ...mapState(['user', 'staffSchoolViewEnabled'])
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/mixins/tables';
@import '@/styles/mixins/buttons';

.scroll-container {
    overflow: overlay;
}

table {
    @include table();
}

.base-button {
    @include base-button();
}

.search {
    margin-right: 15px;
}

.file {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin-top: 1rem;
}

.small {
    font-size: 0.8rem;
}

.delete-btn {
    @include secondary-button();
}

.column-header {
    font-weight: bold;
}

.student-upload-row {
    margin: 1rem;
}

.table-button {
    background: none;
    text-align: left;
    border: 0;
    cursor: pointer;
    &:focus {
        outline: 2px solid $edsights-blue;
        outline-offset: 2px;
    }
}

:deep(.table-header) {
    justify-content: flex-end;
}
</style>
