<template>
    <label class="checkbox" :class="{ 'checkbox--align-center': alignCenter }">
        <input
            ref="checkbox"
            class="checkbox__input"
            type="checkbox"
            :disabled="disabled"
            @input="input($event)"
            @click="$emit('click', $event)"
            :aria-describedby="label ? checkboxLabelId : undefined"
            :aria-label="label ? undefined : ariaLabel"
        />
        <span
            class="checkbox__checkmark"
            :class="{
                'checkbox__checkmark--circle': isCircle,
                'checkbox__checkmark--small': isSmall,
                'checkbox__checkmark--medium': isMedium
            }"
        ></span>
        <span class="checkbox__label" :id="checkboxLabelId">{{ label }}</span>
    </label>
</template>

<script>
import { v4 } from 'uuid';

export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        val: {
            default: ''
        },
        selectedItems: {
            type: Array,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        watch: {
            type: Boolean,
            default: true
        },
        alignCenter: {
            type: Boolean,
            default: false
        },
        checked: {
            type: Boolean,
            default: false
        },
        isCircle: {
            type: Boolean,
            default: false
        },
        watchChecked: {
            type: Boolean,
            default: false
        },
        isSmall: {
            type: Boolean,
            default: false
        },
        isMedium: {
            type: Boolean,
            default: false
        },
        ariaLabel: {
            type: String,
            default: 'Checkbox'
        }
    },
    data() {
        return {
            checkboxLabelId: `checkbox-label-${v4()}`
        };
    },
    watch: {
        checked(newProps, oldProps) {
            if (!this.watchChecked) return;
            this.$refs.checkbox.checked = this.checked;
        },
        selectedItems(selectedItems) {
            if (!this.watch || !this.selectedItems) {
                return;
            }
            if (
                ~selectedItems.findIndex(item => item.id === this.val.id) &&
                !this.$refs.checkbox.checked
            ) {
                this.$refs.checkbox.checked = true;
            }
            if (
                !~selectedItems.findIndex(item => item.id === this.val.id) &&
                this.$refs.checkbox.checked
            ) {
                this.$refs.checkbox.checked = false;
            }
        }
    },
    mounted() {
        if (this.selectedItems == null) {
            this.$refs.checkbox.checked = this.checked;
            return;
        }

        if (this.selectedItems.includes(this.val) && !this.$refs.checkbox.checked) {
            this.$refs.checkbox.checked = true;
        }
        if (!this.selectedItems.includes(this.val) && this.$refs.checkbox.checked) {
            this.$refs.checkbox.checked = false;
        }
    },
    methods: {
        input({ target }) {
            if (this.selectedItems == null) {
                this.$emit('update:modelValue', target.checked);
                return;
            }

            if (target.checked === true) {
                this.selectedItems.push(this.val);
            } else {
                const selectedItems = this.selectedItems.filter(item => item.id !== this.val.id);
                this.$emit('update:selectedItems', selectedItems);
            }
        },
        turnoff() {
            this.$emit('update:modelValue', false);
            this.$refs.checkbox.checked = false;
        },
        turnon() {
            this.$emit('update:modelValue', true);
            this.$refs.checkbox.checked = true;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixins/checkboxes';

.checkbox {
    @include checkbox();
    cursor: pointer;

    .checkbox__input {
        &:focus {
            + .checkbox__checkmark {
                outline: 2px solid $edsights-blue;
            }
        }
    }

    &--align-center {
        justify-content: center;
    }

    &__checkmark--circle {
        width: 33px;
        height: 33px;
        border-radius: 50%;
        margin-right: 0;
        background: #f4f8fa;
        border: 2px solid #e9e8e9;
    }

    &__checkmark--circle.checkbox__checkmark:after {
        display: block;
        left: 10px;
        top: 4px;
        width: 7px;
        height: 13px;
        border: solid #e9e8e9;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }

    &__input:checked ~ &__checkmark--circle.checkbox__checkmark {
        border: 2px solid $primary-brand-color;
        background-color: $primary-brand-color;
    }

    &__checkmark--small {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }

    &__checkmark--small.checkbox__checkmark:after {
        left: 4px;
        top: 1px;
        width: 4px;
        height: 8px;
        border-width: 0 2px 2px 0;
    }

    &__checkmark--medium {
        width: 16px;
        height: 16px;
        margin-right: 0;
    }

    &__checkmark--medium.checkbox__checkmark:after {
        left: 5px;
        top: 1px;
        width: 4px;
        height: 8px;
        border-width: 0 2px 2px 0;
    }
}
</style>