import axios from 'axios';

import store from '@/store';
import CSRF from '@/services/csrf';
import { objectToCamelCase } from './utils';

import { API_BASE, apiErrorHandler } from './api';

// Auth and current user patching endponts
export const LOGIN_ENDPOINT = `${API_BASE}login/`;
export const PASSWORD_CHANGE_ENDPOINT = `${API_BASE}password/change/`;
export const PASSWORD_RESET_ENDPOINT = `${API_BASE}password/reset/`;
export const PASSWORD_RESET_CONFIRM_ENDPOINT = `${API_BASE}password/reset/confirm/`;
export const MAGIC_LINK_ENDPOINT = `${API_BASE}admins/magic-link/`;

export default {
    magicLinkAuth,
    getHeaders,
    login,
    logout,
    isLoggedIn,
    changePassword,
    passwordResetConfirmRequest,
    passwordResetRequest,
    requireAdminauth,
    requireUnAuth,
    requireStaffAuth
};

/**
 * @function     magicLinkAuth
 * @description  Authenticate a user with a magic token.
 * @param        userId <uuid>
 * @param        magicToken <uuid>
 **/
function magicLinkAuth(userId, magicToken) {
    const data = {
        user_id: userId,
        magic_token: magicToken
    };
    const promise = axios.post(MAGIC_LINK_ENDPOINT, data, { headers: CSRF.getHeaders() });
    return promise.then(response => response.data).catch(apiErrorHandler('Registration API error'));
}

/**
 * @function    login
 * @description Submit authentication credentials to retrieve an auth token.
 */
function login(email, password) {
    const promise = axios.post(LOGIN_ENDPOINT, { email, password }, { headers: CSRF.getHeaders() });
    return promise.then(response => response.data).catch(apiErrorHandler('Login API error'));
}

/**
 * @function    passwordResetRequest
 * @description Submit user's email to request a reset link.
 * @param       email <String> - user's email.
 */
function passwordResetRequest(email) {
    const promise = axios.post(PASSWORD_RESET_ENDPOINT, { email });
    return promise
        .then(response => response.data)
        .catch(apiErrorHandler('Reset password API error'));
}

/**
 * @function    passwordResetConfirmRequest
 * @description Submit a new user's password.
 * @param       password <String> - a new user's password;
 * @param       confirmPassword <String> - confirm a new user's password;
 * @param       uid <String> - parameter from sent to email link;
 * @param       token <String> - parameter from sent to email link.
 */
function passwordResetConfirmRequest(password, confirmPassword, uid, token) {
    const promise = axios.post(PASSWORD_RESET_CONFIRM_ENDPOINT, {
        new_password1: password,
        new_password2: confirmPassword,
        uid,
        token
    });
    return promise
        .then(response => response.data)
        .catch(apiErrorHandler('Confirm password reset API error'));
}

/**
 * @function    isLoggedIn
 * @description Determine authentication status. The client is logged in
 *              if they have a valid token. This may require an API request
 *              to validate the token.
 */
function isLoggedIn() {
    return !!store.state.userToken;
}

/**
 * @function    returnToLogin
 * @description Navigates to login page from vue-router beforeEnter-style functions.
 * @param to {Object} Vue-router "to" object that was being navigated to before calling this function
 * @param to.fullPath {string} Login page will redirect here after successful login
 * @param next {function} Vue-router "next" function from this function's invoking context
 */
function returnToLogin(to, next) {
    next({
        path: '/login',
        query: {
            redirect: to.fullPath
        }
    });
}

function requireUnAuth(to, from, next) {
    if (isLoggedIn()) {
        next({
            path: '/'
        });
    } else {
        next();
    }
}

/**
 * @function    requireAdminauth
 * @description vue-router beforeEnter-style function to check if the user is an admin, or a staff user in school view mode
 *              and redirect appropriately.
 */
function requireAdminauth(to, from, next) {
    const { user, staffSchoolViewEnabled } = store.state;
    if (!isLoggedIn()) {
        returnToLogin(to, next);
    } else if (user.isStaff && user.isAdmin && !staffSchoolViewEnabled) {
        // user is staff and admin, but has not enabled staff school view
        // redirect staff user to staff home page
        next({
            name: 'StaffHome'
        });
    } else if (user.isStaff && user.isAdmin && staffSchoolViewEnabled) {
        // user is staff and admin, and has staff school view enabled
        // allow to proceed
        next();
    } else if (!user.isStaff && user.isAdmin) {
        // user is a regular admin (non staff)
        // allow to proceed
        next();
    } else {
        returnToLogin(to, next);
    }
}

/**
 * Require user is a staff member not in school view mode before accessing route, redirects appropriately otherwise.
 */
function requireStaffAuth(to, from, next) {
    const { user, staffSchoolViewEnabled } = store.state;
    if (!isLoggedIn()) {
        returnToLogin(to, next);
    } else if (user.isStaff && !staffSchoolViewEnabled) {
        next();
    } else {
        next({
            name: 'Home'
        });
    }
}

/**
 * @function    getHeaders
 * @description Get the authentication headers, based on the token stored
 *              in state.
 */
function getHeaders() {
    return {
        headers: {
            Authorization: `Token ${store.state.userToken}`
        }
    };
}

/**
 * @function logout
 * @description Perform logout by clearing the Vuex store.
 *
 * TODO: Logout should also call an API endpoint to terminate the Django session.
 */
function logout() {
    store.commit('DO_LOGOUT');
}

function changePassword({ oldPassword, newPassword1, newPassword2 }) {
    const promise = axios.post(
        PASSWORD_CHANGE_ENDPOINT,
        {
            new_password1: newPassword1,
            new_password2: newPassword2,
            old_password: oldPassword
        },
        { ...getHeaders() }
    );
    return promise
        .then(response => objectToCamelCase(response.data))
        .catch(apiErrorHandler({ apiName: 'Password Change error' }));
}
