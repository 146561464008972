import { objectToCamelCase, objectToSnakeCase } from '../utils';
import api from './api';

export default class ChatbotTemplate {
    static api = api.create(ChatbotTemplate);
    constructor(chatbotTemplate) {
        // eslint-disable-next-line no-param-reassign
        chatbotTemplate = chatbotTemplate || {};
        const {
            id = null,
            name = '',
            firstQuestion = '',
            reusable = null,
            createReport = false,
            touchpoint = null,
            questionsRef = null
        } = chatbotTemplate;
        Object.assign(this, {
            id,
            name,
            firstQuestion,
            reusable,
            createReport,
            touchpoint,
            questionsRef
        });
    }

    static create(opts) {
        return new ChatbotTemplate(opts);
    }

    static fromAPI(json) {
        return new ChatbotTemplate(objectToCamelCase(json));
    }

    static toAPI(json) {
        const data = objectToSnakeCase(json);
        return data;
    }
}
