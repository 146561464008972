<template>
    <div>
        <div class="item-wrapper">
            <div v-if="iconName">
                <svg
                    class="icon"
                    style="width: 30px; height: 30px; color: #4688fd; margin-right: 2rem"
                >
                    <use :xlink:href="`#${iconName}`" />
                </svg>
            </div>
            <div class="item">
                <header class="item__header">
                    <h3>{{ title }}</h3>
                </header>
                <div
                    class="item__content"
                    :class="{ item__content_block: buttonPosition === 'bottom' }"
                >
                    <div v-if="description" class="item__description" v-html="description" />
                    <div v-if="imageName" class="item__image">
                        <img :src="require('@/assets/resources/' + imageName)" alt="" />
                    </div>
                    <!-- <Button v-if="description && copyButton" class="item__button" @click="onClickCopy" :link="buttonLink"
                        target="_blank">{{ buttonText }}
                    </Button> -->
                </div>
                <div v-if="fullScreenImage" class="item__fullscreen-image">
                    <img :src="require('@/assets/resources/' + fullScreenImage)" alt="" />
                </div>
            </div>
        </div>
        <div class="subtopic-wrapper" v-if="subtopics && subtopics.length > 0">
            <button tabIndex="0" class="clickable" @click="showSubtopics = !showSubtopics">
                <svg
                    width="10px"
                    height="10px"
                    viewBox="0 0 5.9 17.51"
                    :class="{ arrow: showSubtopics, 'arrow-reversed': !showSubtopics }"
                >
                    <use xlink:href="#icon-arrow-right-wide" />
                </svg>
                {{ subtopicMessage }}
            </button>
            <TransitionExpand v-if="showSubtopics">
                <div>
                    <div class="subtopic" v-for="subtopic in subtopics" :key="subtopic.title">
                        <p class="subtopic-title">> {{ subtopic.title }}</p>
                        <p class="subtopic-description">{{ subtopic.description }}</p>
                    </div>
                </div>
            </TransitionExpand>
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
import TransitionExpand from '@/components-deprecated/TransitionExpand';

export default {
    name: 'ResourceDetailItem',

    components: {
        Button,
        TransitionExpand
    },

    data() {
        return {
            showSubtopics: false
        };
    },

    props: {
        title: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        subtopics: {
            type: Array,
            default: () => []
        },
        copyButton: {
            type: Boolean,
            default: true
        },
        // buttonText: {
        //     type: String,
        //     default: 'Copy'
        // },
        buttonLink: {
            type: String,
            default: null
        },
        iconName: {
            type: String,
            default: null
        },
        imageName: {
            type: String,
            default: null
        },
        fullScreenImage: {
            type: String,
            default: null
        },
        buttonPosition: {
            type: String,
            default: null
        }
    },

    computed: {
        subtopicMessage() {
            if (this.showSubtopics) {
                return `HIDE ${this.subtopics.length} SUBCATEGORIES`;
            } else {
                return `SHOW ${this.subtopics.length} SUBCATEGORIES`;
            }
        }
    },

    methods: {
        onClickCopy() {
            this.$emit('copy');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.item-wrapper {
    display: flex;
    flex-direction: row;
    padding: 15px 15px;

    &:last-child {
        .item {
            border-bottom: 0;
        }
    }
}

.item {
    border-bottom: 1px solid $border-color;

    &__header {
        margin-bottom: 0;
    }

    &__content {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__content_block {
        display: block;

        .item__button {
            margin: 10px 0 0 0;
        }
    }

    &__description {
        width: 100%;
        margin: 10px 10px 5px 0;
        text-align: justify;
    }

    &__image {
        width: 50%;
        align-self: center;

        img {
            max-width: 100%;
        }
    }

    &__fullscreen-image {
        width: 100%;
        text-align: right;
        margin-top: 2rem;

        img {
            width: 50%;
        }
    }

    &__button {
        margin-top: 20px;
        margin-left: 2rem;
        width: 115px;
    }

    &__link {
        margin-top: 15px;
    }

    &_hidden-border {
        border-bottom: 0;
    }
}

// Needs v-deep since we're using v-html to populate the HTML via JSON
:deep(.resource-list) {
    li {
        margin: 0.75rem 0;

        > .resource-list {
            margin-left: 1.5rem;
        }
    }
}

:deep(.blank) {
    list-style-type: none;
}

:deep(.numbered) {
    list-style-type: none;
    counter-reset: item;

    > li:before {
        content: counter(item) '. ';
        counter-increment: item;
        font-weight: bold;
    }
}

.icon {
    background-color: $color-medium-risk;
    border-radius: 50%;
}

.subtopic-wrapper {
    margin-left: 3rem;
    margin-bottom: 2rem;
    padding: 0 0.5rem 1rem 0.5rem;
    border-bottom: 1px solid $border-color;
}

.subtopic {
    margin: 1.5rem;

    &-title {
        font-weight: bold;
        font-size: 1.1rem;
    }

    &-description {
        margin: 0.5rem 1rem;
    }
}

.clickable {
    cursor: pointer;
    background: none;
    border: 0;
    padding: 8px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.arrow {
    margin-right: 1rem;
    transform: rotate(-90deg);

    &-reversed {
        margin-right: 1rem;
        transform: rotate(90deg);
    }
}
</style>
