<template>
    <modal
        classes="v--modal"
        name="modal-assign-students"
        height="auto"
        width="600"
        :scrollable="true"
        :clickToClose="true"
        :stack="false"
        @before-open="beforeOpen"
        tabindex="-1"
    >
        <LoadingSpinner class="spinner" v-if="loading" />
        <div v-else class="container assign-students">
            <CopyPasteEmails
                ref="copyPasteStudents"
                class="box-no-padding copypaste-emails"
                :uploading="uploading"
                :title="subtitle"
                :modal="true"
                @cancel="onCancel"
                @save="onSaveList"
            />
        </div>
    </modal>
</template>

<script>
import * as EmailValidator from 'email-validator';

import Student from '@/services/students';
import Admin from '@/services/admins';
import Tag from '@/services/tags';
import BackgroundOperation from '@/services/backgroundOperations';
import BoxContainer from '@/components-deprecated/BoxContainer';
import CopyPasteEmails from '@/components-deprecated/CopyPasteEmails';
import Button from '@/components-deprecated/Button';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import { mapActions } from 'vuex';
export default {
    name: 'ModalAssignStudents',
    components: {
        BoxContainer,
        CopyPasteEmails,
        Button,
        LoadingSpinner
    },
    data() {
        return {
            uploading: false,
            loading: false,
            tag: null,
            admin: null
        };
    },
    computed: {
        subtitle() {
            if (this.tag) {
                return `Assign students for tag "${this.tag.name}"`;
            } else if (this.admin) {
                return `Assign students to ${this.admin.displayName}`;
            } else {
                return '';
            }
        }
    },
    methods: {
        ...mapActions(['updateBackgroundOperation']),
        beforeOpen(event) {
            this.init(event);
        },
        async init(event) {
            try {
                this.loading = true;
                this.refreshPage = event.params.refreshPage;

                // If we're assigning students to an admin:
                if (event.params.admin) {
                    this.admin = await Admin.api.retrieve(event.params.admin);

                    // If we're assigning students to a tag:
                } else if (event.params.tag) {
                    this.tag = event.params.tag;
                } else {
                    this.$router.replace({ name: 'Error404' });
                }
            } catch {
                this.$router.replace({ name: 'Error404' });
            } finally {
                this.loading = false;
            }
        },
        onCancel() {
            this.$modal.hide('modal-assign-students');
        },
        async onSaveList(emails) {
            if (!this.validateEmails(emails)) {
                return;
            }
            const response = await this.uploadStudents(emails);

            // If the response we received is a background operation, add it to global state
            if (response) {
                if (response.isBackground) {
                    this.updateBackgroundOperation(response.backgroundOp);
                    // If not, emit results so parent component can show them
                } else {
                    this.$emit('results', response.results);
                }
            }
            this.$modal.hide('modal-assign-students');
        },
        validateEmails(emails) {
            const incorrectEmails = emails.filter(email => !EmailValidator.validate(email));

            if (incorrectEmails.length !== 0) {
                this.$Alert.alert({
                    type: 'error',
                    message:
                        '<h5>Invalid emails.</h5><h5>Please, correct these and try again:</h5>' +
                        incorrectEmails.map(email => `<p>${email}</p>`).join(''),
                    timeout: 15000
                });
                return false;
            }

            return true;
        },
        async uploadStudents(emails) {
            this.uploading = true;

            let response;
            try {
                if (this.tag) {
                    response = await this.assignStudentsToTag(emails);
                } else {
                    response = await this.assignStudentsToAdmin(emails);
                }
            } finally {
                this.uploading = false;
            }
            return response;
        },
        async assignStudentsToAdmin(emails) {
            // Add emails of all existing students, so we don't remove them when we assign new students.
            const allEmails = emails.concat(this.admin.adminAccount.assignedStudents);

            const response = await Student.api.assignStudentsToAdmin(this.admin.id, allEmails);
            await this.refreshPage();
            return response;
        },
        async assignStudentsToTag(emails) {
            const response = await Tag.api.addUsersByEmail(this.tag.id, emails);
            await this.refreshPage();
            return response;
        },
        resetInputComponents() {
            if (this.$refs.copyPasteStudents != null) {
                this.$refs.copyPasteStudents.reset();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.assign-students {
    display: flex;
    justify-content: space-evenly;
    padding-top: 50px;

    &__content {
        padding: 25px 45px;
    }

    &__btns {
        margin-top: 20px;
    }

    &__btn {
        width: 175px;
        margin-right: 15px;
    }
}
.spinner {
    // Add some padding so the modal doesn't look stupid if there's just a spinner
    padding: 3rem 0;
}
.copypaste-emails {
    width: 90%;
    min-height: calc(100vh - 300px);
}
</style>
