<template>
    <modal
        classes="v--modal"
        name="modal-settings"
        height="auto"
        width="80%"
        :maxWidth="960"
        :minWidth="320"
        :scrollable="true"
        :clickToClose="false"
        :stack="false"
    >
        <div class="settings" id="settingsBody">
            <div class="settings__header">
                <span class="settings__header-title">Settings</span>
                <button class="button" type="button" @click="onDone">DONE</button>
            </div>

            <div class="settings__body">
                <div class="section">
                    <span class="section__label">Enrolled students</span>
                    <div class="section__content">
                        <div class="annotated-link">
                            <a class="annotated-link__link" @click="onEditStudents" href="#">
                                View/edit students
                            </a>
                            <span class="annotated-link__description">
                                If you would like to add or remove a student, you can do so here.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="section">
                    <span class="section__label">Co-Admins</span>
                    <div class="section__content">
                        <div class="annotated-link">
                            <a class="annotated-link__link" @click="onEditCoAdmins" href="#">
                                View/edit co-admins
                            </a>
                            <span class="annotated-link__description">
                                If you would like to add or remove a co-admin, you can do so here.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="section">
                    <span class="section__label">Change Password</span>

                    <div class="section__content">
                        <form class="password-form" @submit.prevent="onSubmitPasswordChangeForm">
                            <div class="field-group">
                                <label>New Password</label>
                                <input type="password" v-model="passwordForm.newPassword1" />
                            </div>

                            <div class="field-group">
                                <label>Confirm New Password</label>
                                <input type="password" v-model="passwordForm.newPassword2" />
                            </div>

                            <loading-spinner-button
                                type="submit"
                                class="button"
                                :text="'Change Password'"
                                :loading="passwordForm.saving"
                            ></loading-spinner-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import auth from '@/services/auth';

import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';

export default {
    name: 'ModalSettings',
    components: {
        LoadingSpinnerButton
    },
    data() {
        return {
            passwordForm: {
                newPassword1: '',
                newPassword2: '',
                saving: false
            }
        };
    },
    methods: {
        closeModal() {
            this.$modal.hide('modal-settings');
        },
        onDone() {
            this.closeModal();
        },
        onEditCoAdmins() {
            if (this.$router.currentRoute.name !== 'ManageCoAdmins') {
                const { href } = this.$router.resolve({ name: 'ManageCoAdmins' });
                window.open(href, '_blank');
            }
        },
        onEditStudents() {
            if (this.$router.currentRoute.name !== 'ManageStudents') {
                const { href } = this.$router.resolve({ name: 'ManageStudents' });
                window.open(href, '_blank');
            }
        },
        onSubmitPasswordChangeForm() {
            this.passwordForm.saving = true;
            auth.changePassword(this.passwordForm)
                .then(data => {
                    this.$Alert.alert({
                        type: 'success',
                        message: '<h2>Password changed!</h2>',
                        timeout: 7000
                    });
                })
                .finally(() => {
                    this.passwordForm.saving = false;
                });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
@import '../../styles/forms';
@import '../../styles/mixins/buttons';

$break-point: 768px;

.button {
    @include base-button();
}

// Settings modal layout styles
.v--modal-overlay[data-modal='modal-settings'] {
    background-color: $black-alpha-0-5;

    & :deep(.v--modal-box) {
        margin-top: $nav-panel-height;
    }

    & :deep(.v--modal) {
        border-radius: 0;
    }
}

.settings {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__header {
        display: flex;
        background-color: $light-gray;
        padding: 1rem 2rem;
        align-items: center;
        justify-content: space-between;
    }

    &__header-title {
        font-size: 1.7rem;
    }

    &__body {
        // Intentionally Blank
    }
}

// Component style for a large link with small
// descriptive text beneath it.
.annotated-link {
    &__link {
        display: inline-block;
        cursor: pointer;
        font-size: $large-font-size;
    }

    &__description {
        display: block;
        color: $accessible-gray;
        font-size: $small-font-size;
    }
}

// Component style for a sectioned interface with
// large label on the left and content on the right.
.section {
    display: flex;
    flex-direction: row;
    padding: 2rem 2rem;
    border-bottom: 1px solid $mystic;
    align-items: flex-start;
    transition: all 1s linear;

    @media screen and (max-width: $break-point) {
        flex-direction: column;
    }

    &__label {
        flex: 1;
        font-size: $large-font-size;
    }

    &__content {
        flex: 2;
        flex-direction: column;
        justify-content: space-around;
    }
}

.password-form {
    @extend %form;
    max-width: 25rem;
}
</style>
