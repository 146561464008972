import * as userActions from './user';
import * as loadinActions from './loading';
import * as backgroundOperationActions from './backgroundOperations';
import * as staffSchoolViewActions from './staffSchoolView';
import * as pendingAlertCountActions from './pendingAlertCount';

export default {
    ...userActions,
    ...loadinActions,
    ...backgroundOperationActions,
    ...staffSchoolViewActions,
    ...pendingAlertCountActions
};
