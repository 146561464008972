<template>
    <div class="container">
        <LoadingSpinner v-if="loading" />
        <div v-else>
            <ButtonBack
                previousPageDisplayName="Manage Admins"
                previousPageRouteName="ManageCoAdmins"
            ></ButtonBack>
            <ScrollWrapper class="box">
                <List :title="title" :subtitle="subtitle" :loading="loading">
                    <template v-slot:selected-items-count>
                        <p>{{ numTagsSelected }}</p>
                    </template>
                    <template v-slot:list-actions>
                        <Button class="add-new-button" @click="openAddAssignedTagModal">
                            Add New
                        </Button>
                        <Button styleType="off" @click="openRemoveAssignedTagModal">
                            Remove
                        </Button>
                    </template>
                    <template v-slot:list-header>
                        <ManageAdminTagsListHeader
                            :selected="allTagsSelected"
                            @select="toggleAllTags(true)"
                            @unselect="toggleAllTags(false)"
                        />
                    </template>
                    <template v-slot:list-items>
                        <ManageAdminTagsListItem
                            v-for="tag in assignedTags"
                            @select="selectTag"
                            @unselect="unselectAdmin"
                            :tag="tag"
                            :key="tag.id"
                            :selected="!!selectedAssignedTagsMappedById[tag.id]"
                            @view-students="navigateToManageStudents(tag.tagId)"
                        />
                    </template>
                </List>
            </ScrollWrapper>
        </div>
        <AddAssignedTagModal
            v-if="addAssignedTagModalData.show"
            @close-add-new-tag-modal="addAssignedTagModalData.show = false"
            @save-add-new-tag-modal="saveTags"
            :adminDisplayName="title"
            :adminId="adminId"
            :saving="addAssignedTagModalData.saving"
        />
        <RemoveAssignedTagModal
            v-if="removeAssignedTagModalData.show"
            @close="removeAssignedTagModalData.show = false"
            @save="removeSelectedTags"
            :adminDisplayName="title"
            :saving="removeAssignedTagModalData.saving"
        />
    </div>
</template>

<script>
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/Button';
import ButtonBack from '@/components-deprecated/ButtonBack';
import ScrollWrapper from '@/components-deprecated/ScrollWrapper';
import List from '@/components-deprecated/tables/List';
import Admin from '@/services/admins';
import ManageAdminTagsListHeader from '@/views/AdminSettings/ManageAdminTags/components/ManageAdminTagsListHeader';
import ManageAdminTagsListItem from '@/views/AdminSettings/ManageAdminTags/components/ManageAdminTagsListItem';
import {
    createAdminAssignedTag,
    deleteAdminAssignedTagById,
    listAdminAssignedTagsByAdminId
} from '@/api/admin-assigned-tags';
import AddAssignedTagModal from '@/views/AdminSettings/ManageAdminTags/components/AddAssignedTagModal';
import RemoveAssignedTagModal from '@/views/AdminSettings/ManageAdminTags/components/RemoveAssignedTagModal';
import { keyBy } from 'lodash';
import { strictEqual } from 'assert';

export default {
    name: 'ManageAdminTags',
    components: {
        RemoveAssignedTagModal,
        AddAssignedTagModal,
        ManageAdminTagsListItem,
        ManageAdminTagsListHeader,
        ScrollWrapper,
        LoadingSpinner,
        ButtonBack,
        List,
        Button
    },
    data() {
        return {
            loading: true,
            title: '',
            subtitle: '',
            adminId: null,
            assignedTags: [],
            selectedAssignedTags: [],
            allTagsSelected: false,
            addAssignedTagModalData: {
                show: false,
                saving: false
            },
            removeAssignedTagModalData: {
                show: false,
                saving: false
            }
        };
    },
    async created() {
        if (this.$route.params.adminId) {
            this.adminId = this.$route.params.adminId;

            await Promise.all([
                this.loadAdmin(this.$route.params.adminId),
                this.loadAssignedTags(this.$route.params.adminId)
            ]);

            this.loading = false;
        }
    },
    methods: {
        async loadAdmin(adminId) {
            const admin = await Admin.api.retrieve(adminId);
            if (typeof admin === 'object' && typeof admin.displayName === 'string') {
                this.title = admin.displayName;
                this.subtitle = `${admin.displayName} has access to the information for students linked in the following tags. Edit the permissions by adding or removing tags.`;
            }
        },
        async loadAssignedTags(adminId, errorHandlerOptions = {}) {
            const assignedTags = await listAdminAssignedTagsByAdminId({
                adminId,
                errorHandlerOptions
            });

            if (Array.isArray(assignedTags)) {
                this.assignedTags = assignedTags.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                this.selectedAssignedTags = [];
            }
        },
        openAddAssignedTagModal() {
            this.addAssignedTagModalData.show = true;
        },
        openRemoveAssignedTagModal() {
            if (this.selectedAssignedTags.length > 0) {
                this.removeAssignedTagModalData.show = true;
            }
        },
        async removeSelectedTags() {
            try {
                this.removeAssignedTagModalData.saving = true;
                const selectedAssignedTagIds = this.selectedAssignedTags.map(tag => tag.id);
                await Promise.all(
                    selectedAssignedTagIds.map(id =>
                        deleteAdminAssignedTagById({
                            id: id,
                            errorHandlerOptions: {
                                rethrow: true,
                                enableAlert: false
                            }
                        })
                    )
                );

                await this.loadAssignedTags(this.adminId, { rethrow: true, enableAlert: false });
                this.$Alert.alert({
                    type: 'success',
                    message: 'Tag(s) removed from admin.',
                    timeout: 5000
                });

                this.removeAssignedTagModalData.show = false;
                this.removeAssignedTagModalData.saving = false;
            } catch (error) {
                this.removeAssignedTagModalData.saving = false;
                this.$Alert.alert({
                    type: 'error',
                    message:
                        "There was a problem removing the admin's assigned tag(s). Please try again.",
                    timeout: 5000
                });
            }
        },
        async saveTags(tagIds) {
            try {
                strictEqual(Array.isArray(tagIds), true);
                this.addAssignedTagModalData.saving = true;
                await Promise.all(
                    tagIds.map(id =>
                        createAdminAssignedTag({
                            adminId: this.adminId,
                            tagId: id,
                            errorHandlerOptions: {
                                rethrow: true,
                                enableAlert: false
                            }
                        })
                    )
                );

                await this.loadAssignedTags(this.adminId);
                this.$Alert.alert({
                    type: 'success',
                    message: 'Tag(s) linked to admin.',
                    timeout: 5000
                });

                this.addAssignedTagModalData.show = false;
                this.addAssignedTagModalData.saving = false;
            } catch (error) {
                this.removeAssignedTagModalData.saving = false;
                this.$Alert.alert({
                    type: 'error',
                    message:
                        'There was a problem assigning the tag(s) to the admin. Please try again.',
                    timeout: 5000
                });
            }
        },
        toggleAllTags(val) {
            this.allTagsSelected = val;
            if (this.allTagsSelected) {
                this.selectedAssignedTags = this.assignedTags;
            } else {
                this.selectedAssignedTags = [];
            }
        },
        selectTag(tag) {
            this.selectedAssignedTags.push(tag);
        },
        unselectAdmin(tag) {
            this.selectedAssignedTags = this.selectedAssignedTags.filter(
                item => item.id !== tag.id
            );
        },
        navigateToManageStudents(tagId) {
            this.$router.push({
                name: 'ManageAdminStudents',
                params: { adminId: this.adminId, tagId }
            });
        }
    },
    computed: {
        numTagsSelected() {
            const numSelected = this.selectedAssignedTagsLength;
            if (numSelected) {
                return `${numSelected} tag${numSelected !== 1 ? 's' : ''} selected`;
            } else {
                return '';
            }
        },
        selectedAssignedTagsLength() {
            return this.selectedAssignedTags.length;
        },
        selectedAssignedTagsMappedById() {
            return keyBy(this.selectedAssignedTags, 'id');
        }
    },
    watch: {
        selectedAssignedTagsLength(val) {
            // watcher that checks or unchecks the select all checkbox
            if (val === this.assignedTags.length && this.assignedTags.length > 0) {
                this.allTagsSelected = true;
            } else {
                this.allTagsSelected = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
//.container has additional styling in base.scss
.container {
    .add-new-button {
        margin-right: 15px;
    }
}
</style>
