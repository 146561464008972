<template>
    <div class="progress-bar">
        <div class="bar-background">
            <div class="bar" :style="{ width: percentComplete + '%' }"></div>
        </div>
        <h2 class="percentage">{{ percentComplete }}%</h2>
    </div>
</template>

<script>
/**
 * Progress bar that fills in with color based on the `percentComplete`.
 *
 * @prop {number} percentComplete - Value between 0 and 100, determines the width of the filled-in
 *                                   section of the progress bar.
 *
 */
export default {
    name: 'ProgressBar',
    props: {
        percentComplete: {
            type: Number,
            default: 0
        },
        showPercentage: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
.progress-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.bar-background {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 12px;
    border: 1px solid rgb(144, 95, 253); //rgb(108,8,253)
    z-index: 1;
    background-color: white;
}
.bar {
    position: relative;
    height: 10px;
    background-color: rgb(144, 95, 253); //rgb(108,8,253)
    z-index: 2;
    transition: width 1s;
}
.percentage {
    color: rgb(144, 95, 253); //rgb(108,8,253)
    padding: 2rem;
}
</style>
