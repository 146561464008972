<template>
    <modal
        name="modal-create-student-upload"
        height="auto"
        width="90%"
        :scrollable="true"
        :clickToClose="false"
        :adaptive="true"
        @before-open="beforeOpen"
        tabindex="-1"
        :aria-expanded="false"
    >
        <div class="container">
            <h2>Edit students by uploading a spreadsheet</h2>
            <div class="separator"></div>
            <p>
                To make changes to students, upload a list of students and specify which action you
                want to take (e.g. add, edit, remove). Feel free to include a note with more context
                on the action you want to perform.
            </p>
            <div class="section dropdown-section">
                <label for="upload-type-choices" class="header"
                    >Step 1: Select your upload type</label
                >
                <v-select
                    :options="uploadTypeChoices"
                    v-model="uploadType"
                    :clearable="false"
                    id="upload-type-choices"
                    maxWidth="20rem"
                />
            </div>
            <div class="section">
                <p class="header">Step 2: Upload your template</p>
                <p>
                    <button class="download-link text-primary" @click="onDownloadSampleSpreadsheet">
                        Download
                    </button>
                    the sample spreadsheet for your upload type.
                </p>
                <ChooseFileCancelable
                    v-if="sampleDownloaded"
                    @loaded="onFileLoaded"
                    ref="chooser"
                    :readAsString="false"
                >
                    <template v-slot:message>
                        <span class="text-bold text-primary">
                            Upload spreadsheet
                        </span>
                        with your students.
                    </template>
                </ChooseFileCancelable>
                <FieldError :errors="errors" name="file" />
            </div>
            <div class="section">
                <label for="upload-title" class="header">Step 3: Title your upload</label>
                <ElasticTextArea
                    v-model.trim="upload.title"
                    placeholder="Enter a title"
                    style="height: 1rem; padding: 1rem 2rem;"
                    bordered
                    id="upload-title"
                />
                <FieldError :errors="errors" name="title" />
            </div>
            <div class="section">
                <label for="upload-notes" class="header"
                    >Step 4: Anything else we should know?</label
                >
                <ElasticTextArea
                    v-model="upload.notes"
                    placeholder="Add notes for EdSights"
                    bordered
                    id="upload-notes"
                />
            </div>
            <div class="footer">
                <button class="cancel-btn" @click="onCancel">
                    Cancel
                </button>
                <LoadingSpinnerButton
                    class="save-btn"
                    @click="onClickUpload"
                    text="Upload"
                    color="white"
                    :loading="loading"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import ElasticTextArea from '@/components-deprecated/ElasticTextArea';
import StudentUpload from '@/services/studentUploads';
import ChooseFileCancelable from '@/components-deprecated/ChooseFileCancelable';
import FieldError from '@/components-deprecated/FieldError';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
export default {
    name: 'ModalCreateStudentUpload',
    components: {
        ElasticTextArea,
        ChooseFileCancelable,
        FieldError,
        LoadingSpinnerButton
    },
    props: {},
    data() {
        return {
            upload: StudentUpload.create({}),
            uploadType: null,
            loading: false,
            sampleDownloaded: false,
            errors: [],
            uploadChoices: StudentUpload.options.UPLOAD_TYPE_CHOICES_UPDATED
        };
    },
    methods: {
        beforeOpen(event) {
            // Reset any existing values when opened
            this.upload = StudentUpload.create({});
            this.sampleDownloaded = false;
            this.errors = [];
            // Auto-select first upload type
            this.uploadType = this.uploadTypeChoices[0];
        },
        onCancel() {
            this.$emit('closing');
            this.$modal.hide('modal-create-student-upload');
        },
        async onClickUpload() {
            if (this.checkForErrors()) {
                return;
            }
            // Set upload type value
            this.upload.uploadType = this.uploadType.value;
            this.loading = true;
            try {
                const upload = await StudentUpload.api.create(this.upload);
                this.$emit('closing', upload);
                this.$modal.hide('modal-create-student-upload');
            } finally {
                this.loading = false;
            }
        },
        onFileLoaded(file) {
            this.upload.file = file;
        },
        onDownloadSampleSpreadsheet() {
            let filename = '';
            switch (this.uploadType.value) {
                case StudentUpload.options.ADD:
                    filename = 'add';
                    break;
                case StudentUpload.options.UPDATE:
                    filename = 'update';
                    break;
                case StudentUpload.options.REMOVE_GRADUATED:
                    filename = 'delete';
                    break;
                case StudentUpload.options.REMOVE_WITHDREW:
                    filename = 'delete';
                    break;
                case StudentUpload.options.REMOVE_TRANSFERRED:
                    filename = 'delete';
                    break;
                case StudentUpload.options.REMOVE_OTHER:
                    filename = 'delete';
                    break;
                case StudentUpload.options.OTHER:
                    filename = 'add';
                    break;
            }
            window.open(`/integration-templates/${filename}-template.csv`);
            this.sampleDownloaded = true;
        },
        /* Verify user has filled out all required fields */
        checkForErrors() {
            const errors = [];
            if (!this.upload.file) {
                errors.push({
                    field: 'file',
                    detail: 'CSV of students is required.'
                });
            }
            if (!this.upload.title) {
                errors.push({
                    field: 'title',
                    detail: 'Title is required.'
                });
            }
            this.errors = errors;
            return errors.length;
        }
    },
    computed: {
        uploadTypeChoices() {
            return this.uploadChoices;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
@import '@/styles/mixins/buttons';

.container {
    padding: 3rem;
}

.header {
    font-weight: bold;
}

.footer {
    display: flex;
    justify-content: flex-end;
    margin: 2rem 0 1rem 0;
}

.save-btn {
    @include base-button();
}

.cancel-btn {
    @include secondary-button();
    margin-right: 1rem;
}

.download-link {
    font-weight: bold;
    cursor: pointer;
    border: 0;
    background: none;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.dropdown-section {
    max-width: 30rem;
}

.section {
    padding: 1rem 0;
}

.separator {
    width: 100%;
    height: 1px;
    background: #e3eded;
    margin: 1.5rem 0;
}
</style>
