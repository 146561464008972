import { objectToSnakeCase, objectToCamelCase } from '../utils';
import api from './api';
import User from '../users/models';

export default class Staff extends User {
    static api = api.create(Staff);
    static readOnlyFields = ['id'];

    constructor({
        id = null,
        firstName = '',
        lastName = '',
        email = '',
        displayName = '',
        isAdmin = false,
        isStaff = false,
        adminAccount = null,
        school = null,
        schoolRef = null
    } = {}) {
        super(),
            Object.assign(this, {
                id,
                firstName,
                lastName,
                email,
                displayName,
                isAdmin,
                isStaff,
                adminAccount,
                school,
                schoolRef
            });
    }
    static create(data = {}) {
        return new Staff(data);
    }
    static fromAPI(json = {}) {
        const jsonCamel = objectToCamelCase(json);
        return new Staff(jsonCamel);
    }
    static toAPI(d, fields = [], excludeFields = []) {
        let data = {};
        if (fields.length > 0) {
            fields.forEach(f => {
                data[f] = d[f];
            });
        } else {
            data = d;
        }
        excludeFields = [...Staff.readOnlyFields, ...excludeFields];
        excludeFields.forEach(f => {
            delete data[f];
        });
        return objectToSnakeCase(data);
    }
}
