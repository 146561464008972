<template>
    <div class="manage-chatbot">
        <div class="manage-chatbot__container">
            <div class="manage-chatbot__header">
                <span>Manage the Chatbot</span>
            </div>

            <div class="toolbar">
                <div class="toolbar__left">
                    <button class="button-primary" @click="onReview">
                        Review Concerning Responses
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ManageChatbot',
    components: {},
    data() {
        return {};
    },
    created() {},
    methods: {
        onReview() {
            this.$router.push({ name: 'ReviewTriggers' });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/forms';
@import '../../styles/cursors';
@import '../../styles/mixins/buttons';
@import '../../styles/mixins/checkboxes';
@import '../../styles/mixins/lists';
@import '../../styles/mixins/toolbars';

.manage-chatbot {
    display: flex;
    width: 100%;
    justify-content: center;

    &__header {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        font-size: 1.7rem;
        margin-left: 20px;
        font-weight: bold;
    }

    &__container {
        width: 100%;
        margin: 100px auto;
        flex-direction: column;
        justify-content: center;
        max-width: 1200px;
    }
}

.toolbar {
    @include toolbar();
}

.button-primary {
    @include base-button();
}

.button-outline {
    @include outline-button();
}

.list {
    @include list();
}

.checkbox {
    @include checkbox();
}
</style>
