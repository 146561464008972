export const RISK_LEVELS = {
    HIGH_RISK: {
        value: 'HIGH_RISK',
        label: 'High'
    },
    MEDIUM_RISK: {
        value: 'MEDIUM_RISK',
        label: 'Medium'
    },
    LOW_RISK: {
        value: 'LOW_RISK',
        label: 'Low'
    }
};

export const RISK_CATEGORIES = {
    overall: {
        label: 'Overall',
        value: 'OVERALL'
    },
    academic: {
        label: 'Academic',
        value: 'ACADEMIC'
    },
    financial: {
        label: 'Financial',
        value: 'FINANCIAL'
    },
    engagement: {
        label: 'Engagement',
        value: 'ENGAGEMENT'
    },
    wellness: {
        label: 'Wellness',
        value: 'WELLNESS'
    }
};
