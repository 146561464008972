<template>
    <modal
        classes="v--modal"
        name="modal-upload-confirmation"
        height="auto"
        width="800"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
        :stack="false"
    >
        <div class="modal">
            <header class="modal-header">
                <p class="modal-header-title">Upload complete!</p>
            </header>
            <div class="header-separator" />
            <form class="modal-content">
                <slot name="confirmation-message">
                    <div class="student-count-message">
                        There were {{ studentCount }} students in your upload.
                    </div>
                </slot>
                <div class="modal-buttons">
                    <Button class="modal-button" @click.prevent="onDone()">
                        Done
                    </Button>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import Button from '@/components-deprecated/Button';

export default {
    components: {
        Button
    },
    data() {
        return {
            studentCount: 0
        };
    },
    methods: {
        beforeOpen(event) {
            if (event.params) {
                this.studentCount = event.params.studentCount;
            }
        },
        onDone() {
            this.$modal.hide('modal-upload-confirmation');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/views/modal';

.modal-header {
    padding-bottom: 1rem;
}

.modal-header-title {
    font-weight: bold;
    font-size: 1.5rem;
}

.header-separator {
    border-bottom: 2px solid #ebf2f1;
    margin: auto;
    width: 90%;
}

.student-count-message {
    font-size: 1.3rem;
    padding-bottom: 5rem;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
}
</style>
