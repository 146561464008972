<template>
    <div class="container">
        <LoadingSpinner v-if="loading" />
        <div v-else>
            <ButtonBack
                previousPageDisplayName="Manage Admin Tags"
                previousPageRouteName="ManageAdminTags"
            ></ButtonBack>
            <ScrollWrapper class="box">
                <List :title="title" :subtitle="subtitle" :loading="loading">
                    <template v-slot:list-header>
                        <ManageAdminStudentsListHeader />
                    </template>
                    <template v-slot:list-items>
                        <ManageAdminStudentsListItem
                            v-for="student in assignedStudents"
                            :student="student"
                            :key="student.id"
                        />
                    </template>
                </List>
            </ScrollWrapper>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import ButtonBack from '@/components-deprecated/ButtonBack';
import ScrollWrapper from '@/components-deprecated/ScrollWrapper';
import List from '@/components-deprecated/tables/List';
import Admin from '@/services/admins';
import ManageAdminStudentsListHeader from '@/views/AdminSettings/ManageAdminStudents/components/ManageAdminStudentsListHeader';
import ManageAdminStudentsListItem from '@/views/AdminSettings/ManageAdminStudents/components/ManageAdminStudentsListItem';
import { listAdminAssignedStudentsByAdminId } from '@/api/admin-assigned-students';
import { findTagById } from '@/api/tag';

export default {
    name: 'ManageAdminStudents',
    components: {
        ManageAdminStudentsListItem,
        ManageAdminStudentsListHeader,
        ScrollWrapper,
        LoadingSpinner,
        ButtonBack,
        List
    },
    data() {
        return {
            loading: true,
            title: '',
            subtitle: '',
            assignedStudents: [],
            selectedAssignedStudents: [],
            tagName: ''
        };
    },
    async created() {
        if (this.$route.params.adminId && this.$route.params.tagId) {
            const [admin, tag] = await Promise.all([
                Admin.api.retrieve(this.$route.params.adminId),
                findTagById({ id: Number(this.$route.params.tagId) })
            ]);

            if (
                typeof admin === 'object' &&
                typeof admin.displayName === 'string' &&
                typeof tag === 'object' &&
                typeof tag.name === 'string'
            ) {
                this.title = admin.displayName + ` - ${tag.name} Students`;
                this.subtitle = `${admin.displayName} has access to the information for the following students due to their ${tag.name} tag.`;
            }

            await this.loadAssignedStudents(this.$route.params.adminId, this.$route.params.tagId);
            this.loading = false;
        }
    },
    methods: {
        async loadAssignedStudents(adminId, tagId) {
            this.assignedStudents = await listAdminAssignedStudentsByAdminId({
                adminId,
                filters: { tagIds: [tagId] }
            });
            this.selectedAssignedStudents = [];
        }
    }
};
</script>

<style lang="scss" scoped>
//.container has additional styling in base.scss
.container {
    .add-new-button {
        margin-right: 15px;
    }
}
</style>
