<template>
    <div class="risk-driver-explanation-container">
        <Popover>
            <template v-slot:trigger>
                <button
                    class="popover-trigger"
                    tabIndex="0"
                    v-tooltip="'Learn more about risk drivers'"
                >
                    <img
                        class="summary-block__question-icon"
                        src="@/assets/icons/question-mark.svg"
                        alt="Question mark"
                    />
                </button>
            </template>
            <template v-slot:content>
                <div class="risk-driver-exp" tabindex="0">
                    <p class="risk-driver-exp__content">
                        Below are the 4 key risk areas that we measure explained:
                    </p>
                    <div class="risk-driver-exp__content">
                        <b>Financial Distress: </b>
                        <span>
                            This driver measures financial distress "through the eyes" of students
                            by asking them questions around food insecurity, financial means, their
                            ability to pay tuition, and their ability to balance work and school (if
                            they work).
                        </span>
                        <div class="risk-driver-exp__driver">
                            <svg class="risk-driver-exp__driver_icon">
                                <use xlink:href="#icon-risk-financial" />
                            </svg>
                        </div>
                    </div>
                    <div class="risk-driver-exp__content">
                        <b>Academic Engagement: </b>
                        <span>
                            This driver measures how interested a student is in their courses, how
                            excited they are about them, and how useful they perceive them to be for
                            a future career.</span
                        >
                        <div class="risk-driver-exp__driver">
                            <svg class="risk-driver-exp__driver_icon">
                                <use xlink:href="#icon-risk-academic" />
                            </svg>
                        </div>
                    </div>
                    <div class="risk-driver-exp__content">
                        <b>Social Engagement: </b>
                        <span>
                            This driver measures how connected a student feels to their institution
                            and other individuals at their institution. It looks at belonging,
                            whether a student attends on-campus events, and whether they feel
                            committed to their institution.
                        </span>
                        <div class="risk-driver-exp__driver">
                            <svg class="risk-driver-exp__driver_icon">
                                <use xlink:href="#icon-risk-engagement" />
                            </svg>
                        </div>
                    </div>
                    <div class="risk-driver-exp__content">
                        <b>Wellness: </b>
                        <span>
                            Wellness looks at students' satisfaction with housing and roommates (if
                            applicable), as well as whether they are homesick. It also measures how
                            healthy of a lifestyle a student leads (e.g. sleep and exercise
                            habits).</span
                        >
                        <div class="risk-driver-exp__driver">
                            <svg class="risk-driver-exp__driver_icon-belief">
                                <use xlink:href="#icon-risk-belief" />
                            </svg>
                        </div>
                    </div>
                </div>
            </template>
        </Popover>
    </div>
</template>

<script>
import Popover from '@/components-deprecated/global/v2/Popover.vue';

export default {
    name: 'RiskDriverExplanation',
    components: { Popover }
};
</script>

<style lang="scss" scoped>
@import '../styles/variables';
@import '../styles/mixins/buttons';
.popover-trigger {
    cursor: pointer;
    background: none;
    border: 0;

    img {
        width: 16px;
        margin: 6px 2px 0 2px;
    }
}

.risk-driver-exp {
    max-width: 400px;
    &__content {
        padding-bottom: 1rem;
        line-height: 1.8rem;
    }

    &__driver {
        display: inline-flex;
        align-self: center;

        &_icon {
            width: 18px;
            height: 18px;
            color: $gainsboro;
        }

        &_icon-belief {
            background-color: $gainsboro;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            margin-left: 3px;
        }
    }
}
</style>
