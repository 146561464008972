const HIGH_RISK = 'HIGH_RISK';
const MEDIUM_RISK = 'MEDIUM_RISK';
const LOW_RISK = 'LOW_RISK';
const NOT_APPLICABLE = 'NOT_APPLICABLE';
const RISK_LEVEL_TYPE = 'RISK LEVEL';
const RISK_DRIVER_TYPE = 'RISK DRIVER';
const MAX_EMAILS_ON_CLIPBOARD = 100;

const FILTERS = [
    {
        group: RISK_LEVEL_TYPE,
        isExpanded: false,
        tags: [
            {
                type: RISK_LEVEL_TYPE,
                id: 'not_applicable',
                name: 'N/A',
                value: NOT_APPLICABLE
            },
            {
                type: RISK_LEVEL_TYPE,
                id: 'low',
                name: 'Low',
                value: LOW_RISK
            },
            {
                type: RISK_LEVEL_TYPE,
                id: 'medium',
                name: 'Medium',
                value: MEDIUM_RISK
            },
            {
                type: RISK_LEVEL_TYPE,
                id: 'high',
                name: 'High',
                value: HIGH_RISK
            }
        ]
    },
    {
        group: RISK_DRIVER_TYPE,
        tags: [
            {
                type: RISK_DRIVER_TYPE,
                id: 'financial',
                name: 'Financial',
                // Filter name for query params
                filterName: 'financialRiskLevel'
            },
            {
                type: RISK_DRIVER_TYPE,
                id: 'academic',
                name: 'Academic',
                filterName: 'academicRiskLevel'
            },
            {
                type: RISK_DRIVER_TYPE,
                id: 'engagement',
                name: 'Engagement',
                filterName: 'engagementRiskLevel'
            },
            {
                type: RISK_DRIVER_TYPE,
                id: 'wellness',
                name: 'Wellness',
                filterName: 'wellnessRiskLevel'
            }
        ]
    }
];

export default {
    HIGH_RISK,
    MEDIUM_RISK,
    LOW_RISK,
    NOT_APPLICABLE,
    RISK_LEVEL_TYPE,
    RISK_DRIVER_TYPE,
    MAX_EMAILS_ON_CLIPBOARD,
    FILTERS
};
