<template>
    <div class="student-voice-score-over-time-card-container">
        <Card title="Score Over Time" titleTooltip="The history of your school's SVS">
            <div class="student-voice-score-over-time-card-content">
                <div class="score-difference" v-if="scoreDifference">
                    <div class="difference" :class="scoreDifference.class">
                        <i
                            class="difference-icon pi"
                            :class="
                                scoreDifference.difference > 0
                                    ? 'pi-arrow-circle-up'
                                    : scoreDifference.difference < 0
                                    ? 'pi-arrow-circle-down'
                                    : ''
                            "
                        />
                        <span>{{ scoreDifference.difference + ' ' }}</span>
                    </div>
                    <span class="date">since {{ scoreDifference.sinceDate }}</span>
                </div>
                <LineChart
                    class="chart"
                    :height="180"
                    :chartData="chartData"
                    :yAxisTickLimit="3"
                    :yAxisMin="-100"
                    :yAxisMax="100"
                    xAxisType="time"
                    xAxisTimeUnit="year"
                    :xAxisMin="chartTimeAxisLabels.min"
                    :xAxisMax="chartTimeAxisLabels.max"
                    :ariaLabel="chartAriaLabel"
                    :tooltipCallbacks="tooltipCallbacks"
                />
            </div>
        </Card>
    </div>
</template>

<script>
import Card from '@/components-deprecated/global/Card.vue';
import LineChart from '@/components-deprecated/charts/LineChart';
import { formatISO, FORMATS } from '@/lib/dates';
import { getDefaultLineChartProperties } from '@/components-deprecated/charts/constants';
import { DateTime } from 'luxon';

export default {
    name: 'StudentVoiceScoreOverTimeCard',
    components: { LineChart, Card },
    props: {
        studentVoiceScores: {
            type: Array,
            required: false
        }
    },
    methods: {
        formatDifference(difference) {
            if (typeof difference === 'number') {
                return (difference >= 0 ? '+' : '') + difference;
            }

            return null;
        },
        getDifferenceClass(difference) {
            if (typeof difference === 'number') {
                return difference > 0 ? 'increase' : difference < 0 ? 'decrease' : '';
            }

            return null;
        }
    },
    computed: {
        scoreDifference() {
            if (Array.isArray(this.studentVoiceScores) && this.studentVoiceScores.length > 1) {
                const currentIndex = this.studentVoiceScores.length - 1;
                const current = this.studentVoiceScores[currentIndex];
                const previous = this.studentVoiceScores[currentIndex - 1];
                const difference = current.score - previous.score;

                return {
                    difference: this.formatDifference(difference),
                    class: this.getDifferenceClass(difference),
                    sinceDate: formatISO(previous.chatbotFlowDate, FORMATS.MONTH_LONG_YEAR_LONG)
                };
            }

            return null;
        },
        chartData() {
            const data = [];

            if (Array.isArray(this.studentVoiceScores)) {
                this.studentVoiceScores.forEach(studentVoiceScore => {
                    data.push({
                        // this x value must be a luxon date object, or an iso string that matches the
                        // xAxisTimeParser provided to LineChart
                        x: DateTime.fromISO(studentVoiceScore.chatbotFlowDate),
                        y: studentVoiceScore.score
                    });
                });
            }

            return {
                datasets: [
                    {
                        data,
                        ...getDefaultLineChartProperties()
                    }
                ]
            };
        },
        chartAriaLabel() {
            const title = 'SVS score over time: ';
            if (Array.isArray(this.studentVoiceScores) && this.studentVoiceScores.length) {
                const length = this.studentVoiceScores.length;
                const dataString = this.studentVoiceScores.reduce((acc, svs, index) => {
                    const time = formatISO(svs.chatbotFlowDate, FORMATS.MONTH_LONG_YEAR_LONG);
                    const score = svs.score;
                    return `${acc}${time}: ${score}${index === length - 1 ? '.' : ', '}`;
                }, '');
                return `${title} ${dataString}`;
            }

            return null;
        },
        chartTimeAxisLabels() {
            if (Array.isArray(this.studentVoiceScores)) {
                // Note: This card only displays when there is at least one score, so there is
                // guaranteed to be at least one in the array.
                const firstSvs = this.studentVoiceScores[0];
                const lastSvs = this.studentVoiceScores[this.studentVoiceScores.length - 1];
                return {
                    min: DateTime.fromISO(firstSvs.chatbotFlowDate)
                        .startOf('year')
                        .toISO(),
                    max: DateTime.fromISO(lastSvs.chatbotFlowDate)
                        .plus({ years: 1 })
                        .startOf('year')
                        .toISO()
                };
            }

            return null;
        },
        tooltipCallbacks() {
            return {
                title: function(tooltipItem) {
                    // Get x value and confirm it's a luxon date
                    // (chart.js is using luxon as a date parser)
                    if (
                        Array.isArray(tooltipItem) &&
                        tooltipItem[0] &&
                        tooltipItem[0].raw &&
                        tooltipItem[0].raw.x &&
                        //check for .toISO luxon method
                        tooltipItem[0].raw.x.toISO
                    ) {
                        return formatISO(
                            tooltipItem[0].raw.x.toISO(),
                            FORMATS.MONTH_LONG_YEAR_LONG
                        );
                    }
                    return '';
                },
                label: function(context) {
                    if (context && context.formattedValue) {
                        return `${context.formattedValue} texts`;
                    }
                    return '';
                }
            };
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.student-voice-score-over-time-card-container {
    width: 100%;
    .student-voice-score-over-time-card-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        .chart {
            height: 180px;
            width: 100%;
            margin-top: 10px;
        }

        .score-difference {
            display: flex;
            align-items: center;
            font-weight: bold;
            .difference {
                &.increase {
                    color: $green;
                }
                &.decrease {
                    color: $amaranth;
                }

                .difference-icon {
                    margin-right: 5px;
                }
            }

            .date {
                margin-left: 4px;
            }
        }
    }
}
</style>
