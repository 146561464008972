<template>
    <div class="spinner" aria-hidden="true">
        <div class="spinner-a" :style="{ backgroundColor: color }"></div>
        <div class="spinner-b" :style="{ backgroundColor: color }"></div>
        <div class="spinner-c" :style="{ backgroundColor: color }"></div>
    </div>
</template>

<script>
export default {
    name: 'LoadingSpinner',
    props: {
        color: {
            type: String,
            default: '#068BFF'
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins/animations';

.spinner {
    margin: 0 auto;
    width: 70px;
    text-align: center;

    & > div {
        // Bouncing circles
        width: 18px;
        height: 18px;
        border-radius: 100%;
        display: inline-block;
        @include extreme-pulse-animation();
    }

    & .spinner-a {
        animation-delay: -0.32s;
    }

    & .spinner-b {
        animation-delay: -0.16s;
    }
}
</style>
