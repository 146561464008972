import { objectToCamelCase, objectToSnakeCase } from '../utils';
import options from './options';
import BackgroundOperation from '@/services/backgroundOperations';

export default class LargeOperation {
    static options = options;
    constructor({
        file = null,
        filename = '',
        backgroundOp = null,
        isBackground = null,
        results = null
    } = {}) {
        Object.assign(this, {
            file,
            filename,
            backgroundOp: backgroundOp ? BackgroundOperation.fromAPI(backgroundOp) : null,
            isBackground,
            results
        });
    }

    static create(opts) {
        return new LargeOperation(opts);
    }

    static fromAPI(json = {}) {
        return new LargeOperation(objectToCamelCase(json));
    }
}
