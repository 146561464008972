import { objectToCamelCase } from '../utils';
import api from './api';

export default class Tag {
    static api = api.create(Tag);

    constructor(tag) {
        // eslint-disable-next-line no-param-reassign
        tag = tag || {};
        const {
            id = null,
            name = '',
            category = '',
            studentCount = 0,
            categoryRef = {},
            isPublic = false,
            school = null,
            schoolRef = null,
            studentsLastModified = null
        } = tag;
        Object.assign(this, {
            id,
            name,
            category,
            studentCount,
            isPublic,
            categoryRef,
            school,
            schoolRef,
            studentsLastModified
        });
    }

    static create(opts) {
        return new Tag(opts);
    }

    static fromAPI(json = {}) {
        return new Tag(objectToCamelCase(json));
    }
}
