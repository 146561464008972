<template>
    <div>
        <div class="row">
            <p class="tag-category-mapping-name">{{ categoryToMap.header.csvHeader }}</p>
            <select
                :disabled="isCategorySelectDisabled(categoryToMap)"
                class="dropdown"
                v-model="selectedTagCategory"
                @change="onTagCategoryChangeEvent($event)"
            >
                <option value="CUSTOM_NEW">Custom New</option>
                <option
                    v-for="tagCategory in tagCategories"
                    :value="tagCategory.id"
                    :key="tagCategory.id"
                >
                    <p v-if="tagCategory.isPublic">Public:</p>
                    <p v-else>Custom:</p>
                    {{ tagCategory.name }}
                </option>
            </select>
        </div>
        <div class="tag-mapping" v-for="tagMapping in categoryToMap.tags" :key="tagMapping">
            <div class="row">
                <p class="tag-name">{{ tagMapping }}</p>
                <select
                    class="dropdown"
                    @change="onTagChange(tagMapping, $event, categoryToMap.header.csvHeader)"
                >
                    <option
                        v-for="tagChoice in tagChoices"
                        :value="tagChoice.id"
                        :key="tagChoice.id"
                        :selected="tagChoice.id == tagMapping.tag"
                    >
                        {{ tagChoice.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import * as stringHelpers from '@/lib/string-helpers';

export default {
    props: {
        categoryToMap: {
            type: Object,
            required: true
        },
        tagMappings: {
            type: Array,
            required: true
        },
        tagCategoryMappings: {
            type: Array,
            required: true
        },
        tagCategories: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedTagCategory: 'CUSTOM_NEW',
            tagChoices: []
        };
    },
    created() {
        const categoryName = this.categoryToMap.header.csvHeader;
        const categoryDetails = this.categoryToMap.header.categoryDetails;
        let tagCategory = null;

        // categoryDetails indicates a mapped category
        if (categoryDetails && categoryDetails.hasOwnProperty('hasMap')) {
            // set the mapped category as the selected tag category
            this.selectedTagCategory = categoryDetails.id;
            tagCategory = this.tagCategories.find(c => c.id === categoryDetails.id);
        }

        // if category is not mapped, look for a matching category by name
        if (!tagCategory) {
            tagCategory = this.tagCategories.find(
                category =>
                    stringHelpers.trimAndLower(category.name) ===
                    stringHelpers.trimAndLower(categoryName)
            );
        }

        this.handleTagCategoryChange(tagCategory);
    },
    methods: {
        isCategorySelectDisabled(categoryToMap) {
            // the categoryDetails object will be provided if this category exists
            // hasMap will be true or false depending on if the category was mapped
            return (
                categoryToMap.header.categoryDetails &&
                categoryToMap.header.categoryDetails.hasOwnProperty('hasMap')
            );
        },

        onTagCategoryChangeEvent(event) {
            let tagCategory = this.tagCategories.find(
                category => category.id === event.target.value
            );
            this.handleTagCategoryChange(tagCategory);
        },

        handleTagCategoryChange(tagCategory) {
            if (tagCategory) {
                this.tagChoices = tagCategory.tags;

                // if this is a private tag category, add CUSTOM_NEW tag option
                if (!tagCategory.isPublic) {
                    this.tagChoices.unshift({ id: 'CUSTOM_NEW', name: 'Custom New' });
                }

                this.$emit(
                    'on-update-tag-category',
                    this.categoryToMap,
                    tagCategory.id,
                    this.tagChoices[0].id
                );
            } else {
                this.tagChoices = [{ id: 'CUSTOM_NEW', name: 'Custom New' }];
                this.$emit(
                    'on-update-tag-category',
                    this.categoryToMap,
                    'CUSTOM_NEW',
                    this.tagChoices[0].id
                );
            }
        },

        onTagChange(tagMapping, event, tagCategoryName) {
            this.updateTagMapping(tagMapping, event.target.value, tagCategoryName);
        },

        updateTagMapping(tagMapping, mapTo, tagCategoryName) {
            this.$emit('on-update-tag', tagMapping, mapTo, tagCategoryName);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/views/modal';
@import '@/styles/forms';
@import '@/styles/variables';

.tag-category-mapping-name {
    flex: 3;
    font-size: 1.1rem;
    font-weight: bold;
}

.tag-mapping {
    margin-left: 1rem;
    margin-top: 0.5rem;
}

.tag-name {
    flex: 3;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.dropdown {
    flex: 1;
    border-radius: 0;
    height: 100%;
    padding: 0.25rem 0.5rem;
}
</style>
