<template>
    <modal name="modal-welcome" :height="'auto'" :scrollable="true" :adaptive="true">
        <div class="welcome-modal">
            <h1 class="welcome">Welcome!</h1>
            <h2 class="header">
                Welcome to your EdSights account!
            </h2>

            <div class="description">
                Everything you need is on the left hand menu. You can view and edit students under
                Student Analysis or under Settings, and you can manage your tags under Student Tags.
            </div>

            <div style="margin-top: 1rem;">
                <button @click="$modal.hide('modal-welcome')" class="welcome-modal__close-button">
                    Done
                </button>
            </div>
        </div>
    </modal>
</template>

<style lang="scss" scoped>
@import '../../styles/mixins/buttons';
@import '../../styles/variables';

.welcome-modal {
    padding: 2rem;

    &__close-button {
        @include base-button();
        margin: 0 auto;
    }
}

.icon-description {
    display: flex;
    align-items: center;

    & > svg {
        flex: 1;
        margin: 0.5rem 1rem 0 0;
        color: $primary-brand-color;
    }

    & > p {
        flex: 8;
    }
}

.header {
    font-weight: normal;
    text-align: center;
    font-size: 28px;
    margin-bottom: 40px;
}

.description {
    margin-bottom: 40px;
    text-align: center;
    font-size: 19px;
    color: $main-gray;
}

.welcome {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    font-weight: 100;
    color: $primary-brand-color;
    font-family: brush;
}
</style>
