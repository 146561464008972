import Pagination from '@/services/pagination';
import { apiErrorHandler } from '@/services/api';

/**
 * An abstraction that provides a simple interface for managing
 * paginated collections of entities from the API.
 **/
export default class CollectionManager {
    constructor({
        list = [],
        pagination = Pagination.create(),
        refreshing = false,
        loadingNextPage = false,
        filters = {},
        _previousFilters = {},
        ModelClass = null,
        v2 = false,
        slim = false
    } = {}) {
        Object.assign(this, {
            list,
            pagination,
            refreshing,
            loadingNextPage,
            filters,
            _previousFilters,
            ModelClass,
            v2,
            slim
        });
    }

    // Factory Function
    static create(opts = {}) {
        opts = opts || {};
        return new CollectionManager(opts);
    }

    /**
     * Update the collection.
     *
     * @param {object} data - API response data
     * @param {boolean} append - Optional, add to the current list if `true`. Replace the
     *                           current list if false. Defaults to `false`.
     */
    update(data, append = false) {
        this.list = [...(append ? this.list : []), ...data.results];
        this.pagination = Pagination.create({
            ...this.pagination,
            next: data.next,
            previous: data.previous,
            totalCount: data.count
        });

        return this;
    }

    /**
     * Update an item in the collection.
     * @param {string} id - The id of the item to update
     * @param {object} data - The data that will be updated on the item. This can contain one or multiple keys to update.
     */
    updateItem(id, data) {
        this.list = this.list.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    ...data
                };
            }

            return item;
        });
    }

    /**
     * Refresh the collection.
     */
    async refresh() {
        this.refreshing = true;

        // If filters have changed, reset pagination
        if (!_.isEqual(this.filters, this._previousFilters)) {
            this.pagination = Pagination.create();
        }

        // Save value of current filters, so we can check to see if they've changed later
        this._previousFilters = _.cloneDeep(this.filters);
        const params = {
            page: this.pagination.page,
            page_size: this.pagination.size,
            ...this.filters
        };

        if (this.v2) {
            params.v2 = this.v2;
            params.slim = this.slim;
        }

        try {
            const response = await this.ModelClass.api.list({ ...params });

            return this.update(response);
        } catch (error) {
            apiErrorHandler({
                apiName: `Refresh ${this.ModelClass.name} Collection error`
            });
        } finally {
            this.refreshing = false;
        }
    }

    /**
     * Advance to the next page and refresh the collection.
     */
    nextPage() {
        this.pagination.setNextPage();
        return this.refresh();
    }

    /**
     * Go back to the previous page and refresh the collection.
     */
    prevPage() {
        this.pagination.setPrevPage();
        return this.refresh();
    }

    /**
     * Get the next page for a collection and add this page
     * to the collection.
     */
    async addNextPage() {
        if (this.pagination.next === null) {
            return;
        }
        this.pagination.setNextPage();

        this.loadingNextPage = true;
        const params = {
            ...this.filters,
            page: this.pagination.page,
            page_size: this.pagination.size
        };

        if (this.v2) {
            params.v2 = this.v2;
            params.slim = this.slim;
        }

        try {
            const response = await this.ModelClass.api.list({ ...params });
            return this.update(response, true);
        } catch (error) {
            apiErrorHandler({
                apiName: `Add Next Page for ${this.ModelClass.name} Collection error`
            });
        } finally {
            this.loadingNextPage = false;
        }
    }
}
