<template>
    <modal
        classes="v--modal"
        name="modal-manage-student-upload"
        height="auto"
        width="800"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
        :stack="false"
    >
        <div v-if="studentUpload" class="modal">
            <header class="modal-header">
                <p class="modal-header-title">
                    Manage students at {{ studentUpload.schoolRef.name }}
                </p>
            </header>
            <form class="modal-content">
                <div class="step-container">
                    <p class="step-title">Step 1: Download the spreadsheet and review notes</p>
                    <p class="step-details">
                        File:
                        <button class="download-link text-primary" @click="onDownloadSpreadsheet">
                            {{ studentUpload.title }}</button
                        ><img class="download-icon" src="@/assets/upload.png" alt="Download" />
                    </p>
                    <p class="step-details">Upload Type: {{ studentUpload.uploadTypeRef }}</p>
                    <p class="step-details">Notes: {{ studentUpload.notes }}</p>
                </div>
                <div class="step-container">
                    <p class="step-title">Step 2: Student Upload Type</p>
                    <div class="step-details upload-type-select">
                        <v-select
                            :options="uploadTypeOptions"
                            v-model="uploadType"
                            :clearable="false"
                        />
                    </div>
                    <p v-if="invalidUploadType" class="step-error">
                        Please Select a different action to start this upload
                    </p>
                </div>
                <div class="step-container">
                    <p class="step-title">Step 3: Upload your file</p>
                    <p class="step-details">
                        Upload the students you want to perform the above action on.
                        <button
                            class="download-link text-primary"
                            @click="onDownloadSampleSpreadsheet"
                        >
                            Download
                        </button>
                        this sample spreadsheet to see the format you should use for your upload
                    </p>
                    <ChooseFileCancelable :readAsString="false" @loaded="fileUploaded">
                        <template v-slot:message>
                            <h3 class="upload-message">Upload your Spreadsheet</h3>
                        </template>
                    </ChooseFileCancelable>
                </div>
                <div class="modal-buttons">
                    <Button
                        styleType="off"
                        class="modal-button"
                        @click.prevent="$modal.hide('modal-manage-student-upload')"
                    >
                        Cancel
                    </Button>
                    <Button
                        :loading="loading"
                        class="modal-button"
                        @click.prevent="onSubmit()"
                        :disabled="!this.file"
                    >
                        Import
                    </Button>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';
import StudentUpload from '@/services/studentUploads';
import Student from '@/services/students';
import TagMapping from '@/services/tagMappings';
import ChooseFileCancelable from '@/components-deprecated/ChooseFileCancelable';
import Button from '@/components-deprecated/Button';
import BackgroundOperation from '@/services/backgroundOperations';

export default {
    components: {
        ChooseFileCancelable,
        Button
    },
    data() {
        return {
            file: null,
            studentUpload: null,
            uploadType: null,
            uploadTypeOptions: [],
            loading: false
        };
    },
    computed: {
        invalidUploadType() {
            // admins are allowed to set other but staff need to select an option
            return this.uploadType.value == StudentUpload.options.OTHER;
        }
    },
    methods: {
        ...mapActions(['updateBackgroundOperation', 'setBackgroundOperationCompletionAction']),
        beforeOpen(event) {
            this.studentUpload = event.params.studentUpload;
            this.uploadTypeOptions = event.params.uploadTypeOptions;
            this.refreshPage = event.params.refreshPage;
            this.showConfirmationModal = event.params.showConfirmationModal;

            // Default to the upload type that the school admin has chosen
            this.uploadType = this.uploadTypeOptions.find(
                option => option.value === this.studentUpload.uploadType
            );
        },
        async onDownloadSpreadsheet() {
            window.open(this.studentUpload.file);
        },
        async onDownloadSampleSpreadsheet() {
            let filename = '';
            switch (this.uploadType.value) {
                case StudentUpload.options.ADD:
                    filename = 'Student Importer Template - Add';
                    break;
                case StudentUpload.options.UPDATE:
                    filename = 'Student Importer Template - Update';
                    break;
                case StudentUpload.options.REMOVE_GRADUATED:
                    filename = 'Student Importer Template - Remove Graduated';
                    break;
                case StudentUpload.options.REMOVE_WITHDREW:
                    filename = 'Student Importer Template - Remove Withdrew';
                    break;
                case StudentUpload.options.REMOVE_TRANSFERRED:
                    filename = 'Student Importer Template - Remove Transferred';
                    break;
                case StudentUpload.options.REMOVE_OTHER:
                    filename = 'Student Importer Template - Remove Other';
                    break;
                case StudentUpload.options.OTHER:
                    filename = 'Student Importer Template - Other';
                    break;
            }
            window.open(`/static/${filename}.csv`);
            this.sampleDownloaded = true;
        },
        async fileUploaded(file) {
            this.file = file;
        },
        async uploadFile() {
            let response = null;

            // Kick off appropriate API to add / update / deactivate students
            try {
                switch (this.uploadType.value) {
                    case StudentUpload.options.ADD:
                        response = await Student.api.createStudentsFromCSV(
                            this.file,
                            this.studentUpload.schoolRef.id
                        );
                        break;
                    case StudentUpload.options.UPDATE:
                        response = await Student.api.updateStudentsFromCSV(
                            this.file,
                            this.studentUpload.schoolRef.id
                        );
                        break;
                    case StudentUpload.options.REMOVE_GRADUATED:
                        response = await Student.api.deactivateStudentsFromCSV(
                            this.file,
                            this.studentUpload.schoolRef.id,
                            'GRADUATED'
                        );
                        break;
                    case StudentUpload.options.REMOVE_WITHDREW:
                        response = await Student.api.deactivateStudentsFromCSV(
                            this.file,
                            this.studentUpload.schoolRef.id,
                            'WITHDREW'
                        );
                        break;
                    case StudentUpload.options.REMOVE_TRANSFERRED:
                        response = await Student.api.deactivateStudentsFromCSV(
                            this.file,
                            this.studentUpload.schoolRef.id,
                            'TRANSFERRED'
                        );
                        break;
                    case StudentUpload.options.REMOVE_OTHER:
                        response = await Student.api.deactivateStudentsFromCSV(
                            this.file,
                            this.studentUpload.schoolRef.id,
                            'OTHER'
                        );
                        break;
                }

                // Update the Student Upload to an Imported status
                await StudentUpload.api.update({
                    id: this.studentUpload.id,
                    status: StudentUpload.options.IMPORTED
                });
                await this.refreshPage();
            } finally {
                this.loading = false;
            }

            // If the response we received is a Background Operation, add it to global state
            if (response.isBackground) {
                this.updateBackgroundOperation(response.backgroundOp);
                // If not, show confirmation modal with response results
            } else {
                this.showConfirmationModal(response.results);
            }
        },
        showTagMappingModal(tagMappings) {
            this.$modal.show('modal-tag-mapping', {
                tagMappings: tagMappings,
                school: this.studentUpload.schoolRef.id,
                onSubmit: this.uploadFile
            });
        },
        async onVerify() {
            const response = await TagMapping.api.createTagMappingsFromCSV(
                this.file,
                this.studentUpload.schoolRef.id
            );

            if (response.isBackground) {
                this.updateBackgroundOperation(response.backgroundOp);
                this.setBackgroundOperationCompletionAction({
                    backgroundOperationId: response.backgroundOp.id,
                    action: this.showTagMappingModal.bind(this)
                });
                this.loading = false;
            } else if (response.results[0].value.length > 0) {
                this.showTagMappingModal(response.results[0].value);
            } else {
                await this.uploadFile();
                this.loading = false;
            }
        },
        onCancel() {
            this.$modal.show('modal-manage-student-upload');
            this.loading = false;
        },
        async onSubmit() {
            this.loading = true;

            if (
                this.uploadType.value === StudentUpload.options.ADD ||
                this.uploadType.value === StudentUpload.options.UPDATE
            ) {
                this.$modal.hide('modal-manage-student-upload');
                this.$modal.show('modal-csv-verify', {
                    file: this.file,
                    onSubmit: this.onVerify,
                    onCancel: this.onCancel
                });
            } else {
                await this.uploadFile();
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/views/modal';
@import '../../styles/forms';

.modal {
    padding: 3rem;
}

.modal-header {
    padding-bottom: 5rem;
}

.modal-header-title {
    font-weight: bold;
    font-size: 1.5rem;
}

.step-container {
    margin: 1rem;
}

.step-title {
    font-weight: bold;
    font-size: 1.1rem;
}

.step-details {
    margin: 1.5rem 1rem;
}

.download-icon {
    vertical-align: sub;
    height: 16px;
    width: 16px;
    margin-left: 5px;
}

.upload-type-select {
    max-width: 25rem;
}

.download-link {
    font-weight: bold;
    cursor: pointer;
    border: 0;
    background: none;

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.modal-button {
    display: inline-block;

    &:last-child {
        margin-left: 1rem;
    }
}

.upload-message {
    margin: 0.5rem 10rem;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
}

.step-error {
    color: red;
    font-size: 10px;
    font-style: italic;
}
</style>
