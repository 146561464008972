<template>
    <div class="dashboard-container">
        <h1>Create School Dashboard</h1>
        <div class="form-container">
            <div class="label-input-container">
                <div class="label-container">
                    <p>School Name</p>
                    <p>School Nickname</p>
                    <p>School Mascot Name</p>
                    <p>Admin First Name</p>
                    <p>Admin Last Name</p>
                    <p>Admin Email</p>
                    <p>Temporary Password</p>
                </div>
                <div class="input-container">
                    <input class="input-box" v-model="schoolName" placeholder="EdSights College" />
                    <input class="input-box" v-model="schoolNickname" placeholder="EdSights" />
                    <input class="input-box" v-model="schoolMascotName" placeholder="Mascot" />
                    <input class="input-box" v-model="adminFirstName" placeholder="John" />
                    <input class="input-box" v-model="adminLastName" placeholder="Doe" />
                    <input
                        class="input-box"
                        v-model="adminEmail"
                        placeholder="JohnDoe@edsights.edu"
                    />
                    <input class="input-box" v-model="temporaryPassword" />
                </div>
            </div>
            <div class="checkbox-container">
                <BaseCheckbox
                    v-model="verificationChecked"
                    :disabled="registerTwilioOnCreation === false"
                />
                <p v-if="!verificationChecked">
                    Check box to display the phone number verification fields
                </p>
                <p v-if="verificationChecked">
                    Uncheck box to create school without phone verification
                </p>
            </div>
            <div class="verification-container" v-if="verificationChecked">
                <h2 class="verification-container header">
                    Phone Number Verification Fields
                </h2>
                <div class="label-input-container">
                    <div class="label-container">
                        <p>Street Address</p>
                        <p>City</p>
                        <p>State</p>
                        <p>Postal Code</p>
                        <p>Contact First Name</p>
                        <p>Contact Last Name</p>
                        <p>Contact Email</p>
                        <p>Contact Phone #</p>
                        <p>Website</p>
                        <p>Opt In Image Url</p>
                        <p>Message Volume</p>
                        <p>Additional Info</p>
                    </div>
                    <div class="input-container">
                        <input
                            class="input-box verification"
                            v-model="businessStreetAddress"
                            placeholder="1 Main Street"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessCity"
                            placeholder="New York"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessStateProvinceRegion"
                            placeholder="NY"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessPostalCode"
                            placeholder="55555"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessContactFirstName"
                            placeholder="John"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessContactLastName"
                            placeholder="Doe"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessContactEmail"
                            placeholder="JohnDoe@edsights.edu"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessContactPhone"
                            placeholder="1234567890"
                        />
                        <input
                            class="input-box verification"
                            v-model="businessWebsite"
                            placeholder="www.edsights.io"
                        />
                        <input
                            class="input-box verification"
                            v-model="optInImageUrls"
                            placeholder="https://drive.google.com/file/d/1gAq9g"
                        />
                        <v-select
                            :options="messageVolumeList"
                            @update:modelValue="volumeSelectHandler"
                            placeholder="Choose a volume..."
                            :clearable="false"
                            class="dropdown"
                        />
                        <textarea
                            v-model="additionalInformation"
                            class="input-textarea"
                            placeholder="EdSights University, through www.edsights.io, adheres to legal texting standards..."
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
        <Button
            class="button-create"
            width="38rem"
            @click="createDashboardHandler"
            v-if="!showLoadingSpinner"
        >
            Create Dashboard
        </Button>
        <div v-else>
            <LoadingSpinner />
        </div>
        <ul v-if="isDashboardCreated" class="list-container">
            <li v-for="log in logs" :key="log">
                {{ log }}
            </li>
        </ul>
    </div>
</template>

<script>
import * as SchoolApi from '@/api/school';
import {
    registerSubAccount,
    submitPhoneVerification,
    validateTwilioVerification
} from '@/api/phone-number-registry';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Button from '@/components-deprecated/global/Button';
import { BaseCheckbox } from '@edsights/ui-core';

export default {
    name: 'CreateSchoolDashboard',
    components: {
        LoadingSpinner,
        Button,
        BaseCheckbox
    },
    data() {
        return {
            registerTwilioOnCreation: process.env.VUE_APP_CREATE_SCHOOL_AND_REGISTER_NUMBER_IN_TWILIO === 'true',
            adminFirstName: '',
            adminLastName: '',
            adminEmail: '',
            schoolMascotName: '',
            schoolName: '',
            schoolNickname: '',
            temporaryPassword: '',
            isDashboardCreated: false,
            logs: [],
            showLoadingSpinner: false,
            verificationChecked: false,
            businessStreetAddress: '',
            businessCity: '',
            businessStateProvinceRegion: '',
            businessPostalCode: '',
            businessContactFirstName: '',
            businessContactLastName: '',
            businessContactEmail: '',
            businessContactPhone: '',
            businessWebsite: '',
            optInImageUrls: '',
            additionalInformation: '',
            messageVolume: '',
            messageVolumeList: [
                '1',
                '10',
                '1,000',
                '10,000',
                '100,000',
                '250,000',
                '500,000',
                '750,000',
                '1,000,000',
                '5,000,000',
                '10,000,000'
            ],
            businessCountry: 'US',
            optInType: 'WEB_FORM',
            notificationEmail: 'engineering@edsights.io',
            useCaseCategories: ['CHARITY_NONPROFIT', 'HIGHER_EDUCATION'],
            useCaseSummary:
                'Support students via text, remind them of deadlines, and answer their questions.',
            productionMessageSample:
                "Just a friendly reminder to complete your FAFSA by our priority deadline of May 1st to ensure you're considered for all possible financial aid. Reply STOP to opt out."
        };
    },
    methods: {
        volumeSelectHandler(value) {
            this.messageVolume = value;
        },
        resetForm() {
            this.adminFirstName = '';
            this.adminLastName = '';
            this.adminEmail = '';
            this.schoolMascotName = '';
            this.schoolName = '';
            this.schoolNickname = '';
            this.temporaryPassword = '';
            this.selectedSchool = null;
            this.schoolId = '';
            this.businessStreetAddress = '';
            this.businessCity = '';
            this.businessStateProvinceRegion = '';
            this.businessPostalCode = '';
            this.businessContactFirstName = '';
            this.businessContactLastName = '';
            this.businessContactEmail = '';
            this.businessContactPhone = '';
            this.businessName = '';
            this.businessWebsite = '';
            this.optInImageUrls = '';
            this.additionalInformation = '';
            this.messageVolume = '';
        },
        async registerTwilioPhoneNumber(schoolId) {
            try {
                const registrationResponse = await registerSubAccount({ payload: { schoolId } });
                if (registrationResponse.error) {
                    this.logs.push(
                        `Could not register sub account: ${registrationResponse.message}`
                    );
                    throw registrationResponse;
                }
                this.logs.push(`SubAccount created: ${registrationResponse.friendlyName}`);
            } catch (error) {
                this.$Alert.alert({
                    type: 'warning',
                    message: error.message,
                    timeout: 3000
                });
                console.error(error.message);
            }
        },
        async submitTwilioPhoneVerification({ payload }) {
            try {
                const verificationResponse = await submitPhoneVerification({ payload });
                if (verificationResponse.error) {
                    this.logs.push(
                        `Could not submit toll free verification: ${verificationResponse.message}`
                    );
                    throw verificationResponse;
                }
                this.logs.push('Toll Free Verification submitted successfully.');
            } catch (error) {
                this.$Alert.alert({
                    type: 'warning',
                    message: error.message,
                    timeout: 3000
                });
                console.error(error.message);
            }
        },
        async createDashboardHandler() {
            this.showLoadingSpinner = true;
            try {
                let verificationPayload;
                const payload = {
                    name: this.schoolName,
                    nickname: this.schoolNickname,
                    mascotName: this.schoolMascotName,
                    firstName: this.adminFirstName,
                    lastName: this.adminLastName,
                    email: this.adminEmail,
                    password: this.temporaryPassword
                };

                if (this.verificationChecked) {
                    verificationPayload = {
                        businessStreetAddress: this.businessStreetAddress,
                        businessCity: this.businessCity,
                        businessStateProvinceRegion: this.businessStateProvinceRegion,
                        businessPostalCode: this.businessPostalCode,
                        businessContactFirstName: this.businessContactFirstName,
                        businessContactLastName: this.businessContactLastName,
                        businessContactEmail: this.businessContactEmail,
                        businessContactPhone: `1${this.businessContactPhone}`, // 1 for country code
                        businessName: this.schoolName,
                        businessWebsite: this.businessWebsite,
                        optInType: this.optInType,
                        optInImageUrls: [this.optInImageUrls],
                        additionalInformation: this.additionalInformation,
                        messageVolume: this.messageVolume,
                        businessCountry: this.businessCountry,
                        notificationEmail: this.notificationEmail,
                        useCaseCategories: this.useCaseCategories,
                        useCaseSummary: this.useCaseSummary,
                        productionMessageSample: this.productionMessageSample
                    };

                    const isValidated = validateTwilioVerification(verificationPayload);
                    if (!isValidated.status) {
                        throw isValidated.message;
                    }
                }

                const response = await SchoolApi.createSchool({ payload });
                if (response) {
                    this.logs = response.logs;
                    this.$Alert.alert({
                        type: 'success',
                        message: 'School created!',
                        timeout: 3000
                    });
                    this.isDashboardCreated = true;

                    if (this.registerTwilioOnCreation === true) {
                        await this.registerTwilioPhoneNumber(response.schoolId);
                                        
                        if (this.verificationChecked) {
                            await this.submitTwilioPhoneVerification({
                                payload: {
                                    ...verificationPayload,
                                    schoolId: response.schoolId
                                }
                            }
                        )};
                    }
                    this.resetForm();
                }
            } catch (error) {
                this.$Alert.alert({
                    type: 'error',
                    message: error,
                    timeout: 3000
                });
                console.error(error);
            } finally {
                this.showLoadingSpinner = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 2rem;
    margin-top: 2rem;
}
.label-input-container {
    display: flex;
    gap: 5px;
}
.label-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.input-container {
    display: flex;
    flex-direction: column;
    gap: 7px;
    width: 25rem;
}
.input-box {
    height: 27px;
    width: 25rem;
    padding-left: 10px;
    border: 1px solid #3c3c3c42;
    border-radius: 3px;

    &.verification {
        width: 26rem;
    }
}
.button-create {
    margin-left: 2rem;
}
.list-container {
    margin-left: 10rem;
}
.dropdown {
    width: 26rem;
}
.input-textarea {
    height: 10rem;
    width: 26rem;
    border-radius: 3px;
    border-color: #3c3c3c42;
}
.checkbox-container {
    display: flex;
    gap: 5px;
}
.verification-container {
    display: flex;
    flex-direction: column;

    &.header {
        align-items: center;
        justify-content: center;
        margin-bottom: 2rem;
    }
}
</style>
