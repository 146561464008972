<template>
    <modal
        classes="v--modal"
        name="modal-change-student-phase"
        width="800"
        height="auto"
        :scrollable="true"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
        @closed="closed"
        :stack="false"
    >
        <div class="modal">
            <div class="modal__content">
                <div class="filters">
                    <div class="selected-filters">
                        <template v-if="hasFilters.length">
                            <div
                                :key="`${i}-${filter.label}`"
                                v-for="(filter, i) in filters"
                                class="selected-filters__filter"
                            >
                                <template v-if="filter.value">
                                    <span class="filter-label">{{ filter.label }}</span>
                                    <template v-if="Array.isArray(filter.value)">
                                        <input
                                            disabled="true"
                                            class="filter-option"
                                            :key="i"
                                            v-for="(v, i) in filter.value"
                                            :value="v"
                                        />
                                    </template>
                                    <input
                                        type="text"
                                        disabled="true"
                                        class="filter-option"
                                        v-else
                                        :value="filter.value"
                                    />
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            No Filters were selected
                        </template>
                    </div>
                    <span class="count">{{ count }} out of {{ totalCount }} selected</span>
                </div>
                <div class="update-students">
                    <div class="selection">
                        <div class="dd__phases">
                            <label>Select a Phase:</label>
                            <DropDownSelect
                                :items="phases"
                                displayKey="name"
                                valueKey="id"
                                v-model="selectedPhase"
                                v-model:itemsRef="selectedPhaseRef"
                                local
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal__footer">
                <Button v-if="!loading" :disabled="!this.selectedPhase" @click="onSubmit"
                    >Submit</Button
                >
                <LoadingSpinner v-else />
            </div>
        </div>
    </modal>
</template>

<script>
import Student from '@/services/students';
import DropDownSelect from '@/components-deprecated/inputs/DropDownSelect';

import Button from '@/components-deprecated/Button';

import LoadingSpinner from '@/components-deprecated/LoadingSpinner';

export default {
    components: {
        Button,
        DropDownSelect,
        LoadingSpinner
    },
    props: {
        cancelBtn: {
            type: String,
            default: 'Skip'
        }
    },
    data() {
        return {
            loading: false,
            complete: false,
            totalCount: 0,
            count: 0,
            students: [],
            allStudents: false,

            phases: [],
            selectedPhase: null,
            selectedPhaseRef: null,
            filters: [],
            queryParams: null
        };
    },
    watch: {},
    methods: {
        async onSubmit() {
            // if the allStudents checkbox is selected the
            // endpoint will only take the filters and the fields to change
            // otherwise it will just take in the students to change their data
            let data = {};
            if (this.allStudents) {
                data['allStudents'] = true;
                data['fields'] = { phase: this.selectedPhase };
                data['filters'] = this.queryParams;
            } else {
                data['students'] = this.students.map(student =>
                    Student.create({ ...student, phase: this.selectedPhase })
                );
                data['fields'] = { phase: this.selectedPhase };
            }

            try {
                this.loading = true;
                const res = await Student.api.bulkUpdate(data);
                this.selectedPhase = null;
                this.selectedPhaseRef = null;
                this.$emit('success', res);
                this.$modal.hide('modal-change-student-phase');
            } finally {
                this.loading = false;
            }
        },
        beforeOpen(event) {
            this.allStudents = event.params.allStudents;
            this.totalCount = event.params.totalCount;
            this.count = this.allStudents ? this.totalCount : event.params.count;
            this.students = event.params.students.length ? event.params.students : [];

            this.phases = event.params.phaseOptions;
            this.filters = [
                { label: 'schools', value: event.params.filters.schools },
                { label: 'intervention', value: event.params.filters.intervention },
                { label: 'tags', value: event.params.filters.tags },
                { label: 'opted out', value: event.params.filters.optedOutOfSms },
                { label: 'invalid phone number', value: event.params.filters.inValidPhoneNumber },
                { label: 'first time engager', value: event.params.filters.firstTimeEngager },
                { label: 'phase', value: event.params.filters.phase },
                { label: 'riskDrivers', value: event.params.filters.riskDrivers }
            ];
            this.queryParams = event.params.queryParams;
        },
        closed() {
            this.selectedPhase = null;
        }
    },

    computed: {
        hasFilters() {
            return this.filters.filter(l => l.value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/views/modal';
@import '../../styles/forms';
@import '../../styles/variables';

.modal {
    &__header {
        background-color: white;
        padding: 1rem 0rem;
        margin: 0rem;
        border-bottom: solid 1px #e3eded;
        &__title {
            margin: 0 2rem;
            font-weight: bold;
            font-size: 1.5rem;
            color: #202123;
        }
    }
}

.modal__content {
    padding: 0rem 0rem;
    height: 600px;
    padding: 1rem 2rem;
    .filters {
        min-height: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid gray;
    }
}

.modal__btn {
    display: inline-block;
    margin: 0;
    margin-right: 15px;
}

.modal__btn-wrapper {
    display: flex;
    justify-content: flex-end;
}
.step {
    margin: 2rem 2rem;
}
.school-filter {
    display: flex;
}
.filter-label {
    margin-top: 1.5rem;
}
.school-dropdown {
    height: 20px;
}

.step-error {
    color: red;
    font-size: 10px;
    font-style: italic;
}
.step-info {
    color: $primary-brand-color;
    font-size: 10px;
    font-style: italic;
}

form {
    @extend %form;
    input[type='radio'] + label {
        min-width: 1.5rem;
        width: 1.5rem;
    }
}
.filter-option {
}
.filter-label {
    font-size: 11px;
    color: #979797;
    margin: 1rem;
}
.count {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
}
.selected-filters {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    &__filter {
        display: flex;
        flex-wrap: wrap;

        > .filter-option {
            white-space: nowrap;
            overflow: ellipsis;
            width: 20rem;
            height: 2rem;
            border-radius: 30px;
            border: 1px solid gray;

            margin: 1rem;
        }
    }
}
.dd__phases {
    width: 20rem;
    height: 3rem;
}
:deep(.update-students .dropdown) {
    // manually setting input style here

    .dropdown-input-container {
        @extend %input-style;
        width: 100%;

        font: inherit;
        color: rgba(32, 33, 35, 0.6);
        &.disabled {
            border: 1px solid gray;
        }
    }
}
.update-students {
    display: flex;
}
</style>
