<template>
    <div class="container">
        <div class="toolbar">
            <div class="toolbar__row" style="width: 85%;">
                <div style="display: flex;">
                    <label for="start-date">Start Date</label>
                    <input
                        type="date"
                        class="toolbar__date-input input-style"
                        v-model="filters.startDate"
                        @input="refreshMessages"
                        id="start-date"
                    />
                </div>

                <div style="display: flex;">
                    <label for="start-time">Start Time</label>
                    <input
                        type="time"
                        id="start-time"
                        class="toolbar__time-input input-style"
                        v-model="filters.startTime"
                        @input="refreshMessages"
                    />
                </div>

                <div style="display: flex;">
                    <label for="end-date">End Date</label>
                    <input
                        type="date"
                        class="toolbar__date-input input-style"
                        v-model="filters.endDate"
                        @input="refreshMessages"
                        id="end-date"
                    />
                </div>

                <div style="display: flex;">
                    <label for="end-time">End Time</label>
                    <input
                        type="time"
                        id="end-time"
                        class="toolbar__time-input input-style"
                        v-model="filters.endTime"
                        @input="refreshMessages"
                    />
                </div>
            </div>

            <div class="toolbar__row" style="width: 40rem; margin-top: 1rem;">
                <input
                    type="text"
                    class="toolbar__student input-style"
                    v-model="filters.search"
                    @change="refreshMessages"
                    placeholder="Student"
                />

                <select class="toolbar__status input-style" v-model="status">
                    <option value="">All</option>
                    <option value="INCOMING">Incoming</option>
                    <option value="OUTGOING">Outgoing</option>
                </select>
            </div>
        </div>

        <div class="table">
            <List>
                <template v-slot:list-actions>
                    <button v-if="!loadingExport" class="export-link" @click="exportToCSV">
                        Export to CSV
                    </button>
                    <LoadingSpinner v-if="loadingExport" class="export-loading-spinner" />
                </template>

                <template v-slot:list-header>
                    <ChatbotTextsListHeader />
                </template>

                <template v-slot:list-items>
                    <ChatbotTextsListItem
                        v-for="message in messages.list"
                        :key="message.id"
                        :message="message"
                    />

                    <Paginator
                        v-if="messages.pagination.next != null"
                        class="paginator-button"
                        :loading="messages.loadingNextPage"
                        @next-page="messages.addNextPage()"
                    />
                </template>
            </List>
        </div>
    </div>
</template>

<script>
import momentTz from 'moment-timezone';

import { Message } from '@/services/messages/';
import CollectionManager from '@/services/collectionManager';

import List from '@/components-deprecated/tables/List';
import ChatbotTextsListHeader from '@/components-deprecated/tables/ChatbotTextsListHeader';
import ChatbotTextsListItem from '@/components-deprecated/tables/ChatbotTextsListItem';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import Paginator from '@/components-deprecated/Paginator';

export default {
    name: 'ChatbotTexts',
    components: {
        LoadingSpinner,
        List,
        ChatbotTextsListHeader,
        ChatbotTextsListItem,
        Paginator
    },
    props: {
        selectedSchool: {
            default: null
        }
    },
    data() {
        return {
            messages: CollectionManager.create({
                ModelClass: Message
            }),
            filters: {
                startDate: null,
                startTime: null,
                endDate: null,
                endTime: null,
                search: null
            },
            status: '',
            loadingExport: false
        };
    },
    async created() {
        await this.refreshMessages();
    },
    computed: {
        startDateFilter() {
            if (!this.filters.startDate) {
                return null;
            }
            // Start time defaults to Midnight UTC
            const startTime = this.filters.startTime ? this.filters.startTime : '19:00';
            let moment = momentTz.tz(`${this.filters.startDate}T${startTime}`, 'America/New_York');
            return moment
                .clone()
                .utc()
                .format();
        },
        endDateFilter() {
            if (!this.filters.endDate) {
                return null;
            }
            // Emd time defaults to Midnight UTC
            const endTime = this.filters.endTime ? this.filters.endTime : '19:00';
            let moment = momentTz.tz(`${this.filters.endDate}T${endTime}`, 'America/New_York');
            return moment
                .clone()
                .utc()
                .format();
        }
    },
    watch: {
        async selectedSchool(val) {
            await this.refreshMessages();
        },
        async status(val) {
            await this.refreshMessages();
        }
    },
    methods: {
        async refreshMessages() {
            this.messages.filters = {
                search: this.filters.search,
                created__gte: this.startDateFilter,
                created__lte: this.endDateFilter,
                school: this.selectedSchool,
                status: this.status
            };
            await this.messages.refresh();
        },
        async exportToCSV() {
            if (this.loadingExport) return;

            this.loadingExport = true;
            const filters = {
                search: this.filters.search,
                created__gte: this.startDateFilter,
                created__lte: this.endDateFilter,
                school: this.selectedSchool,
                status: this.status
            };

            try {
                await Message.api.export(filters);
            } finally {
                this.loadingExport = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables';
.toolbar {
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__date-input {
        width: 10rem;
    }

    &__time-input {
        width: 10rem;
    }

    &__student {
        width: 20rem;
    }

    &__status {
        width: 10rem;
    }
}

label {
    display: flex;
    align-items: center;
    width: 6rem;
}

.input-style {
    width: 180px;
    margin: 0 20px;
    box-shadow: 0 5px 20px 0 #f6f9fe;
    border: solid 1px #e3eded;
    background-color: rgba(0, 0, 0, 0.02);
    padding: 10px;
    border-radius: 6px;
}

.export-link {
    width: 12rem;
    text-decoration: underline;
    color: #068bff;
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: auto;
    background: none;
    border: 0;
    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.export-loading-spinner {
    margin: 0;
    margin-right: 3rem;
}
</style>
