<template>
    <div class="temp-container">
        <WebChatbot />
    </div>
</template>

<script>
import WebChatbot from './WebChatbot.vue';

export default {
    name: 'Chatbot',
    components: {
        WebChatbot
    }
};
</script>

<style>
.temp-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 80%;
    max-width: 850px;
    margin: 0 auto;
}

.split-container {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.actions-column {
    width: 250px;
    padding: 10px;
    box-sizing: border-box;
}

.split-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.top-pane {
    flex-grow: 1;
    overflow: auto;
    box-sizing: border-box;
    height: 610px;
    /* border-left: 2px solid #ccc; */
}

.bottom-pane {
    flex-grow: 1;
    overflow: auto;
    box-sizing: border-box;
    /* border-left: 2px solid #ccc; */
}

/* .divider {
    height: 2px;
    cursor: row-resize;
    background-color: #ccc;
} */
</style>
