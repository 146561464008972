<!--
Modal accepts a single child element which will render in the main content area, like below:
  <Modal
    title="My Modal"
    primaryButtonTitle="Save"
    :showPrimaryButtonLoadingSpinner="saving"
    @secondary-button-clicked="close"
    @primary-button-clicked="save"
  >
    <div>
      anything can go here, as long as it's wrapped with one element (like this div)
    </div>
  </Modal>
-->
<template>
    <div class="modal-container">
        <div class="modal" :class="size">
            <header>{{ title }}</header>
            <div class="content">
                <slot></slot>
            </div>
            <footer>
                <Button
                    v-if="showSecondaryButton"
                    styleType="off"
                    :disabled="secondaryButtonDisabled || showPrimaryButtonLoadingSpinner"
                    @click="$emit('secondary-button-clicked')"
                >
                    {{ secondaryButtonTitle }}
                </Button>
                <Button
                    v-if="showPrimaryButton"
                    :loading="showPrimaryButtonLoadingSpinner"
                    :disabled="primaryButtonDisabled || showPrimaryButtonLoadingSpinner"
                    @click="$emit('primary-button-clicked')"
                >
                    {{ primaryButtonTitle }}
                </Button>
            </footer>
        </div>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';

export default {
    name: 'Modal',
    components: {
        Button
    },
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        size: {
            type: String,
            default: 'small',
            validator: function(value) {
                return ['small', 'medium', 'large'].includes(value);
            }
        },
        showPrimaryButton: {
            type: Boolean,
            default: true
        },
        showSecondaryButton: {
            type: Boolean,
            default: true
        },
        primaryButtonTitle: {
            type: String,
            default: 'Continue'
        },
        secondaryButtonTitle: {
            type: String,
            default: 'Cancel'
        },
        showPrimaryButtonLoadingSpinner: {
            type: Boolean,
            default: false
        },
        primaryButtonDisabled: {
            type: Boolean,
            default: false
        },
        secondaryButtonDisabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $black-alpha-0-20;

    .modal {
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 1rem 3rem;
        border-radius: 3px;
        @include box-shadow;

        &.small {
            width: 30rem;
            min-height: 20rem;
        }

        &.medium {
            width: 45rem;
            min-height: 20rem;
        }

        &.large {
            width: 60rem;
            min-height: 20rem;
        }

        header {
            margin: 0;
            font-weight: bold;
            font-size: 1.5rem;
            padding: 1rem 0;
            border-bottom: 1px solid $light-gray;
        }

        .content {
            flex-grow: 1;
            display: flex;
            align-items: stretch;
            padding: 0.5rem 0;

            & > * {
                width: 100%;
            }
        }

        footer {
            display: flex;
            justify-content: flex-end;
            bottom: 0;

            * {
                margin-left: 1rem;
            }
        }
    }
}
</style>
