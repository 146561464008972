<template>
    <div class="student" @click="onClickStudent(student.id)">
        <div @click.stop class="student__checkbox">
            <BaseCheckbox
                class="checkbox"
                :modelValue="selected"
                @update:modelValue="toggleSelected"
                :ariaLabel="'Select ' + student.displayName"
            />
        </div>
        <div class="student__name">{{ student.displayName }}</div>
        <div class="student__email">{{ student.email }}</div>
    </div>
</template>

<script>
import Student from '@/services/students';
import { BaseCheckbox } from '@edsights/ui-core';

export default {
    name: 'ManageStudentsListItem',
    inheritAttrs: false,
    components: {
        BaseCheckbox
    },
    props: {
        student: {
            type: Student,
            default: Student.create()
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        onClickStudent(id) {
            const routeData = this.$router.resolve({
                name: 'StudentDetail',
                params: { id }
            });
            window.open(routeData.href, '_blank');
        },
        onClickEdit() {
            this.$emit('edit', this.student);
        },
        toggleSelected(value) {
            if (value) {
                this.$emit('select', this.student);
            } else {
                this.$emit('unselect', this.student);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.student {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    &__checkbox {
        width: 10%;
    }

    &__name {
        width: 25%;
        margin-right: 4rem;
    }

    &__email {
        width: 50%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 4rem;
    }

    &__edit {
        color: $main-gray;
        cursor: pointer;
    }

    &:hover {
        background-color: $off-gray;
        cursor: pointer;
    }
}
</style>
