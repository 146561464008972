import * as Api from '@/api/index';
import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import { strictEqual } from 'assert';

export const findTagById = async ({ id, errorHandlerOptions = {} }) => {
    try {
        strictEqual(typeof id, 'number');

        const url = Api.getv2ApiBase(`/tag/${id}`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the tag. Please try again.',
            ...errorHandlerOptions
        });
    }
};

export const listTagsForSchool = async ({ schoolId, filters, errorHandlerOptions = {} } = {}) => {
    try {
        strictEqual(typeof schoolId, 'string');
        const url = Api.getv2ApiBase(`/tag/school/${schoolId}`);
        const options = Api.getOptions();
        const params = {};

        if (typeof filters === 'object' && typeof filters.includePublic === 'boolean') {
            params.includePublic = filters.includePublic;
        }

        const response = await axios.get(url, { ...options, params });
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error,
            overrideMessage: 'There was a problem loading the tags. Please try again.',
            ...errorHandlerOptions
        });
    }
};
