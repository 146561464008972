<template>
    <div class="login">
        <BackToStaffPortalButton />
        <div class="login__top">
            <img class="logo" src="../../assets/edsights-logo.png" alt="EdSights logo" />
            <h1>Sign in to a school account</h1>
        </div>
        <div class="login__bottom">
            <div class="login__form-box box">
                <form class="login__form" id="login-form" v-on:submit.prevent="enterSchool()">
                    <div class="login__form-top">
                        <h2>Sign in</h2>
                    </div>
                    <div class="line-after"></div>
                    <div class="login__form-bottom">
                        <div class="fields">
                            <div class="login__form-text">
                                <h3 style="color:#808080">School</h3>
                            </div>
                            <div class="field-group">
                                <div class="search-container">
                                    <div class="school-filter">
                                        <SchoolDropDown
                                            @selected-school-id-updated="setSelectedSchool"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="field-group">
                                <loading-spinner-button
                                    type="submit"
                                    class="login-btn"
                                    :text="'Sign in'"
                                    :loading="loading"
                                ></loading-spinner-button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import LoadingSpinnerButton from '@/components-deprecated/LoadingSpinnerButton';
import BackToStaffPortalButton from '@/components-deprecated/staff/BackToStaffPortalButton';
import SchoolDropDown from '@/components-deprecated/inputs/SchoolDropDown';

export default {
    name: 'StaffEnterSchool',
    components: {
        BackToStaffPortalButton,
        LoadingSpinnerButton,
        SchoolDropDown
    },
    data() {
        return {
            loading: false,
            selectedSchool: null
        };
    },
    methods: {
        ...mapActions(['updateStaffUserAndEnterSchoolMode']),
        async enterSchool() {
            if (this.selectedSchool) {
                try {
                    this.loading = true;
                    await this.updateStaffUserAndEnterSchoolMode(this.selectedSchool);
                    await this.$router.push({ name: 'Home' });
                } catch (error) {
                    this.$Alert.alert({
                        type: 'error',
                        message:
                            'A problem occurred while signing in to the selected school. Please try again.',
                        timeout: 10000
                    });
                    console.error(error);
                } finally {
                    this.loading = false;
                }
            } else {
                this.$Alert.alert({
                    type: 'error',
                    message: 'Please select a school.',
                    timeout: 10000
                });
            }
        },
        setSelectedSchool(schoolId) {
            this.selectedSchool = schoolId;
        }
    },
    computed: {
        ...mapState(['user'])
    }
};
</script>

<style lang="scss" scoped>
@import '../../styles/login';

.login {
    .back-to-staff-portal-button {
        padding-left: 25px;
        padding-top: 25px;
    }

    .login__form-box {
        overflow: visible;
    }

    .school-filter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
}
</style>
