'use strict';

import emojiRegex from 'emoji-regex';
import Assert from 'assert';

// utility function that trims and lowercases a string
// does not throw
export const trimAndLower = str => {
    try {
        Assert.strictEqual(typeof str, 'string', 'str must be a string');
        return str.trim().toLowerCase();
    } catch (error) {
        return str;
    }
};

/**
 * Removes emojis from a given string.
 * @param {string} string - The string to remove emojis from.
 * @returns {string | *} Returns the input string with emojis removed. If a string isn't provided or
 * an error occurs, it returns the input unmodified.
 */
export const removeEmojis = string => {
    try {
        Assert.strictEqual(typeof string, 'string', 'string must be a string');
        const regex = emojiRegex();
        return string.replace(regex, '').trim();
    } catch (error) {
        console.log(error);
        return string;
    }
};

export const formatAccessibleString = (string, isAccessible) => {
    return isAccessible === true ? removeEmojis(string) : string;
};
