<template>
    <div class="bindings-dropdown-container">
        <Dropdown
            :options="options"
            alignMenu="right"
            @option-clicked="onSelect"
            :showLoadingMenu="!options.length"
            enableSearch
        >
            <template v-slot:custom-menu-trigger>
                <div class="icon-button">
                    <img class="icon" src="@/assets/icons/template.png" alt="Emoji" />
                </div>
            </template>
        </Dropdown>
    </div>
</template>

<script>
import Dropdown from '@/components-deprecated/global/Dropdown';

export default {
    name: 'BindingsDropdown',
    components: {
        Dropdown
    },
    data() {
        return {
            shouldShowDropdown: false
        };
    },
    props: {
        bindings: {
            type: Object,
            required: true
        }
    },
    methods: {
        onSelect(binding) {
            this.$emit('onSelect', binding);
        }
    },
    computed: {
        options() {
            if (typeof this.bindings === 'object') {
                return Object.keys(this.bindings).map(bindingKey => ({
                    label: bindingKey,
                    value: bindingKey
                }));
            }

            return [];
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.bindings-dropdown-container {
    display: flex;
    flex-direction: row;

    .icon-button {
        cursor: pointer;
        .icon {
            height: 30px;
            width: 30px;
        }
    }
}
</style>
