import axios from 'axios';

import Auth from '../auth';
import { API_BASE, apiErrorHandler } from '../api';

// Endpoint
const RISK_BUCKETS_ENDPOINT = `${API_BASE}risk-buckets/`;

export default class RiskBucketAPI {
    constructor(cls) {
        this.cls = cls;
    }
    static create(cls) {
        return new RiskBucketAPI(cls);
    }

    async list() {
        const url = RISK_BUCKETS_ENDPOINT;
        const options = {
            headers: await Auth.getHeaders().headers,
            params: {
                page_size: 50
            }
        };

        const promise = axios
            .get(url, options)
            .then(response => {
                return {
                    ...response.data,
                    results: response.data.results.map(datum => this.cls.fromAPI(datum))
                };
            })
            .catch(apiErrorHandler({ apiName: 'List Risk Buckets API error' }));
        return promise;
    }
}
