<template>
    <StaffEnterSchool />
</template>

<script>
import StaffEnterSchool from '@/components-deprecated/staff/StaffEnterSchool';
export default {
    name: 'StaffEnterSchoolPage',
    components: { StaffEnterSchool }
};
</script>

<style scoped></style>
