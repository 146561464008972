import axios from 'axios';
import { v2ApiErrorHandler } from '@/api/lib/error-handler';
import * as Api from './index';

export const getStudentFilterStudentCount = async filterParams => {
    try {
        const queryString = Api.serializeObjectToQueryString(filterParams);
        const url = Api.getv2ApiBase(`/student-filters/student-count?${queryString}`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data.count;
    } catch (error) {
        v2ApiErrorHandler({
            error
        });
    }
};

export const findStudentFilterById = async studentFilterId => {
    try {
        const url = Api.getv2ApiBase(`/student-filters/${studentFilterId}`);
        const options = Api.getOptions();

        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        v2ApiErrorHandler({
            error
        });
    }
};
