<template>
    <span class="emoji" :style="{ fontSize: this.fontSize }">{{ selectedEmoji }}</span>
</template>

<script>
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'Emoji',
    props: {
        emoji: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: '12'
        }
    },
    data() {
        return {
            emojis: {
                raisingHands: '\u{1F64C}',
                huggingFace: '\u{1F917}',
                chatBubble: '\u{1F4AC}',
                partyPopper: '\u{1F389}'
            }
        };
    },
    computed: {
        selectedEmoji() {
            if (!this.isExperimentalAccessibilityEnabled && this.emoji && this.emojis[this.emoji]) {
                return this.emojis[this.emoji];
            }
            return '';
        },
        fontSize() {
            return `${this.size}px`;
        },
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    }
};
</script>

<style scoped></style>
