<template>
    <div class="container">
        <TabsContainer initialTabId="New Submission" class="nested-tabs-container">
            <Tab id="New Submission">
                <SubmitVerification />
            </Tab>
            <Tab id="Check Status">
                <CheckVerification />
            </Tab>
        </TabsContainer>
    </div>
</template>

<script>
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import SubmitVerification from '@/views/staff/StaffTools/components/PhoneVerificationComponents/SubmitVerification';
import CheckVerification from '@/views/staff/StaffTools/components/PhoneVerificationComponents/CheckVerification';

export default {
    name: 'PhoneVerificationTools',
    components: {
        TabsContainer,
        Tab,
        SubmitVerification,
        CheckVerification
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.container {
    :deep() {
        .nested-tabs-container .tabs .tab {
            font-size: 1rem !important;

            &:hover {
                border-bottom: 3px solid #43d1ff !important;
            }

            &.is-active {
                color: #43d1ff !important;
                border-bottom: 3px solid #43d1ff !important;
            }
        }
    }
}
</style>
