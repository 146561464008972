<template>
    <modal name="risk-driver-info" :height="'auto'" :scrollable="true">
        <div class="risk-driver-info-modal">
            <h2 style="margin-bottom: 1rem; text-align: center;">About the Risk Drivers</h2>

            <div class="icon-description">
                <svg class="dashboard__risk-icon--large">
                    <use xlink:href="#icon-risk-financial" />
                </svg>
                <p>
                    <b>Financial Struggles:</b> If a student has this icon it means that they are at
                    risk for financial reasons. For example, they may be struggling to balance work
                    and school or to pay for textbooks and bills.
                </p>
            </div>

            <div class="icon-description">
                <svg class="dashboard__risk-icon--large">
                    <use xlink:href="#icon-risk-academic" />
                </svg>
                <p>
                    <b>Academic Struggles:</b> If a student has this icon it means that they are at
                    risk for academic reasons and are struggling with their courses. For example,
                    they may be scared of failing a class or not be interested in the courses that
                    they are taking.
                </p>
            </div>

            <div class="icon-description">
                <svg class="dashboard__risk-icon--large">
                    <use xlink:href="#icon-risk-engagement" />
                </svg>
                <p>
                    <b>Engagement Level:</b> If a student has this icon it means that they are at
                    risk because they are not engaged with their school. For example, they may feel
                    like they don’t fit in or not have someone to reach out to if they have a
                    problem.
                </p>
            </div>

            <div class="icon-description">
                <svg class="dashboard__risk-icon--large">
                    <use xlink:href="#icon-risk-belief" />
                </svg>
                <p>
                    <b>Personal Beliefs:</b> If a student has this icon it means that they are at
                    risk because of their personal beliefs. For example, they may not be confident
                    in their ability to finish school or think that a college degree is not worth
                    the effort.
                </p>
            </div>

            <div style="margin-top: 1rem;">
                <button
                    @click="$modal.hide('risk-driver-info')"
                    class="risk-driver-info-modal__close-button"
                >
                    Close
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'RiskDriverModal',
    methods: {
        open() {
            this.$modal.show('risk-driver-info');
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixins/buttons';
@import '../styles/variables';

.risk-driver-info-modal {
    padding: 2rem;

    &__close-button {
        @include base-button();
        margin: 0 auto;
    }
}

.icon-description {
    display: flex;
    align-items: flex-end;
    max-height: 120px;

    & > svg {
        flex: 1;
        margin: 0.5rem 1rem 0 0;
        color: $primary-brand-color;
    }

    & > p {
        flex: 8;
    }
}
</style>
