<template>
    <Modal
        class="create-label-modal-container"
        title="Create New Label"
        primaryButtonTitle="Save"
        :showPrimaryButtonLoadingSpinner="saving"
        @secondary-button-clicked="close"
        @primary-button-clicked="save"
    >
        <div class="modal-content">
            <TextInput placeholder="Label Name" v-model="labelText" />
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components-deprecated/global/Modal';
import TextInput from '@/components-deprecated/global/TextInput';
import { createChatbotFlowLabel } from '@/api/chatbot-flow-labels';

export default {
    name: 'CreateChatbotFlowLabelModal',
    components: {
        TextInput,
        Modal
    },
    data() {
        return {
            labelText: '',
            saving: false
        };
    },
    methods: {
        close() {
            this.$emit('close-modal');
        },
        async save() {
            if (!this.labelText.trim()) {
                this.$Alert.alert({
                    type: 'error',
                    message: 'Label name is required.',
                    timeout: 5000
                });
                return;
            }

            try {
                this.saving = true;
                const createdLabel = await createChatbotFlowLabel(this.labelText);
                this.saving = false;

                if (
                    createdLabel &&
                    typeof createdLabel.text === 'string' &&
                    typeof createdLabel.id === 'string'
                ) {
                    const { text, id } = createdLabel;
                    this.$emit('label-created', { text, id });
                }
                this.close();
            } catch (error) {
                this.saving = false;
                this.$Alert.alert({
                    type: 'error',
                    message: 'There was an issue while creating the label. Please try again.',
                    timeout: 5000
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.create-label-modal-container {
    .modal-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
