<template>
    <button :class="['selectableTile', tileTheme]" v-tooltip="selected ? 'Reset this alert' : ''">
        <div class="icon-wrapper">
            <slot name="icon">
                <!-- Default SVG if none is provided via slot -->
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </slot>
        </div>

        <div class="flex">
            <span class="title">{{ title }}</span>
            <span v-if="supportingText" class="supporting-text">{{ supportingText }}</span>
        </div>
    </button>
</template>

<script>
export default {
    name: 'SelectableTile',
    props: {
        title: {
            type: String,
            required: true
        },
        supportingText: {
            type: String,
            required: false
        },
        icon: {
            type: String,
            required: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        tileTheme: {
            type: String,
            required: true,
            default: 'blueTile'
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.selectableTile {
    padding: 16px;
    background: transparent;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    cursor: pointer;
    border: 1px solid #757575;
    &.selected {
        border: 2px solid #0d6ec4;
    }
    &.blueTile {
        .icon-wrapper {
            background: #e9f0f9;
        }
        svg {
            color: #0d6ec4;
        }
    }
    &.grayTile {
        .icon-wrapper {
            background: #eeeeee;
        }
        svg {
            color: #333333;
        }
    }
}
.icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}
.flex {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.title {
    font-weight: bold;
    color: #344054;
}
.supporting-text {
    color: #475467;
}
</style>
