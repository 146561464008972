<template>
    <div class="container">
        <TabsContainer initialTabId="Flows">
            <Tab id="Flows">
                <Flows />
            </Tab>
            <Tab id="Calendar">
                <FlowsCalendar />
            </Tab>
            <Tab id="Templates">
                <Templates />
            </Tab>
            <Tab id="Labels">
                <Labels />
            </Tab>
        </TabsContainer>
    </div>
</template>

<script>
import TabsContainer from '@/components-deprecated/global/TabsContainer';
import Tab from '@/components-deprecated/global/Tab';
import Flows from '@/views/staff/ChatbotFlowList/components/Flows';
import Templates from '@/views/staff/ChatbotFlowList/components/Templates';
import Labels from '@/views/staff/ChatbotFlowList/components/Labels';
import FlowsCalendar from '@/views/staff/ChatbotFlowList/components/FlowsCalendar';

export default {
    name: 'ChatbotFlowList',
    components: {
        TabsContainer,
        Tab,
        Flows,
        Templates,
        Labels,
        FlowsCalendar
    }
};
</script>
<style lang="scss" scoped>
@import '~@/styles/variables';
.container {
    :deep() {
        .tabs-container .tabs .tab {
            font-size: 1.333rem;
        }
    }
}
</style>
