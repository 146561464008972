import User from './models';

// Define import methods for student names/emails
const UPLOAD_CSV = 'CSV';
const COPY_PASTE = 'PASTE';
const importTypes = {
    UPLOAD_CSV,
    COPY_PASTE
};

export { User, importTypes };

export default {
    User,
    importTypes
};
