<template>
    <div class="container">
        <div class="description">
            <h1 class="h2">Chatbot Messaging</h1>
            <p>
                This is where you see all the touchpoints your chatbot has had with students,
                including custom campaigns you've sent out.
            </p>
        </div>
        <div class="toolbar">
            <div class="filter-row">
                <template v-if="isExperimentalAccessibilityEnabled">
                    <div class="accessible-filter-wrapper">
                        <DropdownV2
                            :options="filterChoices"
                            :selectedOptionValue="filterValue.value"
                            optionLabelKey="name"
                            label="Filter by Touchpoint Type"
                            @onChange="onFilter"
                        />
                    </div>
                </template>
                <template v-else>
                    <div class="input-container" @click.stop>
                        <button
                            class="input-group cursor-pointer"
                            @click.stop="isDropdownActive = !isDropdownActive"
                        >
                            <label class="input-label faded">
                                {{ filterValue.name }}
                            </label>
                            <div>
                                <svg
                                    width="10px"
                                    height="10px"
                                    class="arrow-reversed"
                                    viewBox="0 0 5.9 17.51"
                                >
                                    <use xlink:href="#icon-arrow-right-wide" />
                                </svg>
                            </div>
                        </button>
                        <TransitionExpand>
                            <div class="transition-container" v-if="isDropdownActive">
                                <button
                                    aria-role="option"
                                    :key="filterChoice.id"
                                    v-for="filterChoice in filterChoices"
                                    class="dropdown-choice"
                                    @click.stop="onFilter(filterChoice)"
                                >
                                    {{ filterChoice.name }}
                                </button>
                            </div>
                        </TransitionExpand>
                    </div>
                </template>
            </div>
        </div>
        <LoadingSpinner v-if="touchpointsCollection.refreshing" />
        <Touchpoints
            v-else
            class="touchpoints"
            :touchpoints="touchpointsCollection.list"
            :hasMoreItems="touchpointsCollection.pagination.next != null"
            :loadingNextPage="touchpointsCollection.loadingNextPage"
            @navigate-to-intervention="navigateToIntervention"
            @next-page="touchpointsCollection.addNextPage()"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Touchpoints from '@/components-deprecated/admin/Touchpoints';
import TransitionExpand from '@/components-deprecated/TransitionExpand';
import LoadingSpinner from '@/components-deprecated/LoadingSpinner';
import v2CollectionManager from '@/services/v2CollectionManager';
import DropdownV2 from '@/components-deprecated/global/v2/DropdownV2.vue';
import { isExperimentalAccessibilityEnabled } from '@/lib/feature-flag';

export default {
    name: 'ChatbotMessagingPage',
    components: {
        DropdownV2,
        LoadingSpinner,
        Touchpoints,
        TransitionExpand
    },

    data() {
        let filterChoices = [
            { name: 'Show All Touchpoints', value: 'all' },
            { name: 'Custom Touchpoints', value: 'custom', isCustom: true },
            { name: 'EdSights Touchpoints', value: 'edsights', isCustom: false }
        ];

        return {
            isDropdownActive: false,
            filterValue: filterChoices[0],
            filterChoices: filterChoices,
            scrollTo: '',
            touchpointsCollection: v2CollectionManager.create({
                listType: 'touchpoints',
                errorHandlerOptions: {
                    enableAlert: true,
                    rethrow: false
                }
            })
        };
    },
    async created() {
        this.loadTouchpoints();
        window.addEventListener('click', this.closeEvent);
    },
    beforeUnmount() {
        window.removeEventListener('click', this.closeEvent);
    },
    computed: {
        ...mapState(['user']),
        isExperimentalAccessibilityEnabled() {
            if (this.$store.state.user.schoolRef) {
                return isExperimentalAccessibilityEnabled(this.$store.state.user.schoolRef.id);
            }

            return false;
        }
    },
    methods: {
        async loadTouchpoints() {
            this.touchpointsCollection.filters.schoolId = this.user.school;
            this.touchpointsCollection.refresh();
        },
        onFilter(filter) {
            this.filterValue = filter;
            this.touchpointsCollection.filters.isCustom = filter.isCustom;
            this.touchpointsCollection.refresh();
            this.isDropdownActive = false;
        },
        closeEvent() {
            this.isInterventionDropdownActive = false;
            this.isDropdownActive = false;
        },
        navigateToIntervention() {
            this.$router.push({
                name: 'InterventionsPage'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';

.interventions-page {
    margin: 0 auto;
    padding-top: 20px;
}

.top-spacing {
    margin-top: 1rem;
}

.table-items {
    display: flex;
}

.interventions-wrapper {
    margin-right: 15px;
    width: 30%;
}

.interventions {
    &__item :deep(.item) {
        min-height: 189px;
    }
}

.filter-row {
    .accessible-filter-wrapper {
        max-width: 300px;
    }

    &__filter {
        display: flex;
        align-items: center;

        * {
            margin: 0 0.5rem;
        }

        label {
            white-space: nowrap;
            font-size: 14px;
            color: black;
            font-weight: bold;
        }

        .dd__school {
            width: 30rem;
            height: 40px;
            display: flex;
            align-items: center;
        }
    }
}

.toolbar {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 2rem 0 0 10rem;
}

.input-container {
    width: 22%;
    position: relative;
    align-items: center;
    margin-right: 2rem;
}

.filter-button {
    margin-right: 1rem;
    color: $accessible-gray;

    &:last-child {
        margin-right: 4rem;
    }
}

.input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $white-blue;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    border: 0;
    height: 32px;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;

    &:focus {
        outline: 2px solid $edsights-blue;
    }

    > input {
        width: 100%;
        border: none;
        outline: none;
        background-color: inherit;
        padding-right: 3rem;
        font: inherit;
        color: $input-gray;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.input-label {
    cursor: pointer;
    background-color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;
}

.arrow {
    transform: rotate(-90deg);

    &-reversed {
        transform: rotate(90deg);
    }
}

.transition-container {
    position: absolute;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: $white;
    left: 0;
    right: 0;
    max-height: 400px;
}

.dropdown-choice {
    cursor: pointer;
    padding: 0.75rem 0.75rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: $base-font-color;
    font-size: 12px;
    letter-spacing: 1px;
    border: 0;
    background: #fff;
    text-align: left;
    margin-bottom: 2px;
    line-height: 26px;

    &:hover {
        background-color: $white-blue;
    }

    &:focus {
        outline: 2px solid $edsights-blue;
    }
}

.description {
    margin: 3rem 2.75rem;
}

.touchpoints {
    margin-top: 3.5rem;
}
</style>
