<template>
    <div class="choose-file">
        <button class="file-box" :class="{ disabled: disabled }" @click="onClickFileBox">
            <span v-if="selectedFile == null">
                <slot name="message">
                    <span class="text-bold" :class="{ 'text-primary': !disabled }"
                        >template file</span
                    >
                    with your students.
                </slot>
            </span>
            <span v-else class="text-primary">{{ selectedFile.name }}</span>
        </button>
        <input
            :disabled="disabled"
            type="file"
            hidden
            ref="file"
            :accept="fileType"
            @change="onSelectFile($event)"
        />
        <Button v-if="selectedFile" class="cancel-button" styleType="off" @click="resetFile">
            Cancel
        </Button>
    </div>
</template>

<script>
import Button from '@/components-deprecated/Button';
export default {
    name: 'ChooseFileCancelable',
    components: {
        Button
    },
    props: {
        // If true, will read file as string
        // If false, will pass back original file
        readAsString: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        // This prop is an optional file passed in from the parent
        file: {
            type: File,
            default: null
        },
        // the default file type is .csv as most uploads require that type
        fileType: {
            type: String,
            default: '.csv'
        }
    },
    data() {
        return {
            selectedFile: null,
            reader: new FileReader()
        };
    },

    created() {
        this.onFileLoaded = this.onFileLoaded.bind(this);
    },
    methods: {
        onClickFileBox() {
            this.$refs.file.click();
        },
        onSelectFile(event) {
            this.selectedFile = event.target.files[0];
            this.loadFile();
        },
        loadFile() {
            this.reader.onloadend = this.onFileLoaded;
            this.reader.readAsText(this.selectedFile);
        },
        onFileLoaded() {
            if (this.readAsString) {
                this.$emit('loaded', this.reader.result);
            } else {
                this.$emit('loaded', this.selectedFile);
            }
        },
        resetFile() {
            this.selectedFile = null;
            this.$refs.file.value = null;
            this.$emit('loaded', this.selectedFile);
        }
    },
    watch: {
        file(newValue) {
            // If the parent component passes in a null file, reset
            if (!newValue) {
                this.resetFile();
                // If the parent component passes in a real file, load it
            } else {
                this.selectedFile = this.file;
                this.loadFile();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.choose-file {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
}

.file-box {
    padding: 1rem;
    border: 2px dotted $primary-brand-color;
    font-size: 1.2rem;
    text-align: center;
    cursor: pointer;
    background: #fff;

    &:focus {
        outline: 2px solid $edsights-blue;
    }

    &.disabled {
        cursor: not-allowed;
        border: 2px dotted $secondary-gray;
    }

    &:hover {
        background-color: $off-gray;
    }
}

.cancel-button {
    margin-left: 1rem;
}
</style>
