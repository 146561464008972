<template>
    <div class="header">
        <div class="header__selected">
            <BaseCheckbox :modelValue="selected" @update:modelValue="toggleSelected" />
        </div>
        <div class="header__question">{{ questions }}</div>
        <div class="header__answer">{{ answers }}</div>
        <div class="header__topic">{{ topics }}</div>
        <div class="header__subtopic">{{ subtopics }}</div>
        <div class="header__lastUpdated">{{ lastUpdated }}</div>
        <div class="header__status">{{ status }}</div>
        <div class="header__action">{{ actions }}</div>
    </div>
</template>

<script>
import { BaseCheckbox } from '@edsights/ui-core';

export default {
    name: 'Faqv2ListItemHeader',
    components: { BaseCheckbox },
    props: {
        questions: {
            type: String,
            default: 'Questions'
        },
        answers: {
            type: String,
            default: 'Answers'
        },
        topics: {
            type: String,
            default: 'Topics'
        },
        subtopics: {
            type: String,
            default: 'Sub-topics'
        },
        lastUpdated: {
            type: String,
            default: 'Last Updated'
        },
        status: {
            type: String,
            default: 'Status'
        },
        actions: {
            type: String,
            default: 'Actions'
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    watch: {},
    methods: {
        toggleSelected(value) {
            this.$emit('handleSelection', value);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';

.header {
    background-color: $white-blue;
    display: table;
    top: 95px;
    z-index: 2;

    .header__selected {
        padding-top: 5px;
        padding-left: 5px;
        width: 5px;
    }

    .header__question {
        width: 250px;
        padding-right: 15px;
    }

    .header__answer {
        width: 300px;
        padding-right: 15px;
    }

    .header__topic {
        width: 100px;
        padding-right: 15px;
    }

    .header__subtopic {
        width: 100px;
    }

    .header__lastUpdated {
        width: 100px;
    }

    .header__status {
        width: 120px;
    }

    .header__action {
        width: 50px;
    }

    & > div {
        margin: 5px;
        word-wrap: break-word;
        display: table-cell;
    }
}
</style>
